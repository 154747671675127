import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/ActivityLog.scss';
import { withTranslation } from 'react-i18next';

class ActivityLog extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="activities">
          <h1>{t('pages.activityLog.title')}</h1>
          <div className="activities__container mt-3">
            <div className="col-xs-6">{t('pages.activityLog.mfa')}</div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">
              {t('pages.activityLog.userPreference')}
            </div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">{t('pages.activityLog.addedUser')}</div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">
              {t('pages.activityLog.addedSecurityQuestions')}
            </div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">{t('pages.activityLog.addedEmail')}</div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">{t('pages.activityLog.addedPhone')}</div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">
              {t('pages.activityLog.createAccount')}
            </div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
          <div className="activities__container">
            <div className="col-xs-6">{t('pages.activityLog.AgreedTerms')}</div>
            <div className="col-xs-6">{t('pages.activityLog.time')}</div>
          </div>
        </div>
      </div>
    );
  }
}
ActivityLog.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(ActivityLog);
