import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const WithError = (props) => {
  const { t } = useTranslation();
  const { errors, errorArray, isPopup } = props;
  const isLoginScreen = window.location.pathname === '/';

  let invalidPasswordHeader = false;

  return (
    <Container className={isLoginScreen ? '' : 'error'}>
      <Row>
        <Col
          className={isLoginScreen ? 'w-100' : ''}
          lg={isLoginScreen ? '' : !isPopup ? { span: 6, offset: 3 } : ''}
        >
          <div role="alert" aria-live="assertive" className="error__block">
            <div className="error__block-header">{t('errors.problem')}</div>
            <div className="error__block-body">
              {errorArray.length > 0
                ? errorArray.map((error, index) => {
                    let content = '';

                    if (error.property === 'mail') {
                      content = (
                        <div
                          className="error__block-body-section"
                          key={`${error.property} ${index}`}
                        >
                          <p>{t('errors.invalidEmail')}</p>
                          <ul>
                            <li>{t('errors.invalidEmailDesc')} </li>
                          </ul>
                        </div>
                      );
                    } else if (error.property === 'incorrectUserCredentials') {
                      content = (
                        <div
                          className="error__block-body-section"
                          key={`${error.property} ${index}`}
                        >
                          <p>{t('errors.loginApiError')}</p>
                          <ul>
                            <li>{t('errors.wrongCredentials')} </li>
                          </ul>
                        </div>
                      );
                    } else if (
                      error.property === 'applink' &&
                      error.policyRequirements[0].policyRequirement === 'UNIQUE'
                    ) {
                      content = (
                        <div
                          className="error__block-body-section"
                          key={`${error.property} ${index}`}
                        >
                          <p>{t('errors.uniqueAppLink')}</p>
                          <ul>
                            <li>{t('errors.uniqueAppLinkDesc')} </li>
                          </ul>
                        </div>
                      );
                    } else if (
                      error.property === 'securityQuestions' &&
                      error.policyRequirements[0] === 'answers'
                    ) {
                      content = (
                        <div
                          className="error__block-body-section"
                          key={`${error.property} ${index}`}
                        >
                          <p>{t('errors.securityQuestions')}</p>
                          <ul>
                            <li>{t('errors.securityQuestionsDesc')} </li>
                          </ul>
                        </div>
                      );
                    } else if (
                      error.property === 'userName' &&
                      error.policyRequirements[0].policyRequirement ===
                        'VALID_USERNAME'
                    ) {
                      content = (
                        <div
                          className="error__block-body-section"
                          key={`${error.property} ${index}`}
                        >
                          <p>{t('errors.invalidUserName')}</p>
                          <ul>
                            <li>{t('errors.invalidUserNameDesc')} </li>
                          </ul>
                        </div>
                      );
                    } else if (error.property === 'password') {
                      content = (
                        <div
                          className="error__block-body-section"
                          key={`${error.property} ${index}`}
                        >
                          <div>
                            <p>
                              {invalidPasswordHeader
                                ? ''
                                : t('errors.invalidPassword')}
                            </p>
                            <ul className="mb-2">
                              {error.policyRequirements[0].policyRequirement ===
                                'CANNOT_CONTAIN_OTHERS' && (
                                <li>
                                  {t('errors.invalidPasswordFirstLastName')}
                                </li>
                              )}
                              {(error.policyRequirements[0]
                                .policyRequirement ===
                                'CONTAINS_CONSECUTIVE_CHARS' ||
                                error.policyRequirements[0]
                                  .policyRequirement ===
                                  'CONTAINS_SEQUENCE_CHARS') && (
                                <li>
                                  {t('errors.invalidConsecutiveCharsDesc')}{' '}
                                </li>
                              )}
                              {error.policyRequirements[0].policyRequirement ===
                                'MIN_LENGTH' && (
                                <li>
                                  {t('errors.passwordMinLengthErrorDesc')}{' '}
                                </li>
                              )}
                              {error.policyRequirements[0].policyRequirement ===
                                'NOT_IN_MOST_COMMON_PASSWORD_LIST' && (
                                <li>
                                  {t(
                                    'errors.invalidPasswordDictionaryWordDesc'
                                  )}
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                      invalidPasswordHeader = true;
                    }
                    return content;
                  })
                : t(errors)}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

WithError.propTypes = {
  errors: PropTypes.string,
  errorArray: PropTypes.array,
  isPopup: PropTypes.bool,
};
WithError.defaultProps = {
  errors: '',
  errorArray: [],
  isPopup: false,
};
export default WithError;
