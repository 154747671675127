import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api cognito/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard Cognito/EditEmailPopup.scss';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
class VerifyEmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      userName: props.userName ? props.userName : '',
      errorText: '',
      errorArray: [],
      sn: props.sn ? props.sn : '',
      verificationCode: '',
      screen: 1,
      mail: props.mail ? props.mail : '',
      verificationOption: '',
      verificationCodeError: '',
      destinationEmail: '',
    };
  }

  componentDidMount() {
    this.inititateEmailVerification();
  }

  inititateEmailVerification = () => {
    const { mail } = this.state;
    let token = Cookies.get('dataStoreToken');
    const {
      onVerifyEmailSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;
    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
          AttributeName: 'email',
          Value: mail,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.GetUserAttributeVerificationCode',
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data.CodeDeliveryDetails) {
        this.setState({
          screen: 2,
          destinationEmail: res.data.CodeDeliveryDetails.Destination,
        });
      } else if (
        res.status === 400 &&
        res.data.__type === 'LimitExceededException'
      ) {
        onErrorEmailSuccessHandler('error', res.data.__type);
      }
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { verificationCode, mail } = this.state;
    const {
      onVerifyEmailSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      let bearer = 'Bearer ';
      // let token = localStorage.getItem('dataStoreToken');
      let token = Cookies.get('dataStoreToken');
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: '/amazonaws',
          data: {
            AccessToken: token,
            AttributeName: 'email',
            Code: verificationCode,
          },
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target':
            'AWSCognitoIdentityProviderService.VerifyUserAttribute',
        }
      ).then((res) => {
        if (res.status === 200 && res.data) {
          ReactGA.event({
            category: 'Email Verification',
            action: 'Success : Email Updated and otp verified.',
          });
          onVerifyEmailSuccessHandler('success');
        } else if (
          res.status === 400 &&
          res.data.__type == 'CodeMismatchException'
        ) {
          ReactGA.event({
            category: 'Email Verification',
            action: 'CodeMismatchException',
          });
          this.setState({
            verificationCodeError: 'pages.editEmail.invalidOTP',
          });
        }
      });
    }
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;

    this.setState({ verificationCode });
  }

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    let errorMsg = '';
    switch (key) {
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 1,
      verificationOption: '',
      verificationCodeError: '',
      verificationCode: '',
    });
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    let token = Cookies.get('dataStoreToken');
    const { mail, userName } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
          AttributeName: 'email',
          Value: mail,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.GetUserAttributeVerificationCode',
      }
    ).then((res) => {
      if (res.status === 400 && res.data.__type === 'NotAuthorizedException') {
        clearCookies();
        window.location.href = '/';
      } else if (
        res.status === 400 &&
        res.data.__type === 'LimitExceededException'
      ) {
        onErrorEmailSuccessHandler('error', res.data.__type);
      }
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      screen,
      verificationCodeError,
      emailError,
      mail,
      verificationOption,
      verificationCode,
      destinationEmail,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="change-password-popup edit-email-pop">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">{t('pages.editEmail.verify')}</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>

                  <div className="col-md-12 registration__container">
                    <p>
                      <span>
                        {t('pages.registration.verificationCodeSentTo')} {mail}
                      </span>
                    </p>
                    <div className="registration__container__resend-verification">
                      <div className="registration__container__resend-verification__resend-text">
                        <p
                          className="resend-link"
                          onClick={(e) => this.resendVerify(e)}
                          role="button"
                          tabIndex={0}
                        >
                          {t('pages.registration.resendVerificationCode')}
                        </p>
                      </div>
                    </div>
                    <Form>
                      <Form.Group
                        className="verification-form"
                        controlId="verificationCode"
                      >
                        <Form.Label className="mb-4">
                          {t('pages.registration.verificationCode')}
                        </Form.Label>
                        <input
                          type="text"
                          pattern="[0-9]*"
                          onInput={this.handleChange.bind(this)}
                          className="ml-3"
                          value={verificationCode}
                        />
                        {verificationCodeError && (
                          <p className="error-text" id="verification-error">
                            {verificationCodeError && t(verificationCodeError)}
                          </p>
                        )}
                      </Form.Group>
                      <div className="registration__container__pleasenote pleasenote">
                        <p className="registration__container__pleasenote-fp">
                          {t('pages.registration.note')}
                        </p>
                        <p className="registration__container__pleasenote-sp">
                          {t('pages.registration.verifyNote')}
                        </p>
                      </div>
                      <div className="row pt-3">
                        <div className="d-sm-flex col-lg-6 col-md-12">
                          <button
                            className="col btn btn-secondary hollow py-2 my-3 mr-sm-4"
                            onClick={(e) => this.onBackLogin(e)}
                            variant="primary"
                            block
                          >
                            {t('pages.forgotPassword.backBtn')}
                          </button>

                          <button
                            className="col btn btn-secondary  py-2 my-3"
                            onClick={(e) => this.onVerify(e)}
                          >
                            {t('pages.registration.verify')}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

VerifyEmailPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onVerifyEmailSuccessHandler: PropTypes.func.isRequired,
  onErrorEmailSuccessHandler: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
  mail: PropTypes.string,
  emailVerificationPopup: PropTypes.bool,
  updateVerifyFlag: PropTypes.func.isRequired,
  userName: PropTypes.string,
};
VerifyEmailPopup.defaultProps = {
  userName: '',
  givenName: '',
  sn: '',
  mail: '',
  emailVerificationPopup: false,
};
export default withTranslation()(VerifyEmailPopup);
