import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainMenu from 'app/modules/Dashboard/MainMenu';
import Home from 'app/modules/Dashboard/Home';
import Apps from 'app/modules/Dashboard/Apps';
import Admin from 'app/modules/Dashboard/Admin';
import IDs from 'app/modules/Dashboard/IDs';
import Wallet from 'app/modules/Dashboard/Wallet';
import CustomWallet from 'app/modules/Dashboard/CustomWallet';
import Notifications from 'app/modules/Dashboard/Notifications';
import Account from 'app/modules/Dashboard/Account';
import 'app/modules/Dashboard/Dashboard.scss';
import { withTranslation } from 'react-i18next';
import http from 'api/http-request';
import queryString from 'query-string';
import { somethingWentWrong } from 'utils/common';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import { checkTimeDiffValid, redirectLogic } from 'utils/common/index';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';
import ReactGA from 'react-ga';
import 'app/modules/IdProofing/IdProofingBanner.scss';
import IdProofingPopup from '../IdProofing/IdProofingPopup';
import AdminAccount from 'app/modules/Dashboard/AdminAccount';
import IdProofingBlockedPopup from '../IdProofing/IdProofingBlockedPopup';
import ManageServices from './ManagedServices/ManageServices';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { sanitizeUrl } from '@braintree/sanitize-url';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect_uri: '',
      client_id: '',
      iss: '',
      getAccessTokenPayLoad: {},
      bearerToken: '',
      userId: '',
      token: '',
      userDetails: {
        userName: '',
        mail: '',
        telephoneNumber: '',
        givenName: '',
        sn: '',
        _refResourceId: '',
        effectiveRoles: [],
      },
      NotificationAlets: 0,
      notificationsArray: [],
      socialPayLoad: {},
      admin: true,
      idProofing: 'false',
      idProofingStatus: '0',
      roles: [],
      showIdPlusPopup: false,
      showIdproofingFailedPopup: false,
      iPlanetDirectoryPro: null,
      allowed: false,
      show: false,
      idType: '',
      vendor: '',
      docType: '',
      idProofData: '',
      code: '',
      csrf: '',
      state: '',
      email: '',
      consent: '',
      referrerUrl: '',
      showCustomWallet: false,
      walletRegistered : false,
      gotoHome:false,
      consentChoices: ['First Name' , 'Last Name' , 'Country' , 'Gender' , 'Date of Birth' , 'ID No.'],
      consentChoicesAttribute: ['firstName' , 'lastName' , 'country' , 'gender' , 'dOb' , 'docID'],
      checkedItems : new Array(6).fill(false),
      userGrants : [],
    };
  }

  getAPIHeaders = () => {
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    return {
      iPlanetdirectorypro: iPlanetDirectoryPro,
      'Content-Type': 'application/json',
    };
  };

  getNotification = () => {
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    http({
      url: 'getNotification',
      method: 'POST',
      data: { iPlanetDirectoryPro: iPlanetDirectoryPro },
    }).then((res) => {
      this.setState({
        notificationsArray: res.data.data?._notifications,
        notificationAlerts: res.data.data?._notifications.length,
      });
    });
  };

  getUser = () => {
    let username = Cookies.get('username');
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    if (iPlanetDirectoryPro == undefined) {
      window.location.href = '/';
    }
    let data = {
      pattern: username,
      attribute: 'userName',
      operation: 'eq',
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http({
      url: '/aegis-managed-service/aegis/api/searchUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 /*&&
        res.data.iamcode == 'IAM0028' &&
        res.data.response == 'Access Denied'*/
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = sanitizeUrl('/');
      } else if (res.data.data.result[0].documentReqested) {
        const idTypeData = res.data.data.result[0].documentReqested
          .split('|')
          .map((item) => JSON.parse(item));

        this.setState({
          allowed: true,
          show: true,

          // set docType and idProofData according to need
          docType: res.data.data.result[0].documentReqested,
          idProofData: res.data.data.result[0].idProofData,
          idType: idTypeData[0].idType,
          email: idTypeData[0].email,
          vendor: idTypeData[0].vendor,
        });
      }

      if (res.data.data.result[0].idProofData) {
        Cookies.set('walletRegistered', true);
        this.setState({walletRegistered:true});
      } else {
        Cookies.set('walletRegistered', false);
      }

      this.setState({gotoHome:true});
      let data = res.data.data.result[0];

      const { setUserDetails } = this.props;
      Cookies.set('username', data.userName);
      Cookies.set('user_id', data._id);
      this.setState({roles:JSON.stringify(data.roles)});
      Cookies.set('preferredLanguage', data.preferredLanguage);
      setUserDetails(data);
      if (Cookies.get('socialLogin')) {
        // localStorage.setItem('email', res.data.mail);
        Cookies.set('email', data.mail);
      } else {
        // localStorage.removeItem('email');
        Cookies.remove('email');
      }

      this.setState({
        userDetails: data,
      });

      if (
        data.preferredLanguage === 'en' ||
        data.preferredLanguage === '' ||
        data.preferredLanguage === null ||
        data.preferredLanguage === undefined
      ) {
        // localStorage.setItem('ctsessionlanguage', 'en_US');
        localStorage.setItem('ctsessionlanguage', 'en_US');
        Cookies.set('ctsessionlanguage', 'en_US');
        this.getNotification();
        i18n.changeLanguage('en_US');
        i18n.changeLanguage('en_US');
      } else {
        // localStorage.setItem('ctsessionlanguage', 'es');
        Cookies.set('ctsessionlanguage', 'es');
        i18n.changeLanguage(data.preferredLanguage);
      }

      // store name for chatbot use
      // localStorage.setItem('firstName', res.data.givenName);
      Cookies.set('firstName', data.givenName);
      // localStorage.setItem('lastName', res.data.sn);
      Cookies.set('lastName', data.sn);

      if ('idProofing' in data) {
        Cookies.set('idProofingStatus', data.idProofing);
        this.setState({
          idProofingStatus: data.idProofing,
        });
        if (
          data.idProofing === '-1' ||
          data.idProofing === '-2' ||
          data.idProofing === '-3'
        ) {
          // Cookies.set('idProofingStatus', data.idProofing);
          this.setState({ showIdPlusPopup: true });
        } else if (data.idProofing === '-4') {
          // Cookies.set('idProofingStatus', data.idProofing);
          this.setState({ showIdproofingFailedPopup: true });
        } else if (data.idProofing === '1' || data.idProofing === '2') {
          // Cookies.set('idProofingStatus', data.idProofing);
          this.setState({ showIdPlusPopup: false, idProofing: 'true' });
        } else if (
          data.idProofing === '0' &&
          Cookies.get('firstLoginSession') === 'true'
        ) {
          fetch(
            'https://sandbox.socure.com/api/3.0/EmailAuthScore?forcerefresh=true',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization:
                  'SocureApiKey f309518e-3289-4e36-bd17-c405d8811fb8',
              },
              body: JSON.stringify({
                modules: [
                  'fraud',
                  'phonerisk',
                  'emailrisk',
                  'addressrisk',
                  'alertlist',
                  'kyc',
                  'watchliststandard',
                ],
                firstName: 'Muhammad',
                surName: 'Davi',
                mobileNumber: '12523285808',
                physicalAddress: '521 west 141st Street',
                city: 'New York',
                state: 'NY',
                zip: '10031',
                country: 'US',
                ipAddress: '172.89.23.172',
                nationalId: '425435128',
                dob: '1980-08-11',
                email: 'travis.stevenson1588@gmail.com',
              }),
            }
          )
            .then((res) => {
              if (res.status === 200) {
                if (Cookies.get('firstName') === 'Idplusnegaitve') {
                  this.setState({ showIdPlusPopup: true });
                  const user_id = Cookies.get('user_id');
                  const dataStoreToken = Cookies.get('dataStoreToken');
                  let bearer = 'Bearer ';
                  let userId = user_id;
                  const payload = [
                    {
                      operation: 'add',
                      field: '/idProofing',
                      value: '-1',
                    },
                  ];
                } else {
                  const user_id = Cookies.get('user_id');
                  const dataStoreToken = Cookies.get('dataStoreToken');
                  let bearer = 'Bearer ';
                  let userId = user_id;
                  const payload = [
                    {
                      operation: 'add',
                      field: '/idProofing',
                      value: '1',
                    },
                  ];
                }
              }
              return res.json();
            })
            .then((data) => {
              console.log('The Address risk scores is ');
              console.log(data.addressRisk.score);
            })
            .catch((error) => console.log('ERROR - ', error));
          Cookies.set('firstLoginSession', false);
        }
      } else if (
        !('idProofing' in res.data) &&
        Cookies.get('firstLoginSession') === 'false'
      ) {
        console.log('This is not the first time user is logged in');
      }
    });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.userGrantedAccess();
    this.setState({
      show: false,
      allowed: false,
    });

    const consentData = {
      consent: this.state.userGrants,
      decision: e.target.value,
      documentRequired: this.state.idType,
    };

    const consentPayload = [
      {
        operation: 'add',
        field: '/consentProvided',
        value: JSON.stringify(consentData),
      },
      {
        operation: 'remove',
        field: '/documentReqested',
      },
    ];

    if (e) {
      http(
        {
          url: `updateSelfProfile`,
          method: 'PATCH',
          data: consentPayload,
        },
        { ...this.getAPIHeaders() },
      ).then((res) => {
        if (res.status === 200) {
          console.log('data sent');
        }
      });
    }
  };

  getAPICORSheaders = (bearer, token) => {
    return {
      Authorization: `${bearer}${token}`,
    };
  };
  closePopup = (e) => {
    if (this.state.idProofing === 'false') {
      this.setState({ showIdPlusPopup: true });
    }
  };
  closeIdProofingPopup = (e) => {
    this.setState({ showIdPlusPopup: false });
  };

  /*getUserDetails = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';
    http(
      {
        url: `managed/user/${id}?&_fields=*`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        const { setUserDetails } = this.props;
        Cookies.set('userNameLogin', res.data.userName);
        Cookies.set('userName', res.data.userName);
        setUserDetails(res.data);
        // if (localStorage.getItem('socialLogin')) {
        if (Cookies.get('socialLogin')) {
          // localStorage.setItem('email', res.data.mail);
          Cookies.set('email', res.data.mail);
        } else {
          // localStorage.removeItem('email');
          Cookies.remove('email');
        }

        this.setState({
          userDetails: res.data,
        });

        // store name for chatbot use
        // localStorage.setItem('firstName', res.data.givenName);
        Cookies.set('firstName', res.data.givenName);
        // localStorage.setItem('lastName', res.data.sn);
        Cookies.set('lastName', res.data.sn);

        // for (let i = 0; i < res.data.effectiveRoles.length; i++) {
        //   let item = res.data.effectiveRoles[i];

        //   if (
        //     ('name' in item &&
        //       item.name === process.env.REACT_APP_ADMIN_NAME &&
        //       item._id == process.env.REACT_APP_ADMIN_ID) ||
        //     (item.name === process.env.REACT_APP_UI_NAME &&
        //       item._id == process.env.REACT_APP_UI_ID)
        //   ) {
        //     this.setState({
        //       admin: true,
        //     });
        //     break;
        //   }
        // }

        if (
          res.data.preferredLanguage === 'en' ||
          res.data.preferredLanguage === '' ||
          res.data.preferredLanguage === null ||
          res.data.preferredLanguage === undefined
        ) {
          // localStorage.setItem('ctsessionlanguage', 'en_US');
          localStorage.setItem('ctsessionlanguage', 'en_US');
          Cookies.set('ctsessionlanguage', 'en_US');
          i18n.changeLanguage('en_US');
          i18n.changeLanguage('en_US');
        } else {
          // localStorage.setItem('ctsessionlanguage', 'es');
          Cookies.set('ctsessionlanguage', 'es');
          i18n.changeLanguage(res.data.preferredLanguage);
        }
        // if ('idProofing' in res.data) {
        //   this.setState({ idProofing: JSON.stringify(res.data.idProofing) });
        // }
        // if ('idProofing' in res.data) {
        //   this.setState({
        //     idProofingStatus: JSON.stringify(res.data.idProofing),
        //   });
        //   Cookies.set('firstLoginSession', false);
        //   if (
        //     res.data.idProofing === '-1' ||
        //     res.data.idProofing === '-2' ||
        //     res.data.idProofing === '-3'
        //   ) {
        //     Cookies.set('idProofingStatus', res.data.idProofing);
        //     this.setState({ showIdPlusPopup: true });
        //   }
        //   if (res.data.idProofing === '-4') {
        //     Cookies.set('idProofingStatus', res.data.idProofing);
        //     this.setState({ showIdproofingFailedPopup: true });
        //   }
        //   if (res.data.idProofing === '1') {
        //     Cookies.set('idProofingStatus', '1');
        //     this.setState({ showIdPlusPopup: false });
        //   }
        // } else if (
        //   !('idProofing' in res.data) &&
        //   Cookies.get('firstLoginSession') === 'true'
        // ) {
        //   fetch(
        //     'https://sandbox.socure.com/api/3.0/EmailAuthScore?forcerefresh=true',
        //     {
        //       method: 'POST',
        //       headers: {
        //         'Content-Type': 'application/json',
        //         Authorization:
        //           'SocureApiKey f309518e-3289-4e36-bd17-c405d8811fb8',
        //       },
        //       body: JSON.stringify({
        //         modules: [
        //           'fraud',
        //           'phonerisk',
        //           'emailrisk',
        //           'addressrisk',
        //           'alertlist',
        //           'kyc',
        //           'watchliststandard',
        //         ],
        //         firstName: 'Muhammad',
        //         surName: 'Davi',
        //         mobileNumber: '12523285808',
        //         physicalAddress: '521 west 141st Street',
        //         city: 'New York',
        //         state: 'NY',
        //         zip: '10031',
        //         country: 'US',
        //         ipAddress: '172.89.23.172',
        //         nationalId: '425435128',
        //         dob: '1980-08-11',
        //         email: 'travis.stevenson1588@gmail.com',
        //       }),
        //     }
        //   )
        //     .then((res) => {
        //       if (res.status === 200) {
        //         if (Cookies.get('firstName') === 'Idplusnegaitve') {
        //           this.setState({ showIdPlusPopup: true });
        //           const user_id = Cookies.get('user_id');
        //           const dataStoreToken = Cookies.get('dataStoreToken');
        //           let bearer = 'Bearer ';
        //           let userId = user_id;
        //           const payload = [
        //             {
        //               operation: 'replace',
        //               field: '/idProofing',
        //               value: '-1',
        //             },
        //           ];
        //           http(
        //             {
        //               url: `managed/user/${userId}`,
        //               method: 'PATCH',
        //               data: payload,
        //             },
        //             {
        //               'X-OpenIDM-NoSession': false,
        //               'X-OpenIDM-OAuth-Login': true,
        //               'X-Requested-With': 'XMLHttpRequest',
        //               Authorization: `${bearer}${dataStoreToken}`,
        //             }
        //           ).then((res) => {
        //             if (res && res.status === 200 && res.data) {
        //               Cookies.set('idProofing', '-1');
        //             } else if (res.status === 401) {
        //               this.showError('pages.idproofing.sessionExpiry');
        //               setTimeout(() => {
        //                 window.location.href = '/';
        //               }, 3000);
        //             }
        //           });
        //         } else {
        //           const user_id = Cookies.get('user_id');
        //           const dataStoreToken = Cookies.get('dataStoreToken');
        //           let bearer = 'Bearer ';
        //           let userId = user_id;
        //           const payload = [
        //             {
        //               operation: 'replace',
        //               field: '/idProofing',
        //               value: '1',
        //             },
        //           ];
        //           http(
        //             {
        //               url: `managed/user/${userId}`,
        //               method: 'PATCH',
        //               data: payload,
        //             },
        //             {
        //               'X-OpenIDM-NoSession': false,
        //               'X-OpenIDM-OAuth-Login': true,
        //               'X-Requested-With': 'XMLHttpRequest',
        //               Authorization: `${bearer}${dataStoreToken}`,
        //             }
        //           ).then((res) => {
        //             if (res && res.status === 200 && res.data) {
        //               Cookies.set('idProofingStatus', '1');
        //             } else if (res.status === 401) {
        //               this.showError('pages.idproofing.sessionExpiry');
        //               setTimeout(() => {
        //                 window.location.href = '/';
        //               }, 3000);
        //             }
        //           });
        //         }
        //       }
        //       return res.json();
        //     })
        //     .then((data) => {
        //       console.log('The Address risk scores is ');
        //       console.log(data.addressRisk.score);
        //       console.log(data);
        //     })
        //     .catch((error) => console.log('ERROR - ', error));
        //   Cookies.set('firstLoginSession', false);
        // } else if (
        //   !('idProofing' in res.data) &&
        //   Cookies.get('firstLoginSession') === 'false'
        // ) {
        //   console.log('This is not the first time user is logged in');
        // }
      }
    });
  };*/

  isLooggedIn = () => {
    http(
      {
        url: 'sessions?_action=getSessionInfo',
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.0',
      }
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (Cookies.get('dashboardRequired') == 'customWallet') {
      this.setState({ showCustomWallet: true });
    }

    const isRedirectLogin = document.referrer.includes('original_request_url');
    const isRedirectLogin2 = document.referrer.includes('goto');

    // checking if redirection is from dashboard or other pages. It is done cause UIB wants to open aegis account page.

    let redir = window.location.href;
    if (isRedirectLogin && redir == process.env.REACT_APP_LOGIN) {
      const url = document.referrer.indexOf('original_request_url');
      if (url !== -1) {
        const referrerUrls = document.referrer.substring(url + 21);
        window.location.href = decodeURIComponent(referrerUrls);
      }
    } else if(isRedirectLogin2 && redir == process.env.REACT_APP_LOGIN){
      let windowURL = document.referrer;
      const urls = windowURL.split("goto=");
      window.location.href = urls[1];

    }else if (Cookies.get('referer') && redir == process.env.REACT_APP_LOGIN) {
      window.location.href = Cookies.get('referer');
    }

    this.getUser();
  }
  componentDidUpdate(prop) {
    if (window.location.pathname !== prop.location.pathname) {
      this.props.setHasRouteChanged(true);
      this.getUser();
    } else {
      this.props.setHasRouteChanged(false);
    }
  }

  handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...this.state.checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    this.setState({ checkedItems: updatedCheckedItems });
  };

  userGrantedAccess = () =>{

    for(let i = 0; i<6;i++){
      if(this.state.checkedItems[i]!=false){
        this.state.userGrants.push(this.state.consentChoicesAttribute[i]);
      }
    }
  };

  render() {
    const {
      match: {
        params: { pageName },
      },
      setUserDetails,
    } = this.props;
    const {
      userDetails,
      notificationAlerts,
      notificationsArray,
      admin,
      idProofing,
      roles,
      showIdPlusPopup,
      showIdproofingFailedPopup,
      allowed,
      show,
      idType,
      vendor,
      showCustomWallet,
      walletRegistered,
      gotoHome,
      consentChoices,
      checkedItems,
    } = this.state;

    return (
      <>
        {allowed && (
          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Authorization Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {vendor} wants to access your {idType}. Please select details you want to share<br></br><br></br>
            <form>
              {[...Array(6).keys()].map((index) => (
                <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={checkedItems[index]}
                    onChange={() => this.handleCheckboxChange(index)}
                  />{" "}
                   {consentChoices[index]}
                </label>
                <br></br>
                </div>
              ))}
            </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                value="allow"
                onClick={(e) => this.handleClose(e)}
              >
                Allow
              </Button>
              <Button
                variant="secondary"
                value="deny"
                onClick={(e) => this.handleClose(e)}
              >
                Deny
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {pageName !== 'SocialLogin' && (
          <>
            {showIdPlusPopup && (
              <IdProofingPopup closePopup={this.closeIdProofingPopup} />
            )}
            {showIdproofingFailedPopup && <IdProofingBlockedPopup />}
            <MainMenu
              pageName={pageName || ''}
              notificationAlerts={notificationAlerts}
              admin={this.state.admin}
            />
            <div className="dashboard">
              {!pageName ||
                (pageName === 'Dashboard' && gotoHome && (
                  <Home
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    idProofing={idProofing}
                    walletRegistered={walletRegistered}
                  />
                ))}
              {!pageName ||
                (pageName === 'Apps' && (
                  <Apps
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                  />
                ))}

              {/*{!pageName ||
                (pageName === 'Admin' && (
                  <Admin
                    
                    userDetails={userDetails}
                    
                  />
                ))}*/}
              {!pageName ||
                ((pageName === 'Admin' ||
                  pageName === 'AdminApp' ||
                  pageName === 'BusinessApprovals') && (
                  <AdminAccount
                    pageName={pageName || ''}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    roles={roles}
                  />
                ))}

              {!pageName || (pageName === 'IDs' && <IDs />)}

              {showCustomWallet && pageName === 'Wallet' && <CustomWallet />}
              {!showCustomWallet && pageName === 'Wallet' && <Wallet />}

              {!pageName ||
                (pageName === 'Notifications' && (
                  <Notifications notificationsArray={notificationsArray} />
                ))}
              {!pageName ||
                ((pageName === 'personalInformation' ||
                  pageName === 'Account' ||
                  pageName === 'changePassword' ||
                  pageName === 'CommunicationPreferences' ||
                  pageName === 'AuthorizedApps' ||
                  pageName === 'PeopleSharing' ||
                  pageName === 'PrivacyConsent' ||
                  pageName === 'ActivityLog' ||
                  pageName === 'AccountManagement' ||
                  pageName === 'SiteSettings' ||
                  pageName === 'Devices') && (
                  <Account
                    pageName={pageName || ''}
                    setUserDetails={setUserDetails}
                    userDetails={userDetails}
                  />
                ))}
            </div>
          </>
        )}
      </>
    );
  }
}
Dashboard.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  setHasRouteChanged: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withTranslation()(Dashboard);
