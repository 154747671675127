import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Registration/Registration.scss';
import Form from 'react-bootstrap/Form';
import { Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import {
  isPasswordFormatValid,
  preventNonDigitCharacters,
  formatPhoneNumber,
} from 'utils/common';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import Select from 'react-select';
import http from 'api/http-request';
import Alert from 'app/modules/Shared/Alert/Alert';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import InputGroup from 'react-bootstrap/InputGroup';

const CryptoJS = require('crypto-js');

class Registration extends Component {
  constructor(props) {
    super(props);
    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.verificationInputRef = React.createRef();
    this.telephoneNumberInputRef = React.createRef();
    this.state = {
      password: '',
      confirmPassword: '',
      screen: 0,
      verificationPlaceholder: ['0', '0', '0', '0', '0', '0', '0', '0'],
      currentStep: 0,
      stepNames: [
        'pages.formNavigator.personalInfo',
        'pages.formNavigator.securityQuestion',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification',
      ],
      maxStep: 3,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      givenName: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      sn: '',
      userName: '',
      mail: '',
      confirmEmail: '',
      confirmPasswordError: '',
      passwordError: '',
      formSubmitted: false,
      errorText: '',
      errorArray: [],
      questionVals: [null, null, null],
      token: '',
      telephoneNumber: '',
      telephoneNumberUSFormatted: '',
      numberFocus: false,
      filterOptions: [],
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      telephoneNumberFormatted: '',
      preferredLanguage: 'en',
      copyEmailToUsername: false,
      responseData: {},
      capthaData: {},
      OTPVerificationData: {},
      receivedOTPdata: {},
      showDropDown: true,
      showDropDownTwo: true,
      showDropDownThree: true,
      customQuestion: '',
      currentOption: null,
      currentIndex: 0,
      invalidOTP: '',
    };
  }

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  mapQuestionDictToArray = (questionDict) => {
    let filterOptions = Object.keys(questionDict).map(function (k) {
      let dict = {};

      dict['value'] = k;

      dict['label'] = questionDict[k];
      dict['translations'] = questionDict[k];
      return dict;
    });

    return filterOptions;
  };

  getFirstResponse = () => {
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(this.state.password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });

    this.setState({
      errorText: '',
    });

    const formData = {
      'User Name': this.state.userName,
      'First Name': this.state.givenName,
      'Last Name': this.state.sn,
      Email: this.state.mail,
      'Telephone Number': this.state.telephoneNumber
        ? this.state.telephoneNumber
        : null,
      Password: encrypted.toString(),
    };
    http(
      {
        url: 'customerSelfRegistration',
        data: formData,
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (
        res.status === 200 &&
        res.data &&
        res.data.response === 'success' &&
        res.data.isUserExists === 'false'
      ) {
        const questions = res.data.data.callbacks[0].output[1].value;
        let filterOptionsFromServer = this.mapQuestionDictToArray(questions);
        this.setState({
          filterOptions: filterOptionsFromServer,
        });
        this.setState(
          {
            responseData: res.data,
          },
          () => {
            this.increaseCurrentStepHandler();
            this.setState({
              screen: 1,
              errorArray: '',
            });
          }
        );
      } else if (
        res.status === 200 &&
        res.data &&
        res.data.response === 'success' &&
        res.data.isUserExists === 'true'
      ) {
        this.showError('pages.registration.userAlreadyExist');
        this.scrollToTop();
      } else if (res.status === 400 && res.data.iamcode == 'IAM0055') {
        this.scrollToTop();
        this.showError(res.data.data.Message.errorMessage);
      } else {
        this.scrollToTop();
        this.showError('pages.registration.internalServerError');
      }
    });
  };

  // checkUsername = () => {
  //   const {
  //     givenName,
  //     sn,
  //     userName,
  //     mail,
  //     password,
  //     telephoneNumber,
  //     preferredLanguage,
  //   } = this.state;

  //   this.setState({
  //     errorText: '',
  //   });

  //   let payLoad = {};
  //   if (telephoneNumber) {
  //     payLoad = {
  //       givenName,
  //       sn,
  //       userName,
  //       mail,
  //       password,
  //       telephoneNumber: '+1' + telephoneNumber.replace(/[^A-Z0-9]/gi, ''),
  //       preferredLanguage,
  //       // preferences: {},
  //     };
  //     this.setState({
  //       telephoneNumberFormatted: payLoad.telephoneNumber,
  //     });
  //   } else {
  //     payLoad = {
  //       givenName,
  //       sn,
  //       userName,
  //       mail,
  //       password,
  //       preferredLanguage,
  //       // preferences: {},
  //     };
  //   }
  //   http(
  //     {
  //       url: `policy/managed/user/test?_action=validateObject`,
  //       data: payLoad,
  //       method: 'POST',
  //     },
  //     {
  //       'X-OpenIDM-NoSession': true,
  //       'X-OpenIDM-Username': 'anonymous',
  //       'X-OpenIDM-Password': 'anonymous',
  //     }
  //   ).then((res) => {
  //     const failedPolicyRequirements = getNestedObject(res, [
  //       'data',
  //       'failedPolicyRequirements',
  //     ]);
  //     if (failedPolicyRequirements && failedPolicyRequirements.length > 0) {
  //       this.setState({ isUserExists userAlreadyExist
  //         errorArray: failedPolicyRequirements,
  //       });
  //       this.scrollToTop();
  //     } else if (
  //       res.status === 200 &&
  //       res.data &&
  //       res.data.failedPolicyRequirements.length === 0 &&
  //       res.data.result === true
  //     ) {
  //       //this.getRegistrationToken();
  //       this.increaseCurrentStepHandler();
  //       this.setState({
  //         screen: 1,
  //         errorArray: '',
  //       });
  //     } else if (
  //       res.status === 500 &&
  //       res.data &&
  //       res.data.reason === 'Internal Server Error'
  //     ) {
  //       this.scrollToTop();
  //       this.showError('pages.registration.internalServerError');
  //     }
  //   });
  // };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      formSubmitted: true,
    });
    const {
      givenName,
      sn,
      userName,
      mail,
      confirmEmail,
      password,
      confirmPassword,
      telephoneNumber,
    } = this.state;
    const hasGivenName = this.isFormFieldValid(givenName, 'givenNameError');
    const hasSn = this.isFormFieldValid(sn, 'snError');
    const hasUserName = this.isFormFieldValid(userName, 'userNameError');
    const hasMail = this.isFormFieldValid(mail, 'emailError');
    const hasConfirmMail = this.isFormFieldValid(
      confirmEmail,
      'confirmEmailError'
    );
    const hasPassword = this.isFormFieldValid(password, 'passwordError');
    const hasConfirmPassword = this.isFormFieldValid(
      confirmPassword,
      'confirmPasswordError'
    );
    if (
      hasGivenName ||
      hasSn ||
      hasUserName ||
      hasMail ||
      hasConfirmMail ||
      hasPassword ||
      hasConfirmPassword
    ) {
      this.showError('errors.required');
    } else {
      if (telephoneNumber !== '') {
        const currentValue = this.state.telephoneNumber;
        this.setState((prevState) => ({
          ...prevState,
          telephoneNumber: formatPhoneNumber(
            currentValue,
            prevState.telephoneNumber
          ).replace(/[^A-Z0-9]/gi, ''),
        }));
      } else if (telephoneNumber === '') {
        // this.checkUsername();
      } else {
        this.setState({
          telephoneNumberError: 'pages.registration.mobileNumberInvalid',
        });
        this.showError('pages.registration.mobileNumberInvalid');
      }
    }
    if (!hasConfirmPassword) {
      this.getFirstResponse();
    }
  };

  increaseCurrentStepHandler = () => {
    this.scrollToTop();
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  onVerify = () => {
    const {
      givenName,
      sn,
      userName,
      mail,
      password,
      answerOne,
      answerTwo,
      answerThree,
      questionVals,
      // token,
      telephoneNumberFormatted,
      // preferredLanguage,
      responseData,
    } = this.state;
    this.setState({
      errorText: '',
    });
    const hasAnswerOne = this.isFormFieldValid(answerOne, 'answerOneError');
    const hasAnswerTwo = this.isFormFieldValid(answerTwo, 'answerTwoError');
    const hasAnswerThree = this.isFormFieldValid(
      answerThree,
      'answerThreeError'
    );
    if (
      hasAnswerOne ||
      hasAnswerTwo ||
      hasAnswerThree ||
      !questionVals[0] ||
      !questionVals[1] ||
      !questionVals[2]
    ) {
      this.showError('errors.required');
    } else if (
      answerOne === answerTwo ||
      answerOne === answerThree ||
      answerTwo === answerThree
    ) {
      this.showError('errors.duplicateEntriesBoth');
    } else if (
      questionVals[0].label === questionVals[1].label ||
      questionVals[0].label === questionVals[2].label ||
      questionVals[1].label === questionVals[2].label
    ) {
      this.showError('errors.duplicateEntriesBoth');
    } else {
      let updatedPayload = {};
      if (telephoneNumberFormatted) {
        updatedPayload = {
          userName: userName,
          givenName: givenName,
          sn: sn,
          mail: mail,
          password: password,
          telephoneNumber: telephoneNumberFormatted,
          preferredLanguage:
            // localStorage.getItem('ctsessionlanguage') === 'en_US' ? 'en' : 'es',
            Cookies.get('ctsessionlanguage') === 'en_US' ? 'en' : 'es',
        };
      } else {
        updatedPayload = {
          userName: userName,
          givenName: givenName,
          sn: sn,
          mail: mail,
          password: password,
          preferredLanguage:
            // localStorage.getItem('ctsessionlanguage') === 'en_US' ? 'en' : 'es',
            Cookies.get('ctsessionlanguage') === 'en_US' ? 'en' : 'es',
        };
      }

      const securityQuestionsData = {
        'User Name': userName,
        authId: responseData.data.authId,
        Question1: questionVals[0].label,
        Answer1: answerOne,
        Question2: questionVals[1].label,
        Answer2: answerTwo,
        Question3: questionVals[2].label,
        Answer3: answerThree,
      };

      http(
        {
          url: 'customerSelfRegistration',
          data: securityQuestionsData,
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data && res.data.response === 'success') {
          this.setState(
            {
              capthaData: res.data,
              showGoogleCaptcha: true,
              googleCaptchaToken: res.data.data.callbacks[0].output[0].value,
            },
            () => {
              this.increaseCurrentStepHandler();
              this.setState({
                screen: 4,
                errorArray: '',
              });
            }
          );
        } else {
          this.scrollToTop();
          this.showError('pages.registration.internalServerError');
        }
      });

      // http(
      //   {
      //     url: 'selfservice/registration?_action=submitRequirements',
      //     data: {
      //       input: {
      //         user: updatedPayload,
      //         kba: [
      //           {
      //             answer: answerOne,
      //             questionId: questionVals[0].value,
      //           },
      //           {
      //             answer: answerTwo,
      //             questionId: questionVals[1].value,
      //           },
      //           {
      //             answer: answerThree,
      //             questionId: questionVals[2].value,
      //           },
      //         ],
      //         accept: 'true',
      //       },
      //       token: token,
      //     },
      //     method: 'POST',
      //   },
      //   {
      //     'X-OpenIDM-NoSession': true,
      //     'X-OpenIDM-Username': 'anonymous',
      //     'X-OpenIDM-Password': 'anonymous',
      //   }
      // ).then((res) => {
      //   if (
      //     res.status === 200 &&
      //     res.data &&
      //     res.data.type === 'EmailValidation' &&
      //     res.data.tag === 'VALIDATE_CODE'
      //   ) {
      //     const token = getNestedObject(res, ['data', 'token']);
      //     if (token) {
      //       this.setState({
      //         token: token,
      //       });
      //     }
      //     this.setState({
      //       screen: 2,
      //       verificationOption:
      //         res.data.requirements.properties.verificationCode.description,
      //     });
      //   } else if (
      //     res.status === 200 &&
      //     res.data &&
      //     res.data.type === 'allInOneRegistration' &&
      //     res.data.tag === 'initial'
      //   ) {
      //     const token = getNestedObject(res, ['data', 'token']);
      //     if (token) {
      //       this.setState({
      //         token: token,
      //       });
      //     }
      //     const recaptchaSiteKey = getNestedObject(res, [
      //       'data',
      //       'requirements',
      //       'properties',
      //       'response',
      //       'recaptchaSiteKey',
      //     ]);
      //     if (recaptchaSiteKey) {
      //       this.setState({
      //         showGoogleCaptcha: true,
      //         googleCaptchaToken: recaptchaSiteKey,
      //         screen: 4,
      //       });
      //       this.increaseCurrentStepHandler();
      //     }
      //   }
      //   //this.increaseCurrentStepHandler();
      // });
    }
  };

  captchaSubmit = () => {
    const { googleCaptchaResponse, capthaData, userName } = this.state;
    this.setState({
      errorText: '',
    });

    const googleCaptchaData = {
      'User Name': userName,
      authId: capthaData.data.authId,
      RecaptchaValue: googleCaptchaResponse,
    };

    http(
      {
        url: 'customerSelfRegistration',
        data: googleCaptchaData,
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data.response === 'success') {
        this.setState(
          {
            OTPVerificationData: res.data,
            showGoogleCaptcha: false,
            authID: res.data.data.authId,
            userName: userName,
          },
          () => {
            this.increaseCurrentStepHandler();
            this.setState({
              screen: 2,
              errorArray: '',
              verificationCodeError: '',
            });
          }
        );
      } else {
        this.scrollToTop();
        this.showError('pages.registration.internalServerError');
      }
    });

    // http(
    //   {
    //     url: 'selfservice/registration?_action=submitRequirements',
    //     data: {
    //       input: {
    //         response: googleCaptchaResponse,
    //         'g-recaptcha-response': googleCaptchaResponse,
    //       },
    //       token: token,
    //     },
    //     method: 'POST',
    //   },
    //   {
    //     'X-OpenIDM-NoSession': true,
    //     'X-OpenIDM-Username': 'anonymous',
    //     'X-OpenIDM-Password': 'anonymous',
    //   }
    // ).then((res) => {
    //   if (
    //     res.status === 200 &&
    //     res.data &&
    //     res.data.type === 'EmailValidation' &&
    //     res.data.tag === 'VALIDATE_CODE'
    //   ) {
    //     const token = getNestedObject(res, ['data', 'token']);
    //     if (token) {
    //       this.setState({
    //         token: token,
    //       });
    //     }
    //     this.setState({
    //       screen: 2,
    //       verificationOption:
    //         res.data.requirements.properties.verificationCode.description,
    //       showGoogleCaptcha: false,
    //     });
    //     this.increaseCurrentStepHandler();
    //   }
    // });
  };
  copyEmailToUsername(e) {
    this.setState(
      (prevState) => {
        return {
          copyEmailToUsername: !prevState.copyEmailToUsername,
        };
      },
      () => {
        let { copyEmailToUsername, mail } = this.state;
        if (copyEmailToUsername) {
          this.setState({
            userName: mail,
            userNameError: '',
          });
        } else {
          this.setState({
            userName: '',
          });
        }
      }
    );
  }
  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 3000);
        }
      );
      this.scrollToTop();
    }
  };

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    const { token, authID, verificationCode, userName, sendOTPData } =
      this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
        invalidOTP: '',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });

      const sendOTPData = {
        'User Name': userName,
        authId: authID,
        OTPChoice: 'Validate',
      };

      http(
        {
          url: 'customerSelfRegistration',
          data: sendOTPData,
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data && res.data.response === 'success') {
          this.setState(
            {
              receivedOTPdata: res.data,
            },
            () => {}
          );

          const OneTimePassword = {
            'User Name': userName,
            authId: res.data.data.authId,
            'One Time Password': verificationCode,
          };

          http(
            {
              url: 'customerSelfRegistration',
              data: OneTimePassword,
              method: 'POST',
            },
            {
              'Accept-API-Version': 'protocol=1.0,resource=2.1',
            }
          ).then((res) => {
            if (
              res.status === 200 &&
              res.data &&
              res.data.response === 'success' &&
              !res.data.data.callbacks
            ) {
              this.setState({
                screen: 3,
              });
              ReactGA.event({
                category: 'Registeration',
                action: 'Success: Registeration Verification',
              });
            } else if (
              res.status === 200 &&
              res.data &&
              res.data.response === 'success' &&
              res.data.data.callbacks
            ) {
              this.setState({
                authID: res.data.data.authId,
                invalidOTP: 'pages.forgotUserId.invalidOTP',
                verificationCodeError: '',
              });
            } else if (res.status == 401 && res.data.iamcode == 'IAM0039') {
              this.showError('errors.maxVerifyAttempt');
              setTimeout(() => {
                window.location.href = '/';
              }, 3000);
            } else if (
              res.status === 400 &&
              res.data &&
              res.data.code === 400 &&
              res.data.message === 'MAX_RETRY_EXCEEDED'
            ) {
              this.setState({
                screen: 0,
                currentStep: 0,
                password: '',
                confirmPassword: '',
                googleCaptchaResponse: '',
                captchaReset: false,
                givenName: '',
                answerOne: '',
                answerTwo: '',
                answerThree: '',
                sn: '',
                userName: '',
                mail: '',
                confirmEmail: '',
                confirmPasswordError: '',
                passwordError: '',
                formSubmitted: false,
                errorText: '',
                errorArray: [],
                token: '',
                telephoneNumber: '',
                telephoneNumberUSFormatted: '',
                verificationCode: '',
                verificationOption: '',
                verificationCodeError: '',
                questionVals: [null, null, null],
              });
              this.onErrorHandler('error', res.data.message);
              ReactGA.event({
                category: 'Registeration',
                action:
                  'Failure: Registeration Verification: ' + res.data.message,
              });
            } else {
              this.scrollToTop();
              this.showError('pages.registration.internalServerError');
            }
          });
        } else {
          this.scrollToTop();
          this.showError('pages.registration.internalServerError');
        }
      });
      /*  http(
        {
          url: 'selfservice/registration?_action=submitRequirements',
          data: {
            input: {
              verificationCode: verificationCode,
            },
            token: token,
          },
          method: 'POST',
        },
        {
          'X-OpenIDM-NoSession': true,
          'X-OpenIDM-Username': 'anonymous',
          'X-OpenIDM-Password': 'anonymous',
        }
      ).then((res) => {
        if (
          res.status === 200 &&
          res.data &&
          res.data.type === 'EmailValidation' &&
          res.data.tag === 'VALIDATE_CODE'
        ) {
          this.setState({
            verificationCodeError: 'pages.forgotUserId.invalidVerificationCode',
          });
          const token = getNestedObject(res, ['data', 'token']);
          if (token) {
            this.setState({
              token: token,
            });
          }
        } else if (
          res.status === 200 &&
          res.data &&
          res.data.type === 'localAutoLogin' &&
          res.data.tag === 'end'
        ) {
          this.setState({
            screen: 3,
          });

          ReactGA.event({
            category: 'Registeration',
            action: 'Success: Registeration Verification',
          });
        } else if (
          res.status === 400 &&
          res.data &&
          res.data.code === 400 &&
          res.data.message === 'MAX_RETRY_EXCEEDED'
        ) {
          this.setState({
            screen: 0,
            currentStep: 0,
            password: '',
            confirmPassword: '',
            googleCaptchaResponse: '',
            captchaReset: false,
            givenName: '',
            answerOne: '',
            answerTwo: '',
            answerThree: '',
            sn: '',
            userName: '',
            mail: '',
            confirmEmail: '',
            confirmPasswordError: '',
            passwordError: '',
            formSubmitted: false,
            errorText: '',
            errorArray: [],
            token: '',
            telephoneNumber: '',
            telephoneNumberUSFormatted: '',
            verificationCode: '',
            verificationOption: '',
            verificationCodeError: '',
            questionVals: [null, null, null],
          });
          this.onErrorHandler('error', res.data.message);
          ReactGA.event({
            category: 'Registeration',
            action: 'Failure: Registeration Verification: ' + res.data.message,
          });
          this.getToken();
        }
      }); */
    }
  };

  disableCopyPaste = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
      invalidOTP: '',
    });
    // const { token } = this.state;
    this.setState({
      errorText: '',
    });

    const resendOTPData = {
      'User Name': this.state.userName,
      authId: this.state.authID,
      OTPChoice: 'Resend',
    };

    http(
      {
        url: 'customerSelfRegistration',
        data: resendOTPData,
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data.response === 'success') {
        this.setState(
          {
            authID: res.data.data.authId,
            showGoogleCaptcha: false,
          },
          () => {
            this.increaseCurrentStepHandler();
            this.setState({
              screen: 2,
              errorArray: '',
            });
          }
        );
      } else if (res.status == 401 && res.data.iamcode == 'IAM0039') {
        this.showError('errors.maxOTPAttempt');
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      } else {
        this.scrollToTop();
        this.showError('pages.registration.internalServerError');
      }
    });

    // http(
    //   {
    //     url: 'selfservice/registration?_action=submitRequirements',
    //     data: {
    //       input: {
    //         resend: 'true',
    //       },
    //       token: token,
    //     },
    //     method: 'POST',
    //   },
    //   {
    //     'X-OpenIDM-NoSession': true,
    //     'X-OpenIDM-Username': 'anonymous',
    //     'X-OpenIDM-Password': 'anonymous',
    //   }
    // ).then((res) => {
    //   if (res.status === 200 && res.data) {
    //     const token = getNestedObject(res, ['data', 'token']);
    //     if (token) {
    //       this.setState({
    //         token: token,
    //       });
    //     }
    //   } else if (
    //     res.status === 400 &&
    //     res.data &&
    //     res.data.code === 400 &&
    //     res.data.message === 'MAX_RESEND_EXCEEDED'
    //   ) {
    //     this.setState({
    //       screen: 0,
    //       currentStep: 0,
    //       password: '',
    //       confirmPassword: '',
    //       googleCaptchaResponse: '',
    //       captchaReset: false,
    //       givenName: '',
    //       answerOne: '',
    //       answerTwo: '',
    //       answerThree: '',
    //       sn: '',
    //       userName: '',
    //       mail: '',
    //       confirmEmail: '',
    //       confirmPasswordError: '',
    //       passwordError: '',
    //       formSubmitted: false,
    //       errorText: '',
    //       errorArray: [],
    //       token: '',
    //       telephoneNumber: '',
    //       telephoneNumberUSFormatted: '',
    //       verificationCode: '',
    //       verificationOption: '',
    //       verificationCodeError: '',
    //       questionVals: [null, null, null],
    //     });
    //     this.onErrorHandler('error', res.data.message);
    //     this.getToken();
    //   }
    // });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { mail, confirmEmail, password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'givenNameError':
        if (value.length <= 0) {
          errorMsg = 'errors.firstNameError';
        }
        break;
      case 'answerOneError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerOneError';
        }
        break;
      case 'answerTwoError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerTwoError';
        }
        break;
      case 'answerThreeError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerThreeError';
        }
        break;
      case 'snError':
        if (value.length <= 0) {
          errorMsg = 'errors.lastNameError';
        }
        break;
      case 'userNameError':
        if (value.length < 5 || value.length > 30) {
          errorMsg = 'errors.userNameLength';
        } else if (
          !value.match(/([a-zA-Z])*/) &&
          value.length >= 5 &&
          value.length <= 30
        ) {
          errorMsg = 'errors.userNameAtleastAlphabet';
        }
        break;
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      case 'confirmEmailError':
        if (value.length <= 0) {
          errorMsg = 'errors.confirmEmailError';
        } else if (mail !== confirmEmail) {
          errorMsg = 'errors.mailsNotMatchError';
        }
        break;
      case 'passwordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (password !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  decreaseCurrentStepHandler = () => {
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };

  sendToLogin = (e) => {
    e.preventDefault();
    if(Cookies.get('dashboardRequired')=='customWallet'){
      return this.props.history.push('/customWallet');
    } else{
      return this.props.history.push('/');
    }
  };

  onCaptchaChange = (value) => {
    if (value) {
      this.setState(
        {
          googleCaptchaResponse: value,
        },
        () => {
          this.captchaSubmit('validate');
        }
      );
    } else {
      this.setState({
        googleCaptchaResponse: '',
      });
    }
  };

  onFirstAndLastNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z '`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  onUserNameChange = (e, key) => {
    const value = e.target.value.trim();
    if (!value.match(/[\\+/""&'*%;, ]/g)) {
      this.setState({
        [key]: value,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    this.scrollToTop();
  };

  handleQuestionValChange = (option, index) => {
    const newQuestionVals = this.state.questionVals;

    if (option.value === 'Custom' && index === 0) {
      this.setState({
        showDropDown: false,
        answerOne: '',
      });
    } else if (option.value === 'Custom' && index === 1) {
      this.setState({
        showDropDownTwo: false,
        answerTwo: '',
      });
    } else if (option.value === 'Custom' && index === 2) {
      this.setState({
        showDropDownThree: false,
        answerThree: '',
      });
    }
    if (option.value != 'Custom') {
      newQuestionVals[index] = option;
      this.setState(() => {
        return {
          questionVals: newQuestionVals,
        };
      });
    } else {
      this.setState({
        currentOption: option,
        currentIndex: index,
      });
    }
  };

  setCustomQuestion = (e) => {
    e.preventDefault();
    const { currentIndex } = this.state;
    const newQuestionVals = this.state.questionVals;
    let customOption = this.state.currentOption;
    customOption = {
      ...customOption,
      label: this.state.customQuestion,
      value: currentIndex,
      translations: this.state.customQuestion,
    };
    newQuestionVals[currentIndex] = customOption;
    this.setState(() => {
      return {
        questionVals: newQuestionVals,
      };
    });
  };

  onChangeQuestion = (e, i) => {
    e.preventDefault();
    if (i === 0) {
      this.setState({
        showDropDown: true,
        answerOne: '',
      });
    } else if (i === 1) {
      this.setState({
        showDropDownTwo: true,
        answerTwo: '',
      });
    } else if (i === 2) {
      this.setState({
        showDropDownThree: true,
        answerThree: '',
      });
    }
  };

  getAvailableOptions = () => {
    const availableOptionsLeft = this.state.filterOptions;
    let optionsAvailable = availableOptionsLeft.filter((questionOption) => {
      return this.state.questionVals.indexOf(questionOption) === -1;
    });
    if (!this.state.questionVals.includes('Custom')) {
      optionsAvailable = [
        ...optionsAvailable,
        { label: 'Custom Question', value: 'Custom' },
      ];
      return optionsAvailable;
    } else {
      return optionsAvailable;
    }
  };

  onMobileNumberChange = (e) => {
    const currentValue = e.target.value.trim();
    this.setState((prevState) => ({
      ...prevState,
      telephoneNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
    }));
  };

  handleKeyPress = (e) => {
    preventNonDigitCharacters(e);
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 0,
      currentStep: 0,
      password: '',
      confirmPassword: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      givenName: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      sn: '',
      userName: '',
      mail: '',
      confirmEmail: '',
      confirmPasswordError: '',
      passwordError: '',
      formSubmitted: false,
      errorText: '',
      errorArray: [],
      token: '',
      telephoneNumber: '',
      telephoneNumberUSFormatted: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      questionVals: [null, null, null],
      copyEmailToUsername: false,
    });
    // this.getToken();
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  render() {
    const {
      currentStep,
      maxStep,
      stepNames,
      screen,
      givenName,
      givenNameError,
      sn,
      snError,
      userName,
      userNameError,
      mail,
      emailError,
      confirmEmail,
      confirmEmailError,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
      formSubmitted,
      errorText,
      answerOne,
      answerTwo,
      answerThree,
      answerOneError,
      answerTwoError,
      answerThreeError,
      telephoneNumber,
      telephoneNumberError,
      numberFocus,
      verificationOption,
      verificationCodeError,
      errorArray,
      verificationCode,
      alertText,
      alertStatus,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
      copyEmailToUsername,
      showDropDown,
      showDropDownTwo,
      showDropDownThree,
      invalidOTP,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="registration registration-form">
          {(errorText || errorArray.length > 0) && (
            <WithError errors={errorText} errorArray={errorArray} />
          )}
          <div className="col">
            <h1>{t('pages.registration.title')}</h1>
          </div>
          <FormNavigationBar
            currentStep={currentStep}
            numFormSteps={maxStep + 1}
            stepNames={stepNames}
            t={t}
          />
          {currentStep === 0 && screen === 0 && (
            <div className="col-md-12 registration__container">
              <p>
                <span className="pr-2 red bold">*</span>
                {t('pages.registration.requiredFields')}
              </p>
              <Form>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="firstName">
                      <Form.Label>
                        {t('pages.registration.firstName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.onFirstAndLastNameChange(e, 'givenName');
                        }}
                        value={givenName}
                        maxLength="40"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'givenNameError');
                        }}
                        className={givenNameError ? 'error-input' : undefined}
                        aria-required="true"
                        aria-invalid={!!givenNameError}
                        aria-describedby="firstname-error"
                      />
                      <p className="error-text" id="firstname-error">
                        {givenNameError && t(givenNameError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pl-2" lg={{ span: 6 }}>
                    <Form.Group controlId="lastName">
                      <Form.Label>
                        {t('pages.registration.lastName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.onFirstAndLastNameChange(e, 'sn');
                        }}
                        value={sn}
                        maxLength="80"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'snError');
                        }}
                        className={snError ? 'error-input' : undefined}
                        aria-required
                        aria-invalid={!!snError}
                        aria-describedby="lastname-error"
                      />
                      <p className="error-text" id="lastname-error">
                        {snError && t(snError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        {t('pages.registration.email')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          this.setState({
                            mail: e.target.value.trim().toLowerCase(),
                          });
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'emailError');
                        }}
                        value={mail}
                        aria-required="true"
                        className={emailError ? 'error-input' : undefined}
                        aria-invalid={!!emailError}
                        aria-describedby="email-error"
                      />
                      <p>{t('pages.registration.emailContext')}</p>
                      <p className="error-text" id="email-error">
                        {emailError && t(emailError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="confirmEmail">
                      <Form.Label>
                        {t('pages.registration.confirmEmail')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          const value = e.target.value.trim().toLowerCase();
                          this.setState(
                            {
                              confirmEmail: value,
                            },
                            () => {
                              this.isFormFieldValid(value, 'confirmEmailError');
                            }
                          );
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'confirmEmailError');
                        }}
                        value={confirmEmail}
                        className={
                          confirmEmailError ? 'error-input' : undefined
                        }
                        aria-required="true"
                        aria-invalid={!!confirmEmailError}
                        aria-describedby="confirm-email-error"
                      />
                      <p className="error-text" id="confirm-email-error">
                        {confirmEmailError && t(confirmEmailError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="userName">
                      <Form.Label>
                        {t('pages.registration.userName')}
                        <span className="red"> *</span>
                        <span style={{ 'margin-left': '2rem' }}>
                          <input
                            type="checkbox"
                            id="username"
                            name="username"
                            onChange={(e) => {
                              this.copyEmailToUsername(e);
                            }}
                          ></input>
                          <label
                            style={{
                              'font-weight': '400',
                              margin: 'auto',
                              'margin-left': '0.5rem',
                            }}
                          >
                            {t('pages.registration.copyEmail')}
                          </label>
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.onUserNameChange(e, 'userName');
                        }}
                        value={userName}
                        maxLength="30"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'userNameError');
                        }}
                        className={userNameError ? 'error-input' : undefined}
                        aria-required
                        autoComplete="off"
                        aria-invalid={!!userNameError}
                        aria-describedby="username-error"
                        disabled={copyEmailToUsername}
                      />
                      <p className="error-text" id="username-error">
                        {userNameError && t(userNameError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="telephoneNumber">
                      <Form.Label>
                        {t('pages.registration.telephoneNumber')}
                      </Form.Label>
                      <Form.Control
                        ref={this.telephoneNumberInputRef}
                        onKeyDown={this.handleKeyPress}
                        placeholder={!numberFocus ? '(###) ###-####' : ''}
                        onFocus={() => {
                          this.setState({
                            numberFocus: true,
                          });
                        }}
                        onBlur={() => {
                          this.setState({
                            numberFocus: false,
                          });
                        }}
                        onChange={this.onMobileNumberChange}
                        value={telephoneNumber}
                        aria-required="true"
                        className={
                          telephoneNumberError ? 'error-input' : undefined
                        }
                        aria-invalid={!!telephoneNumberError}
                        aria-describedby="mobile-number-error"
                      />
                      <p className="error-text" id="mobile-number-error">
                        {telephoneNumberError && t(telephoneNumberError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="passWord">
                      <Form.Label>
                        {t('pages.registration.password')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <PasswordField
                        onChange={(value) => {
                          this.setState({
                            password: value,
                          });
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'passwordError');
                        }}
                        password={password}
                        className={passwordError ? 'error-input' : undefined}
                        aria-required="true"
                        ariaInvalid={!!passwordError}
                        ariaDescribedby="password-error"
                        myRef={this.passwordInputRef}
                        onCopy={(e) => {
                          this.disableCopyPaste(e);
                        }}
                        onPaste={(e) => {
                          this.disableCopyPaste(e);
                        }}
                      />
                    </Form.Group>
                    <p className="error-text" id="password-error">
                      {passwordError && t(passwordError)}
                    </p>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="confirmPassWord">
                      <Form.Label>
                        {t('pages.registration.confirmPassword')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <PasswordField
                        onChange={(value) => {
                          this.setState(
                            {
                              confirmPassword: value,
                            },
                            () => {
                              this.isFormFieldValid(
                                value,
                                'confirmPasswordError'
                              );
                            }
                          );
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'confirmPasswordError');
                        }}
                        password={confirmPassword}
                        className={
                          confirmPasswordError ? 'error-input' : undefined
                        }
                        aria-required="true"
                        ariaInvalid={!!confirmPasswordError}
                        ariaDescribedby="confirm-password-error"
                        myRef={this.confirmPasswordInputRef}
                        onCopy={(e) => {
                          this.disableCopyPaste(e);
                        }}
                        onPaste={(e) => {
                          this.disableCopyPaste(e);
                        }}
                      />
                    </Form.Group>
                    <p className="error-text" id="confirm-password-error">
                      {confirmPasswordError && t(confirmPasswordError)}
                    </p>
                  </Col>
                </Form.Row>
                <div className="mt-3">
                  <PasswordRequirements
                    password={password}
                    formSubmitted={formSubmitted}
                  />
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                      onClick={(e) => this.sendToLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      // type="submit"
                      onClick={(e) => this.onSubmit(e)}
                    >
                      {t('pages.registration.registration')}
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="separator-light mt-4 mb-4"></div>
            </div>
          )}
          {currentStep === 1 && screen === 1 && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.registration.signUpSecurityQuestion')}</span>
              </h3>
              <p>
                <span className="pr-2 red bold">*</span>
                {t('pages.registration.requiredFields')}
              </p>
              <div>
                <Form>
                  <label>
                    {t('pages.registration.question1')}
                    <span className="red"> *</span>
                  </label>
                  {showDropDown && (
                    <Select
                      name="filters"
                      placeholder="Question 1"
                      value={this.state.questionVals[0]}
                      options={this.getAvailableOptions()}
                      onChange={(e) => {
                        this.handleQuestionValChange(e, 0);
                      }}
                      className={`select-box`}
                    />
                  )}
                  {!showDropDown && (
                    <InputGroup className="mb-3 question-input">
                      <Form.Control
                        placeholder="Please enter a custom question"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          this.setState({
                            customQuestion: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          this.setCustomQuestion(e);
                        }}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={(e) => this.onChangeQuestion(e, 0)}
                      >
                        x
                      </Button>
                    </InputGroup>
                  )}
                  <Form.Group controlId="answerOne">
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'answerOne');
                      }}
                      value={answerOne}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'answerOneError');
                      }}
                      placeholder={
                        !showDropDown
                          ? 'Please enter a custom answer'
                          : 'Answer 1'
                      }
                      className={`answer-input ${
                        answerOneError ? 'error-input' : undefined
                      }`}
                      aria-required="true"
                      aria-invalid={!!answerOneError}
                      aria-describedby="firstname-error"
                    />
                  </Form.Group>
                  <label>
                    {t('pages.registration.question2')}
                    <span className="red"> *</span>
                  </label>
                  {showDropDownTwo && (
                    <Select
                      name="filters"
                      placeholder="Question 2"
                      value={this.state.questionVals[1]}
                      options={this.getAvailableOptions()}
                      onChange={(e) => {
                        this.handleQuestionValChange(e, 1);
                      }}
                      className={`select-box`}
                    />
                  )}
                  {!showDropDownTwo && (
                    <InputGroup className="mb-3 question-input">
                      <Form.Control
                        placeholder="Please enter a custom question"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          this.setState({
                            customQuestion: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          this.setCustomQuestion(e);
                        }}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={(e) => this.onChangeQuestion(e, 1)}
                      >
                        x
                      </Button>
                    </InputGroup>
                  )}
                  <Form.Group controlId="answerTwo">
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'answerTwo');
                      }}
                      value={answerTwo}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'answerTwoError');
                      }}
                      className={`answer-input ${
                        answerTwoError ? 'error-input' : undefined
                      }`}
                      placeholder={
                        !showDropDownTwo
                          ? 'Please enter a custom answer'
                          : 'Answer 2'
                      }
                      aria-required="true"
                      aria-invalid={!!answerTwoError}
                      aria-describedby="firstname-error"
                    />
                  </Form.Group>
                  <label>
                    {t('pages.registration.question3')}
                    <span className="red"> *</span>
                  </label>
                  {showDropDownThree && (
                    <Select
                      name="filters"
                      placeholder="Question 3"
                      value={this.state.questionVals[2]}
                      options={this.getAvailableOptions()}
                      onChange={(e) => {
                        this.handleQuestionValChange(e, 2);
                      }}
                      className={`select-box`}
                    />
                  )}
                  {!showDropDownThree && (
                    <InputGroup className="mb-3 question-input">
                      <Form.Control
                        placeholder="Please enter a custom question"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          this.setState({
                            customQuestion: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          this.setCustomQuestion(e);
                        }}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={(e) => this.onChangeQuestion(e, 2)}
                      >
                        x
                      </Button>
                    </InputGroup>
                  )}
                  <Form.Group controlId="answerThree">
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'answerThree');
                      }}
                      value={answerThree}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'answerThreeError');
                      }}
                      className={`answer-input ${
                        answerThreeError ? 'error-input' : undefined
                      }`}
                      placeholder={
                        !showDropDownThree
                          ? 'Please enter a custom answer'
                          : 'Answer 3'
                      }
                      aria-required="true"
                      aria-invalid={!!answerThreeError}
                      aria-describedby="firstname-error"
                    />
                  </Form.Group>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                        variant="primary"
                        block
                      >
                        {t('pages.registration.back')}
                      </Button>
                      <Button
                        className="col btn btn-secondary  py-2 my-3"
                        onClick={(e) => this.onVerify(e)}
                      >
                        {t('pages.registration.verify')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
          {currentStep === 2 && showGoogleCaptcha && screen === 4 && (
            <>
              <div className="captcha">
                <div className="col">
                  <h2 className="mb-4">{t('pages.forgotPassword.header3')}</h2>
                </div>
                <div className="col-md-6 mt-3 mb-9">
                  <div className="google-captcha">
                    <div className="google-captcha__box">
                      {googleCaptchaToken && (
                        <GoogleCaptcha
                          sitekey={googleCaptchaToken}
                          onCaptchaChange={this.onCaptchaChange}
                          captchaReset={captchaReset}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex col-md-3">
                  <Button
                    className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                    type="submit"
                    onClick={(e) => this.decreaseCurrentStepHandler(e)}
                  >
                    {t('pages.forgotUserId.backBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {/* {currentStep === 3 && screen === 2 && verificationOption && ( */}
          {currentStep === 3 && screen === 2 && (
            <div className="col-md-12 registration__container">
              <div className="registration__container__resend-verification">
                <div className="registration__container__resend-verification__resend-text">
                  <h3>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {/* {verificationOption} */}
                    {this.state.mail.replace(/^(.{1})[^@]+/, '$1******')}
                  </h3>
                </div>
              </div>
              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className="ml-3"
                    value={verificationCode}
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                  {invalidOTP && (
                    <p className="error-text" id="verification-error">
                      {invalidOTP && t(invalidOTP)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__resend-verification__resend-text">
                  <p
                    className="resend-link"
                    onClick={(e) => this.resendVerify(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
                <div className="registration__container__pleasenote">
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>

                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4  py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={(e) => this.onVerificationCodeSubmit(e)}
                      type="submit"
                    >
                      {t('pages.registration.verify')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 3 && screen === 3 && (
            <div className="col-md-12 registration__container mb-5">
              <>
                <SuccessConfirmation
                  successMessage={t('pages.registration.registrationSuccess')}
                  emailVerify={t('pages.registration.welcomeRegistration')}
                />
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-3 col-md-12">
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={this.sendToLogin}
                      type="submit"
                    >
                      {t('pages.forgotPassword.loginBtn')}
                    </Button>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    );
  }
}
Registration.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(Registration);
