import React, { Component } from 'react';
import { Tab, Tabs, Breadcrumb } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { faSearch, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import 'app/modules/Dashboard/Dashboard.scss';
import 'app/modules/Dashboard/Tasks.scss';
import PropTypes from 'prop-types';
import http from 'api/http-request';
import 'app/modules/Dashboard/Admin.scss';
import Cookies from 'js-cookie';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';
import SalesforceLogo from 'assets/img/SalesforceLogo.png';
import ServicenowLogo from 'assets/img/ServicenowLogo.png';
import MyTasks from './MyTasks';

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: props.roles ? props.roles : [],
      adminDetail: undefined,

      key: '',
    };
  }

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };
  componentDidMount() {
    this.scrollToTop();
    this.getAllRoles();
  }

  getAllRoles = () => {
    let username = Cookies.get('username');

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      userName: username,

      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http({
      url: 'getUserRoles',
      method: 'POST',
      data: data,
    }).then((res) => {
      /*if (
        res.status == 401 &&
        res.data.iamcode == 'IAM0028' &&
        res.data.response == 'Access Denied'
      ) {
        Cookies.remove('userName');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';
      }*/

      let data = res.data.data.result;

      let adminRoles = data.filter((obj) => {
        return obj.name.includes('Admin');
      });

      this.setState(
        {
          roles: adminRoles,
          adminDetail: adminRoles[0],
          key: adminRoles[0].name,
        },
        () => {
          console.log('FETCHED USER ROLES');
        }
      );
    });
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };
  //   setFilerKeyword = (e) => {
  //     let searchString = e.target.value;
  //     this.setState({ searchString: searchString });
  //   };

  redirectToApp = (appLink) => {
    if (appLink.indexOf('https://') === 0 || appLink.indexOf('http://') === 0) {
      window.open(appLink, '_blank');
    } else {
      window.open('https://' + appLink, '_blank');
    }
  };

  changeRole = (key) => {
    let { roles } = this.state;
    let adminDetail = roles.filter((obj) => {
      if (obj.name.includes(key)) {
        return obj;
      }
    });

    this.setState({ adminDetail: adminDetail[0], key: key }, () => {});
  };
  render() {
    const { t } = this.props;
    let { roles, adminDetail, key } = this.state;

    return (
      <>
        <div>
          <AlertGlobal useTicker={false}></AlertGlobal>
        </div>
        <div className="admin-apps apps-tab">
          <div className="tab-area pb-5">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                {t('pages.BreadCrumb.home')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('pages.BreadCrumb.admin')}</Breadcrumb.Item>
              <Breadcrumb.Item active>...</Breadcrumb.Item>
            </Breadcrumb>

            <Tabs
              defaultActiveKey="My-Tasks"
              className="ml-3"
              activeKey={key}
              onSelect={(k) => {
                this.changeRole(k);
              }}
            >
              {roles.map((obj) => {
                return (
                  <Tab
                    key={obj?._id}
                    eventKey={obj?.name}
                    title={obj?.name?.replace(/Admin$/, '')}
                    tabClassName="px-3 mx-1"
                    onkey={(e) => {
                      this.changeRole(e, obj);
                    }}
                  ></Tab>
                );
              })}
            </Tabs>
            <div className="m-5">
              <div className="row mb-5 align-items-center">
                <div className="col">
                  <MyTasks adminDetail={adminDetail} />
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </>
    );
  }
}

Tasks.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,

  // getUserDetails: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};
export default withTranslation()(Tasks);
