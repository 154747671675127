import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';

const resources = { en_US: { translation: en }, es: { translation: es } };

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en_US',
    fallbackLng: 'en_US',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
