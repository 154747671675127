import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import ProfileLeftMenu from 'app/modules/Dashboard/ProfileLeftMenu';
import { withTranslation } from 'react-i18next';
import RequestAccess from 'app/modules/Dashboard/RequestAccess';
import PersonalInformation from 'app/modules/Dashboard/PersonalInformation';
import ChangePassword from 'app/modules/Dashboard/ChangePassword';
import Devices from 'app/modules/Dashboard/Devices';
import CommunicationPreferences from 'app/modules/Dashboard/CommunicationPreferences';
import AuthorizedApps from 'app/modules/Dashboard/AuthorizedApps';
import PeopleSharing from 'app/modules/Dashboard/PeopleSharing';
import ActivityLog from 'app/modules/Dashboard/ActivityLog';
import SiteSettings from 'app/modules/Dashboard/SiteSettings';
import AccountManagement from 'app/modules/Dashboard/AccountManagement';
import PrivacyConsent from 'app/modules/Dashboard/PrivacyConsent';
import http from 'api/http-request';
import 'app/modules/Dashboard/Account.scss';
import Cookies from 'js-cookie';
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails ? props.userDetails : {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUserDetails();
  }

  getUserDetails = () => {
    let username = Cookies.get('username');

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      pattern: username,

      attribute: 'userName',

      operation: 'eq',
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http({
      url: '/aegis-managed-service/aegis/api/searchUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 &&
        res.data.iamcode == 'IAM000' &&
        res.data.response == 'Access Denied'
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';
      }
      let data = res.data.data.result[0];
      const { setUserDetails } = this.props;
      setUserDetails(data);
      this.setState({
        userDetails: data,
      });
    });
  };

  getUpdatedName = () => {
    this.getUserDetails();
  };
  render() {
    const { pageName, setUserDetails } = this.props;
    const { userDetails } = this.state;
    return (
      <Container fluid className="profile">
        <Row noGutters className="profile__row">
          <Col
            md={{ span: 12 }}
            lg={{ span: 3 }}
            className="profile__row__left"
          >
            <ProfileLeftMenu pageName={pageName || ''} />
          </Col>
          <Col
            md={{ span: 12 }}
            lg={{ span: 9 }}
            className="profile__row__right"
          >
            {!pageName ||
              (pageName === 'Account' && (
                <PersonalInformation
                  userDetails={userDetails}
                  getUpdatedName={() => {
                    this.getUpdatedName();
                  }}
                  setUserDetails={setUserDetails}
                />
              ))}
            {pageName === 'requestAccess' && <RequestAccess />}
            {pageName === 'personalInformation' && (
              <PersonalInformation
                userDetails={userDetails}
                getUpdatedName={() => {
                  this.getUpdatedName();
                }}
                setUserDetails={setUserDetails}
              />
            )}
            {pageName === 'changePassword' && (
              <ChangePassword userDetails={userDetails} />
            )}
            {pageName === 'Devices' && <Devices userDetails={userDetails} />}
            {pageName === 'AuthorizedApps' && <AuthorizedApps />}
            {pageName === 'PeopleSharing' && <PeopleSharing />}
            {pageName === 'PrivacyConsent' && <PrivacyConsent />}
            {pageName === 'ActivityLog' && <ActivityLog />}
            {pageName === 'SiteSettings' && <SiteSettings />}
            {pageName === 'AccountManagement' && <AccountManagement />}
            {pageName === 'CommunicationPreferences' && (
              <CommunicationPreferences />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
Account.propTypes = {
  match: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object,
  userDetails: {},
};
Account.defaultProps = {
  userData: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
  userDetails: {},
};
export default withTranslation()(Account);
