import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import 'app/modules/Login/Login.scss';
import { withTranslation } from 'react-i18next';
import googleLogo from 'assets/img/google-plus-logo.png';
import hyprLogo from 'assets/img/hypr.png';
import kosmosLogo from 'assets/img/1kosmos.png';
import yubiKeyLogo from 'assets/img/Yubikey.jpg';
import http from 'api/http-request';
import Form from 'react-bootstrap/Form';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { getNestedObject, randomCount } from 'utils/common';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import Radio from 'app/modules/Shared/Radio/Radio';
import QRCode from 'qrcode.react';
import { DeviceUUID } from 'device-uuid';
import { faCircleNotch, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import Carousel1 from 'assets/img/florida-homes-hero-image.jpg';
import Carousel2 from 'assets/img/mountains-hero-image.jpg';
import Carousel3 from 'assets/img/nyc.jpg';
import Carousel4 from 'assets/img/tampa-bay-hero-image.jpg';
import Carousel5 from 'assets/img/nyc1.jpg';
import Carousel6 from 'assets/img/san-usa.jpg';
import Carousel7 from 'assets/img/atlanta-usa.jpg';
import pivCacLogo from 'assets/img/piv_cac.jpg';
import qrcode from 'assets/img/qrcode.jpg';
import AlertGlobal from 'app/modules/Shared/AlertGlobal/AlertGlobal';
import YubikeyLogin from 'app/modules/Login/YubikeyLogin';
const CryptoJS = require('crypto-js');
import passwordlessAuthLogo from 'assets/img/pass.png';
import uib from 'assets/img/uniLogin.png';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import { Redirect } from 'react-router-dom';
import Alert from 'app/modules/Shared/Alert/Alert';

class LoginCustomWallet extends Component {
  constructor(props) {
    super(props);
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    if (iPlanetDirectoryPro) {
      window.location.href = sanitizeUrl(`/profile/dashboard`);
    }
    this.state = {
      redirect: false,
      showalert: '',
      userName: '',
      password: '',
      showPassword: false,
      errorText: '',
      isAuthenticated: false,
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      screen: 1,
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCode: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
      qrURL: '',
      showAlert: '',
      loginQRCode: '',
      devicePayLoad: { metadata: { hardware: {}, platform: {}, browser: {} } },
      selectedRegisterOption: '',
      countInterval: 0,
      socialLogin: false,
      carouselImages: [
        { image: Carousel1 },
        { image: Carousel2 },
        { image: Carousel3 },
        { image: Carousel4 },
        { image: Carousel5 },
        { image: Carousel6 },
        { image: Carousel7 },
      ],
      imageIndex: null,
      authID: '',
      isGooglein: null,
      loginUrl: '',
    };
  }

  displayRandomImage = () => {
    this.setState({
      imageIndex: randomCount(),
    });
  };

  changeLanguage = (lang) => {
    setTimeout(() => {
      i18n.changeLanguage(lang);
    }, 0);
  };

  getHeaders = () => {
    return {
      Authorization: `${process.env.AUTHORIZATION_HEADER}`,
      // Cookie: `${process.env.COOKIE_HEADER}`,
    };
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 3000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  componentDidMount() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const environment = process.env.REACT_APP_ENV;
    const state = query.get('state');
    const code = query.get('code');
    Cookies.set('dashboardRequired', 'customWallet');
    this.setState({
      isGooglein: state,
    });

    if (environment === 'dev') {
      this.setState({
        loginUrl: process.env.REACT_APP_LOGIN,
      });
    } else {
      this.setState({
        loginUrl: process.env.REACT_APP_LOGIN_LOCAL,
      });
    }

    const isRedirectLogin = document.URL.includes('original_request_url');

    if (isRedirectLogin) {
      const url = document.URL.indexOf('original_request_url');
      if (url !== -1) {
        const referrerUrls = document.URL.substring(url + 21);
        Cookies.set('referer', decodeURIComponent(referrerUrls));
      }
    }

    if (state !== null && code !== null) {
      if (Cookies.get('loginType') == 'socialLogin') {
        http(
          {
            url: 'socialLogin',
            method: 'POST',
            data: {
              authId: Cookies.get('googleAuthId'),
              state: state,
              code: code,
            },
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        ).then((res) => {
          Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
            domain: '.aegis-frk.com',
          });
          // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
          Cookies.set('username', res.data.user);
          Cookies.set('firstLoginSession', true);
          Cookies.remove('googleAuthId');

          this.loginResponse(res);
        });
      } else if (Cookies.get('loginType') == 'uniLogin') {
        http(
          {
            url: 'uniLogin',
            method: 'POST',
            data: {
              authId: Cookies.get('uniloginAuthId'),
              state: state,
              code: code,
            },
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        ).then((res) => {
          Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
            domain: '.aegis-frk.com',
          });
          // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
          Cookies.set('username', res.data.user);
          Cookies.set('firstLoginSession', true);
          Cookies.remove('uniloginAuthId');
          this.loginResponse(res);
        });
      }
    }

    this.scrollToTop();
    if (Cookies.get('socialLogin')) {
      this.socialLoginMFA();
    }
    this.setDefaultLanguage();
    this.displayRandomImage();
    if (this.props.history.action === 'REPLACE') {
      const { showalert } = this.props.location.state || { showalert: '' };
      this.showError(showalert);
    }
  }

  setDefaultLanguage = () => {
    localStorage.setItem('ctsessionlanguage', 'en_US');
    Cookies.set('ctsessionlanguage', 'en_US');

    this.changeLanguage('en_US');
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      screen: 1,
      userName: '',
      password: '',
      showPassword: '',
      errorText: '',
      isAuthenticated: '',
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
    });
  };

  genericResponse = (res) => {
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = sanitizeUrl(`/profile/dashboard`);
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.authId || res.data.tokenId)
      ) {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = sanitizeUrl(`/profile/dashboard`);
          localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res, ['data'])) {
          this.setState({
            loginPayLoad: res.data,
            isAuthenticated: true,
            loginToken: res.data.authId,
          });
        }
      }
    } else if (
      res.data.code === 401 &&
      res.data.message === 'Authentication Failed'
    ) {
      this.showError('pages.login.incorrectLogin');
    } else if (
      res.data.code === 401 &&
      res.data.message === 'Session has timed out'
    ) {
      this.showError('pages.login.sessionTimedOut');
    } else if (
      res.data.code === 401 &&
      res.data.message ===
        'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
    ) {
      this.showError('pages.login.willGetLocked');
    } else if (
      res.data.code === 401 &&
      res.data.message ===
        'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
    ) {
      this.showError('pages.login.willGetLocked2');
    }
  };

  authenticate = () => {
    http(
      {
        url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      this.genericResponse(res);
    });
  };

  socialLoginMFA = () => {
    // const authId = localStorage.getItem('socialAuthId');
    const authId = Cookies.get('socialAuthId');
    // const socialLogin = JSON.parse(localStorage.getItem('socialLogin'));
    const socialLogin = JSON.parse(Cookies.get('socialLogin'));
    let state = socialLogin.state;
    let authuser = socialLogin.authuser;
    let prompt = socialLogin.prompt;
    let scope = socialLogin.scope;
    let code = socialLogin.code;
    socialLogin.authId = authId;
    http(
      {
        url: `authenticate?state=${state}&code=${code}&scope=${scope}&authuser=${authuser}&prompt=${prompt}`,
        method: 'POST',
        data: socialLogin,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.loginResponse(res);
    });
  };

  socialAuthenticate = () => {
    http(
      {
        url: 'socialLogin',
        method: 'POST',
        data: {},
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      window.location.href = res.data.data.callbacks[0].output[0].value;
      this.setState({
        authID: res.data.data.authId,
      });
      Cookies.set('googleAuthId', res.data.data.authId);
      Cookies.set('loginType', 'socialLogin');
    });
  };

  uniLoginAuthenticate = () => {
    http(
      {
        url: 'uniLogin',
        method: 'POST',
        data: {},
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      window.location.href = res.data.data.callbacks[0].output[0].value;
      this.setState({
        authID: res.data.data.authId,
      });
      Cookies.set('uniloginAuthId', res.data.data.authId);
      Cookies.set('loginType', 'uniLogin');
    });
  };

  loginResponse = (res) => {
    this.scrollToTop();
    if (
      getNestedObject(res.data, ['data', 'successUrl']) ||
      getNestedObject(res.data, ['data', 'tokenId'])
    ) {
      window.location.href = sanitizeUrl(`/profile/dashboard`);

      window.location.href = this.state.loginUrl;
    } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.data.authId || res.data.data.tokenId)
      ) {
        if (
          getNestedObject(res.data, ['data', 'successUrl']) ||
          getNestedObject(res.data, ['data', 'tokenId'])
        ) {
          window.location.href = sanitizeUrl(`/profile/dashboard`);
          // localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
          const {
            data: {
              data: { callbacks },
            },
          } = res;
          if (callbacks) {
            if (
              callbacks[0].output[0].value === 'User Name' &&
              callbacks[1].output[0].value === 'Password'
            ) {
              this.showError('pages.login.incorrectLogin');
            }
            //  else if (callbacks[0].type === 'DeviceProfileCallback') {
            //   this.setState({
            //     loginPayLoad: res.data,
            //   });
            //   this.deviceCheck();
            // } else if (callbacks[0].output[0].value === 'One Time Password') {
            else if (
              callbacks[0].output[0].value === 'One Time Password' ||
              callbacks[0].output[0].value.trim() === 'OTP Preference'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                authID: res.data.data.authId,
                screen: 3,
                loginPayLoad: res.data,
              });
            } else if (
              callbacks[0].output[0].value === 'Enter verification code'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 5,
                loginPayLoad: res.data,
              });
            } else if (
              callbacks[0].output[0].value.includes('Scan the barcode')
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 4,
                loginPayLoad: res.data,
                scanTitle: callbacks[0].output[0].value,
                qrCodeUrl: callbacks[2].output[0].value,
              });
              if (this.state.qrCodeUrl.includes('pushauth')) {
                setInterval(this.getPushNotification(), 2000);
                this.setState({
                  countInterval: this.state.countInterval + 1,
                });
              }
            } else {
              this.setState({
                userOption: callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 2,
                loginPayLoad: res.data.data,
              });
            }
          }
        }
      }
    }
  };

  deviceCheck = () => {
    const { devicePayLoad, loginPayLoad } = this.state;
    const deviceId = new DeviceUUID().get();
    const du = new DeviceUUID().parse();
    if (du) {
      devicePayLoad.metadata.platform.deviceName = du.platform;
    }
    if (deviceId) {
      devicePayLoad.identifier = deviceId;
    }
    if (navigator.userAgent) {
      devicePayLoad.metadata.browser.userAgent = navigator.userAgent;
    }
    if (navigator.appName) {
      devicePayLoad.metadata.browser.appName = navigator.appName;
    }
    if (navigator.appCodeName) {
      devicePayLoad.metadata.browser.appCodeName = navigator.appCodeName;
    }
    if (navigator.appVersion) {
      devicePayLoad.metadata.browser.appVersion = navigator.appVersion;
    }
    if (navigator.appMinorVersion) {
      devicePayLoad.metadata.browser.appMinorVersion =
        navigator.appMinorVersion;
    }
    if (navigator.buildID) {
      devicePayLoad.metadata.browser.buildID = navigator.buildID;
    }
    if (navigator.platform) {
      devicePayLoad.metadata.platform.platform = navigator.platform;
    }
    if (navigator.cpuClass) {
      devicePayLoad.metadata.hardware.cpuClass = navigator.cpuClass;
    }
    if (navigator.oscpu) {
      devicePayLoad.metadata.hardware.oscpu = navigator.oscpu;
    }
    if (navigator.product) {
      devicePayLoad.metadata.browser.product = navigator.product;
    }
    if (navigator.productSub) {
      devicePayLoad.metadata.browser.productSub = navigator.productSub;
    }
    if (navigator.vendor) {
      devicePayLoad.metadata.browser.vendor = navigator.vendor;
    }
    if (navigator.vendorSub) {
      devicePrint.metadata.browser.vendorSub = navigator.vendorSub;
    }
    if (navigator.language) {
      devicePayLoad.metadata.platform.language = navigator.language;
    }
    if (navigator.userLanguage) {
      devicePayLoad.metadata.platform.userLanguage = navigator.userLanguage;
    }
    if (navigator.browserLanguage) {
      devicePayLoad.metadata.browser.browserLanguage =
        navigator.browserLanguage;
    }
    if (navigator.systemLanguage) {
      devicePayLoad.metadata.platform.systemLanguage = navigator.systemLanguage;
    }
    loginPayLoad.callbacks[0].input[0].value = JSON.stringify(devicePayLoad);
    // loginPayLoad.callbacks[0].input[0].value =
    //   '{"identifier":"1267894517-1638565932-442693090","metadata":{"hardware":{"cpuClass":null,"deviceMemory":null,"hardwareConcurrency":8,"maxTouchPoints":10,"oscpu":null,"display":{"width":1280,"height":720,"pixelDepth":24,"angle":0}},"browser":{"userAgent":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36","appName":"Netscape","appCodeName":"Mozilla","appVersion":"5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36","appMinorVersion":null,"buildID":null,"product":"Gecko","productSub":"20030107","vendor":"Google Inc."},"platform":{"language":"en-US","platform":"Win32","userLanguage":null,"systemLanguage":null,"deviceName":"Windows (Browser)","timezone":-330}}}';
    http(
      {
        url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.scrollToTop();
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = sanitizeUrl(`/profile/dashboard`);

        window.location.href = this.state.loginUrl;
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (
                callbacks[0].output[0].value === 'User Name' &&
                callbacks[1].output[0].value === 'Password'
              ) {
                this.showError('pages.login.incorrectLogin');
              } else if (callbacks[0].type === 'DeviceProfileCallback') {
                this.setState({
                  loginPayLoad: res.data,
                });
                this.deviceCheck();
              } else if (callbacks[0].type === 'RedirectCallback') {
                this.setState({
                  loginPayLoad: res.data,
                });
                window.location.href = callbacks[0].output[0].value;
                // localStorage.setItem('socialAuthId', res.data.authId);
                Cookies.set('socialAuthId', res.data.authId);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      }
    });
  };

  onSocialLogin = (e) => {
    e.preventDefault();
    this.socialAuthenticate();
  };

  onUIBLogin = (e) => {
    e.preventDefault();
    this.uniLoginAuthenticate();
  };

  onYubikeyLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 11,
    });
  };

  onpivCacLogin = (e) => {
    e.preventDefault();
    window.location.href = sanitizeUrl(process.env.REACT_APP_PIV);
  };

  /*on1kosmosLogin = async (e) => {
    e.preventDefault();

    const BIDSDK = require('blockid-nodejs-helpers/BIDSDK.js');
    const BIDSessions = require('blockid-nodejs-helpers/BIDSessions.js');
    let mySession = null;

    BIDSDK.setupTenant(
      { dns: 'blockid-trial.1kosmos.net', communityName: 'devx' },
      '0fc061c6-bfcb-4037-acf2-35d3fb488c76'
    ).then((loaded) => {
      console.log('is tenant loaded:', loaded);
    });

    

    mySession = await BIDSessions.createNewSession('Fingerprint', 'firstname');
    const sessionid = mySession.sessionId;
    const url = mySession.url;

    this.setState({
      qrURL: url + '/session/' + sessionid,
      screen: 10,
    });

    this.kosmosPollingSession(e, sessionid);
  };

  kosmosPollingSession = (e, sessionid) => {
    const BIDSessions = require('blockid-nodejs-helpers/BIDSessions.js');
    let sessionData = null;
    let count = 0;
    try {
      const pollingSessionTimer = setInterval(async () => {
        count++;
        if (count === 120) {
          clearInterval(pollingSessionTimer);
          this.on1kosmosLogin(e);
        }
        sessionData = await BIDSessions.pollSession(sessionid, true, true);
        if (sessionData.status === 200) {
          clearInterval(pollingSessionTimer);
          this.showError('Authentication Successful');
        } else if (
          sessionData.message === "Session with this sessionId doesn't exist"
        ) {
          clearInterval(pollingSessionTimer);
          this.showError('The Session has Expired, Try Refreshing the QR code');
        }
        console.log(sessionData);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  // createUser= () => {

  //   const BIDECDSA = require("blockid-nodejs-helpers/BIDECDSA.js");
  //   var BIDSDK = require("blockid-nodejs-helpers/BIDSDK.js");
  //   BIDSDK.setupTenant({dns: "eims-pilot.1kosmos.net>", communityName: "default"}, "9111a44f-886c-4d59-b6bc-b1d60ce77bd3").then(loaded => {
  //     console.log('is tenant loaded:', loaded);
  //   });

  //   const publicKey = "os9skQhJpyJIz7g5xgopwlN3N7eqMR9XMupvIIZf+CkB0V5ADDDdQ0wfA8KqH98NuFch4cIm6qwfuLGwoPCk+A==";
  //   const licenseKey = "9111a44f-886c-4d59-b6bc-b1d60ce77bd3";
  //   const privateKey = "cGB/g7jd/jw1v4z8FDfoeppYQ6y1IJAYzPPkzKkCc4w=";
  //   const bidpublickKey = "tl5v3ISk+1/tssWIOHHoZeg9QF3SztMyvOstLnNy6v0NdESeaVurn9CuwPE+qtMlyO9x1pbPbGZZeR7aF75aIQ==";

  //   const sharedKey = BIDECDSA.createSharedKey(privateKey,bidpublickKey);
  //   const encryptedLicenseKey = BIDECDSA.encrypt(licenseKey,sharedKey);

  //   var dataStr = JSON.stringify({ts: Math.round(new Date().getTime() / 1000),appid: 'com.bid.jsp.sdk',uuid: '77992454-beb3-49ce-ba8a-b230bfc90d67'});

  //   const encryptedRequestID = BIDECDSA.encrypt(dataStr,sharedKey);

  //   http(
  //     {
  //       url: 'tenant/61b0c6a594e40a0a3ed7bc42/community/61b0c6a694e40a0a3ed7bc43/users/create',
  //       data:{
  //         "authModule": "61b9fff0e785e8001ace7229",
  //         "users": [
  //           {
  //             "username": "anant123",
  //             "password": "@Lpha123",
  //             "status": "active",
  //             "firstname": "Anant",
  //             "middlename": "asd",
  //             "lastname": "Mishra",
  //             "email1": "anantmishra20@email.com",
  //             "email1_verified": true,
  //             "email2": "anantmishra22@email.com",
  //             "email2_verified": true,
  //             "phone1": "9876543210",
  //             "phone1_verified": true,
  //             "address": {"house" : "Church Road"},
  //             "address_verified": true,
  //             "disabled": false
  //           }
  //         ]
  //       },
  //       method: 'PUT',
  //     },
  //     {
  //       "Content-Type": "application/json",
  //       "Accept": "",
  //       "licensekey": encryptedLicenseKey,
  //       "requestid" : encryptedRequestID,
  //       "publickey": publicKey
  //     }
  //   ).then((res) => {
  //     console.log(res);
  //   });
  // }
  */

  onPasswordlessLogin = (e) => {
    e.preventDefault();
    this.setState({ screen: 13 });
  };

  onPasswordlessAuthentication = (e) => {
    e.preventDefault();

    const { userName } = this.state;
    if (userName === '') {
      this.showError('errors.userName');
    } else {
      this.setState({
        errorText: '',
      });
      this.getPasswordlessAuth();
    }
  };

  getPasswordlessAuth = () => {
    const { userName } = this.state;
    this.scrollToTop();

    http(
      {
        url: `passowordless`,
        method: 'POST',
        data: {
          'User Name': userName,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    ).then(async (res) => {
      if (
        res.status === 200 &&
        res.data.iamcode == 'IAM000' &&
        res.data.isUserExists == 'true'
      ) {
        // already registered
        this.setState({ challenges: res.data.data.challenge });
        this.setState({ rpid: res.data.data.rpid });
        this.setState({ allowCredentials: res.data.data.allowCredentials });
        this.setState({ authId: res.data.data.authId });
        this.setState({ message: res.data.data.message });

        const { challenges, message, rpid, allowCredentials, authId } =
          this.state;

        const assertion = await navigator.credentials.get({
          publicKey: {
            rpId: rpid,
            timeout: 300000,
            userVerification: 'preferred',
            challenge: eval(challenges),
            allowCredentials: eval(allowCredentials),
          },
        });
        console.log(assertion);
        const clientData = String.fromCharCode.apply(
          null,
          new Uint8Array(assertion.response.clientDataJSON)
        );
        const authenticatorData = new Int8Array(
          assertion.response.authenticatorData
        ).toString();
        const signature = new Int8Array(
          assertion.response.signature
        ).toString();
        const rawId = assertion.id;
        const combined =
          clientData +
          '::' +
          authenticatorData +
          '::' +
          signature +
          '::' +
          rawId;
        console.log(combined);

        http(
          {
            url: `passowordless`,
            method: 'POST',
            data: {
              value: combined,
              authId: authId,
              message: message,
              'User Name': userName,
            },
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        ).then((res) => {
          if (
            res.status === 200 &&
            res.data.iamcode == 'IAM000' &&
            getNestedObject(res.data, ['data', 'successUrl'])
          ) {
            Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
              domain: '.aegis-frk.com',
            });
            // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
            Cookies.set('username', userName);
            Cookies.set('firstLoginSession', true);
            window.location.href = this.state.loginUrl;
          } else {
            this.showError('pages.registration.internalServerError');
          }
        });
      } else if (
        res.status === 200 &&
        res.data.iamcode == 'IAM000' &&
        res.data.isUserExists == 'false'
      ) {
        this.setState({ authId: res.data.data.authId });
        const { authId } = this.state;
        this.setState({ screen: 14 });
      } else if (res.status === 401) {
        this.showError('pages.ManageServices.userDoesNotExist');
      } else {
        this.showError('pages.registration.internalServerError');
      }
    });
  };

  onPasswordlessRegistration = (e) => {
    e.preventDefault();

    const { password, authId } = this.state;
    if (password === '') {
      this.showError('errors.noPasswordError');
    } else {
      this.setState({
        errorText: '',
      });
      this.registrationPasswordless();
    }
  };

  registrationPasswordless = () => {
    const { password, authId, userName } = this.state;
    this.scrollToTop();

    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });

    http(
      {
        url: `passowordless`,
        method: 'POST',
        data: {
          'User Name': userName,
          Password: encrypted.toString(),
          authId: authId,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    ).then(async (res) => {
      if (res.status === 200 && res.data.iamcode == 'IAM000') {
        this.setState({ authId: res.data.data.authId });
        this.setState({ challenges: res.data.data.challenge });
        this.setState({ rpid: res.data.data.rpid });
        this.setState({ rpname: res.data.data.rpname });
        this.setState({ username: res.data.data.username });
        this.setState({ message: res.data.data.message });
        this.setState({ userid: res.data.data.userid });
        this.setState({ displayName: res.data.data.displayName });

        const {
          authId,
          challenges,
          rpname,
          rpid,
          username,
          userid,
          displayName,
          message,
        } = this.state;

        const credential = await navigator.credentials.create({
          publicKey: {
            challenge: eval(challenges),
            rp: {
              name: rpname,
              id: rpid,
            },
            user: {
              id: Uint8Array.from(userid, (c) => c.charCodeAt(0)),
              name: username,
              displayName: displayName,
            },
            pubKeyCredParams: [
              {
                type: 'public-key',
                alg: -7,
              },
              {
                type: 'public-key',
                alg: -257,
              },
            ],
            authenticatorSelection: {
              userVerification: 'preferred',
            },
            timeout: 60000,
            attestation: 'none',
          },
        });
        console.log(credential);
        const rawId = credential.id;
        let clientData = String.fromCharCode.apply(
          null,
          new Uint8Array(credential.response.clientDataJSON)
        );
        const keyData = new Int8Array(
          credential.response.attestationObject
        ).toString();
        const combined = clientData + '::' + keyData + '::' + rawId;
        console.log(combined);

        http(
          {
            url: `passowordless`,
            method: 'POST',
            data: {
              value: combined,
              authId: authId,
              'User Name': userName,
              message: message,
            },
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        ).then((res) => {
          if (
            res.status === 200 &&
            res.data.iamcode == 'IAM000' &&
            getNestedObject(res.data, ['data', 'successUrl'])
          ) {
            Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
              domain: '.aegis-frk.com',
            });
            // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
            Cookies.set('username', userName);
            Cookies.set('firstLoginSession', true);
            window.location.href = this.state.loginUrl;
          } else if (res.status === 401) {
            this.showError('pages.login.passwordLessRegistrationsuccess');
            this.setState({ screen: 13 });
          } else {
            this.showError('pages.registration.internalServerError');
            this.setState({ screen: 13 });
          }
        });
      } else if (res.status === 401) {
        this.showError('errors.incorrectPassword');
      } else {
        this.showError('pages.registration.internalServerError');
      }
    });
  };
  onhyprLogin = (e) => {
    e.preventDefault();

    http(
      {
        url: `hyprAuth`,
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.setState({ screen: 8 });

      this.genericResponse(res);
    });
  };

  onHyprAuthentication = (e) => {
    e.preventDefault();
    const { userName } = this.state;
    if (userName === '') {
      this.showError('errors.userName');
    } else {
      this.setState({
        errorText: '',
      });

      this.getHyprAuth();
    }
  };

  getHyprAuth = () => {
    const { userName, loginPayLoad } = this.state;
    this.scrollToTop();
    loginPayLoad.callbacks[0].input[0].value = userName;

    http(
      {
        url: `hyprAuth`,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (
        res.data &&
        res.status === 200 &&
        res.data.callbacks[0].type === 'PollingWaitCallback'
      ) {
        this.setState({
          loginPayLoad: res.data,
          screen: 9,
          userOption: res.data.callbacks[0].output,
        });
        setInterval(this.getPushHYPR(), 3000);
      } else if (
        res.status === 401 &&
        res.data.code === 401 &&
        res.data.reason === 'Unauthorized'
      ) {
        this.showError('errors.invalidUsername');
        http(
          {
            url: `hyprAuth`,
            method: 'POST',
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        ).then((res) => {
          if (getNestedObject(res, ['data'])) {
            this.setState({
              loginPayLoad: res.data,
              loginToken: res.data.authId,
            });
          }
        });
      }
    });
  };

  onLogin = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });
    const { userName, password, socialLogin } = this.state;
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    if (!socialLogin) {
      Cookies.remove('socialLogin');
      Cookies.remove('email');
    }

    if (userName === '' || password === '') {
      this.showError('errors.userNamePassword');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: 'userLogin',
          method: 'POST',
          data: {
            'User Name': userName,
            Password: encrypted.toString(),
          },
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
          ...this.getHeaders(),
        }
      ).then((res) => {
        //this.genericResponse(res);
        if (res.status == 200) {
          this.loginResponse(res);
        } else if (res.status == 401) {
          this.showError('pages.login.incorrectLogin');
        } else {
          this.showError('pages.pageNotFound.title');
        }
      });
    }
  };

  // showError = (errorText) => {
  //   this.setState({
  //     errorText,
  //   });
  //   window.scrollTo({
  //     behavior: 'smooth',
  //     top: 0,
  //   });
  // };

  showPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  PasswordValidation = (e) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/)) {
      this.setState({
        password: value,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onOptionsChange = (value) => {
    this.setState({
      selectedOption: value,
    });
  };

  onDeviceOptionsChange = (value) => {
    if (value.includes('Yes')) {
      value = 'Yes';
    } else {
      value = 'No';
    }
    this.setState({
      selectedRegisterOption: value,
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  onDeregister = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });
    const { loginToken, socialLogin, userName } = this.state;
    if (!socialLogin) {
      Cookies.remove('socialLogin');
      Cookies.remove('email');
    }

    if (!loginToken) {
      this.showError('errors.sessionTimedOut');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: 'userLogin',
          method: 'POST',
          data: {
            'User Name': userName,
            authId: loginToken,
            'Authentication Preference': 'De-Register',
          },
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        //this.genericResponse(res);
        if (res.status == 200) {
          this.onBackLogin(e);
        } else if (res.status == 401) {
          this.showError('pages.login.sessionTimedOut');
        }
      });
    }
  };

  onResubmit = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });
    const { loginToken, socialLogin, userName, verificationCode } = this.state;
    if (!socialLogin) {
      Cookies.remove('socialLogin');
      Cookies.remove('email');
    }

    if (!loginToken) {
      this.showError('errors.sessionTimedOut');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: 'userLogin',
          method: 'POST',
          data: {
            'User Name': userName,
            authId: loginToken,
            'Authentication Preference': 'Re-Submit',
          },
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        //this.genericResponse(res);
        if (res.status == 200) {
          if (getNestedObject(res.data, ['data', 'successUrl'])) {
            window.location.href = 'sanitizeUrl(`/profile/dashboard`);';

            window.location.href = this.state.loginUrl;
          } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
            if (
              res.status === 200 &&
              res.data &&
              (res.data.data.authId || res.data.data.tokenId)
            ) {
              if (getNestedObject(res.data, ['data', 'successUrl'])) {
                window.location.href = sanitizeUrl(`/profile/dashboard`);
                // localStorage.setItem('userLoggedIn', true);
                Cookies.set('userLoggedIn', true);
              } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
                const {
                  data: {
                    data: { callbacks },
                  },
                } = res;
                if (callbacks) {
                  if (callbacks[0].output[0].value === 'One Time Password') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 3,
                      loginPayLoad: res.data,
                    });
                  } else if (
                    callbacks[0].output[0].value === 'Enter verification code'
                  ) {
                    this.setState({
                      // isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      verificationCode: verificationCode,
                      userName: userName,
                    });

                    this.onVerificationCodeSubmit(e);
                  } else if (
                    callbacks[0].output[0].value.includes('Scan the barcode')
                  ) {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 4,
                      loginPayLoad: res.data,
                      scanTitle: callbacks[0].output[0].value,
                      qrCodeUrl: callbacks[2].output[0].value,
                    });
                    if (this.state.qrCodeUrl.includes('pushauth')) {
                      setInterval(this.getPushNotification(), 2000);
                      this.setState({
                        countInterval: this.state.countInterval + 1,
                      });
                    }
                  } else if (callbacks[0].type === 'ChoiceCallback') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.authId,
                      screen: 6,
                      loginPayLoad: res.data,
                      userOption: '',
                    });
                  } else if (callbacks[0].type === 'PollingWaitCallback') {
                    this.setState({
                      loginPayLoad: res.data,
                      screen: 7,
                      userOption: res.data.callbacks[0].output,
                    });
                    setInterval(this.getPushNotification(), 3000);
                  } else {
                    this.setState({
                      userOption: res.data.callbacks[0].output[1].value,
                      isAuthenticated: true,
                      loginToken: res.data.authId,
                      screen: 2,
                      loginPayLoad: res.data,
                    });
                  }
                }
              }
            }
          }
        } else if (res.status == 401) {
          this.showError('pages.login.sessionTimedOut');
        }
      });
    }
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });

    this.setState({
      errorText: '',
    });
    const { authID, socialLogin, userName } = this.state;
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'userLogin';
    }
    http(
      {
        url: url,
        data: {
          authId: authID,
          OTPChoice: 'Resend',
          'User Name': userName,
        },
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        const {
          data: {
            data: { authId },
          },
        } = res;

        this.setState({
          verificationCode: '',
          verificationCodeError: '',
          authID: authId,
        });
      } else {
        this.showError('errors.maxOTPAttempt');
        setTimeout(() => {
          window.location.href = sanitizeUrl(`/`);
        }, 3000);
      }
    });
  };

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const {
      loginPayLoad,
      verificationCode,
      loginToken,
      userName,
      socialLogin,
    } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      let url = '';
      if (socialLogin) {
        url =
          'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
      } else {
        url = 'userLogin';
      }
      http(
        {
          url: url,
          data: {
            authId: loginToken,
            'User Name': userName,
            'Enter verification code': verificationCode,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (
          res.status === 200 &&
          res.data.iamcode === 'IAM000' &&
          getNestedObject(res.data, ['data', 'successUrl'])
        ) {
          Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
            domain: '.aegis-frk.com',
          });
          // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
          Cookies.set('username', userName);
          Cookies.set('firstLoginSession', true);
          window.location.href = this.state.loginUrl;
        } else if (res.status === 200 && res.data.iamcode === 'IAM000') {
          this.setState({
            verificationCode: '',
          });

          if (getNestedObject(res.data, ['data', 'callbacks'])) {
            if (res.status === 401) {
              window.location.href = '/';
            }
            if (
              res.status === 200 &&
              res.data &&
              (res.data.data.authId || res.data.data.tokenId)
            ) {
              if (getNestedObject(res.data, ['data', 'successUrl'])) {
                window.location.href = sanitizeUrl(`/profile/dashboard`);
                Cookies.set('userLoggedIn', true);
              } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
                const {
                  data: {
                    data: { callbacks },
                  },
                } = res;
                if (callbacks) {
                  if (callbacks[0].output[0].value === 'One Time Password') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 3,
                      loginPayLoad: res.data,
                      verificationCodeError:
                        'pages.forgotUserId.invalidVerificationCode',
                    });
                  } else if (
                    callbacks[0].output[0].value ===
                    'Choose Authentication Preference'
                  ) {
                    this.showError('pages.login.loginFailure');
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 12,
                      loginPayLoad: res.data,
                    });
                  } else if (callbacks[0].type === 'ChoiceCallback') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 6,
                      loginPayLoad: res.data,
                      userOption: res.data.callbacks[0].output,
                    });
                  } else {
                    this.setState({
                      userOption: res.data.callbacks[0].output[1].value,
                      isAuthenticated: true,
                      loginToken: res.data.dataauthId,
                      screen: 2,
                      loginPayLoad: res.data,
                    });
                  }
                }
              }
            }
          } else if (
            res.data.code === 401 &&
            res.data.message ===
              'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
          ) {
            this.showError('pages.login.willGetLocked');
          } else if (
            res.data.code === 401 &&
            res.data.message ===
              'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
          ) {
            this.showError('pages.login.willGetLocked2');
          } else if (
            res.data.code === 401 &&
            res.data.message === 'Login failure'
          ) {
            window.location.href = '/';
            this.showError('pages.login.loginFailure');
          }
        } else {
          this.setState({
            alertText: 'Some error occured.',
            alertStatus: 'error',
          });
        }
      });
    }
  };

  onOneTimePasswordSubmit = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const {
      loginPayLoad,
      verificationCode,
      loginToken,
      userName,
      socialLogin,
      authID,
    } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      let url = '';
      if (socialLogin) {
        url =
          'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
      } else {
        url = 'userLogin';
      }
      http(
        {
          url: url,
          data: {
            authId: authID,
            OTPChoice: 'Validate',
            'User Name': userName,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data.iamcode === 'IAM000') {
          const {
            data: {
              data: { authId },
            },
          } = res;
          this.setState(
            {
              authID: authId,
            },
            () => {
              http(
                {
                  url: url,
                  method: 'POST',
                  data: {
                    'User Name': userName,
                    authId: this.state.authID,
                    'One Time Password': verificationCode,
                  },
                },
                {
                  'Accept-API-Version': 'protocol=1.0,resource=2.1',
                  ...this.getHeaders(),
                }
              ).then((res) => {
                if (getNestedObject(res.data, ['data', 'successUrl'])) {
                  window.location.href = sanitizeUrl(`/profile/dashboard`);
                  // const environment = process.env.REACT_APP_ENV;
                  Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
                    domain: '.aegis-frk.com',
                  });
                  // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
                  Cookies.set('username', userName);
                  Cookies.set('firstLoginSession', true);
                  // if (environment === 'dev') {
                  //   window.location.href =
                  //     'https://cloudfront.aegis-frk.com/profile/dashboard';
                  // } else {
                  //   window.location.href = 'http://localhost:3000/profile/dashboard';
                  // }
                  window.location.href = this.state.loginUrl;
                } else if (
                  res.status === 401 &&
                  res.data.iamcode === 'IAM0039'
                ) {
                  this.showError('errors.maxVerifyAttempt');
                  setTimeout(() => {
                    window.location.href = sanitizeUrl(`/`);
                  }, 3000);
                } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
                  if (res.status === 401) {
                    window.location.href = '/';
                  }
                  if (
                    res.status === 200 &&
                    res.data &&
                    (res.data.data.authId || res.data.data.tokenId)
                  ) {
                    if (getNestedObject(res.data, ['data', 'successUrl'])) {
                      window.location.href = sanitizeUrl(`/profile/dashboard`);
                      // localStorage.setItem('userLoggedIn', true);
                      Cookies.set('userLoggedIn', true);
                    } else if (
                      getNestedObject(res.data, ['data', 'callbacks'])
                    ) {
                      const {
                        data: {
                          data: { callbacks },
                        },
                      } = res;
                      if (callbacks) {
                        if (
                          callbacks[0].output[0].value === 'OTP Preference '
                        ) {
                          this.setState({
                            isAuthenticated: true,
                            authID: res.data.data.authId,
                            screen: 3,
                            loginPayLoad: res.data,
                            verificationCodeError:
                              'pages.forgotUserId.invalidVerificationCode',
                          });
                        } else if (
                          callbacks[0].output[0].value ===
                          'Choose Authentication Preference'
                        ) {
                          this.showError('pages.login.loginFailure');
                          this.setState({
                            isAuthenticated: true,
                            loginToken: res.data.data.authId,
                            screen: 12,
                            loginPayLoad: res.data,
                          });
                        } else if (callbacks[0].type === 'ChoiceCallback') {
                          this.setState({
                            isAuthenticated: true,
                            loginToken: res.data.data.authId,
                            screen: 6,
                            loginPayLoad: res.data,
                            userOption: res.data.callbacks[0].output,
                          });
                        } else {
                          this.setState({
                            userOption: res.data.callbacks[0].output[1].value,
                            isAuthenticated: true,
                            loginToken: res.data.dataauthId,
                            screen: 2,
                            loginPayLoad: res.data,
                          });
                        }
                      }
                    }
                  }
                } else if (
                  res.data.code === 401 &&
                  res.data.message ===
                    'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
                ) {
                  this.showError('pages.login.willGetLocked');
                } else if (
                  res.data.code === 401 &&
                  res.data.message ===
                    'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
                ) {
                  this.showError('pages.login.willGetLocked2');
                } else if (
                  res.data.code === 401 &&
                  res.data.message === 'Login failure'
                ) {
                  window.location.href = '/';
                  this.showError('pages.login.loginFailure');
                }
              });
            }
          );
        } else {
          this.setState({
            alertText: 'Some error occured.',
            alertStatus: 'error',
          });
        }
      });
    }
  };

  getPushNotification = () => {
    const { loginPayLoad, socialLogin } = this.state;
    this.scrollToTop();
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url =
        'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
                if (this.state.qrCodeUrl.includes('pushauth')) {
                  setTimeout(() => {
                    setInterval(this.getPushNotification(), 10000);
                  }, 2000);
                  this.setState({
                    countInterval: this.state.countInterval + 1,
                  });
                }
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 6,
                  loginPayLoad: res.data,
                  userOption: res.data.callbacks[0].output,
                });
              } else if (callbacks[0].type === 'PollingWaitCallback') {
                this.setState({
                  loginPayLoad: res.data,
                  screen: 7,
                  userOption: res.data.callbacks[0].output,
                });
                setInterval(this.getPushNotification(), 3000);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'Login failure'
      ) {
        window.location.href = '/';
        //this.showError('errors.loginApiError');
      }
    });
  };

  onNext = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginToken, selectedOption, socialLogin, userName } = this.state;
    // loginPayLoad.callbacks[0].input[0].value = selectedOption;
    let url = '',
      mfaPreference = '';

    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'userLogin';
    }

    if (selectedOption === 0) {
      mfaPreference = 'OTP over Email';
    } else if (selectedOption === 1) {
      mfaPreference = 'Authenticator App';
    } else if (selectedOption === 2) {
      mfaPreference = 'Push Authentication';
    } else if (selectedOption === 3) {
      mfaPreference = 'OTP over SMS';
    } else if (selectedOption === 4) {
      mfaPreference = 'OTP over Phone Call';
    }

    http(
      {
        url: url,
        method: 'POST',
        data: {
          'User Name': userName,
          authId: loginToken,
          choice: mfaPreference,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      if (getNestedObject(res.data, ['data', 'successUrl'])) {
        window.location.href = sanitizeUrl(`/profile/dashboard`);

        window.location.href = this.state.loginUrl;
      } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.data.authId || res.data.data.tokenId)
        ) {
          if (getNestedObject(res.data, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
            const {
              data: {
                data: { callbacks },
              },
            } = res;
            if (callbacks) {
              if (
                callbacks[0].output[0].value === 'One Time Password' ||
                callbacks[0].output[0].value.trim() === 'OTP Preference'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.data.authId,
                  authID: res.data.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: callbacks[0].output[0].value,
                  qrCodeUrl: callbacks[2].output[0].value,
                });
                if (this.state.qrCodeUrl.includes('pushauth')) {
                  setInterval(this.getPushNotification(), 2000);
                  this.setState({
                    countInterval: this.state.countInterval + 1,
                  });
                }
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 6,
                  loginPayLoad: res.data,
                  userOption: '',
                });
              } else if (callbacks[0].type === 'PollingWaitCallback') {
                this.setState({
                  loginPayLoad: res.data,
                  screen: 7,
                  userOption: res.data.callbacks[0].output,
                });
                setInterval(this.getPushNotification(), 3000);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      }
    });
    this.scrollToTop();
  };

  onNextRegister = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginPayLoad, selectedRegisterOption, socialLogin } = this.state;
    if (selectedRegisterOption === 'Yes') {
      loginPayLoad.callbacks[0].input[0].value = 0;
    } else if (selectedRegisterOption === 'No') {
      loginPayLoad.callbacks[0].input[0].value = 1;
    }
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url =
        'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = sanitizeUrl(`/profile/dashboard`);
        // const environment = process.env.REACT_APP_ENV;
        Cookies.set('firstLoginSession', true);

        window.location.href = this.state.loginUrl;
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'User Locked Out.'
      ) {
        this.showError('pages.login.userLockedOut');
        window.location.href = '/';
      }
    });
  };
  createSession = () => {
    createNewSession(
      'Fingerprint',
      'firstname,did,userIdList',
      'qrcode',
      null,
      function (result, error) {
        client_dataRecieved(result);
      }
    );
  };
  client_dataRecieved = (result) => {
    let str = JSON.stringify(result, null, 4);
    var aurl =
      'http://forgerock.blockid.co/XUI/?realm=/&service=BlockIDTree&BlockIDAuthn=' +
      window.btoa(str);
    window.location.href = aurl;
  };
  generateQRCode = () => {
    let loginQRCode =
      'http://chart.apis.google.com/chart?cht=qr&chl=google.com&chs=248';
    this.setState({
      loginQRCode: loginQRCode,
    });
    this.createSession();
  };
  onNextQR = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginToken, socialLogin, qrCodeUrl, userName } = this.state;
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'userLogin';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: {
          'User Name': userName,
          authId: loginToken,
          mfaDeviceRegistration: qrCodeUrl,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      // this.setState({ authId: res.data.data.authId });
      // this.setState({screen: 6});

      if (
        res.status === 200 &&
        res.data &&
        (res.data.data.authId || res.data.data.tokenId)
      ) {
        if (getNestedObject(res.data, ['data', 'callbacks'])) {
          const {
            data: {
              data: { callbacks },
            },
          } = res;
          if (callbacks) {
            if (callbacks[0].output[0].value === 'One Time Password') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 3,
                loginPayLoad: res.data,
              });
            } else if (
              callbacks[0].output[0].value.includes('Scan the barcode')
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 4,
                loginPayLoad: res.data,
                scanTitle: callbacks[0].output[0].value,
                qrCodeUrl: callbacks[2].output[0].value,
              });
              if (this.state.qrCodeUrl.includes('pushauth')) {
                setInterval(this.getPushNotification(), 3000);
                this.setState({
                  countInterval: this.state.countInterval + 1,
                });
              }
            } else if (
              callbacks[0].output[0].value === 'Enter verification code'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 5,
                loginPayLoad: res.data,
              });
            } else if (callbacks[0].type === 'ChoiceCallback') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 6,
                loginPayLoad: res.data,
                userOption: res.data.callbacks[0].output,
              });
            } else if (
              callbacks[0].output[0].value ===
              'Choose Authentication Preference'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 12,
                loginPayLoad: res.data,
              });
            } else {
              this.setState({
                userOption: callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 2,
                loginPayLoad: res.data,
              });
            }
          }
        }
      }
    });
  };

  render() {
    const {
      userName,
      password,
      showPassword,
      errorText,
      errorArray,
      screen,
      userOption,
      selectedOption,
      verificationCode,
      verificationCodeError,
      verificationOption,
      scanTitle,
      qrCodeUrl,
      selectedRegisterOption,
      carouselImages,
      imageIndex,
      redirect,
      alertText,
      showalert,
      loginQRCode,
    } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { showalert },
          }}
        />
      );
    }

    const { t } = this.props;
    return (
      <>
        {/* {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )} */}
        {!this.state.isGooglein && (
          <div
            className={`login-container
            ${screen > 1 ? 'has-background' : undefined}`}
          >
            {(errorText || errorArray.length > 0) && (
              <div className="error-div">
                <WithError errors={errorText} errorArray={errorArray} />
              </div>
            )}
            {/* {this.props.match.path==='/forgot-password' && 
          (<div>Error</div>)} */}
            {screen === 1 && (
              <div className="log-container">
                <div className="row justify-content-center">
                  <div className="col-md-6 p-0">
                    <div className="mt-3">
                      <AlertGlobal useTicker={false}></AlertGlobal>
                    </div>

                    <div className="row justify-content-center pt-5">
                      <Form className="login-form p-3">
                        <h1>{t('pages.login.login')}</h1>
                        <Form.Group controlId="loginEmail">
                          <Form.Label>{t('pages.login.userId')}</Form.Label>
                          <Form.Control
                            type="email"
                            onChange={(e) => {
                              this.setState({
                                userName: e.target.value.trim(),
                              });
                            }}
                            value={userName}
                            aria-required
                          />
                        </Form.Group>
                        <Form.Group
                          className="password-field position-relative"
                          controlId="loginPassword"
                        >
                          <Form.Label>{t('pages.login.password')}</Form.Label>
                          <Form.Control
                            className="login-password-input"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                              this.PasswordValidation(e);
                            }}
                            value={password}
                            aria-required
                            autoComplete="off"
                          />
                          <span
                            className={
                              showPassword
                                ? 'eye-icon login-password-eye show'
                                : 'eye-icon login-password-eye'
                            }
                            onClick={() => this.showPassword()}
                          />
                        </Form.Group>
                        <div className="row justify-content-center mt-3">
                          <div className="col">
                            <Button
                              className="btn w-100 btn btn-primary"
                              type="submit"
                              onClick={(e) => this.onLogin(e)}
                            >
                              {t('pages.login.logInBtnText')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    {/*
                  <div className="row">
                    <div className="col text-center">
                      {loginQRCode == '' && (
                        <button
                          className="unstyled-button mb-2"
                          onClick={this.generateQRCode}
                        >
                          {t('pages.login.scanLogin')}{' '}
                          <img src={qrcode} alt="qrcode" className="qrcode" />
                        </button>
                      )}

                      {loginQRCode != '' && (
                        <button
                          className="unstyled-button mb-2"
                          onClick={this.generateQRCode}
                        >
                          {t('pages.login.scanLoginNew')}{' '}
                          <img src={qrcode} alt="qrcode" className="qrcode" />
                        </button>
                      )}

                      {loginQRCode != '' && (
                        <div className="col text-center">
                          {/*<div
                            id="qrcode"
                            style="z-index: 1;position:relative;margin:10px"
                            align="center"
                          ></div>
                          */}
                    {/*
                          <QRCode value={loginQRCode} />
                        </div>
                      )}
                    </div>
                  </div>
                          */}

                    <div className="row">
                      <div className="col text-center">
                        <p className="mb-2">{t('pages.login.withText')}</p>
                        {/* <span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              src={googleLogo}
                              alt="google"
                              onClick={(e) => this.onSocialLogin(e)}
                            />
                          </a>
                        </span>
                        <span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              style={{ 'max-height': '3.5rem' }}
                              src={passwordlessAuthLogo}
                              alt="PasswordlessAuth"
                              onClick={(e) => this.onPasswordlessLogin(e)}
                            />
                          </a>
                        </span>
                        <span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              style={{ 'max-height': '3.5rem' }}
                              src={hyprLogo}
                              alt="hypr"
                              onClick={(e) => this.onhyprLogin(e)}
                            />
                          </a>
                        </span>

                        <span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              style={{ 'max-height': '3.5rem' }}
                              src={kosmosLogo}
                              alt="1kosmos"
                              onClick={(e) => this.on1kosmosLogin(e)}
                            />
                          </a>
                        </span> */}
                        {/*<span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              style={{ 'max-height': '3.5rem' }}
                              src={yubiKeyLogo}
                              alt="Yubikey NFC"
                              onClick={(e) => this.onYubikeyLogin(e)}
                            />
                          </a>
                        </span>*/}

                        <span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              style={{ 'max-height': '3.5rem' }}
                              src={uib}
                              alt="uib"
                              onClick={(e) => this.onUIBLogin(e)}
                            />
                          </a>
                        </span>

                        {/* <span className="mr-sm-4 py-2 my-3">
                          <a>
                            <img
                              style={{ 'max-height': '3.5rem' }}
                              src={pivCacLogo}
                              alt="pivcac"
                              onClick={(e) => this.onpivCacLogin(e)}
                            />
                          </a>
                        </span> */}
                      </div>
                    </div>
                    <hr className="separator-login" />
                    <div className="row justify-content-center">
                      <Form className="login-form p-3">
                        <Form.Group>
                          <p style={{ textAlign: 'center' }}>
                            <span className="signup-option-text">
                              {t('pages.login.signUpCustomer')}
                            </span>
                            <span className="sign-up-text-right">
                              <Button
                                style={{ 'margin-left': '1rem' }}
                                className="btn1 btn-login"
                                onClick={() => {
                                  window.location.href = '/registration';
                                }}
                              >
                                {t('pages.login.registerNow')}
                              </Button>
                            </span>
                          </p>
                          {/* <p className="login-secondOption">
                            <span className="signup-option-text">
                              {t('pages.login.signUpBusiness')}
                            </span>
                            <span className="sign-up-text-right">
                              <Button
                                className="btn1 btn-login"
                                style={{ 'margin-left': '1rem' }}
                                onClick={() => {
                                  window.location.href =
                                    '/business-registration';
                                }}
                              >
                                {t('pages.login.registerNow')}
                              </Button>
                            </span>
                          </p> */}
                        </Form.Group>
                      </Form>
                    </div>
                    {/* <div className="row justify-content-center mb-5">
                      <div className="forget-username-mobile col-sm-4 pb-3 pb-sm-0 text-center text-sm-left">
                        <Link to="/forgot-userId" className="text-blue">
                          {t('pages.login.forgotUserIdLink')}
                        </Link>
                      </div>
                      <div className="forget-password-mobile col-sm-4 text-center text-sm-right">
                        <Link to="forgot-password" className="text-blue">
                          {t('pages.login.forgotPasswordLink')}
                        </Link>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-md-6 p-0">
                    <div
                      className="hero-img-bg"
                      style={{
                        backgroundImage: `url(${
                          carouselImages[imageIndex || 0].image
                        })`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {screen === 2 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  {/* {localStorage.getItem('email') && ( */}
                  {Cookies.get('email') && (
                    <div>
                      {t('pages.login.socialLoggedIn')}{' '}
                      <span className="bold">
                        {/* {localStorage.getItem('email')} */}
                        {Cookies.get('email')}
                      </span>
                    </div>
                  )}
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.choseMFA')}
                  </div>
                  <div>
                    <Form className="login-radio-buttons">
                      {userOption.length > 0 &&
                        userOption.map((payLoad, index) => (
                          <Radio
                            key={index}
                            name={payLoad}
                            label={payLoad}
                            value={index}
                            isChecked={selectedOption === index ? true : false}
                            handleChange={this.onOptionsChange}
                          />
                        ))}
                      <div className="row mt-3 mt-sm-5">
                        <div className="d-sm-flex col-lg-6 col-md-12">
                          <Button
                            className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                            onClick={(e) => this.onBackLogin(e)}
                          >
                            {t('pages.forgotPassword.backBtn')}
                          </Button>
                          <Button
                            className="col btn btn-secondary py-2 my-3 "
                            type="submit"
                            onClick={(e) => this.onNext(e)}
                            disabled={selectedOption === ''}
                          >
                            {t('pages.editNumberPopup.next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}
            {screen === 3 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <h3>
                    <span>{t('pages.registration.verificationCodeSent')}</span>
                  </h3>
                  <Form>
                    <Form.Group
                      className="verification-form mt-5"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4">
                        {t('pages.registration.verificationCode')}
                      </Form.Label>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        onInput={this.handleChange.bind(this)}
                        className="ml-3"
                        value={verificationCode}
                      />
                      {verificationCodeError && (
                        <p className="error-text" id="verification-error">
                          {verificationCodeError && t(verificationCodeError)}
                        </p>
                      )}
                    </Form.Group>
                    <div className="registration__container__resend-verification__resend-text">
                      <p
                        className="resend-link"
                        onClick={(e) => this.resendVerify(e)}
                        role="button"
                        tabIndex={0}
                      >
                        {t('pages.registration.resendVerificationCode')}
                      </p>
                    </div>
                    <div className="registration__container__pleasenote">
                      <p className="registration__container__pleasenote-fp">
                        {t('pages.registration.note')}
                      </p>
                      <p className="registration__container__pleasenote-sp">
                        {t('pages.registration.verifyNote')}
                      </p>
                    </div>
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>

                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onOneTimePasswordSubmit(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {screen === 4 && (
              <>
                <div className="radiobox-container container my-5 forgot-username barcode-center">
                  <h4 className="mb-5">
                    <span>{scanTitle}</span>
                  </h4>
                  <QRCode value={qrCodeUrl} />
                  {qrCodeUrl.includes('pushauth') && (
                    <div className="mb-3 mt-5">
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="fa-spin"
                        role="img"
                      />
                      <span className="ml-3">
                        {t('pages.login.waitingResponse')}
                      </span>
                    </div>
                  )}
                  {!qrCodeUrl.includes('pushauth') && (
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3 "
                          type="submit"
                          onClick={(e) => this.onNextQR(e)}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {screen === 5 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <h3>
                    <span>{t('pages.login.verificationApp')}</span>
                  </h3>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.registration.verificationCode')}
                      </Form.Label>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        onInput={this.handleChange.bind(this)}
                        className="ml-3"
                        value={verificationCode}
                      />
                      {verificationCodeError && (
                        <p className="error-text" id="verification-error">
                          {verificationCodeError && t(verificationCodeError)}
                        </p>
                      )}
                    </Form.Group>
                    <div className="registration__container__pleasenote">
                      <p className="registration__container__pleasenote-fp">
                        {t('pages.registration.note')}
                      </p>
                      <p className="registration__container__pleasenote-sp">
                        {t('pages.registration.verifyNote')}
                      </p>
                    </div>
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onVerificationCodeSubmit(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {screen === 6 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <div className="mt-3 mb-3 choose-title">
                    {userOption[0].value}
                  </div>
                  <div>
                    <Form>
                      <Radio
                        key={'Yes'}
                        name={'Yes'}
                        label={'Yes'}
                        value={'Yes'}
                        isChecked={
                          selectedRegisterOption === 'Yes' ? true : false
                        }
                        handleChange={this.onDeviceOptionsChange}
                      />
                      <Radio
                        key={'No'}
                        name={'No'}
                        label={'No'}
                        value={'No'}
                        isChecked={
                          selectedRegisterOption === 'No' ? true : false
                        }
                        handleChange={this.onDeviceOptionsChange}
                      />
                      <div className="row pt-3">
                        <div className="d-sm-flex col-lg-6 col-md-12">
                          <Button
                            className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                            onClick={(e) => this.onBackLogin(e)}
                          >
                            {t('pages.forgotUserId.backBtn')}
                          </Button>
                          <Button
                            className="col btn btn-secondary py-2 my-3"
                            type="submit"
                            onClick={(e) => this.onNextRegister(e)}
                            disabled={selectedRegisterOption === ''}
                          >
                            {t('pages.editNumberPopup.next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}
            {screen === 7 && (
              <>
                <div className="radiobox-container container my-5 forgot-username waiting-response">
                  <div className="mt-3 mb-3 choose-title">
                    {userOption[1].value}
                  </div>
                </div>
              </>
            )}
            {screen === 9 && (
              <>
                <div className="radiobox-container container my-5 forgot-username waiting-response">
                  <div className="mt-3 mb-3 choose-title">
                    {userOption[1].value}
                  </div>
                </div>
              </>
            )}

            {screen === 8 && (
              <>
                <div className="radiobox-container container my-5 forgot-username hypr">
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.hyprLogin')}
                  </div>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.login.userId')} /{' '}
                        {t('pages.registration.userName')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.setState({
                            userName: e.target.value.trim(),
                          });
                        }}
                        value={userName}
                      />
                    </Form.Group>

                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onHyprAuthentication(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {screen === 10 && (
              <>
                <div className="radiobox-container container my-5 1kosmos barcode-center">
                  <h4 className="mb-5">
                    <span>{t('pages.login.1kosmosTitle')}</span>
                  </h4>
                  <QRCode value={this.state.qrURL} />
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3 "
                        type="submit"
                        onClick={(e) => this.on1kosmosLogin(e)}
                      >
                        {t('pages.forgotUserId.generateQRBtn')}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {screen === 11 && (
              <YubikeyLogin
                userName={userName}
                onBackLogin={this.onBackLogin}
                loginResponse={this.loginResponse}
                showError={this.showError}
              />
            )}

            {screen === 12 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <h3>
                    <span>
                      {t('pages.registration.verificationCodeSentTo')}{' '}
                      {t('pages.registration.authenticatorApp')}
                    </span>
                  </h3>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.registration.verificationCode')}
                      </Form.Label>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        onInput={this.handleChange.bind(this)}
                        className="ml-3"
                        value={verificationCode}
                      />
                      {verificationCodeError && (
                        <p className="error-text" id="verification-error">
                          {verificationCodeError && t(verificationCodeError)}
                        </p>
                      )}
                    </Form.Group>
                    <div className="registration__container__pleasenote">
                      <p className="registration__container__pleasenote-fp">
                        {t('pages.registration.note')}
                      </p>
                      <p className="registration__container__pleasenote-sp">
                        {t('pages.registration.verifyNote')}
                      </p>
                    </div>
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onDeregister(e)}
                          type="submit"
                        >
                          {t('pages.forgotUserId.deRegisterBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onResubmit(e)}
                          type="submit"
                        >
                          {t('pages.login.reSubmit')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}

            {screen === 13 && (
              <>
                <div className="radiobox-container container my-5 forgot-username passwordless">
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.passwordLessLogin')}
                  </div>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.registration.userName')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.setState({
                            userName: e.target.value.trim(),
                          });
                        }}
                        value={userName}
                      />
                    </Form.Group>

                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onPasswordlessAuthentication(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}

            {screen === 14 && (
              <>
                <div className="radiobox-container container my-5 forgot-username hypr">
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.passwordLessLogin')}
                  </div>
                  <Form>
                    <Form.Group
                      className="password-field position-relative"
                      controlId="loginPassword"
                      style={{ width: '48%' }}
                    >
                      <Form.Label>{t('pages.login.password')}</Form.Label>
                      <Form.Control
                        className="login-password-input"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                          this.PasswordValidation(e);
                        }}
                        value={password}
                        aria-required
                        autoComplete="off"
                      />
                      <span
                        className={
                          showPassword
                            ? 'eye-icon login-password-eye show'
                            : 'eye-icon login-password-eye'
                        }
                        onClick={() => this.showPassword()}
                      />
                    </Form.Group>

                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onPasswordlessRegistration(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}
LoginCustomWallet.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
    history: PropTypes.object,
    search: PropTypes.object,
  }).isRequired,
  action: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withTranslation()(LoginCustomWallet);
