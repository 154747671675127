import React from 'react';
import 'app/modules/Shared/FormNavigationBar/FormNavigationBar.scss';
import PropTypes from 'prop-types';
import { Component } from 'react';

class FormNavigationBar extends Component {
  constructor(props) {
    super(props);
  }

  createNavBar = () => {
    const { currentStep, numFormSteps, stepNames, t } = this.props;
    let navList = [];
    for (let x = 0; x < numFormSteps; x++) {
      navList.push(
        <li
          className={currentStep === x ? 'active' : ''}
          key={`listItem${x + 1}`}
        >
          <span>{x + 1}</span>
          <p className="m-0">{t(stepNames[x])}</p>
        </li>
      );
    }
    return navList;
  };

  render() {
    return (
      <div className="wayfinder-horizontal">
        <ul className="d-flex">{this.createNavBar().map((item) => item)}</ul>
      </div>
    );
  }
}

FormNavigationBar.propTypes = {
  t: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  numFormSteps: PropTypes.number,
  stepNames: PropTypes.array,
};

export default FormNavigationBar;
