import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const GoogleCaptcha = (props) => {
  const { sitekey, onCaptchaChange, captchaReset } = props;
  let captcha;
  const { i18n } = useTranslation();

  const [key, setKey] = useState(0);

  useEffect(() => {
    if (captchaReset) {
      captcha.reset();
    }
  });

  useEffect(() => {
    setKey((prevKey) => ++prevKey);
  }, [i18n.language]);

  return (
    <ReCAPTCHA
      key={key}
      sitekey={sitekey}
      onChange={onCaptchaChange}
      onExpired={() => {
        onCaptchaChange('');
      }}
      ref={(e) => {
        captcha = e;
      }}
      hl={i18n.language}
    />
  );
};

GoogleCaptcha.propTypes = {
  sitekey: PropTypes.string.isRequired,
  onCaptchaChange: PropTypes.func.isRequired,
  captchaReset: PropTypes.bool,
};
GoogleCaptcha.defaultProps = {
  captchaReset: false,
};
export default GoogleCaptcha;
