import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Tab,
  Tabs,
  Breadcrumb,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import idProofingAnimation from 'assets/json/idproofing_banner.json';
import bulletAnimation from 'assets/json/bullet.json';

import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import 'app/modules/Dashboard/Dashboard.scss';
import 'app/modules/Dashboard/Apps.scss';
import 'app/modules/Dashboard/WalletMobile.scss';
import Cookies from 'js-cookie';
import 'app/modules/IdProofing/IdProofingBanner.scss';
import http from 'api/http-request';
import background from 'assets/img/back3.png';
import { sanitizeUrl } from '@braintree/sanitize-url';

const WalletMobile = () => {
  const paymentTypes = [
    {
      cardHolder: 'John Smith',
      cardNumber: '4012888888881881',
      ccv: '123',
      expDate: '08/23',
      last4Digits: '1234',
      paymentType: 'Visa',
    },
    {
      cardHolder: 'John Smith',
      cardNumber: '5105105105105100',
      ccv: '321',
      expDate: '04/22',
      last4Digits: '5678',
      paymentType: 'Mastercard',
    },
  ];

  const { t } = useTranslation();
  const [newPaymentMethod, setNewPaymentMethod] = useState(false);
  const [hiddenCardNumList, setHiddenCardNumList] = useState(
    paymentTypes.map(() => true)
  );
  const [hiddenCVVList, setHiddenCVVList] = useState(
    paymentTypes.map(() => true)
  );

  const [showBannerLocalStorage, setShowBannerLocalStorage] = useState(false);
  const [idProofing, setIdProofing] = useState(false);
  const [pingOne, setPingOne] = useState(Cookies.get('walletRegistered'));

  const [documentIssued, setDocumentIssued] = useState();
  const [documentIssuedwoConsent, setDocumentIssuedwoConsent] = useState();

  useEffect(() => {
    searchAPI();
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });

    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
  }, []);

  const searchAPI = () => {
    let username = Cookies.get('username');

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      pattern: username,

      attribute: 'userName',

      operation: 'eq',
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };

    http({
      url: '/aegis-managed-service/aegis/api/searchUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 &&
        ((res.data.iamcode == 'IAM0028' &&
          res.data.response == 'Access Denied') ||
          (res.data.iamcode == 'IAM0039' &&
            res.data.response == 'Unauthorized'))
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = sanitizeUrl('/mobile');
      } else if (res.status == 200) {
        if (res.data.data.result[0].idProofData) {
          Cookies.set('walletRegistered', true);
          setPingOne(true);

          let data = res.data.data.result[0].idProofData
            .split('|')
            .map((item) => JSON.parse(item));

          if (data[0].dataConsent == 'allowed') {
            setDocumentIssued(
              res.data.data.result[0].idProofData
                .split('|')
                .map((item) => JSON.parse(item))
            );
          } else {
            setDocumentIssuedwoConsent(
              res.data.data.result[0].idProofData
                .split('|')
                .map((item) => JSON.parse(item))
            );
          }
        }
      }
    });
  };

  const banner = {
    loop: true,
    autoplay: true,
    animationData: idProofingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const bullet = {
    loop: true,
    autoplay: true,
    animationData: bulletAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const myDocumentsHTML = () => {
    if (pingOne === true) {
      return (
        <div className="my-5" style={{ width: '100%' }}>
          <div className="row mx-5 mb-5">
            <p>
              Welcome,<b> {Cookies.get('userName')}</b>
              <br />
              List of your verified Documents
            </p>
          </div>

          {documentIssued
            ?.reduce((rows, documentsIssued, index) => {
              if (index % 3 === 0) {
                rows.push([]);
              }

              rows[rows.length - 1].push(
                <div className="col-lg-3 col-md-3 mx-5" key={index}>
                  <div className="row">
                    <div
                      className="crad-mobile"
                      style={{
                        backgroundImage: `url(${background})`,
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-10 text-left">
                          <div className="text-vcard">
                            <b>{documentsIssued?.docType}</b>.
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  First Name:
                                </strong>{' '}
                                {documentsIssued?.firstName}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Last Name:
                                </strong>{' '}
                                {documentsIssued?.lastName}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Date of Birth:
                                </strong>{' '}
                                {documentsIssued?.dOb}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Gender:
                                </strong>{' '}
                                {documentsIssued?.gender}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Document ID:
                                </strong>{' '}
                                {documentsIssued?.docID}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Expiration Date:
                                </strong>{' '}
                                {documentsIssued?.docExp}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Country:
                                </strong>{' '}
                                {documentsIssued?.country}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div className="row" key={rowIndex}>
                {row}
              </div>
            ))}

          {documentIssuedwoConsent
            ?.reduce((rows, documentIssuedwoConsent, index) => {
              if (index % 3 === 0) {
                rows.push([]);
              }

              rows[rows.length - 1].push(
                <div className="col-lg-3 col-md-3 mx-5" key={index}>
                  <div className="row">
                    <div
                      className="crad-mobile"
                      style={{
                        backgroundImage: `url(${background})`,
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-10 text-left">
                          <div className="text-vcard">
                            <b>{documentIssuedwoConsent?.docType}</b>.
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Doc Type:
                                </strong>{' '}
                                {documentIssuedwoConsent?.docType}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Doc Expiry:
                                </strong>{' '}
                                {documentIssuedwoConsent?.docExp}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Aegis ID:
                                </strong>{' '}
                                {documentIssuedwoConsent?.frID}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Issuer Name:
                                </strong>{' '}
                                {documentIssuedwoConsent?.issuerName}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Issuer ID:
                                </strong>{' '}
                                {documentIssuedwoConsent?.issuerId}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="mb-2">
                              <div className="op">
                                <strong className="font-bolder-new">
                                  Issued On:
                                </strong>{' '}
                                {documentIssuedwoConsent?.eventTimestamp}
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div className="row" key={rowIndex}>
                {row}
              </div>
            ))}

          <div className="row mx-5">
            <div className="col-sm-3 p-0">
              <a href="https://auth.pingone.com/916fd1e1-7806-45f1-9663-bbe43dd7e5aa/as/authorize?response_type=code&client_id=0547853d-e541-4b0f-a8fc-99714c6d8c91&scope=openid&redirect_uri=https://cloudfront.aegis-frk.com/profile/Wallet">
                <Button className="btn btn-secondary py-2 my-2">
                  {t('pages.walletDocuments.addMore')}
                </Button>
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="tab-area pb-5">
        <div className="row mx-3 mb-3">
          <h1>{t('pages.BreadCrumb.wallet')}</h1>
        </div>
        {myDocumentsHTML()}
      </div>
    </div>
  );
};

WalletMobile.propTypes = {};

export default WalletMobile;
