import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/ForgotPassword/ForgotPassword.scss';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import { Redirect } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import http from 'api/http-request';

import {
  getNestedObject,
  isPasswordFormatValid,
  pageNotFound,
} from 'utils/common';
import Radio from 'app/modules/Shared/Radio/Radio';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import Alert from 'app/modules/Shared/Alert/Alert';
import WithError from '../Shared/WithError/WithError';
const CryptoJS = require('crypto-js');

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();

    this.state = {
      mail: '',
      recapSiteKey: '',
      authID: '',
      emailError: '',
      stepNames: [
        'pages.formNavigator.lookup',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification',
      ],
      maxStep: 2,
      screen: 0,
      redirect: false,
      showalert: '',
      errortext: '',
      errortextconfirm: '',
      currentStep: 0,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      token: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      responseToken: '',
      userName: '',
      verificationPlaceholder: ['0', '0', '0', '0', '0', '0'],
      securityQuestion1: '',
      securityQuestion2: '',
      securityQuestion3: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      errorText: '',
      passwordErrorText: '',
      errorArray: [],
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      changeMethod: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      securityQuestionError: '',
      userExist: '',
      radioOptionError: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getToken = (event) => {
    event.preventDefault();
    const { mail } = this.state;
    http(
      {
        url: 'resetForgotPassword',
        method: 'POST',
        data: {
          Email: mail,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        this.increaseCurrentStepHandler();

        const {
          data: {
            data: { callbacks, authId },
          },
        } = res;

        this.setState({
          recapSiteKey: callbacks[0].output[0].value,
          authID: authId,
          showGoogleCaptcha: true,
          screen: 1,
        });
      } else if (res.data.data.Message === 'Login failure') {
        if (mail === '') {
          this.setState({
            emailError: true,
            userExist: false,
          });
        } else {
          this.setState({
            userExist: true,
            emailError: false,
          });
        }
      } else {
        this.setState({
          alertText: 'Some error occured.',
          alertStatus: 'error',
        });
      }
    });
  };

  captchaValidation = () => {
    const { googleCaptchaResponse, authID } = this.state;
    http(
      {
        url: 'resetForgotPassword',
        method: 'POST',
        data: {
          authId: authID,
          RecaptchaValue: googleCaptchaResponse,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        const {
          data: {
            data: { callbacks, authId },
          },
        } = res;
        this.increaseCurrentStepHandler();
        this.setState({
          screen: 2,
          kba: callbacks[0].output[1].value[0],
          authID: authId,
        });
      } else {
        this.setState({
          alertText: 'Some error occured.',
          alertStatus: 'error',
        });
      }
    });
  };

  isEmailValid = (value) => {
    if (
      value.length <= 0 ||
      !value.match(
        /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
      )
    ) {
      this.setState({
        emailError: true,
        userExist: false,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
  };

  disableCopyPaste = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  increaseCurrentStepHandler = () => {
    const { recapSiteKey } = this.state;
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  decreaseCurrentStepHandler = (e) => {
    e.preventDefault();
    this.setState({
      userExist: false,
    });
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };
  sendToLogin = () => {
    return this.props.history.push('/');
  };

  onCaptchaChange = (value) => {
    if (value) {
      this.setState({
        googleCaptchaResponse: value,
      });
      this.captchaValidation();
    } else {
      this.setState({
        googleCaptchaResponse: '',
      });
    }
  };

  onnextBtn = () => {
    this.setState({
      screen: 4,
    });
  };

  submitNewPassword = (e) => {
    e.preventDefault();
    const { password, confirmPassword, authID } = this.state;
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    const hasPassword = this.isFormFieldValid(password, 'passwordError');
    const hasConfirmPassword = this.isFormFieldValid(
      confirmPassword,
      'confirmPasswordError'
    );
    const { t } = this.props;
    if (hasPassword || hasConfirmPassword) {
      this.setState({
        errortextConfirm: t('errors.required'),
        errortext: '',
      });
    } else {
      this.setState({
        errortextConfirm: '',
      });
      http(
        {
          url: 'resetForgotPassword',
          data: {
            authId: authID,
            newPassword: encrypted.toString(),
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data.data.successUrl == '/am/console') {
          this.increaseCurrentStepHandler();
          this.setState({
            screen: 5,
            passwordErrorText: '',
            errorArray: '',
          });
        } else if (res.data.iamcode === 'IAM0039') {
          this.setState({
            alertText: 'Some error occured.',
            alertStatus: 'error',
          });
        } else {
          this.setState({
            errortextConfirm: 'Password do not meet the requirements',
          });

          // this.setState({
          //   errortextConfirm: res.data.data.Message.errorMessage,
          // })

        }
      });
    }
  };

  onSubmit = () => {
    this.increaseCurrentStepHandler();
    this.setState({
      screen: 2,
    });
  };

  onOptionsChange = (value) => {
    if (value.includes('@')) {
      value = 'mail';
    } else {
      value = 'kba';
    }
    this.setState({
      selectedOption: value,
    });
  };

  isFormFieldValid = (e, key) => {
    const { t } = this.props;
    const value = e.target ? e.target.value.trim() : e;
    const { password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'passwordError':
        if (value.length <= 0) {
          errorMsg = t('errors.noPasswordError');
          this.setState({
            errortext: errorMsg,
          });
        } else if (isPasswordFormatValid(value)) {
          errorMsg = t('errors.invalidFormat');
          this.setState({
            errortextConfirm: errorMsg,
          });
        } else {
          this.setState({
            errortext: '',
          });
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = t('errors.noConfirmPasswordError');
          this.setState({
            errortextConfirm: errorMsg,
          });
        } else if (password !== confirmPassword) {
          errorMsg = t('errors.passwordsError');
          this.setState({
            errortextConfirm: errorMsg,
            errortext: '',
          });
        } else {
          this.setState({
            errortextConfirm: '',
          });
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    return errorMsg;
  };

  onVerify = (e) => {
    e.preventDefault();
    const { verificationCode, authID } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });

      http(
        {
          url: 'resetForgotPassword',
          data: {
            authId: authID,
            OTPChoice: 'Validate',
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data.iamcode === 'IAM000') {
          const {
            data: {
              data: { authId },
            },
          } = res;
          this.setState({
            authID: authId,
          });
        } else {
          this.setState({
            alertText: 'Some error occured.',
            alertStatus: 'error',
          });
        }

        http(
          {
            url: 'resetForgotPassword',
            data: {
              authId: this.state.authID,
              'One Time Password': verificationCode,
            },
            method: 'POST',
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        ).then((res) => {
          if (res.status === 200 && res.data.iamcode === 'IAM000') {
            const {
              data: {
                data: { authId, Message },
              },
            } = res;
            this.setState({
              authID: authId,
            });
            if (res.data.data.callbacks[0].output.length === 5) {
              this.setState({
                screen: 4,
              });
            } else {
              this.setState({
                verificationCodeError: 'pages.editEmail.invalidOTP',
              });
            }
          } else {
            this.setState({
              showalert: 'errors.maxVerifyAttempt',
            });
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 3000);
          }
        });
      });
    }
  };

  accountVerificationPreferenceChange = (e) => {
    e.preventDefault();
    const { authID } = this.state;

    http(
      {
        url: 'resetForgotPassword',
        data: {
          authId: authID,
          confirmationChoice: 'yes',
        },
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        const {
          data: {
            data: { authId },
          },
        } = res;
        this.setState({
          authID: authId,
          verificationOption: true,
          securityOption: false,
          screen: 3,
        });
      } else {
        this.setState({
          showalert: 'errors.maxQuestionAttempt',
        });
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);
      }
    });
  };

  onVerifyQuestion = (e) => {
    e.preventDefault();
    const { securityQuestionValue, authID, securityOption } = this.state;
    if (securityQuestionValue === '') {
      this.setState({
        securityQuestionError: 'pages.forgotUserId.securityQuestionRequired',
      });
    } else {
      http(
        {
          url: 'resetForgotPassword',
          data: {
            authId: authID,
            Question1: securityOption,
            Answer1: securityQuestionValue,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data.iamcode === 'IAM000') {
          const {
            data: {
              data: { authId },
            },
          } = res;
          this.setState({
            authID: authId,
          });
          if (res.data.data.callbacks[0].output.length === 5) {
            this.setState({
              screen: 4,
            });
          } else {
            this.setState({
              securityQuestionValue: '',
              securityQuestionError: 'pages.forgotUserId.invalidSecurityAnswer',
              securityOption: res.data.data.callbacks[0].output[0].value,
            });
          }
        } else {
          this.setState({
            showalert: 'errors.maxQuestionAttempt',
          });
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 3000);
        }
      });
    }
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });

    this.setState({
      errorText: '',
    });
    const { authID } = this.state;
    http(
      {
        url: 'resetForgotPassword',
        data: {
          authId: authID,
          OTPChoice: 'Resend',
        },
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        const {
          data: {
            data: { authId },
          },
        } = res;

        this.setState({
          verificationCode: '',
          verificationCodeError: '',
          authID: authId,
        });
      } else {
        this.setState({
          showalert: 'errors.maxOTPAttempt',
        });
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);
      }
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { authID, selectedOption } = this.state;
    let selectedValue;
    if (selectedOption === 'mail') {
      selectedValue = 'OTP over Email';
      this.setState({
        verificationOption: true,
      });
    } else {
      selectedValue = 'Security Questions';
      this.setState({
        securityOption: true,
      });
    }
    this.setState({
      errorText: '',
    });
    if (selectedValue) {
      http(
        {
          url: 'resetForgotPassword',
          data: {
            authId: authID,
            choice: selectedValue,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data.iamcode === 'IAM000') {
          const {
            data: {
              data: { callbacks, authId },
            },
          } = res;

          if (res.data.data.callbacks[0].output[0].name === 'message') {
            this.setState({
              screen: 6,
              authID: authId,
            });
            if (selectedValue === 'Security Questions') {
              this.setState({
                securityOption: callbacks[0].output[0].value,
              });
            }
          } else {
            this.setState({
              screen: 3,
              authID: authId,
            });
            if (selectedValue === 'Security Questions') {
              this.setState({
                securityOption: callbacks[0].output[0].value,
              });
            }
          }
        }
      });
    } else {
      this.setState({
        radioOptionError: 'pages.forgotUserId.radioCodeRequired',
      });
    }
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 0,
      currentStep: 0,
      verificationOption: '',
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      verificationCodeError: '',
      verificationCode: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      securityQuestionError: '',
      userExist: false,
      radioOptionError: '',
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  handleQuestionChange(evt) {
    const securityQuestionValue = evt.target.validity.valid
      ? evt.target.value
      : this.state.securityQuestionValue;

    this.setState({ securityQuestionValue });
  }

  render() {
    const {
      mail,
      errorMsg,
      recapSiteKey,
      securityQuestionValue,
      emailError,
      currentStep,
      maxStep,
      stepNames,
      screen,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
      userName,
      selectedOption,
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      verificationOption,
      verificationCodeError,
      userEmail,
      userPhone,
      kba,
      securityOption,
      securityQuestionError,
      userExist,
      verificationCode,
      alertText,
      alertStatus,
      radioOptionError,
      passwordErrorText,
      errorArray,
      redirect,
      showalert,
    } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { showalert },
          }}
        />
      );
    }

    const { t } = this.props;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="container forgot-username forgot-password my-5">
          {(passwordErrorText || errorArray.length > 0) && (
            <WithError errors={passwordErrorText} errorArray={errorArray} />
          )}
          <div className="col">
            <h1>{t('pages.forgotPassword.header1ForgotPassword')}</h1>
          </div>
          <div>
            <FormNavigationBar
              currentStep={currentStep}
              numFormSteps={maxStep + 1}
              stepNames={stepNames}
              t={t}
            />
          </div>
          {currentStep === 0 && screen === 0 && (
            <>
              <div className="col">
                <h3 className="m-0">
                  {t('pages.forgotPassword.instructions')}
                </h3>
              </div>
              <Form className="col-md-6 mt-3 pt-4">
                <Form.Group controlId="loginEmail">
                  <Form.Label>
                    {t('pages.forgotUserId.emailAddress')}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      this.setState({
                        mail: e.target.value.trim().toLowerCase(),
                      });
                      this.isEmailValid(e.target.value.trim().toLowerCase());
                    }}
                    value={mail}
                    className={
                      emailError || userExist ? 'error-input' : undefined
                    }
                    aria-required
                    aria-invalid={!!emailError}
                    aria-describedby="email-error"
                  />
                  <p
                    className="error-text forgot-error-username"
                    id="email-error"
                  >
                    {emailError && t('errors.emailError')}
                    {userExist && t('errors.userExist')}
                  </p>
                </Form.Group>
                <div className="row mt-5">
                  <div className="d-flex col-md-6">
                    <Button
                      className="wrapper__container-card-body-submit"
                      size="lg"
                      variant="secondary"
                      block
                      type="submit"
                      onClick={(e) => {
                        this.getToken(e);
                      }}
                    >
                      {t('pages.forgotPassword.nextBtn')}
                    </Button>
                  </div>
                </div>
                <div>
                  <p className="return-text mt-4">
                    {t('pages.forgotUserId.returnTo')}
                    <span className="bgColor">
                      <a
                        href="#"
                        id="registerPolicy"
                        className="pl-2"
                        onClick={(e) => this.decreaseCurrentStepHandler(e)}
                      >
                        {t('pages.forgotUserId.loginBtn')}
                      </a>
                    </span>
                  </p>
                </div>
              </Form>
            </>
          )}
          {currentStep === 1 && showGoogleCaptcha && screen === 1 && (
            <>
              <div className="col">
                <h2 className="mb-4">
                  {t('pages.registration.fillOutCaptcha')}
                </h2>
              </div>
              <div className="col-md-6 mt-3 mb-9">
                <div className="google-captcha">
                  <div className="google-captcha__box">
                    {
                      <GoogleCaptcha
                        sitekey={recapSiteKey}
                        onCaptchaChange={this.onCaptchaChange}
                        captchaReset={captchaReset}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="d-sm-flex col-lg-4 col-md-12">
                <button
                  className="col btn btn-secondary hollow mr-sm-4  py-2 my-3"
                  size="lg"
                  variant="secondary"
                  block
                  type="submit"
                  onClick={(e) => this.decreaseCurrentStepHandler(e)}
                >
                  {t('pages.forgotUserId.backBtn')}
                </button>
              </div>
            </>
          )}
          {currentStep === 2 && screen === 2 && mail && (
            // !verificationOption &&
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.forgotUserId.verificationMethod')}</span>
              </h3>
              <Form className="radio-form">
                {/* {userPhone && (
                    <Radio
                      key={userPhone}
                      name={userPhone}
                      label={`${t(
                        'pages.forgotUserId.smsCode'
                      )}${' '}${userPhone}`}
                      value={userPhone}
                      isChecked={selectedOption === 'userPhone' ? true : false}
                      handleChange={this.onOptionsChange}
                    />
                  )} */}
                {mail && (
                  <Radio
                    key={mail}
                    name={mail}
                    label={`${t('pages.forgotUserId.emailCode')}${' '}${mail}`}
                    value={mail}
                    isChecked={selectedOption === 'mail' ? true : false}
                    handleChange={this.onOptionsChange}
                  />
                )}
                {kba && (
                  <Radio
                    key={kba}
                    name={kba}
                    label={t('pages.forgotUserId.answerSecurityQuestion')}
                    value={kba}
                    isChecked={selectedOption === 'kba' ? true : false}
                    handleChange={this.onOptionsChange}
                  />
                )}
                {radioOptionError && (
                  <p className="error-text" id="verification-error">
                    {radioOptionError && t(radioOptionError)}
                  </p>
                )}
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onNext(e)}
                    >
                      {t('pages.forgotUserId.nextBtn')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 3 && verificationOption && (
            <div className="col-md-12 registration__container">
              <div className="registration__container__resend-verification ml-0">
                <div className="registration__container__resend-verification__resend-text">
                  <h3>
                    {t('pages.login.verificationEmail')} {verificationOption}
                  </h3>
                </div>
              </div>
              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className={`ml-3 verification-input ${
                      verificationCodeError ? 'error-verification' : undefined
                    }`}
                    value={verificationCode}
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__resend-verification__resend-text">
                  <p
                    className="resend-link"
                    onClick={(e) => this.resendVerify(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
                <div
                  id="pease_note"
                  className="registration__container__pleasenote"
                >
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onVerify(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 3 && securityOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.forgotUserId.securityQuestion')}</span>
              </h3>
              <Form className="col-md-6 mt-3 pt-4 pl-0">
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">{securityOption}</Form.Label>
                  <Form.Control
                    type="text"
                    onInput={this.handleQuestionChange.bind(this)}
                    value={securityQuestionValue}
                  />
                  {securityQuestionError && (
                    <p className="error-text" id="verification-error">
                      {securityQuestionError && t(securityQuestionError)}
                    </p>
                  )}
                </Form.Group>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-12 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      // onClick={this.decreaseCurrentStepHandler}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onVerifyQuestion(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 4 && (
            <>
              <Col className="pr-2" lg={{ span: 6 }}>
                <Form.Group controlId="passWord">
                  <Form.Label>{t('pages.registration.password')}</Form.Label>
                  <PasswordField
                    onChange={(value) => {
                      this.setState({
                        password: value,
                      });
                    }}
                    onBlur={(e) => {
                      this.isFormFieldValid(e, 'passwordError');
                    }}
                    password={password}
                    className={passwordError ? 'error-input' : undefined}
                    ariaInvalid={!!passwordError}
                    ariaDescribedby="new-password-error"
                    myRef={this.passwordInputRef}
                    onCopy={(e) => {
                      this.disableCopyPaste(e);
                    }}
                    onPaste={(e) => {
                      this.disableCopyPaste(e);
                    }}
                  />
                  <p className="error-text" id="password-error">
                    {this.state.errortext}
                  </p>
                </Form.Group>
              </Col>
              <Col className="pr-2" lg={{ span: 6 }}>
                <Form.Group controlId="confirmPassWord">
                  <Form.Label>
                    {t('pages.registration.confirmPassword')}
                  </Form.Label>
                  <PasswordField
                    onChange={(value) => {
                      this.setState(
                        {
                          confirmPassword: value,
                        },
                        () => {
                          this.isFormFieldValid(value, 'confirmPasswordError');
                        }
                      );
                    }}
                    onBlur={(e) => {
                      this.isFormFieldValid(e, 'confirmPasswordError');
                    }}
                    password={confirmPassword}
                    className={confirmPasswordError ? 'error-input' : undefined}
                    ariaInvalid={!!confirmPasswordError}
                    ariaDescribedby="confirm-password-error"
                    myRef={this.confirmPasswordInputRef}
                    onCopy={(e) => {
                      this.disableCopyPaste(e);
                    }}
                    onPaste={(e) => {
                      this.disableCopyPaste(e);
                    }}
                  />
                  <p className="error-text" id="password-error">
                    {this.state.errortextConfirm}
                  </p>
                </Form.Group>
              </Col>
              <div className="pl-1 pt-2">
                <PasswordRequirements
                  password={password}
                  formSubmitted={false}
                />
              </div>
              <div className="row pt-3">
                <div className="d-sm-flex col-lg-6 col-md-12">
                  <button
                    className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                    onClick={(e) => this.onBackLogin(e)}
                  >
                    {t('pages.forgotPassword.backBtn')}
                  </button>
                  <button
                    className="col btn btn-secondary  py-2 my-3"
                    onClick={(e) => this.submitNewPassword(e)}
                  >
                    {t('pages.forgotPassword.nextBtn')}
                  </button>
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && screen === 5 && (
            <>
              <SuccessConfirmation
                header={t('pages.forgotUserId.successBannerHeader')}
                successMessage={t('pages.forgotPassword.password')}
                username={userName}
              />
              <div className="row mt-5">
                <div className="d-sm-flex col-lg-3 col-md-12">
                  <Button
                    className="col btn btn-secondary py-2 my-3"
                    size="lg"
                    variant="secondary"
                    block
                    type="submit"
                    onClick={(e) => this.sendToLogin(e)}
                  >
                    {t('pages.forgotUserId.loginBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {screen === 6 && securityOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{securityOption}</span>
              </h3>
              <Form className="col-md-6 mt-3 pt-4 pl-0">
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.forgotUserId.otpContinue')}
                  </Form.Label>
                </Form.Group>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-12 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      // onClick={this.decreaseCurrentStepHandler}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) =>
                        this.accountVerificationPreferenceChange(e)
                      }
                    >
                      {t('pages.registration.registration')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </>
    );
  }
}
ForgotPassword.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(ForgotPassword);
