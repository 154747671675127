import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'app/modules/Shared/Checkbox/Checkbox.scss';

function Checkbox(props) {
  const { t } = useTranslation();
  const {
    name,
    ariaLabel,
    label,
    isChecked,
    handleChange,
    isInline = true,
    disabled = false,
    labelAlignment = 'left',
  } = props;

  const handleKeyPress = (e) => {
    e.preventDefault();
    const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
    if (charCode === 13) {
      handleChange(!isChecked);
    }
  };

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Form.Check className="form-checkbox" type="checkbox" inline={isInline}>
          <span className="pr-2">{labelAlignment === 'left' && t(label)}</span>
          <div
            className={`checkbox-container ${isChecked ? 'checked' : ''} ${
              disabled ? 'disabled' : ''
            }`}
            tabIndex={0}
            onKeyPress={handleKeyPress}
            role="checkbox"
            aria-checked={isChecked}
            aria-label={ariaLabel}
            aria-disabled={disabled}
          >
            <Form.Check.Input
              type="checkbox"
              name={name}
              checked={isChecked}
              onChange={(e) => handleChange(e.target.checked)}
              disabled={disabled}
            />
          </div>
          <span className="pl-2">{labelAlignment === 'right' && t(label)}</span>
        </Form.Check>
      </Form.Label>
    </Form.Group>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isInline: PropTypes.bool,
  disabled: PropTypes.bool,
  labelAlignment: PropTypes.string,
};

Checkbox.defaultProps = {
  isInline: true,
  disabled: false,
  labelAlignment: 'left',
};

export default Checkbox;
