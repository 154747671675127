import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/EditEmailPopup.scss';
import Cookies from 'js-cookie';
import WithError from 'app/modules/Shared/WithError/WithError';
import ReactGA from 'react-ga';

class EditEmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      errorText: '',
      authID: '',
      errorArray: [],
      errorExist: '',
      sn: props.sn ? props.sn : '',
      verificationCode: '',
      screen: 1,
      mail: props.mail ? props.mail : '',
      verificationOption: '',
      verificationCodeError: '',
    };
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { mail, emailError } = this.state;
    const hasEmail = this.isFormFieldValid(mail, 'errors.emailError');
    if (mail === '') {
      this.setState({
        emailError: 'pages.editEmail.emailAddressRequired',
      });
      this.emailAddressInputRef.current.focus();
    }
    else if(hasEmail === ''){
      this.getEmailVerify();
      this.setState(
        {
          emailError: '',
        }
      );
    }
  };

  // getOtp = () => {
  //   const { mail, authID, verificationCode } = this.state;
  //   // let bearer = 'Bearer ';
  //   // let token = localStorage.getItem('dataStoreToken');
  //   // let token = Cookies.get('dataStoreToken');
  //   let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
  //   const { onEditEmailSuccessHandler } = this.props;
  //   http(
  //     {
  //       url: 'upadteEmail',
  //       method: 'POST',
  //       data: {
  //         OTP: verificationCode,
  //         iPlanetDirectoryPro: iPlanetDirectoryPro,
  //         authId: authID,
  //       },
  //     }
  //   ).then((res) => {
  //     if (res.status === 200) {
  //       console.log(res);
  //       // this.setState({
  //       //   screen: 2,
  //       // });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'EMAIL_ALREADY_PRESENT'
  //     ) {
  //       this.setState({
  //         emailError: 'pages.editEmail.emailAlreadyExist',
  //       });
  //     }
  //   });
  // };

  getEmailVerify = () => {
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    const { onEditEmailSuccessHandler } = this.props;
    http(
      {
        url: 'updateEmail',
        method: 'POST',
        data: {
          NewEmail: this.state.mail,
          Email: this.props.mail,
          iPlanetDirectoryPro: iPlanetDirectoryPro
        },
      }
    ).then((res) => {
      if (
        res.status === 200 && res.data.iamcode === "IAM000"
      ) {
        this.setState({
          authID: res.data.data.authId,
          screen:2
        });
        // this.getOtp();
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'EMAIL_ALREADY_PRESENT'
      ) {
        this.setState({
          emailError: 'pages.editEmail.emailAlreadyExist',
        });
      }else if(res.status === 400 && res.data.iamcode == 'IAM0055' ){

        this.setState({
          emailError: res.data.data.Message.errorMessage,
        });

      } else if (res.status === 401) {
        this.showError('pages.idproofing.sessionExpiry');
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }
    });
  };

  resendVerify = (e) => {
    e.preventDefault();
    const { authID } = this.state;
    const iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    const {
      onResendOTP
    } = this.props;
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: 'updateEmail',
        method: 'POST',
        data: {
          OTPChoice: "Resend",
          iPlanetDirectoryPro: iPlanetDirectoryPro,
          authId: authID,
        },
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === "IAM000") {
        onResendOTP('success');
        this.setState({
          authID: res.data.data.authId,
        });
      }
      // else if (res.status === 401) {
      //   this.showError('pages.idproofing.sessionExpiry');
      //   setTimeout(() => {
      //     window.location.href = '/';
      //   }, 3000);
      // }
      else {
        this.showError('errors.maxOTPAttempt');
        setTimeout(() => {
          window.location.href = '/profile/Account';
        }, 3000);
      }
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { authID, verificationCode } = this.state;
    const {
      onEditEmailSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
      // let bearer = 'Bearer ';
      // // let token = localStorage.getItem('dataStoreToken');
      // let token = Cookies.get('dataStoreToken');
      this.setState({
        verificationCodeError: '',
      });
      
        http(
          {
            url: 'updateEmail',
            method: 'POST',
            data: {
              OTPChoice: "Validate",
              OTP: verificationCode,
              iPlanetDirectoryPro: iPlanetDirectoryPro,
              authId: authID,
            },
          }
        ).then((res) => {
          if (res.status === 200 && res.data.data.successUrl) {
            this.setState({
              authID: res.data.data.authId,
            });
            onEditEmailSuccessHandler('success');
            ReactGA.event({
              category: 'Email Update',
              action: 'Email Update Success: Email Updated and otp verified.',
            });
          } else if(res.status == 401 && res.data.iamcode == 'IAM0039'){
            this.showError('errors.maxVerifyAttempt');
            setTimeout(() => {
              window.location.href = '/profile/Account';
            }, 3000);
          }else if (
            res.status === 400
          ) {
            this.setState({
              verificationCodeError: 'pages.editEmail.verificationCodeError',
            });}
            else if (
              res.status === 200 && res.data.data.callbacks[0].output[0].value === "OTP Preference"
            ) {
              this.setState({
                authID: res.data.data.authId,
              });
              this.setState({
                verificationCodeError: 'pages.editNumberPopup.otpInvalid',
              });}
            else {
            onErrorEmailSuccessHandler('error', 'Some Error Occured' );
          }
        });  
    }
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;

    this.setState({ verificationCode });
  }

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    let errorMsg = '';
    switch (key) {
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 1,
      verificationOption: '',
      verificationCodeError: '',
      verificationCode: '',
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      screen,
      verificationCodeError,
      emailError,
      mail,
      errorText,
      errorArray,
      verificationOption,
      verificationCode,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
        className='email-popup-parent'
      >
        <div className="change-password-popup edit-email-pop">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">{t('pages.editEmail.title')}</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <p>
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  </p>
                  {screen === 1 && (
                    <>
                      <Form.Group controlId="mail">
                        <Form.Label>{t('pages.editEmail.email')}</Form.Label>
                        <Form.Control
                          ref={this.emailAddressInputRef}
                          type="email"
                          onChange={(e) => {
                            this.setState({
                              mail: e.target.value.trim().toLowerCase(),
                              emailError:'',
                            });
                          }}
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'emailError');
                          }}
                          value={mail}
                          aria-required
                          className={emailError ? 'error-input' : undefined}
                          aria-invalid={!!emailError}
                          aria-describedby="email-error"
                        />
                      </Form.Group>
                      <p className="error-text" id="email-error">
                        {emailError && t(emailError)}
                      </p>
                      <div className="mb-3 mt-3">
                        <Button
                          className="wrapper__container-card-body-submit mb-3"
                          size="lg"
                          variant="primary"
                          block
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                        >
                          {t('pages.editName.save')}
                        </Button>
                      </div>
                    </>
                  )}
                  {screen === 2 && (
                    <div className="col-md-12 registration__container">
                      <p>
                        <span>
                          {t('pages.registration.verificationCodeSentTo')}{' '}
                          {mail}
                        </span>
                      </p>
                      <div className="registration__container__resend-verification">
                        <div className="registration__container__resend-verification__resend-text">
                          <p
                            className="resend-link"
                            onClick={(e) => this.resendVerify(e)}
                            role="button"
                            tabIndex={0}
                          >
                            {t('pages.registration.resendVerificationCode')}
                          </p>
                        </div>
                      </div>
                      <Form>
                        <Form.Group
                          className="verification-form"
                          controlId="verificationCode"
                        >
                          <Form.Label className="mb-4">
                            {t('pages.registration.verificationCode')}
                          </Form.Label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            onInput={this.handleChange.bind(this)}
                            className="ml-3"
                            value={verificationCode}
                          />
                          {verificationCodeError && (
                            <p className="error-text" id="verification-error">
                              {verificationCodeError &&
                                t(verificationCodeError)}
                            </p>
                          )}
                        </Form.Group>
                        <div className="registration__container__pleasenote pleasenote">
                          <p className="registration__container__pleasenote-fp">
                            {t('pages.registration.note')}
                          </p>
                          <p className="registration__container__pleasenote-sp">
                            {t('pages.registration.verifyNote')}
                          </p>
                        </div>
                        <div className="row pt-3">
                          <div className="d-sm-flex col-lg-6 col-md-12">
                            <button
                              className="col btn btn-secondary mr-sm-4 py-2 my-3"
                              onClick={(e) => this.onVerify(e)}
                            >
                              {t('pages.registration.verify')}
                            </button>
                            <button
                              className="col btn btn-secondary hollow py-2 my-3"
                              onClick={(e) => this.onBackLogin(e)}
                              variant="primary"
                              block
                            >
                              {t('pages.forgotPassword.backBtn')}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

EditEmailPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditEmailSuccessHandler: PropTypes.func.isRequired,
  onErrorEmailSuccessHandler: PropTypes.func.isRequired,
  onResendOTP: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
  mail: PropTypes.string,
};
EditEmailPopup.defaultProps = {
  givenName: '',
  sn: '',
  mail: '',
};
export default withTranslation()(EditEmailPopup);
