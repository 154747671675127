import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/Home.scss';
import { withTranslation } from 'react-i18next';
import heroImage from 'assets/img/mountains-hero-image.jpg';
import http from 'api aegisCloud/http-request';
import Cookies from 'js-cookie';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails ? props.userDetails : {},
    };
  }

  getUserDetails = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';
    http(
      {
        url: `bravo_user/${id}`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        const { setUserDetails } = this.props;
        setUserDetails(res.data);
        this.setState({
          userDetails: res.data,
        });
      }
    });
  };

  componentDidMount() {
    const { getNotifications } = this.props;
    window.scrollTo(0, 0);
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
    if (token || id) {
      this.getUserDetails(id, token);
      //getNotifications(id, token);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userDetails !== prevProps.userDetails) {
      this.setState({
        userDetails: this.props.userDetails,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { userDetails } = this.state;
    return (
      <>
        {/* <div className="home-page__container">
          <img src={heroImage} />
          <div className="home-page__container-content">
            <h1>
              {t('pages.homePage.welcomeText')}, {userDetails.givenName}{' '}
              {userDetails.sn}
            </h1>
            <div className="col-md-12">
              <div className="home-page__container-content-wrapper">
                <p className="home-page__container-content-wrapper__title">
                  {t('pages.homePage.newsTitle')}
                </p>
                <p className="home-page__container-content-wrapper__description">
                  {t('pages.homePage.newsDescription')}
                </p>
                <p className="home-page__container-content-wrapper__title">
                  {t('pages.homePage.companyUpdate')}
                </p>
                <p className="home-page__container-content-wrapper__description">
                  {t('pages.homePage.companyDescription')}
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          <div>
            <AlertGlobal useTicker={false}></AlertGlobal>
          </div>
          <div className="home-page__container m-md-5">
            <div className="home-page__container-content">
              <div>
                <div className="home-page__container-content-wrapper">
                  <div className="w-100 image-banner"></div>
                  <h1>
                    {t('pages.homePage.welcomeText')}, {userDetails.givenName}{' '}
                    {userDetails.sn}
                  </h1>
                  <div className="row mt-5">
                    <div className="col-12 pb-5 pb-md-4">
                      <div className="card box-shadow-default p-3 h-100">
                        <h2 className="home-page__container-content-wrapper__title p-0">
                          {t('pages.homePage.newsTitle')}
                        </h2>
                        <div className="home-page__container-content-wrapper__description">
                          <p>{t('pages.homePage.newsDescription')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card box-shadow-default p-3 h-100">
                        <h2 className="home-page__container-content-wrapper__title p-0">
                          {t('pages.homePage.companyUpdate')}
                        </h2>
                        <p className="home-page__container-content-wrapper__description">
                          {t('pages.homePage.companyDescription')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Home.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object,
  userDetails: {},
};
Home.defaultProps = {
  userData: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
  userDetails: {},
};
export default withTranslation()(Home);
