export const getAdminUser = (username) => {
  return new Promise(function (resolve, reject) {
    var output = null;

    var aws = require('aws-sdk');
    aws.config.update({
      region: 'us-east-1',

      accessKeyId: `${process.env.REACT_APP_COGNITO_AccessKey}`, // should be:  process.env.AWS_ACCESS_ID
      secretAccessKey: `${process.env.REACT_APP_COGNITO_SecretKey}`,
    });

    var cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider();
    var params = {
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}`,
      Username: username,
    };

    cognitoidentityserviceprovider.adminGetUser(params, function (err, data) {
      if (err) {
        resolve(false);
      } else {
        data.UserAttributes.splice(1, 1);
        /*
        data.UserAttributes.splice(0, 0, {
          Name: 'custom:Confirm_Email',
          Value: data.UserAttributes[data.UserAttributes.length - 1].Value,
        });*/
        data.UserAttributes.splice(0, 0, {
          Name: 'custom:Confirm_Email',
          Value: '',
        });
        data.UserAttributes.splice(3, 0, {
          Name: 'custom:Confirm_Password',
          Value: '',
        });

        data.UserAttributes.map((elem, index) => {
          if (elem.Name == 'custom:Confirm_Email') {
            data.mail = elem.Value;
          } else if (elem.Name == 'custom:Last_Name') {
            data.sn = elem.Value;
          } else if (elem.Name == 'custom:First_Name') {
            data.givenName = elem.Value;
          }
        });
        data.userName = data.Username;
        if (!data.UserAttributes.find((o) => o.Name === 'phone_number')) {
          let cloneData = JSON.parse(JSON.stringify(data));
          data.UserAttributes[6].Name = 'phone_number';
          data.UserAttributes[6].Value = '';
          data.UserAttributes[7] = {};
          data.UserAttributes[7].Name = cloneData.UserAttributes[6].Name;
          data.UserAttributes[7].Value = cloneData.UserAttributes[6].Value;
        }

        output = JSON.parse(JSON.stringify(data));

        resolve(output);
      }
    });
  });
};
