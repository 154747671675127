import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faPaperPlane,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import 'app/modules/Shared/ChatBot/AWSConnectChatbot.scss';

import DotWaveAnimation from 'app/modules/Shared/ChatBot/DotWaveAnimation';
import agentIcon from 'assets/img/chatbot-agent-icon.png';
import botIcon from 'assets/img/chatbot-bot-icon.png';
import userIcon from 'assets/img/chatbot-user-icon.png';
import { capitalizeString } from 'utils/common/';
import Cookies from 'js-cookie';

/**
 * Setup examples here
 * https://github.com/amazon-connect/amazon-connect-chat-ui-examples/tree/master/cloudformationTemplates/startChatContactAPI
 */

class AWSConnectChatbot extends Component {
  constructor(props) {
    super(props);
    this.messageContainerDiv = React.createRef();

    this.state = {
      chatbotHidden: true,
      isChatBotInitiated: false,
      chatbotMessageHistory: [],
      displayName: '',
      session: '',
      userMessageQuery: '',
      userBtnMessageQuery: '',
      welcomeMessage: 'welcomeMessage',
      isWelcomeMessageSent: false,
      isPassThroughValueShellSent: false,
      isAgentTyping: false,
      clearChatHistory: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.props.hasRouteChanged && this.state.isWelcomeMessageSent) {
      // get a new welcome message when a new screen is visited
      this.sendChat(false, true, false);
    }
  }

  retrieveFirstName = () => {
    const fName = Cookies.get('firstName');
    return fName ? capitalizeString(fName) : '';
  };

  retrieveLastName = () => {
    const lName = Cookies.get('lastName');
    return lName ? capitalizeString(lName) : '';
  };

  retrieveUserID = () => {
    const userID = Cookies.get('user_id');
    return userID ? userID : '';
  };

  chatbotInit = () => {
    try {
      const firstName = this.retrieveFirstName();
      const lastName = this.retrieveLastName();

      // initiate chatbot connection
      connect.ChatSession.setGlobalConfig({
        region: 'us-east-1',
      });

      // const token = localStorage.getItem('dataStoreToken');
      const token = Cookies.get('dataStoreToken');
      // const id = localStorage.getItem('user_id');
      const id = Cookies.get('user_id');

      // config details
      let customerName = `${firstName ? firstName : 'User'} ${
        lastName ? lastName : ''
      }`;

      const contactFlowId =
        token && id
          ? `${process.env.REACT_APP_CHATBOT_CONTACT_FLOW_ID_AUTHENTICATED}`
          : `${process.env.REACT_APP_CHATBOT_CONTACT_FLOW_ID_UNAUTHENTICATED}`;
      const instanceId =
        token && id
          ? `${process.env.REACT_APP_CHATBOT_INSTANCE_ID_AUTHENTICATED}`
          : `${process.env.REACT_APP_CHATBOT_INSTANCE_ID_UNAUTHENTICATED}`;
      const apiGatewayEndpoint =
        token && id
          ? 'https://2jpxgsgawd.execute-api.us-east-1.amazonaws.com/Prod'
          : 'https://sytgycu19f.execute-api.us-east-1.amazonaws.com/Prod';

      const initiateChatRequest = {
        ParticipantDetails: {
          DisplayName: customerName,
        },
        ContactFlowId: contactFlowId,
        InstanceId: instanceId,
        Attributes: {
          firstName: firstName,
          lastName: lastName,
        },
      };

      // http call to the chatbot API
      fetch(apiGatewayEndpoint, {
        method: 'POST',
        body: JSON.stringify(initiateChatRequest),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Status Code: ${response.status}\nMessage: ${response.statusText}`
            );
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data) {
            const startChatResult = data.data.startChatResult;
            let { session } = this.state;

            session = connect.ChatSession.create({
              chatDetails: startChatResult,
              type: connect.ChatSession.SessionTypes.CUSTOMER,
            });

            session.connect().then(
              (response) => {
                return response;
              },
              (error) => {
                return Promise.reject(error);
              }
            );

            session.onTyping(() => {
              // capture support agent typing event then add dancing dots
              this.setState({ isAgentTyping: true }, () => {
                if (this.state.isAgentTyping) {
                  let htmlResult = (
                    <div className="mb-3 mt-4">
                      <DotWaveAnimation></DotWaveAnimation>
                    </div>
                  );

                  const chatContent = {
                    currentMessage: htmlResult,
                    displayType: 'typing',
                  };

                  let currentChatHistory = _.cloneDeep(
                    this.state.chatbotMessageHistory
                  );
                  let duplicateMessage = _.isEqual(
                    currentChatHistory[currentChatHistory.length - 1],
                    chatContent
                  );

                  if (!duplicateMessage) {
                    currentChatHistory.push(chatContent);
                    let newChatHistory = currentChatHistory;
                    this.setState({
                      chatbotMessageHistory: newChatHistory,
                    });
                  }
                }
              });
            });

            session.onConnectionBroken(() => {
              // if connection is broken
              console.log('Connection broken.');
            });

            this.setState({ session: session });
            this.retrieveChatbotMessage();
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  sendChat = (isBtnMessage, isInitialMessage, isPassThroughValueShell) => {
    // used to send a message to chatbot backend
    try {
      let message = '';

      if (isBtnMessage) {
        //did this message come from a button?
        message = this.state.userBtnMessageQuery;
      } else if (isInitialMessage) {
        // is this the welcome message?
        message = this.getWelcomeMessage();
      } else if (isPassThroughValueShell) {
        // is this auth token message?
        // message = this.getPassThroughValueShell();
      } else {
        message = this.state.userMessageQuery;
      }

      if (message) {
        if (message.length > 0) {
          if (this.state.session.controller) {
            this.state.session.controller
              .sendMessage({
                message: message,
                contentType: 'text/plain',
              })
              .then(() =>
                isInitialMessage || isPassThroughValueShell
                  ? ''
                  : this.retrieveChatbotMessage()
              )
              .then(() => this.clearUserQuery());
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  getWelcomeMessage = () => {
    // used to retrieve the initial welcome message based on current page
    let retVal = '';
    const path = window.location.pathname;
    const firstName = this.retrieveFirstName();
    const userID = this.retrieveUserID();

    if (path === '/profile/Dashboard') {
      this.setState({ welcomeMessage: `WelcomeHomeAuthTest ${firstName}` });
      retVal = `WelcomeHomeAuthTest ${firstName}`;
    } else if (path === '/profile/Apps') {
      this.setState({ welcomeMessage: `WelcomeUserAppsAuthTest ${firstName}` });
      retVal = `WelcomeUserAppsAuthTest ${firstName}`;
    } else if (path === '/profile/IDs') {
      this.setState({ welcomeMessage: `WelcomeUserIdAuthTest ${firstName}` });
      retVal = `WelcomeUserIdAuthTest ${firstName}`;
    } else if (path === '/profile/Notifications') {
      this.setState({
        welcomeMessage: `WelcomeUserNotifyAuthTest ${firstName}`,
      });
      retVal = `WelcomeUserNotifyAuthTest ${firstName}`;
    } else if (path === '/profile/Wallet') {
      this.setState({
        welcomeMessage: `WelcomeUserWalletAuthTest ${firstName}`,
      });
      retVal = `WelcomeUserWalletAuthTest ${firstName}`;
    } else if (path.includes('/profile/')) {
      this.setState({
        welcomeMessage: `WelcomeUserProfileAuthTest ${firstName}`,
      });
      retVal = `WelcomeUserProfileAuthTest ${firstName}`;
    } else if (path === '/') {
      this.setState({ welcomeMessage: `welcomeMessage ${firstName}` });
      retVal = `welcomeMessage ${firstName}`;
    }
    return retVal;
  };

  getPassThroughValueShell = () => {
    // encode to base64 then send
    let directCookie = Buffer.from(
      Cookies.get('iPlanetDirectoryPro')
        ? Cookies.get('iPlanetDirectoryPro')
        : ''
    ).toString('base64');
    let retVal = '';
    if (directCookie) {
      retVal = `passThroughValueShell ${directCookie}`;
      this.setState({ isPassThroughValueShellSent: true });
    }
    return retVal;
  };

  setUserQuery = (messageQuery, isBtnMessage) => {
    // set user's input (what the user typed in)
    isBtnMessage
      ? this.setState({
          userBtnMessageQuery: messageQuery.target.value,
        })
      : this.setState({
          userMessageQuery: messageQuery.target.value,
        });
  };

  handleChatBtnClicks = (m) => {
    // handle button clicks for interactive chatbot messages
    try {
      const isBtnMessage = true;

      if (m.target.tagName.toLowerCase() === 'span') {
        let parentValue = m.target.parentElement.value;
        this.setState(
          {
            userBtnMessageQuery: parentValue,
          },
          () => this.sendChat(isBtnMessage, false, false)
        );
      } else {
        this.setState(
          {
            userBtnMessageQuery: m.target.value,
          },
          () => this.sendChat(isBtnMessage, false, false)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  getTranscript = () => {
    // retrieving Transcript of conversation
    this.state.session
      .getTranscript({
        scanDirection: 'BACKWARD',
        sortOrder: 'ASCENDING',
        maxResults: 15,
      })
      .then((response) => {
        console.log(JSON.stringify(response.data.Transcript));
      });
  };

  sendTypingEvent = () => {
    this.state.session.controller.sendEvent({
      contentType: 'application/vnd.amazonaws.connect.event.typing',
    });
  };

  chatbotHiddenHandler = () => {
    // hide and show chatbot
    if (this.state.isChatBotInitiated) {
      this.endChat();
      this.setState({
        isWelcomeMessageSent: false,
        isPassThroughValueShellSent: false,
      });
    } else {
      this.chatbotInit();
    }

    this.setState((prev) => ({
      chatbotHidden: !prev.chatbotHidden,
    }));
  };

  scrollToBottom = () => {
    // when message is received scroll to bottom of chatbot
    this.messageContainerDiv.current
      ? this.messageContainerDiv.current.scrollTo(0, 0)
      : '';
  };

  retrieveChatbotMessage = () => {
    // retrieve received chatbot messages (coming from backend)
    this.state.session.onMessage(
      (message) => {
        if (message) {
          const chatData = message.data;
          this.parseMessage(chatData);
        }
      },
      (err) => console.error(err)
    );
  };

  parseMessage = (chatData) => {
    // parse received messages (from Backend) to make them readable on chatbot
    try {
      let contentType = chatData.ContentType.toLowerCase();

      if (
        contentType === 'application/vnd.amazonaws.connect.event.chat.ended' ||
        contentType ===
          'application/vnd.amazonaws.connect.event.participant.left'
      ) {
        this.getSessionEndedMessage();
      } else if (
        contentType ===
        'application/vnd.amazonaws.connect.event.participant.joined'
      ) {
        this.getParticipantJoinedMessage(chatData);
      } else if (contentType === 'text/plain' && chatData.Type === 'MESSAGE') {
        this.getPlainMessage(chatData);
      } else if (
        contentType === 'application/vnd.amazonaws.connect.message.interactive'
      ) {
        this.getInteractiveMessage(chatData);
      }
      this.scrollToBottom();
    } catch (error) {
      console.error(error);
    }
  };

  getSessionEndedMessage = () => {
    // on session end, retrieve sessionEnded message
    const { t } = this.props;
    let htmlResult = (
      <div>
        <p>{t('pages.awsConnectChatbot.sessionEnded')}</p>
        <hr></hr>
      </div>
    );

    const chatContent = {
      currentMessage: htmlResult,
    };

    let currentChatHistory = _.cloneDeep(this.state.chatbotMessageHistory);
    let duplicateMessage = _.isEqual(
      currentChatHistory[currentChatHistory.length - 1],
      chatContent
    );

    if (!duplicateMessage) {
      if (this.state.isAgentTyping) {
        currentChatHistory.pop();
      }
      currentChatHistory.push(chatContent);
      let newChatHistory = currentChatHistory;

      this.setState({
        chatbotMessageHistory: newChatHistory,
        chatbotHidden: true,
        isChatBotInitiated: false,
        isWelcomeMessageSent: false,
        isPassThroughValueShellSent: false,
        isAgentTyping: false,
      });
    }
  };

  getParticipantJoinedMessage = (chatData) => {
    // when a participant (user or agent) joins the chat, display notification

    const { t } = this.props;
    let htmlResult = (
      <div>
        <p>
          {chatData.DisplayName} {t('pages.awsConnectChatbot.hasJoinedChat')}
        </p>
      </div>
    );
    const chatContent = {
      currentMessage: htmlResult,
    };

    let currentChatHistory = _.cloneDeep(this.state.chatbotMessageHistory);
    let duplicateMessage = _.isEqual(
      currentChatHistory[currentChatHistory.length - 1],
      chatContent
    );

    if (!duplicateMessage) {
      if (this.state.isAgentTyping) {
        currentChatHistory.pop();
      }
      currentChatHistory.push(chatContent);
      let newChatHistory = currentChatHistory;

      this.setState({
        chatbotMessageHistory: newChatHistory,
        isAgentTyping: false,
        isChatBotInitiated: true,
      });
    }
  };

  getPlainMessage = (chatData) => {
    //parse and display regular chatbot messages
    //this method displays the chat messages on the UI chatbot

    if (chatData) {
      let plainMessageContent = chatData.Content;
      const chatContent = {
        displayName: chatData.DisplayName,
        absoluteTime: chatData.AbsoluteTime,
        participantRole: chatData.ParticipantRole,
      };

      if (plainMessageContent.includes('http')) {
        // parse hyperlinks so they can be active and selected
        let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
        let regex = new RegExp(expression);
        const matchingHyperlink = plainMessageContent.match(regex)[0];

        let link = matchingHyperlink[matchingHyperlink.length - 1].includes('.')
          ? plainMessageContent
              .match(regex)[0]
              .slice(0, matchingHyperlink.length - 1)
          : matchingHyperlink;
        let pos1 = plainMessageContent.indexOf(link);

        let string1 = plainMessageContent.slice(0, pos1);
        let string2 = plainMessageContent.slice(
          pos1 + link.length,
          plainMessageContent.length
        );

        const htmlResult = (
          <div>
            <span>{string1}</span>
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
            <span>
              {matchingHyperlink.includes('.') ? '. ' : ' '}
              {string2}
            </span>
          </div>
        );

        chatContent.currentMessage = htmlResult;
      } else {
        chatContent.currentMessage = plainMessageContent;
      }

      // check if current message is a duplicate
      let currentChatHistory = _.cloneDeep(this.state.chatbotMessageHistory);
      let duplicateMessage = _.isEqual(
        currentChatHistory[currentChatHistory.length - 1],
        chatContent
      );

      if (!duplicateMessage) {
        const isCurrentMessageString =
          typeof chatContent.currentMessage === 'string';

        if (this.state.isAgentTyping) {
          // check if agent is typing
          currentChatHistory.pop();
        }

        // add current message to chat history
        currentChatHistory.push(chatContent);
        let newChatHistory = currentChatHistory;

        if (!this.state.isWelcomeMessageSent) {
          // if welcome message is has not been sent, send welcome message
          this.setState({
            chatbotMessageHistory: newChatHistory,
            displayName: chatContent.displayName,
            isWelcomeMessageSent: true,
          });
          this.sendChat(false, true, false);
        } else if (!this.state.isPassThroughValueShellSent) {
          // if passthrough message has not been sent,, Send passThrough Auth Value
          this.setState({
            displayName: chatContent.displayName,
            isPassThroughValueShellSent: true,
          });
          this.sendChat(false, false, true);
        } else if (
          this.state.isWelcomeMessageSent &&
          this.state.isPassThroughValueShellSent &&
          isCurrentMessageString
        ) {
          // if message is string and isn't welcome or passthroughValueShell message, display in chatbot
          if (
            chatContent.currentMessage !== this.state.welcomeMessage &&
            !chatContent.currentMessage.includes('passThroughValueShell')
          ) {
            this.setState({
              displayName: chatContent.displayName,
              chatbotMessageHistory: newChatHistory,
              isAgentTyping: false,
            });
          }
        } else if (
          this.state.isWelcomeMessageSent &&
          this.state.isPassThroughValueShellSent &&
          !isCurrentMessageString
        ) {
          // if message is not a string and isn't welcome or passthroughValueShell message, display in chatbot
          this.setState({
            displayName: chatContent.displayName,
            chatbotMessageHistory: newChatHistory,
            isAgentTyping: false,
          });
        }
      }
    }
  };

  getInteractiveMessage = (chatData) => {
    // parse and receive an advanced chatbot message
    // for example, a message with buttons and links

    let htmlResult = '';

    let contentData;
    if (chatData.Content.includes('\n')) {
      contentData = JSON.parse(
        chatData.Content.replace(/\n/g, '\\n')
          .replace(/\r/g, '\\\\r')
          .replace(/\t/g, '\\\\t')
      ).data.content;
    } else {
      contentData = JSON.parse(chatData.Content).data.content;
    }
    // let contentData = JSON.parse(chatData.Content).data.content;
    let subtitle = contentData.subtitle;

    if (subtitle.includes('http')) {
      // parse hyperlinks so they can be active and selected
      let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      let regex = new RegExp(expression);
      const matchingHyperlink = subtitle.match(regex)[0];

      let link = matchingHyperlink.includes('.')
        ? subtitle.match(regex)[0].slice(0, matchingHyperlink.length - 1)
        : matchingHyperlink;
      let pos1 = subtitle.indexOf(link);

      let string1 = contentData.subtitle.slice(0, pos1);
      let string2 = contentData.subtitle.slice(
        pos1 + string1.length,
        subtitle.length
      );

      htmlResult = (
        <div>
          <p className="bold">{contentData.title}</p>
          <div className="mb-3">
            <span>{string1}</span>
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
            <span>
              {matchingHyperlink.includes('.') ? '. ' : ' '}
              {string2}
            </span>
          </div>
          <div className="text-center">
            {contentData.elements.map((item, index) => {
              return (
                <button
                  key={`button${index}`}
                  className={
                    contentData.elements.length > 0 ? 'chatBtn mb-3' : 'chatBtn'
                  }
                  value={item.title}
                  onClick={this.handleChatBtnClicks}
                >
                  {item.title}
                  {item.subtitle ? (
                    <span className="d-block text-gray ft-12 mt-2">
                      {item.subtitle}
                    </span>
                  ) : (
                    ''
                  )}
                </button>
              );
            })}
          </div>
        </div>
      );
    } else {
      htmlResult = (
        <div>
          <p className="bold">{contentData.title}</p>
          <p>
            {contentData.subtitle.split('\n').map((str) => (
              <span key={`span${1}`} className="d-block">
                {str}
              </span>
            ))}
          </p>
          <div className="text-center">
            {contentData.elements.map((item, index) => {
              if (item.link) {
                return (
                  <button
                    key={`anchor${index}`}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className={
                      contentData.elements.length > 0
                        ? 'd-block chatBtn chatBtn-link mx-auto mb-3'
                        : 'd-block chatBtn chatBtn-link mx-auto'
                    }
                    value={item.title}
                    onClick={() => {
                      this.handleChatBtnClicks();
                      window.open(item.link, '_blank');
                    }}
                  >
                    {item.title}
                    {item.subtitle ? (
                      <span className="d-block text-gray ft-12 mt-2">
                        {item.subtitle}
                      </span>
                    ) : (
                      ''
                    )}
                  </button>
                );
              } else {
                return (
                  <button
                    key={`button${index}`}
                    className={
                      contentData.elements.length > 0
                        ? 'chatBtn mb-3'
                        : 'chatBtn'
                    }
                    value={item.title}
                    onClick={this.handleChatBtnClicks}
                  >
                    {item.title}
                    {item.subtitle ? (
                      <span className="d-block text-gray ft-12 mt-2">
                        {item.subtitle}
                      </span>
                    ) : (
                      ''
                    )}
                  </button>
                );
              }
            })}
          </div>
        </div>
      );
    }

    const chatContent = {
      currentMessage: htmlResult,
      displayName: chatData.DisplayName,
      absoluteTime: chatData.AbsoluteTime,
      participantRole: chatData.ParticipantRole,
    };

    let currentChatHistory = this.state.chatbotMessageHistory;

    let duplicateMessage = _.isEqual(
      currentChatHistory[currentChatHistory.length - 1],
      chatContent
    );

    if (!duplicateMessage) {
      if (this.state.isAgentTyping) {
        currentChatHistory.pop();
      }
      currentChatHistory.push(chatContent);
      let newChatHistory = currentChatHistory;

      this.setState({
        displayName: chatContent.displayName,
        chatbotMessageHistory: newChatHistory,
        isAgentTyping: false,
      });
    }
  };

  endChat = () => {
    // end chatbot session
    if (this.state.session) {
      this.state.session.controller
        .disconnectParticipant()
        .then(() =>
          this.state.clearChatHistory
            ? this.setState({ chatbotMessageHistory: [] })
            : ''
        );
    }
  };

  onKeyDown = (event) => {
    // capture enter key press to send chat message
    if (event.keyCode === 13) {
      const isBtnMessage = false;

      event.preventDefault();
      this.sendChat(isBtnMessage, false, false);
    } else {
      return;
    }
  };

  clearUserQuery = () => {
    // empties the userInput
    this.setState({
      userMessageQuery: '',
      userBtnMessageQuery: '',
    });
  };

  componentWillUnmount() {
    this.endChat();
  }

  render() {
    const { t } = this.props;
    const {
      chatbotHidden,
      chatbotMessageHistory,
      userMessageQuery,
      isChatBotInitiated,
    } = this.state;
    return (
      <div>
        <div
          id="chatbot-main-container"
          className={chatbotHidden ? 'dehydrated' : 'box-shadow-default'}
        >
          <div
            className={
              isChatBotInitiated
                ? 'h-100 chatbot-secondary-container'
                : 'loader chatbot-secondary-container h-100'
            }
          >
            <div className="row justify-content-between chatbot-header  m-0 ">
              <div>{t('pages.awsConnectChatbot.chatbotTitle')}</div>

              <button
                className="chatbot-session-end-btn d-flex align-items-center"
                onClick={() => {
                  this.chatbotHiddenHandler();
                }}
                aria-label={
                  chatbotHidden ? 'Open live chat' : 'Close live chat'
                }
              >
                <FontAwesomeIcon
                  icon={chatbotHidden ? faCommentAlt : faTimes}
                ></FontAwesomeIcon>
              </button>
            </div>
            <div
              className={
                isChatBotInitiated
                  ? 'message-list-container'
                  : 'message-list-container hidden'
              }
              ref={this.messageContainerDiv}
            >
              <div className="row m-0">
                <div className="col py-3">
                  {chatbotMessageHistory
                    ? chatbotMessageHistory.map((message, index) => (
                        <div key={index}>
                          {message.displayName ? (
                            <div
                              className="row justify-content-between align-items-center m-0"
                              key={`div${index}`}
                            >
                              <div
                                className="row align-items-center m-0"
                                key={`div-message${index}`}
                              >
                                <img
                                  src={
                                    message.participantRole.toLowerCase() ===
                                    'agent'
                                      ? agentIcon
                                      : message.participantRole.toLowerCase() ===
                                        'customer'
                                      ? userIcon
                                      : botIcon
                                  }
                                  alt={
                                    message.displayName.toLowerCase() === 'bot'
                                      ? 'bot icon'
                                      : message.participantRole.toLowerCase() ===
                                        'agent'
                                      ? 'agent icon'
                                      : message.participantRole.toLowerCase() ===
                                        'customer'
                                      ? 'user icon'
                                      : 'bot icon'
                                  }
                                  className={
                                    message.participantRole.toLowerCase() ===
                                    'agent'
                                      ? 'agent-icon'
                                      : 'chatbot-icons'
                                  }
                                />
                                <p className="m-0 pl-2" key={`p1${index}`}>
                                  {message.displayName}
                                </p>
                              </div>
                              <p className="m-0" key={`p2${index}`}>
                                {message.absoluteTime
                                  ? new Date(message.absoluteTime)
                                      .toLocaleTimeString('en-US')
                                      .slice(0, 4) +
                                    new Date(message.absoluteTime)
                                      .toLocaleTimeString('en-US')
                                      .slice(7, 11)
                                  : ''}
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                          <div
                            className={
                              message.displayName
                                ? message.displayName.toLowerCase() === 'bot' ||
                                  message.participantRole.toLowerCase() ===
                                    'agent'
                                  ? 'bubble bot mb-3'
                                  : 'bubble user mb-3'
                                : 'text-center mb-4'
                            }
                          >
                            {message.currentMessage}
                          </div>
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
            <div
              className={
                isChatBotInitiated
                  ? 'user-text-container row m-0'
                  : 'user-text-container row m-0 hidden'
              }
            >
              <div className="col-10 px-0">
                <input
                  type="text"
                  maxLength="256"
                  onChange={(event) => this.setUserQuery(event, false)}
                  value={userMessageQuery}
                  placeholder="Type a message here"
                  onKeyDown={this.onKeyDown}
                  aria-label="Input"
                ></input>
              </div>
              <div
                className={
                  userMessageQuery.length > 0
                    ? 'col-2 px-0 send-btn cursor-pointer'
                    : 'col-2 px-0 send-btn cursor-pointer empty'
                }
              >
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  role="img"
                  onClick={() => this.sendChat(false, false, false)}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            className={chatbotHidden ? 'chatbot-connect-toggle' : 'd-none'}
            onClick={() => {
              this.chatbotHiddenHandler();
            }}
            aria-label="Open live chat"
            aria-haspopup={chatbotHidden ? 'false' : 'true'}
          >
            <FontAwesomeIcon
              icon={faCommentAlt}
              role="button"
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
    );
  }
}

AWSConnectChatbot.propTypes = {
  hasRouteChanged: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AWSConnectChatbot);
