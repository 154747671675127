import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Breadcrumb } from 'react-bootstrap';
import Alert from 'app/modules/Shared/Alert Cognito/Alert';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown } from 'react-bootstrap';
import { Col, Button } from 'react-bootstrap';
import http from 'api cognito/http-request';
import Checkbox from '../Shared/Checkbox Cognito/Checkbox';
import 'app/modules/Dashboard Cognito/AdminRoleChange.scss';
import AdminRoleChangePopup from 'app/modules/Dashboard Cognito/AdminRoleChangePopup';
import ReactGA from 'react-ga';
import { aws_request } from 'api cognito/aws-request';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AdminRoleChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: '',
      alertStatus: '',
      userList: [],
      unfilteredUserList: [],
      adminUserCheckbox: false,
      standardUserCheckbox: false,
      showPopup: false,
    };
  }
  componentDidMount() {
    //this.listUsersFunction();
    this.listUsersInGroup();
  }

  onErrorEmailSuccessHandler = (status, message) => {
    let alertText = '';
    if (message === 'SMTP_ERROR') {
      alertText = 'pages.editEmail.smtpError';
    } else if (message === 'MAX_RESEND_EXCEEDED') {
      alertText = 'errors.maxResendAttempt';
    } else if (message === 'MAX_RETRY_EXCEEDED') {
      alertText = 'errors.maxVerifyAttempt';
    } else if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (message === 'LimitExceededException') {
      alertText = 'errors.maxResendAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,

          showPopup: false,

          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };
  onEditSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.AdminRoleChange.roleChangeSuccess'
        : 'pages.AdminRoleChange.roleChangeFailure';
    if (status === 'success') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
    }
  };

  listUsersFunction = async () => {
    var userData = await this.listUsers();

    this.setState({
      userList: userData,
    });
  };

  updateRoleAdminInitialize = async (role) => {
    if (role.role != '') {
      return;
    }
    ReactGA.event({
      category: 'User role update',
      action: 'User role update triggered',
    });
    let removeRoleParams = {
      GroupName: role.prevRole /* required */,
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
      Username: role.Username /* required */,
    };
    /*
    let addRoleParams = {
      GroupName: role.role
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}`
      Username: role.Username 
    };
    */

    let cognitoidentityserviceprovider = aws_request();
    let output1 = await this.removePrevRole(
      removeRoleParams,
      cognitoidentityserviceprovider
    );

    /*
    let output2 = await this.addnewRole(
      addRoleParams,
      cognitoidentityserviceprovider
    );*/

    if (output1) {
      ReactGA.event({
        category: 'User role update',
        action: 'User removed from Admin User Group',
      });
      this.onEditSuccessHandler('success');
      this.listUsersInGroup();
    } else {
      this.onEditSuccessHandler('failure');
    }
  };

  removePrevRole = (params, cognitoidentityserviceprovider) => {
    return new Promise(function (resolve, reject) {
      cognitoidentityserviceprovider.adminRemoveUserFromGroup(
        params,
        function (err, data) {
          if (err) {
            reject(false);
          }
          // an error occurred
          else {
            resolve(true); // successful response
          }
        }
      );
    });
  };

  addnewRole = (params, cognitoidentityserviceprovider) => {
    return new Promise(function (resolve, reject) {
      cognitoidentityserviceprovider.adminAddUserToGroup(
        params,
        function (err, data) {
          if (err) {
            reject(false);
          }
          // an error occurred
          else {
            resolve(true);
          } // successful response
        }
      );
    });
  };

  updateRoleAdmin = (role) => {
    return new Promise(function (resolve, reject) {
      var output = null;

      var aws = require('aws-sdk');
      aws.config.update({
        region: `${process.env.REACT_APP_COGNITO_RegionName}`,

        accessKeyId: `${process.env.REACT_APP_COGNITO_AccessKey}`, // should be:  process.env.AWS_ACCESS_ID
        secretAccessKey: `${process.env.REACT_APP_COGNITO_SecretKey}`,
      });

      var cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider();
      var params = {
        UserAttributes: [
          /* required */

          {
            ...role.Attributes[role.Attributes.length - 2],
          },

          /* more items */
        ],
        UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
        Username: role.Username /* required */,
      };
      cognitoidentityserviceprovider.adminUpdateUserAttributes(
        params,
        function (err, data) {
          if (err) {
            reject(false);
          } else {
            resolve(true);
          } // successful response
        }
      );
    });
  };

  updateRole = (role) => {
    this.setState({
      errorText: '',
    });
    let token = Cookies.get('dataStoreToken');

    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
          UserAttributes: [
            {
              ...role.Attributes[role.Attributes.length - 2],
            },
          ],
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.UpdateUserAttributes',
      }
    ).then((res) => {
      if (res && res.status === 200 && res.data) {
        this.onEditSuccessHandler('success');
        this.listUsers();
      } else {
        this.onEditSuccessHandler('failure');
      }
    });
  };
  listUsers = () => {
    var setCustomState = (dict) => {
      this.setState(dict);
    };
    ReactGA.event({
      category: 'List all Users',
      action: 'List all users in Admin page',
    });
    return new Promise(function (reject, resolve) {
      var aws = require('aws-sdk');
      aws.config.update({
        region: 'us-east-1',

        accessKeyId: `${process.env.REACT_APP_COGNITO_AccessKey}`, // should be:  process.env.AWS_ACCESS_ID
        secretAccessKey: `${process.env.REACT_APP_COGNITO_SecretKey}`,
      });

      var cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider();
      var params = {
        UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}`,
      };
      cognitoidentityserviceprovider.listUsers(params, function (err, data) {
        if (err) {
          reject({ errorText: err.stack });
        }
        // an error occurred
        else {
          data.Users.map((elem) => {
            elem.roleChangeStatus = true;
            if (
              elem.Attributes[elem.Attributes.length - 2].Name != 'custom:Role'
            ) {
              elem.Attributes.splice(elem.Attributes.length - 1, 0, {
                Name: 'custom:Role',
                Value: '',
              });
            }
          });

          setCustomState({
            userList: data.Users,
            unfilteredUserList: data.Users,
          });

          resolve(data);
        }
      });
    });
  };

  listUsersInGroup = async () => {
    let adminUserList = null;
    let standardUserList = null;
    var params1 = {
      GroupName: 'Admin' /* required */,
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
    };

    adminUserList = await this.listUsersInGroupAPI(params1);
    adminUserList.Users.map((elem) => {
      elem.roleChangeStatus = true;
      elem.role = 'Admin';
    });

    let final = adminUserList.Users;

    this.setState({
      userList: final,
      unfilteredUserList: final,
    });
  };

  listUsersInGroupAPI = (params) => {
    return new Promise(function (resolve, reject) {
      let cognitoidentityserviceprovider = aws_request();
      cognitoidentityserviceprovider.listUsersInGroup(
        params,
        function (err, data) {
          resolve(data);
        }
      );
    });
  };

  addRoleToUser = (username, role, elem) => {
    elem.roleChangeStatus = role != '' ? true : false;
    elem.prevRole = elem.role;
    elem.role = role;
    this.setState((prevState) => ({
      userList: prevState.userList.map((obj) =>
        obj.Username == username ? Object.assign(obj, elem) : obj
      ),
    }));
  };

  filterList = (userType) => {
    const {
      userList,
      unfilteredUserList,
      adminUserCheckbox,
      standardUserCheckbox,
    } = this.state;
    var newUserList = unfilteredUserList;

    if (!adminUserCheckbox && !standardUserCheckbox) {
      this.setState({ userList: newUserList });
      return;
    }

    newUserList = unfilteredUserList.filter((item) => {
      if (
        (adminUserCheckbox &&
          item.Attributes[item.Attributes.length - 2].Value.includes(
            'Admin'
          )) ||
        (standardUserCheckbox &&
          item.Attributes[item.Attributes.length - 2].Value.includes(
            'StandardUser'
          ))
      ) {
        return true;
      } else {
        return false;
      }
    });

    this.setState({ userList: newUserList });
  };

  render() {
    const { t } = this.props;
    const {
      alertText,
      userList,
      alertStatus,
      standardUserCheckbox,
      adminUserCheckbox,
      showPopup,
    } = this.state;
    return (
      <>
        <div className="admin-apps">
          {alertText && (
            <Alert
              alertText={alertText}
              className={alertStatus}
              onAlertClose={() => {
                this.setState({
                  alertText: '',
                });
              }}
            />
          )}

          {showPopup && (
            <AdminRoleChangePopup
              onEditSuccessHandler={this.onEditSuccessHandler}
              onErrorEmailSuccessHandler={this.onErrorEmailSuccessHandler}
              listUsersInGroup={this.listUsersInGroup}
              onClose={() => {
                this.setState({ showPopup: false });
              }}
            />
          )}
          <div className="tab-area pb-5">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                {t('pages.BreadCrumb.home')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('pages.BreadCrumb.admin')}</Breadcrumb.Item>
              <Breadcrumb.Item active>...</Breadcrumb.Item>
            </Breadcrumb>
            <div className="m-5">
              <div>
                {alertText && (
                  <Alert
                    alertText={alertText}
                    className={alertStatus}
                    onAlertClose={() => {
                      this.setState({
                        alertText: '',
                      });
                    }}
                  />
                )}
                <div>
                  <div className="admin-title">
                    <h1>{t('pages.AdminRoleChange.listUsers')}</h1>
                    <div>
                      <a
                        className="btn-add-apps btn-small py-2 my-3 mt-0"
                        onClick={() => {
                          this.setState({ showPopup: true });
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="pl-2">
                          {t('pages.AdminRoleChange.addAdmin')}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div style={{ 'margin-top': '2rem' }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="th1 align-middle">#</th>
                          <th colspan="2" className="th1 align-middle">
                            {t('pages.AdminRoleChange.userName')}
                          </th>
                          <th colspan="2" className="th1 align-middle">
                            {t('pages.AdminRoleChange.email')}
                          </th>
                          <th colspan="3" className="th1 align-middle">
                            {t('pages.AdminRoleChange.roleChange')}
                          </th>
                          <th colspan="3" className="th1 align-middle">
                            {t('pages.AdminRoleChange.saveRole')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.length > 0
                          ? userList.map((elem, index) => {
                              elem.stateChange = false;
                              return (
                                <tr key={index}>
                                  <td className="td1 align-middle">
                                    {index + 1}
                                  </td>
                                  <td colspan="2" className="td1 align-middle">
                                    {elem.Username}
                                  </td>
                                  <td colspan="2" className="td1 align-middle">
                                    {
                                      elem.Attributes[
                                        elem.Attributes.length - 1
                                      ].Value
                                    }
                                  </td>
                                  <td colspan="3" className="td1 align-middle">
                                    <span>
                                      <Checkbox
                                        classes={{
                                          display: 'inline',
                                          'margin-left': '2rem',
                                        }}
                                        name={'Admin Role'}
                                        labelAlignment="right"
                                        label="Admin Role"
                                        isChecked={
                                          elem.role == 'Admin' ? true : false
                                        }
                                        handleChange={(checked) => {
                                          if (checked) {
                                            this.addRoleToUser(
                                              elem.Username,
                                              'Admin',
                                              elem
                                            );
                                          } else {
                                            this.addRoleToUser(
                                              elem.Username,
                                              '',
                                              elem
                                            );
                                          }
                                        }}
                                      />
                                    </span>
                                    {/*
                                    <DropdownButton
                                      ariaDescribedby="role"
                                      title={
                                        elem.Attributes[
                                          elem.Attributes.length - 2
                                        ].Value == ''
                                          ? t('pages.profile.pleaseSelect')
                                          : t(
                                              `pages.registration.${
                                                elem.Attributes[
                                                  elem.Attributes.length - 2
                                                ].Value
                                              }`
                                            )
                                      }
                                      className="role-dropdown"
                                      variant="Secondary"
                                    >
                                      <Dropdown.Item
                                        eventKey="AdminUser"
                                        onClick={() => {
                                          this.addRoleToUser(
                                            elem.Username,
                                            'AdminUser',
                                            elem
                                          );
                                        }}
                                      >
                                        {t('pages.registration.AdminUser')}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="StandardUser"
                                        onClick={() => {
                                          this.addRoleToUser(
                                            elem.Username,

                                            'StandardUser',
                                            elem
                                          );
                                        }}
                                      >
                                        {t('pages.registration.StandardUser')}
                                      </Dropdown.Item>
                                      </DropdownButton>*/}
                                  </td>
                                  <td colspan="3" className="td1 align-middle">
                                    <Button
                                      className={
                                        elem.roleChangeStatus
                                          ? 'col btn btn-secondary hollow mr-sm-4 py-2 my-3'
                                          : 'col btn btn-primary   mr-sm-4 py-2 my-3'
                                      }
                                      type="submit"
                                      disabled={elem.roleChangeStatus}
                                      onClick={() =>
                                        this.updateRoleAdminInitialize(elem)
                                      }
                                    >
                                      {t('pages.changePassword.save')}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
AdminRoleChange.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
  getUserDetails: PropTypes.func.isRequired,
};
export default withTranslation()(AdminRoleChange);
