import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Ticker from 'react-ticker';

import PropTypes from 'prop-types';

import http from 'api/http-request';
import 'app/modules/Shared/AlertGlobal/AlertGlobal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

class AlertGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertNotifications: [],
    };
  }

  render() {
    const { alertNotifications } = this.state;
    const { useTicker } = this.props;

    if (useTicker && alertNotifications) {
      if (alertNotifications?.length > 0) {
        return (
          <div>
            <Ticker
              offset="run-in"
              speed={7}
              height={32}
              mode={'chain'}
              className="ticker-container"
            >
              {() => (
                <div className="row m-0 flex-nowrap">
                  {alertNotifications.map((item, i) => (
                    <span key={i} className="mr-5 pr-5 ticker-item red">
                      {item}
                    </span>
                  ))}
                </div>
              )}
            </Ticker>
          </div>
        );
      } else {
        return '';
      }
    } else if (alertNotifications) {
      return (
        <div>
          <div className="alert-style nice-scrollbar px-3">
            {alertNotifications.map((item, iterator) =>
              item ? (
                <div
                  className={`d-flex red`}
                  role="alert"
                  aria-live="assertive"
                  key={`globalAlert ${iterator}`}
                >
                  <span className="px-3 text-right">
                    <FontAwesomeIcon icon={faExclamationCircle} role="img" />
                  </span>
                  <p>{item}</p>
                </div>
              ) : (
                ''
              )
            )}
          </div>
        </div>
      );
    } else {
      return '';
    }
  }
}

AlertGlobal.propTypes = {
  alertText: PropTypes.string,
  className: PropTypes.string,
  useTicker: PropTypes.bool,
};

export default withTranslation()(AlertGlobal);
