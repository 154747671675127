import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainMenu from 'app/modules/Dashboard Cognito/MainMenu';
import Home from 'app/modules/Dashboard Cognito/Home';
import Apps from 'app/modules/Dashboard Cognito/Apps';
import Admin from 'app/modules/Dashboard Cognito/Admin';
import AdminRoleChange from 'app/modules/Dashboard Cognito/AdminRoleChange';
import IDs from 'app/modules/Dashboard Cognito/IDs';
import Wallet from 'app/modules/Dashboard Cognito/Wallet';
import Account from 'app/modules/Dashboard Cognito/Account';
import 'app/modules/Dashboard Cognito/Dashboard.scss';
import { withTranslation } from 'react-i18next';
import http from 'api cognito/http-request';
import queryString from 'query-string';
import { somethingWentWrong } from 'utils/common cognito';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import { checkTimeDiffValid, redirectLogic } from 'utils/common/index';
import AlertGlobal from '../Shared/AlertGlobal Cognito/AlertGlobal';
import { clearCookies } from 'utils/common cognito';
import ReactGA from 'react-ga';
import { getAdminUser } from 'api cognito/aws4_module';
import { aws_request } from 'api cognito/aws-request';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect_uri: '',
      client_id: '',
      code: '',
      iss: '',
      getAccessTokenPayLoad: {},
      bearerToken: '',
      userId: '',
      token: '',
      userDetails: {
        userName: '',
        mail: '',
        telephoneNumber: '',
        givenName: '',
        sn: '',
        _refResourceId: '',
        effectiveRoles: [],
        UserAttributes: [
          {
            Name: 'custom:Confirm_Email',
            Value: '',
          },
          {
            Name: 'email_verified',
            Value: 'false',
          },
          {
            Name: 'custom:Confirm_Password',
            Value: '',
          },
          {
            Name: 'custom:Confirm_Password',
            Value: '',
          },
          {
            Name: 'custom:Last_Name',
            Value: '',
          },
          {
            Name: 'phone_number_verified',
            Value: 'false',
          },
          {
            Name: 'phone_number',
            Value: '',
          },
          {
            Name: 'custom:First_Name',
            Value: '',
          },
          {
            Name: 'email',
            Value: '',
          },
        ],
      },
      socialPayLoad: {},
      admin: false,
    };
  }

  getUserDetails = (token) => {
    if (token) {
      // return as token is there, thus no need to fetch user details
      return;
    }

    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
      }
    ).then((res) => {
      if (res.data && res.status === 200) {
        Cookies.set('userName', res.data.Username);
        const { setUserDetails } = this.props;
        let cloneData = _.cloneDeep(res.data);
        let data = res.data;

        data.UserAttributes.map((elem, index) => {
          if (elem.Name == 'custom:Confirm_Email') {
            data.mail = elem.Value;
          } else if (elem.Name == 'custom:LastName') {
            data.sn = elem.Value;
          } else if (elem.Name == 'custom:First_Name') {
            data.givenName = elem.Value;
          }
        });
        data.userName = data.Username;
        if (!data.UserAttributes.find((o) => o.Name === 'phone_number')) {
          data.UserAttributes[6].Name = 'phone_number';
          data.UserAttributes[6].Value = '';
          data.UserAttributes[7] = {};
          data.UserAttributes[7].Name = cloneData.UserAttributes[6].Name;
          data.UserAttributes[7].Value = cloneData.UserAttributes[6].Value;
        }

        setUserDetails(data);

        this.setState({
          userDetails: res.data,
        });

        this.checkAdminUser(data.userName);
        ReactGA.event({
          category: 'Login',
          action:
            'Login-Dashboard:  User authorized and user details are stored',
        });
      } else if (
        res.status === 400 &&
        (res.data.__type === 'NotAuthorizedException' ||
          res.data.__type === 'InvalidParameterException')
      ) {
        clearCookies();
        ReactGA.event({
          category: 'Login',
          action: 'Login-Dashboard:  User not authorized.',
        });

        window.location.href = '/';
      }
    });
  };

  checkAdminUser = (username) => {
    let customSet = (params) => {
      this.setState(params);
    };
    var params = {
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
      Username: username /* required */,
    };
    let cognitoidentityserviceprovider = aws_request();
    cognitoidentityserviceprovider.adminListGroupsForUser(
      params,
      function (err, data) {
        if (data.Groups.length > 0) {
          data.Groups.map((obj) => {
            if (obj.GroupName == 'Admin') {
              customSet({ admin: true });
            } else {
              customSet({ admin: false });
            }
          });
        } else {
          customSet({ admin: false });
        }
      }
    );
  };

  getAdminUser = async (username) => {
    const { setUserDetails } = this.props;
    var output = await getAdminUser(username, setUserDetails);

    if (output) {
      this.setState({ userDetails: output });
      setUserDetails(output);
    }
  };

  getUserInfo = () => {
    let token = Cookies.get('dataStoreToken');
    let bearer = 'Bearer ';

    http(
      {
        url: '/userInfo',
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      Cookies.set('userName', res.data.username);
      this.getAdminUser(res.data.username);
    });
  };

  getSalesforceToken = () => {
    let token = Cookies.get('dataStoreToken');

    const {
      location: { search },
    } = this.props;
    const { code } = queryString.parse(search);

    if (code) {
      let getAccessTokenPayLoad = {};
      getAccessTokenPayLoad.grant_type = 'authorization_code';
      getAccessTokenPayLoad.client_id = `${process.env.REACT_APP_COGNITO_ClientID}`;
      getAccessTokenPayLoad.code = code;
      getAccessTokenPayLoad.redirect_uri =
        `${process.env.REACT_APP_DOMAIN}` + 'profile/Dashboard';
      http(
        {
          url: '/salesforcetoken',
          data: queryString.stringify(getAccessTokenPayLoad),
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-www-form-urlencoded',
          //Authorization: 'Basic ' + authorization,
        }
      ).then((res) => {
        if (res.status === 200) {
          let token = res.data.access_token;
          Cookies.set('dataStoreToken', res.data.access_token);
          Cookies.set('code', code);
          this.getUserInfo(token);
          //this.getUserDetails(token);
        } else {
          let token = Cookies.get('dataStoreToken');
          this.getUserDetails(token);
        }
      });
    } else {
      let token = Cookies.get('dataStoreToken');
      this.getUserDetails(token);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getSalesforceToken();
  }

  render() {
    const {
      match: {
        params: { pageName },
      },
      setUserDetails,
    } = this.props;
    const { userDetails, admin } = this.state;
    return (
      <>
        <MainMenu pageName={pageName || ''} admin={admin} />
        <div className="dashboard">
          {!pageName ||
            (pageName === 'Dashboard' && (
              <Home
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                checkAdminUser={this.checkAdminUser}
              />
            ))}
          {!pageName ||
            (pageName === 'Admin' && (
              <AdminRoleChange
                userDetails={userDetails}
                getUserDetails={this.getUserDetails}
              />
            ))}
          {!pageName ||
            ((pageName === 'personalInformation' ||
              pageName === 'Account' ||
              pageName === 'changePassword' ||
              pageName === 'CommunicationPreferences' ||
              pageName === 'AuthorizedApps' ||
              pageName === 'PeopleSharing' ||
              pageName === 'PrivacyConsent' ||
              pageName === 'ActivityLog' ||
              pageName === 'AccountManagement' ||
              pageName === 'SiteSettings' ||
              pageName === 'Devices') && (
              <Account
                pageName={pageName || ''}
                setUserDetails={setUserDetails}
                userDetails={userDetails}
                checkAdminUser={this.checkAdminUser}
              />
            ))}
        </div>
      </>
    );
  }
}
Dashboard.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  setHasRouteChanged: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withTranslation()(Dashboard);
