import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { withTranslation } from 'react-i18next';
import 'app/modules/IdProofing/IdProofingBlockedPopup.scss';
import faceScan from './img/faceScan.png';
import closeButton from './img/closeButton.png';
import warningButton from './img/warning.png';
import { Progress } from 'react-sweet-progress';
import Cookies from 'js-cookie';
import http from 'api/http-request';
import 'react-sweet-progress/lib/style.css';
import { motion, AnimatePresence } from 'framer-motion';
import blockedAnimation from 'assets/json/contact_admin.json';
import finsihAnimation from 'assets/json/verified.json';

function IdProofingBlockedPopup() {
  const blocked = {
    loop: true,
    autoplay: true,
    animationData: blockedAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        className="popupBackground-idproofing"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="popupContainer-idproofing"
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
        >
          <div className="titleCloseBtn">
            {/* <button onClick={() => closePopup()}> X </button> */}
            <img
              className="closeBtn"
              alt="Close"
              src={closeButton}
              data-holder-rendered="true"
            />
          </div>
              <div className="popupTitle-idproofing">
                <h3>
                  Access Blocked
                </h3>
              </div>
              <div className="popupBody-idproofing">
                <div className="popupBody-idproofing-image">
                  {/* <img
                    alt="100x100"
                    src={faceScan}
                    data-holder-rendered="true"
                  /> */}
                  <Lottie options={blocked} height={150} width={150} />
                </div>
                <p>
                 You have tried and failed 3 times while trying to idproof your account.
                 Hence your account access has been revoked to reinstate it you can 
                 reach out to the Admin department.
                </p>
              </div>
              <div className="popupFooter-idproofing">
                <button id='cancelBtn' >Logout</button>
              </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

IdProofingBlockedPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(IdProofingBlockedPopup);
