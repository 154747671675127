import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/EditEmailPopup.scss';
import {
  preventNonDigitCharacters,
  formatPhoneNumber,
  getPhoneNumberFromFormattedString,
} from 'utils/common';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';

class EditMobileNumberPopup extends Component {
  constructor(props) {
    super(props);
    this.mobileNumberInputRef = React.createRef();
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      errorText: '',
      errorArray: [],
      sn: props.sn ? props.sn : '',
      verificationCode: '',
      screen: 1,
      mail: props.mail ? props.mail : '',
      verificationCodeError: '',
      mobileNumber: props.telephoneNumber ? props.telephoneNumber : '',
      mobileNumberUSFormatted: '',
    };
  }

  handleKeyPress = (e) => {
    preventNonDigitCharacters(e);
  };

  componentDidMount() {
    this.onMobileNumberChange();
  }

  onMobileNumberChange = (e) => {
    if (e) {
      const currentValue = e.target.value.trim();
      this.setState((prevState) => ({
        ...prevState,
        mobileNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
      }));
    } else {
      const currentValue = this.state.mobileNumber.trim().replace('+91', '');
      this.setState((prevState) => ({
        ...prevState,
        mobileNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
      }));
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { mobileNumber } = this.state;
    if (mobileNumber.length >= 1 && mobileNumber.length < 14) {
      this.setState({
        mobileNumberError: 'pages.editNumberPopup.mobileNumberInvalid',
      });
      this.mobileNumberInputRef.current.focus();
    } else {
      this.setState(
        {
          mobileNumberError: '',
          mobileNumberUSFormatted:
            mobileNumber === ''
              ? ''
              : getPhoneNumberFromFormattedString(mobileNumber),
        },
        () => {
          this.formSubmit();
        }
      );
    }
  };

  getAPIHeaders = () => {
       const iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    return {
         iPlanetDirectoryPro: `${iPlanetDirectoryPro}`,
    };
  };

  formSubmit = () => {
    this.setState({
      mobileNumberError: '',
    });
    const user_id = Cookies.get('user_id');
    let userId = user_id;
    const { onEditMobileNumberSuccessHandler, telephoneNumber } = this.props;
    
    const payload = [
      {
        operation: 'add',
        field: '/telephoneNumber',
        value: this.state.mobileNumber,
      },
    ];
    http(
      {
        url: `updateSelfProfile`,
        method: 'PATCH',
        data: payload,
      },
      { ...this.getAPIHeaders() }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode == 'IAM000') {
        onEditMobileNumberSuccessHandler('success');
      }
      else if (res.status === 401) {
        this.showError('pages.idproofing.sessionExpiry');
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      } else {
        this.setState({
          mobileNumberError: 'Mobile Number Invalid',
        });
      }
    });
  };





  // getOtp = () => {
  //   const { mobileNumberUSFormatted } = this.state;
  //   let bearer = 'Bearer ';
  //   // let token = localStorage.getItem('dataStoreToken');
  //   let token = Cookies.get('dataStoreToken');
  //   const { onEditMobileNumberSuccessHandler } = this.props;
  //   http(
  //     {
  //       url: 'endpoint/phoneVerification?_action=generateOtp',
  //       method: 'POST',
  //       data: {
  //         phoneNumber: mobileNumberUSFormatted,
  //       },
  //     },
  //     {
  //       Authorization: `${bearer}${token}`,
  //     }
  //   ).then((res) => {
  //     if (
  //       res.status === 200 &&
  //       res.data &&
  //       res.data.code === 200 &&
  //       res.data.message === 'OTP_SENT_SUCCESS'
  //     ) {
  //       this.setState({
  //         screen: 2,
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.reason === 'Bad Request' &&
  //       res.data.message.includes('Missing required')
  //     ) {
  //       this.setState({
  //         mobileNumberError: 'pages.editNumberPopup.mobileNumberRequired',
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'INVALID_NUMBER'
  //     ) {
  //       this.setState({
  //         mobileNumberError: 'pages.editNumberPopup.mobileNumberInvalid',
  //       });
  //     }
  //   });
  // };

  // resendVerify = (e) => {
  //   e.preventDefault();
  //   const { mobileNumberUSFormatted } = this.state;
  //   const {
  //     onEditMobileNumberSuccessHandler,
  //     onErrorEmailSuccessHandler,
  //   } = this.props;
  //   this.setState({
  //     verificationCode: '',
  //     verificationCodeError: '',
  //   });
  //   let bearer = 'Bearer ';
  //   // let token = localStorage.getItem('dataStoreToken');
  //   let token = Cookies.get('dataStoreToken');
  //   http(
  //     {
  //       url: 'endpoint/phoneVerification?_action=resendOtp',
  //       method: 'POST',
  //       data: {
  //         phoneNumber: mobileNumberUSFormatted,
  //       },
  //     },
  //     {
  //       Authorization: `${bearer}${token}`,
  //     }
  //   ).then((res) => {
  //     if (res.status === 200 && res.data && res.data.code === 200) {
  //       this.setState({
  //         screen: 2,
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.message === 'Please enter valid OTP'
  //     ) {
  //       this.setState({
  //         verificationCodeError: 'pages.editEmail.verificationCodeError',
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'CONTENT_REQUIRED'
  //     ) {
  //       this.setState({
  //         verificationCodeError: 'pages.editEmail.contentRequired',
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'ACTION_REQUIRED'
  //     ) {
  //       this.setState({
  //         verificationCodeError: 'pages.editEmail.contentRequired',
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'OTP_REQUIRED'
  //     ) {
  //       this.setState({
  //         verificationCodeError: 'pages.editEmail.otpRequired',
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'OTP_INVALID'
  //     ) {
  //       this.setState({
  //         verificationCodeError: 'pages.editEmail.invalidOTP',
  //       });
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'OTP_EXPIRED'
  //     ) {
  //       this.setState({
  //         verificationCodeError: 'pages.editEmail.expiredOTP',
  //       });
  //     } else if (
  //       res.status === 502 &&
  //       res.data.code === 502 &&
  //       res.data.message === 'SMTP_ERROR'
  //     ) {
  //       onErrorEmailSuccessHandler('error', res.data.message);
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'MAX_RESEND_EXCEEDED'
  //     ) {
  //       onErrorEmailSuccessHandler('error', res.data.message);
  //     } else if (
  //       res.status === 400 &&
  //       res.data.code === 400 &&
  //       res.data.message === 'MAX_RETRY_EXCEEDED'
  //     ) {
  //       onErrorEmailSuccessHandler('error', res.data.message);
  //     } else {
  //       onEditMobileNumberSuccessHandler('error');
  //     }
  //   });
  // };

  // onVerify = (e) => {
  //   e.preventDefault();
  //   const { verificationCode } = this.state;
  //   const {
  //     onEditMobileNumberSuccessHandler,
  //     onErrorEmailSuccessHandler,
  //   } = this.props;
  //   if (verificationCode === '') {
  //     this.setState({
  //       verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
  //     });
  //   } else {
  //     let bearer = 'Bearer ';
  //     // let token = localStorage.getItem('dataStoreToken');
  //     let token = Cookies.get('dataStoreToken');
  //     this.setState({
  //       verificationCodeError: '',
  //     });
  //     http(
  //       {
  //         url: 'endpoint/phoneVerification?_action=validateOtp',
  //         method: 'POST',
  //         data: {
  //           otp: verificationCode,
  //         },
  //       },
  //       {
  //         Authorization: `${bearer}${token}`,
  //       }
  //     ).then((res) => {
  //       if (res.status === 200 && res.data && res.data.code === 200) {
  //         onEditMobileNumberSuccessHandler('success');
  //         ReactGA.event({
  //           category: 'Mobile Update-Success',
  //           action: 'Mobile Updated and otp verified.',
  //         });
  //       } else if (
  //         res.status === 400 &&
  //         res.data.message === 'Please enter valid OTP'
  //       ) {
  //         this.setState({
  //           verificationCodeError: 'pages.editEmail.verificationCodeError',
  //         });
  //         ReactGA.event({
  //           category: 'Mobile Update -Faliure',
  //           action: 'OTP verification Failed',
  //         });
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'CONTENT_REQUIRED'
  //       ) {
  //         this.setState({
  //           verificationCodeError: 'pages.editEmail.contentRequired',
  //         });
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'ACTION_REQUIRED'
  //       ) {
  //         this.setState({
  //           verificationCodeError: 'pages.editEmail.contentRequired',
  //         });
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'OTP_REQUIRED'
  //       ) {
  //         this.setState({
  //           verificationCodeError: 'pages.editEmail.otpRequired',
  //         });
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'OTP_INVALID'
  //       ) {
  //         this.setState({
  //           verificationCodeError: 'pages.editEmail.invalidOTP',
  //         });
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'OTP_EXPIRED'
  //       ) {
  //         this.setState({
  //           verificationCodeError: 'pages.editEmail.expiredOTP',
  //         });
  //       } else if (
  //         res.status === 502 &&
  //         res.data.code === 502 &&
  //         res.data.message === 'SMTP_ERROR'
  //       ) {
  //         onErrorEmailSuccessHandler('error', res.data.message);
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'MAX_RESEND_EXCEEDED'
  //       ) {
  //         onErrorEmailSuccessHandler('error', res.data.message);
  //       } else if (
  //         res.status === 400 &&
  //         res.data.code === 400 &&
  //         res.data.message === 'MAX_RETRY_EXCEEDED'
  //       ) {
  //         onErrorEmailSuccessHandler('error', res.data.message);
  //       } else {
  //         onEditMobileNumberSuccessHandler('error');
  //       }
  //     });
  //   }
  // };

  // handleChange(evt) {
  //   const verificationCode = evt.target.validity.valid
  //     ? evt.target.value
  //     : this.state.verificationCode;

  //   this.setState({ verificationCode });
  // }

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 1,
      verificationCodeError: '',
      verificationCode: '',
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      screen,
      verificationCodeError,
      verificationCode,
      mobileNumberError,
      mobileNumber,
      numberFocus,
      telephoneNumber,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="change-password-popup edit-email-pop">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {telephoneNumber
                        ? t('pages.editNumberPopup.editNumber')
                        : t('pages.editNumberPopup.addPhoneNumber')}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  {screen === 1 && (
                    <>
                      <Form.Group controlId="mobileNumber">
                        <Form.Label>
                          {t('pages.editNumberPopup.mobilePhone')}
                        </Form.Label>
                        <Form.Control
                          ref={this.mobileNumberInputRef}
                          className={
                            mobileNumberError ? 'error-input' : undefined
                          }
                          onKeyDown={this.handleKeyPress}
                          placeholder={!numberFocus ? '(###) ###-####' : ''}
                          onFocus={() => {
                            this.setState({
                              numberFocus: true,
                            });
                          }}
                          onBlur={() => {
                            this.setState({
                              numberFocus: false,
                            });
                          }}
                          onChange={this.onMobileNumberChange}
                          value={mobileNumber}
                          autoFocus
                          aria-required
                          aria-invalid={!!mobileNumberError}
                          aria-describedby="mobile-number-error"
                        />
                      </Form.Group>
                      <p className="error-text" id="mobile-number-error">
                        {mobileNumberError && t(mobileNumberError)}
                      </p>
                      <div className="mb-3 mt-3">
                        <Button
                          className="wrapper__container-card-body-submit mb-3"
                          size="lg"
                          variant="primary"
                          block
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                        >
                          {t('pages.editNumberPopup.save')}
                        </Button>
                      </div>
                    </>
                  )}
                  {screen === 2 && (
                    <div className="col-md-12 registration__container">
                      <h3>
                        <span>
                          {t('pages.forgotUserId.verificationMethod')}
                        </span>
                      </h3>
                      <div className="registration__container__resend-verification">
                        <div className="registration__container__resend-verification__resend-text">
                          <p>
                            {t(
                              'pages.editNumberPopup.enterTheVerificationText'
                            )}
                            {' +1'}
                            {mobileNumber}
                          </p>
                          <p
                            className="resend-link"
                            onClick={(e) => this.resendVerify(e)}
                            role="button"
                            tabIndex={0}
                          >
                            {t('pages.registration.resendVerificationCode')}
                          </p>
                        </div>
                      </div>
                      <Form>
                        <Form.Group
                          className="verification-form"
                          controlId="verificationCode"
                        >
                          <Form.Label className="mb-4">
                            {t('pages.registration.verificationCode')}
                          </Form.Label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            onInput={this.handleChange.bind(this)}
                            className="ml-3"
                            value={verificationCode}
                          />
                          {verificationCodeError && (
                            <p className="error-text" id="verification-error">
                              {verificationCodeError &&
                                t(verificationCodeError)}
                            </p>
                          )}
                        </Form.Group>
                        <div className="registration__container__pleasenote pleasenote">
                          <p className="registration__container__pleasenote-fp">
                            {t('pages.registration.note')}
                          </p>
                          <p className="registration__container__pleasenote-sp">
                            {t('pages.registration.verifyNote')}
                          </p>
                        </div>
                        <div className="row pt-3">
                          <div className="d-sm-flex col-lg-6 col-md-12">
                            <button
                              className="col btn btn-secondary mr-sm-4 py-2 my-3"
                              onClick={(e) => this.onVerify(e)}
                            >
                              {t('pages.registration.verify')}
                            </button>
                            <button
                              className="col btn btn-secondary hollow py-2 my-3"
                              onClick={(e) => this.onBackLogin(e)}
                              variant="primary"
                              block
                            >
                              {t('pages.forgotPassword.backBtn')}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

EditMobileNumberPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditMobileNumberSuccessHandler: PropTypes.func.isRequired,
  onErrorEmailSuccessHandler: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
  mail: PropTypes.string,
  telephoneNumber: PropTypes.string,
};
EditMobileNumberPopup.defaultProps = {
  givenName: '',
  sn: '',
  mail: '',
  telephoneNumber: '',
};
export default withTranslation()(EditMobileNumberPopup);
