import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard Cognito/ProfileLeftMenu.scss';
import ProfileLeftMenu from 'app/modules/Dashboard Cognito/ProfileLeftMenu';
import { withTranslation } from 'react-i18next';
import RequestAccess from 'app/modules/Dashboard Cognito/RequestAccess';
import PersonalInformation from 'app/modules/Dashboard Cognito/PersonalInformation';
import ChangePassword from 'app/modules/Dashboard Cognito/ChangePassword';
import Devices from 'app/modules/Dashboard Cognito/Devices';
import CommunicationPreferences from 'app/modules/Dashboard Cognito/CommunicationPreferences';
import AuthorizedApps from 'app/modules/Dashboard Cognito/AuthorizedApps';
import PeopleSharing from 'app/modules/Dashboard Cognito/PeopleSharing';
import ActivityLog from 'app/modules/Dashboard Cognito/ActivityLog';
import SiteSettings from 'app/modules/Dashboard Cognito/SiteSettings';
import AccountManagement from 'app/modules/Dashboard Cognito/AccountManagement';
import PrivacyConsent from 'app/modules/Dashboard Cognito/PrivacyConsent';
import http from 'api cognito/http-request';
import { clearCookies } from 'utils/common cognito';
import 'app/modules/Dashboard Cognito/Account.scss';
import Cookies from 'js-cookie';
import { getAdminUser } from 'api cognito/aws4_module';
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails ? props.userDetails : {},
    };
  }

  getAdminUser = async (username) => {
    const { setUserDetails } = this.props;
    var output = await getAdminUser(username);

    if (output) {
      setUserDetails(output);
      let { checkAdminUser } = this.props;
      this.setState(
        {
          userDetails: output,
        },
        () => {
          checkAdminUser(this.state.userDetails.Username);
        }
      );
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let token = Cookies.get('dataStoreToken');
    let code = Cookies.get('code');

    if (code) {
      this.getAdminUser(Cookies.get('userName'));
    } else {
      if (token) {
        this.getUserDetails(token);
      }
    }
  }

  getUserDetails = (token) => {
    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
      }
    ).then((res) => {
      if (res.data && res.status === 200) {
        const { setUserDetails } = this.props;
        let cloneData = _.cloneDeep(res.data);
        let data = res.data;
        setUserDetails(data);
        let { checkAdminUser } = this.props;
        this.setState(
          {
            userDetails: res.data,
          },
          () => {
            checkAdminUser(data.Username);
          }
        );
      } else if (
        res.status === 400 &&
        res.data.__type === 'NotAuthorizedException'
      ) {
        clearCookies();
        //window.location.href = '/?app=cognito';
        window.location.href = '/';
      }
    });
  };

  getUpdatedName = () => {
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let id = Cookies.get('user_id');
    this.getUserDetails(id, token);
  };
  render() {
    const { pageName, setUserDetails } = this.props;
    const { userDetails } = this.state;
    return (
      <Container fluid className="profile">
        <Row noGutters className="profile__row">
          <Col
            md={{ span: 12 }}
            lg={{ span: 3 }}
            className="profile__row__left"
          >
            <ProfileLeftMenu pageName={pageName || ''} />
          </Col>
          <Col
            md={{ span: 12 }}
            lg={{ span: 9 }}
            className="profile__row__right"
          >
            {!pageName ||
              (pageName === 'Account' && (
                <PersonalInformation
                  userDetails={userDetails}
                  getUpdatedName={() => {
                    this.getUpdatedName();
                  }}
                  setUserDetails={setUserDetails}
                />
              ))}
            {pageName === 'personalInformation' && (
              <PersonalInformation
                userDetails={userDetails}
                getUpdatedName={() => {
                  this.getUpdatedName();
                }}
                setUserDetails={setUserDetails}
              />
            )}
            {pageName === 'changePassword' && (
              <ChangePassword userDetails={userDetails} />
            )}
            {/* {pageName === 'Devices' && <Devices userDetails={userDetails} />} */}
            {pageName === 'AuthorizedApps' && <AuthorizedApps />}
            {pageName === 'PrivacyConsent' && <PrivacyConsent />}
            {pageName === 'ActivityLog' && <ActivityLog />}
            {pageName === 'AccountManagement' && <AccountManagement />}
            {pageName === 'CommunicationPreferences' && (
              <CommunicationPreferences />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
Account.propTypes = {
  pageName: PropTypes.string.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  checkAdminUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object,
  userDetails: {},
};
Account.defaultProps = {
  userData: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
  userDetails: {},
};
export default withTranslation()(Account);
