import React, { Component } from 'react';
import 'app/modules/Shared/ChatBot/DotWaveAnimation.scss';

class DotWaveAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="wave">
        <span className="dot" key="dot1"></span>
        <span className="dot" key="dot2"></span>
        <span className="dot" key="dot3"></span>
      </div>
    );
  }
}
export default DotWaveAnimation;
