import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainMenu from 'app/modules/Dashboard/MainMenu';
import Home from 'app/modules/Dashboard AegisCloud/Home';
import Apps from 'app/modules/Dashboard/Apps';
import Admin from 'app/modules/Dashboard/Admin';
import IDs from 'app/modules/Dashboard/IDs';
import Wallet from 'app/modules/Dashboard/Wallet';
import Notifications from 'app/modules/Dashboard/Notifications';
import Account from 'app/modules/Dashboard AegisCloud/Account';
import 'app/modules/Dashboard/Dashboard.scss';
import { withTranslation } from 'react-i18next';
import http from 'api aegisCloud/http-request';
import queryString from 'query-string';
import { somethingWentWrong } from 'utils/common';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import { checkTimeDiffValid, redirectLogic } from 'utils/common/index';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';
import ReactGA from 'react-ga';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect_uri: '',
      client_id: '',
      code: '',
      iss: '',
      getAccessTokenPayLoad: {},
      bearerToken: '',
      userId: '',
      token: '',
      userDetails: {
        userName: '',
        mail: '',
        telephoneNumber: '',
        givenName: '',
        sn: '',
        _refResourceId: '',
        effectiveRoles: [],
      },
      NotificationAlets: 0,
      notificationsArray: [],
      socialPayLoad: {},
      admin: false,
    };
  }

  getAPIHeaders = () => {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  };

  getAPICORSheaders = (bearer, token) => {
    return {
      Authorization: `${bearer}${token}`,
    };
  };

  getUserDetails = (id, token) => {
    if (token || id) {
      return;
    }
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';
    http(
      {
        url: `bravo_user/${id}`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        const { setUserDetails } = this.props;
        setUserDetails(res.data);
        this.setState({
          userDetails: res.data,
        });
      }
    });
  };
  /*
  
  getUserDetails = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';
    http(
      {
        url: `managed/user/${id}?&_fields=*`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        const { setUserDetails } = this.props;
        setUserDetails(res.data);
        // if (localStorage.getItem('socialLogin')) {
        if (Cookies.get('socialLogin')) {
          // localStorage.setItem('email', res.data.mail);
          Cookies.set('email', res.data.mail);
        } else {
          // localStorage.removeItem('email');
          Cookies.remove('email');
        }

        this.setState({
          userDetails: res.data,
        });

        // store name for chatbot use
        // localStorage.setItem('firstName', res.data.givenName);
        Cookies.set('firstName', res.data.givenName);
        // localStorage.setItem('lastName', res.data.sn);
        Cookies.set('lastName', res.data.sn);

        for (let i = 0; i < res.data.effectiveRoles.length; i++) {
          let item = res.data.effectiveRoles[i];

          if (
            ('name' in item &&
              item.name === process.env.REACT_APP_ADMIN_NAME &&
              item._id == process.env.REACT_APP_ADMIN_ID) ||
            (item.name === process.env.REACT_APP_UI_NAME &&
              item._id == process.env.REACT_APP_UI_ID)
          ) {
            this.setState({
              admin: true,
            });
            break;
          }
        }

        if (res.data.preferredLanguage === 'en') {
          // localStorage.setItem('ctsessionlanguage', 'en_US');
          Cookies.set('ctsessionlanguage', 'en_US');
          i18n.changeLanguage('en_US');
        } else {
          // localStorage.setItem('ctsessionlanguage', 'es');
          Cookies.set('ctsessionlanguage', 'es');
          i18n.changeLanguage(res.data.preferredLanguage);
        }
      }
    });
  };
  */

  isLooggedIn = () => {
    http(
      {
        url: 'sessioninfo',
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.0',
      }
    ).then((res) => {
      Cookies.set('user_id', res.data.id);
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const {
      location: { search },
    } = this.props;
    const {
      code,
      iss,
      client_id,
      state,
      scope,
      authuser,
      prompt,
    } = queryString.parse(search);
    const { getAccessTokenPayLoad, socialPayLoad } = this.state;
    const environment = process.env.REACT_APP_ENV;

    // if (!localStorage.getItem('isUserLogin')) {
    if (!Cookies.get('isUserLogin')) {
      this.isLooggedIn();
    }

    if (code) {
      // if (state || scope || authuser) {
      //   socialPayLoad.state = state;
      //   socialPayLoad.scope = scope;
      //   socialPayLoad.authuser = authuser;
      //   socialPayLoad.prompt = prompt;
      //   socialPayLoad.code = code;
      //   socialPayLoad.realm = 'aegis';
      //   // localStorage.setItem('socialLogin', JSON.stringify(socialPayLoad));
      //   Cookies.set('socialLogin', JSON.stringify(socialPayLoad));

      //   window.location.href = '/';
      // } else {
      // localStorage.setItem('clientId', client_id);
      Cookies.set('clientId', client_id);

      // getAccessTokenPayLoad.code =
      //   code || localStorage.getItem(getAccessTokenPayLoad.code);
      getAccessTokenPayLoad.code =
        code || Cookies.get(getAccessTokenPayLoad.code);
      // getAccessTokenPayLoad.client_id =
      //   client_id || localStorage.getItem(getAccessTokenPayLoad.client_id);
      getAccessTokenPayLoad.client_id =
        client_id || Cookies.get(getAccessTokenPayLoad.client_id);
      getAccessTokenPayLoad.grant_type = 'authorization_code';
      if (environment === 'dev') {
        getAccessTokenPayLoad.redirect_uri =
          'https://aegisfrkui.deloitte.com/profile/Dashboard';
      } else {
        getAccessTokenPayLoad.redirect_uri =
          'http://localhost:3000/profile/Dashboard';
      }
      getAccessTokenPayLoad.realm = '/bravo';
      let authorizeUrl = '?realm=/bravo';
      // localStorage.setItem(
      //   'profileCodeDetails',
      //   JSON.stringify(getAccessTokenPayLoad)
      // );
      Cookies.set('profileCodeDetails', JSON.stringify(getAccessTokenPayLoad));

      http(
        {
          url: 'accessToken',
          method: 'POST',
          data: queryString.stringify(getAccessTokenPayLoad),
        },
        { ...this.getAPIHeaders() }
      ).then((res) => {
        // localStorage.setItem('idToken', res.data.id_token);
        Cookies.set('idToken', res.data.id_token);

        // localStorage.setItem('dataStoreToken', res.data.access_token);
        Cookies.set('dataStoreToken', res.data.access_token);
        ReactGA.event({
          category: 'Login',
          action: 'Login-Dashboard: Token Generated for User after Login',
        });

        const { bearerToken } = this.state;
        this.setState({
          bearerToken: res.data.token_type,
        });
        // let token = localStorage.getItem('dataStoreToken');
        let token = Cookies.get('dataStoreToken');

        let bearer = 'Bearer ';
        // 'Thu Sep 30 2021 18:07:44 GMT+0530 (India Standard Time)'
        let sessionTimeStart = moment(new Date());
        // localStorage.setItem('sessionTimeStart', sessionTimeStart);
        Cookies.set('sessionTimeStart', sessionTimeStart);
        let user_id = Cookies.get('user_id');

        http(
          {
            url: `bravo_user/${user_id}`,
            method: 'GET',
          },
          { ...this.getAPICORSheaders(bearer, token) }
        ).then((res) => {
          if (res.data && res.status === 200) {
            // localStorage.setItem('user_id', res.data.authorization.id);
            const { setUserDetails } = this.props;
            setUserDetails(res.data);
            this.setState({
              userDetails: res.data,
            });

            // this.getNotifications(
            //   res.data.authorization.id,
            //   // localStorage.getItem('dataStoreToken')
            //   Cookies.get('dataStoreToken')
            // );
            // this.getUserDetails(
            //   res.data.authorization.id,
            //   // localStorage.getItem('dataStoreToken')
            //   Cookies.get('dataStoreToken')
            // );
            // localStorage.setItem('isUserLogin', '1');
            Cookies.set('isUserLogin', '1');
          } else {
            redirectLogic();
          }
        });
      });
      //}
    } else if (!code) {
      // let token = localStorage.getItem('dataStoreToken');
      let token = Cookies.get('dataStoreToken');

      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      this.getUserDetails(id, token);
    } else {
      redirectLogic();
    }

    //this.getUserDetails();
  }
  componentDidUpdate(prop) {
    if (window.location.pathname !== prop.location.pathname) {
      this.props.setHasRouteChanged(true);
    } else {
      this.props.setHasRouteChanged(false);
    }
  }

  getNotifications = (userID, Btoken) => {
    const id = userID;
    const token = Btoken;

    http(
      {
        url: `managed/user/${id}?_fields=_notifications/*`,
        method: 'GET',
      },
      {
        Authorization: `Bearer ${token}`,
      }
    ).then((res) => {
      const notifications = res.data._notifications;
      let sortedNotifications = _.orderBy(
        notifications,
        ['createDate'],
        ['desc']
      );
      if (notifications) {
        this.setState({
          notificationsArray: sortedNotifications,
          notificationAlerts: sortedNotifications.length,
        });
      }
    });
  };

  render() {
    const {
      match: {
        params: { pageName },
      },
      setUserDetails,
    } = this.props;
    const {
      userDetails,
      notificationAlerts,
      notificationsArray,
      admin,
    } = this.state;
    return (
      <>
        {pageName !== 'SocialLogin' && (
          <>
            <MainMenu
              pageName={pageName || ''}
              notificationAlerts={notificationAlerts}
              admin={admin}
            />
            <div className="dashboard">
              {!pageName ||
                (pageName === 'Dashboard' && (
                  <Home
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    getNotifications={this.getNotifications}
                  />
                ))}
              {!pageName ||
                (pageName === 'Apps' && (
                  <Apps
                    getNotifications={this.getNotifications}
                    getUserDetails={this.getUserDetails}
                    userDetails={userDetails}
                  />
                ))}

              {!pageName ||
                (pageName === 'Admin' && (
                  <Admin
                    getNotifications={this.getNotifications}
                    userDetails={userDetails}
                    getUserDetails={this.getUserDetails}
                  />
                ))}
              {!pageName ||
                (pageName === 'IDs' && (
                  <IDs getNotifications={this.getNotifications} />
                ))}
              {!pageName ||
                (pageName === 'Wallet' && (
                  <Wallet getNotifications={this.getNotifications} />
                ))}
              {!pageName ||
                (pageName === 'Notifications' && (
                  <Notifications
                    notificationsArray={notificationsArray}
                    getNotifications={this.getNotifications}
                  />
                ))}
              {!pageName ||
                ((pageName === 'personalInformation' ||
                  pageName === 'Account' ||
                  pageName === 'changePassword' ||
                  pageName === 'CommunicationPreferences' ||
                  pageName === 'AuthorizedApps' ||
                  pageName === 'PeopleSharing' ||
                  pageName === 'PrivacyConsent' ||
                  pageName === 'ActivityLog' ||
                  pageName === 'AccountManagement' ||
                  pageName === 'SiteSettings' ||
                  pageName === 'Devices') && (
                  <Account
                    pageName={pageName || ''}
                    setUserDetails={setUserDetails}
                    userDetails={userDetails}
                    getNotifications={this.getNotifications}
                  />
                ))}
            </div>
          </>
        )}
      </>
    );
  }
}
Dashboard.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  setHasRouteChanged: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withTranslation()(Dashboard);
