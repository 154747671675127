import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid, getNestedObject } from 'utils/common cognito';
import 'app/modules/Dashboard Cognito/ChangePasswordPopup.scss';
import http from 'api cognito/http-request';
import WithError from '../Shared/WithError Cognito/WithError';
import Cookies from 'js-cookie';

class ChangePasswordPopup extends Component {
  constructor(props) {
    super(props);
    this.changePasswordPopupContainer = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.currentPasswordInputRef = React.createRef();
    this.newPasswordInputRef = React.createRef();
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      errorText: '',
      errorArray: [],
    };
  }

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { currentPassword, newPassword, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'currentPasswordError':
        if (value.length <= 0) {
          errorMsg = 'common.errors.noCurrentPasswordError';
        }
        break;
      case 'newPasswordError':
        if (value.length <= 0) {
          errorMsg = 'common.errors.noNewPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'common.errors.invalidFormat';
        } else if (currentPassword === confirmPassword) {
          errorMsg = 'common.errors.currentNewPasswordSameError';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'common.errors.noConfirmPasswordError';
        } else if (newPassword !== confirmPassword) {
          errorMsg = 'common.errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  onChangePassword = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword } = this.state;
    const { onChangePasswordHandler } = this.props;

    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
          PreviousPassword: currentPassword,
          ProposedPassword: newPassword,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.ChangePassword',
      }
    ).then((res) => {
      console.log(JSON.stringify(res.data));
      if (res.status === 200 && res.data) {
        onChangePasswordHandler('success');
      }
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div
          className="change-password-popup"
          ref={this.changePasswordPopupContainer}
        >
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {t('pages.changePassword.changePassword')}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <Form.Group controlId="currentPassword">
                    <Form.Label>
                      {t('pages.changePassword.currentPassword')}
                    </Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState({
                          currentPassword: value,
                        });
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'currentPasswordError');
                      }}
                      password={currentPassword}
                      className={
                        currentPasswordError ? 'error-input' : undefined
                      }
                      autoFocus
                      aria-invalid="false"
                      aria-required="true"
                      myRef={this.currentPasswordInputRef}
                    />
                  </Form.Group>
                  <Form.Group controlId="newPassword">
                    <Form.Label>
                      {t('pages.changePassword.newPassword')}
                    </Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState({
                          newPassword: value,
                        });
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'newPasswordError');
                      }}
                      password={newPassword}
                      className={newPasswordError ? 'error-input' : undefined}
                      ariaInvalid={!!newPasswordError}
                      aria-required="true"
                      myRef={this.newPasswordInputRef}
                    />
                  </Form.Group>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>
                      {t('pages.changePassword.confirmPassword')}
                    </Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState(
                          {
                            confirmPassword: value,
                          },
                          () => {
                            this.isFormFieldValid(
                              value,
                              'confirmPasswordError'
                            );
                          }
                        );
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'confirmPasswordError');
                      }}
                      password={confirmPassword}
                      className={
                        confirmPasswordError ? 'error-input' : undefined
                      }
                      ariaInvalid={!!confirmPasswordError}
                      aria-required="true"
                      myRef={this.confirmPasswordInputRef}
                    />
                  </Form.Group>
                  <PasswordRequirements
                    password={newPassword}
                    formSubmitted={false}
                  />
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.onChangePassword(e)}
                  >
                    {t('pages.changePassword.changePassword')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ChangePasswordPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangePasswordHandler: PropTypes.func.isRequired,
};
export default withTranslation()(ChangePasswordPopup);
