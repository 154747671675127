import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid, getNestedObject } from 'utils/common';
import 'app/modules/Dashboard/ChangeSecurityQuestionsPopup.scss';
import http from 'api/http-request';
import WithError from '../Shared/WithError/WithError';
import Select from 'react-select';
import { counter } from '@fortawesome/fontawesome-svg-core';
import Cookies from 'js-cookie';
import InputGroup from 'react-bootstrap/InputGroup';

class ChangeSecurityQuestionsPopup extends Component {
  constructor(props) {
    super(props);
    this.changePasswordPopupContainer = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.currentPasswordInputRef = React.createRef();
    this.newPasswordInputRef = React.createRef();
    this.state = {
      questionVals: [null, null, null],
      filterOptions: [],
      errorText: '',
      errorArray: [],
      authID: '',
      showDropDown: true,
      showDropDownTwo: true,
      showDropDownThree: true,
      customQuestion: '',
      securityQuestions: ['', '', ''],
      currentOption: null,
      currentIndex: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // let token = localStorage.getItem('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    // let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
    if (id) {
      this.getSecurityQuestions(id);
    }
  }

  // getSecurityQuestions = () =>{
  //   const questions = [
  //     { value: 'What is your pet name?', label: 'What is your pet name?' },
  //     { value: "What's your favorite color?", label: "What's your favorite color?" },
  //     { value: 'Who was your first employer?', label: 'Who was your first employer?' },
  //   ];
  //   this.setState({
  //     filterOptions: questions,
  //   });
  // }

  updateSecurity = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.changePassword.resetSecurity'
        : 'pages.changePassword.resetSecurity';
    this.setState(
      {
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };

  getAvailableOptions = () => {
    const availableOptionsLeft = this.state.filterOptions;
    let optionsAvailable = availableOptionsLeft.filter((questionOption) => {
      return this.state.questionVals.indexOf(questionOption) === -1;
    });
    if (!this.state.questionVals.includes('Custom')) {
      optionsAvailable = [
        ...optionsAvailable,
        { label: 'Custom Question', value: 'Custom' },
      ];
      return optionsAvailable;
    } else {
      return optionsAvailable;
    }
  };

  mapQuestionDictToArray = (questionDict) => {
    // let preferredLanguage = localStorage.getItem('ctsessionlanguage');
    let preferredLanguage = Cookies.get('ctsessionlanguage');
    if (preferredLanguage === 'en_US') {
      preferredLanguage = 'en';
    }
    let filterOptions = Object.keys(questionDict).map(function (k) {
      let dict = {};

      dict['value'] = k;

      dict['label'] = questionDict[k];
      dict['translations'] = questionDict[k];
      return dict;
    });

    return filterOptions;
  };

  getSecurityQuestions = (id) => {
    this.setState({
      userId: id,
    });

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    http({
      url: 'updateSecurityQuestions',
      method: 'POST',
      data: {
        Email: Cookies.get('mail'),
        iPlanetDirectoryPro: iPlanetDirectoryPro,
      },
    }).then((res) => {
      if (res.data && res.status === 200 && res.data.iamcode === "IAM000") {
        const questions = res.data.data.callbacks[0].output[1].value;
        // questions.push("Custom");
        let filterOptionsFromServer = this.mapQuestionDictToArray(questions);

        this.setState({
          authID: res.data.data.authId,
          //filterOptions: Object.values(res.data.questions),
          filterOptions: filterOptionsFromServer,
        });
      }
      else if (res.status === 401) {
        this.showError('pages.idproofing.sessionExpiry');
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }
    });
    // .then(() => {
    //   this.getUserSelectedQuestions();
    // });
    
  };

  // getUserSelectedQuestions = (id, token) => {
  //   this.setState({
  //     userId: id,
  //     token: token,
  //   });
  //   let bearer = 'Bearer ';

  //   http(
  //     {
  //       url: `managed/user/${id}?&_fields=kbaInfo`,
  //       method: 'GET',
  //     },
  //     {
  //       Authorization: `${bearer}${token}`,
  //     }
  //   ).then((res) => {
  //     if (res.data) {
  //       let questionList = res.data.kbaInfo;

  //       let index_counter = 0;
  //       for (let i = 0; i < questionList.length; i++) {
  //         for (let j = 0; j < this.state.filterOptions.length; j++) {
  //           if (
  //             questionList[i].questionId == this.state.filterOptions[j].value
  //           ) {
  //             this.handleQuestionValChange(
  //               this.state.filterOptions[j],
  //               index_counter
  //             );
  //           }
  //         }
  //         index_counter++;
  //       }
  //     }
  //   });
  // };

  handleQuestionValChange = (option, index) => {
    const newQuestionVals = this.state.questionVals;

    if (option.value === 'Custom' && index === 0) {
      this.setState({
        showDropDown: false,
        answerOne: '',
      });
    } else if (option.value === 'Custom' && index === 1) {
      this.setState({
        showDropDownTwo: false,
        answerTwo: '',
      });
    } else if (option.value === 'Custom' && index === 2) {
      this.setState({
        showDropDownThree: false,
        answerThree: '',
      });
    }
    if (option.value != 'Custom') {
      newQuestionVals[index] = option;
      this.setState(() => {
        return {
          questionVals: newQuestionVals,
        };
      });
    } else {
      this.setState({
        currentOption: option,
        currentIndex: index,
      });
    }
  };

  setCustomQuestion = (e) => {
    e.preventDefault();
    const { currentIndex } = this.state;
    const newQuestionVals = this.state.questionVals;
    let customOption = this.state.currentOption;
    customOption = {
      ...customOption,
      label: this.state.customQuestion,
      value: currentIndex,
      translations: this.state.customQuestion,
    };
    newQuestionVals[currentIndex] = customOption;
    this.setState(() => {
      return {
        questionVals: newQuestionVals,
      };
    });
  };

  onChangeQuestion = (e, i) => {
    e.preventDefault();
    if (i === 0) {
      this.setState({
        showDropDown: true,
        answerOne: '',
      });
    } else if (i === 1) {
      this.setState({
        showDropDownTwo: true,
        answerTwo: '',
      });
    } else if (i === 2) {
      this.setState({
        showDropDownThree: true,
        answerThree: '',
      });
    }
  };

  onVerify = (e) => {
    e.preventDefault();
    const {
      answerOne,
      answerTwo,
      answerThree,
      questionVals,
      authID,
    } = this.state;
    const { updateSecurity } = this.props;

    let userId = Cookies.get('user_id');
    // let userId = Cookies.get('user_id');
    // let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    // let token = Cookies.get('dataStoreToken');
    //  let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    // if (!answerOne || !answerTwo || !answerThree) {
    //   let failedPolicyRequirements = [
    //     { policyRequirements: ['answers'], property: 'securityQuestions' },
    //   ];
    //   this.setState({
    //     errorArray: failedPolicyRequirements,
    //   });
    //   this.changePasswordPopupContainer.current.scrollTo({
    //     behavior: 'smooth',
    //     top: 0,
    //   });
    //   return;
    // }
    if (
      answerOne === answerTwo ||
      answerOne === answerThree ||
      answerTwo === answerThree
    ) {
      this.showError('errors.duplicateEntriesBoth');
    } else if (
      questionVals[0].label === questionVals[1].label ||
      questionVals[0].label === questionVals[2].label ||
      questionVals[1].label === questionVals[2].label
    ) {
      this.showError('errors.duplicateEntriesBoth');
    } else {
      http({
        url: `updateSecurityQuestions`,
        method: 'POST',
        data: {
          Question1: questionVals[0].label,
          Answer1: answerOne,
          Question2: questionVals[1].label,
          Answer2: answerTwo,
          Question3: questionVals[2].label,
          Answer3: answerThree,
          userId: userId,
          authId: authID,
        },
      }).then((res) => {
        if (res.status === 200 && res.data.data.successUrl === '/am/console') {
          updateSecurity('success');
          this.setState({
            errorArray: '',
          });
        }
        else if (res.status === 401) {
          this.showError('pages.idproofing.sessionExpiry');
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
        }
        this.props.onClose();
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
  };

  onFirstAndLastNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z '`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { mail, confirmEmail, password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'answerOneError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerOneError';
        }
        break;
      case 'answerTwoError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerTwoError';
        }
        break;
      case 'answerThreeError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerThreeError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  /*________________________________________________________________*/

  render() {
    const { onClose, t } = this.props;
    const {
      answerOne,
      answerTwo,
      showDropDown,
      showDropDownTwo,
      showDropDownThree,
      customQuestion,
      answerThree,
      answerOneError,
      answerTwoError,
      answerThreeError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div
          className="change-password-popup"
          ref={this.changePasswordPopupContainer}
          role="main"
        >
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}

                  <div className="change-password-popup__popup-body-header">
                    <h1 id="password-modal">
                      {t('pages.changePassword.SetQuestionPopup')}
                    </h1>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <div className="form-group">
                    <Form>
                      <label>
                        {t('pages.changePassword.singleQuestion')} 1
                      </label>
                      {showDropDown && (
                        <Select
                          name="filters"
                          placeholder={
                            t('pages.changePassword.singleQuestion') + ' 1'
                          }
                          value={this.state.questionVals[0]}
                          options={this.getAvailableOptions()}
                          onChange={(e) => {
                            this.handleQuestionValChange(e, 0);
                          }}
                          className="select-box"
                          aria-label="list"
                        />
                      )}
                      {!showDropDown && (
                        <InputGroup className="question-input">
                          <Form.Control
                            placeholder="Please enter a custom question"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              this.setState({
                                customQuestion: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              this.setCustomQuestion(e);
                            }}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={(e) => this.onChangeQuestion(e, 0)}
                          >
                            x
                          </Button>
                        </InputGroup>
                      )}
                      <br />
                      <Form.Group controlId="answerOne">
                        <Form.Control
                          onChange={(e) => {
                            this.onFirstAndLastNameChange(e, 'answerOne');
                          }}
                          value={answerOne}
                          placeholder={
                            !showDropDown
                              ? 'Please enter a custom answer'
                              : 'Answer 1'
                          }
                          maxLength="40"
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'answerOneError');
                          }}
                          className={`answer-input ${
                            answerOneError ? 'error-input' : undefined
                          }`}
                          aria-required
                          aria-invalid="false"
                          aria-label="answer1"
                        />
                        <p className="error-text" id="firstname-error1">
                          {answerOneError && t(answerOneError)}
                        </p>
                      </Form.Group>
                      <label>
                        {t('pages.changePassword.singleQuestion')} 2
                      </label>
                      {showDropDownTwo && (
                        <Select
                          name="filters"
                          placeholder={
                            t('pages.changePassword.singleQuestion') + ' 2'
                          }
                          value={this.state.questionVals[1]}
                          options={this.getAvailableOptions()}
                          onChange={(e) => {
                            this.handleQuestionValChange(e, 1);
                          }}
                          className="select-box"
                          aria-label="list"
                        />
                      )}
                      {!showDropDownTwo && (
                        <InputGroup className="question-input">
                          <Form.Control
                            placeholder="Please enter a custom question"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              this.setState({
                                customQuestion: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              this.setCustomQuestion(e);
                            }}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={(e) => this.onChangeQuestion(e, 1)}
                          >
                            x
                          </Button>
                        </InputGroup>
                      )}
                      <br />
                      <Form.Group controlId="answerTwo">
                        <Form.Control
                          onChange={(e) => {
                            this.onFirstAndLastNameChange(e, 'answerTwo');
                          }}
                          value={answerTwo}
                          placeholder={
                            !showDropDownTwo
                              ? 'Please enter a custom answer'
                              : 'Answer 2'
                          }
                          maxLength="40"
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'answerTwoError');
                          }}
                          className={`answer-input ${
                            answerTwoError ? 'error-input' : undefined
                          }`}
                          aria-required
                          aria-invalid="false"
                          aria-label="answer2"
                        />
                        <p className="error-text" id="firstname-error2">
                          {answerTwoError && t(answerTwoError)}
                        </p>
                      </Form.Group>
                      <label>
                        {t('pages.changePassword.singleQuestion')} 3
                      </label>
                      {showDropDownThree && (
                        <Select
                          name="filters"
                          placeholder={
                            t('pages.changePassword.singleQuestion') + ' 3'
                          }
                          value={this.state.questionVals[2]}
                          options={this.getAvailableOptions()}
                          onChange={(e) => {
                            this.handleQuestionValChange(e, 2);
                          }}
                          className="select-box"
                          aria-label="list"
                        />
                      )}
                      {!showDropDownThree && (
                        <InputGroup className="question-input">
                          <Form.Control
                            placeholder="Please enter a custom question"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              this.setState({
                                customQuestion: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              this.setCustomQuestion(e);
                            }}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={(e) => this.onChangeQuestion(e, 2)}
                          >
                            x
                          </Button>
                        </InputGroup>
                      )}
                      <br />
                      <Form.Group controlId="answerThree">
                        <Form.Control
                          onChange={(e) => {
                            this.onFirstAndLastNameChange(e, 'answerThree');
                          }}
                          value={answerThree}
                          placeholder={
                            !showDropDownThree
                              ? 'Please enter a custom answer'
                              : 'Answer 3'
                          }
                          maxLength="40"
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'answerThreeError');
                          }}
                          className={`answer-input ${
                            answerThreeError ? 'error-input' : undefined
                          }`}
                          aria-required
                          aria-invalid="false"
                          aria-label="answer3"
                        />
                        <p className="error-text" id="firstname-error3">
                          {answerThreeError && t(answerThreeError)}
                        </p>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.onVerify(e)}
                  >
                    {t('pages.changePassword.save')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ChangeSecurityQuestionsPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateSecurity: PropTypes.func.isRequired,
  email: PropTypes.string,
};
export default withTranslation()(ChangeSecurityQuestionsPopup);
