import React, { Component } from 'react';
import { Tab, Tabs, Breadcrumb } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { faSearch, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import 'app/modules/Dashboard/Dashboard.scss';
import 'app/modules/Dashboard/Apps.scss';
import PropTypes from 'prop-types';
import http from 'api/http-request';
import 'app/modules/Dashboard/Admin.scss';
import Cookies from 'js-cookie';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';

class Apps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminApps: [],
      appDetails: {},
      searchString: '',
    };
  }
  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };
  componentDidMount() {
    const { getUserDetails } = this.props;

    this.scrollToTop();
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
    if (token || id) {
      getUserDetails(id, token);
    }
    this.getAdminApps();
  }

  getAdminApps = () => {
    let bearer = 'Bearer ';
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `endpoint/getAllApp`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      this.setState({
        adminApps: res.data.result,
      });
    });
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  setFilerKeyword = (e) => {
    let searchString = e.target.value;
    this.setState({ searchString: searchString });
  };

  redirectToApp = (appLink) => {
    if (appLink.indexOf('https://') === 0 || appLink.indexOf('http://') === 0) {
      window.open(appLink, '_blank');
    } else {
      window.open('https://' + appLink, '_blank');
    }
  };
  render() {
    const { t } = this.props;
    const { adminApps, searchString } = this.state;

    return (
      <>
        <div>
          <AlertGlobal useTicker={false}></AlertGlobal>
        </div>
        <div className="admin-apps apps-tab">
          <div className="tab-area pb-5">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                {t('pages.BreadCrumb.home')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('pages.BreadCrumb.applications')}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>...</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs
              defaultActiveKey="My-Apps"
              transition={false}
              className="ml-3"
            >
              <Tab
                eventKey="My-Apps"
                title={t('pages.Apps.tabMyApps')}
                tabClassName="px-3"
              >
                <div className="m-5">
                  <div className="row mb-5 align-items-center">
                    <div className="col-md-5 mb-3 mb-sm-0">
                      <h1>{t('pages.Admin.searchBarMyAppsPlaceholder')}</h1>
                      <div className="input-group rounded">
                        <input
                          type="search"
                          className="form-control rounded"
                          onChange={this.setFilerKeyword}
                          placeholder={t(
                            'pages.Admin.searchBarMyAppsPlaceholder'
                          )}
                          aria-label={t('pages.Apps.searchBarAriaLabel')}
                          aria-describedby="search-addon"
                        />
                        <span
                          className="input-group-text border-0"
                          id="search-addon"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0 mb-5">
                    {adminApps?.length > 0 ? (
                      adminApps
                        .filter((item) =>
                          item.appname
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        )
                        .map((device, index) => (
                          <div
                            key={index}
                            className="col-sm-6 col-md-3 col-lg-2 mx-0 mx-sm-4 my-4 app-box-item box-shadow text-center mt-3 app-content"
                            onClick={() => this.redirectToApp(device.applink)}
                            tabIndex="0"
                          >
                            {device.photo ? (
                              <img
                                className="apps-img"
                                src={`data:image/png;base64,${device.photo}`}
                                alt=""
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTabletAlt}
                                className="inside-button app-icon"
                                role="img"
                              />
                            )}
                            <div className="device-name">{device.appname}</div>
                          </div>
                        ))
                    ) : (
                      <div className="no-devices mb-3">
                        {t('pages.Admin.noApps')}
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

Apps.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
  getUserDetails: PropTypes.func.isRequired,
};
export default withTranslation()(Apps);
