import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'app/modules/Registration/Registration.scss';
import Form from 'react-bootstrap/Form';
import { Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import {
  isPasswordFormatValid,
  preventNonDigitCharacters,
  formatPhoneNumber,
} from 'utils/common';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import WithError from 'app/modules/Shared/WithError Cognito/WithError';
import 'app/modules/Shared/WithError Cognito/WithError.scss';
import Select from 'react-select';
import http from 'api/http-request';
import { getNestedObject } from 'utils/common';
import Alert from 'app/modules/Shared/Alert/Alert';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import Cookies from 'js-cookie';
import validator from 'validator';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { aws_request } from 'api cognito/aws-request';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.verificationInputRef = React.createRef();
    this.telephoneNumberInputRef = React.createRef();
    this.state = {
      password: '',
      confirmPassword: '',
      screen: 0,
      verificationPlaceholder: ['0', '0', '0', '0', '0', '0', '0', '0'],
      currentStep: 0,
      stepNames: [
        'pages.formNavigator.personalInfo',
        'pages.formNavigator.securityQuestion',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification',
      ],
      maxStep: 2,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      givenName: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      sn: '',
      userName: '',
      mail: '',
      confirmEmail: '',
      confirmPasswordError: '',
      passwordError: '',
      formSubmitted: false,
      errorText: '',
      errorArray: [],
      questionVals: [null, null, null],
      token: '',
      telephoneNumber: '',
      telephoneNumberUSFormatted: '',
      numberFocus: false,
      filterOptions: [
        {
          value: '1',
          label: 'Whats your favorite color?',
        },
        {
          value: '2',
          label: 'Who was your first employer?',
        },
        {
          value: '3',
          label: 'What is your favorite vacation place?',
        },
        {
          value: '4',
          label: 'In which city were you born in?',
        },
        {
          value: '5',
          label: 'Who was the make of your first car?',
        },
        {
          value: '6',
          label: 'What is your favorite team?',
        },
        {
          value: '7',
          label: 'What was your favorite toy as a child?',
        },
        {
          value: '8',
          label: 'What is your mother maiden name?',
        },
      ],
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      telephoneNumberFormatted: '',
      preferredLanguage: 'en',
    };
  }

  componentDidMount() {
    this.scrollToTop();
    this.getToken();
  }

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  captchaSubmit = () => {
    const {
      givenName,
      sn,
      userName,
      mail,
      password,
      telephoneNumber,
      preferredLanguage,
      googleCaptchaResponse,
      confirmPassword,
      confirmEmail,
    } = this.state;

    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
          Password: password,
          Username: userName,
          ValidationData: [
            {
              Name: 'captcha',
              Value: googleCaptchaResponse,
            },
          ],
          UserAttributes: [
            {
              Name: 'custom:First_Name',
              Value: givenName,
            },
            {
              Name: 'custom:Last_Name',
              Value: sn,
            },
            {
              Name: 'email',
              Value: mail,
            },
            {
              Name: 'custom:Confirm_Email',
              Value: confirmEmail,
            },
            {
              Name: 'custom:Confirm_Password',
              Value: confirmPassword,
            },
            {
              Name: 'phone_number',
              Value: telephoneNumber ? `+1${telephoneNumber}` : '',
            },
          ],
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp',
      }
    ).then((res) => {
      if (res.data && res.data.CodeDeliveryDetails) {
        this.setState({
          screen: 2,
          verificationOption: res.data.CodeDeliveryDetails.Destination,
          showGoogleCaptcha: false,
        });
        this.increaseCurrentStepHandler();
      } else if (res.data.__type === 'UsernameExistsException') {
        this.showError('errors.invalidUserNameDesc');
        this.setState({
          screen: 0,
          currentStep: 0,
          userName: '',
        });
      }
    });
  };

  getToken = () => {
    http(
      {
        url: 'selfservice/registration?_action=submitRequirements',
        data: { input: { input: {} } },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (
        res.status === 200 &&
        res.data &&
        res.data.tag === 'initial' &&
        res.data.type === 'allInOneRegistration'
      ) {
        this.setState({
          token: res.data.token,
        });
      }
    });
  };

  checkUsername = () => {
    const {
      givenName,
      sn,
      userName,
      mail,
      password,
      telephoneNumber,
      preferredLanguage,
    } = this.state;

    this.setState({
      errorText: '',
    });

    var setCustomState = (dict) => {
      this.setState(dict);
    };

    var error_list = [];
    var aws = require('aws-sdk');
    aws.config.update({
      region: 'us-east-1',

      accessKeyId: `${process.env.REACT_APP_COGNITO_AccessKey}`, // should be:  process.env.AWS_ACCESS_ID
      secretAccessKey: `${process.env.REACT_APP_COGNITO_SecretKey}`,
    });

    var cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider();
    var params = {
      AttributesToGet: ['email'],
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}`,
      Filter: `email="${mail}"`,
    };
    cognitoidentityserviceprovider.listUsers(params, function (err, data) {
      if (err) {
        setCustomState({ errorText: err.stack });
      }
      // an error occurred
      else {
        if (data.Users.length > 0) {
          error_list.push({ property: 'mail' });

          setCustomState({ errorArray: error_list });
        } else {
          http(
            {
              url: '/amazonaws',
              data: {
                ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
                AuthFlow: 'USER_PASSWORD_AUTH',
                AuthParameters: {
                  USERNAME: userName,
                  PASSWORD: password,
                },
              },
              method: 'POST',
            },
            {
              'Content-Type': 'application/x-amz-json-1.1',
              'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            }
          ).then((res) => {
            if (
              res.data.__type == 'UsernameExistsException' ||
              (res.data.AuthenticationResult &&
                res.data.AuthenticationResult.AccessToken)
            ) {
              this.showError('errors.invalidUserNameDesc');
            } else if (res.data.__type == 'NotAuthorizedException') {
              //this.increaseCurrentStepHandler();

              setCustomState({
                screen: 1,
                errorArray: '',
                showGoogleCaptcha: true,
                currentStep: 1,
              });
            } else if ('ChallengeName' in res.data) {
              setCustomState({
                screen: 1,
                errorArray: '',
                showGoogleCaptcha: true,
                currentStep: 1,
              });
            }
          });
        }
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      formSubmitted: true,
    });
    const {
      givenName,
      sn,
      userName,
      mail,
      confirmEmail,
      password,
      confirmPassword,
      telephoneNumber,
    } = this.state;
    const hasGivenName = this.isFormFieldValid(givenName, 'givenNameError');
    const hasSn = this.isFormFieldValid(sn, 'snError');
    const hasUserName = this.isFormFieldValid(userName, 'userNameError');
    const hasMail = this.isFormFieldValid(mail, 'emailError');
    const hasConfirmMail = this.isFormFieldValid(
      confirmEmail,
      'confirmEmailError'
    );
    const hasPassword = this.isFormFieldValid(password, 'passwordError');
    const hasConfirmPassword = this.isFormFieldValid(
      confirmPassword,
      'confirmPasswordError'
    );

    const hasTelephoneNumber = this.isFormFieldValid(
      telephoneNumber,
      'telephoneNumberError'
    );

    if (
      hasGivenName ||
      hasSn ||
      hasUserName ||
      hasMail ||
      hasConfirmMail ||
      hasPassword ||
      hasConfirmPassword ||
      hasTelephoneNumber
    ) {
      this.showError('errors.required');
    } else {
      if (telephoneNumber !== '') {
        const currentValue = this.state.telephoneNumber;
        this.setState((prevState) => ({
          ...prevState,
          telephoneNumber: formatPhoneNumber(
            currentValue,
            prevState.telephoneNumber
          ).replace(/[^A-Z0-9]/gi, ''),
        }));
        this.checkUsername();
      } else if (telephoneNumber === '') {
        this.checkUsername();
      } else {
        this.setState({
          telephoneNumberError: 'pages.registration.mobileNumberInvalid',
        });
        this.showError('pages.registration.mobileNumberInvalid');
      }
    }
  };

  increaseCurrentStepHandler = () => {
    this.scrollToTop();
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
      this.scrollToTop();
    }
  };

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    const { verificationCode, userName } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: '/amazonaws',
          data: {
            ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
            ConfirmationCode: verificationCode,
            Username: userName,
          },
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmSignUp',
        }
      ).then((res) => {
        ReactGA.event({
          category: 'Registeration',
          action: 'Success: Registeration Verification',
        });
        this.setMFASettings(userName);
        this.setState({
          screen: 3,
        });
      });
    }
  };
  setMFASettings = (userName) => {
    var params = {
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
      Username: userName /* required */,
      SMSMfaSettings: {
        Enabled: true,
        PreferredMfa: true,
      },
      SoftwareTokenMfaSettings: {
        Enabled: false,
        PreferredMfa: false,
      },
    };
    let cognitoidentityserviceprovider = aws_request();
    cognitoidentityserviceprovider.adminSetUserMFAPreference(
      params,
      function (err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
      }
    );
  };
  disableCopyPaste = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    const { userName } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,

          Username: userName,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.ResendConfirmationCode',
      }
    ).then((res) => {
      if (res.status === 400) {
        this.setState({
          screen: 0,
          currentStep: 0,
          password: '',
          confirmPassword: '',
          googleCaptchaResponse: '',
          captchaReset: false,
          givenName: '',
          answerOne: '',
          answerTwo: '',
          answerThree: '',
          sn: '',
          userName: '',
          mail: '',
          confirmEmail: '',
          confirmPasswordError: '',
          passwordError: '',
          formSubmitted: false,
          errorText: '',
          errorArray: [],
          token: '',
          telephoneNumber: '',
          telephoneNumberUSFormatted: '',
          verificationCode: '',
          verificationOption: '',
          verificationCodeError: '',
          questionVals: [null, null, null],
        });
      }
    });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { mail, confirmEmail, password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'telephoneNumberError':
        if (!validator.isMobilePhone(value)) {
          errorMsg = 'errors.telephoneNumberError';
        }
        break;
      case 'givenNameError':
        if (value.length <= 0) {
          errorMsg = 'errors.firstNameError';
        }
        break;
      case 'answerOneError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerOneError';
        }
        break;
      case 'answerTwoError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerTwoError';
        }
        break;
      case 'answerThreeError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerThreeError';
        }
        break;
      case 'snError':
        if (value.length <= 0) {
          errorMsg = 'errors.lastNameError';
        }
        break;
      case 'userNameError':
        if (value.length < 5 || value.length > 30) {
          errorMsg = 'errors.userNameLength';
        } else if (
          !value.match(/([a-zA-Z])*/) &&
          value.length >= 5 &&
          value.length <= 30
        ) {
          errorMsg = 'errors.userNameAtleastAlphabet';
        }
        break;
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      case 'confirmEmailError':
        if (value.length <= 0) {
          errorMsg = 'errors.confirmEmailError';
        } else if (mail !== confirmEmail) {
          errorMsg = 'errors.mailsNotMatchError';
        }
        break;
      case 'passwordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (password !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  decreaseCurrentStepHandler = () => {
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };

  sendToLogin = (e) => {
    e.preventDefault();
    return this.props.history.push('/');
  };

  onCaptchaChange = (value) => {
    if (value) {
      this.setState(
        {
          googleCaptchaResponse: value,
        },
        () => {
          this.captchaSubmit();
        }
      );
    } else {
      this.setState({
        googleCaptchaResponse: '',
      });
    }
  };

  onFirstAndLastNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z '`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  onUserNameChange = (e, key) => {
    const value = e.target.value.trim();
    if (!value.match(/[\\+/""&@'*%;, ]/g)) {
      this.setState({
        [key]: value.toLowerCase(),
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    this.scrollToTop();
  };

  onMobileNumberChange = (e) => {
    const currentValue = e.target.value.trim();
    this.setState((prevState) => ({
      ...prevState,
      telephoneNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
    }));
  };

  handleKeyPress = (e) => {
    preventNonDigitCharacters(e);
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 0,
      currentStep: 0,
      password: '',
      confirmPassword: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      givenName: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      sn: '',
      userName: '',
      mail: '',
      confirmEmail: '',
      confirmPasswordError: '',
      passwordError: '',
      formSubmitted: false,
      errorText: '',
      errorArray: [],
      token: '',
      telephoneNumber: '',
      telephoneNumberUSFormatted: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      questionVals: [null, null, null],
    });
    this.getToken();
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  onChange = (value) => {};

  render() {
    const {
      currentStep,
      maxStep,
      stepNames,
      screen,
      givenName,
      givenNameError,
      sn,
      snError,
      userName,
      userNameError,
      mail,
      emailError,
      confirmEmail,
      confirmEmailError,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
      formSubmitted,
      errorText,
      answerOne,
      answerTwo,
      answerThree,
      answerOneError,
      answerTwoError,
      answerThreeError,
      telephoneNumber,
      telephoneNumberError,
      numberFocus,
      verificationOption,
      verificationCodeError,
      errorArray,
      verificationCode,
      alertText,
      alertStatus,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="registration registration-form">
          {(errorText || errorArray.length > 0) && (
            <WithError errors={errorText} errorArray={errorArray} />
          )}
          <div className="col">
            <h1>{t('pages.registration.title')}</h1>
          </div>
          <FormNavigationBar
            currentStep={currentStep}
            numFormSteps={maxStep + 1}
            stepNames={stepNames}
            t={t}
          />
          {currentStep === 0 && screen === 0 && (
            <div className="col-md-12 registration__container">
              <p>
                <span className="pr-2 red bold">*</span>
                {t('pages.registration.requiredFields')}
              </p>
              <Form>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="firstName">
                      <Form.Label>
                        {t('pages.registration.firstName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.onFirstAndLastNameChange(e, 'givenName');
                        }}
                        value={givenName}
                        maxLength="40"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'givenNameError');
                        }}
                        className={givenNameError ? 'error-input' : undefined}
                        aria-required="true"
                        aria-invalid={!!givenNameError}
                        aria-describedby="firstname-error"
                      />
                      <p className="error-text" id="firstname-error">
                        {givenNameError && t(givenNameError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pl-2" lg={{ span: 6 }}>
                    <Form.Group controlId="lastName">
                      <Form.Label>
                        {t('pages.registration.lastName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.onFirstAndLastNameChange(e, 'sn');
                        }}
                        value={sn}
                        maxLength="80"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'snError');
                        }}
                        className={snError ? 'error-input' : undefined}
                        aria-required
                        aria-invalid={!!snError}
                        aria-describedby="lastname-error"
                      />
                      <p className="error-text" id="lastname-error">
                        {snError && t(snError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="userName">
                      <Form.Label>
                        {t('pages.registration.userName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.onUserNameChange(e, 'userName');
                        }}
                        value={userName}
                        maxLength="30"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'userNameError');
                        }}
                        className={userNameError ? 'error-input' : undefined}
                        aria-required="true"
                        autoComplete="off"
                        aria-invalid={!!userNameError}
                        aria-describedby="username-error"
                      />
                      <p className="error-text" id="username-error">
                        {userNameError && t(userNameError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="telephoneNumber">
                      <Form.Label>
                        {t('pages.registration.telephoneNumber')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        ref={this.telephoneNumberInputRef}
                        onKeyDown={this.handleKeyPress}
                        placeholder={!numberFocus ? '(###) ###-####' : ''}
                        onFocus={() => {
                          this.setState({
                            numberFocus: true,
                          });
                        }}
                        onBlur={(e) => {
                          this.setState({
                            numberFocus: false,
                          });

                          this.isFormFieldValid(e, 'telephoneNumberError');
                        }}
                        onChange={this.onMobileNumberChange}
                        value={telephoneNumber}
                        aria-required="true"
                        className={
                          telephoneNumberError ? 'error-input' : undefined
                        }
                        aria-invalid={!!telephoneNumberError}
                        aria-describedby="mobile-number-error"
                      />
                      <p className="error-text" id="mobile-number-error">
                        {telephoneNumberError && t(telephoneNumberError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        {t('pages.registration.email')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          this.setState({
                            mail: e.target.value.trim().toLowerCase(),
                          });
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'emailError');
                        }}
                        value={mail}
                        aria-required="true"
                        className={emailError ? 'error-input' : undefined}
                        aria-invalid={!!emailError}
                        aria-describedby="email-error"
                      />
                      <p>{t('pages.registration.emailContext')}</p>
                      <p className="error-text" id="email-error">
                        {emailError && t(emailError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="confirmEmail">
                      <Form.Label>
                        {t('pages.registration.confirmEmail')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          const value = e.target.value.trim().toLowerCase();
                          this.setState(
                            {
                              confirmEmail: value,
                            },
                            () => {
                              this.isFormFieldValid(value, 'confirmEmailError');
                            }
                          );
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'confirmEmailError');
                        }}
                        value={confirmEmail}
                        className={
                          confirmEmailError ? 'error-input' : undefined
                        }
                        aria-required="true"
                        aria-invalid={!!confirmEmailError}
                        aria-describedby="confirm-email-error"
                      />
                      <p className="error-text" id="confirm-email-error">
                        {confirmEmailError && t(confirmEmailError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="passWord">
                      <Form.Label>
                        {t('pages.registration.password')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <PasswordField
                        onChange={(value) => {
                          this.setState({
                            password: value,
                          });
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'passwordError');
                        }}
                        password={password}
                        className={passwordError ? 'error-input' : undefined}
                        aria-required="true"
                        ariaInvalid={!!passwordError}
                        ariaDescribedby="password-error"
                        myRef={this.passwordInputRef}
                        onCopy={(e) => {
                          this.disableCopyPaste(e);
                        }}
                        onPaste={(e) => {
                          this.disableCopyPaste(e);
                        }}
                      />
                    </Form.Group>
                    <p className="error-text" id="password-error">
                      {passwordError && t(passwordError)}
                    </p>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="confirmPassWord">
                      <Form.Label>
                        {t('pages.registration.confirmPassword')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <PasswordField
                        onChange={(value) => {
                          this.setState(
                            {
                              confirmPassword: value,
                            },
                            () => {
                              this.isFormFieldValid(
                                value,
                                'confirmPasswordError'
                              );
                            }
                          );
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'confirmPasswordError');
                        }}
                        password={confirmPassword}
                        className={
                          confirmPasswordError ? 'error-input' : undefined
                        }
                        aria-required="true"
                        ariaInvalid={!!confirmPasswordError}
                        ariaDescribedby="confirm-password-error"
                        myRef={this.confirmPasswordInputRef}
                        onCopy={(e) => {
                          this.disableCopyPaste(e);
                        }}
                        onPaste={(e) => {
                          this.disableCopyPaste(e);
                        }}
                      />
                    </Form.Group>

                    <p className="error-text" id="confirm-password-error">
                      {confirmPasswordError && t(confirmPasswordError)}
                    </p>
                  </Col>
                </Form.Row>
                {/*
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="role">
                      <Form.Label>{t('pages.registration.role')}</Form.Label>
                      <DropdownButton
                        ariaDescribedby="role"
                        title={
                          role == ''
                            ? t('pages.profile.pleaseSelect')
                            : t(`pages.registration.${role}`)
                        }
                        className="role-dropdown"
                        variant="Secondary"
                      >
                        <Dropdown.Item
                          eventKey="AdminUser"
                          onClick={() => {
                            this.setState({ role: 'AdminUser' });
                          }}
                        >
                          {t('pages.registration.AdminUser')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="StandardUser"
                          onClick={() => {
                            this.setState({ role: 'StandardUser' });
                          }}
                        >
                          {t('pages.registration.StandardUser')}
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                  </Col>
                        </Form.Row>*/}
                <div className="mt-3">
                  <PasswordRequirements
                    password={password}
                    formSubmitted={formSubmitted}
                  />
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                      onClick={(e) => this.sendToLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      type="submit"
                      onClick={(e) => this.onSubmit(e)}
                    >
                      {t('pages.registration.registration')}
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="separator-light mt-4 mb-4"></div>
            </div>
          )}
          {currentStep === 1 && showGoogleCaptcha && screen === 1 && (
            <>
              <div className="captcha">
                <div className="col">
                  <h2 className="mb-4">{t('pages.forgotPassword.header3')}</h2>
                </div>
                <div className="col-md-6 mt-3 mb-9">
                  <div className="google-captcha">
                    <div className="google-captcha__box">
                      <GoogleCaptcha
                        sitekey={'6LfOjwYeAAAAAEMklJxXFtNJuGhqfUgsUp8Ln6ka'}
                        onCaptchaChange={this.onCaptchaChange}
                        captchaReset={captchaReset}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex col-md-3">
                  <Button
                    className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                    type="submit"
                    onClick={(e) => this.decreaseCurrentStepHandler(e)}
                  >
                    {t('pages.forgotUserId.backBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && screen === 2 && verificationOption && (
            <div className="col-md-12 registration__container">
              <div className="registration__container__resend-verification">
                <div className="registration__container__resend-verification__resend-text">
                  <h3>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {verificationOption}
                  </h3>
                </div>
              </div>
              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className="ml-3"
                    value={verificationCode}
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__resend-verification__resend-text">
                  <p
                    className="resend-link"
                    onClick={(e) => this.resendVerify(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
                <div className="registration__container__pleasenote">
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>

                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4  py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={(e) => this.onVerificationCodeSubmit(e)}
                      type="submit"
                    >
                      {t('pages.registration.verify')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 3 && (
            <div className="col-md-12 registration__container mb-5">
              <>
                <SuccessConfirmation
                  successMessage={t('pages.registration.registrationSuccess')}
                  emailVerify={t('pages.registration.welcomeRegistration')}
                />
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-3 col-md-12">
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={this.sendToLogin}
                      type="submit"
                    >
                      {t('pages.forgotPassword.loginBtn')}
                    </Button>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    );
  }
}
Registration.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(Registration);
