import PropTypes from 'prop-types';
import './ManageServices.scss';
import { withTranslation, useTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { useReducer, useRef, useState, useEffect } from 'react';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid } from 'utils/common';
import WithError from '../../Shared/WithError/WithError';
import Cookies from 'js-cookie';
import http from 'api/http-request';
const CryptoJS = require('crypto-js');

function UserModel(props) {
  const { t } = useTranslation();
  const { userModelVal, handleModelClose } = props;

  const newPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const [errorExist, setErrorExist] = useState();
  const [errorText, setErrorText] = useState();
  const [errorArray, setErrorArray] = useState([]);


  const showError = (errorText) => {
    setErrorText(errorText)
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  function formReducer(state, action) {
    switch (action.type) {
      case 'text_change':
        return {
          ...state,
          data: { ...state.data, [action.keyword]: action.value },
        };

      case 'handle_error':
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.keyword]: errorHandler(action.value, action.keyword),
          },
        };

      case 'handle_bulk_error':
        setErrorExist(errorFlag(action.value));
        return {
          ...state,
          errors: {
            ...action.value,
          },
        };

      default:
        return state;
    }
  }

  const initialPayLoad = {
    data: {
      iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
      'User Name': '',
      Password: '',
      mail: '',
      'First Name': '',
      'Last Name': '',
      confirmPassword: '',
    },
    errors: {
      givenNameError: '',
      snError: '',
      userNameError: '',
      emailError: '',
      confirmPasswordError: '',
      newPasswordError: '',
    },
  };
  let [payLoad, dispatch] = useReducer(formReducer, initialPayLoad);

  const handleTextChange = (e, key) => {
    dispatch({
      type: 'text_change',
      keyword: key,
      value: e.target.value,
    });
  };

  const checkErrors = (e, key) => {
    dispatch({
      type: 'handle_error',
      keyword: key,
      value: e.target.value,
    });
  };

  const handlePasswordChange = (value, key) => {
    dispatch({
      type: 'text_change',
      keyword: key,
      value: value,
    });
  };

  const checkErrorsList = () => {
    return new Promise((resolve, reject) => {
      let obj = {
        givenNameError: '',
        snError: '',
        userNameError: '',
        emailError: '',
        confirmPasswordError: '',
        newPasswordError: '',
      };
      obj.givenNameError = errorHandler(
        payLoad.data['First Name'],
        'givenNameError'
      );
      obj.snError = errorHandler(payLoad.data['Last Name'], 'snError');
      obj.userNameError = errorHandler(
        payLoad.data['User Name'],
        'userNameError'
      );
      obj.emailError = errorHandler(payLoad.data['mail'], 'emailError');
      obj.newPasswordError = errorHandler(
        payLoad.data['Password'],
        'newPasswordError'
      );

      obj.confirmPasswordError = errorHandler(
        payLoad.data['confirmPassword'],
        'confirmPasswordError'
      );

      dispatch({
        type: 'handle_bulk_error',

        value: obj,
      });
    });
  };
  function errorFlag(obj) {
    let error = false;
    for (let key in obj) {
      if (obj[key] != '') {
        error = true;
        return error;
      }
    }

    return error;
  }

  useEffect(() => {
    if (errorExist == false) {
      createAPI();
    }
  }, [errorExist]);

  const createAPI = () => {
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(payLoad.data.Password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    payLoad = {
      ...payLoad,
      data: { ...payLoad.data, Password: encrypted.toString() },
    };
    let data = {...payLoad.data}
    delete data['confirmPassword'];
    http({
      url: '/aegis-managed-service/aegis/api/createUserByPrivilegeUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 && 
        (res.data.iamcode == 'IAM0039' || res.data.iamcode == 'IAM0056')
      ) {
        showError(res.data.data.message)
      } else if(res.status == 400 && res.data.iamcode == 'IAM0055'){
        showError(res.data.data.Message.errorMessage)
      } else if (res.status == 200 && res.data.response == 'success') {
        const { handleModelClose, setUserModelVal } = props;

        handleModelClose('addUser');
        setUserModelVal(null);
      }
    });
  };
  const createUser = (e) => {
    e.preventDefault();
    checkErrorsList();
  };

  function errorHandler(value, key) {
    let errorMsg = '';

    switch (key) {
      case 'givenNameError':
        if (value.length <= 0) {
          errorMsg = 'errors.firstNameError';
        }
        break;
      case 'snError':
        if (value.length <= 0) {
          errorMsg = 'errors.lastNameError';
        }
        break;

      case 'userNameError':
        if (value.length < 5 || value.length > 30) {
          errorMsg = 'errors.userNameLength';
        } else if (
          !value.match(/([a-zA-Z])*/) &&
          value.length >= 5 &&
          value.length <= 30
        ) {
          errorMsg = 'errors.userNameAtleastAlphabet';
        }
        break;

      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;

      case 'newPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }

    return errorMsg;
  }

  return (
    <>
      <Modal
        show={userModelVal}
        onHide={() => {
          props.setUserModelVal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(`pages.ManageServices.${userModelVal}User`)}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
          <Container fluid>
            <Form>
              <Form.Group controlId="firstName">
                <Form.Label>
                  {t('pages.editName.firstName')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  maxLength="40"
                  aria-required
                  aria-describedby="firstname-error"
                  value={payLoad.data['First Name']}
                  onChange={(e) => {
                    handleTextChange(e, 'First Name');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'givenNameError');
                  }}
                />
                <p className="error-text" id="firstname-error">
                  {t(payLoad.errors.givenNameError)}
                </p>
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label>
                  {t('pages.editName.lastName')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  maxLength="40"
                  aria-required
                  aria-describedby="firstname-error"
                  value={payLoad.data['Last Name']}
                  onChange={(e) => {
                    handleTextChange(e, 'Last Name');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'snError');
                  }}
                />
                <p className="error-text" id="lastname-error">
                  {payLoad.errors.snError && t(payLoad.errors.snError)}
                </p>
              </Form.Group>

              <Form.Group controlId="userName">
                <Form.Label>
                  {t('pages.ManageServices.userName')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  maxLength="40"
                  aria-required
                  aria-describedby="userName-error"
                  value={payLoad.data['User Name']}
                  onChange={(e) => {
                    handleTextChange(e, 'User Name');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'userNameError');
                  }}
                />
                <p className="error-text" id="username-error">
                  {payLoad.errors.userNameError &&
                    t(payLoad.errors.userNameError)}
                </p>
              </Form.Group>

              <Form.Group controlId="mail">
                <Form.Label>
                  {t('pages.ManageServices.email')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  maxLength="40"
                  aria-required
                  aria-describedby="mail-error"
                  value={payLoad.data['mail']}
                  onChange={(e) => {
                    handleTextChange(e, 'mail');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'emailError');
                  }}
                />
                <p className="error-text" id="email-error">
                  {payLoad.errors.emailError && t(payLoad.errors.emailError)}
                </p>
              </Form.Group>

              <Form.Group controlId="newPassword">
                <Form.Label>{t('pages.changePassword.password')}</Form.Label>
                <span className="mandatory">*</span>
                <PasswordField
                  onChange={(value) => {
                    handlePasswordChange(value, 'Password');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'newPasswordError');
                  }}
                  password={payLoad.data['Password']}
                  className={
                    payLoad.errors.newPasswordError ? 'error-input' : undefined
                  }
                  ariaInvalid={!!payLoad.errors.newPasswordError}
                  ariaDescribedby="new-password-error"
                  myRef={newPasswordInputRef}
                />
                {payLoad.errors.newPasswordError && (
                  <p className="error-text" id="verification-error">
                    {payLoad.errors.newPasswordError &&
                      t(payLoad.errors.newPasswordError)}
                  </p>
                )}
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>
                  {t('pages.changePassword.confirmNewPassword')}
                </Form.Label>
                <span className="mandatory">*</span>
                <PasswordField
                  onChange={(value) => {
                    handlePasswordChange(value, 'confirmPassword');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'confirmPasswordError');
                  }}
                  password={payLoad.data['confirmPassword']}
                  className={
                    payLoad.errors.confirmPasswordError
                      ? 'error-input'
                      : undefined
                  }
                  ariaInvalid={!!payLoad.errors.confirmPasswordError}
                  ariaDescribedby="confirm-password-error"
                  myRef={confirmPasswordInputRef}
                />
                {payLoad.errors.confirmPasswordError && (
                  <p className="error-text" id="verification-error">
                    {payLoad.errors.confirmPasswordError &&
                      t(payLoad.errors.confirmPasswordError)}
                  </p>
                )}
                {payLoad.data.confirmPassword != '' &&
                  payLoad.data['Password'] !== payLoad.data.confirmPassword && (
                    <p className="error-text" id="verification-error">
                      {t('errors.passwordsError')}
                    </p>
                  )}
              </Form.Group>

              <PasswordRequirements
                password={payLoad.data['Password']}
                formSubmitted={false}
              />
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              size="lg"
              variant="primary"
              block
              type="submit"
              onClick={(e) => createUser(e)}
            >
              {t('pages.editName.save')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

UserModel.propTypes = {
  t: PropTypes.func.isRequired,
  userModelVal: PropTypes.string.isRequired,
  handleModelClose: PropTypes.func.isRequired,
  setUserModelVal: PropTypes.func,
};

export default withTranslation()(UserModel);
