import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import {
  faUserCircle,
  faUnlock,
  faMobileAlt,
  faComments,
  faShapes,
  faUsers,
  faUserSecret,
  faChartLine,
  faCogs,
  faTasks,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ProfileLeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideAccountMenus: false,
      width: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  hideAccountMenusHandler = () => {
    this.setState((prev) => ({ hideAccountMenus: !prev.hideAccountMenus }));
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.hideAccountMenusHandler();
    } else {
      return;
    }
  };

  render() {
    const { pageName, t } = this.props;
    const { width, hideAccountMenus } = this.state;

    return (
      <div className="profile-menu">
        <div className="row align-items-center m-0">
          <div className="col-10">
            <h6 className="profile-menu__title p-0">
              {t('pages.profile.manageAccount')}
            </h6>
          </div>
          <div className="col-2 text-right ft-24">
            <FontAwesomeIcon
              className={
                width < 992 ? 'cursor-pointer' : 'd-none cursor-pointer'
              }
              icon={hideAccountMenus ? faChevronDown : faChevronUp}
              onClick={this.hideAccountMenusHandler}
              onKeyDown={this.onKeyDown}
              tabIndex={0}
              role="img"
            ></FontAwesomeIcon>
          </div>
        </div>
        <ul
          className={`profile-menu__options list-unstyled px-3 px-sm-0 ${
            hideAccountMenus && width < 992 ? 'd-none' : ''
          }`}
        >
          <li className={`pb-2 ${pageName === 'Account' ? 'active' : ''}`}>
            <Link
              className={`preferences black
            ${pageName === 'Account' ? 'active' : ''}`}
              //to="/profile/Account?app=cognito"
              to="/profile/Account"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faUserCircle} role="button" />
              <span className="pl-3">{t('pages.profile.updateProfile')}</span>
            </Link>
          </li>
          <li
            className={`pb-2 ${pageName === 'changePassword' ? 'active' : ''}`}
          >
            <Link
              className={`preferences black
            ${pageName === 'changePassword' ? 'active' : ''}`}
              //to="/profile/changePassword?app=cognito"
              to="/profile/changePassword"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faUnlock} role="button" />
              <span className="pl-3">{t('pages.profile.changePassword')}</span>
            </Link>
          </li>
          {/* <li className={`pb-2 ${pageName === 'Devices' ? 'active' : ''}`}>
            <Link
              className={`preferences black
            ${pageName === 'Devices' ? 'active' : ''}`}
              to="/profile/Devices"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faMobileAlt} role="button" />
              <span className="pl-3">{t('pages.devices.title')}</span>
            </Link>
          </li> */}
          <li
            className={`pb-2 ${
              pageName === 'CommunicationPreferences' ? 'active' : ''
            }`}
          >
            <Link
              className={`preferences black
            ${pageName === 'CommunicationPreferences' ? 'active' : ''}`}
              //to="/profile/CommunicationPreferences?app=cognito"
              to="/profile/CommunicationPreferences"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faComments} role="button" />
              <span className="pl-3">
                {t('pages.communicationPreferences.title')}
              </span>
            </Link>
          </li>
          {/* <li
            className={`pb-2 ${pageName === 'AuthorizedApps' ? 'active' : ''}`}
          >
            <Link
              className={`preferences black
            ${pageName === 'AuthorizedApps' ? 'active' : ''}`}
              to="/profile/AuthorizedApps"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faShapes} role="button" />
              <span className="pl-3">{t('pages.authorizedApps.title')}</span>
            </Link>
          </li>
          <li
            className={`pb-2 ${pageName === 'PeopleSharing' ? 'active' : ''}`}
          >
            <Link
              className={`preferences black
            ${pageName === 'PeopleSharing' ? 'active' : ''}`}
              to="/profile/PeopleSharing"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faUsers} role="button" />
              <span className="pl-3">{t('pages.peopleSharing.title')}</span>
            </Link>
          </li>
          <li
            className={`pb-2 ${pageName === 'PrivacyConsent' ? 'active' : ''}`}
          >
            <Link
              className={`preferences black
            ${pageName === 'PrivacyConsent' ? 'active' : ''}`}
              to="/profile/PrivacyConsent"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faUserSecret} role="button" />
              <span className="pl-3">{t('pages.privacyConsent.title')}</span>
            </Link>
          </li>
          <li className={`pb-2 ${pageName === 'ActivityLog' ? 'active' : ''}`}>
            <Link
              className={`preferences black
            ${pageName === 'ActivityLog' ? 'active' : ''}`}
              to="/profile/ActivityLog"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faChartLine} role="button" />
              <span className="pl-3">{t('pages.activityLog.title')}</span>
            </Link>
          </li>
          <li className={`pb-2 ${pageName === 'SiteSettings' ? 'active' : ''}`}>
            <Link
              className={`preferences black
            ${pageName === 'SiteSettings' ? 'active' : ''}`}
              to="/profile/SiteSettings"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faCogs} role="button" />
              <span className="pl-3">{t('pages.siteSettings.title')}</span>
            </Link>
          </li>
          <li
            className={`pb-2 ${
              pageName === 'AccountManagement' ? 'active' : ''
            }`}
          >
            <Link
              className={`preferences black
            ${pageName === 'AccountManagement' ? 'active' : ''}`}
              to="/profile/AccountManagement"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faTasks} role="button" />
              <span className="pl-3">{t('pages.accountManagement.title')}</span>
            </Link>
          </li> */}
        </ul>
      </div>
    );
  }
}
ProfileLeftMenu.propTypes = {
  pageName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ProfileLeftMenu);
