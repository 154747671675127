import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/AuthorizedApps.scss';
import { withTranslation } from 'react-i18next';
import {
  faFacebook,
  faTwitter,
  faGooglePlusG,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AuthorizedApps extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="authorized-apps">
          <div className="row">
            <div className="col-xs-12 col-sm-4 pb-2">
              <div className="auth-app-container">
                <FontAwesomeIcon icon={faFacebook} role="img" />
                <div className="auth-app-container__container">
                  <div className="green">{t('pages.authorizedApps.view')}</div>
                  <div className="red">
                    {t('pages.authorizedApps.unauthorize')}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 pb-2">
              <div className="auth-app-container">
                <FontAwesomeIcon icon={faTwitter} role="button" />
                <div className="auth-app-container__container">
                  <div className="green">{t('pages.authorizedApps.view')}</div>
                  <div className="red">
                    {t('pages.authorizedApps.unauthorize')}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 pb-2">
              <div className="auth-app-container">
                <FontAwesomeIcon icon={faGooglePlusG} role="button" />
                <div className="auth-app-container__container">
                  <div className="green">{t('pages.authorizedApps.view')}</div>
                  <div className="red">
                    {t('pages.authorizedApps.unauthorize')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AuthorizedApps.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(AuthorizedApps);
