import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/SiteSettings.scss';
import { withTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { Dropdown } from 'react-bootstrap';
import timeZones from 'assets/json/timezones.json';

class SiteSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      appLanguage: 'English',
      appTimeZone: '(GMT-05:00) Eastern Time (US & Canada)',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (checked) => {
    this.setState({
      checked: checked,
    });
  };

  setAppLanguage = (e) => {
    this.setState({
      appLanguage: e,
    });
  };

  setAppTimeZone = (e) => {
    this.setState({
      appTimeZone: e,
    });
  };

  render() {
    const { t } = this.props;
    const { checked, appLanguage, appTimeZone } = this.state;
    return (
      <div>
        <div className="site-settings mb-5">
          <h1>{t('pages.siteSettings.title')}</h1>
          <hr />
          <div className="site-settings__container">
            <div className="col-xs-6">
              {t('pages.siteSettings.notification')}
            </div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={(checked) => {
                  this.handleChange(checked);
                }}
                checked={checked}
                id="switch1"
                aria-label="notification"
              />
            </div>
          </div>
          <div className="site-settings__container">
            <div className="col-xs-6">{t('pages.siteSettings.faceId')}</div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={(checked) => {
                  this.handleChange(checked, 'switch2');
                }}
                id="switch2"
                aria-label="faceId"
              />
            </div>
          </div>
          <div className="site-settings__container">
            <div className="col-xs-6">{t('pages.siteSettings.location')}</div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                aria-label="location"
              />
            </div>
          </div>
          <div className="site-settings__container">
            <div className="col-xs-6">
              {t('pages.siteSettings.dataCollection')}
            </div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                aria-label="dataCollection"
              />
            </div>
          </div>
          <div className="site-settings__container">
            <div className="col-xs-6">{t('pages.siteSettings.allow4g')}</div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                aria-label="allow4g"
              />
            </div>
          </div>
          <div className="site-settings__container">
            <div className="col-xs-6">
              {t('pages.siteSettings.sendAppCrash')}
            </div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                aria-label="sendAppCrash"
              />
            </div>
          </div>
          <div className="site-settings__container">
            <div className="col-xs-6">{t('pages.siteSettings.darkMode')}</div>
            <div className="col-xs-6">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                aria-label="darkMode"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <button className="btn btn-danger py-2 my-3">
                {t('pages.siteSettings.clear')}
              </button>
            </div>
          </div>
          <hr></hr>
          <div>
            <div className="row mb-3">
              <legend className="col">
                {t('pages.siteSettings.languageSelectLegend')}:
              </legend>
              <Dropdown
                className="site-setting-dropdowns col-md-6 p-0 m-0 mx-3"
                onSelect={this.setAppLanguage}
              >
                <Dropdown.Toggle id="dropdown-basic1">
                  {t(appLanguage)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey={'English'} key="English">
                    {t('pages.siteSettings.languageEnglish')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="row">
              <legend className="col">
                {t('pages.siteSettings.timeZoneLegend')}:
              </legend>
              <Dropdown
                className="col-md-6 site-setting-dropdowns p-0 m-0 mx-3"
                onSelect={this.setAppTimeZone}
              >
                <Dropdown.Toggle id="dropdown-basic">
                  {`${appTimeZone.slice(0, 12)}${t(appTimeZone.slice(12))}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {timeZones.map((timezone, index) => (
                    <Dropdown.Item key={index} eventKey={timezone.label}>
                      {timezone.label.slice(0, 12)}
                      {t(timezone.label.slice(12))}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SiteSettings.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(SiteSettings);
