import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid, getNestedObject } from 'utils/common';
import 'app/modules/Dashboard Cognito/ChangeSecurityQuestionsPopup.scss';
import http from 'api cognito/http-request';
import WithError from '../Shared/WithError Cognito/WithError';
import Select from 'react-select';
import { counter } from '@fortawesome/fontawesome-svg-core';
import Cookies from 'js-cookie';

class ChangeSecurityQuestionsPopup extends Component {
  constructor(props) {
    super(props);
    this.changePasswordPopupContainer = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.currentPasswordInputRef = React.createRef();
    this.newPasswordInputRef = React.createRef();
    this.state = {
      questionVals: [null, null, null],
      filterOptions: [],
      errorText: '',
      errorArray: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // let token = localStorage.getItem('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
    if (token || id) {
      this.getSecurityQuestions(id, token);
    }
  }

  updateSecurity = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.changePassword.resetSecurity'
        : 'pages.changePassword.resetSecurity';
    this.setState(
      {
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };
  getAvailableOptions = () => {
    const availableOptionsLeft = this.state.filterOptions;
    return availableOptionsLeft.filter((questionOption) => {
      return this.state.questionVals.indexOf(questionOption) === -1;
    });
  };

  mapQuestionDictToArray = (questionDict) => {
    // let preferredLanguage = localStorage.getItem('ctsessionlanguage');
    let preferredLanguage = Cookies.get('ctsessionlanguage');
    if (preferredLanguage === 'en_US') {
      preferredLanguage = 'en';
    }
    let filterOptions = Object.keys(questionDict).map(function (k) {
      let dict = {};

      dict['value'] = k;

      dict['label'] = questionDict[k][preferredLanguage];
      dict['translations'] = questionDict[k];
      return dict;
    });

    return filterOptions;
  };

  getSecurityQuestions = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';

    http(
      {
        url: 'selfservice/kba',
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    )
      .then((res) => {
        if (res.data) {
          let filterOptionsFromServer = this.mapQuestionDictToArray(
            res.data.questions
          );

          this.setState({
            //filterOptions: Object.values(res.data.questions),
            filterOptions: filterOptionsFromServer,
          });
        }
      })
      .then(() => {
        this.getUserSelectedQuestions(id, token);
      });
  };

  getUserSelectedQuestions = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';

    http(
      {
        url: `managed/user/${id}?&_fields=kbaInfo`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        let questionList = res.data.kbaInfo;

        let index_counter = 0;
        for (let i = 0; i < questionList.length; i++) {
          for (let j = 0; j < this.state.filterOptions.length; j++) {
            if (
              questionList[i].questionId == this.state.filterOptions[j].value
            ) {
              this.handleQuestionValChange(
                this.state.filterOptions[j],
                index_counter
              );
            }
          }
          index_counter++;
        }
      }
    });
  };

  handleQuestionValChange = (option, index) => {
    const newQuestionVals = this.state.questionVals;
    newQuestionVals[index] = option;
    this.setState(() => {
      return {
        questionVals: newQuestionVals,
      };
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { answerOne, answerTwo, answerThree, questionVals } = this.state;
    const { updateSecurity } = this.props;

    // let userId = localStorage.getItem('user_id');
    let userId = Cookies.get('user_id');
    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');

    if (!answerOne || !answerTwo || !answerThree) {
      let failedPolicyRequirements = [
        { policyRequirements: ['answers'], property: 'securityQuestions' },
      ];
      this.setState({
        errorArray: failedPolicyRequirements,
      });
      this.changePasswordPopupContainer.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
      return;
    }
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: [
          {
            operation: 'replace',
            field: '/kbaInfo',
            value: [
              {
                answer: answerOne,
                questionId: questionVals[0].value,
              },
              {
                answer: answerTwo,
                questionId: questionVals[1].value,
              },
              {
                answer: answerThree,
                questionId: questionVals[2].value,
              },
            ],
          },
        ],
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data._id === userId) {
        updateSecurity('success');
        this.setState({
          errorArray: '',
        });
      }
      this.props.onClose();
    });
  };

  onFirstAndLastNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z '`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { mail, confirmEmail, password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'answerOneError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerOneError';
        }
        break;
      case 'answerTwoError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerTwoError';
        }
        break;
      case 'answerThreeError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerThreeError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  /*________________________________________________________________*/

  render() {
    const { onClose, t } = this.props;
    const {
      answerOne,
      answerTwo,
      answerThree,
      answerOneError,
      answerTwoError,
      answerThreeError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div
          className="change-password-popup"
          ref={this.changePasswordPopupContainer}
          role="main"
        >
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}

                  <div className="change-password-popup__popup-body-header">
                    <h1 id="password-modal">
                      {t('pages.changePassword.SetQuestionPopup')}
                    </h1>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <div className="form-group">
                    <Form>
                      <label>
                        {t('pages.changePassword.singleQuestion')} 1
                      </label>
                      <Select
                        name="filters"
                        placeholder={
                          t('pages.changePassword.singleQuestion') + ' 1'
                        }
                        value={this.state.questionVals[0]}
                        options={this.getAvailableOptions()}
                        onChange={(e) => {
                          this.handleQuestionValChange(e, 0);
                        }}
                        className="select-box"
                        aria-label="list"
                      />
                      <Form.Group controlId="answerOne">
                        <Form.Control
                          onChange={(e) => {
                            this.onFirstAndLastNameChange(e, 'answerOne');
                          }}
                          value={answerOne}
                          maxLength="40"
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'answerOneError');
                          }}
                          className={`answer-input ${
                            answerOneError ? 'error-input' : undefined
                          }`}
                          aria-required
                          aria-invalid="false"
                          aria-label="answer1"
                        />
                        <p className="error-text" id="firstname-error1">
                          {answerOneError && t(answerOneError)}
                        </p>
                      </Form.Group>
                      <label>
                        {t('pages.changePassword.singleQuestion')} 2
                      </label>
                      <Select
                        name="filters"
                        placeholder={
                          t('pages.changePassword.singleQuestion') + ' 2'
                        }
                        value={this.state.questionVals[1]}
                        options={this.getAvailableOptions()}
                        onChange={(e) => {
                          this.handleQuestionValChange(e, 1);
                        }}
                        className="select-box"
                        aria-label="list"
                      />
                      <Form.Group controlId="answerTwo">
                        <Form.Control
                          onChange={(e) => {
                            this.onFirstAndLastNameChange(e, 'answerTwo');
                          }}
                          value={answerTwo}
                          maxLength="40"
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'answerTwoError');
                          }}
                          className={`answer-input ${
                            answerTwoError ? 'error-input' : undefined
                          }`}
                          aria-required
                          aria-invalid="false"
                          aria-label="answer2"
                        />
                        <p className="error-text" id="firstname-error2">
                          {answerTwoError && t(answerTwoError)}
                        </p>
                      </Form.Group>
                      <label>
                        {t('pages.changePassword.singleQuestion')} 3
                      </label>
                      <Select
                        name="filters"
                        placeholder={
                          t('pages.changePassword.singleQuestion') + ' 3'
                        }
                        value={this.state.questionVals[2]}
                        options={this.getAvailableOptions()}
                        onChange={(e) => {
                          this.handleQuestionValChange(e, 2);
                        }}
                        className="select-box"
                        aria-label="list"
                      />
                      <Form.Group controlId="answerThree">
                        <Form.Control
                          onChange={(e) => {
                            this.onFirstAndLastNameChange(e, 'answerThree');
                          }}
                          value={answerThree}
                          maxLength="40"
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'answerThreeError');
                          }}
                          className={`answer-input ${
                            answerThreeError ? 'error-input' : undefined
                          }`}
                          aria-required
                          aria-invalid="false"
                          aria-label="answer3"
                        />
                        <p className="error-text" id="firstname-error3">
                          {answerThreeError && t(answerThreeError)}
                        </p>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.onVerify(e)}
                  >
                    {t('pages.changePassword.save')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ChangeSecurityQuestionsPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateSecurity: PropTypes.func.isRequired,
};
export default withTranslation()(ChangeSecurityQuestionsPopup);
