import PropTypes from 'prop-types';
import './ManageServices.scss';
import { withTranslation, useTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { useReducer, useRef, useState, useEffect } from 'react';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid } from 'utils/common';
import Cookies from 'js-cookie';
import http from 'api/http-request';
import WithError from '../../Shared/WithError/WithError';
const CryptoJS = require('crypto-js');

function EditPasswordPopup(props) {
  const { t } = useTranslation();
  const { handleModelClose } = props;
  const [errorText, setErrorText] = useState();
  const newPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const [errorExist, setErrorExist] = useState();
  const [errorArray, setErrorArray] = useState([]);

  function formReducer(state, action) {
    switch (action.type) {
      case 'text_change':
        return {
          ...state,
          data: { ...state.data, [action.keyword]: action.value },
        };

      case 'handle_error':
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.keyword]: errorHandler(action.value, action.keyword),
          },
        };

      case 'handle_bulk_error':
        setErrorExist(errorFlag(action.value));
        return {
          ...state,
          errors: {
            ...action.value,
          },
        };

      default:
        return state;
    }
  }

  const initialPayLoad = {
    data: {
      iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
      'User Name': props.editModelVal['User Name'],
      Password: '',

      confirmPassword: '',
    },
    errors: {
      confirmPasswordError: '',
      newPasswordError: '',
    },
  };
  let [payLoad, dispatch] = useReducer(formReducer, initialPayLoad);

  const showError = (errorText) => {
    setErrorText(errorText);
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const handleTextChange = (e, key) => {
    dispatch({
      type: 'text_change',
      keyword: key,
      value: e.target.value,
    });
  };

  const checkErrors = (e, key) => {
    dispatch({
      type: 'handle_error',
      keyword: key,
      value: e.target.value,
    });
  };

  const handlePasswordChange = (value, key) => {
    dispatch({
      type: 'text_change',
      keyword: key,
      value: value,
    });
  };

  const checkErrorsList = () => {
    return new Promise((resolve, reject) => {
      let obj = {
        confirmPasswordError: '',
        newPasswordError: '',
      };

      obj.newPasswordError = errorHandler(
        payLoad.data['Password'],
        'newPasswordError'
      );

      obj.confirmPasswordError = errorHandler(
        payLoad.data['confirmPassword'],
        'confirmPasswordError'
      );

      dispatch({
        type: 'handle_bulk_error',

        value: obj,
      });
    });
  };
  function errorFlag(obj) {
    let error = false;
    for (let key in obj) {
      if (obj[key] != '') {
        error = true;
        return error;
      }
    }

    return error;
  }

  useEffect(() => {
    if (errorExist == false) {
      createAPI();
    }
  }, [errorExist]);

  const createAPI = () => {
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(payLoad.data.Password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });

    payLoad = {
      ...payLoad,
      data: { ...payLoad.data, Password: encrypted.toString() },
    };
    let data = payLoad.data;
    delete data['confirmPassword'];
    http({
      url: '/aegis-managed-service/aegis/api/setNewPasswordByPrivilegeUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 &&
        ((res.data.iamcode == 'IAM0028' &&
          res.data.response == 'Access Denied') ||
          (res.data.iamcode == 'IAM0039' &&
            res.data.response == 'Unauthorized'))
      ) {
        /*
        Cookies.remove('userName');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';*/
      } else if (res.status == 200 && res.data.response == 'success') {
        const { handleModelClose, setEditModelVal } = props;
        handleModelClose(res.data.response);
        setEditModelVal((prev) => {
          return { ...prev, showPasswordModel: false };
        });
      } else if (res.status == 400 && res.data.iamcode == 'IAM0055') {
        showError(res.data.data.Message.errorMessage);
      }
    });
  };
  const createUser = (e) => {
    e.preventDefault();
    checkErrorsList();
  };

  function errorHandler(value, key) {
    let errorMsg = '';

    switch (key) {
      case 'newPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }

    return errorMsg;
  }

  return (
    <>
      <Modal
        show={props.editModelVal.showPasswordModel}
        onHide={() => {
          props.setEditModelVal((prev) => {
            return { ...prev, showPasswordModel: false };
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(`pages.changePassword.changePassword`)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(errorText || errorArray.length > 0) && (
            <WithError
              errors={errorText}
              errorArray={errorArray}
              isPopup={true}
            />
          )}
          <Container fluid>
            <Form>
              <Form.Group controlId="newPassword">
                <Form.Label>{t('pages.changePassword.password')}</Form.Label>
                <span className="mandatory">*</span>
                <PasswordField
                  onChange={(value) => {
                    handlePasswordChange(value, 'Password');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'newPasswordError');
                  }}
                  password={payLoad.data['Password']}
                  className={
                    payLoad.errors.newPasswordError ? 'error-input' : undefined
                  }
                  ariaInvalid={!!payLoad.errors.newPasswordError}
                  ariaDescribedby="new-password-error"
                  myRef={newPasswordInputRef}
                />
                {payLoad.errors.newPasswordError && (
                  <p className="error-text" id="verification-error">
                    {payLoad.errors.newPasswordError &&
                      t(payLoad.errors.newPasswordError)}
                  </p>
                )}
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>
                  {t('pages.changePassword.confirmNewPassword')}
                </Form.Label>
                <span className="mandatory">*</span>
                <PasswordField
                  onChange={(value) => {
                    handlePasswordChange(value, 'confirmPassword');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'confirmPasswordError');
                  }}
                  password={payLoad.data['confirmPassword']}
                  className={
                    payLoad.errors.confirmPasswordError
                      ? 'error-input'
                      : undefined
                  }
                  ariaInvalid={!!payLoad.errors.confirmPasswordError}
                  ariaDescribedby="confirm-password-error"
                  myRef={confirmPasswordInputRef}
                />
                {payLoad.errors.confirmPasswordError && (
                  <p className="error-text" id="verification-error">
                    {payLoad.errors.confirmPasswordError &&
                      t(payLoad.errors.confirmPasswordError)}
                  </p>
                )}
                {payLoad.data.confirmPassword != '' &&
                  payLoad.data['Password'] !== payLoad.data.confirmPassword && (
                    <p className="error-text" id="verification-error">
                      {t('errors.passwordsError')}
                    </p>
                  )}
              </Form.Group>

              <PasswordRequirements
                password={payLoad.data['password']}
                formSubmitted={false}
              />
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              size="lg"
              variant="primary"
              block
              type="submit"
              onClick={(e) => createUser(e)}
            >
              {t('pages.editName.save')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EditPasswordPopup.propTypes = {
  t: PropTypes.func.isRequired,
  editModelVal: PropTypes.bool.isRequired,
  handleModelClose: PropTypes.func.isRequired,
  setEditModelVal: PropTypes.func.isRequired,
};

export default withTranslation()(EditPasswordPopup);
