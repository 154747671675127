import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logo } from 'utils/common aegisCloud/clientStyleAssets';
import 'app/modules/Header/Header.scss';
import { withTranslation } from 'react-i18next';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'react-bootstrap';
import http from 'api aegisCloud/http-request';
import queryString from 'query-string';
import i18n from 'i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

class Header extends Component {
  constructor(props) {
    super(props);
  }

  getCTSession = () => {
    if (Cookies.get('ctsessionlanguage')) {
      if (
        Cookies.get('ctsessionlanguage') === 'en_US' ||
        Cookies.get('ctsessionlanguage') === 'undefined'
      ) {
        ReactGA.event({
          category: 'Language Change - AegisCloud',
          action: 'Language Changed: Switched to English',
        });
        this.changeLanguage('en_US');
      } else {
        ReactGA.event({
          category: 'Language Change - AegisCloud',
          action: 'Language Changed: Switched to Spanish',
        });
        this.changeLanguage('es');
      }
    }
  };
  setDefaultLanguage = () => {
    localStorage.setItem('ctsessionlanguage', 'en_US');
    Cookies.set('ctsessionlanguage', 'en_US');
  };

  componentDidMount = () => {
    this.getCTSession();
  };

  getAPIHeaders = () => {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  };

  endSession = () => {
    let idToken = Cookies.get('idToken');
    let bearer = 'Bearer ';
    let accessToken = Cookies.get('dataStoreToken');
    http(
      {
        url: `endSession?id_token_hint=${idToken}`,
        method: 'GET',
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
      }
    ).then(() => {
      Cookies.remove('user_id');
      Cookies.remove('dataStoreToken');
      Cookies.remove('profileCodeDetails');
      Cookies.set('userLoggedIn', false);
      Cookies.remove('idToken');
      Cookies.remove('clientId');
      Cookies.remove('userDetails');
      Cookies.remove('firstName');
      Cookies.remove('lastName');
      Cookies.remove('isUserLogin');
      Cookies.remove('userName');
      Cookies.remove('iPlanetDirectoryPro');
      Cookies.remove('editEmailSuccess');

      Cookies.remove('sessionTimeStart');
      Cookies.remove('_gat');
      Cookies.remove('_gid');
      Cookies.remove('_ga');
      Cookies.remove('ctsessionlanguage');

      //this.getCTSession();
      ReactGA.event({
        category: 'Logout',
        action: 'Logout: Logout-Success',
      });
      this.setDefaultLanguage();
      window.location.href = sanitizeUrl('/');
    });
  };

  deleteStorage = () => {
    let accessToken = Cookies.get('dataStoreToken');
    let payLoad = {};
    payLoad.token = accessToken;
    payLoad.client_id = Cookies.get('clientId');
    payLoad.token_type_hint = 'access_token';

    http(
      {
        url: 'revoke',
        method: 'POST',
        data: queryString.stringify(payLoad),
      },
      { ...this.getAPIHeaders() }
    ).then(() => {
      ReactGA.event({
        category: 'Logout',
        action: 'Logout: Logout Button triggered from Header Component',
      });
      this.endSession();
    });
  };

  changeLanguage = (lang) => {
    Cookies.set('ctsessionlanguage', lang);
    ReactGA.event({
      category: 'Language Change - AegisCloud',
      action: 'Language Change Triggered: User trying to switch langugae',
    });
    i18n.changeLanguage(lang);
  };

  render() {
    const { t, userDetails } = this.props;
    const userInitials = `${
      userDetails.givenName ? userDetails.givenName.charAt(0) : ''
    }${userDetails.sn ? userDetails.sn.charAt(0) : ''}`;

    const userDetailsEmpty = Object.keys(userDetails).length === 0;
    return (
      <div className="header d-flex align-items-center">
        <a className="my-0 mr-auto" href="/">
          <img src={logo} alt="AegisCloud" className="w-100" />
        </a>
        {/* AegisCloud */}
        <Dropdown className="m-0 translation" tabIndex="0">
          <Dropdown.Toggle id="dropdownButton" as={Button}>
            <p className="user-initials-icon bold p-4 m-0">
              {Cookies.get('ctsessionlanguage') === undefined ||
              Cookies.get('ctsessionlanguage') === 'en_US' ||
              Cookies.get('ctsessionlanguage') === null
                ? t('pages.header.english')
                : t('pages.header.spanish')}
            </p>
          </Dropdown.Toggle>
          <Dropdown.Menu tabIndex="0">
            <Dropdown.Item
              to="#"
              as={Link}
              onClick={() => {
                this.changeLanguage('en_US');
              }}
            >
              {t('pages.header.english')}
            </Dropdown.Item>
            <Dropdown.Item
              to="#"
              as={Link}
              onClick={() => {
                this.changeLanguage('es');
              }}
            >
              {t('pages.header.spanish')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {!userDetailsEmpty && userInitials && (
          <nav
            className="my-2 my-md-0 ml-3 mr-sm-3"
            id="user-Initials-container"
            role="navigation"
            aria-label="user navigation"
          >
            <Dropdown className="m-0 b-0">
              <Dropdown.Toggle as={Button}>
                <p className="user-initials-icon bold p-3 m-0 ">
                  {userInitials}
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <p className="welcome-text">
                  {t('pages.header.welcome')},{' '}
                  <span className="bold">
                    {userDetails.givenName} {userDetails.sn}
                  </span>
                </p>
                <Dropdown.Item
                  href="#"
                  onClick={(e) => {
                    this.deleteStorage(e);
                  }}
                >
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      role="img"
                    ></FontAwesomeIcon>
                  </span>
                  {t('pages.header.dropdownLogout')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </nav>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
  history: PropTypes.object,
};
Header.defaultProps = {
  userDetails: {},
};

export default withTranslation()(Header);
