import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid, getNestedObject } from 'utils/common';
import 'app/modules/Dashboard/ChangePasswordPopup.scss';
import http from 'api aegisCloud/http-request';
import WithError from '../Shared/WithError/WithError';
import Cookies from 'js-cookie';
import { isNull } from 'lodash';

class ChangePasswordPopup extends Component {
  constructor(props) {
    super(props);
    this.changePasswordPopupContainer = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.currentPasswordInputRef = React.createRef();
    this.newPasswordInputRef = React.createRef();
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      errorText: '',
      errorArray: [],
      payLoad: {},
    };
  }

  componentDidMount() {
    this.getToken();
    window.scrollTo(0, 0);
  }

  getToken = () => {
    http(
      {
        url:
          '?noSession=true&ForceAuth=true&authIndexType=service&authIndexValue=UpdatePassword&goto=https%3A%2F%2Fopenam-deloitte-dev2.forgeblocks.com%2Fenduser%2F%3Frealm%3Dbravo%23%2Fprofile',

        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.setState({ payLoad: res.data });
    });
  };

  checkAllField = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = this.state;

    if (currentPassword.length <= 0) {
      let errorMsg = 'errors.noCurrentPasswordError';
      this.setState({
        currentPasswordError: errorMsg,
      });
    }
    if (newPassword.length <= 0) {
      let errorMsg = 'errors.noNewPasswordError';
      this.setState({
        newPasswordError: errorMsg,
      });
    }

    if (confirmPassword.length <= 0) {
      let errorMsg = 'errors.noConfirmPasswordError';
      this.setState({
        confirmPasswordError: errorMsg,
      });
    }

    if (newPassword !== confirmPassword) {
      let errorMsg = 'errors.passwordsError';
      this.setState({
        confirmPasswordError: errorMsg,
      });
    }

    if (currentPassword === newPassword) {
      let errorMsg = 'errors.currentNewPasswordSameError';
      this.setState({
        newPasswordError: errorMsg,
      });
    }

    const {
      currentPasswordError,
      confirmPasswordError,
      newPasswordError,
    } = this.state;
    if (!(currentPasswordError || confirmPasswordError || newPasswordError)) {
      this.onChangePassword();
    }
  };
  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { currentPassword, newPassword, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'currentPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noCurrentPasswordError';
        }
        break;
      case 'newPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noNewPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        } else if (currentPassword === confirmPassword) {
          errorMsg = 'errors.currentNewPasswordSameError';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (newPassword !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  onChangePassword = () => {
    const { currentPassword, payLoad } = this.state;

    const { onChangePasswordHandler } = this.props;
    payLoad.callbacks[0].input[0].value = currentPassword;

    http(
      {
        url:
          '?noSession=true&ForceAuth=true&authIndexType=service&authIndexValue=UpdatePassword&goto=https%3A%2F%2Fopenam-deloitte-dev2.forgeblocks.com%2Fenduser%2F%3Frealm%3Dbravo%23%2Fprofile',
        data: payLoad,
        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status == 200) {
        this.setState({ payLoad: res.data, currentPasswordError: '' }, () => {
          this.updatePassword();
        });
      } else {
        this.setState({
          currentPasswordError: 'common.errors.currentPasswordError',
        });
      }
    });

    /*
    // let userId = localStorage.getItem('user_id');
    let userId = Cookies.get('user_id');
    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: [
          { operation: 'replace', field: '/password', value: newPassword },
        ],
      },
      {
        'X-OpenIDM-Reauth-Password': currentPassword,
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      const failedPolicyRequirements = getNestedObject(res, [
        'data',
        'detail',
        'failedPolicyRequirements',
      ]);
      if (failedPolicyRequirements) {
        if (failedPolicyRequirements.length > 0) {
          this.setState({
            errorArray: failedPolicyRequirements,
          });
          this.changePasswordPopupContainer.current.scrollTo({
            behavior: 'smooth',
            top: 0,
          });
        }
      } else if (res.status === 200 && res.data && res.data._id === userId) {
        const { getNotifications } = this.props;
        onChangePasswordHandler('success');
        this.setState({
          errorArray: '',
        });
        // let token = localStorage.getItem('dataStoreToken');
        // let id = localStorage.getItem('user_id');
        let token = Cookies.get('dataStoreToken');
        let id = Cookies.get('user_id');
        if (token || id) {
          getNotifications(id, token);
        }
      }
    });

    */
  };

  updatePassword = () => {
    const { newPassword, payLoad } = this.state;
    const { onChangePasswordHandler } = this.props;
    payLoad.callbacks[0].input[0].value = newPassword;

    http(
      {
        url:
          '?noSession=true&ForceAuth=true&authIndexType=service&authIndexValue=UpdatePassword',
        data: payLoad,
        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status == 200) {
        onChangePasswordHandler('success');
        this.setState({
          errorArray: '',
        });
      }
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div
          className="change-password-popup"
          ref={this.changePasswordPopupContainer}
        >
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">Change Password</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <Form.Group controlId="currentPassword">
                    <Form.Label>Current Password</Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState({
                          currentPassword: value,
                        });
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'currentPasswordError');
                      }}
                      password={currentPassword}
                      className={
                        currentPasswordError ? 'error-input' : undefined
                      }
                      autoFocus
                      aria-invalid="false"
                      aria-required="true"
                      myRef={this.currentPasswordInputRef}
                    />

                    <p
                      className="error-text forgot-error-username"
                      id="email-error"
                    >
                      {currentPasswordError && t('errors.currentPasswordError')}
                    </p>
                  </Form.Group>
                  <Form.Group controlId="newPassword">
                    <Form.Label>New Password</Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState({
                          newPassword: value,
                        });
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'newPasswordError');
                      }}
                      password={newPassword}
                      className={newPasswordError ? 'error-input' : undefined}
                      ariaInvalid={!!newPasswordError}
                      aria-required="true"
                      myRef={this.newPasswordInputRef}
                    />
                    <p
                      className="error-text forgot-error-username"
                      id="email-error"
                    >
                      {newPasswordError && t(newPasswordError)}
                    </p>
                  </Form.Group>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState(
                          {
                            confirmPassword: value,
                          },
                          () => {
                            this.isFormFieldValid(
                              value,
                              'confirmPasswordError'
                            );
                          }
                        );
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'confirmPasswordError');
                      }}
                      password={confirmPassword}
                      className={
                        confirmPasswordError ? 'error-input' : undefined
                      }
                      ariaInvalid={!!confirmPasswordError}
                      aria-required="true"
                      myRef={this.confirmPasswordInputRef}
                    />
                    <p
                      className="error-text forgot-error-username"
                      id="email-error"
                    >
                      {confirmPasswordError && t(confirmPasswordError)}
                    </p>
                  </Form.Group>
                  <PasswordRequirements
                    password={newPassword}
                    formSubmitted={false}
                  />
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.checkAllField(e)}
                  >
                    {t('pages.changePassword.changePassword')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ChangePasswordPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangePasswordHandler: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
};
export default withTranslation()(ChangePasswordPopup);
