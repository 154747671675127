import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const PasswordField = (props) => {
  const {
    password,
    className,
    autoFocus,
    ariaInvalid,
    ariaDescribedby,
    myRef,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const PasswordValidation = (e) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/)) {
      props.onChange(value);
    }
  };
  return (
    <div className="password-field position-relative">
      {ariaDescribedby ? (
        <Form.Control
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => PasswordValidation(e)}
          onBlur={(e) => {
            props.onBlur(e);
          }}
          className={className ? 'error-input' : undefined}
          autoFocus={autoFocus}
          aria-required
          aria-invalid={ariaInvalid}
          aria-describedby={ariaDescribedby}
          ref={myRef}
          autoComplete="off"
        />
      ) : (
        <Form.Control
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => PasswordValidation(e)}
          onBlur={(e) => {
            props.onBlur(e);
          }}
          className={className ? 'error-input' : undefined}
          autoFocus={autoFocus}
          aria-required
          autoComplete="off"
        />
      )}

      <span
        className={showPassword ? 'eye-icon show' : 'eye-icon'}
        onClick={() => setShowPassword(!showPassword)}
      />
    </div>
  );
};

PasswordField.propTypes = {
  password: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  ariaInvalid: PropTypes.bool,
  ariaDescribedby: PropTypes.string,
  myRef: PropTypes.object,
};
PasswordField.defaultProps = {
  password: '',
  className: '',
  autoFocus: false,
  ariaInvalid: false,
  ariaDescribedby: '',
  myRef: '',
};
export default PasswordField;
