import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import 'app/modules/Shared/Terms/Terms.scss';
import {
  faFacebook,
  faTwitter,
  faGooglePlusG,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ContactUs extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <h1 className="text-center">{t('pages.contactUs.title')}</h1>
        <hr className="separator-solid" />
        <Container className="terms">
          <Row>
            <Col lg={{ span: 12 }}>
              <div>
                <p>{t('pages.help.description')}</p>
              </div>
              <div>
                <p>{t('pages.help.help')}</p>
                <p>{t('pages.help.description')}</p>
              </div>
              <div>
                <p>{t('pages.help.sharePage')}</p>
              </div>
              <div className="ft-24">
                <FontAwesomeIcon icon={faFacebook} role="img" />
                <span className="pl-2">{t('pages.terms.facebook')}</span>
              </div>
              <div className="ft-24">
                <FontAwesomeIcon icon={faTwitter} role="img" />
                <span className="pl-2">{t('pages.terms.twitter')}</span>
              </div>
              <div className="ft-24 pb-3">
                <FontAwesomeIcon icon={faGooglePlusG} role="img" />
                <span className="pl-2">{t('pages.terms.googlePlus')}</span>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
ContactUs.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(ContactUs);
