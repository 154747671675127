import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api aegisCloud/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard AegisCloud/EditNamePopup.scss';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

class EditNamePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      errorText: '',
      errorArray: [],
      sn: props.sn ? props.sn : '',
    };
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { givenName, sn } = this.state;
    const hasGivenName = this.isFormFieldValid(givenName, 'givenNameError');
    const hasSn = this.isFormFieldValid(sn, 'snError');
    if (hasGivenName || hasSn) {
      this.showError('common.errors.required');
    } else {
      this.formSubmit();
      // const { onEditSuccessHandler } = this.props;
      // onEditSuccessHandler('success');
    }
  };

  getAPIHeaders = () => {
    // const dataStoreToken = localStorage.getItem('dataStoreToken');
    const dataStoreToken = Cookies.get('dataStoreToken');
    let bearer = 'Bearer ';
    return {
      Authorization: `${bearer}${dataStoreToken}`,
    };
  };

  formSubmit = () => {
    this.setState({
      errorText: '',
    });
    // const user_id = localStorage.getItem('user_id');
    const user_id = Cookies.get('user_id');
    let userId = user_id;
    const { givenName, sn } = this.state;
    const { onEditSuccessHandler } = this.props;

    const payload = [
      {
        operation: 'replace',
        field: '/givenName',
        value: givenName,
      },
      {
        operation: 'replace',
        field: '/sn',
        value: sn,
      },
    ];
    http(
      {
        url: `bravo_user/${user_id}`,
        method: 'PATCH',
        data: payload,
      },
      { ...this.getAPIHeaders() }
    ).then((res) => {
      if (res && res.status === 200 && res.data) {
        ReactGA.event({
          category: 'Name Update - AegisCloud',
          action: 'Name Update Complete: Name was updated successfully',
        });
        onEditSuccessHandler('success');
      } else {
        ReactGA.event({
          category: 'Name Update - AegisCloud',
          action: 'Name Update Complete: Name was not updated',
        });
      }
    });
  };

  onFirstAndLastNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z '`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  onMiddleNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z'`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;

    let errorMsg = '';
    switch (key) {
      case 'givenNameError':
        if (value.length <= 0) {
          errorMsg = 'First name is mandatory';
        }
        break;
      case 'snError':
        if (value.length <= 0) {
          errorMsg = 'Last name is mandatory';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  render() {
    const { onClose, t } = this.props;
    const { givenName, sn, givenNameError, snError } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="edit-name-pop change-password-popup">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">{t('pages.editName.title')}</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <Form.Group controlId="firstName">
                    <Form.Label>
                      {t('pages.editName.firstName')}
                      <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'givenName');
                      }}
                      value={givenName}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'givenNameError');
                      }}
                      className={givenNameError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!givenNameError}
                      aria-describedby="firstname-error"
                    />
                    <p className="error-text" id="firstname-error">
                      {givenNameError && t(givenNameError)}
                    </p>
                  </Form.Group>
                  {/* <Form.Group controlId="middleName">
                    <Form.Label>{t('pages.editName.middleName')}</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onMiddleNameChange(e, 'middleName');
                      }}
                      value={middleName}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'middleNameError');
                      }}
                      className={middleNameError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!middleNameError}
                      aria-describedby="firstname-error"
                    />
                    <p className="error-text" id="firstname-error">
                      {middleNameError && t(middleNameError)}
                    </p>
                  </Form.Group> */}
                  <Form.Group controlId="lastName">
                    <Form.Label>
                      {t('pages.editName.lastName')}
                      <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'sn');
                      }}
                      value={sn}
                      maxLength="80"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'snError');
                      }}
                      className={snError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!snError}
                      aria-describedby="lastname-error"
                    />
                    <p className="error-text" id="lastname-error">
                      {snError && t(snError)}
                    </p>
                  </Form.Group>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.onNext(e)}
                  >
                    {t('pages.editName.save')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

EditNamePopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditSuccessHandler: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
};
EditNamePopup.defaultProps = {
  givenName: '',
  sn: '',
};
export default withTranslation()(EditNamePopup);
