import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const Pending = (props) => {
  const { successMessage, username, emailVerify, t } = props;
  return (
    <div>
      <h2 className="mb-4">{t('pages.registration.approvalPending')}</h2>
      <div className="alert alert-success">
        <h3></h3>
        <p>
          {successMessage ? successMessage : ''} {username ? username : ''}
        </p>
        <p>{emailVerify ? emailVerify : ''}</p>
      </div>
    </div>
  );
};

Pending.propTypes = {
  props: PropTypes.any,
  t: PropTypes.func.isRequired,
  header: PropTypes.string,
  successMessage: PropTypes.string,
  emailVerify: PropTypes.string,
  username: PropTypes.string,
};

export default withTranslation()(Pending);
