import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard Cognito/EditEmailPopup.scss';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import { getAdminUser } from 'api cognito/aws4_module';
import { faEye, faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumber } from 'utils/common cognito';
import { aws_request } from 'api cognito/aws-request';

class AdminRoleChangePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      errorArray: [],
      username: '',
      screen: 1,
      userDetails: {},
      usernameError: '',
      adminInfo: '',
    };
  }

  getAdminUser = async (username) => {
    var output = await getAdminUser(username);

    if (output) {
      this.checkAdminUser(username);

      this.setState({
        userDetails: output,
        screen: 2,
      });
    } else {
      this.showError('errors.userExist');
    }
  };
  showError = (usernameError) => {
    this.setState({
      usernameError: usernameError,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    let { username } = this.state;

    this.getAdminUser(username);
  };

  checkAdminUser = (username) => {
    let customSet = (params) => {
      this.setState(params);
    };
    var params = {
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
      Username: username /* required */,
    };
    let cognitoidentityserviceprovider = aws_request();
    cognitoidentityserviceprovider.adminListGroupsForUser(
      params,
      function (err, data) {
        if (data.Groups.length > 0) {
          if (data.Groups[0].GroupName == 'Admin') {
            customSet({ adminInfo: 'pages.AdminRoleChange.adminInfo' });
          }
        }
      }
    );
  };

  addUserGroup = async (e) => {
    e.preventDefault();

    let { username } = this.state;
    const {
      onEditSuccessHandler,
      onClose,
      onErrorEmailSuccessHandler,
      listUsersInGroup,
    } = this.props;

    let addRoleParams = {
      GroupName: 'Admin',
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}`,
      Username: username,
    };

    let cognitoidentityserviceprovider = aws_request();
    let output = await this.addnewRole(
      addRoleParams,
      cognitoidentityserviceprovider
    );

    if (output) {
      onEditSuccessHandler('success');
      listUsersInGroup();
      onClose();
    } else {
      onErrorEmailSuccessHandler('failure');
    }
  };

  addnewRole = (params, cognitoidentityserviceprovider) => {
    return new Promise(function (resolve, reject) {
      cognitoidentityserviceprovider.adminAddUserToGroup(
        params,
        function (err, data) {
          if (err) {
            reject(false);
          }
          // an error occurred
          else {
            resolve(true);
          } // successful response
        }
      );
    });
  };
  render() {
    const { onClose, t } = this.props;
    const {
      screen,
      adminInfo,
      usernameError,
      username,
      userDetails,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="change-password-popup edit-email-pop">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {t('pages.AdminRoleChange.addAdmin')}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  {screen === 1 && (
                    <>
                      <Form.Group controlId="username">
                        <Form.Label>
                          {t('pages.AdminRoleChange.userName')}
                        </Form.Label>
                        <Form.Control
                          ref={this.emailAddressInputRef}
                          type="username"
                          value={username}
                          aria-required
                          className={usernameError ? 'error-input' : undefined}
                          aria-invalid={!!usernameError}
                          aria-describedby="username-error"
                          onChange={(e) => {
                            this.setState({
                              username: e.target.value.trim().toLowerCase(),
                            });
                          }}
                        />
                      </Form.Group>
                      <p className="error-text" id="email-error">
                        {usernameError && t(usernameError)}
                      </p>
                      <div className="mb-3 mt-3">
                        <Button
                          className="wrapper__container-card-body-submit mb-3"
                          size="lg"
                          variant="primary"
                          block
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                        >
                          {t('pages.Apps.searchBarAriaLabel')}
                        </Button>
                      </div>
                    </>
                  )}
                  {screen === 2 && (
                    <div className="personal-information">
                      <h5 className="pb-3">
                        {t('pages.AdminRoleChange.userInfo')}
                      </h5>
                      <div className="personal-information__container">
                        <div className="col-sm-4 bold">
                          {t('pages.personalInformation.fullName')}
                        </div>

                        <div className="col-sm-6">
                          {userDetails.givenName} {userDetails.sn}
                        </div>
                      </div>
                      <div className="personal-information__container">
                        <div className="col-sm-4 bold">
                          {t('pages.AdminRoleChange.userName')}
                        </div>

                        <div className="col-sm-6">{userDetails.userName}</div>
                      </div>
                      <div className="personal-information__container">
                        <div className="col-sm-4 bold">
                          {t('pages.personalInformation.Email')}
                        </div>
                        <div className="col-sm-5 text-left">
                          {userDetails.mail}
                        </div>
                      </div>
                      <div className="personal-information__container">
                        <div className="col-sm-4 bold">
                          {t('pages.personalInformation.PhoneNumber')}
                        </div>
                        <div className="col-sm-5 text-left">
                          {this.state.phoneNumberHidden
                            ? this.codeText(
                                t('pages.personalInformation.Phone')
                              )
                            : userDetails.UserAttributes[7].Value
                            ? `${formatPhoneNumber(
                                userDetails.UserAttributes[7].Value.replace(
                                  '+1',
                                  ''
                                )
                              )}`
                            : ''}
                        </div>
                      </div>
                      <p
                        className="error-text"
                        id="email-error"
                        style={{ marginTop: '10px' }}
                      >
                        {adminInfo && username + ' ' + t(adminInfo)}
                      </p>
                      <div className="mb-3 mt-3">
                        <Button
                          className="wrapper__container-card-body-submit mb-3"
                          size="lg"
                          variant="primary"
                          block
                          type="submit"
                          disabled={adminInfo}
                          onClick={(e) => this.addUserGroup(e)}
                        >
                          {t('pages.AdminRoleChange.addToGroup')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

AdminRoleChangePopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditSuccessHandler: PropTypes.func.isRequired,
  onErrorEmailSuccessHandler: PropTypes.func.isRequired,
  listUsersInGroup: PropTypes.func.isRequired,
};

export default withTranslation()(AdminRoleChangePopup);
