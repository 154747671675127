import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Col, Button, Row, Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/ChangePassword.scss';
import ApproveDetailsPopup from 'app/modules/Dashboard/ApproveDetailsPopup';
import { withTranslation } from 'react-i18next';

import http from 'api/http-request';
import i18n from 'i18n';
import 'app/modules/Dashboard/MyTasks.scss';
import {
  faPenAlt,
  faSearch,
  faTabletAlt,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'app/modules/Shared/Alert/Alert';
import Cookies from 'js-cookie';
import Table from 'react-bootstrap/Table';

class MyTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: '',
      alertStatus: '',
      showPasswordPopup: false,
      industryEmail: false,
      industrySMS: false,
      weeklyEmail: false,
      weeklySMS: false,
      announcementEmail: false,
      announcementSMS: false,
      profileUpdateEmail: false,
      profileUpdateSMS: false,
      securityNotificationEmail: false,
      securityNotificationSMS: false,
      walletUpdateEmail: false,
      walletUpdateSMS: false,
      preferredLanguage: 'en',
      myTask: '',
      businessApprovalList: [],
      businessRejectedList: [],
      showEditPopup: false,
      screen: 1,
      listIndex: null,
      myAssignedTask: '',
      userList: '',
      addAssign: null,
      approverList: [],
      adminDetail: this.props.adminDetail ? this.props.adminDetail : undefined,
      roles: this.props.adminDetail ? this.props.adminDetail : [],
      adminRoles: this.props.adminRoles
        ? this.props.adminRoles
        : [
            {
              _ref: '',
            },
          ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { adminDetail } = this.props;

    if (!adminDetail) {
      this.getAllRoles();
    }

    this.getAllTasks();

    if (this.state.adminDetail) {
      this.getAproverList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { taskList } = this.state;

    if (
      prevProps.adminDetail &&
      prevProps.adminDetail.name != this.props.adminDetail.name
    ) {
      this.setState({ adminDetail: this.props.adminDetail }, () => {
        this.getAproverList();
      });
      let requiredDepartment = this.props.adminDetail.name.replace('Admin', '');
      if (requiredDepartment != 'All') {
        let assignedTask = taskList.filter((obj) => {
          let department = obj.tasks[0].variables.department;

          if (department == requiredDepartment) {
            return obj;
          }
        });

        this.setState({
          myAssignedTask: assignedTask,
        });
      } else {
        this.setState({
          myAssignedTask: taskList,
        });
      }
    } else if (
      this.state.adminDetail &&
      prevState.adminDetail != this.state.adminDetail
    ) {
      this.getAproverList();
    }
  }
  getAllRoles = () => {
    let username = Cookies.get('username');

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      userName: username,
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http({
      url: 'getUserRoles',
      method: 'POST',
      data: data,
    }).then((res) => {
      /*if (
        res.status == 401 &&
        res.data.iamcode == 'IAM0028' &&
        res.data.response == 'Access Denied'
      ) {
        Cookies.remove('userName');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';
      }*/

      let data = res.data.data.result;

      let adminRoles = data.filter((obj) => {
        return obj.name.includes('Admin');
      });

      this.setState({
        roles: adminRoles,
        adminDetail: adminRoles[0],
        key: adminRoles[0].name,
      });
    });
  };
  getAproverList = () => {
    let { adminDetail } = this.state;
    let str = adminDetail._ref.replace('internal/role/', '');

    http(
      {
        url: `getUsersFromRoles/${str}`,
        method: 'POST',
        data: {
          iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
        },
      },
      {
        'Content-Type': `application/json`,
        Accept: `application/json, text/plain, */*`,
      }
    ).then((res) => {
      if (res.status == 200 && 'result' in res.data.data) {
        this.setState({ approverList: res.data.data.result });
      } else if (res.status == 401) {
        //window.location.href = '/';
      }
    });
  };

  getAllTasks = () => {
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http({
      url: `assignee`,
      method: 'POST',
      data: data,
    }).then((res) => {
      let data = res.data.data;

      if (res.status == 200 && 'result' in data) {
        let objList = Object.values(data.result[0]);
        let { adminDetail } = this.props;

        let requiredDepartment = adminDetail.name.replace('Admin', '');

        if (requiredDepartment != 'All') {
          let taskList = objList.filter((obj) => {
            let department = obj.tasks[0].variables.department;

            if (department == requiredDepartment) {
              return obj;
            }
          });
          this.setState({
            taskList: objList,
            myAssignedTask: taskList,
          });
        } else {
          this.setState({
            taskList: objList,
            myAssignedTask: objList,
          });
        }
      } else if (res.status == 401) {
        //window.location.href = '/';
      }
    });
  };

  onEditSuccessHandler = (status) => {
    let alertText = 'pages.myTasks.taskApproved';
    if (status === 'success') {
      alertText = 'pages.myTasks.taskApproved';
    } else if (status === 'assigned') {
      alertText = 'pages.myTasks.taskAssigned';
    } else {
      alertText = 'pages.myTasks.taskDenied';
    }

    this.setState(
      {
        showEditPopup: false,
        alertStatus:
          status == 'assigned' || status == 'success' ? 'success' : 'error',
        alertText: alertText,
      },
      () => {
        this.getAllTasks();
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };
  showEditPopup = (index, assignee) => {
    this.setState({
      showEditPopup: true,
      listIndex: index,
      addAssign: assignee,
    });
  };
  setFilerKeyword = (e) => {
    let searchString = e.target.value;

    let { taskList } = this.state;
    this.setState({ searchString: searchString });
    let assignedTask = taskList.filter((obj) => {
      let department = obj.tasks[0].variables.department;

      if (department.toLowerCase().startsWith(searchString.toLowerCase())) {
        return obj;
      }
    });

    this.setState({
      myAssignedTask: assignedTask,
    });
  };

  render() {
    const { t } = this.props;
    const {
      myTask,
      showEditPopup,
      alertText,
      alertStatus,
      screen,
      listIndex,
      businessApprovalList,
      businessRejectedList,
      myAssignedTask,
      addAssign,
      approverList,
      adminDetail,
    } = this.state;

    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        {showEditPopup && (
          <ApproveDetailsPopup
            onClose={() => {
              this.setState({ showEditPopup: false });
            }}
            listIndex={listIndex}
            onEditSuccessHandler={this.onEditSuccessHandler}
            myTask={myAssignedTask}
            approverList={approverList}
            addAssign={addAssign}
          />
        )}

        <div className="my-tasks" style={{ 'margin-left': '0px' }}>
          {screen === 1 && (
            <>
              <div>
                <h1 className="pb-3">{t('pages.myTasks.title')}</h1>

                {myAssignedTask ? (
                  <div>
                    {adminDetail && adminDetail.name == 'AllAdmin' && (
                      <div className="row mb-5 align-items-center">
                        <div className="col-md-5 mb-3 mb-sm-0">
                          <h3>{t('pages.myTasks.searchOrganization')}</h3>
                          <div className="input-group rounded">
                            <input
                              type="search"
                              className="form-control rounded"
                              placeholder={t(
                                'pages.myTasks.searchOrganization'
                              )}
                              aria-label={t('pages.myTasks.searchOrganization')}
                              aria-describedby="search-addon"
                              onChange={this.setFilerKeyword}
                            />
                            <span
                              className="input-group-text border-0"
                              id="search-addon"
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t('pages.registration.firstName')}</th>
                          <th>{t('pages.registration.lastName')}</th>
                          <th>{t('pages.registration.userName')}</th>
                          <th style={{ 'white-space': 'nowrap' }}>
                            {t('pages.registration.businessName')}
                          </th>
                          <th style={{ 'white-space': 'nowrap' }}>
                            {t('pages.registration.businessRole')}
                          </th>
                          <th>{t('pages.myTasks.assignee')}</th>
                          <th>{t('pages.registration.action')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myAssignedTask.map((obj, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{obj.tasks[0].variables.givenName}</td>
                              <td>{obj.tasks[0].variables.sn}</td>
                              <td>{obj.tasks[0].variables.userName}</td>
                              <td>{obj.tasks[0].variables.department}</td>
                              <td>{obj.tasks[0].variables.jobTitle}</td>
                              <td>
                                {obj.tasks[0].assignee === null
                                  ? t('pages.registration.unAssignee')
                                  : obj.tasks[0].assignee}
                              </td>
                              <td>
                                <div
                                  className="col-sm-2 text-right"
                                  onClick={() => {
                                    this.showEditPopup(
                                      index,
                                      obj.tasks[0].assignee
                                    );
                                  }}
                                  role="button"
                                  style={{ 'padding-left': '2rem' }}
                                  tabIndex={0}
                                  onKeyPress={() => {
                                    this.showEditPopup(
                                      index,
                                      obj.tasks[0].assignee
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPenAlt} />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="no-devices mb-3">
                    {t('pages.myTasks.noTasks')}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
MyTasks.propTypes = {
  t: PropTypes.func.isRequired,
  adminDetail: PropTypes.object.isRequired,
  adminRoles: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
};
export default withTranslation()(MyTasks);
