import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/ForgotPassword/ForgotPassword.scss';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import { Col } from 'react-bootstrap';
import http from 'api aegisCloud/http-request';
import {
  getNestedObject,
  isPasswordFormatValid,
  pageNotFound,
} from 'utils/common';
import Radio from 'app/modules/Shared/Radio/Radio';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import Alert from 'app/modules/Shared/Alert/Alert';
import WithError from '../Shared/WithError/WithError';
import ReactGA from 'react-ga';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.state = {
      mail: '',
      emailError: '',
      stepNames: [ 
        'pages.formNavigator.lookup',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification'
      ],
      maxStep: 2,
      screen: 0,
      currentStep: 0,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      token: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      responseToken: '',
      userName: '',
      verificationPlaceholder: ['0', '0', '0', '0', '0', '0'],
      securityQuestion1: '',
      securityQuestion2: '',
      securityQuestion3: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      errorText: '',
      passwordErrorText: '',
      errorArray: [],
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      changeMethod: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      securityQuestionError: '',
      userExist: '',
      radioOptionError: '',
      payLoad: {},
      userOption: [],
    };
  }
  componentDidMount() {
    this.getToken();
    window.scrollTo(0, 0);
  }
  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  getToken = () => {
    http(
      {
        url: 'ResetPassword',

        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.setState({ payLoad: res.data });
    });
  };

  isEmailValid = (value) => {
    if (
      value.length <= 0 ||
      !value.match(
        /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
      )
    ) {
      this.setState({
        emailError: true,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
  };

  disableCopyPaste = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };
  onRecoverPassword = (e) => {
    e.preventDefault();
    const { mail, emailError } = this.state;
    if (mail === '' || emailError) {
      this.setState({
        emailError: true,
      });
      this.showError('A valid email address is required');
    } else if (mail !== '' && !emailError) {
      this.getForgotUserToken();
      this.setState({
        errorText: '',
      });
    }
  };

  getForgotUserToken = () => {
    const { mail, payLoad } = this.state;

    payLoad.callbacks[0].input[0].value = mail;
    http(
      {
        url: 'ResetPassword',
        data: payLoad,
        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.genericResponse(res);
    });
  };

  genericResponse = (res) => {
    this.scrollToTop();
    if (getNestedObject(res, ['data', 'callbacks'])) {
      const {
        data: { callbacks },
      } = res;
      if (callbacks) {
        if (callbacks[0].type === 'ReCaptchaCallback') {
          const recaptchaSiteKey = res.data.callbacks[0].output[0].value;
          if (recaptchaSiteKey) {
            this.setState({
              showGoogleCaptcha: true,
              googleCaptchaToken: recaptchaSiteKey,
              screen: 1,
              payLoad: res.data,
            });
            this.increaseCurrentStepHandler();
          }
        } else if (callbacks[0].type === 'SuspendedTextOutputCallback') {
          this.setState({
            currentStep: 2,
            screen: 5,
            payLoad: res.data,
          });
        } else if (callbacks[0].type === 'ChoiceCallback') {
          this.increaseCurrentStepHandler();
          this.setState({
            payLoad: res.data,
            screen: 2,
            userOption: res.data.callbacks[0].output,
          });
        } else if (callbacks[0].type === 'PasswordCallback') {
          if (res.data.callbacks[0].output[0].value == 'One Time Password') {
            this.setState({
              payLoad: res.data,
              screen: 3,
              verificationOption: res.data.callbacks[0].output[0].value,
            });
          } else {
            this.setState({
              payLoad: res.data,
              screen: 6,
              verificationOption: res.data.callbacks[0].output[0].value,
            });
          }
        } else if (callbacks[0].type === 'ValidatedCreatePasswordCallback') {
          this.setState({
            payLoad: res.data,
            screen: 4,
          });
        }
      }
    }
  };
  increaseCurrentStepHandler = () => {
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  decreaseCurrentStepHandler = (e) => {
    e.preventDefault();
    this.setState({
      userExist: false,
    });
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };

  sendToLogin = () => {
    return this.props.history.push('/');
  };

  onCaptchaChange = (value) => {
    if (value) {
      this.setState(
        {
          googleCaptchaResponse: value,
        },
        () => {
          this.forgotPasswordSubmit();
        }
      );
    } else {
      this.setState({
        googleCaptchaResponse: '',
      });
    }
  };

  forgotPasswordSubmit = () => {
    const { googleCaptchaResponse, payLoad, googleCaptchaToken } = this.state;
    payLoad.callbacks[0].input[0].value = googleCaptchaResponse;
    payLoad.callbacks[0].output[0].value = googleCaptchaToken;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'ResetPassword',
        data: payLoad,
        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.genericResponse(res);
    });
  };

  securityQuestionHandler = (sec1, sec2, sec3) => {
    const { responseToken } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'selfservice/reset?_action=submitRequirements',
        data: {
          input: {
            answer1: sec1,
            answer2: sec2,
            answer3: sec3,
          },
          token: responseToken,
        },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (
        res.status === 200 &&
        res.data.type === 'resetStage' &&
        res.data.tag === 'initial'
      ) {
        this.setState({
          responseToken: res.data.token,
        });
        this.increaseCurrentStepHandler();
        this.setState({
          screen: 3,
        });
      }
    });
  };
  onnextBtn = () => {
    //this.increaseCurrentStepHandler();
    this.setState({
      screen: 4,
    });
  };
  submitNewPassword = (e) => {
    e.preventDefault();
    const { password, confirmPassword, payLoad } = this.state;
    const hasPassword = this.isFormFieldValid(password, 'passwordError');
    const hasConfirmPassword = this.isFormFieldValid(
      confirmPassword,
      'confirmPasswordError'
    );
    if (hasPassword || hasConfirmPassword) {
      this.showError('errors.required');
    } else {
      payLoad.callbacks[0].input[0].value = confirmPassword;
      http(
        {
          url: 'ResetPassword',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        ReactGA.event({
          category: 'Forgot Password - AegisCloud',
          action: 'Forgot Password: Password Updated',
        });
        this.genericResponse(res);
      });
    }
  };
  onSubmit = () => {
    this.increaseCurrentStepHandler();
    this.setState({
      screen: 2,
    });
  };

  onOptionsChange = (value) => {
    if (value.includes('Email')) {
      value = 'userEmail';
    } else if (value.includes('Security')) {
      value = 'kba';
    } else {
      value = 'userPhone';
    }
    this.setState({
      selectedOption: value,
    });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'passwordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (password !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  handleVerifyChange = (vals) => {
    this.setState({
      verificationCode: vals,
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { payLoad, verificationCode } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      payLoad.callbacks[0].input[0].value = verificationCode;
      //this.verificationInputRef.current.__clearvalues__();
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: 'ResetPassword',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data) {
          ReactGA.event({
            category: 'Forgot Password - AegisCloud',
            action: 'Forgot Password: OTP Verified',
          });
          this.genericResponse(res);
        } else if (
          res.status === 401 &&
          res.data &&
          res.data.reason === 'Unauthorized'
        ) {
          this.setState({
            verificationCodeError: 'pages.forgotUserId.invalidVerificationCode',
          });
        }
      });
    }
  };

  onVerifyQuestion = (e) => {
    e.preventDefault();
    const { payLoad, securityQuestionValue } = this.state;
    if (securityQuestionValue === '') {
      this.setState({
        securityQuestionError: 'pages.forgotUserId.securityQuestionRequired',
      });
    } else {
      payLoad.callbacks[0].input[0].value = securityQuestionValue;
      http(
        {
          url: 'ResetPassword',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data) {
          this.genericResponse(res);
        } else if (
          res.status === 401 &&
          res.data &&
          res.data.reason === 'Unauthorized'
        ) {
          this.setState({
            securityQuestionError: 'pages.forgotUserId.invalidSecurityAnswer',
          });
        }
      });
    }
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    const { responseToken } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'selfservice/reset?_action=submitRequirements',
        data: {
          input: {
            resend: 'true',
          },
          token: responseToken,
        },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (res.status === 200 && res.data) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
            verificationCode: '',
            verificationCodeError: '',
          });
        }
      } else if (
        res.status === 400 &&
        res.data &&
        res.data.code === 400 &&
        res.data.message === 'MAX_RESEND_EXCEEDED'
      ) {
        this.setState({
          screen: 0,
          currentStep: 0,
          verificationOption: '',
          userEmail: '',
          userPhone: '',
          selectedOption: '',
          verificationCodeError: '',
          verificationCode: '',
          kba: '',
          securityOption: '',
          securityQuestionValue: '',
          securityQuestionError: '',
          userExist: false,
          radioOptionError: '',
        });
        this.onErrorHandler('error', res.data.message);
      }
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { payLoad, selectedOption } = this.state;
    if (selectedOption === 'userEmail') {
      payLoad.callbacks[0].input[0].value = 0;
    } else if (selectedOption === 'kba') {
      payLoad.callbacks[0].input[0].value = 1;
    }
    this.setState({
      errorText: '',
    });
    if (selectedOption) {
      http(
        {
          url: 'ResetPassword',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        this.genericResponse(res);
      });
    } else {
      this.setState({
        radioOptionError: 'pages.forgotUserId.radioCodeRequired',
      });
    }
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.getToken();
    this.setState({
      screen: 0,
      currentStep: 0,
      verificationOption: '',
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      verificationCodeError: '',
      verificationCode: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      securityQuestionError: '',
      userExist: false,
      radioOptionError: '',
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  handleQuestionChange(evt) {
    const securityQuestionValue = evt.target.validity.valid
      ? evt.target.value
      : this.state.securityQuestionValue;

    this.setState({ securityQuestionValue });
  }

  render() {
    const {
      mail,
      emailError,
      currentStep,
      maxStep,
      stepNames,
      screen,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
      userName,
      selectedOption,
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      verificationOption,
      verificationCodeError,
      userEmail,
      userPhone,
      kba,
      securityOption,
      securityQuestionError,
      userExist,
      verificationCode,
      alertText,
      alertStatus,
      radioOptionError,
      passwordErrorText,
      errorArray,
      userOption,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="container forgot-username forgot-password my-5">
          {(passwordErrorText || errorArray.length > 0) && (
            <WithError errors={passwordErrorText} errorArray={errorArray} />
          )}
          <div className="col">
            <h1>{t('pages.forgotPassword.header1ForgotPassword')}</h1>
          </div>
          <div>
            <FormNavigationBar
              currentStep={currentStep}
              numFormSteps={maxStep + 1}
              stepNames={stepNames}
              t={t}
            />
          </div>
          {currentStep === 0 && screen === 0 && (
            <>
              <div className="col">
                <h3 className="m-0">
                  {t('pages.forgotPassword.instructions')}
                </h3>
              </div>
              <Form className="col-md-6 mt-3 pt-4">
                <Form.Group controlId="loginEmail">
                  <Form.Label>
                    {t('pages.forgotUserId.emailAddress')}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      this.setState({
                        mail: e.target.value.trim().toLowerCase(),
                      });
                      this.isEmailValid(e.target.value.trim().toLowerCase());
                    }}
                    value={mail}
                    className={
                      emailError || userExist ? 'error-input' : undefined
                    }
                    aria-required
                    aria-invalid={!!emailError}
                    aria-describedby="email-error"
                  />
                  <p
                    className="error-text forgot-error-username"
                    id="email-error"
                  >
                    {emailError && t('errors.emailError')}
                    {userExist && t('errors.userExist')}
                  </p>
                </Form.Group>
                <div className="row mt-5">
                  <div className="d-flex col-md-6">
                    <Button
                      className="wrapper__container-card-body-submit"
                      size="lg"
                      variant="secondary"
                      block
                      type="submit"
                      onClick={(e) => this.onRecoverPassword(e)}
                    >
                      {t('pages.forgotPassword.nextBtn')}
                    </Button>
                  </div>
                </div>
                <div>
                  <p className="return-text mt-4">
                    {t('pages.forgotUserId.returnTo')}
                    <span className="bgColor">
                      <a
                        href="#"
                        id="registerPolicy"
                        className="pl-2"
                        onClick={(e) => this.decreaseCurrentStepHandler(e)}
                      >
                        {t('pages.forgotUserId.loginBtn')}
                      </a>
                    </span>
                  </p>
                </div>
              </Form>
            </>
          )}
          {currentStep === 1 && showGoogleCaptcha && screen === 1 && (
            <>
              <div className="col">
                <h2 className="mb-4">
                  {t('pages.registration.fillOutCaptcha')}
                </h2>
              </div>
              <div className="col-md-6 mt-3 mb-9">
                <div className="google-captcha">
                  <div className="google-captcha__box">
                    {googleCaptchaToken && (
                      <GoogleCaptcha
                        sitekey={googleCaptchaToken}
                        onCaptchaChange={this.onCaptchaChange}
                        captchaReset={captchaReset}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-sm-flex col-lg-4 col-md-12">
                <button
                  className="col btn btn-secondary hollow mr-sm-4  py-2 my-3"
                  size="lg"
                  variant="secondary"
                  block
                  type="submit"
                  onClick={(e) => this.decreaseCurrentStepHandler(e)}
                >
                  {t('pages.forgotUserId.backBtn')}
                </button>
              </div>
            </>
          )}
          {currentStep === 2 && screen === 2 && userOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{userOption[0].value}</span>
              </h3>
              <Form className="radio-form">
                <Radio
                  key={'Email'}
                  name={'Email'}
                  label={`${userOption[1].value[0]}`}
                  value={'Email'}
                  isChecked={selectedOption === 'userEmail' ? true : false}
                  handleChange={this.onOptionsChange}
                />
                <Radio
                  key={'kba'}
                  name={'Email'}
                  label={`${userOption[1].value[1]}`}
                  value={'Security'}
                  isChecked={selectedOption === 'kba' ? true : false}
                  handleChange={this.onOptionsChange}
                />
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onNext(e)}
                      disabled={selectedOption === ''}
                    >
                      {t('pages.forgotUserId.nextBtn')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 3 && verificationOption && (
            <div className="col-md-12 registration__container">
              <div className="registration__container__resend-verification ml-0">
                <div className="registration__container__resend-verification__resend-text">
                  <h3>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {verificationOption}
                  </h3>
                </div>
              </div>
              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className={`ml-3 verification-input ${
                      verificationCodeError ? 'error-verification' : undefined
                    }`}
                    value={verificationCode}
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__resend-verification__resend-text">
                  <p
                    className="resend-link"
                    onClick={(e) => this.resendVerify(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
                <div
                  id="pease_note"
                  className="registration__container__pleasenote"
                >
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onVerify(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 6 && verificationOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.forgotUserId.securityQuestion')}</span>
              </h3>
              <Form className="col-md-6 mt-3 pt-4 pl-0">
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">{verificationOption}</Form.Label>
                  <Form.Control
                    type="text"
                    onInput={this.handleQuestionChange.bind(this)}
                  />
                  {securityQuestionError && (
                    <p className="error-text" id="verification-error">
                      {securityQuestionError && t(securityQuestionError)}
                    </p>
                  )}
                </Form.Group>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-12 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      // onClick={this.decreaseCurrentStepHandler}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onVerifyQuestion(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 4 && (
            <>
              <Col className="pr-2" lg={{ span: 6 }}>
                <Form.Group controlId="passWord">
                  <Form.Label>{t('pages.registration.password')}</Form.Label>
                  <PasswordField
                    onChange={(value) => {
                      this.setState({
                        password: value,
                      });
                    }}
                    onBlur={(e) => {
                      this.isFormFieldValid(e, 'passwordError');
                    }}
                    password={password}
                    className={passwordError ? 'error-input' : undefined}
                    ariaInvalid={!!passwordError}
                    ariaDescribedby="new-password-error"
                    myRef={this.passwordInputRef}
                    onCopy={(e) => {
                      this.disableCopyPaste(e);
                    }}
                    onPaste={(e) => {
                      this.disableCopyPaste(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="pr-2" lg={{ span: 6 }}>
                <Form.Group controlId="confirmPassWord">
                  <Form.Label>
                    {t('pages.registration.confirmPassword')}
                  </Form.Label>
                  <PasswordField
                    onChange={(value) => {
                      this.setState(
                        {
                          confirmPassword: value,
                        },
                        () => {
                          this.isFormFieldValid(value, 'confirmPasswordError');
                        }
                      );
                    }}
                    onBlur={(e) => {
                      this.isFormFieldValid(e, 'confirmPasswordError');
                    }}
                    password={confirmPassword}
                    className={confirmPasswordError ? 'error-input' : undefined}
                    ariaInvalid={!!confirmPasswordError}
                    ariaDescribedby="confirm-password-error"
                    myRef={this.confirmPasswordInputRef}
                    onCopy={(e) => {
                      this.disableCopyPaste(e);
                    }}
                    onPaste={(e) => {
                      this.disableCopyPaste(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <div className="pl-1 pt-2">
                <PasswordRequirements
                  password={password}
                  formSubmitted={false}
                />
              </div>
              <div className="row pt-3">
                <div className="d-sm-flex col-lg-6 col-md-12">
                  <button
                    className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                    onClick={(e) => this.onBackLogin(e)}
                  >
                    {t('pages.forgotPassword.backBtn')}
                  </button>
                  <button
                    className="col btn btn-secondary  py-2 my-3"
                    onClick={(e) => this.submitNewPassword(e)}
                  >
                    {t('pages.forgotPassword.nextBtn')}
                  </button>
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && screen === 5 && (
            <>
              <SuccessConfirmation
                header={t('pages.forgotUserId.successBannerHeader')}
                successMessage={t('pages.forgotPassword.password')}
                username={mail}
              />
              <div className="row mt-5">
                <div className="d-sm-flex col-lg-3 col-md-12">
                  <Button
                    className="col btn btn-secondary py-2 my-3"
                    size="lg"
                    variant="secondary"
                    block
                    type="submit"
                    onClick={(e) => this.sendToLogin(e)}
                  >
                    {t('pages.forgotUserId.loginBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
ForgotPassword.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(ForgotPassword);
