import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Radio(props) {
  const { name, label, value, isChecked, disabled, handleChange } = props;
  const { t } = useTranslation();

  const handleKeyPress = (e) => {
    e.preventDefault();
    const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
    if (charCode === 13) {
      handleChange(value);
    }
  };

  return (
    <Form.Check
      key={name}
      type="radio"
      className="form-check-radio"
      label={t(label)}
    >
      <Form.Check.Input
        type="radio"
        tabIndex={0}
        onClick={() => handleChange(value)}
        onKeyPress={handleKeyPress}
        name={name}
        id={name}
        checked={isChecked}
        disabled={disabled}
        onChange={() => handleChange(value)}
        role="radio"
        aria-checked="false"
        aria-label={t(label)}
      />
      <span className="after-input" />
      <Form.Check.Label className="form-check-radio-label">
        {t(label)}
      </Form.Check.Label>
    </Form.Check>
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  disabled: false,
};

export default Radio;
