import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/IdProofing/IdProofing.scss';
import 'app/modules/ForgotPassword/ForgotPassword.scss';
import 'app/modules/IdProofing/IdproofingSocure.scss';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import http from 'api/http-request';

class IdProofingSocure extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const script = document.createElement('script');
    script.src = 'https://websdk.socure.com/bundle.js';
    script.onload = () => this.idProofingSessionCreation();
    document.body.appendChild(script);
  }

  idProofingSessionCreation = () => {
    console.log('Inside');
    function onProgress(progress) {
      console.log(progress);
    }
    function onSuccess(response) {
      var uUid = '';
      if (Cookies.get('lastName') === 'Docvnegative') {
        uUid = '2222-4444';
      } else {
        uUid = response.documentUuid;
      }

      fetch('https://sandbox.socure.com/api/3.0/EmailAuthScore', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'SocureApiKey f309518e-3289-4e36-bd17-c405d8811fb8',
        },
        body: JSON.stringify({
          documentUuid: uUid,
          modules: ['documentverification'],
          firstName: 'Anant',
          surName: 'Mishra',
          fullName: 'Anant Mishra',
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.documentVerification.decision.value === 'reject') {
            const user_id = Cookies.get('user_id');
            const dataStoreToken = Cookies.get('dataStoreToken');
            let bearer = 'Bearer ';
            let userId = user_id;
            let idProofignStatusValue = Cookies.get('idProofingStatus');

            if (
              idProofignStatusValue === '1' ||
              idProofignStatusValue === '-1'
            ) {
              idProofignStatusValue = '-2';
            } else if (idProofignStatusValue === '-2') {
              idProofignStatusValue = '-3';
            } else if (idProofignStatusValue === '-3') {
              idProofignStatusValue = '-4';
            }
            const payload = [
              {
                operation: 'add',
                field: '/idProofing',
                value: '-1',
              },
            ];
            http(
              {
                url: `updateSelfProfile/${userId}`,
                method: 'PATCH',
                data: payload,
              },
              {
                'X-OpenIDM-NoSession': false,
                'X-OpenIDM-OAuth-Login': true,
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: `${bearer}${dataStoreToken}`,
              }
            ).then((res) => {
              if (res && res.status === 200 && res.data) {
                Cookies.set('idProofingStatus', idProofignStatusValue);
                window.location.href = '/idproofing-failed';
              } else if (res.status === 401) {
                this.showError('pages.idproofing.sessionExpiry');
                setTimeout(() => {
                  window.location.href = '/';
                }, 3000);
              }
            });
          } else if (data.documentVerification.decision.value === 'accept') {
            Cookies.set('idProofingStatus', '2');
            window.location.href = '/idproofing-completed';
          }
        })
        .catch((error) => console.log('ERROR - ', error));
    }

    function onError(error) {
      console.log(error);
    }

    var config = {
      onProgress: onProgress,
      onSuccess: onSuccess,
      onError: onError,
      qrCodeNeeded: true,
    };

    SocureInitializer.init('cfe973a2-86d4-4a8a-a56d-f96d53f29f8f').then(
      (lib) => {
        lib
          .init('cfe973a2-86d4-4a8a-a56d-f96d53f29f8f', '#websdk', config)
          .then(function () {
            lib.start(1);
          });
      }
    );
  };

  render() {
    const {t} = this.props;
    return (
      <>
        <div className="id-proofing-login container forgot-username forgot-password my-5">
          <div>
            <h2>{t('pages.idproofing.title')}</h2>
          </div>
          <div id="websdk" className="socureContainer"></div>
          <h6
            className="pt-5"
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: '700',
            }}
          >
            {t('pages.idproofing.note')}
          </h6>
        </div>
      </>
    );
  }
}

IdProofingSocure.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(IdProofingSocure);
