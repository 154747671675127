import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loginAnimation from 'assets/json/login_idProofing.json';
import licenseAnimation from 'assets/json/licensePlate.json';
import securityAnimation from 'assets/json/security.json';
import failedAnimation from 'assets/json/idProofing_failed.json';
import dotAnimation from 'assets/json/threeDot.json';
import { Button } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import PropTypes from 'prop-types';
import Alert from 'app/modules/Shared/Alert/Alert';
import http from 'api/http-request';
import Cookies from 'js-cookie';

class IdProofingFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertStatus: '',
      alertText: '',
      errorText: '',
      errorArray: [],
      finish: {
        loop: true,
        autoplay: true,
        animationData: failedAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onRetry = (e) =>{
    console.log('ID Proofing Retry');
    window.location.href = '/idproofing-landing';
  };

  render() {
    const { t } = this.props;
    const { finish, errorText, errorArray } = this.state;

    return (
      <>
        {(errorText || errorArray.length > 0) && (
          <div className="error-div">
            <WithError errors={errorText} errorArray={errorArray} />
          </div>
        )}
        <div className="id-proofing-login container forgot-username forgot-password my-5">
          <div className="col">
            <h2>{t('pages.idproofing.title')}</h2>
          </div>
          <div>
            <div className="container">
              {/* <h2>{t('pages.idproofing.finish')}</h2> */}
              <h4 className="pt-5" style={{ textAlign: 'center' }}>
                {t('pages.idproofing.failedhDesc')}
              </h4>
              <div className="image mt-5 mb-3 mx-5">
                <Lottie options={finish} height={180} width={180}/>
              </div>
              <div className="row pt-5 d-flex justify-content-center">
                <div className="d-sm-flex col-lg-6 col-md-12">
                  <div class="buttonWrapper-idproofing">
                    <Button
                      className="startButton-idproofing"
                      type="submit"
                      onClick={(e) => this.onRetry(e)}
                    >
                      {t('pages.idproofing.retryBtn')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6
            className="pt-5"
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: '700',
            }}
          >
            {t('pages.idproofing.note2')}
          </h6>
        </div>
      </>
    );
  }
}

IdProofingFailed.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(IdProofingFailed);
