import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api cognito/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/EditNamePopup.scss';
import Cookies from 'js-cookie';
import WithError from '../Shared/WithError/WithError';
import validator from 'validator';
import { getNestedObject } from 'utils/common cognito';

class AdminPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appName: '',
      appLink: '',
      appDescription: '',
      errorText: '',
      errorArray: [],
    };
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { appName, appLink } = this.state;
    const hasGivenName = this.isFormFieldValid(appName, 'appNameError');
    const hasSn = this.isFormFieldValid(appLink, 'appLinkError');
    if (hasGivenName || hasSn) {
      this.showError('errors.required');
    } else {
      this.formSubmit();
      // const { onEditSuccessHandler } = this.props;
      // onEditSuccessHandler('success');
    }
  };

  formSubmit = () => {
    this.setState({
      errorText: '',
    });
    // const user_id = localStorage.getItem('user_id');
    const user_id = Cookies.get('user_id');
    let userId = user_id;
    const { appName, appLink, appDescription, base64TextString } = this.state;
    const { updateApp } = this.props;
    let bearer = 'Bearer ';
    let token = Cookies.get('dataStoreToken');

    const payload = {
      appname: appName,
      applink: appLink,
      description: appDescription,
      photo: base64TextString,
    };
    http(
      {
        url: `endpoint/createappandrole`,
        method: 'POST',
        data: payload,
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res && res.status === 201 && res.data) {
        updateApp('success');
        this.props.onClose();
      } else {
        const failedPolicyRequirements = getNestedObject(res, [
          'data',
          'detail',
          'failedPolicyRequirements',
        ]);

        if (failedPolicyRequirements && failedPolicyRequirements.length > 0) {
          this.setState({
            errorArray: failedPolicyRequirements,
          });
        }
      }
    });
  };

  onNameChange = (e, key) => {
    const {
      target: { value },
    } = e;

    this.setState({
      [key]: value,
    });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;

    let errorMsg = '';
    switch (key) {
      case 'appNameError':
        if (value.length <= 0) {
          errorMsg = 'App name is mandatory';
        }
        break;
      case 'appLinkError':
        if (value.length <= 0) {
          errorMsg = 'App link is mandatory';
        } else if (!validator.isURL(value)) {
          errorMsg = 'Please eneter a valid url';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  onImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.setState({
      base64TextString: btoa(binaryString),
    });
  };
  render() {
    const { onClose, t } = this.props;
    const {
      appName,
      appLink,
      appDescription,
      appNameError,
      appLinkError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="edit-name-pop change-password-popup">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">{t('pages.Admin.popupTitle')}</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <p>
                    <span className="pr-2 red bold">*</span>
                    {t('pages.registration.requiredFields')}
                  </p>
                  <Form.Group controlId="appName">
                    <Form.Label>
                      {t('pages.Admin.appName')}
                      <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNameChange(e, 'appName');
                      }}
                      value={appName}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'appNameError');
                      }}
                      className={appNameError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!appNameError}
                      aria-describedby="firstname-error"
                    />
                    <p className="error-text" id="firstname-error">
                      {appNameError && t(appNameError)}
                    </p>
                  </Form.Group>

                  <Form.Group controlId="appLink">
                    <Form.Label>
                      {t('pages.Admin.appLink')}
                      <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNameChange(e, 'appLink');
                      }}
                      value={appLink}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'appLinkError');
                      }}
                      className={appLinkError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!appLinkError}
                      aria-describedby="lastname-error"
                    />
                    <p className="error-text" id="lastname-error">
                      {appLinkError && t(appLinkError)}
                    </p>
                  </Form.Group>

                  <Form.Group controlId="appDescription">
                    <Form.Label>{t('pages.Admin.appDescription')}</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNameChange(e, 'appDescription');
                      }}
                      value={appDescription}
                      as="textarea"
                      rows={2}
                    />
                  </Form.Group>

                  <Form.Group controlId="imageUpload">
                    <Form.Label>{t('pages.Admin.uploadImage')}</Form.Label>
                    <Form.Control
                      onChange={(e) => this.onImageChange(e)}
                      type="file"
                      name="image"
                      accept=".jpeg , .png, .jpg"
                    />
                  </Form.Group>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.onNext(e)}
                  >
                    {t('pages.editName.save')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

AdminPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateApp: PropTypes.func.isRequired,
};

export default withTranslation()(AdminPopup);
