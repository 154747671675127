import {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation, useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import PropTypes from 'prop-types';
import './ManageServices.scss';
import Cookies from 'js-cookie';
import http from 'api/http-request';

const SearchBar = (props, ref) => {
  const { roles} = props;
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState([
    'userName',
    'givenName',
    'sn',
    'mail',
  ]);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchVal, setSearchVal] = useState({
    operation: '',
    searchString: '',
  });
  const [dropDownError, setDropDownError] = useState('');

  useImperativeHandle(ref, () => ({
    searchUser,
  }));

  useEffect(() => {
    searchUser();
  }, [searchVal]);

  const debounce = (cb, delay = 700) => {
    let timer;

    return function (...args) {
      let context = this;
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        timer = null;
        cb.apply(context, args);
      }, delay);
    };
  };

  const searchUser = (value) => {
    let { setUserList, setUserNotFoundError } = props;
    let { operation, searchString } = searchVal;
    if (searchString == '') {
      setUserList([]);
      setUserNotFoundError('');

      return;
    }
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      pattern: searchString,

      attribute: selectedAttribute,
      user: 'admin',
      operation: value ? value : operation,
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    if (selectedAttribute === '') {
      setDropDownError('pages.ManageServices.selectDropdown');
    } else {
      setDropDownError('');
      http({
        url: '/aegis-managed-service/aegis/api/searchUser',
        method: 'POST',
        data: data,
      }).then((res) => {
        if (
          res.status == 401 &&
          ((res.data.iamcode == 'IAM0028' &&
            res.data.response == 'Access Denied') ||
            (res.data.iamcode == 'IAM0039' &&
              res.data.response == 'Unauthorized'))
        ) {
          Cookies.remove('username');
          Cookies.remove('admin');
          Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
          window.location.href = '/';
        } else if (
          res.data.iamcode == 'IAM000' &&
          res.data.data.response == "User doesn't exist"
        ) {
          setUserList([]);
          props.setUserNotFoundError('pages.ManageServices.userDoesNotExist');
        } else {
          setUserNotFoundError('');
          let data = res.data.data.result;

          setUserList(data);
        }
      });
    }
  };

  const searchInitialize = (operation, value) => {
    setSearchVal({
      operation: 'sw',
      searchString: value,
    });
  };

  const optimizedHandleChange = useCallback(debounce(searchInitialize), []);
  const searchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
    optimizedHandleChange('eq', e.target.value);
  };

  return (
    <div className="search-container">
      <div className="col-md-4">
        <DropdownButton
          id="dropdown-basic-button"
          title={
            selectedAttribute
              ? t(`pages.ManageServices.${selectedAttribute}`)
              : t(`pages.ManageServices.searchBy`)
          }
        >
          {attributes.map((elem) => {
            return (
              <Dropdown.Item
                key={elem}
                onClick={() => {
                  setSelectedAttribute(elem);
                }}
                className="search-dropdown"
              >
                {t(`pages.ManageServices.${elem}`)}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <p
          className="error-text"
          id="username-error"
          style={{ fontSize: '14px', padding: '0' }}
        >
          {dropDownError && t(dropDownError)}
        </p>
      </div>
      <div
        className="col-md-8 input-group rounded "
        style={{ 'margin-left': '3rem', 'margin-right': '3rem' }}
      >
        <span>
          <input
            type="search"
            className="form-control rounded search-input"
            placeholder={t('pages.ManageServices.search')}
            aria-describedby="search-addon"
            onChange={(e) => {
              searchKeywordChange(e);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                searchUser('eq');
              }
            }}
            value={searchKeyword}
            disabled={!roles.includes('openidm-admin')}
          />
        </span>
        <span
          className="input-group-text border-0 search-icon"
          id="search-addon"
          onClick={() => {
            searchUser('eq');
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  t: PropTypes.func.isRequired,
  setUserList: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  setUserNotFoundError: PropTypes.func.isRequired,
};

export default forwardRef(SearchBar);
