import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import loginAnimation from 'assets/json/login_idProofing.json';
import licenseAnimation from 'assets/json/document_scan.json';
import securityAnimation from 'assets/json/face_scan.json';
import verifiedAnimation from 'assets/json/verified.json';
import dotAnimation from 'assets/json/threeDot.json';
import { Button } from 'react-bootstrap';
import contentStyles from 'app/modules/IdProofing/IdProofingLandingPage.scss';
import { useTranslation, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import IdProofingBlockedPopup from '../IdProofing/IdProofingBlockedPopup';

function IdProofingLandingPage() {
  const [showBlockedPopup, setBlockedPopup] = useState('false');
  const license = {
    loop: true,
    autoplay: true,
    animationData: licenseAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const security = {
    loop: true,
    autoplay: true,
    animationData: securityAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const finish = {
    loop: true,
    autoplay: true,
    animationData: verifiedAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const threeDot = {
    loop: true,
    autoplay: true,
    animationData: dotAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if(Cookies.get('idProofingStatus') === '-4'){
      setBlockedPopup(true);
    }else{
      setBlockedPopup(false);
    }
  }, []);

  function onStart() {
    // e.preventDefault();
    window.location.href = '/idproofing-socureLogin';
  }

  const { t } = useTranslation();

  return (
    <>
     {showBlockedPopup && (
              <IdProofingBlockedPopup />
      )}
    <div className="id-proofing-login container forgot-username forgot-password my-5">
      <div className="col">
        <h2>{t('pages.idproofing.title')}</h2>
      </div>
      <div>
        <div className="grid-container-idproofing">
          <div className="grid-even-columns-idproofing">
            <div className="flow-idproofing">
              <div className="image-grid-idproofing">
                <Lottie options={license} height={150} width={150} />
              </div>
              <div className="number-container-idproofing">
                <h5 className="numbering-idproofing">1</h5>
              </div>
              <h5>{t('pages.idproofing.idVerification')} </h5>
              <p>{t('pages.idproofing.idVerificationDesc')}</p>
            </div>
            <div className="flow-idproofing">
              <div className="image-grid-idproofing">
                <Lottie options={security} height={150} width={150} />
              </div>
              <div className="number-container-idproofing">
                <h5 className="numbering-idproofing">2</h5>
              </div>
              <h5>{t('pages.idproofing.securityQuestions')}</h5>
              <p>{t('pages.idproofing.securityQuestionsDesc')}</p>
            </div>
            <div className="flow-idproofing">
              <div className="image-grid-idproofing">
                <Lottie options={finish} height={150} width={150} />
              </div>
              <div className="number-container-idproofing">
                <h5 className="numbering-idproofing">3</h5>
              </div>
              <h5>{t('pages.idproofing.complete')}</h5>
              <p>{t('pages.idproofing.finishDesc')}</p>
            </div>
          </div>
        </div>
        <div className="threeDot-idproofing">
          <Lottie options={threeDot} height={40} width={40} />
        </div>
        <div class="buttonWrapper-idproofing">
          <Button className="startButton-idproofing" onClick={onStart}>
            {t('pages.idproofing.start')}
          </Button>
        </div>
      </div>
      <h6
        className="pt-4"
        style={{ display: 'flex', justifyContent: 'center', fontWeight: '700' }}
      >
        Note : You are being re-directed to a third-party integrated service for
        continuing with ID Proofing.
      </h6>
    </div>
    </>
  );
}

IdProofingLandingPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(IdProofingLandingPage);
