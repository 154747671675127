import PropTypes from 'prop-types';
import './ManageServices.scss';
import { withTranslation, useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import { Modal, Button } from 'react-bootstrap';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import http from 'api/http-request';
import Cookies from 'js-cookie';
import Alert from 'app/modules/Shared/Alert/Alert';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

function DisplayUserList(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertText, setAlertText] = useState('');
  const [userId, setUserId] = useState('');
  const [msg, setMsg] = useState('');
  const [rowid, setRowId] = useState(null);
  const [userlistdata, setUserList] = useState(
    props.userList && props.userList
  );

  const handleClose = () => {
    const copy = structuredClone(userlistdata);
    copy.forEach((item) => {
      if (item._id == rowid) {
        if (item.accountStatus == 'active') {
          item.accountStatus = 'inactive';
        } else {
          item.accountStatus = 'active';
        }
      }
    });
    setShow(false);
    setUserList(copy);
  };

  const handleChange = () => {
    setShow(false);
    http({
      url: '/aegis-managed-service/aegis/api/updateUserStatusByPrivilegeUser',
      method: 'POST',
      data: {
        status: status,
        userId: userId,
        iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
      },
    }).then((res) => {
      if (
        res.status == 401 &&
        ((res.data.iamcode == 'IAM0028' &&
          res.data.response == 'Access Denied') ||
          (res.data.iamcode == 'IAM0039' &&
            res.data.response == 'Unauthorized'))
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';
      } else if (res.status == 200 && res.data.response == 'success') {
        console.log('Successfull');
      }
    });
  };

  const changeStatus = (e, id, accountStatus) => {
    setUserId(id);
    const copy = structuredClone(userlistdata);
    copy.forEach((item) => {
      if (item._id == id) {
        if (item.accountStatus == 'active') {
          item.accountStatus = 'inactive';
        } else {
          item.accountStatus = 'active';
        }
      }
    });
    setUserList(copy);

    setShow(true);
    setRowId(id);
    if (accountStatus != 'active') {
      setStatus('0');
      setMsg('pages.ManageServices.changeStatusEnable');
    } else {
      setStatus('1');
      setMsg('pages.ManageServices.changeStatusDisable');
    }
  };

  const productsGenerator = () => {
    const items = [];
    for (let i = 0; i < userlistdata.length; i++) {
      items.push({
        num: i + 1,
        id: userlistdata[i].userName,
        name: userlistdata[i].givenName,
        price: userlistdata[i].sn,
        id1: userlistdata[i].mail,
        name1: (
          <p
            className={
              userlistdata[i].accountStatus === 'active'
                ? 'statusbtn-active'
                : 'statusbtn-inactive'
            }
            onClick={(e) =>
              changeStatus(
                e,
                userlistdata[i]._id,
                userlistdata[i].accountStatus
              )
            }
          >
            {userlistdata[i].accountStatus === 'active' ? 'Active' : 'Inactive'}
          </p>
        ),
        price1: (
          <div
            className="col-sm-2 text-right"
            role="button"
            tabIndex={0}
            aria-label="Edit User Information"
            onClick={() => {
              props.setUserEditModelVal({
                showModal: true,
                userDetails: userlistdata[i],
              });
            }}
          >
            <FontAwesomeIcon icon={faPenAlt} />
          </div>
        ),
        price2: (
          <div
            className="col-sm-2 text-right"
            role="button"
            tabIndex={0}
            aria-label="Edit Password"
            onClick={() => {
              props.setEditModelVal({
                showPasswordModel: true,
                'User Name': userlistdata[i].userName,
              });
            }}
          >
            <FontAwesomeIcon icon={faPenAlt} />
          </div>
        ),
      });
    }
    return items;
  };

  const products = productsGenerator();

  const columns = [
    {
      dataField: 'num',
      text: <span>#</span>,
    },
    {
      dataField: 'id',
      text: <span>{t('pages.ManageServices.userName')}</span>,
    },
    {
      dataField: 'name',
      text: <span>{t('pages.ManageServices.givenName')}</span>,
    },
    {
      dataField: 'price',
      text: <span>{t('pages.ManageServices.sn')}</span>,
    },
    {
      dataField: 'id1',
      text: <span>{t('pages.ManageServices.email')}</span>,
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'name1',
      text: <span>{t('pages.ManageServices.status')}</span>,
    },
    {
      dataField: 'price1',
      text: <span>{t('pages.ManageServices.edit')}</span>,
    },
    {
      dataField: 'price2',
      text: <span>{t('pages.changePassword.password')}</span>,
    },
  ];

  return (
    <div>
      {alertText && (
        <Alert
          alertText={alertText}
          className={alertStatus}
          onAlertClose={() => {
            setAlertText('');
          }}
        />
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('pages.ManageServices.confirm')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t(msg)}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('pages.ManageServices.cancel')}
          </Button>
          <Button variant="primary" onClick={handleChange}>
            {t('pages.ManageServices.ok')}
          </Button>
        </Modal.Footer>
      </Modal>

      <BootstrapTable
        keyField="id"
        data={products}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 5, sizePerPageList: [] })}
        className="test"
      />
      {/* <Table striped bordered hover className="user-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('pages.ManageServices.userName')}</th>
            <th>{t('pages.ManageServices.givenName')}</th>
            <th>{t('pages.ManageServices.sn')}</th>
            <th>{t('pages.ManageServices.email')}</th>
            <th>{t('pages.ManageServices.status')}</th>
            <th>{t('pages.ManageServices.edit')}</th>
            <th>{t('pages.changePassword.password')}</th>
          </tr>
        </thead>
        <tbody>
          {userlistdata?.length > 0 ? (
            userlistdata.map((obj, id) => {
              return (
                <tr key={obj._id}>
                  <td>{id + 1}</td>
                  <td>{obj.userName}</td>
                  <td>{obj.givenName}</td>
                  <td>{obj.sn}</td>
                  <td>{obj.mail}</td>
                  <td>
                    <p
                      className={
                        obj.accountStatus === 'active'
                          ? 'statusbtn-active'
                          : 'statusbtn-inactive'
                      }
                      onClick={(e) =>
                        changeStatus(e, obj._id, obj.accountStatus)
                      }
                    >
                      {obj.accountStatus === 'active' ? 'Active' : 'Inactive'}
                    </p>
                  </td>
                  <td>
                    <div
                      className="col-sm-2 text-right"
                      role="button"
                      tabIndex={0}
                      aria-label="Edit User Information"
                      onClick={() => {
                        props.setUserEditModelVal({
                          showModal: true,
                          userDetails: obj,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPenAlt} />
                    </div>
                  </td>
                  <td>
                    <div
                      className="col-sm-2 text-right"
                      role="button"
                      tabIndex={0}
                      aria-label="Edit Password"
                      onClick={() => {
                        props.setEditModelVal({
                          showPasswordModel: true,
                          'User Name': obj.userName,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPenAlt} />
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <div
              style={{
                marginLeft: '180%',
                textAlign: 'center',
                width: '100%',
                padding: '25%',
              }}
            >
              <p>No data to display</p>
            </div>
          )}
        </tbody>
      </Table> */}
    </div>
  );
}

DisplayUserList.propTypes = {
  t: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  setUserList: PropTypes.func.isRequired,
  setEditModelVal: PropTypes.func.isRequired,
  setUserEditModelVal: PropTypes.func.isRequired,
};

export default withTranslation()(DisplayUserList);
