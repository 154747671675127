import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/PrivacyConsent.scss';
import { withTranslation } from 'react-i18next';
import Checkbox from 'app/modules/Shared/Checkbox/Checkbox';

class PrivacyConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreePolicy: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onCheckboxChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  render() {
    const { t } = this.props;
    const { agreePolicy } = this.state;
    return (
      <div>
        <div className="privacy-consent">
          <h1>{t('pages.privacyConsent.title')}</h1>
          <hr />
          <h3>{t('pages.privacyConsent.aegisPrivacyPolicy')}</h3>
          <hr />
          <div className="grid-100 mobile-grid-100 tablet-grid-100">
            <div className="entry-content clearfix">
              <p>{t('pages.privacyConsent.adoptNotice')}</p>
              <p>
                <strong>
                  <u>{t('pages.privacyConsent.informationWeCollect')}</u>
                </strong>
              </p>
              <p>{t('pages.privacyConsent.weCollectInfo')}</p>
              <table border="1" width="100%">
                <tbody>
                  <tr>
                    <th className="p-3" width="27%">
                      {t('pages.privacyConsent.category')}
                    </th>
                    <th className="p-3" width="60%">
                      {t('pages.privacyConsent.examples')}
                    </th>
                    <th className="p-3" width="11%">
                      {t('pages.privacyConsent.collected')}
                    </th>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      {t('pages.privacyConsent.identifiers')}
                    </td>
                    <td className="p-3" width="60%">
                      A real name, alias, postal address, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, account name, Social Security number,
                      driver’s license number, passport number, or other similar
                      identifiers.
                    </td>
                    <td className="p-3" width="11%">
                      YES
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      B. Personal information categories listed in the
                      California Customer Records statute (Cal. Civ. Code §
                      1798.80(e)).
                    </td>
                    <td className="p-3" width="60%">
                      A name, signature, Social Security number, physical
                      characteristics or description, address, telephone number,
                      passport number, driver’s license or state identification
                      card number, insurance policy number, education,
                      employment, employment history, bank account number,
                      credit card number, debit card number, or any other
                      financial information, medical information, or health
                      insurance information. Some personal information included
                      in this category may overlap with other categories.
                    </td>
                    <td className="p-3" width="11%">
                      YES
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      C. Protected classification characteristics under
                      California or federal law.
                    </td>
                    <td className="p-3" width="60%">
                      Age (40 years or older), race, color, ancestry, national
                      origin, citizenship, religion or creed, marital status,
                      medical condition, physical or mental disability, sex
                      (including gender, gender identity, gender expression,
                      pregnancy or childbirth and related medical conditions),
                      sexual orientation, veteran or military status, genetic
                      information (including familial genetic information).
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      D. Commercial information.
                    </td>
                    <td className="p-3" width="60%">
                      Records of personal property, products or services
                      purchased, obtained, or considered, or other purchasing or
                      consuming histories or tendencies.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      E. Biometric information.
                    </td>
                    <td className="p-3" width="60%">
                      Genetic, physiological, behavioral, and biological
                      characteristics, or activity patterns used to extract a
                      template or other identifier or identifying information,
                      such as, fingerprints, faceprints, and voiceprints, iris
                      or retina scans, keystroke, gait, or other physical
                      patterns, and sleep, health, or exercise data.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      F. Internet or other similar network activity.
                    </td>
                    <td className="p-3" width="60%">
                      Browsing history, search history, information on a
                      consumer’s interaction with a website, application, or
                      advertisement.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      G. Geolocation data.
                    </td>
                    <td className="p-3" width="60%">
                      Physical location or movements.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      H. Sensory data.
                    </td>
                    <td className="p-3" width="60%">
                      Audio, electronic, visual, thermal, olfactory, or similar
                      information.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      I. Professional or employment-related information.
                    </td>
                    <td className="p-3" width="60%">
                      Current or past job history or performance evaluations.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      J. Non-public education information (per the Family
                      Educational Rights and Privacy Act (20 U.S.C. Section
                      1232g, 34 C.F.R. Part 99)).
                    </td>
                    <td className="p-3" width="60%">
                      Education records directly related to a student maintained
                      by an educational institution or party acting on its
                      behalf, such as grades, transcripts, class lists, student
                      schedules, student identification codes, student financial
                      information, or student disciplinary records.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" width="27%">
                      K. Inferences drawn from other personal information.
                    </td>
                    <td className="p-3" width="60%">
                      Profile reflecting a person’s preferences,
                      characteristics, psychological trends, predispositions,
                      behavior, attitudes, intelligence, abilities, and
                      aptitudes.
                    </td>
                    <td className="p-3" width="11%">
                      NO
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>Personal information does not include:</p>
              <ul>
                <li>Publicly available information from government records.</li>
                <li>De-identified or aggregated consumer information.</li>
                <li>
                  Information excluded from the CCPA’s scope, like:
                  <ul>
                    <li>
                      health or medical information covered by the Health
                      Insurance Portability and Accountability Act of 1996
                      (HIPAA) and the California Confidentiality of Medical
                      Information Act (CMIA) or clinical trial data;
                    </li>
                    <li>
                      personal information covered by certain sector-specific
                      privacy laws, including the Fair Credit Reporting Act
                      (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                      Financial Information Privacy Act (FIPA), and the Driver’s
                      Privacy Protection Act of 1994.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                We obtain the categories of personal information listed above
                from the following categories of sources:
              </p>
              <ul>
                <li>
                  The following are examples. Insert your own sources here.
                </li>
                <li>
                  Directly from our clients or their agents. For example, from
                  documents that our clients provide to us related to the
                  services for which they engage us.
                </li>
                <li>
                  Indirectly from our clients or their agents. For example,
                  through information we collect from our clients in the course
                  of providing services to them.
                </li>
                <li>
                  Directly and indirectly from activity on our website. For
                  example, from submissions through our website portal or
                  website usage details collected automatically.
                </li>
                <li>
                  From third-parties that interact with us in connection with
                  the services we perform.
                </li>
              </ul>
              <p>
                <strong>
                  <u>Use of Personal Information</u>
                </strong>
              </p>
              <p>
                We may use or disclose the personal information we collect for
                one or more of the following business purposes:
              </p>
              <ul>
                <li>
                  To fulfill or meet the reason for which the information is
                  provided. For example, if you provide us with personal
                  information in order for us to prepare a tax return, we will
                  use that information to prepare the return and submit it to
                  the applicable taxing authorities.
                </li>
                <li>
                  To provide you with information, products or services that you
                  request from us.
                </li>
                <li>
                  To provide you with email alerts, event registrations and
                  other notices concerning our products or services, or events
                  or news, that may be of interest to you.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collections.
                </li>
                <li>To improve our website and present its contents to you.</li>
                <li>
                  For testing, research, analysis and product development.
                </li>
                <li>
                  As necessary or appropriate to protect the rights, property or
                  safety of us, our clients or others.
                </li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations.
                </li>
                <li>
                  As described to you when collecting your personal information
                  or as otherwise set forth in the CCPA.
                </li>
                <li>
                  To evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  personal information held by us is among the assets
                  transferred.
                </li>
              </ul>
              <p>
                We will not collect additional categories of personal
                information or use the personal information we collect for
                materially different, unrelated, or incompatible purposes
                without providing you notice.
              </p>
              <p>
                <strong>
                  <u>Sharing Personal Information</u>
                </strong>
              </p>
              <p>
                We may disclose your personal information to a third party for a
                business purpose. When we disclose personal information for a
                business purpose, we enter a contract that describes the purpose
                and requires the recipient to both keep that personal
                information confidential and not use it for any purpose except
                performing the contract.
              </p>
              <p>
                In the preceding twelve (12) months, we have disclosed the
                following categories of personal information for a business
                purpose:
              </p>
              <p>Category A: Identifiers.</p>
              <p>
                Category B: California Customer Records personal information
                categories.
              </p>
              <p>
                Category C: Protected classification characteristics under
                California or federal law.
              </p>
              <p>
                We disclose your personal information for a business purpose to
                the following categories of third parties:
              </p>
              <ul>
                <li>Our affiliates.</li>
                <li>Service providers.</li>
                <li>
                  Third parties to whom you or your agents authorize us to
                  disclose your personal information in connection with products
                  or services we provide to you.
                </li>
              </ul>
              <p>
                In the preceding twelve (12) months, we have not sold any
                personal information.
              </p>
              <p>
                <strong>
                  <u>Your Rights and Choices</u>
                </strong>
              </p>
              <p>
                The CCPA provides consumers (California residents) with specific
                rights regarding their personal information. This section
                describes your CCPA rights and explains how to exercise those
                rights.
              </p>
              <p>
                <u>
                  <strong>
                    Access to Specific Information and Data Portability Rights
                  </strong>
                </u>
              </p>
              <p>
                You have the right to request that we disclose certain
                information to you about our collection and use of your personal
                information over the past 12 months. Once we receive and confirm
                your verifiable consumer request, we will disclose to you:
              </p>
              <ul>
                <li>
                  The categories of personal information we collected about you.
                </li>
                <li>
                  The categories of sources for the personal information we
                  collected about you.
                </li>
                <li>
                  Our business or commercial purpose for collecting or selling
                  that personal information.
                </li>
                <li>
                  The categories of third parties with whom we share that
                  personal information.
                </li>
                <li>
                  The specific pieces of personal information we collected about
                  you (also called a data portability request).
                </li>
                <li>
                  If we sold or disclosed your personal information for a
                  business purpose, two separate lists disclosing:
                  <ul>
                    <li>
                      sales, identifying the personal information categories
                      that each category of recipient purchased; and
                    </li>
                    <li>
                      disclosures for a business purpose, identifying the
                      personal information categories that each category of
                      recipient obtained.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <strong>
                  <u>Deletion Request Rights</u>
                </strong>
              </p>
              <p>
                You have the right to request that we delete any of your
                personal information that we collected from you and retained,
                subject to certain exceptions. Once we receive and confirm your
                verifiable consumer request, we will delete (and direct our
                service providers to delete) your personal information from our
                records, unless an exception applies.
              </p>
              <p>
                We may deny your deletion request if retaining the information
                is necessary for us or our service providers to:
              </p>
              <ol>
                <li>
                  Complete the transaction for which we collected the personal
                  information, provide a good or service that you requested,
                  take actions reasonably anticipated within the context of our
                  ongoing business relationship with you, or otherwise perform
                  our contract with you.
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities.
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality.
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise their free speech rights, or exercise another right
                  provided for by law.
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act (Cal. Penal Code § 1546 <em>seq.</em>).
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when the
                  information’s deletion may likely render impossible or
                  seriously impair the research’s achievement, if you previously
                  provided informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on your relationship with us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                  Make other internal and lawful uses of that information that
                  are compatible with the context in which you provided it.
                </li>
              </ol>
              <p>
                <u>
                  <strong>
                    Exercising Access, Data Portability, Deletion, and Opt-out
                    Rights
                  </strong>
                </u>
              </p>
              <p>
                To exercise the access, portability, deletion, or opt-out rights
                described above, please submit a verifiable consumer request to
                us by either:
              </p>
              <ul>
                {/* <li>Calling us at: 800-456-7890</li> */}
                {/* <li>Online: https://YourWebsite.com/opt-out-page</li> */}
                <li>Emailing: CCPA@ProjectAegis.com</li>
                {/* <li>Mailing: 123 Street Name, City, State</li> */}
              </ul>
              <p>
                Only you or a person registered with the California Secretary of
                State that you authorize to act on your behalf, may make a
                verifiable consumer request related to your personal
                information. You may also make a verifiable consumer request on
                behalf of your minor child.
              </p>
              <p>
                You may only make a verifiable consumer request for access or
                data portability twice within a 12-month period. The verifiable
                consumer request must:
              </p>
              <ul>
                <li>
                  Provide sufficient information that allows us to reasonably
                  verify you are the person or an authorized representative of
                  the person we collected personal information on.
                </li>
                <li>
                  Describe your request with sufficient detail that allows us to
                  properly understand, evaluate, and respond to it.
                </li>
              </ul>
              <p>
                We cannot respond to your request or provide you with personal
                information if we cannot verify your identity or authority to
                make the request and confirm the personal information relates to
                you. Making a verifiable consumer request does not require you
                to create an account with us. We will only use personal
                information provided in a verifiable consumer request to verify
                the requestor’s identity or authority to make the request.
              </p>
              <p>
                <u>
                  <strong>Response Timing and Format</strong>
                </u>
              </p>
              <p>
                We endeavor to respond to a verifiable consumer request within
                45 days of its receipt. If we require more time (up to 90 days),
                we will inform you of the reason and extension period in
                writing. If you have an account with us, we will deliver our
                written response to that account. If you do not have an account
                with us, we will deliver our written response by mail or
                electronically, at your option. Any disclosures we provide will
                only cover the 12-month period preceding the verifiable consumer
                request’s receipt. The response we provide will also explain the
                reasons we cannot comply with a request, if applicable. For data
                portability requests, we will select a format to provide your
                personal information that is readily useable and should allow
                you to transmit the information from one entity to another
                entity without hindrance.
              </p>
              <p>
                We do not charge a fee to process or respond to your verifiable
                consumer request unless it is excessive, repetitive, or
                manifestly unfounded. If we determine that the request warrants
                a fee, we will tell you why we made that decision and provide
                you with a cost estimate before completing your request.
              </p>
              <p>
                <strong>
                  <u>Non-Discrimination</u>
                </strong>
              </p>
              <p>
                We will not discriminate against you for exercising any of your
                CCPA rights. Unless permitted by the CCPA, we will not:
              </p>
              <ul>
                <li>Deny you goods or services.</li>
                <li>
                  Charge you different prices or rates for goods or services,
                  including through granting discounts or other benefits, or
                  imposing penalties.
                </li>
                <li>
                  Provide you a different level or quality of goods or services.
                </li>
                <li>
                  Suggest that you may receive a different price or rate for
                  goods or services or a different level or quality of goods or
                  services.
                </li>
              </ul>
              <p>
                <strong>
                  <u>Changes to Our Privacy Notice</u>
                </strong>
              </p>
              <p>
                We reserve the right to amend this privacy notice at our
                discretion and at any time. When we make changes to this privacy
                notice, we will notify you by email or through a notice on our
                website homepage.
              </p>
              <p>
                <strong>
                  <u>Contact Information</u>
                </strong>
              </p>
              <p>
                If you have any questions or comments about this notice, our
                Privacy Statement, the ways in which we collect and use your
                personal information, your choices and rights regarding such
                use, or wish to exercise your rights under California law,
                please do not hesitate to contact us at:
              </p>
              <p>Phone:(425) 555-0123</p>
              <p>Website: ProjectAegis.com</p>
              <p>Email:projectAegis@support.com</p>
              {/* <p>Mailing Address:</p> */}
            </div>
          </div>
          <div className="pt-2 check">
            <span>{t('pages.privacyConsent.acknowledgeConsent')}</span>
            <Checkbox
              className="checkbox"
              name="agreePolicy"
              labelAlignment="right"
              handleChange={(checked) =>
                this.onCheckboxChange('agreePolicy', checked)
              }
              isChecked={agreePolicy}
              role="checkbox"
              aria-checked="false"
              aria-disabled="false"
            />
          </div>
        </div>
      </div>
    );
  }
}
PrivacyConsent.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(PrivacyConsent);
