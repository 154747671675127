import React from 'react';
import { Accordion, Breadcrumb, Button, Card } from 'react-bootstrap';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import 'app/modules/Dashboard/Notifications.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appAlertToggle: false,
      securityAlertToggle: false,
      userId: '',
      token: '',
    };
  }

  componentDidMount() {
    window.scrollTo(
      {
        behavior: 'smooth',
        top: 0,
      },
      []
    );

    // let token = localStorage.getItem('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
  }

  appAlertToggleHandler = () => {
    if (this.state.securityAlertToggle || this.state.appAlertToggle) {
      this.setState((prev) => ({
        appAlertToggle: !prev.appAlertToggle,
        securityAlertToggle: false,
      }));
    } else {
      this.setState((prev) => ({
        appAlertToggle: !prev.appAlertToggle,
      }));
    }
  };

  securityAlertToggleHandler = () => {
    if (this.state.appAlertToggle || this.state.securityAlertToggle) {
      this.setState((prev) => ({
        appAlertToggle: false,
        securityAlertToggle: !prev.securityAlertToggle,
      }));
    } else {
      this.setState((prev) => ({
        securityAlertToggle: !prev.securityAlertToggle,
      }));
    }
  };

  render() {
    const { notificationsArray, t } = this.props;
    return (
      <div>
        <Breadcrumb aria-label="breadcrumb">
          <Breadcrumb.Item href="#">
            {t('pages.BreadCrumb.home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#">
            {t('pages.BreadCrumb.notifications')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>...</Breadcrumb.Item>
        </Breadcrumb>
        <div className="m-5">
          <Accordion>
            {/* app alerts */}
            <div className="row mb-3">
              <div className="col-12 d-flex align-items-center">
                <h1 className="font-weight-normal">{t('pages.notifications.appAlerts')}</h1>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  aria-label="show app alerts toggle button"
                  tabIndex={0}
                  onClick={this.appAlertToggleHandler}
                >
                  <FontAwesomeIcon
                    icon={
                      this.state.appAlertToggle ? faChevronDown : faChevronUp
                    }
                    role="img"
                    className="chevron-style"
                  />
                </Accordion.Toggle>
              </div>
              <div className="col-12 pt-2">
                <Accordion.Collapse eventKey="1">
                  <div></div>
                </Accordion.Collapse>
              </div>
            </div>
            {/* security alerts */}

            <div className="row mb-3">
              <div className="col-12 d-flex align-items-center">
                <h1 className="font-weight-normal">
                  {t('pages.notifications.securityAlerts')}
                </h1>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  aria-label="show security alerts toggle button"
                  tabIndex={0}
                  onClick={this.securityAlertToggleHandler}
                >
                  <FontAwesomeIcon
                    icon={
                      this.state.securityAlertToggle
                        ? faChevronDown
                        : faChevronUp
                    }
                    className="chevron-style"
                  />
                </Accordion.Toggle>
              </div>
              <div className="col-12 pt-2">
                <Accordion.Collapse eventKey="2">
                  <div className="pt-2">
                    {notificationsArray
                      ? notificationsArray.map((notification, index) =>
                          notification.notificationType === 'info' ? (
                            <Card
                              key={index}
                              className="box-shadow-default p-3 mb-4"
                            >
                              <div className="row align-items-center">
                                <div className="col-sm-7 col-md-9">
                                  <div className="d-block">
                                    <Card.Title>
                                      {notification.message}
                                    </Card.Title>
                                  </div>
                                  <div className="d-block">
                                    <p className="text-gray m-0">
                                      <span className="pr-3">
                                        {new Date(
                                          notification.createDate
                                        ).toLocaleDateString()}
                                      </span>
                                      <span>
                                        {new Date(
                                          notification.createDate
                                        ).toLocaleTimeString('en-US')}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                {/* todo add functionality for new notifications and delete once available */}
                                {/* <div className="col-sm-5 col-md-3 d-flex justify-content-end align-items-center pt-3 pt-sm-0">
                              {notification.alertNewToggle ? (
                                <div className="new-indicator card bold px-3 pb-1 mr-3">
                                  <p className="ft-18 m-0 bold">New</p>
                                </div>
                              ) : (
                                ''
                              )}
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="black"
                              />
                            </div> */}
                              </div>
                            </Card>
                          ) : (
                            ''
                          )
                        )
                      : ''}
                  </div>
                </Accordion.Collapse>
              </div>
            </div>
          </Accordion>
          <div className="row">
            <div className="col-md-3">
              <button className="btn btn-danger py-2 my-3">{t('pages.notifications.deleteAll')}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Notifications.propTypes = {
  notificationsArray: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(Notifications);
