import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/Home.scss';
import { withTranslation } from 'react-i18next';
import heroImage from 'assets/img/mountains-hero-image.jpg';
import http from 'api/http-request';
import Cookies from 'js-cookie';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';
import { Button } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'react-lottie';
import idProofingAnimation from 'assets/json/idproofing_banner.json';
import bulletAnimation from 'assets/json/bullet.json';
import 'app/modules/IdProofing/IdProofingBanner.scss';
import IdProofingPopup from '../IdProofing/IdProofingPopup';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';
import aegisIMG from 'assets/img/apple-touch-icon.png';
import 'app/modules/Dashboard/Wallet.scss';
import { sanitizeUrl } from '@braintree/sanitize-url';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBannerLocalStorage: '',
      userDetails: props.userDetails ? props.userDetails : {},
      showIdPlusPopup: false,
      pingIDProofing: true,
      url: '',
      pingVerified: false,
      upadtes: [
        {
          title: 'PingOne',
          description: 'Now aegis platform offers ID proofing via PingOne. User can issue themself a digital document and use that to verify at different third party portals.',
        },
        {
          title: 'DeloitteWallet',
          description: 'Now aegis platform is integrated with Deloitte Digital Wallet. Which offers seemless login and other services.',
        },
        {
          title: 'PasswordLess',
          description: 'As we already have password less authentication feature which leverage ForgeRock, Now we are developing same with PingOne DaVinci flow.',
        },
      ],
    };
  }

  componentDidMount() {
    const { idProofing , walletRegistered } = this.props;
    window.scrollTo(0, 0);
    // let token = localStorage.getItem('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');

    let isIdProofingStorage = localStorage.getItem('isIdProofing');
    if (
      isIdProofingStorage == 'false' ||
      walletRegistered || Cookies.get('dashboardRequired') === 'customWallet'
    ) {
      this.setState({ showBannerLocalStorage: 'false' });
      this.setState({ pingVerified: 'true' });
    }

    this.interval = setInterval(() => {
      const userId = Cookies.get('user_id');
      if (userId) {
        clearInterval(this.interval);
      } else {
        this.setState({
          userID: userId,
        });
      }
    }, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userDetails !== prevProps.userDetails) {
      this.setState({
        userDetails: this.props.userDetails,
      });
    }

    if (
      prevProps.idProofing != this.props.idProofing &&
      prevState.showBannerLocalStorage != this.state.showBannerLocalStorage &&
      this.state.idProofing === 'true'
    ) {
      this.setState({
        showBannerLocalStorage: 'false',
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  showIdProofingPopup = (e) => {
    const { idProofing } = this.props;
    if (idProofing === 'false') {
      this.setState({ showIdPlusPopup: true, showBannerLocalStorage: 'false' });
    }
  };
  closeIdProofingPopup = (e) => {
    this.setState({ showIdPlusPopup: false });
  };

  onStartIdProofing = (e) => {
    if (Cookies.get('idProofingStatus') !== '-4') {
      window.location.href = '/idproofing-landing';
    }
  };

  onSkipIdProofing = (e) => {
    this.setState({
      showBannerLocalStorage: 'false',
    });

    localStorage.setItem('isIdProofing', 'false');
  };

  render() {
    const { t, idProofing } = this.props;
    const { userDetails, showBannerLocalStorage, showIdPlusPopup, upadtes } =
    this.state;
    const banner = {
      loop: true,
      autoplay: true,
      animationData: idProofingAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const bullet = {
      loop: true,
      autoplay: true,
      animationData: bulletAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <>
        <div>
          {showIdPlusPopup && (
            <IdProofingPopup closePopup={this.closeIdProofingPopup} />
          )}
          <div>
            <AlertGlobal useTicker={false}></AlertGlobal>
          </div>
          <div className="home-page__container m-md-5">
            <div className="home-page__container-content">
              <div>
                <div className="home-page__container-content-wrapper">
                  <AnimatePresence>
                    {showBannerLocalStorage != 'false' &&
                      idProofing != 'true' && (
                        <motion.div
                          exit={{ x: -2000 }}
                          transition={{ duration: 0.5 }}
                          className="w-100 idproofing-banner-container"
                        >
                          {showBannerLocalStorage != 'false' &&
                            idProofing != 'true' && (
                              <motion.div
                                exit={{ x: -2000 }}
                                transition={{ duration: 0.5 }}
                                className="container-idproofing-banner"
                              >
                                <motion.div
                                  transition={{ duration: 0.5 }}
                                  className="idproofing-banner-wrapper"
                                >
                                  <div className="idproofing-banner-animation">
                                    <Lottie
                                      options={banner}
                                      height={150}
                                      width={150}
                                    />
                                  </div>
                                  <div className="idproofing-info">
                                    <h4>{t('pages.idproofing.title')}</h4>
                                    <h5>
                                      {t('pages.idproofing.bannerMessage')}
                                    </h5>
                                    <div className="idproofing-steps">
                                      <div className="idproofing-bullet-wrapper">
                                        <Lottie
                                          className="img-bullet"
                                          options={bullet}
                                          height={50}
                                          width={50}
                                        />
                                        <h6>
                                          {t(
                                            'pages.idproofing.bannerRequirement1'
                                          )}
                                        </h6>
                                      </div>
                                      <div className="idproofing-bullet-wrapper">
                                        <Lottie
                                          className="img-bullet"
                                          options={bullet}
                                          height={50}
                                          width={50}
                                        />
                                        <h6>
                                          {t(
                                            'pages.idproofing.bannerRequirement2'
                                          )}
                                        </h6>
                                      </div>
                                      <div className="buttonWrapper-idproofing">
                                        <motion.Button
                                          className="startButton-idproofing mx-5"
                                          onClick={(e) =>
                                            this.showIdProofingPopup(e)
                                          }
                                        >
                                          {t('pages.idproofing.start')}
                                        </motion.Button>
                                        <Button
                                          className="startButton-idproofing_skip"
                                          onClick={(e) =>
                                            this.onSkipIdProofing(e)
                                          }
                                        >
                                          {t('pages.idproofing.skip')}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </motion.div>
                              </motion.div>
                            )}
                        </motion.div>
                      )}
                  </AnimatePresence>
                  <div className="w-100 image-banner">
                    <h1>
                      {t('pages.homePage.welcomeText')}, {userDetails.givenName}{' '}
                      {userDetails.sn}
                    </h1>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 pb-5 pb-md-4">
                      <div className="card box-shadow-default p-3 h-100">
                        <h2 className="home-page__container-content-wrapper__title p-0">
                          {t('pages.homePage.newsTitle')}
                        </h2>
                        <div className="home-page__container-content-wrapper__description">
                          <p>{t('pages.homePage.newsDescription1')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card box-shadow-default p-3 h-100">
                        <h2 className="home-page__container-content-wrapper__title p-0">
                          {t('pages.homePage.companyUpdate')}
                        </h2>
                        <p className="home-page__container-content-wrapper__description">
                        <div>
                            {upadtes?.length > 0 ? (
                              upadtes
                                .map((update, index) => (
                                  <div key={index} tabIndex="0">

                                    <b>{update.title}  : </b>
                                    {update.description}

                                  </div>
                                ))
                            ) : (
                              <div className="no-devices mb-3">
                                {t('pages.homePage.companyDescription')}
                              </div>
                            )}

                            <a href=''>{t('pages.homePage.viewMore')}</a>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Home.propTypes = {
  setUserDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object,
  userDetails: {},
  idProofing: PropTypes.bool.isRequired,
  walletRegistered : PropTypes.bool.isRequired,
};
Home.defaultProps = {
  userData: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
  userDetails: {},
};
export default withTranslation()(Home);
