import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/DeleteDocument.scss';

class DeleteDocument extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClose, t } = this.props;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="edit-name-pop change-password-popup delete-document-popup">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">{t('pages.deletePopup.title')}</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    variant="primary"
                    block
                    type="submit"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t('pages.deletePopup.yes')}
                  </Button>
                  <Button
                    className="wrapper__container-card-body-submit"
                    variant="primary"
                    block
                    type="submit"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t('pages.deletePopup.no')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

DeleteDocument.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default withTranslation()(DeleteDocument);
