import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ErrorIcon from 'assets/img/404-image.png';
import 'app/modules/Shared/SomethingWentWrong/SomethingWentWrong.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PageNotFound = (props) => {
  const { t } = useTranslation();
  const { setData } = props;
  useEffect(() => {
    document.body.classList.add('gray-background');
  }, []);
  const sendToLogin = () => {
    window.location.href = '/';
  };
  return (
    <Container className="wentwrong">
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <>
            <div
              role="alert"
              aria-live="assertive"
              className="wentwrong__block"
            >
              <img
                src={ErrorIcon}
                alt="Error Icon"
                aria-hidden="true"
                className="wentwrong__block-icon"
              />
              {setData && setData.message === 'Snapshot token is invalid' && (
                <>
                  <h1 className="wentwrong__block-title">
                    {t('errors.badRequest')}
                  </h1>
                  <p className="wentwrong__block-desc">{setData.message}</p>
                  <div className="wentwrong__block-desc">
                    <p
                      className="resend-link"
                      onClick={() => sendToLogin()}
                      role="button"
                      tabIndex={0}
                    >
                      {t('pages.forgotUserId.loginBtn')}
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        </Col>
      </Row>
    </Container>
  );
};
PageNotFound.propTypes = {
  setData: PropTypes.object.isRequired,
};
PageNotFound.defaultProps = {
  setData: '',
};
export default PageNotFound;
