export const aws_request = () => {
  var aws = require('aws-sdk');
  aws.config.update({
    region: `${process.env.REACT_APP_COGNITO_RegionName}`,

    accessKeyId: `${process.env.REACT_APP_COGNITO_AccessKey}`, // should be:  process.env.AWS_ACCESS_ID
    secretAccessKey: `${process.env.REACT_APP_COGNITO_SecretKey}`,
  });

  var cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider();
  return cognitoidentityserviceprovider;
};
