import { withTranslation, useTranslation } from 'react-i18next';
import React, { Suspense, useState, useEffect, useRef } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import searchUserAnimation from 'assets/img/searchUser.gif';
import userNotFoundAnimation from 'assets/img/userNotFound.gif';
import './ManageServices.scss';
import SearchBar from './searchBar';
import DisplayUserList from './displayUserList';
import UserModel from './userModel';
import EditPasswordPopup from './editPasswordPopup';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'app/modules/Shared/Alert/Alert';
import EditUserModel from './editUserModal';
import Cookies from 'js-cookie';

function ManageServices(props) {
  const { t } = useTranslation();
  const { roles} = props;
  const [userList, setUserList] = useState([]);
  const [userModelVal, setUserModelVal] = useState();
  const [isAdmin , setIsAdmin] = useState(false);
  const [editModelVal, setEditModelVal] = useState({
    showPasswordModel: false,
    'User Name': '',
  });
  const [alertVal, setAlertVal] = useState({
    alertStatus: '',
    alertText: '',
  });

  useEffect(()=>{
    if(roles.includes('openidm-admin')){
        setIsAdmin(true);
      }else{
        setIsAdmin(false);
      }
  },[]);

  const [userNotFoundError, setUserNotFoundError] = useState('');
  const [userEditModelVal, setUserEditModelVal] = useState({
    showModal: false,
    userDetails: {}
  });
  const searchBarRef = useRef();

  const handleModelClose = async (status, val = null) => {
    let alertText =
      status === 'success'
        ? 'pages.editName.successMsgother'
        : 'pages.editNamePopup.errorMsg';

        let finalStatus = status;
        if(status === 'addUser'){
          alertText = 'pages.Admin.addUser'
          finalStatus = "success";
        }

    searchBarRef.current.searchUser();
    await setAlertVal({
      showEditPopup: false,
      alertStatus: finalStatus,
      alertText: alertText,
    });

    setTimeout(() => {
      setAlertVal({
        alertText: '',
        alertStatus: '',
      });
    }, 3000);
  };

  return (
    <div>
      {!isAdmin && (<div><h1>{t('pages.Admin.notEntitled')}</h1></div>)}
      {isAdmin && (<>
      <div className="row mx-5 mb-5">
        <h1>{t('pages.mainMenu.manageServices')}</h1>
      </div>
        <div className="">
          <div className="">
            {alertVal.alertText && (
              <Alert
                alertText={alertVal.alertText}
                className={alertVal.alertStatus}
                onAlertClose={() => {
                  setAlertVal({
                    alertText: '',
                    alertStatus: '',
                  });
                }}
              />
            )}

            {userModelVal && (
              <UserModel
                userModelVal={userModelVal}
                handleModelClose={handleModelClose}
                setUserModelVal={setUserModelVal}
              />
            )}

            {userEditModelVal.showModal && (
              <EditUserModel
                userEditModelVal={userEditModelVal}
                handleModelClose={handleModelClose}
                setUserEditModelVal={setUserEditModelVal}
              />
            )}

            {editModelVal.showPasswordModel && (
              <EditPasswordPopup
                handleModelClose={handleModelClose}
                editModelVal={editModelVal}
                setEditModelVal={setEditModelVal}
              />
            )}
            <Row className='search-row'>
              <Col style={{display:"inline-grid"}}>
            <SearchBar
              setUserList={setUserList}
              setUserNotFoundError={setUserNotFoundError}
              roles={roles}
              ref={searchBarRef}
            />
            <div
              className="addUser"
              onClick={() => {
                setUserModelVal('add');
              }}
            >
            <FontAwesomeIcon icon={faPlus} style={{marginRight:'10px'}}/>
              {t('pages.ManageServices.addUser')}
            </div>
            </Col>
            </Row>
            {/* <DisplayUserList userList={userList} setUserEditModelVal={setUserEditModelVal} setEditModelVal={setEditModelVal} setUserList={setUserList} /> */}
            {userNotFoundError == '' && (
              <div>
                <AnimatePresence>
                  {userList.length > 0 ? (
                    <motion.div
                      exit={{ x: -2000 }}
                      transition={{ duration: 0.8 }}
                      className="w-100 idproofing-banner-container"
                    >
                      <DisplayUserList
                        userList={userList}
                        setUserList={setUserList}
                        setEditModelVal={setEditModelVal}
                        setUserEditModelVal={setUserEditModelVal}
                      />
                    </motion.div>
                  ) : null}
                </AnimatePresence>
                <AnimatePresence>
                  {userList.length <= 0 ? (
                    <motion.div
                      exit={{ x: -2000 }}
                      transition={{ duration: 0.8 }}
                      className="w-100 idproofing-banner-container"
                    >
                      <img src={searchUserAnimation} alt="loading..." style={{width:'20%', marginLeft: '35%'}}/>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            )}
            <AnimatePresence>
              {userNotFoundError != '' ? (
                <motion.div
                  exit={{ x: -2000 }}
                  transition={{ duration: 0.8 }}
                  className="w-100 idproofing-banner-container"
                >
                  <p className="error">{t(userNotFoundError)}</p>
                  <img src={userNotFoundAnimation} alt="loading..." style={{width:'20%', marginLeft: '35%'}}/>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>
        </div>
        </>)}</div>
  );
}

ManageServices.propTypes = {
  roles: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ManageServices);
