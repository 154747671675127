import { withTranslation, useTranslation } from 'react-i18next';

import http from 'api/http-request';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import 'app/modules/Login/Login.scss';

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { getNestedObject } from 'utils/common';

function scrollToTop() {
  let scrollElement = document.getElementsByClassName('App-View')[0];
  scrollElement
    ? scrollElement.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    : '';
}
function getId(strtext) {
  let index1 = strtext.indexOf('from(');
  let index2 = strtext.indexOf(',', index1);
  let newStr = strtext.slice(index1 + 5, index2);

  return newStr;
}
function getPublicKey(strtext) {
  let index1 = strtext.indexOf('[');
  let index2 = strtext.indexOf(']');
  let newStr = strtext.slice(index1 + 1, index2);
  let arr = newStr.split(',');
  let newarr = arr.map((e) => {
    return parseInt(e);
  });

  return newarr;
}
function getPublicKeyFromString(strtext) {
  let withoutLineBreaks = strtext.replace(/[\r\n]/gm, '');
  withoutLineBreaks = withoutLineBreaks.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '');

  let index1 = strtext.indexOf('publicKey');
  let index2 = strtext.indexOf(';', index1);
  let newStr = strtext.slice(index1 + 12, index2);
  //newStr = newStr.replace(/&quot;/gi, '"');
  newStr = newStr.replace('// Relying Party:', '');
  newStr = newStr.replace('// User:', '');
  newStr = newStr.replace(/\n/g, '');
  newStr = newStr.replace(/ /g, '');
  newStr = newStr.replace(/newInt8Array/, 'new Int8Array');
  newStr = newStr.replace(/returnc/, 'return c');
  console.log(newStr);
  let publicKey = getPublicKey(newStr);
  let id = getId(newStr);
  return { publicKey: publicKey, id: id };
}
const YubikeyLogin = (props) => {
  const [userName, setuserName] = useState(props.userName);
  const [password, setPassword] = useState('');
  const [screen, setScreen] = useState(0);
  const [payLoad, setPayLoad] = useState({});
  const [errorText, setError] = useState('');
  const [jsonChallenge, setJsonChallenge] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    authenticate();
  }, []);

  const challengeResponse = (jsonChallengeArg, payLoadArg) => {
    console.log('start');

    console.log('exact start');
    let payLoad = payLoadArg;
    console.log(jsonChallengeArg);
    console.log(payLoad);
    /*let tmp = {
      challenge: new Int8Array([
        26,
        55,
        126,
        -70,
        -65,
        106,
        -64,
        -64,
        127,
        -85,
        17,
        63,
        -101,
        -67,
        100,
        71,
        -37,
        -36,
        60,
        35,
        -65,
        -104,
        31,
        -64,
        105,
        0,
        -71,
        -82,
        -107,
        -125,
        -63,
        46,
      ]).buffer,
      rp: { name: 'ForgeRock' },
      user: {
        id: Uint8Array.from('c2FoaXNvbmk', function (c) {
          return c.charCodeAt(0);
        }),
        name: 'sahisoni',
        displayName: 'sahisoni',
      },
      pubKeyCredParams: [
        { type: 'public-key', alg: -7 },
        { type: 'public-key', alg: -257 },
      ],
      attestation: 'none',
      timeout: 60000,
      excludeCredentials: [],
      authenticatorSelection: { userVerification: 'preferred' },
    };*/
    navigator.credentials
      .create({ publicKey: jsonChallengeArg })
      .then(function (newCredentialInfo) {
        var rawId = newCredentialInfo.id;
        var clientData = String.fromCharCode.apply(
          null,
          new Uint8Array(newCredentialInfo.response.clientDataJSON)
        );
        var keyData = new Int8Array(
          newCredentialInfo.response.attestationObject
        ).toString();

        payLoad.callbacks[2].input[0].value =
          clientData + '::' + keyData + '::' + rawId;
        console.log(payLoad);
        http(
          {
            url: 'yubikey',
            method: 'POST',
            data: payLoad,
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        )
          .then((res) => {
            console.log(JSON.stringify(res.data));
            loginResponse(res);
          })
          .catch((err) => {
            console.log(err);
            //props.showError('pages.login.incorrectLogin');
          });
      });
  };

  const showError = (errorText) => {
    //console.log('pages.login.incorrectLogin');
    setError(errorText);

    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    if (errorText) {
      props.showError('pages.login.incorrectLogin');
      window.location.href = '/';
    }
  };
  function authenticate() {
    http(
      {
        url: 'yubikey',
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      setPayLoad(res.data);
    });
  }

  const updateCredential = (e, data) => {
    e.preventDefault();
    payLoad.callbacks[0].input[0].value = data;

    http(
      {
        url: 'yubikey',
        method: 'POST',
        data: payLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (
        res.data.code == 401 &&
        res.data.reason == 'Unauthorized' &&
        res.data.message == 'Login failure'
      ) {
        showError('pages.login.incorrectLogin');
      } else {
        showError('');
        loginResponse(res);
      }
    });
  };

  const loginResponse = (res) => {
    scrollToTop();
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
      const environment = process.env.REACT_APP_ENV;
      if (environment !== 'dev') {
        window.location.href =
          'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
      } else {
        window.location.href =
          'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
      }
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.authId || res.data.tokenId)
      ) {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = sanitizeUrl(`/profile/Dashboard`);
          // localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res, ['data', 'callbacks'])) {
          const {
            data: { callbacks },
          } = res;
          if (callbacks) {
            if (
              callbacks[0].output[0].value === 'User Name' &&
              callbacks[1].output[0].value === 'Password'
            ) {
              showError('pages.login.incorrectLogin');
            } else if (callbacks[0].type === 'PasswordCallback') {
              setPayLoad(res.data);
              setScreen(1);
            } else if (
              callbacks[0].type === 'TextOutputCallback' ||
              callbacks[0].type === 'MetadataCallback'
            ) {
              handleChallenge(res.data);
            } else if (
              (res.data.code === 401 &&
                res.data.message === 'Authentication Failed') ||
              res.data.message === 'Login failure'
            ) {
              props.showError('pages.login.incorrectLogin');
              window.location.href = '/';
            } else {
              setState({
                userOption: res.data.callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 2,
                loginPayLoad: res.data,
              });
            }
          }
        }
      }
    }
  };

  const handleChallenge = async (res) => {
    console.log(res);
    let payLoad = res;

    console.log(JSON.stringify(res.data));
    getPublicKeyFromString(res.callbacks[0].output[0].value);

    if (!window.PublicKeyCredential) {
      console.log('not working');
    }

    const { publicKey, id } = getPublicKeyFromString(
      res.callbacks[0].output[0].value
    );
    console.log(publicKey, id);
    console.log(new Int8Array(publicKey).buffer);
    console.log(
      Uint8Array.from(id, function (c) {
        return c.charCodeAt(0);
      })
    );
    /* 
    console.log(res.callbacks[0].output[0]);
    let challenge = res.callbacks[0].output[0].value.challenge;
    let userId = res.callbacks[0].output[0].value.userId;
    */

    let jsonChallenge = {
      challenge: new Int8Array(publicKey).buffer,
      rp: { name: 'ForgeRock' },
      user: {
        id: await Uint8Array.from(id, function (c) {
          return c.charCodeAt(0);
        }),
        name: userName,
        displayName: userName,
      },
      pubKeyCredParams: [
        { type: 'public-key', alg: -7 },
        { type: 'public-key', alg: -257 },
      ],
      attestation: 'none',
      timeout: 60000,
      excludeCredentials: [],
      authenticatorSelection: { userVerification: 'preferred' },
    };

    challengeResponse(jsonChallenge, payLoad);
  };

  return (
    <>
      <div className="radiobox-container container my-5 forgot-username hypr">
        <div className="mt-3 mb-3 choose-title">
          {t('pages.login.yubiKeyLogin')}
        </div>
        {screen === 0 && (
          <Form>
            <Form.Group
              className="verification-form"
              controlId="verificationCode"
            >
              <Form.Label className="mb-4 mt-3">
                {t('pages.login.userId')} / {t('pages.registration.userName')}
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setuserName(e.target.value.trim());
                }}
                value={userName}
              />
              <p className="error-text" id="firstname-error">
                {errorText && t(errorText)}
              </p>
            </Form.Group>

            <div className="row pt-3">
              <div className="d-sm-flex col-lg-6 col-md-12">
                <Button
                  className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                  onClick={(e) => props.onBackLogin(e)}
                >
                  {t('pages.forgotUserId.backBtn')}
                </Button>
                <Button
                  className="col btn btn-secondary py-2 my-3"
                  type="submit"
                  onClick={(e) => updateCredential(e, userName)}
                >
                  {t('pages.forgotPassword.nextBtn')}
                </Button>
              </div>
            </div>
          </Form>
        )}

        {screen === 1 && (
          <Form>
            <Form.Group
              className="verification-form"
              controlId="verificationCode"
            >
              <Form.Label className="mb-4 mt-3">
                {t('pages.registration.password')}
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                }}
                value={password}
              />
              <p className="error-text" id="firstname-error">
                {errorText && t(errorText)}
              </p>
            </Form.Group>

            <div className="row pt-3">
              <div className="d-sm-flex col-lg-6 col-md-12">
                <Button
                  className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                  onClick={(e) => props.onBackLogin(e)}
                >
                  {t('pages.forgotUserId.backBtn')}
                </Button>
                <Button
                  className="col btn btn-secondary py-2 my-3"
                  type="submit"
                  onClick={(e) => updateCredential(e, password)}
                >
                  {t('pages.forgotPassword.nextBtn')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

YubikeyLogin.propTypes = {
  t: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  onBackLogin: PropTypes.func.isRequired,
  showError: PropTypes.func.showError,
};
export default withTranslation()(YubikeyLogin);
