import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/ForgotUserId/ForgotUserId.scss';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import http from 'api/http-request';
import { getNestedObject, pageNotFound } from 'utils/common';
import Radio from 'app/modules/Shared/Radio/Radio';
import Alert from 'app/modules/Shared/Alert/Alert';
import { Redirect } from 'react-router-dom';

class ForgotUserId extends Component {
  constructor(props) {
    super(props);
    this.verificationInputRef = React.createRef();
    this.state = {
      recapSiteKey: '',
      authID: '',
      mail: '',
      emailError: '',
      stepNames: [
        'pages.formNavigator.lookup',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification',
      ],
      redirect: false,
      showalert: '',
      maxStep: 2,
      screen: 0,
      currentStep: 0,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      token: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      responseToken: '',
      userName: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      radioOptionError: '',
      errorText: '',
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      changeMethod: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      userExist: '',
      securityQuestionError: '',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // first call
  getToken = (event) => {
    event.preventDefault();
    const { mail } = this.state;
    http(
      {
        url: 'forgotUserName',
        method: 'POST',
        data: {
          Email: mail,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200) {
        this.increaseCurrentStepHandler();

        const {
          data: {
            data: { callbacks, authId },
          },
        } = res;

        this.setState({
          recapSiteKey: callbacks[0].output[0].value,
          authID: authId,
          showGoogleCaptcha: true,
          screen: 1,
        });
      } else {
        if (mail === '') {
          this.setState({
            emailError: true,
            userExist: false,
          });
        } else {
          this.setState({
            userExist: true,
            emailError: false,
          });
        }
      }
    });
  };

  //captcha

  captchaValidation = () => {
    const { googleCaptchaResponse, authID } = this.state;
    http(
      {
        url: 'forgotUserName',
        method: 'POST',
        data: {
          authId: authID,
          RecaptchaValue: googleCaptchaResponse,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200) {
        const {
          data: {
            data: { callbacks, authId },
          },
        } = res;
        this.increaseCurrentStepHandler();
        this.setState({
          screen: 2,
          kba: callbacks[0].output[1].value[0],
          authID: authId,
        });
      }
    });
  };

  isEmailValid = (value) => {
    if (
      value.length <= 0 ||
      !value.match(
        /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
      )
    ) {
      this.setState({
        emailError: true,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };
  onRecoverUserName = (e) => {
    e.preventDefault();
    const { mail, emailError } = this.state;
    if (mail === '' || emailError) {
      this.setState({
        emailError: true,
      });
      this.showError('A valid email address is required');
    } else if (mail !== '' && !emailError) {
      this.getToken(e);
      this.setState({
        errorText: '',
      });
    }
  };

  // getForgotUserToken = () => {
  //   const { mail } = this.state;
  //   http(
  //     {
  //       url: 'selfservice/username?_action=submitRequirements',
  //       data: { input: { queryFilter: `mail eq "${mail}"` } },
  //       method: 'POST',
  //     },
  //     {
  //       'X-OpenIDM-NoSession': true,
  //       'X-OpenIDM-Username': 'anonymous',
  //       'X-OpenIDM-Password': 'anonymous',
  //     }
  //   ).then((res) => {
  //     if (res.data.type === 'captcha') {
  //       const token = getNestedObject(res, ['data', 'token']);
  //       if (token) {
  //         this.setState({
  //           token,
  //         });
  //       }
  //       const recaptchaSiteKey = getNestedObject(res, [
  //         'data',
  //         'requirements',
  //         'properties',
  //         'response',
  //         'recaptchaSiteKey',
  //       ]);
  //       if (recaptchaSiteKey) {
  //         this.setState({
  //           showGoogleCaptcha: true,
  //           googleCaptchaToken: recaptchaSiteKey,
  //           screen: 1,
  //         });
  //         this.increaseCurrentStepHandler();
  //       } else {
  //         this.setState({
  //           showSuccessMsg: true,
  //         });
  //       }
  //       this.setState({
  //         responseToken: res.data.token,
  //       });
  //     } else if (
  //       res.data.code === 400 &&
  //       res.data.message === 'USER_NOT_FOUND'
  //     ) {
  //       this.showError('User not found');
  //       this.setState({
  //         userExist: true,
  //       });
  //     } else if (
  //       res.data.code === 400 &&
  //       res.data.message === 'Snapshot token is invalid'
  //     ) {
  //       this.showError('User not found');
  //       this.setState({
  //         userExist: true,
  //       });
  //       pageNotFound(res.data);
  //     }
  //   });
  // };

  increaseCurrentStepHandler = () => {
    const { recapSiteKey } = this.state;
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 0,
      currentStep: 0,
      verificationOption: '',
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      verificationCodeError: '',
      verificationCode: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      userExist: false,
      securityQuestionError: '',
      radioOptionError: '',
    });
  };

  decreaseCurrentStepHandler = (e) => {
    e.preventDefault();
    this.setState({
      userExist: false,
    });
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };

  sendToLogin = () => {
    return this.props.history.push('/');
  };

  onCaptchaChange = (value) => {
    if (value) {
      this.setState(
        {
          googleCaptchaResponse: value,
        },
        () => {
          this.forgotUserNameSubmit();
        }
      );
    } else {
      this.setState({
        googleCaptchaResponse: '',
      });
    }
  };

  forgotUserNameSubmit = () => {
    const { googleCaptchaResponse, authID } = this.state;
    this.setState({
      errorText: '',
    });
    http({
      url: 'forgotUserName',
      data: {
        authId: authID,
        RecaptchaValue: googleCaptchaResponse,
      },
      method: 'POST',
    }).then((res) => {
      if (res.status === 200) {
        const {
          data: {
            data: { callbacks, authId },
          },
        } = res;
        this.increaseCurrentStepHandler();
        this.setState({
          screen: 2,
          kba: callbacks[0].output[1].value[0],
          authID: authId,
        });
      }
      /*
      if (
        res.status === 200 &&
        res.data &&
        res.data.type === 'codeValidation' &&
        res.data.tag === 'VALIDATE_CODE'
      ) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
          });
        }
        this.setState({
          screen: 3,
          verificationOption:
            res.data.requirements.properties.verificationCode.description,
        });
        this.increaseCurrentStepHandler();
      } else if (
        res.status === 200 &&
        res.data &&
        res.data.type === 'userOTPChoice' &&
        res.data.tag === 'initial'
      ) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
          });
        }
        this.setState({
          screen: 2,
          userEmail: res.data.requirements.userEmail,
          userPhone: res.data.requirements.userPhone,
          kba: res.data.requirements.KBA,
        });
        this.increaseCurrentStepHandler();
      }
      */
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { verificationCode, authID } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });

      http(
        {
          url: 'forgotUserName',
          data: {
            authId: authID,
            OTPChoice: 'Validate',
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data) {
          const {
            data: {
              data: { authId },
            },
          } = res;
          this.setState({
            authID: authId,
          });
        }

        http(
          {
            url: 'forgotUserName',
            data: {
              authId: this.state.authID,
              'One Time Password': verificationCode,
            },
            method: 'POST',
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        ).then((res) => {
          if (res.status === 200) {
            const {
              data: {
                data: { authId, Message },
              },
            } = res;
            this.setState({
              authID: authId,
            });
            if (
              res.data.data.callbacks[0].type == 'SuspendedTextOutputCallback'
            ) {
              this.setState({
                screen: 4,
              });
            } else {
              this.setState({
                verificationCodeError: 'pages.editEmail.invalidOTP',
              });
            }
          } else {
            this.setState({
              showalert: 'errors.maxVerifyAttempt',
            });
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 3000);
          }
        });
      });
    }
  };

  accountVerificationPreferenceChange = (e) => {
    e.preventDefault();
    const { authID } = this.state;
    http(
      {
        url: 'resetForgotPassword',
        data: {
          authId: authID,
          confirmationChoice: 'yes',
        },
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        const {
          data: {
            data: { authId },
          },
        } = res;
        this.setState({
          authID: authId,
          verificationOption: true,
          securityOption: false,
          screen: 3,
        });
      } else {
        this.setState({
          showalert: 'errors.maxQuestionAttempt',
        });
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);
      }
    });
  };

  onVerifyQuestion = (e) => {
    e.preventDefault();
    const { securityQuestionValue, authID, securityOption } = this.state;
    if (securityQuestionValue === '') {
      this.setState({
        securityQuestionError: 'pages.forgotUserId.securityQuestionRequired',
      });
    } else {
      http(
        {
          url: 'forgotUserName',
          data: {
            authId: authID,
            Question1: securityOption,
            Answer1: securityQuestionValue,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200) {
          const {
            data: {
              data: { authId },
            },
          } = res;
          this.setState({
            authID: authId,
          });
          if (
            res.data.data.callbacks[0].type == 'SuspendedTextOutputCallback'
          ) {
            this.setState({
              screen: 4,
            });
          } else {
            this.setState({
              securityQuestionValue: '',
              securityQuestionError: 'pages.forgotUserId.invalidSecurityAnswer',
              securityOption: res.data.data.callbacks[0].output[0].value,
            });
          }
        } else {
          this.setState({
            showalert: 'errors.maxQuestionAttempt',
          });
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 3000);
        }
        // this.sendToLogin(e);
        // alert('You have entered incorrect answer for more than three times. Please retry.');
      });
    }
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 3000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });

    this.setState({
      errorText: '',
    });
    const { authID } = this.state;
    http(
      {
        url: 'forgotUserName',
        data: {
          authId: authID,
          OTPChoice: 'Resend',
        },
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200) {
        const {
          data: {
            data: { authId },
          },
        } = res;

        this.setState({
          verificationCode: '',
          verificationCodeError: '',
          authID: authId,
        });
      } else {
        this.setState({
          showalert: 'errors.maxOTPAttempt',
        });
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);

        // alert('Resend OTP was clicked more than 3 times. Please retry. ');
        // this.sendToLogin(e);
      }
    });
  };

  onOptionsChange = (value) => {
    if (value.includes('@')) {
      value = 'mail';
    } else {
      value = 'kba';
    }

    this.setState({
      selectedOption: value,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { authID, selectedOption } = this.state;
    let selectedValue;
    if (selectedOption === 'mail') {
      selectedValue = 'OTP over Email';
      this.setState({
        verificationOption: true,
      });
    } else {
      selectedValue = 'Security Questions';
      this.setState({
        securityOption: true,
      });
    }
    this.setState({
      errorText: '',
    });
    if (selectedValue) {
      http(
        {
          url: 'forgotUserName',
          data: {
            authId: authID,
            choice: selectedValue,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200) {
          const {
            data: {
              data: { callbacks, authId },
            },
          } = res;
          if (res.data.data.callbacks[0].output[0].name === 'message') {
            this.setState({
              screen: 6,
              authID: authId,
            });
            if (selectedValue === 'Security Questions') {
              this.setState({
                securityOption: callbacks[0].output[0].value,
              });
            }
          } else {
            this.setState({
              screen: 3,
              authID: authId,
            });
            if (selectedValue === 'Security Questions') {
              this.setState({
                securityOption: callbacks[0].output[0].value,
              });
            }
          }
        }
      });
    } else {
      this.setState({
        radioOptionError: 'pages.forgotUserId.radioCodeRequired',
      });
    }
  };

  changeMethod = (changeMethod) => {
    const { responseToken } = this.state;
    let selectedValue = changeMethod;
    if (changeMethod === 'PHONE') {
      selectedValue = 'PHONE';
    } else {
      selectedValue = 'MAIL';
    }
    this.verificationInputRef.current.__clearvalues__();
    this.setState({
      errorText: '',
      verificationCodeError: '',
    });
    http(
      {
        url: 'selfservice/username?_action=submitRequirements',
        data: {
          input: {
            changeMethod: selectedValue,
          },
          token: responseToken,
        },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (res.status === 200 && res.data) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
          });
        }
        this.setState({
          screen: 3,
          verificationOption:
            res.data.requirements.properties.verificationCode.description,
          changeMethod:
            res.data.requirements.properties.changeMethod.description,
        });
      }
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;

    this.setState({ verificationCode });
  }
  handleQuestionChange(evt) {
    const securityQuestionValue = evt.target.validity.valid
      ? evt.target.value
      : this.state.securityQuestionValue;

    this.setState({ securityQuestionValue });
  }

  render() {
    const {
      mail,
      emailError,
      currentStep,
      maxStep,
      recapSiteKey,
      stepNames,
      screen,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
      verificationOption,
      verificationCodeError,
      userEmail,
      userPhone,
      selectedOption,
      kba,
      securityOption,
      userExist,
      securityQuestionError,
      alertText,
      alertStatus,
      radioOptionError,
      redirect,
      showalert,
    } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { showalert },
          }}
        />
      );
    }
    const { t } = this.props;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="container my-5 forgot-username">
          {/* {errorText && <WithError errors={errorText} />} */}
          <div className="col">
            <h1>{t('pages.forgotUserId.header2')}</h1>
          </div>
          <div>
            <FormNavigationBar
              currentStep={currentStep}
              numFormSteps={maxStep + 1}
              stepNames={stepNames}
              t={t}
            />
          </div>
          {currentStep === 0 && screen === 0 && (
            <>
              <div className="col">
                <h3 className="m-0">{t('pages.forgotUserId.instructions')}</h3>
              </div>
              <Form className="col-md-6 mt-3 pt-4">
                <Form.Group controlId="loginEmail">
                  <Form.Label>
                    {t('pages.forgotUserId.emailAddress')}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      this.setState({
                        mail: e.target.value.trim().toLowerCase(),
                      });
                      this.isEmailValid(e.target.value.trim().toLowerCase());
                    }}
                    value={mail}
                    className={
                      emailError || userExist ? 'error-input' : undefined
                    }
                    aria-required
                    aria-invalid={!!emailError}
                    aria-describedby="email-error"
                  />
                  <p
                    className="error-text forgot-error-username"
                    id="email-error"
                  >
                    {emailError && t('errors.emailError')}
                    {userExist && t('errors.userExist')}
                  </p>
                </Form.Group>
                <div className="row mt-5">
                  <div className="d-flex col-md-6">
                    <Button
                      className="wrapper__container-card-body-submit"
                      size="lg"
                      variant="secondary"
                      block
                      type="submit"
                      onClick={(e) => this.getToken(e)}
                    >
                      {t('pages.forgotUserId.recoverUsername')}
                    </Button>
                  </div>
                </div>
                <div>
                  <p className="return-text mt-4">
                    {t('pages.forgotUserId.returnTo')}
                    <span className="bgColor">
                      <a
                        href="#"
                        id="registerPolicy"
                        className="pl-2"
                        onClick={(e) => this.decreaseCurrentStepHandler(e)}
                      >
                        {t('pages.forgotUserId.loginBtn')}
                      </a>
                    </span>
                  </p>
                </div>
              </Form>
            </>
          )}
          {currentStep === 1 && showGoogleCaptcha && screen === 1 && (
            <>
              <div className="col">
                <h2 className="mb-4">
                  {t('pages.registration.fillOutCaptcha')}
                </h2>
              </div>
              <div className="col-md-6 mt-3 mb-9">
                <div className="google-captcha">
                  <div className="google-captcha__box">
                    {
                      <GoogleCaptcha
                        sitekey={recapSiteKey}
                        onCaptchaChange={this.onCaptchaChange}
                        captchaReset={captchaReset}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="d-sm-flex col-lg-4 col-md-12">
                <button
                  className="col btn btn-secondary hollow mr-sm-4  py-2 my-3"
                  size="lg"
                  variant="secondary"
                  block
                  type="submit"
                  onClick={(e) => this.decreaseCurrentStepHandler(e)}
                >
                  {t('pages.forgotUserId.backBtn')}
                </button>
              </div>
            </>
          )}
          {currentStep === 2 && screen === 3 && verificationOption && (
            <div className="col-md-12 registration__container">
              <div className="registration__container__resend-verification mt-0">
                <div className="registration__container__resend-verification__resend-text">
                  <h3>
                    {t('pages.login.verificationEmail')} {verificationOption}
                  </h3>
                </div>
              </div>
              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className="ml-3"
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__resend-verification__resend-text">
                  <p
                    className="resend-link"
                    onClick={(e) => this.resendVerify(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
                <div
                  id="pease_note"
                  className="registration__container__pleasenote"
                >
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>

                    <button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={(e) => this.onVerify(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 3 && securityOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.forgotUserId.securityQuestion')}</span>
              </h3>
              <Form className="col-md-6 mt-3 pt-4 pl-0">
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">{securityOption}</Form.Label>
                  <Form.Control
                    type="text"
                    onInput={this.handleQuestionChange.bind(this)}
                  />
                  {securityQuestionError && (
                    <p className="error-text" id="verification-error">
                      {securityQuestionError && t(securityQuestionError)}
                    </p>
                  )}
                </Form.Group>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-12 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onVerifyQuestion(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 2 && mail && !verificationOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.forgotUserId.verificationMethod')}</span>
              </h3>
              <Form className="radio-form">
                {mail && (
                  <Radio
                    key={mail}
                    name={mail}
                    label={`${t('pages.forgotUserId.emailCode')}${' '}${mail}`}
                    value={mail}
                    isChecked={selectedOption === 'mail' ? true : false}
                    handleChange={this.onOptionsChange}
                  />
                )}
                {kba && (
                  <Radio
                    key={kba}
                    name={kba}
                    label={t('pages.forgotUserId.answerSecurityQuestion')}
                    value={kba}
                    isChecked={selectedOption === 'kba' ? true : false}
                    handleChange={this.onOptionsChange}
                  />
                )}
                {radioOptionError && (
                  <p className="error-text" id="verification-error">
                    {radioOptionError && t(radioOptionError)}
                  </p>
                )}
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onNext(e)}
                    >
                      {t('pages.forgotUserId.nextBtn')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 2 && screen === 4 && (
            <>
              <SuccessConfirmation
                header={t('pages.forgotUserId.successBannerHeader')}
                successMessage={t('pages.forgotUserId.username')}
              />
              <div className="row mt-5">
                <div className="d-sm-flex col-lg-4 col-md-12">
                  <Button
                    className="col btn btn-secondary py-2 my-3"
                    size="lg"
                    variant="secondary"
                    block
                    type="submit"
                    onClick={(e) => this.sendToLogin(e)}
                  >
                    {t('pages.forgotUserId.loginBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {screen === 6 && securityOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{securityOption}</span>
              </h3>
              <Form className="col-md-6 mt-3 pt-4 pl-0">
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.forgotUserId.otpContinue')}
                  </Form.Label>
                </Form.Group>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-12 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      // onClick={this.decreaseCurrentStepHandler}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) =>
                        this.accountVerificationPreferenceChange(e)
                      }
                    >
                      {t('pages.registration.registration')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </>
    );
  }
}
ForgotUserId.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(ForgotUserId);
