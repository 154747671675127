import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard Cognito/Home.scss';
import { withTranslation } from 'react-i18next';
import heroImage from 'assets/img/mountains-hero-image.jpg';
import http from 'api cognito/http-request';
import AlertGlobal from '../Shared/AlertGlobal Cognito/AlertGlobal';
import _ from 'lodash';
import { clearCookies } from 'utils/common cognito';
import Cookies from 'js-cookie';
import { getAdminUser } from 'api cognito/aws4_module';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails ? props.userDetails : {},
    };
  }

  getUserDetails = (token) => {
    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
      }
    ).then((res) => {
      if (res.data && res.status === 200) {
        const { setUserDetails } = this.props;
        let data = res.data;
        data.UserAttributes.map((elem, index) => {
          if (elem.Name == 'custom:Confirm_Email') {
            data.mail = elem.Value;
          } else if (elem.Name == 'custom:Last_Name') {
            data.sn = elem.Value;
          } else if (elem.Name == 'custom:First_Name') {
            data.givenName = elem.Value;
          }
        });
        setUserDetails(data);

        let { checkAdminUser } = this.props;

        this.setState(
          {
            userDetails: res.data,
          },
          () => {
            checkAdminUser(data.Username);
          }
        );
      } else if (
        res.status === 400 &&
        res.data.__type === 'NotAuthorizedException'
      ) {
        clearCookies();
        window.location.href = '/';
      }
    });
  };

  getAdminUser = async () => {
    const { setUserDetails } = this.props;
    var username = Cookies.get('userName');
    var output = await getAdminUser(username);

    if (output) {
      setUserDetails(output);
      let { checkAdminUser } = this.props;
      this.setState(
        {
          userDetails: output,
        },
        () => {
          checkAdminUser(this.state.userDetails.Username);
        }
      );
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let token = Cookies.get('dataStoreToken');

    let code = Cookies.get('code');
    let username = Cookies.get('userName');

    if (code && username) {
      this.getAdminUser();
    }
    if (token && !code) {
      this.getUserDetails(token);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userDetails !== prevProps.userDetails) {
      this.setState({
        userDetails: this.props.userDetails,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { userDetails } = this.state;
    return (
      <>
        <div>
          <div>
            <AlertGlobal useTicker={false}></AlertGlobal>
          </div>
          <div className="home-page__container m-md-5">
            <div className="home-page__container-content">
              <div>
                <div className="home-page__container-content-wrapper">
                  <div className="w-100 image-banner"></div>
                  <h1>
                    {t('pages.homePage.welcomeText')},{' '}
                    {userDetails.UserAttributes
                      ? userDetails.UserAttributes[7].Value
                      : ''}{' '}
                    {userDetails.UserAttributes
                      ? userDetails.UserAttributes[4].Value
                      : ''}
                  </h1>
                  <div className="row mt-5">
                    <div className="col-12 pb-5 pb-md-4">
                      <div className="card box-shadow-default p-3 h-100">
                        <h2 className="home-page__container-content-wrapper__title p-0">
                          {t('pages.homePage.newsTitle')}
                        </h2>
                        <div className="home-page__container-content-wrapper__description">
                          <p>{t('pages.homePage.newsDescription')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card box-shadow-default p-3 h-100">
                        <h2 className="home-page__container-content-wrapper__title p-0">
                          {t('pages.homePage.companyUpdate')}
                        </h2>
                        <p className="home-page__container-content-wrapper__description">
                          {t('pages.homePage.companyDescription')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Home.propTypes = {
  setUserDetails: PropTypes.func.isRequired,
  checkAdminUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object,
  userDetails: {},
};
Home.defaultProps = {
  userData: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
  userDetails: {},
};
export default withTranslation()(Home);
