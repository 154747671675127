import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import 'app/modules/Login/Login.scss';
import { withTranslation } from 'react-i18next';
import http from 'api/http-request';
import Form from 'react-bootstrap/Form';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { getNestedObject, randomCount } from 'utils/common';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import Radio from 'app/modules/Shared/Radio/Radio';
import QRCode from 'qrcode.react';
import { faCircleNotch, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import Carousel1 from 'assets/img/florida-homes-hero-image.jpg';
import Carousel2 from 'assets/img/mountains-hero-image.jpg';
import Carousel3 from 'assets/img/nyc.jpg';
import Carousel4 from 'assets/img/tampa-bay-hero-image.jpg';
import Carousel5 from 'assets/img/nyc1.jpg';
import Carousel6 from 'assets/img/san-usa.jpg';
import Carousel7 from 'assets/img/atlanta-usa.jpg';
import AlertGlobal from 'app/modules/Shared/AlertGlobal/AlertGlobal';
import YubikeyLogin from 'app/modules/Login/YubikeyLogin';
const CryptoJS = require('crypto-js');
import { Redirect } from 'react-router-dom';

class LoginMobile extends Component {
  constructor(props) {
    super(props);
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    if (iPlanetDirectoryPro) {
      window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
    }
    this.state = {
      redirect: false,
      showalert: '',
      userName: '',
      password: '',
      showPassword: false,
      errorText: '',
      isAuthenticated: false,
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      screen: 1,
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCode: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
      qrURL: '',
      showAlert: '',
      loginQRCode: '',
      devicePayLoad: { metadata: { hardware: {}, platform: {}, browser: {} } },
      selectedRegisterOption: '',
      countInterval: 0,
      socialLogin: false,
      carouselImages: [
        { image: Carousel1 },
        { image: Carousel2 },
        { image: Carousel3 },
        { image: Carousel4 },
        { image: Carousel5 },
        { image: Carousel6 },
        { image: Carousel7 },
      ],
      imageIndex: null,
      authID: '',
      isGooglein: null,
      loginUrl: '',
    };
  }

  displayRandomImage = () => {
    this.setState({
      imageIndex: randomCount(),
    });
  };

  changeLanguage = (lang) => {
    setTimeout(() => {
      i18n.changeLanguage(lang);
    }, 0);
  };

  getHeaders = () => {
    return {
      Authorization: `${process.env.AUTHORIZATION_HEADER}`,
      // Cookie: `${process.env.COOKIE_HEADER}`,
    };
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 3000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  componentDidMount() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const environment = process.env.REACT_APP_ENV;
    const state = query.get('state');
    const code = query.get('code');
    Cookies.set('DeviceUsed', 'mobile');
    this.setState({
      isGooglein: state,
    });

    if (environment === 'dev') {
      this.setState({
        loginUrl: process.env.REACT_APP_LOGIN_MOBILE,
      });
    } else {
      this.setState({
        loginUrl: process.env.REACT_APP_LOGIN_LOCAL_MOBILE,
      });
    }

    this.scrollToTop();
    if (Cookies.get('socialLogin')) {
      this.socialLoginMFA();
    }
    this.setDefaultLanguage();
    this.displayRandomImage();
    if (this.props.history.action === 'REPLACE') {
      const { showalert } = this.props.location.state || { showalert: '' };
      this.showError(showalert);
    }
  }

  setDefaultLanguage = () => {
    localStorage.setItem('ctsessionlanguage', 'en_US');
    Cookies.set('ctsessionlanguage', 'en_US');

    this.changeLanguage('en_US');
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      screen: 1,
      userName: '',
      password: '',
      showPassword: '',
      errorText: '',
      isAuthenticated: '',
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
    });
  };

  genericResponse = (res) => {
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.authId || res.data.tokenId)
      ) {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
          localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res, ['data'])) {
          this.setState({
            loginPayLoad: res.data,
            isAuthenticated: true,
            loginToken: res.data.authId,
          });
        }
      }
    } else if (
      res.data.code === 401 &&
      res.data.message === 'Authentication Failed'
    ) {
      this.showError('pages.login.incorrectLogin');
    } else if (
      res.data.code === 401 &&
      res.data.message === 'Session has timed out'
    ) {
      this.showError('pages.login.sessionTimedOut');
    } else if (
      res.data.code === 401 &&
      res.data.message ===
        'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
    ) {
      this.showError('pages.login.willGetLocked');
    } else if (
      res.data.code === 401 &&
      res.data.message ===
        'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
    ) {
      this.showError('pages.login.willGetLocked2');
    }
  };

  loginResponse = (res) => {
    this.scrollToTop();
    if (
      getNestedObject(res.data, ['data', 'successUrl']) ||
      getNestedObject(res.data, ['data', 'tokenId'])
    ) {
      window.location.href = 'sanitizeUrl(`/profile/DashboardMobile`);';

      window.location.href = this.state.loginUrl;
    } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.data.authId || res.data.data.tokenId)
      ) {
        if (
          getNestedObject(res.data, ['data', 'successUrl']) ||
          getNestedObject(res.data, ['data', 'tokenId'])
        ) {
          window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
          // localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
          const {
            data: {
              data: { callbacks },
            },
          } = res;
          if (callbacks) {
            if (
              callbacks[0].output[0].value === 'User Name' &&
              callbacks[1].output[0].value === 'Password'
            ) {
              this.showError('pages.login.incorrectLogin');
            }
            //  else if (callbacks[0].type === 'DeviceProfileCallback') {
            //   this.setState({
            //     loginPayLoad: res.data,
            //   });
            //   this.deviceCheck();
            // } else if (callbacks[0].output[0].value === 'One Time Password') {
            else if (
              callbacks[0].output[0].value === 'One Time Password' ||
              callbacks[0].output[0].value.trim() === 'OTP Preference'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                authID: res.data.data.authId,
                screen: 3,
                loginPayLoad: res.data,
              });
            } else if (
              callbacks[0].output[0].value === 'Enter verification code'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 5,
                loginPayLoad: res.data,
              });
            } else if (
              callbacks[0].output[0].value.includes('Scan the barcode')
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 4,
                loginPayLoad: res.data,
                scanTitle: callbacks[0].output[0].value,
                qrCodeUrl: callbacks[2].output[0].value,
              });
              if (this.state.qrCodeUrl.includes('pushauth')) {
                setInterval(this.getPushNotification(), 2000);
                this.setState({
                  countInterval: this.state.countInterval + 1,
                });
              }
            } else {
              this.setState({
                userOption: callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 2,
                loginPayLoad: res.data.data,
              });
            }
          }
        }
      }
    }
  };

  onLogin = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });
    const { userName, password, socialLogin } = this.state;
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    if (!socialLogin) {
      Cookies.remove('socialLogin');
      Cookies.remove('email');
    }

    if (userName === '' || password === '') {
      this.showError('errors.userNamePassword');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: 'userLogin',
          method: 'POST',
          data: {
            'User Name': userName,
            Password: encrypted.toString(),
          },
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
          ...this.getHeaders(),
        }
      ).then((res) => {
        //this.genericResponse(res);
        if (res.status == 200) {
          this.loginResponse(res);
        } else if (res.status == 401) {
          this.showError('pages.login.incorrectLogin');
        } else {
          this.showError('pages.pageNotFound.title');
        }
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  showPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  PasswordValidation = (e) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/)) {
      this.setState({
        password: value,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  onDeregister = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });
    const { loginToken, socialLogin, userName } = this.state;
    if (!socialLogin) {
      Cookies.remove('socialLogin');
      Cookies.remove('email');
    }

    if (!loginToken) {
      this.showError('errors.sessionTimedOut');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: 'userLogin',
          method: 'POST',
          data: {
            'User Name': userName,
            authId: loginToken,
            'Authentication Preference': 'De-Register',
          },
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status == 200) {
          this.onBackLogin(e);
        } else if (res.status == 401) {
          this.showError('pages.login.sessionTimedOut');
        }
      });
    }
  };

  onResubmit = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });
    const { loginToken, socialLogin, userName, verificationCode } = this.state;
    if (!socialLogin) {
      Cookies.remove('socialLogin');
      Cookies.remove('email');
    }

    if (!loginToken) {
      this.showError('errors.sessionTimedOut');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: 'userLogin',
          method: 'POST',
          data: {
            'User Name': userName,
            authId: loginToken,
            'Authentication Preference': 'Re-Submit',
          },
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        //this.genericResponse(res);
        if (res.status == 200) {
          if (getNestedObject(res.data, ['data', 'successUrl'])) {
            window.location.href = 'sanitizeUrl(`/profile/DashboardMobile`);';

            window.location.href = this.state.loginUrl;
          } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
            if (
              res.status === 200 &&
              res.data &&
              (res.data.data.authId || res.data.data.tokenId)
            ) {
              if (getNestedObject(res.data, ['data', 'successUrl'])) {
                window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
                // localStorage.setItem('userLoggedIn', true);
                Cookies.set('userLoggedIn', true);
              } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
                const {
                  data: {
                    data: { callbacks },
                  },
                } = res;
                if (callbacks) {
                  if (callbacks[0].output[0].value === 'One Time Password') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 3,
                      loginPayLoad: res.data,
                    });
                  } else if (
                    callbacks[0].output[0].value === 'Enter verification code'
                  ) {
                    this.setState({
                      // isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      verificationCode: verificationCode,
                      userName: userName,
                    });

                    this.onVerificationCodeSubmit(e);
                  } else if (
                    callbacks[0].output[0].value.includes('Scan the barcode')
                  ) {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 4,
                      loginPayLoad: res.data,
                      scanTitle: callbacks[0].output[0].value,
                      qrCodeUrl: callbacks[2].output[0].value,
                    });
                    if (this.state.qrCodeUrl.includes('pushauth')) {
                      setInterval(this.getPushNotification(), 2000);
                      this.setState({
                        countInterval: this.state.countInterval + 1,
                      });
                    }
                  } else if (callbacks[0].type === 'ChoiceCallback') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.authId,
                      screen: 6,
                      loginPayLoad: res.data,
                      userOption: '',
                    });
                  } else if (callbacks[0].type === 'PollingWaitCallback') {
                    this.setState({
                      loginPayLoad: res.data,
                      screen: 7,
                      userOption: res.data.callbacks[0].output,
                    });
                    setInterval(this.getPushNotification(), 3000);
                  } else {
                    this.setState({
                      userOption: res.data.callbacks[0].output[1].value,
                      isAuthenticated: true,
                      loginToken: res.data.authId,
                      screen: 2,
                      loginPayLoad: res.data,
                    });
                  }
                }
              }
            }
          }
        } else if (res.status == 401) {
          this.showError('pages.login.sessionTimedOut');
        }
      });
    }
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });

    this.setState({
      errorText: '',
    });
    const { authID, socialLogin, userName } = this.state;
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'userLogin';
    }
    http(
      {
        url: url,
        data: {
          authId: authID,
          OTPChoice: 'Resend',
          'User Name': userName,
        },
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        const {
          data: {
            data: { authId },
          },
        } = res;

        this.setState({
          verificationCode: '',
          verificationCodeError: '',
          authID: authId,
        });
      } else {
        this.showError('errors.maxOTPAttempt');
        setTimeout(() => {
          window.location.href = sanitizeUrl(`/`);
        }, 3000);
      }
    });
  };

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const {
      loginPayLoad,
      verificationCode,
      loginToken,
      userName,
      socialLogin,
    } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      let url = '';
      if (socialLogin) {
        url =
          'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
      } else {
        url = 'userLogin';
      }
      http(
        {
          url: url,
          data: {
            authId: loginToken,
            'User Name': userName,
            'Enter verification code': verificationCode,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (
          res.status === 200 &&
          res.data.iamcode === 'IAM000' &&
          getNestedObject(res.data, ['data', 'successUrl'])
        ) {
          // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
          Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
            domain: '.aegis-frk.com',
          });
          Cookies.set('username', userName);
          Cookies.set('firstLoginSession', true);
          window.location.href = this.state.loginUrl;
        } else if (res.status === 200 && res.data.iamcode === 'IAM000') {
          this.setState({
            verificationCode: '',
          });

          if (getNestedObject(res.data, ['data', 'callbacks'])) {
            if (res.status === 401) {
              window.location.href = '/';
            }
            if (
              res.status === 200 &&
              res.data &&
              (res.data.data.authId || res.data.data.tokenId)
            ) {
              if (getNestedObject(res.data, ['data', 'successUrl'])) {
                window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
                Cookies.set('userLoggedIn', true);
              } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
                const {
                  data: {
                    data: { callbacks },
                  },
                } = res;
                if (callbacks) {
                  if (callbacks[0].output[0].value === 'One Time Password') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 3,
                      loginPayLoad: res.data,
                      verificationCodeError:
                        'pages.forgotUserId.invalidVerificationCode',
                    });
                  } else if (
                    callbacks[0].output[0].value ===
                    'Choose Authentication Preference'
                  ) {
                    this.showError('pages.login.loginFailure');
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 12,
                      loginPayLoad: res.data,
                    });
                  } else if (callbacks[0].type === 'ChoiceCallback') {
                    this.setState({
                      isAuthenticated: true,
                      loginToken: res.data.data.authId,
                      screen: 6,
                      loginPayLoad: res.data,
                      userOption: res.data.callbacks[0].output,
                    });
                  } else {
                    this.setState({
                      userOption: res.data.callbacks[0].output[1].value,
                      isAuthenticated: true,
                      loginToken: res.data.dataauthId,
                      screen: 2,
                      loginPayLoad: res.data,
                    });
                  }
                }
              }
            }
          } else if (
            res.data.code === 401 &&
            res.data.message ===
              'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
          ) {
            this.showError('pages.login.willGetLocked');
          } else if (
            res.data.code === 401 &&
            res.data.message ===
              'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
          ) {
            this.showError('pages.login.willGetLocked2');
          } else if (
            res.data.code === 401 &&
            res.data.message === 'Login failure'
          ) {
            window.location.href = '/';
            this.showError('pages.login.loginFailure');
          }
        } else {
          this.setState({
            alertText: 'Some error occured.',
            alertStatus: 'error',
          });
        }
      });
    }
  };

  onOneTimePasswordSubmit = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const {
      loginPayLoad,
      verificationCode,
      loginToken,
      userName,
      socialLogin,
      authID,
    } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      let url = '';
      if (socialLogin) {
        url =
          'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
      } else {
        url = 'userLogin';
      }
      http(
        {
          url: url,
          data: {
            authId: authID,
            OTPChoice: 'Validate',
            'User Name': userName,
          },
          method: 'POST',
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data.iamcode === 'IAM000') {
          const {
            data: {
              data: { authId },
            },
          } = res;
          this.setState(
            {
              authID: authId,
            },
            () => {
              http(
                {
                  url: url,
                  method: 'POST',
                  data: {
                    'User Name': userName,
                    authId: this.state.authID,
                    'One Time Password': verificationCode,
                  },
                },
                {
                  'Accept-API-Version': 'protocol=1.0,resource=2.1',
                  ...this.getHeaders(),
                }
              ).then((res) => {
                if (getNestedObject(res.data, ['data', 'successUrl'])) {
                  // const environment = process.env.REACT_APP_ENV;
                  // Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId);
                  Cookies.set('iPlanetDirectoryPro', res.data.data.tokenId, {
                    domain: '.aegis-frk.com',
                  });
                  Cookies.set('username', userName);
                  Cookies.set('firstLoginSession', true);
                  // window.location.href = this.state.loginUrl;
                  window.location.href = sanitizeUrl(
                    `/profile/DashboardMobile`
                  );
                } else if (
                  res.status === 401 &&
                  res.data.iamcode === 'IAM0039'
                ) {
                  this.showError('errors.maxVerifyAttempt');
                  setTimeout(() => {
                    window.location.href = sanitizeUrl(`/`);
                  }, 3000);
                } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
                  if (res.status === 401) {
                    window.location.href = '/';
                  }
                  if (
                    res.status === 200 &&
                    res.data &&
                    (res.data.data.authId || res.data.data.tokenId)
                  ) {
                    if (getNestedObject(res.data, ['data', 'successUrl'])) {
                      window.location.href = sanitizeUrl(
                        `/profile/DashboardMobile`
                      );
                      // localStorage.setItem('userLoggedIn', true);
                      Cookies.set('userLoggedIn', true);
                    } else if (
                      getNestedObject(res.data, ['data', 'callbacks'])
                    ) {
                      const {
                        data: {
                          data: { callbacks },
                        },
                      } = res;
                      if (callbacks) {
                        if (
                          callbacks[0].output[0].value === 'OTP Preference '
                        ) {
                          this.setState({
                            isAuthenticated: true,
                            authID: res.data.data.authId,
                            screen: 3,
                            loginPayLoad: res.data,
                            verificationCodeError:
                              'pages.forgotUserId.invalidVerificationCode',
                          });
                        } else if (
                          callbacks[0].output[0].value ===
                          'Choose Authentication Preference'
                        ) {
                          this.showError('pages.login.loginFailure');
                          this.setState({
                            isAuthenticated: true,
                            loginToken: res.data.data.authId,
                            screen: 12,
                            loginPayLoad: res.data,
                          });
                        } else if (callbacks[0].type === 'ChoiceCallback') {
                          this.setState({
                            isAuthenticated: true,
                            loginToken: res.data.data.authId,
                            screen: 6,
                            loginPayLoad: res.data,
                            userOption: res.data.callbacks[0].output,
                          });
                        } else {
                          this.setState({
                            userOption: res.data.callbacks[0].output[1].value,
                            isAuthenticated: true,
                            loginToken: res.data.dataauthId,
                            screen: 2,
                            loginPayLoad: res.data,
                          });
                        }
                      }
                    }
                  }
                } else if (
                  res.data.code === 401 &&
                  res.data.message ===
                    'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
                ) {
                  this.showError('pages.login.willGetLocked');
                } else if (
                  res.data.code === 401 &&
                  res.data.message ===
                    'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
                ) {
                  this.showError('pages.login.willGetLocked2');
                } else if (
                  res.data.code === 401 &&
                  res.data.message === 'Login failure'
                ) {
                  window.location.href = '/';
                  this.showError('pages.login.loginFailure');
                }
              });
            }
          );
        } else {
          this.setState({
            alertText: 'Some error occured.',
            alertStatus: 'error',
          });
        }
      });
    }
  };

  getPushNotification = () => {
    const { loginPayLoad, socialLogin } = this.state;
    this.scrollToTop();
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url =
        'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
                if (this.state.qrCodeUrl.includes('pushauth')) {
                  setTimeout(() => {
                    setInterval(this.getPushNotification(), 10000);
                  }, 2000);
                  this.setState({
                    countInterval: this.state.countInterval + 1,
                  });
                }
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 6,
                  loginPayLoad: res.data,
                  userOption: res.data.callbacks[0].output,
                });
              } else if (callbacks[0].type === 'PollingWaitCallback') {
                this.setState({
                  loginPayLoad: res.data,
                  screen: 7,
                  userOption: res.data.callbacks[0].output,
                });
                setInterval(this.getPushNotification(), 3000);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'Login failure'
      ) {
        window.location.href = '/';
        //this.showError('errors.loginApiError');
      }
    });
  };

  onNext = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginToken, selectedOption, socialLogin, userName } = this.state;
    // loginPayLoad.callbacks[0].input[0].value = selectedOption;
    let url = '',
      mfaPreference = '';

    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'userLogin';
    }

    if (selectedOption === 0) {
      mfaPreference = 'OTP over Email';
    } else if (selectedOption === 1) {
      mfaPreference = 'Authenticator App';
    } else if (selectedOption === 2) {
      mfaPreference = 'Push Authentication';
    } else if (selectedOption === 3) {
      mfaPreference = 'OTP over SMS';
    } else if (selectedOption === 4) {
      mfaPreference = 'OTP over Phone Call';
    }

    http(
      {
        url: url,
        method: 'POST',
        data: {
          'User Name': userName,
          authId: loginToken,
          choice: mfaPreference,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      if (getNestedObject(res.data, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/DashboardMobile`);';

        window.location.href = this.state.loginUrl;
      } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.data.authId || res.data.data.tokenId)
        ) {
          if (getNestedObject(res.data, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res.data, ['data', 'callbacks'])) {
            const {
              data: {
                data: { callbacks },
              },
            } = res;
            if (callbacks) {
              if (
                callbacks[0].output[0].value === 'One Time Password' ||
                callbacks[0].output[0].value.trim() === 'OTP Preference'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.data.authId,
                  authID: res.data.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: callbacks[0].output[0].value,
                  qrCodeUrl: callbacks[2].output[0].value,
                });
                if (this.state.qrCodeUrl.includes('pushauth')) {
                  setInterval(this.getPushNotification(), 2000);
                  this.setState({
                    countInterval: this.state.countInterval + 1,
                  });
                }
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 6,
                  loginPayLoad: res.data,
                  userOption: '',
                });
              } else if (callbacks[0].type === 'PollingWaitCallback') {
                this.setState({
                  loginPayLoad: res.data,
                  screen: 7,
                  userOption: res.data.callbacks[0].output,
                });
                setInterval(this.getPushNotification(), 3000);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      }
    });
    this.scrollToTop();
  };

  onNextRegister = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginPayLoad, selectedRegisterOption, socialLogin } = this.state;
    if (selectedRegisterOption === 'Yes') {
      loginPayLoad.callbacks[0].input[0].value = 0;
    } else if (selectedRegisterOption === 'No') {
      loginPayLoad.callbacks[0].input[0].value = 1;
    }
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url =
        'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
        ...this.getHeaders(),
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/DashboardMobile`);';
        // const environment = process.env.REACT_APP_ENV;
        Cookies.set('firstLoginSession', true);

        window.location.href = this.state.loginUrl;
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/DashboardMobile`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'User Locked Out.'
      ) {
        this.showError('pages.login.userLockedOut');
        window.location.href = '/';
      }
    });
  };
  createSession = () => {
    createNewSession(
      'Fingerprint',
      'firstname,did,userIdList',
      'qrcode',
      null,
      function (result, error) {
        client_dataRecieved(result);
      }
    );
  };
  client_dataRecieved = (result) => {
    let str = JSON.stringify(result, null, 4);
    var aurl =
      'http://forgerock.blockid.co/XUI/?realm=/&service=BlockIDTree&BlockIDAuthn=' +
      window.btoa(str);
    window.location.href = aurl;
  };
  generateQRCode = () => {
    let loginQRCode =
      'http://chart.apis.google.com/chart?cht=qr&chl=google.com&chs=248';
    this.setState({
      loginQRCode: loginQRCode,
    });
    this.createSession();
  };
  onNextQR = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginToken, socialLogin, qrCodeUrl, userName } = this.state;
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'userLogin';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: {
          'User Name': userName,
          authId: loginToken,
          mfaDeviceRegistration: qrCodeUrl,
        },
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      // this.setState({ authId: res.data.data.authId });
      // this.setState({screen: 6});

      if (
        res.status === 200 &&
        res.data &&
        (res.data.data.authId || res.data.data.tokenId)
      ) {
        if (getNestedObject(res.data, ['data', 'callbacks'])) {
          const {
            data: {
              data: { callbacks },
            },
          } = res;
          if (callbacks) {
            if (callbacks[0].output[0].value === 'One Time Password') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 3,
                loginPayLoad: res.data,
              });
            } else if (
              callbacks[0].output[0].value.includes('Scan the barcode')
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 4,
                loginPayLoad: res.data,
                scanTitle: callbacks[0].output[0].value,
                qrCodeUrl: callbacks[2].output[0].value,
              });
              if (this.state.qrCodeUrl.includes('pushauth')) {
                setInterval(this.getPushNotification(), 3000);
                this.setState({
                  countInterval: this.state.countInterval + 1,
                });
              }
            } else if (
              callbacks[0].output[0].value === 'Enter verification code'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 5,
                loginPayLoad: res.data,
              });
            } else if (callbacks[0].type === 'ChoiceCallback') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.data.authId,
                screen: 6,
                loginPayLoad: res.data,
                userOption: res.data.callbacks[0].output,
              });
            } else if (
              callbacks[0].output[0].value ===
              'Choose Authentication Preference'
            ) {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 12,
                loginPayLoad: res.data,
              });
            } else {
              this.setState({
                userOption: callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 2,
                loginPayLoad: res.data,
              });
            }
          }
        }
      }
    });
  };

  render() {
    const {
      userName,
      password,
      showPassword,
      errorText,
      errorArray,
      screen,
      userOption,
      selectedOption,
      verificationCode,
      verificationCodeError,
      verificationOption,
      scanTitle,
      qrCodeUrl,
      selectedRegisterOption,
      carouselImages,
      imageIndex,
      redirect,
      alertText,
      showalert,
      loginQRCode,
    } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { showalert },
          }}
        />
      );
    }

    const { t } = this.props;
    return (
      <>
        {/* {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )} */}
        {!this.state.isGooglein && (
          <div
            className={`login-container
            ${screen > 1 ? 'has-background' : undefined}`}
          >
            {(errorText || errorArray.length > 0) && (
              <div className="error-div">
                <WithError errors={errorText} errorArray={errorArray} />
              </div>
            )}
            {/* {this.props.match.path==='/forgot-password' && 
          (<div>Error</div>)} */}
            {screen === 1 && (
              <div className="log-container">
                <div className="row justify-content-center">
                  <div className="col-md-6 p-0">
                    <div className="mt-3">
                      <AlertGlobal useTicker={false}></AlertGlobal>
                    </div>

                    <div className="row justify-content-center pt-5">
                      <Form className="login-form p-3">
                        <h1>{t('pages.login.login')}</h1>
                        <Form.Group controlId="loginEmail">
                          <Form.Label>{t('pages.login.userId')}</Form.Label>
                          <Form.Control
                            type="email"
                            onChange={(e) => {
                              this.setState({
                                userName: e.target.value.trim(),
                              });
                            }}
                            value={userName}
                            aria-required
                          />
                        </Form.Group>
                        <Form.Group
                          className="password-field position-relative"
                          controlId="loginPassword"
                        >
                          <Form.Label>{t('pages.login.password')}</Form.Label>
                          <Form.Control
                            className="login-password-input"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                              this.PasswordValidation(e);
                            }}
                            value={password}
                            aria-required
                            autoComplete="off"
                          />
                          <span
                            className={
                              showPassword
                                ? 'eye-icon login-password-eye show'
                                : 'eye-icon login-password-eye'
                            }
                            onClick={() => this.showPassword()}
                          />
                        </Form.Group>
                        <div className="row justify-content-center mt-3">
                          <div className="col">
                            <Button
                              className="btn w-100 btn btn-primary"
                              type="submit"
                              onClick={(e) => this.onLogin(e)}
                            >
                              {t('pages.login.logInBtnText')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <hr className="separator-login" />
                  </div>
                </div>
              </div>
            )}
            {screen === 2 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  {/* {localStorage.getItem('email') && ( */}
                  {Cookies.get('email') && (
                    <div>
                      {t('pages.login.socialLoggedIn')}{' '}
                      <span className="bold">
                        {/* {localStorage.getItem('email')} */}
                        {Cookies.get('email')}
                      </span>
                    </div>
                  )}
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.choseMFA')}
                  </div>
                  <div>
                    <Form className="login-radio-buttons">
                      {userOption.length > 0 &&
                        userOption.map((payLoad, index) => (
                          <Radio
                            key={index}
                            name={payLoad}
                            label={payLoad}
                            value={index}
                            isChecked={selectedOption === index ? true : false}
                            handleChange={this.onOptionsChange}
                          />
                        ))}
                      <div className="row mt-3 mt-sm-5">
                        <div className="d-sm-flex col-lg-6 col-md-12">
                          <Button
                            className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                            onClick={(e) => this.onBackLogin(e)}
                          >
                            {t('pages.forgotPassword.backBtn')}
                          </Button>
                          <Button
                            className="col btn btn-secondary py-2 my-3 "
                            type="submit"
                            onClick={(e) => this.onNext(e)}
                            disabled={selectedOption === ''}
                          >
                            {t('pages.editNumberPopup.next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}
            {screen === 3 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <h3>
                    <span>{t('pages.registration.verificationCodeSent')}</span>
                  </h3>
                  <Form>
                    <Form.Group
                      className="verification-form mt-5"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4">
                        {t('pages.registration.verificationCode')}
                      </Form.Label>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        onInput={this.handleChange.bind(this)}
                        className="ml-3"
                        value={verificationCode}
                      />
                      {verificationCodeError && (
                        <p className="error-text" id="verification-error">
                          {verificationCodeError && t(verificationCodeError)}
                        </p>
                      )}
                    </Form.Group>
                    <div className="registration__container__resend-verification__resend-text">
                      <p
                        className="resend-link"
                        onClick={(e) => this.resendVerify(e)}
                        role="button"
                        tabIndex={0}
                      >
                        {t('pages.registration.resendVerificationCode')}
                      </p>
                    </div>
                    <div className="registration__container__pleasenote">
                      <p className="registration__container__pleasenote-fp">
                        {t('pages.registration.note')}
                      </p>
                      <p className="registration__container__pleasenote-sp">
                        {t('pages.registration.verifyNote')}
                      </p>
                    </div>
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>

                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onOneTimePasswordSubmit(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {screen === 4 && (
              <>
                <div className="radiobox-container container my-5 forgot-username barcode-center">
                  <h4 className="mb-5">
                    <span>{scanTitle}</span>
                  </h4>
                  <QRCode value={qrCodeUrl} />
                  {qrCodeUrl.includes('pushauth') && (
                    <div className="mb-3 mt-5">
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="fa-spin"
                        role="img"
                      />
                      <span className="ml-3">
                        {t('pages.login.waitingResponse')}
                      </span>
                    </div>
                  )}
                  {!qrCodeUrl.includes('pushauth') && (
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3 "
                          type="submit"
                          onClick={(e) => this.onNextQR(e)}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {screen === 5 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <h3>
                    <span>{t('pages.login.verificationApp')}</span>
                  </h3>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.registration.verificationCode')}
                      </Form.Label>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        onInput={this.handleChange.bind(this)}
                        className="ml-3"
                        value={verificationCode}
                      />
                      {verificationCodeError && (
                        <p className="error-text" id="verification-error">
                          {verificationCodeError && t(verificationCodeError)}
                        </p>
                      )}
                    </Form.Group>
                    <div className="registration__container__pleasenote">
                      <p className="registration__container__pleasenote-fp">
                        {t('pages.registration.note')}
                      </p>
                      <p className="registration__container__pleasenote-sp">
                        {t('pages.registration.verifyNote')}
                      </p>
                    </div>
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onVerificationCodeSubmit(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {screen === 6 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <div className="mt-3 mb-3 choose-title">
                    {userOption[0].value}
                  </div>
                  <div>
                    <Form>
                      <Radio
                        key={'Yes'}
                        name={'Yes'}
                        label={'Yes'}
                        value={'Yes'}
                        isChecked={
                          selectedRegisterOption === 'Yes' ? true : false
                        }
                        handleChange={this.onDeviceOptionsChange}
                      />
                      <Radio
                        key={'No'}
                        name={'No'}
                        label={'No'}
                        value={'No'}
                        isChecked={
                          selectedRegisterOption === 'No' ? true : false
                        }
                        handleChange={this.onDeviceOptionsChange}
                      />
                      <div className="row pt-3">
                        <div className="d-sm-flex col-lg-6 col-md-12">
                          <Button
                            className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                            onClick={(e) => this.onBackLogin(e)}
                          >
                            {t('pages.forgotUserId.backBtn')}
                          </Button>
                          <Button
                            className="col btn btn-secondary py-2 my-3"
                            type="submit"
                            onClick={(e) => this.onNextRegister(e)}
                            disabled={selectedRegisterOption === ''}
                          >
                            {t('pages.editNumberPopup.next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}
            {screen === 7 && (
              <>
                <div className="radiobox-container container my-5 forgot-username waiting-response">
                  <div className="mt-3 mb-3 choose-title">
                    {userOption[1].value}
                  </div>
                </div>
              </>
            )}
            {screen === 9 && (
              <>
                <div className="radiobox-container container my-5 forgot-username waiting-response">
                  <div className="mt-3 mb-3 choose-title">
                    {userOption[1].value}
                  </div>
                </div>
              </>
            )}

            {screen === 8 && (
              <>
                <div className="radiobox-container container my-5 forgot-username hypr">
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.hyprLogin')}
                  </div>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.login.userId')} /{' '}
                        {t('pages.registration.userName')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.setState({
                            userName: e.target.value.trim(),
                          });
                        }}
                        value={userName}
                      />
                    </Form.Group>

                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onHyprAuthentication(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {screen === 10 && (
              <>
                <div className="radiobox-container container my-5 1kosmos barcode-center">
                  <h4 className="mb-5">
                    <span>{t('pages.login.1kosmosTitle')}</span>
                  </h4>
                  <QRCode value={this.state.qrURL} />
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3 "
                        type="submit"
                        onClick={(e) => this.on1kosmosLogin(e)}
                      >
                        {t('pages.forgotUserId.generateQRBtn')}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {screen === 11 && (
              <YubikeyLogin
                userName={userName}
                onBackLogin={this.onBackLogin}
                loginResponse={this.loginResponse}
                showError={this.showError}
              />
            )}

            {screen === 12 && (
              <>
                <div className="radiobox-container container my-5 forgot-username">
                  <h3>
                    <span>
                      {t('pages.registration.verificationCodeSentTo')}{' '}
                      {t('pages.registration.authenticatorApp')}
                    </span>
                  </h3>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.registration.verificationCode')}
                      </Form.Label>
                      <input
                        type="text"
                        pattern="[0-9]*"
                        onInput={this.handleChange.bind(this)}
                        className="ml-3"
                        value={verificationCode}
                      />
                      {verificationCodeError && (
                        <p className="error-text" id="verification-error">
                          {verificationCodeError && t(verificationCodeError)}
                        </p>
                      )}
                    </Form.Group>
                    <div className="registration__container__pleasenote">
                      <p className="registration__container__pleasenote-fp">
                        {t('pages.registration.note')}
                      </p>
                      <p className="registration__container__pleasenote-sp">
                        {t('pages.registration.verifyNote')}
                      </p>
                    </div>
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onDeregister(e)}
                          type="submit"
                        >
                          {t('pages.forgotUserId.deRegisterBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onResubmit(e)}
                          type="submit"
                        >
                          {t('pages.login.reSubmit')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}

            {screen === 13 && (
              <>
                <div className="radiobox-container container my-5 forgot-username passwordless">
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.passwordLessLogin')}
                  </div>
                  <Form>
                    <Form.Group
                      className="verification-form"
                      controlId="verificationCode"
                    >
                      <Form.Label className="mb-4 mt-3">
                        {t('pages.registration.userName')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.setState({
                            userName: e.target.value.trim(),
                          });
                        }}
                        value={userName}
                      />
                    </Form.Group>

                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onPasswordlessAuthentication(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}

            {screen === 14 && (
              <>
                <div className="radiobox-container container my-5 forgot-username hypr">
                  <div className="mt-3 mb-3 choose-title">
                    {t('pages.login.passwordLessLogin')}
                  </div>
                  <Form>
                    <Form.Group
                      className="password-field position-relative"
                      controlId="loginPassword"
                      style={{ width: '48%' }}
                    >
                      <Form.Label>{t('pages.login.password')}</Form.Label>
                      <Form.Control
                        className="login-password-input"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                          this.PasswordValidation(e);
                        }}
                        value={password}
                        aria-required
                        autoComplete="off"
                      />
                      <span
                        className={
                          showPassword
                            ? 'eye-icon login-password-eye show'
                            : 'eye-icon login-password-eye'
                        }
                        onClick={() => this.showPassword()}
                      />
                    </Form.Group>

                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          onClick={(e) => this.onPasswordlessRegistration(e)}
                          type="submit"
                        >
                          {t('pages.login.login')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}
LoginMobile.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
    history: PropTypes.object,
    search: PropTypes.object,
  }).isRequired,
  action: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withTranslation()(LoginMobile);
