import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/MainMenu.scss';
import {
  faHome,
  faStore,
  faIdCard,
  faWallet,
  faBell,
  faUser,
  faCogs,
  faUserShield,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar } from 'react-bootstrap';
import Cookies from 'js-cookie';

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.MobileNavbarToggle = React.createRef();
    this.state = {
      width: window?.innerWidth,
      admin: this.props.admin,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (this.props.admin || Cookies.get('admin') === 'true') {
      this.setState({ admin: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.admin != this.state.admin ||
      prevProps.admin != this.props.admin
    ) {
      if (this.props.admin || Cookies.get('admin')) {
        this.setState({ admin: true });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  toggleNavExpansion = () => {
    this.MobileNavbarToggle.current.click();
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { t, pageName, notificationAlerts } = this.props;
    const { width, admin } = this.state;

    return (
      <div>
        <Navbar
          expand="md"
          className="nav-style p-0 main-menu__options"
          role="navigation"
        >
          <div className="row align-items-center m-0">
            <div className="current-menu-style d-md-none col-8 col-sm-10">
              <ul className="list-unstyled mb-0 mr-auto">
                <li
                  className={`col-md-2 brand-bar  ${
                    pageName === 'Dashboard' ? 'active' : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center ${
                      pageName === 'Dashboard' ? 'active' : ''
                    }`}
                    to="/profile/Dashboard"
                  >
                    <FontAwesomeIcon
                      icon={faHome}
                      className="mr-3 mx-md-auto"
                      role="img"
                    />
                    <span>{t('pages.mainMenu.home')}</span>
                  </Link>
                </li>
                <li
                  className={`col-md-2 brand-bar ${
                    pageName === 'Apps' ? 'active' : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center ${
                      pageName === 'Apps' ? 'active' : ''
                    }`}
                    to="/profile/Apps"
                  >
                    <FontAwesomeIcon
                      icon={faStore}
                      className="mr-3 mx-md-auto"
                      role="img"
                    />
                    <span>{t('pages.mainMenu.apps')}</span>
                  </Link>
                </li>
                <li
                  className={`col-md-2 brand-bar ${
                    pageName === 'IDs' ? 'active' : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center ${
                      pageName === 'personalInformation' ? 'active' : ''
                    }`}
                    to="/profile/IDs"
                  >
                    <FontAwesomeIcon
                      icon={faIdCard}
                      className="mr-3 mx-md-auto"
                      role="img"
                    />
                    <span>{t('pages.mainMenu.ids')}</span>
                  </Link>
                </li>
                <li
                  className={`col-md-2 brand-bar ${
                    pageName === 'Wallet' ? 'active' : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center ${
                      pageName === 'Wallet' ? 'active' : ''
                    }`}
                    to="/profile/Wallet"
                  >
                    <FontAwesomeIcon
                      icon={faWallet}
                      className="mr-3 mx-md-auto"
                      role="img"
                    />
                    <span>{t('pages.mainMenu.wallet')}</span>
                  </Link>
                </li>
                <li
                  className={`col-md-2 brand-bar ${
                    pageName === 'Notifications' ? 'active' : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center ${
                      pageName === 'Notifications' ? 'active' : ''
                    }`}
                    to="/profile/Notifications"
                  >
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faBell}
                        role="img"
                        className="mr-4 mr-3 mx-md-auto"
                      ></FontAwesomeIcon>
                      <span className="notificationAlerts">
                        {notificationAlerts ? notificationAlerts : 0}
                      </span>
                    </div>
                    <span>{t('pages.mainMenu.notifications')}</span>
                  </Link>
                </li>
                <li
                  className={`col-md-2 brand-bar ${
                    pageName === 'Notifications' ? 'active' : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center ${
                      pageName === 'Notifications' ? 'active' : ''
                    }`}
                    to="/profile/ManageServices"
                  >
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faBell}
                        role="img"
                        className="mr-4 mr-3 mx-md-auto"
                      ></FontAwesomeIcon>
                      <span className="notificationAlerts">
                        {notificationAlerts ? notificationAlerts : 0}
                      </span>
                    </div>
                    <span>{t('pages.mainMenu.notifications')}</span>
                  </Link>
                </li>
                {/*  */}
                {admin && (
                  <li
                    className={`col-md-2 brand-bar ${
                      pageName === 'Admin' ? 'active' : 'd-none'
                    }`}
                  >
                    <Link
                      className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'Admin' ? 'active' : ''}`}
                      to="/profile/Admin"
                    >
                      <FontAwesomeIcon
                        icon={faUserShield}
                        className="mr-4 mx-md-auto"
                        size="lg"
                        role="img"
                      />
                      <span>{t('pages.mainMenu.admin')}</span>
                    </Link>
                  </li>
                )}
                {/*  */}
                <li
                  className={`col-md-2 brand-bar ${
                    pageName === 'Account' ||
                    pageName === 'changePassword' ||
                    pageName === 'AuthorizedApps' ||
                    pageName === 'PeopleSharing' ||
                    pageName === 'PrivacyConsent' ||
                    pageName === 'CommunicationPreferences' ||
                    pageName === 'ActivityLog' ||
                    pageName === 'SiteSettings' ||
                    pageName === 'AccountManagement' ||
                    pageName === 'Devices'
                      ? 'active'
                      : 'd-none'
                  }`}
                >
                  <Link
                    className={`preferences d-flex justify-content-center  ${
                      pageName === 'Account' ? 'active' : ''
                    }`}
                    to="/profile/Account"
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className="mr-3 mx-md-auto"
                      role="img"
                    />
                    <span>{t('pages.mainMenu.account')}</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-4 col-sm-2 text-center">
              <Navbar.Toggle
                aria-controls="responsive-navbar"
                className="ft-24 navbar-toggle"
                ref={this.MobileNavbarToggle}
              />
            </div>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul
              className={`main-menu__options list-unstyled w-100 mb-0 col-11 ${
                admin ? 'main-menu-margin' : ''
              } `}
              style={
                admin ? { 'margin-left': '3rem' } : { 'margin-left': '3rem' }
              }
            >
              <li
                className={`col-md-${admin ? '1' : '1'}   ${
                  pageName === 'Dashboard' ? 'active' : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'Dashboard' ? 'active' : ''}`}
                  to="/profile/Dashboard"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    className="mr-3 mx-md-auto"
                    role="img"
                  />
                  <span>{t('pages.mainMenu.home')}</span>
                </Link>
              </li>
              <li
                className={`col-md-${admin ? '2' : '1'}   ${
                  pageName === 'Apps' ? 'active' : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'Apps' ? 'active' : ''}`}
                  to="/profile/Apps"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <FontAwesomeIcon
                    icon={faStore}
                    className="mr-3 mx-md-auto"
                    role="img"
                  />
                  <span>{t('pages.mainMenu.apps')}</span>
                </Link>
              </li>

              <li
                className={`col-md-${admin ? '1' : '1'}   ${
                  pageName === 'IDs' ? 'active' : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'personalInformation' ? 'active' : ''}`}
                  to="/profile/IDs"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <FontAwesomeIcon
                    icon={faIdCard}
                    className="mr-3 mx-md-auto"
                    role="img"
                  />
                  <span>{t('pages.mainMenu.ids')}</span>
                </Link>
              </li>
              <li
                className={`col-md-${admin ? '2' : '1'}   ${
                  pageName === 'Wallet' ? 'active' : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'Wallet' ? 'active' : ''}`}
                  to="/profile/Wallet"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <FontAwesomeIcon
                    icon={faWallet}
                    className="mr-3 mx-md-auto"
                    role="img"
                  />
                  <span>{t('pages.mainMenu.wallet')}</span>
                </Link>
              </li>
              <li
                className={`col-md-${admin ? '2' : '1'}   ${
                  pageName === 'Notifications' ? 'active' : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'Notifications' ? 'active' : ''}`}
                  to="/profile/Notifications"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <div className="position-relative">
                    <FontAwesomeIcon
                      icon={faBell}
                      className="mr-3 mx-md-auto"
                      role="img"
                    ></FontAwesomeIcon>
                    <span className="notificationAlerts">
                      {notificationAlerts ? notificationAlerts : 0}
                    </span>
                  </div>
                  <span>{t('pages.mainMenu.notifications')}</span>
                </Link>
              </li>
              {/* <li
                className={`col-md-${admin ? '2' : '1'}   ${
                  pageName === 'ManageServices' ? 'active' : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'ManageServices' ? 'active' : ''}`}
                  to="/profile/ManageServices"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <div className="position-relative">
                    <FontAwesomeIcon
                      icon={faCogs}
                      className="mr-3 mx-md-auto"
                      role="img"
                    ></FontAwesomeIcon>
                  </div>
                  <span>{t('pages.mainMenu.manageServices')}</span>
                </Link>
              </li> */}
              {admin && (
                <li
                  className={`col-md-${admin ? '2' : '1'}   ${
                    pageName === 'Admin' ? 'active' : ''
                  }`}
                >
                  <Link
                    className={`preferences d-flex d-md-block justify-content-center
            ${pageName === 'Admin' ? 'active' : ''}`}
                    to="/profile/Admin"
                    onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                  >
                    <FontAwesomeIcon
                      icon={faUserShield}
                      className="mr-4 mx-md-auto"
                      size="lg"
                      role="img"
                    />
                    <span>{t('pages.mainMenu.admin')}</span>
                  </Link>
                </li>
              )}
              <li
                className={`col-md-${admin ? '2' : '1'}  ${
                  pageName === 'Account' ||
                  pageName === 'changePassword' ||
                  pageName === 'AuthorizedApps' ||
                  pageName === 'PeopleSharing' ||
                  pageName === 'CommunicationPreferences' ||
                  pageName === 'PrivacyConsent' ||
                  pageName === 'ActivityLog' ||
                  pageName === 'SiteSettings' ||
                  pageName === 'AccountManagement' ||
                  pageName === 'Devices'
                    ? 'active'
                    : ''
                }`}
              >
                <Link
                  className={`preferences d-flex d-md-block justify-content-center 
            ${pageName === 'Account' ? 'active' : ''}`}
                  to="/profile/Account"
                  onClick={width < 768 ? () => this.toggleNavExpansion() : ''}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    className="mr-3 mx-md-auto"
                    role="img"
                  />
                  <span>{t('pages.mainMenu.account')}</span>
                </Link>
              </li>
            </ul>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
MainMenu.propTypes = {
  admin: PropTypes.object,
  notificationAlerts: PropTypes.number,
  pageName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(MainMenu);
