import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/ChangePasswordPopup.scss';
import 'app/modules/Dashboard/ChangeRequestPopup.scss';

class ChangeRequestPopup extends Component {
  constructor(props) {
    super(props);
  }

  onChangeRequest = (e) => {
    e.preventDefault();
    const { onChangeRequestAccessHandler } = this.props;
    onChangeRequestAccessHandler('success');
  };

  render() {
    const { onClose, t, showText, showTextDescription } = this.props;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="change-password-popup change-request-access">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {showText}
                      {/* {t('pages.requestAccess.vehicleRegistraion')} */}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <div>
                    <p>{showTextDescription}</p>
                  </div>
                  <div className="terms-condition">
                    <p>{t('pages.requestAccess.termsandcondition')}</p>
                    <p>{t('pages.requestAccess.generalDisclaimer')}</p>
                    <p>{t('pages.requestAccess.termsUpdated')}</p>
                  </div>
                  <div className="form-check form-group">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="validationType"
                      id="requestAccessAccept"
                      defaultChecked={'true'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="requestAccessAccept"
                    >
                      {t('pages.requestAccess.agreeTerms')}
                    </label>
                  </div>
                  <div className="form-check form-group">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="validationType"
                      id="requestAccessNotAccept"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="requestAccessNotAccept"
                    >
                      {t('pages.requestAccess.notAgreeTerms')}
                    </label>
                  </div>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    onClick={(e) => this.onChangeRequest(e)}
                  >
                    {t('pages.requestAccess.requestAccess')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ChangeRequestPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showText: PropTypes.string,
  showTextDescription: PropTypes.string,
  onChangeRequestAccessHandler: PropTypes.func.isRequired,
};
export default withTranslation()(ChangeRequestPopup);
