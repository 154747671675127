import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import { isPasswordFormatValid, getNestedObject } from 'utils/common';
import 'app/modules/Dashboard/ChangePasswordPopup.scss';
import http from 'api/http-request';
import WithError from '../Shared/WithError/WithError';
import Cookies from 'js-cookie';
const CryptoJS = require('crypto-js');

class ChangePasswordPopup extends Component {
  constructor(props) {
    super(props);
    this.changePasswordPopupContainer = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.currentPasswordInputRef = React.createRef();
    this.newPasswordInputRef = React.createRef();
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      errorText: '',
      errorArray: [],
      payLoad: {},
    };
  }

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { currentPassword, newPassword, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'currentPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noCurrentPasswordError';
        }
        break;
      case 'newPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noNewPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        } else if (currentPassword === confirmPassword) {
          errorMsg = 'errors.currentNewPasswordSameError';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (newPassword !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  /*
  checkPreviousPassword = (e) => {
    e.preventDefault();
    let { currentPassword } = this.state;
    let userName = Cookies.get('userName');
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    http({
      url: `changePassword`,
      method: 'POST',
      data: {
        iPlanetDirectoryPro: iPlanetDirectoryPro,
        'User Name': userName,
        Password: currentPassword,
      },
    }).then((res) => {
      if (res.status == 200 && res.data.iamcode == 'IAM000') {
        this.onChangePassword(res.data.data.authId);
      } else if (res.status == 200 && res.data.iamcode == 'IAM0039') {
        this.setState({ currentPasswordError: 'errors.currentPasswordIssue' });
      }
    });
  };
  */
  onChangePassword = (e) => {
    e.preventDefault();
    const { newPassword, currentPassword } = this.state;
    const { onChangePasswordHandler } = this.props;
    let key = CryptoJS.enc.Latin1.parse('1234567812345678');
    let iv = CryptoJS.enc.Latin1.parse('1234567812345678');
    let currentPasswordEncrypted = CryptoJS.AES.encrypt(currentPassword, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    let newPasswordEncrypted = CryptoJS.AES.encrypt(newPassword, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    let userName = Cookies.get('username');
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    http(
      {
        url: `changePassword`,
        method: 'POST',
        data: {
          newPassword: newPasswordEncrypted.toString(),
          'User Name': userName,
          Password: currentPasswordEncrypted.toString(),
          iPlanetDirectoryPro: iPlanetDirectoryPro,
        },
      },
      {
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      if (
        res.status == 401 &&
        res.data.response == 'incorrect current password'
      ) {
        this.setState({ currentPasswordError: 'errors.currentPasswordIssue' });
      } else if (
        res.status == 401 &&
        (res.data.response == 'Unauthorized' ||
          res.data.response == 'Access Denied')
      ) {
        // Cookies.remove('userName');
        // Cookies.remove('admin');
        // Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        // window.location.href = '/';
        this.setState({
          // errorArray: 'Current password does not match',
          passwordError: 'Current password does not match',
        });
      } else if (
        res.status === 200 &&
        res.data &&
        res.data.iamcode == 'IAM000'
      ) {
        onChangePasswordHandler('success');
        this.setState({
          errorArray: '',
        });
      } else if (res.status === 400 && res.data.iamcode == 'IAM0055') {
        this.setState({
          passwordError: res.data.data.errorMessage,
        });
      }
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      passwordError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div
          className="change-password-popup"
          ref={this.changePasswordPopupContainer}
        >
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {t('pages.changePassword.changePassword')}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <Form.Group controlId="currentPassword">
                    <Form.Label>
                      {t('pages.changePassword.currentPassword')}
                    </Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState({
                          currentPassword: value,
                        });
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'currentPasswordError');
                      }}
                      password={currentPassword}
                      className={
                        currentPasswordError ? 'error-input' : undefined
                      }
                      autoFocus
                      ariaInvalid={!!currentPasswordError}
                      ariaDescribedby="current-password-error"
                      myRef={this.currentPasswordInputRef}
                    />
                    {currentPasswordError && (
                      <p className="error-text" id="verification-error">
                        {currentPasswordError && t(currentPasswordError)}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group controlId="newPassword">
                    <Form.Label>
                      {t('pages.changePassword.newPassword')}
                    </Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState({
                          newPassword: value,
                        });
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'newPasswordError');
                      }}
                      password={newPassword}
                      className={newPasswordError ? 'error-input' : undefined}
                      ariaInvalid={!!newPasswordError}
                      ariaDescribedby="new-password-error"
                      myRef={this.newPasswordInputRef}
                    />
                    {newPasswordError && (
                      <p className="error-text" id="verification-error">
                        {newPasswordError && t(newPasswordError)}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>
                      {t('pages.changePassword.confirmNewPassword')}
                    </Form.Label>
                    <PasswordField
                      onChange={(value) => {
                        this.setState(
                          {
                            confirmPassword: value,
                          },
                          () => {
                            this.isFormFieldValid(
                              value,
                              'confirmPasswordError'
                            );
                          }
                        );
                      }}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'confirmPasswordError');
                      }}
                      password={confirmPassword}
                      className={
                        confirmPasswordError ? 'error-input' : undefined
                      }
                      ariaInvalid={!!confirmPasswordError}
                      ariaDescribedby="confirm-password-error"
                      myRef={this.confirmPasswordInputRef}
                    />
                    {confirmPasswordError && (
                      <p className="error-text" id="verification-error">
                        {confirmPasswordError && t(confirmPasswordError)}
                      </p>
                    )}
                  </Form.Group>
                  <p className="error-text" id="email-error">
                    {passwordError && t(passwordError)}
                  </p>
                  <PasswordRequirements
                    password={newPassword}
                    formSubmitted={false}
                  />
                </div>
                <div className="change-password-popup__popup-footer">
                  {confirmPasswordError ||
                  newPasswordError ||
                  currentPasswordError ||
                  currentPassword.length <= 0 ||
                  newPassword.length <= 0 ||
                  confirmPassword.length <= 0 ? (
                    <Button
                      className="wrapper__container-card-body-submit"
                      size="lg"
                      variant="primary"
                      block
                      type="submit"
                      onClick={(e) => this.onChangePassword(e)}
                      disabled
                    >
                      {t('pages.changePassword.changePassword')}
                    </Button>
                  ) : (
                    <Button
                      className="wrapper__container-card-body-submit"
                      size="lg"
                      variant="primary"
                      block
                      type="submit"
                      onClick={(e) => this.onChangePassword(e)}
                    >
                      {t('pages.changePassword.changePassword')}
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ChangePasswordPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangePasswordHandler: PropTypes.func.isRequired,
};
export default withTranslation()(ChangePasswordPopup);
