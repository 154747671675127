import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ErrorIcon from 'assets/img/404-image.png';
import 'app/modules/Shared/SomethingWentWrong/SomethingWentWrong.scss';

const SomethingWentWrong = () => {
  const { t } = useTranslation();
  return (
    <Container className="wentwrong">
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <div role="alert" aria-live="assertive" className="wentwrong__block">
            <img
              src={ErrorIcon}
              alt="Error Icon"
              aria-hidden="true"
              className="wentwrong__block-icon"
            />
            <h1 className="wentwrong__block-title">
              {t('pages.pageNotFound.title')}
            </h1>
            <h6 className="wentwrong__block-desc">
              {t('pages.pageNotFound.desc')}
            </h6>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default SomethingWentWrong;
