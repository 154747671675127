import React from 'react';
import PropTypes from 'prop-types';

const SuccessConfirmation = (props) => {
  const { successMessage, header, username, emailVerify, passwordLessBanner} = props;
  return (
    <div>
      {!passwordLessBanner && <h2 className="mb-4">Confirmation</h2>}
      {/* <h2 className="mb-4">Confirmation</h2> */}
      <div className="alert alert-success">
        <h3>{header ? header : 'Success'}</h3>
        <p>
          {successMessage ? successMessage : ''} {username ? username : ''}
        </p>
        <p>{emailVerify ? emailVerify : ''}</p>
      </div>
    </div>
  );
};

SuccessConfirmation.propTypes = {
  props: PropTypes.any,
  header: PropTypes.string,
  successMessage: PropTypes.string,
  emailVerify: PropTypes.string,
  username: PropTypes.string,
  passwordLessBanner: PropTypes.bool,
};

export default SuccessConfirmation;
