import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown } from 'react-bootstrap';
import 'app/modules/Dashboard/RequestAccess.scss';
import ChangeRequestPopup from 'app/modules/Dashboard/ChangeRequestPopup';
import Alert from 'app/modules/Shared/Alert/Alert';

class RequestAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestPopup: false,
      showTextTitle: '',
      showTextDescription: '',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChangeRequestAccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.profile.successMsg'
        : 'pages.editNamePopup.errorMsg';
    this.setState(
      {
        showRequestPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };

  showChangeRequestPopup = (text) => {
    if (text === 'VehicleRegistration') {
      this.setState({
        showText: this.props.t('pages.requestAccess.vehicleRegistration'),
        showTextDescription: this.props.t(
          'pages.requestAccess.vehicleRegistraionApplication'
        ),
      });
    } else {
      this.setState({
        showText: this.props.t('pages.requestAccess.payTax'),
        showTextDescription: this.props.t(
          'pages.requestAccess.payTaxApplication'
        ),
      });
    }
    this.setState({
      showRequestPopup: true,
    });
  };

  render() {
    const { t } = this.props;
    const {
      showRequestPopup,
      showText,
      showTextDescription,
      alertText,
      alertStatus,
    } = this.state;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        {showRequestPopup && (
          <ChangeRequestPopup
            onClose={() => {
              this.setState({ showRequestPopup: false });
            }}
            showText={showText}
            showTextDescription={showTextDescription}
            onChangeRequestAccessHandler={this.onChangeRequestAccessHandler}
          />
        )}
        <div className="request-access">
          <h1>{t('pages.requestAccess.title')}</h1>
          <p>{t('pages.requestAccess.description')}</p>
          <hr className="separator-solid" />
          <div className="row">
            <div className="col-md-6">
              <DropdownButton
                title={t('pages.profile.pleaseSelect')}
                id="dropdown-menu-align-right"
              >
                <Dropdown.Item eventKey="">
                  {t('pages.requestAccess.pleaseSelect')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="Mr">
                  {t('pages.requestAccess.agencyConsumer')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="Mrs">
                  {t('pages.requestAccess.agencyVehicleRegistration')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="Miss">
                  {t('pages.requestAccess.agencyEducation')}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FontAwesomeIcon icon={faCartPlus} role="img" />
              <span>{t('pages.requestAccess.agencyConsumer')}</span>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <p
                className="request-link"
                onClick={() => {
                  this.showChangeRequestPopup('VehicleRegistration');
                }}
                role="button"
                tabIndex={0}
                onKeyPress={() => {
                  this.showChangeRequestPopup('VehicleRegistration');
                }}
              >
                {t('pages.requestAccess.agencyVehicleRegistration')}
              </p>
              <div className="pt-2"></div>
              <p
                className="request-link"
                onClick={() => {
                  this.showChangeRequestPopup('PayTax');
                }}
                role="button"
                tabIndex={0}
                onKeyPress={() => {
                  this.showChangeRequestPopup('PayTax');
                }}
              >
                {t('pages.requestAccess.payTax')}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
RequestAccess.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(RequestAccess);
