import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import 'app/modules/Dashboard/AccountManagement.scss';
import avatar from 'assets/img/avatar.svg';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditNamePopup from 'app/modules/Dashboard/EditNamePopup';
import Alert from 'app/modules/Shared/Alert/Alert';
import Cookies from 'js-cookie';
import http from 'api/http-request';
import queryString from 'query-string';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { capitalizeString } from 'utils/common';

class AccountManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: '',
      alertStatus: '',
      showEditPopup: false,
      firstName: 'John',
      lastName: 'Smith',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onEditSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editName.successMsg'
        : 'pages.editNamePopup.errorMsg';
    this.setState(
      {
        showEditPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };

  showEditPopup = () => {
    this.setState({
      showEditPopup: true,
    });
  };

  deleteAccount = () => {
    debugger;
    let bearer = 'Bearer ';
    let id = Cookies.get('user_id');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `managed/user/${id} `,
        method: 'DELETE',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.status === 200) {
        this.deleteStorage();
      }
    });
  };

  getAPIHeaders = () => {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  };

  endSession = () => {
    let idToken = Cookies.get('idToken');
    let bearer = 'Bearer ';
    let accessToken = Cookies.get('dataStoreToken');
    http(
      {
        url: `endSession?id_token_hint=${idToken}`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${accessToken}`,
      }
    ).then(() => {
      Cookies.remove('user_id');
      Cookies.remove('dataStoreToken');
      Cookies.remove('profileCodeDetails');
      Cookies.set('userLoggedIn', false);
      Cookies.remove('idToken');
      Cookies.remove('clientId');
      Cookies.remove('userDetails');
      Cookies.remove('firstName');
      Cookies.remove('lastName');
      Cookies.remove('isUserLogin');
      Cookies.remove('username');
      Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
      this.setDefaultLanguage();
      window.location.href = sanitizeUrl('/');
    });
  };

  setDefaultLanguage = () => {
    Cookies.set('ctsessionlanguage', 'en_US');
  };

  deleteStorage = () => {
    let accessToken = Cookies.get('dataStoreToken');
    let payLoad = {};
    payLoad.token = accessToken;
    payLoad.client_id = Cookies.get('clientId');
    http(
      {
        url: 'revoke?realm=/aegis',
        method: 'POST',
        data: queryString.stringify(payLoad),
      },
      { ...this.getAPIHeaders() }
    ).then(() => {
      this.endSession();
    });
  };

  retrieveFirstName = () => {
    const fName = Cookies.get('firstName');
    return fName ? capitalizeString(fName) : '';
  };

  retrieveLastName = () => {
    const lName = Cookies.get('lastName');
    return lName ? capitalizeString(lName) : '';
  };

  render() {
    const { t } = this.props;
    const { alertText, alertStatus, showEditPopup } = this.state;

    const firstName = this.retrieveFirstName();
    const lastName = this.retrieveLastName();
    return (
      <div>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        {showEditPopup && (
          <EditNamePopup
            onClose={() => {
              this.setState({ showEditPopup: false });
            }}
            onEditSuccessHandler={this.onEditSuccessHandler}
            firstName={firstName}
            lastName={lastName}
          />
        )}
        <div className="account-management">
          <h1>{t('pages.accountManagement.title')}</h1>
          <div className="row account-management__container">
            <div className="col-xs-3">
              <img
                className="rounded-circle"
                alt="100x100"
                src={avatar}
                data-holder-rendered="true"
              />
              <div className="text-center btn-secondary">
                {t('pages.accountManagement.addImage')}
              </div>
            </div>
            <div className="col-xs-3 mb-2 bold ft-18">
              <div>
                {firstName} {lastName}
              </div>
              <div>{t('pages.accountManagement.designation')}</div>
            </div>
            <div className="col-xs-3 mb-2">
              <div className="content">
                <FontAwesomeIcon
                  icon={faPenAlt}
                  onClick={() => {
                    this.showEditPopup();
                  }}
                  role="button"
                  tabIndex={-1}
                  onKeyPress={() => {
                    this.showEditPopup();
                  }}
                />
              </div>
              <div className="content">
                <FontAwesomeIcon icon={faPenAlt} />
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-3">
              <button className="btn btn-warning py-2 my-3">
                {t('pages.accountManagement.deactivateAccount')}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <button
                className="btn btn-danger py-2 my-3"
                onClick={() => this.deleteAccount()}
              >
                {t('pages.accountManagement.deleteAccount')}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-secondary py-2 my-3">
                {t('pages.accountManagement.addAccount')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AccountManagement.propTypes = {
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
AccountManagement.defaultProps = {
  firstName: '',
  lastName: '',
};
export default withTranslation()(AccountManagement);
