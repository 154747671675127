import PropTypes from 'prop-types';
import './ManageServices.scss';
import { withTranslation, useTranslation } from 'react-i18next';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { useReducer, useState } from 'react';
import Cookies from 'js-cookie';
import WithError from '../../Shared/WithError/WithError';
import http from 'api/http-request';

function EditUserModel(props) {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState();
  const [errorArray, setErrorArray] = useState([]);
  const { userEditModelVal } = props;

  function formReducer(state, action) {
    switch (action.type) {
      case 'text_change':
        return {
          ...state,
          data: { ...state.data, [action.keyword]: action.value },
        };
      case 'handle_error':
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.keyword]: errorHandler(action.value, action.keyword),
          },
        };
      case 'handle_bulk_error':
        return {
          ...state,
          errors: {
            ...action.value,
          },
        };
      default:
        return state;
    }
  }

  const showError = (errorText) => {
    setErrorText(errorText);
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const initialPayLoad = {
    data: {
      iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
      'User Name': userEditModelVal.userDetails.userName,
      mail: userEditModelVal.userDetails.mail,
      'First Name': userEditModelVal.userDetails.givenName,
      'Last Name': userEditModelVal.userDetails.sn,
      jobTitle: userEditModelVal.userDetails.jobTitle,
      department: userEditModelVal.userDetails.department,
      accountStatus: userEditModelVal.userDetails.accountStatus,
    },
    errors: {
      givenNameError: '',
      snError: '',
      userNameError: '',
      emailError: '',
    },
  };
  const [payLoad, dispatch] = useReducer(formReducer, initialPayLoad);
  const handleTextChange = (e, key) => {
    dispatch({
      type: 'text_change',
      keyword: key,
      value: e.target.value,
    });
  };
  const checkErrors = (e, key) => {
    dispatch({
      type: 'handle_error',
      keyword: key,
      value: e.target.value,
    });
  };

  const checkErrorsList = async () => {
    let obj = {
      givenNameError: '',
      snError: '',
      userNameError: '',
      emailError: '',
    };
    obj.givenNameError = errorHandler(
      payLoad.data['First Name'],
      'givenNameError'
    );
    obj.snError = errorHandler(payLoad.data['Last Name'], 'snError');
    obj.userNameError = errorHandler(
      payLoad.data['User Name'],
      'userNameError'
    );
    obj.emailError = errorHandler(payLoad.data['mail'], 'emailError');

    await dispatch({
      type: 'handle_bulk_error',
      value: obj,
    });
  };
  function errorFlag() {
    for (let key in payLoad.errors) {
      if (payLoad.errors[key] != '') {
        return true;
      } else {
        return false;
      }
    }
  }

  const editUser = async (e) => {
    e.preventDefault();
    await checkErrorsList();
    let errors = await errorFlag();
    if (!errors) {
      let data = payLoad.data;
      //   delete data['confirmPassword'];
      http({
        url: '/aegis-managed-service/aegis/api/updateUserByPrivilegeUser',
        method: 'PUT',
        data: data,
      }).then((res) => {
        if (
          res.status == 401 &&
          (res.data.iamcode == 'IAM0039' || res.data.iamcode == 'IAM0056')
        ) {
          showError(res.data.data.message);
        } else if (res.status == 400 && res.data.iamcode == 'IAM0055') {
          showError(res.data.data.errorMessage + ' - Email already exists');
        } else if (res.status == 200 && res.data.response == 'success') {
          const { handleModelClose } = props;
          handleModelClose(res.data.response);
          props.setUserEditModelVal((prev) => {
            return { ...prev, showModal: false };
          });
        }
      });
    }
  };
  function errorHandler(value, key) {
    let errorMsg = '';
    switch (key) {
      case 'givenNameError':
        if (value.length <= 0) {
          errorMsg = 'errors.firstNameError';
        }
        break;
      case 'snError':
        if (value.length <= 0) {
          errorMsg = 'errors.lastNameError';
        }
        break;
      case 'userNameError':
        if (value.length < 5 || value.length > 30) {
          errorMsg = 'errors.userNameLength';
        } else if (
          !value.match(/([a-zA-Z])*/) &&
          value.length >= 5 &&
          value.length <= 30
        ) {
          errorMsg = 'errors.userNameAtleastAlphabet';
        }
        break;
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    return errorMsg;
  }
  return (
    <>
      <Modal
        show={userEditModelVal.showModal}
        onHide={() => {
          props.setUserEditModelVal((prev) => {
            return { ...prev, showModal: false };
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(`pages.ManageServices.editUser`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(errorText || errorArray.length > 0) && (
            <WithError
              errors={errorText}
              errorArray={errorArray}
              isPopup={true}
            />
          )}
          <Container fluid>
            <Form>
              <Form.Group controlId="firstName">
                <Form.Label>
                  {t('pages.editName.firstName')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  maxLength="40"
                  aria-required
                  aria-describedby="firstname-error"
                  defaultValue={
                    userEditModelVal.userDetails.givenName
                      ? userEditModelVal.userDetails.givenName
                      : payLoad.data['First Name']
                  }
                  onChange={(e) => {
                    handleTextChange(e, 'First Name');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'givenNameError');
                  }}
                />
                <p className="error-text" id="firstname-error">
                  {t(payLoad.errors.givenNameError)}
                </p>
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>
                  {t('pages.editName.lastName')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  maxLength="40"
                  aria-required
                  aria-describedby="firstname-error"
                  defaultValue={
                    userEditModelVal.userDetails.sn
                      ? userEditModelVal.userDetails.sn
                      : payLoad.data['Last Name']
                  }
                  onChange={(e) => {
                    handleTextChange(e, 'Last Name');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'snError');
                  }}
                />
                <p className="error-text" id="lastname-error">
                  {payLoad.errors.snError && t(payLoad.errors.snError)}
                </p>
              </Form.Group>
              <Form.Group controlId="userName">
                <Form.Label>
                  {t('pages.ManageServices.userName')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  maxLength="40"
                  readOnly
                  aria-required
                  aria-describedby="userName-error"
                  defaultValue={
                    userEditModelVal.userDetails.userName
                      ? userEditModelVal.userDetails.userName
                      : payLoad.data['User Name']
                  }
                  onChange={(e) => {
                    handleTextChange(e, 'User Name');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'userNameError');
                  }}
                />
                <p className="error-text" id="username-error">
                  {payLoad.errors.userNameError &&
                    t(payLoad.errors.userNameError)}
                </p>
              </Form.Group>
              <Form.Group controlId="mail">
                <Form.Label>
                  {t('pages.ManageServices.email')}
                  <span className="mandatory">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  maxLength="40"
                  aria-required
                  aria-describedby="mail-error"
                  defaultValue={
                    userEditModelVal.userDetails.mail
                      ? userEditModelVal.userDetails.mail
                      : payLoad.data['mail']
                  }
                  onChange={(e) => {
                    handleTextChange(e, 'mail');
                  }}
                  onBlur={(e) => {
                    checkErrors(e, 'emailError');
                  }}
                />
                <p className="error-text" id="email-error">
                  {payLoad.errors.emailError && t(payLoad.errors.emailError)}
                </p>
              </Form.Group>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              size="lg"
              variant="primary"
              block
              type="submit"
              onClick={(e) => editUser(e)}
            >
              {t('pages.editName.save')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
EditUserModel.propTypes = {
  t: PropTypes.func.isRequired,
  userEditModelVal: PropTypes.any,
  handleModelClose: PropTypes.func.isRequired,
  setUserEditModelVal: PropTypes.func.isRequired,
};

export default withTranslation()(EditUserModel);
