import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import {
  faTasks,
  faChevronDown,
  faChevronUp,
  faCogs
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideAccountMenus: false,
      width: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  hideAccountMenusHandler = () => {
    this.setState((prev) => ({ hideAccountMenus: !prev.hideAccountMenus }));
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.hideAccountMenusHandler();
    } else {
      return;
    }
  };

  render() {
    const { pageName, t, roles } = this.props;
    const { width, hideAccountMenus } = this.state;
    return (
      <div className="profile-menu">
        <div className="row align-items-center m-0">
          <div className="col-12">
            <h6 className="profile-menu__title p-0">
              {t('pages.myTasks.adminPanel')}
            </h6>
          </div>
          <div className="col-2 text-right ft-24">
            <FontAwesomeIcon
              className={
                width < 992 ? 'cursor-pointer' : 'd-none cursor-pointer'
              }
              icon={hideAccountMenus ? faChevronDown : faChevronUp}
              onClick={this.hideAccountMenusHandler}
              onKeyDown={this.onKeyDown}
              tabIndex={0}
            ></FontAwesomeIcon>
          </div>
        </div>
        <ul
          className={`profile-menu__options list-unstyled px-3 px-sm-0 ${
            hideAccountMenus && width < 992 ? 'd-none' : ''
          }`}
        >
          <li
            className={`pb-2 ${pageName === 'Admin' ? 'active' : ''}`}
            // style={
            //   !roles.includes('internal/role/manager')
            //     ? { 'pointer-events': 'none', color: 'lightgrey' }
            //     : {}
            // }
          >
            <Link
              className={`preferences black
            ${pageName === 'Admin' ? 'active' : ''} disabled-link`}
              to="/profile/Admin"
              onClick={this.hideAccountMenusHandler}
            >
              <FontAwesomeIcon icon={faCogs} role="button" />
              <span className="pl-3">{t('pages.mainMenu.manageServices')}</span>
            </Link>
          </li>
          <li
            className={`pb-2 ${pageName === 'BusinessApprovals' ? 'active' : ''}`}
            // style={
            //   !roles.includes('internal/role/manager')
            //     ? { 'pointer-events': 'none', color: 'lightgrey' }
            //     : {}
            // }
          >
            <Link
              className={`preferences black
            ${pageName === 'BusinessApprovals' ? 'active' : ''} disabled-link`}
              to="/profile/BusinessApprovals"
            >
              <FontAwesomeIcon icon={faTasks} />
              <span className="pl-3">{t('pages.myTasks.tabMyTasks')}</span>
            </Link>
          </li>

          <li
            className={`pb-2 ${pageName === 'AdminApp' ? 'active' : ''}`}
            // style={
            //   !roles.includes('internal/role/openidm-tasks-manager')
            //     ? { 'pointer-events': 'none', color: 'lightgrey' }
            //     : {}
            // }
          >
            <Link
              className={`preferences black
            ${pageName === 'AdminApp' ? 'active' : ''}`}
              to="/profile/AdminApp"
            >
              <FontAwesomeIcon icon={faTasks} />
              <span className="pl-3">{t('pages.BreadCrumb.applications')}</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
AdminPanel.propTypes = {
  pageName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

AdminPanel.defaultProps = {
  roles: [],
};

export default withTranslation()(AdminPanel);
