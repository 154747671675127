import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api cognito/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard Cognito/EditEmailPopup.scss';
import {
  preventNonDigitCharacters,
  formatPhoneNumber,
  getPhoneNumberFromFormattedString,
} from 'utils/common';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import validator from 'validator';
class EditMobileNumberPopup extends Component {
  constructor(props) {
    super(props);
    this.mobileNumberInputRef = React.createRef();
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      userName: props.userName ? props.userName : '',
      errorText: '',
      errorArray: [],
      sn: props.sn ? props.sn : '',
      verificationCode: '',
      screen: 1,
      mail: props.mail ? props.mail : '',
      verificationCodeError: '',
      mobileNumber: props.telephoneNumber ? props.telephoneNumber : '',
      mobileNumberUSFormatted: '',
    };
  }

  handleKeyPress = (e) => {
    preventNonDigitCharacters(e);
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  componentDidMount() {
    // this.setState({
    //   mobileNumber: this.state.mobileNumber.replace('+1', ''),
    // });
    this.onMobileNumberChange();
  }

  inititateMobileVerification = (e) => {
    e.preventDefault();
    let token = Cookies.get('dataStoreToken');
    const { onErrorEmailSuccessHandler } = this.props;

    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
          AttributeName: 'phone_number',
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.GetUserAttributeVerificationCode',
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data.CodeDeliveryDetails) {
        this.setState({
          screen: 2,
        });
      } else if (
        res.status === 400 &&
        res.data.__type === 'LimitExceededException'
      ) {
        onErrorEmailSuccessHandler('error', res.data.__type);
      }
    });
  };

  onMobileNumberChange = (e) => {
    if (e) {
      const currentValue = e.target.value.trim();
      this.setState((prevState) => ({
        ...prevState,
        mobileNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
      }));
    } else {
      const currentValue = this.state.mobileNumber.trim().replace('+1', '');
      this.setState((prevState) => ({
        ...prevState,
        mobileNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
      }));
    }
  };

  onNext = (e) => {
    e.preventDefault();
    const { mobileNumber } = this.state;
    if (
      mobileNumber.length >= 1 &&
      mobileNumber.length < 14 &&
      !validator.isMobilePhone(mobileNumber)
    ) {
      this.setState({
        mobileNumberError: 'pages.editNumberPopup.mobileNumberInvalid',
      });
      this.mobileNumberInputRef.current.focus();
    } else {
      this.setState(
        {
          mobileNumberError: '',
          mobileNumberUSFormatted:
            mobileNumber === ''
              ? ''
              : getPhoneNumberFromFormattedString(mobileNumber),
        },
        () => {
          let code = Cookies.get('code');
          if (code) {
            this.getMobileVerifySalesforce();
          } else {
            this.getPhoneVerify();
          }
        }
      );
    }
  };

  getMobileVerifySalesforce = async () => {
    const { mobileNumberUSFormatted } = this.state;
    const { onErrorEmailSuccessHandler } = this.props;
    let output = await this.getMobileVerifySalesforceAPI(
      mobileNumberUSFormatted
    );
    if (output) {
      this.setState({
        screen: 2,
      });
    } else {
      onErrorEmailSuccessHandler('error', 'LimitExceededException');
    }
  };
  getMobileVerifySalesforceAPI = (mobileNumberUSFormatted) => {
    return new Promise(function (resolve, reject) {
      var output = null;

      var aws = require('aws-sdk');
      aws.config.update({
        region: `${process.env.REACT_APP_COGNITO_RegionName}`,

        accessKeyId: `${process.env.REACT_APP_COGNITO_AccessKey}`, // should be:  process.env.AWS_ACCESS_ID
        secretAccessKey: `${process.env.REACT_APP_COGNITO_SecretKey}`,
      });

      var cognitoidentityserviceprovider = new aws.CognitoIdentityServiceProvider();
      var params = {
        UserAttributes: [
          /* required */

          {
            Name: 'phone_number',
            Value: mobileNumberUSFormatted,
          },

          /* more items */
        ],
        UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}` /* required */,
        Username: Cookies.get('userName') /* required */,
      };
      cognitoidentityserviceprovider.adminUpdateUserAttributes(
        params,
        function (err, data) {
          if (err) {
            reject(false);
          } else {
            resolve(true);
          } // successful response
        }
      );
    });
  };

  getPhoneVerify = () => {
    const { mobileNumber, mobileNumberUSFormatted } = this.state;

    let token = Cookies.get('dataStoreToken');
    const {
      onEditMobileNumberSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;

    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: token,
          UserAttributes: [
            {
              Name: 'phone_number',
              Value: mobileNumberUSFormatted,
            },
          ],
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.UpdateUserAttributes',
      }
    ).then((res) => {
      http(
        {
          url: '/amazonaws',
          data: {
            AccessToken: token,
            AttributeName: 'phone_number',
          },
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target':
            'AWSCognitoIdentityProviderService.GetUserAttributeVerificationCode',
        }
      ).then((res) => {
        if (res.status === 200 && res.data && res.data.CodeDeliveryDetails) {
          this.setState({
            screen: 2,
          });
        } else if (
          res.status === 400 &&
          res.data.__type === 'LimitExceededException'
        ) {
          onErrorEmailSuccessHandler('error', res.data.__type);
        }
      });
    });
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    let userName = Cookies.get('userName');
    this.setState({
      errorText: '',
    });
    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,

          Username: userName,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.ResendConfirmationCode',
      }
    ).then((res) => {
      if (res.status === 400 && res.data.__type === 'NotAuthorizedException') {
        clearCookies();
        window.location.href = '/';
      }
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { verificationCode } = this.state;
    const {
      onEditMobileNumberSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      let bearer = 'Bearer ';
      // let token = localStorage.getItem('dataStoreToken');
      let token = Cookies.get('dataStoreToken');
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: '/amazonaws',
          method: 'POST',
          data: {
            AccessToken: token,
            AttributeName: 'phone_number',
            Code: verificationCode,
          },
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target':
            'AWSCognitoIdentityProviderService.VerifyUserAttribute',
        }
      ).then((res) => {
        if (res.status === 200 && res.data) {
          ReactGA.event({
            category: 'Mobile Update -Success',
            action: 'OTP verification Success',
          });
          onEditMobileNumberSuccessHandler('success');
        } else if (
          res.status === 400 &&
          res.data.__type == 'CodeMismatchException'
        ) {
          ReactGA.event({
            category: 'Mobile Update -Faliure',
            action: 'OTP verification Failed',
          });
          this.setState({
            verificationCodeError: 'pages.editEmail.invalidOTP',
          });
        }
      });
    }
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;

    this.setState({ verificationCode });
  }

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 1,
      verificationCodeError: '',
      verificationCode: '',
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      screen,
      verificationCodeError,
      verificationCode,
      mobileNumberError,
      mobileNumber,
      numberFocus,
      telephoneNumber,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="change-password-popup edit-email-pop">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {t('pages.editNumberPopup.addPhoneNumber')}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  {screen === 1 && (
                    <>
                      <Form.Group controlId="mobileNumber">
                        <Form.Label>
                          {t('pages.editNumberPopup.mobilePhone')}
                        </Form.Label>
                        <Form.Control
                          ref={this.mobileNumberInputRef}
                          className={
                            mobileNumberError ? 'error-input' : undefined
                          }
                          onKeyDown={this.handleKeyPress}
                          placeholder={!numberFocus ? '(###) ###-####' : ''}
                          onFocus={() => {
                            this.setState({
                              numberFocus: true,
                            });
                          }}
                          onBlur={() => {
                            this.setState({
                              numberFocus: false,
                            });
                          }}
                          onChange={this.onMobileNumberChange}
                          value={mobileNumber}
                          autoFocus
                          aria-required
                          aria-invalid={!!mobileNumberError}
                          aria-describedby="mobile-number-error"
                        />
                      </Form.Group>
                      <p className="error-text" id="mobile-number-error">
                        {mobileNumberError && t(mobileNumberError)}
                      </p>
                      <div className="mb-3 mt-3">
                        <Button
                          className="wrapper__container-card-body-submit mb-3"
                          size="lg"
                          variant="primary"
                          block
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </>
                  )}
                  {screen === 2 && (
                    <div className="col-md-12 registration__container">
                      <h3>
                        <span>
                          {t('pages.forgotUserId.verificationMethod')}
                        </span>
                      </h3>
                      <div className="registration__container__resend-verification">
                        <div className="registration__container__resend-verification__resend-text">
                          <p>
                            {t(
                              'pages.editNumberPopup.enterTheVerificationText'
                            )}
                            {' +1'}
                            {mobileNumber}
                          </p>
                          <p
                            className="resend-link"
                            onClick={(e) => this.inititateMobileVerification(e)}
                            role="button"
                            tabIndex={0}
                          >
                            {t('pages.registration.resendVerificationCode')}
                          </p>
                        </div>
                      </div>
                      <Form>
                        <Form.Group
                          className="verification-form"
                          controlId="verificationCode"
                        >
                          <Form.Label className="mb-4">
                            {t('pages.registration.verificationCode')}
                          </Form.Label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            onInput={this.handleChange.bind(this)}
                            className="ml-3"
                            value={verificationCode}
                          />
                          {verificationCodeError && (
                            <p className="error-text" id="verification-error">
                              {verificationCodeError &&
                                t(verificationCodeError)}
                            </p>
                          )}
                        </Form.Group>
                        <div className="registration__container__pleasenote pleasenote">
                          <p className="registration__container__pleasenote-fp">
                            {t('pages.registration.note')}
                          </p>
                          <p className="registration__container__pleasenote-sp">
                            {t('pages.registration.verifyNote')}
                          </p>
                        </div>
                        <div className="row pt-3">
                          <div className="d-sm-flex col-lg-6 col-md-12">
                            <button
                              className="col btn btn-secondary hollow py-2 my-3 mr-sm-4"
                              onClick={(e) => this.onBackLogin(e)}
                              variant="primary"
                              block
                            >
                              {t('pages.forgotPassword.backBtn')}
                            </button>

                            <button
                              className="col btn btn-secondary  py-2 my-3"
                              onClick={(e) => this.onVerify(e)}
                            >
                              {t('pages.registration.verify')}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

EditMobileNumberPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditMobileNumberSuccessHandler: PropTypes.func.isRequired,
  onErrorEmailSuccessHandler: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
  mail: PropTypes.string,
  telephoneNumber: PropTypes.string,
  userName: PropTypes.string,
};
EditMobileNumberPopup.defaultProps = {
  givenName: '',
  sn: '',
  mail: '',
  telephoneNumber: '',
  userName: '',
};
export default withTranslation()(EditMobileNumberPopup);
