import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import 'app/modules/Dashboard/PersonalInformation.scss';
import Alert from 'app/modules/Shared/Alert/Alert';
import { faEye, faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditNamePopup from 'app/modules/Dashboard AegisCloud/EditNamePopup';
import EditEmailPopup from 'app/modules/Dashboard AegisCloud/EditEmailPopup';
import EditMobileNumberPopup from 'app/modules/Dashboard AegisCloud/EditMobileNumberPopup';
import http from 'api aegisCloud/http-request';
import { formatPhoneNumber } from 'utils/common';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: '',
      alertStatus: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      showEditPopup: false,
      dobHidden: true,
      phoneNumberHidden: true,
      userDetails: props.userDetails ? props.userDetails : '',
      showEmailPopup: false,
      showMobileNumberPopup: false,
      disableEdit: false,
    };
  }

  getUserDetails = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';
    http(
      {
        url: `bravo_user/${id}`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        const { setUserDetails } = this.props;
        setUserDetails(res.data);
        this.setState({
          userDetails: res.data,
        });
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { getNotifications } = this.props;
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let id = Cookies.get('user_id');
    if (token || id) {
      this.getUserDetails(id, token);
      //getNotifications(id, token);
    }
  }

  onEditSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editName.successMsg'
        : 'pages.editNamePopup.errorMsg';
    if (status === 'success') {
      // const { getUpdatedName } = this.props;
      // getUpdatedName();
      // let token = localStorage.getItem('dataStoreToken');
      let token = Cookies.get('dataStoreToken');
      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      if (token || id) {
        this.getUserDetails(id, token);
      }
    }
    this.setState(
      {
        showEditPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };

  onEditMobileNumberSuccessHandler = (status) => {
    const { getNotifications } = this.props;
    const alertText =
      status === 'success'
        ? 'pages.editNumberPopup.mobileChangeSuccessMsg'
        : 'pages.editNumberPopup.mobileChangeErrorMsg';
    if (status === 'success') {
      // let token = localStorage.getItem('dataStoreToken');
      let token = Cookies.get('dataStoreToken');
      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      if (token || id) {
        this.getUserDetails(id, token);
        getNotifications(id, token);
      }
    }
    this.setState(
      {
        showMobileNumberPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };
  onEditEmailSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editEmail.emailChangeSuccessMsg'
        : 'pages.editNamePopup.errorMsg';
    if (status === 'success') {
      // let token = localStorage.getItem('dataStoreToken');
      let token = Cookies.get('dataStoreToken');
      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      if (token || id) {
        this.getUserDetails(id, token);
      }
      this.setState({
        disableEdit: true,
      });
    }
    this.setState(
      {
        showEmailPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };
  onErrorEmailSuccessHandler = (status, message) => {
    let alertText = '';
    if (message === 'SMTP_ERROR') {
      alertText = 'pages.editEmail.smtpError';
    } else if (message === 'MAX_RESEND_EXCEEDED') {
      alertText = 'errors.maxResendAttempt';
    } else if (message === 'MAX_RETRY_EXCEEDED') {
      alertText = 'errors.maxVerifyAttempt';
    } else if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          showEmailPopup: false,
          showEditPopup: false,
          showMobileNumberPopup: false,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  showEditPopup = () => {
    ReactGA.event({
      category: 'Name Update - AegisCloud',
      action: 'Name Update Triggered: Name Update Button Clicked',
    });
    this.setState({
      showEditPopup: true,
    });
  };

  showEmailPopup = () => {
    ReactGA.event({
      category: 'Email Update',
      action: 'Email Update Triggered: Email Update Button Clicked',
    });
    this.setState({
      showEmailPopup: true,
    });
  };

  showMobileNumberPopup = () => {
    ReactGA.event({
      category: 'Mobile Update',
      action: 'Mobile Update Button Clicked.',
    });
    this.setState({
      showMobileNumberPopup: true,
    });
  };

  codeText = (text) => {
    return text.replace(/.(?=.{0})/g, '*');
  };

  phoneNumberHideToggler = () => {
    this.setState((prev) => ({
      phoneNumberHidden: !prev.phoneNumberHidden,
    }));
  };

  render() {
    const { getNotifications, t } = this.props;
    const {
      alertText,
      alertStatus,
      showEditPopup,
      userDetails,
      showEmailPopup,
      showMobileNumberPopup,
      disableEdit,
    } = this.state;
    return (
      <div>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        {showEditPopup && (
          <EditNamePopup
            onClose={() => {
              this.setState({ showEditPopup: false });
            }}
            onEditSuccessHandler={this.onEditSuccessHandler}
            givenName={userDetails.givenName}
            sn={userDetails.sn}
          />
        )}
        {showEmailPopup && (
          <EditEmailPopup
            onClose={() => {
              this.setState({ showEmailPopup: false });
            }}
            onEditEmailSuccessHandler={this.onEditEmailSuccessHandler}
            givenName={userDetails.givenName}
            sn={userDetails.sn}
            mail={userDetails.mail}
            onErrorEmailSuccessHandler={this.onErrorEmailSuccessHandler}
          />
        )}
        {showMobileNumberPopup && (
          <EditMobileNumberPopup
            onClose={() => {
              this.setState({ showMobileNumberPopup: false });
            }}
            onEditMobileNumberSuccessHandler={
              this.onEditMobileNumberSuccessHandler
            }
            telephoneNumber={userDetails.telephoneNumber}
            onErrorEmailSuccessHandler={this.onErrorEmailSuccessHandler}
            getNotifications={getNotifications}
          />
        )}
        <div className="personal-information">
          <h1 className="pb-3">{t('pages.personalInformation.title')}</h1>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.fullName')}
            </div>
            <div className="col-sm-5">
              {userDetails.givenName} {userDetails.sn}
            </div>
            <div
              className="col-sm-2 text-right"
              onClick={() => {
                this.showEditPopup();
              }}
              role="button"
              tabIndex={0}
              aria-label="Edit Full Name"
              onKeyPress={() => {
                this.showEditPopup();
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.Email')}
            </div>
            <div className="col-sm-5">{userDetails.mail}</div>
            <div
              style={
                Cookies.get('editEmailSuccess')
                  ? { 'pointer-events': 'none', color: 'lightgrey' }
                  : {}
              }
              className="col-sm-2 text-right"
              onClick={() => {
                this.showEmailPopup();
              }}
              role="button"
              aria-label="Edit Mail"
              tabIndex={0}
              onKeyPress={() => {
                this.showEmailPopup();
              }}
              disabled={Cookies.get('editEmailSuccess')}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.PhoneNumber')}
            </div>
            <div className="col-sm-5">
              {this.state.phoneNumberHidden
                ? this.codeText(t('pages.personalInformation.Phone'))
                : userDetails.telephoneNumber
                ? `${formatPhoneNumber(
                    userDetails.telephoneNumber.replace('+1', '')
                  )}`
                : ''}
            </div>
            <div className="col-sm-2 text-right">
              <button
                className="bg-transparent border-0"
                aria-label="Show phone number"
                onClick={this.phoneNumberHideToggler}
              >
                <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
              </button>
            </div>
            {/*<div
              className="col-sm-1 text-right"
              onClick={() => {
                this.showMobileNumberPopup();
              }}
              role="button"
              tabIndex={0}
              onKeyPress={() => {
                this.showMobileNumberPopup();
              }}
              aria-label="Edit phone number"
            >
              <FontAwesomeIcon icon={faPenAlt} role="img" />
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
PersonalInformation.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  getUpdatedName: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
};
PersonalInformation.defaultProps = {
  userDetails: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
};
export default withTranslation()(PersonalInformation);
