import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api aegisCloud/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard AegisCloud/EditEmailPopup.scss';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

class EditEmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      errorText: '',
      errorArray: [],
      sn: props.sn ? props.sn : '',
      verificationCode: '',
      screen: 1,
      mail: props.mail ? props.mail : '',
      verificationOption: '',
      verificationCodeError: '',
      payLoad: {},
    };
  }

  getAuth = () => {
    http(
      {
        url: 'updateEmail',
        method: 'POST',
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      this.setState({ payLoad: res.data });
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAuth();
  }

  onNext = (e) => {
    e.preventDefault();
    const { mail } = this.state;
    if (mail === '') {
      this.setState({
        emailError: 'pages.editEmail.emailAddressRequired',
      });
      this.emailAddressInputRef.current.focus();
    } else {
      this.setState(
        {
          emailError: '',
        },
        () => {
          this.getEmailVerify();
        }
      );
    }
  };

  getOtp = () => {
    const { mail } = this.state;
    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    const { onEditEmailSuccessHandler } = this.props;
    http(
      {
        url: 'endpoint/emailVerification?_action=generateOtp',
        method: 'POST',
        data: {
          mail: mail,
        },
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data.code === 200) {
        this.setState({
          screen: 2,
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'EMAIL_ALREADY_PRESENT'
      ) {
        this.setState({
          emailError: 'pages.editEmail.emailAlreadyExist',
        });
      }
    });
  };

  getEmailVerify = () => {
    const { payLoad, mail } = this.state;
    payLoad.callbacks[0].input[0].value = mail;
    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    const { onEditEmailSuccessHandler } = this.props;
    http(
      {
        url: 'updateEmail',
        method: 'POST',
        data: payLoad,
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      if (
        res.status === 200 &&
        res.data &&
        res.data.callbacks[0].type === 'PasswordCallback' &&
        res.data.callbacks[0].output[0].value === 'One Time Password'
      ) {
        // this.getOtp();
        this.setState({
          screen: 2,
          payLoad: res.data,
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'EMAIL_ALREADY_PRESENT'
      ) {
        this.setState({
          emailError: 'pages.editEmail.emailAlreadyExist',
        });
      }
    });
  };

  resendVerify = (e) => {
    e.preventDefault();
    const { mail } = this.state;
    const {
      onEditEmailSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    let bearer = 'Bearer ';
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: 'endpoint/emailVerification?_action=resendOtp',
        method: 'POST',
        data: {
          mail: mail,
        },
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.status === 200 && res.data && res.data.code === 200) {
        this.setState({
          screen: 2,
        });
      } else if (
        res.status === 400 &&
        res.data.message === 'Please enter valid OTP'
      ) {
        this.setState({
          verificationCodeError: 'pages.editEmail.verificationCodeError',
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'CONTENT_REQUIRED'
      ) {
        this.setState({
          verificationCodeError: 'pages.editEmail.contentRequired',
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'ACTION_REQUIRED'
      ) {
        this.setState({
          verificationCodeError: 'pages.editEmail.contentRequired',
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'OTP_REQUIRED'
      ) {
        this.setState({
          verificationCodeError: 'pages.editEmail.otpRequired',
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'OTP_INVALID'
      ) {
        this.setState({
          verificationCodeError: 'pages.editEmail.invalidOTP',
        });
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'OTP_EXPIRED'
      ) {
        this.setState({
          verificationCodeError: 'pages.editEmail.expiredOTP',
        });
      } else if (
        res.status === 502 &&
        res.data.code === 502 &&
        res.data.message === 'SMTP_ERROR'
      ) {
        onErrorEmailSuccessHandler('error', res.data.message);
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'MAX_RESEND_EXCEEDED'
      ) {
        onErrorEmailSuccessHandler('error', res.data.message);
      } else if (
        res.status === 400 &&
        res.data.code === 400 &&
        res.data.message === 'MAX_RETRY_EXCEEDED'
      ) {
        onErrorEmailSuccessHandler('error', res.data.message);
      } else {
        onEditEmailSuccessHandler('error');
      }
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { verificationCode, payLoad } = this.state;
    const {
      onEditEmailSuccessHandler,
      onErrorEmailSuccessHandler,
    } = this.props;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      payLoad.callbacks[0].input[0].value = verificationCode;
      // let bearer = 'Bearer ';
      // let token = localStorage.getItem('dataStoreToken');
      // let token = Cookies.get('dataStoreToken');
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: 'updateEmail',
          method: 'POST',
          data: payLoad,
        },
        {
          'accept-api-version': 'protocol=1.0,resource=2.1',
          'Content-Type': 'application/json',
        }
      ).then((res) => {
        if (res.status === 200) {
          Cookies.set('editEmailSuccess', true);
          onEditEmailSuccessHandler('success');
          ReactGA.event({
            category: 'Email Update',
            action: 'Email Update Success: Email Updated and otp verified.',
          });
        } else if (res.status === 401) {
          this.setState({
            verificationCodeError: 'pages.forgotUserId.invalidVerificationCode',
          });
        } else if (
          (res.status === 400 &&
            res.data.message === 'Please enter valid OTP') ||
          (res.status === 401 &&
            res.data.message === 'Login failure' &&
            res.data.message === 'Unauthorized')
        ) {
          this.setState({
            verificationCodeError: 'pages.editEmail.verificationCodeError',
          });
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'CONTENT_REQUIRED'
        ) {
          this.setState({
            verificationCodeError: 'pages.editEmail.contentRequired',
          });
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'ACTION_REQUIRED'
        ) {
          this.setState({
            verificationCodeError: 'pages.editEmail.contentRequired',
          });
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'OTP_REQUIRED'
        ) {
          this.setState({
            verificationCodeError: 'pages.editEmail.otpRequired',
          });
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'OTP_INVALID'
        ) {
          this.setState({
            verificationCodeError: 'pages.editEmail.invalidOTP',
          });
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'OTP_EXPIRED'
        ) {
          this.setState({
            verificationCodeError: 'pages.editEmail.expiredOTP',
          });
        } else if (
          res.status === 502 &&
          res.data.code === 502 &&
          res.data.message === 'SMTP_ERROR'
        ) {
          onErrorEmailSuccessHandler('error', res.data.message);
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'MAX_RESEND_EXCEEDED'
        ) {
          onErrorEmailSuccessHandler('error', res.data.message);
        } else if (
          res.status === 400 &&
          res.data.code === 400 &&
          res.data.message === 'MAX_RETRY_EXCEEDED'
        ) {
          onErrorEmailSuccessHandler('error', res.data.message);
        } else {
          onEditEmailSuccessHandler('error');
        }
      });
    }
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;

    this.setState({ verificationCode });
  }

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    let errorMsg = '';
    switch (key) {
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 1,
      verificationOption: '',
      verificationCodeError: '',
      verificationCode: '',
    });
  };

  render() {
    const { onClose, t } = this.props;
    const {
      screen,
      verificationCodeError,
      emailError,
      mail,
      verificationOption,
      verificationCode,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="change-password-popup edit-email-pop">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">{t('pages.editEmail.title')}</h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  {screen === 1 && (
                    <>
                      <Form.Group controlId="mail">
                        <Form.Label>{t('pages.editEmail.email')}</Form.Label>
                        <Form.Control
                          ref={this.emailAddressInputRef}
                          type="email"
                          onChange={(e) => {
                            this.setState({
                              mail: e.target.value.trim().toLowerCase(),
                            });
                          }}
                          onBlur={(e) => {
                            this.isFormFieldValid(e, 'emailError');
                          }}
                          value={mail}
                          aria-required
                          className={emailError ? 'error-input' : undefined}
                          aria-invalid={!!emailError}
                          aria-describedby="email-error"
                        />
                      </Form.Group>
                      <p className="error-text" id="email-error">
                        {emailError && t(emailError)}
                      </p>
                      <div className="mb-3 mt-3">
                        <Button
                          className="wrapper__container-card-body-submit mb-3"
                          size="lg"
                          variant="primary"
                          block
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                        >
                          {t('pages.editName.save')}
                        </Button>
                      </div>
                    </>
                  )}
                  {screen === 2 && (
                    <div className="col-md-12 registration__container">
                      <p>
                        <span>
                          {t('pages.registration.verificationCodeSentTo')}{' '}
                          {mail}
                        </span>
                      </p>
                      <div className="registration__container__resend-verification">
                        <div className="registration__container__resend-verification__resend-text">
                          <p
                            className="resend-link"
                            onClick={(e) => this.resendVerify(e)}
                            role="button"
                            tabIndex={0}
                          >
                            {t('pages.registration.resendVerificationCode')}
                          </p>
                        </div>
                      </div>
                      <Form>
                        <Form.Group
                          className="verification-form"
                          controlId="verificationCode"
                        >
                          <Form.Label className="mb-4">
                            {t('pages.registration.verificationCode')}
                          </Form.Label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            onInput={this.handleChange.bind(this)}
                            className="ml-3"
                            value={verificationCode}
                          />
                          {verificationCodeError && (
                            <p className="error-text" id="verification-error">
                              {verificationCodeError &&
                                t(verificationCodeError)}
                            </p>
                          )}
                        </Form.Group>
                        <div className="registration__container__pleasenote pleasenote">
                          <p className="registration__container__pleasenote-fp">
                            {t('pages.registration.note')}
                          </p>
                          <p className="registration__container__pleasenote-sp">
                            {t('pages.registration.verifyNote')}
                          </p>
                        </div>
                        <div className="row pt-3">
                          <div className="d-sm-flex col-lg-6 col-md-12">
                            <button
                              className="col btn btn-secondary hollow py-2 my-3 mr-sm-4"
                              onClick={(e) => this.onBackLogin(e)}
                              variant="primary"
                              block
                            >
                              {t('pages.forgotPassword.backBtn')}
                            </button>

                            <button
                              className="col btn btn-secondary  py-2 my-3"
                              onClick={(e) => this.onVerify(e)}
                            >
                              {t('pages.registration.verify')}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

EditEmailPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditEmailSuccessHandler: PropTypes.func.isRequired,
  onErrorEmailSuccessHandler: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
  mail: PropTypes.string,
};
EditEmailPopup.defaultProps = {
  givenName: '',
  sn: '',
  mail: '',
};
export default withTranslation()(EditEmailPopup);
