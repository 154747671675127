import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Shared/Alert/Alert.scss';

const Alert = (props) => {
  const { t } = useTranslation();
  const { alertText, className, onAlertClose } = props;
  return (
    <>
      <div
        className={`alert-popup ${className}`}
        role="alert"
        aria-live="assertive"
      >
        {t(alertText)}
        <Button
          className="alert-popup__close"
          onClick={() => onAlertClose()}
          variant="light"
          aria-label="Close"
        >
          <img src={CloseIcon} alt="" />
        </Button>
      </div>
    </>
  );
};

Alert.propTypes = {
  alertText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onAlertClose: PropTypes.func.isRequired,
};

export default Alert;
