import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/ProfileLeftMenu';
import AdminPanel from 'app/modules/Dashboard/AdminPanel';
import Admin from 'app/modules/Dashboard/Admin';
import { withTranslation } from 'react-i18next';
import Tasks from './Tasks';
import http from 'api/http-request';
import 'app/modules/Dashboard/Account.scss';
import Cookies from 'js-cookie';
import ManageServices from './ManagedServices/ManageServices';
class AdminAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails ? props.userDetails : {},
      roles: props.roles ? props.roles : [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUser();
    let roles = this.state.roles;
    if (roles) {
      this.setState({ roles: roles });
    }
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  getUser = () => {
    let username = Cookies.get('username');

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      pattern: username,

      attribute: 'userName',

      operation: 'eq',
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http({
      url: '/aegis-managed-service/aegis/api/searchUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 &&
        res.data.iamcode == 'IAM0028' &&
        res.data.response == 'Access Denied'
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';
      }

      let data = res.data.data.result[0];

      const { setUserDetails } = this.props;
      Cookies.set('username', data.userName);
      setUserDetails(data);
      if (Cookies.get('socialLogin')) {
        // localStorage.setItem('email', res.data.mail);
        Cookies.set('email', data.mail);
      } else {
        // localStorage.removeItem('email');
        Cookies.remove('email');
      }

      this.setState({
        userDetails: data,
      });

      Cookies.set('firstName', data.givenName);
      this.setState({ roles: JSON.stringify(data.roles) });
      Cookies.set('lastName', data.sn);
    });
  };
  // getUpdatedName = () => {
  //   let token = localStorage.getItem('dataStoreToken');
  //   let id = localStorage.getItem('user_id');
  //   this.getUserDetails(id, token);
  // };
  render() {
    const { pageName } = this.props;
    const { userDetails, roles } = this.state;
    return (
      <Container fluid className="profile">
        <Row noGutters className="profile__row">
          <Col
            md={{ span: 12 }}
            lg={{ span: 2 }}
            className="profile__row__left"
          >
            <AdminPanel pageName={pageName || ''} roles={roles} />
          </Col>
          <Col
            md={{ span: 12 }}
            lg={{ span: 9 }}
            className="profile__row__right"
          >
            {pageName === 'Admin' && roles.length!=0 &&  <ManageServices  
            roles = {roles}
            />}

            {pageName === 'BusinessApprovals' &&
              roles.includes('openidm-tasks-manager') && (
                <Tasks
                  userDetails={userDetails}
                  roles={JSON.parse(this.state.roles)}
                />
              )}

            {pageName === 'AdminApp' && (
              <Admin
                userDetails={userDetails}
                // getUserDetails={getUserDetails}
              />
            )}

            {!pageName && roles.includes('openidm-tasks-manager') ? (
              <Tasks
                userDetails={userDetails}
                roles={JSON.parse(this.state.roles)}
              />
            ) : (
              !pageName && (
                <Admin
                  userDetails={userDetails}
                  // getUserDetails={getUserDetails}
                />
              )
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
AdminAccount.propTypes = {
  match: PropTypes.object.isRequired,
  pageName: PropTypes.string.isRequired,
  // getUserDetails: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userData: PropTypes.object,
  userDetails: {},
  roles: PropTypes.array.isRequired,
};
AdminAccount.defaultProps = {
  userData: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
  userDetails: {},
  roles: [],
};
export default withTranslation()(AdminAccount);
