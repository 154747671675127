import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';
import 'app/modules/Dashboard/PersonalInformation.scss';
import Alert from 'app/modules/Shared/Alert/Alert';
import { faEye, faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditNamePopup from 'app/modules/Dashboard/EditNamePopup';
import EditEmailPopup from 'app/modules/Dashboard/EditEmailPopup';
import EditMobileNumberPopup from 'app/modules/Dashboard/EditMobileNumberPopup';
import Lottie from 'react-lottie';
import completedAnimation from 'assets/json/idProofing_completed.json';
import actionRequiredAnimation from 'assets/json/idProofing_required.json';
import http from 'api/http-request';
import { formatPhoneNumber } from 'utils/common';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: '',
      alertStatus: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      showEditPopup: false,
      dobHidden: true,
      phoneNumberHidden: true,
      userDetails: props.userDetails ? props.userDetails : '',
      showEmailPopup: false,
      showMobileNumberPopup: false,
      finish: {
        loop: true,
        autoplay: true,
        animationData: completedAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      actionRequired: {
        loop: true,
        autoplay: true,
        animationData: actionRequiredAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    };
  }

  getAPIHeaders = () => {
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    return {
      iPlanetDirectoryPro: iPlanetDirectoryPro,
      'Content-Type': 'application/json',
    };
  };
  getUserDetails = () => {
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    let username = Cookies.get('username');
    let data = {
      pattern: username,

      attribute: 'userName',

      operation: 'eq',
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };
    http(
      {
        url: '/aegis-managed-service/aegis/api/searchUser',
        method: 'POST',
        data: data,
      },

      this.getAPIHeaders()
    ).then((res) => {
      if (
        res.status == 401 &&
        res.data.iamcode == 'IAM0028' &&
        res.data.response == 'Access Denied'
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = '/';
      }

      let data = res.data.data.result[0];

      const { setUserDetails } = this.props;
      Cookies.set('username', data.userName);
      Cookies.set('mail', data.mail);
      setUserDetails(data);
      if (Cookies.get('socialLogin')) {
        // localStorage.setItem('email', res.data.mail);
        Cookies.set('email', data.mail);
      } else {
        // localStorage.removeItem('email');
        Cookies.remove('email');
      }

      this.setState({ idProofing: data.idProofing }, () => {
        if (
          data.idProofing === '2' ||
          Cookies.get('walletRegistered') == 'true'
        ) {
          this.setState({
            idProofing: 'Completed',
          });
        } else if (data.idProofing === '1') {
          this.setState({
            idProofing:
              'Identity Verified - Action Required : Click on the prompt to proceed',
          });
        } else {
          this.setState({
            idProofing: 'Action Required : Click on the prompt to proceed',
          });
        }
      });
      this.setState({
        userDetails: data,
      });

      if (
        data.preferredLanguage === 'en' ||
        data.preferredLanguage === '' ||
        data.preferredLanguage === null ||
        data.preferredLanguage === undefined
      ) {
        // localStorage.setItem('ctsessionlanguage', 'en_US');
        localStorage.setItem('ctsessionlanguage', 'en_US');
        Cookies.set('ctsessionlanguage', 'en_US');
        i18n.changeLanguage('en_US');
        i18n.changeLanguage('en_US');
      } else {
        // localStorage.setItem('ctsessionlanguage', 'es');
        Cookies.set('ctsessionlanguage', 'es');
        i18n.changeLanguage(data.preferredLanguage);
      }

      // store name for chatbot use
      // localStorage.setItem('firstName', res.data.givenName);
      Cookies.set('firstName', data.givenName);
      // localStorage.setItem('lastName', res.data.sn);
      Cookies.set('lastName', data.sn);
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUserDetails();
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let id = Cookies.get('user_id');
    let idProofingStatus = Cookies.get('idProofingStatus');
    if (idProofingStatus === '2') {
      this.setState({
        idProofing: 'Completed',
      });
    } else if (idProofingStatus === '1') {
      this.setState({
        idProofing:
          'Identity Verified - Action Required : Click on the prompt to proceed',
      });
    } else {
      this.setState({
        idProofing: 'Action Required : Click on the prompt to proceed',
      });
    }
  }

  onEditSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editName.successMsg'
        : 'pages.editNamePopup.errorMsg';
    if (status === 'success') {
      // const { getUpdatedName } = this.props;
      // getUpdatedName();
      // let token = localStorage.getItem('dataStoreToken');
      // let token = Cookies.get('dataStoreToken');
      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      if (id) {
        this.getUserDetails();
      }
    }
    this.setState(
      {
        showEditPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };

  onEditMobileNumberSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editNumberPopup.mobileChangeSuccessMsg'
        : 'pages.editNumberPopup.mobileChangeErrorMsg';
    if (status === 'success') {
      // let token = localStorage.getItem('dataStoreToken');
      // let token = Cookies.get('dataStoreToken');
      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      if (id) {
        this.getUserDetails();
      }
    }
    this.setState(
      {
        showMobileNumberPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };
  onEditEmailSuccessHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editEmail.emailChangeSuccessMsg'
        : 'pages.editNamePopup.errorMsg';
    if (status === 'success') {
      // let token = localStorage.getItem('dataStoreToken');
      // let token = Cookies.get('dataStoreToken');
      // let id = localStorage.getItem('user_id');
      let id = Cookies.get('user_id');
      if (id) {
        this.getUserDetails();
      }
    }
    this.setState(
      {
        showEmailPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };

  onResendOTP = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editNumberPopup.sendOTP'
        : 'pages.editNamePopup.sendOTPFailed';
    this.setState(
      {
        showRequestPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 2000);
      }
    );
  };

  onErrorEmailSuccessHandler = (status, message) => {
    let alertText = '';
    if (message === 'SMTP_ERROR') {
      alertText = 'pages.editEmail.smtpError';
    } else if (message === 'MAX_RESEND_EXCEEDED') {
      alertText = 'errors.maxResendAttempt';
    } else if (message === 'MAX_RETRY_EXCEEDED') {
      alertText = 'errors.maxVerifyAttempt';
    } else if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (message === 'Some Error Occured') {
      alertText = 'pages.editNumberPopup.unknownError';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          showEmailPopup: false,
          showEditPopup: false,
          showMobileNumberPopup: false,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 3000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  showEditPopup = () => {
    this.setState({
      showEditPopup: true,
    });
  };

  showEmailPopup = () => {
    ReactGA.event({
      category: 'Email Update',
      action: 'Email Update Triggered: Email Update Button Clicked',
    });
    this.setState({
      showEmailPopup: true,
    });
  };

  showMobileNumberPopup = () => {
    ReactGA.event({
      category: 'Mobile Update',
      action: 'Mobile Update Button Clicked.',
    });
    this.setState({
      showMobileNumberPopup: true,
    });
  };

  codeText = (text) => {
    return text.replace(/.(?=.{0})/g, '*');
  };

  phoneNumberHideToggler = () => {
    this.setState((prev) => ({
      phoneNumberHidden: !prev.phoneNumberHidden,
    }));
  };

  onStartIdProofing = () => {
    if (Cookies.get('idProofingStatus') !== '-4') {
      window.location.href = '/idproofing-landing';
    }
  };

  render() {
    const {
      alertText,
      alertStatus,
      showEditPopup,
      userDetails,
      showEmailPopup,
      showMobileNumberPopup,
      finish,
      actionRequired,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        {showEditPopup && (
          <EditNamePopup
            onClose={() => {
              this.setState({ showEditPopup: false });
            }}
            onEditSuccessHandler={this.onEditSuccessHandler}
            givenName={userDetails.givenName}
            sn={userDetails.sn}
          />
        )}
        {showEmailPopup && (
          <EditEmailPopup
            onClose={() => {
              this.setState({ showEmailPopup: false });
            }}
            onEditEmailSuccessHandler={this.onEditEmailSuccessHandler}
            givenName={userDetails.givenName}
            sn={userDetails.sn}
            mail={userDetails.mail}
            onErrorEmailSuccessHandler={this.onErrorEmailSuccessHandler}
            onResendOTP={this.onResendOTP}
          />
        )}
        {showMobileNumberPopup && (
          <EditMobileNumberPopup
            onClose={() => {
              this.setState({ showMobileNumberPopup: false });
            }}
            onEditMobileNumberSuccessHandler={
              this.onEditMobileNumberSuccessHandler
            }
            telephoneNumber={userDetails.telephoneNumber}
            onErrorEmailSuccessHandler={this.onErrorEmailSuccessHandler}
          />
        )}
        <div className="personal-information">
          <h1 className="pb-3">{t('pages.personalInformation.title')}</h1>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.fullName')}
            </div>
            <div className="col-sm-5">
              {userDetails.givenName} {userDetails.sn}
            </div>
            <div
              className="col-sm-2 text-right"
              onClick={() => {
                this.showEditPopup();
              }}
              role="button"
              tabIndex={0}
              aria-label="Edit Full Name"
              onKeyPress={() => {
                this.showEditPopup();
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.Email')}
            </div>
            <div className="col-sm-5">{userDetails.mail}</div>
            <div
              className="col-sm-2 text-right"
              onClick={() => {
                this.showEmailPopup();
              }}
              role="button"
              aria-label="Edit Mail"
              tabIndex={0}
              onKeyPress={() => {
                this.showEmailPopup();
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.PhoneNumber')}
            </div>
            <div className="col-sm-5">
              {this.state.phoneNumberHidden
                ? this.codeText(t('pages.personalInformation.Phone'))
                : userDetails.telephoneNumber
                ? `${formatPhoneNumber(
                    userDetails.telephoneNumber.replace('+1', '')
                  )}`
                : ''}
            </div>
            <div className="col-sm-1 text-right">
              <button
                className="bg-transparent border-0"
                aria-label="Show phone number"
                onClick={this.phoneNumberHideToggler}
              >
                <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
              </button>
            </div>
            <div
              className="col-sm-1 text-right"
              onClick={() => {
                this.showMobileNumberPopup();
              }}
              role="button"
              tabIndex={0}
              onKeyPress={() => {
                this.showMobileNumberPopup();
              }}
              aria-label="Edit phone number"
            >
              <FontAwesomeIcon icon={faPenAlt} role="img" />
            </div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.personalInformation.idProofing')}
            </div>
            <div className="col-sm-5">{this.state.idProofing}</div>
            {this.state.idProofing ===
              'Action Required : Click on the prompt to proceed' ||
              (this.state.idProofing ===
                'Identity Verified - Action Required : Click on the prompt to proceed' && (
                <div
                  className="col-sm-2 text-right"
                  style={{ right: '-6%' }}
                  onClick={() => {
                    this.onStartIdProofing();
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => {
                    this.onStartIdProofing();
                  }}
                >
                  <Lottie
                    className="lottie-completed-animation"
                    options={actionRequired}
                    height={35}
                    width={35}
                  />
                </div>
              ))}
            {this.state.idProofing === 'Completed' && (
              <div className="col-sm-2 text-right" style={{ right: '-6%' }}>
                <Lottie
                  isClickToPauseDisabled={true}
                  className="lottie-completed-animation"
                  options={finish}
                  height={40}
                  width={40}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
PersonalInformation.propTypes = {
  getUpdatedName: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
};
PersonalInformation.defaultProps = {
  userDetails: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
};
export default withTranslation()(PersonalInformation);
