import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/EditNamePopup.scss';
import 'app/modules/Dashboard/AdminPopup.scss';
import Cookies from 'js-cookie';
import DateTimePicker from 'react-datetime-picker';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getNestedObject } from 'utils/common';
import WithError from '../Shared/WithError/WithError';
import 'app/modules/Dashboard/AdminEditPopup.scss';

class AdminEditPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appName: props.appDetails.appname,
      appLink: props.appDetails.applink,
      appDescription: props.appDetails.description,
      notificationMessage: props.appDetails.NotificationMessage,
      appId: props.appDetails._id,
      errorText: '',
      errorArray: [],
      notificationStartTime: props.appDetails.NotificationStartTime
        ? moment(
            props.appDetails.NotificationStartTime,
            'YYYYMMDDHHmmss'
          ).format()
        : '',
      notificationEndTime: props.appDetails.NotificationEndTime
        ? moment(
            props.appDetails.NotificationEndTime,
            'YYYYMMDDHHmmss'
          ).format()
        : '',
      photo: props.appDetails.photo ? props.appDetails.photo : '',
      notificationEndTimeError: false,
    };
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    const { appName, appLink, appId } = this.state;
    const hasGivenName = this.isFormFieldValid(appName, 'appNameError');
    const hasSn = this.isFormFieldValid(appLink, 'appLinkError');
    if (hasGivenName || hasSn) {
      this.showError('common.errors.required');
    } else {
      this.formSubmit();
    }
  };

  formSubmit = () => {
    this.setState({
      errorText: '',
    });
    const {
      appLink,
      appDescription,
      appId,
      notificationMessage,
      photo,
      notificationStartTime,
      notificationEndTime,
    } = this.state;
    const { updateApp } = this.props;
    let bearer = 'Bearer ';
    let token = Cookies.get('dataStoreToken');

    const payload = [
      {
        operation: 'replace',
        field: '/NotificationMessage',
        value: notificationMessage ? notificationMessage : '',
      },
      {
        operation: 'replace',
        field: '/applink',
        value: appLink,
      },
      {
        operation: 'replace',
        field: '/description',
        value: appDescription,
      },
      {
        operation: 'replace',
        field: '/photo',
        value: photo,
      },
      {
        operation: 'replace',
        field: '/NotificationStartTime',
        value: notificationStartTime
          ? Number(moment(notificationStartTime).format('YYYYMMDDHHmmss'))
          : 0,
      },
      {
        operation: 'replace',
        field: '/NotificationEndTime',
        value: notificationEndTime
          ? Number(moment(notificationEndTime).format('YYYYMMDDHHmmss'))
          : 0,
      },
    ];

    http(
      {
        url: `managed/Application/${appId}`,
        method: 'PATCH',
        data: payload,
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res && res.status === 200 && res.data) {
        updateApp('success', 'edit');
        this.props.onClose();
      } else {
        const failedPolicyRequirements = getNestedObject(res, [
          'data',
          'detail',
          'failedPolicyRequirements',
        ]);

        if (failedPolicyRequirements && failedPolicyRequirements.length > 0) {
          this.setState({
            errorArray: failedPolicyRequirements,
          });
        }
      }
    });
  };

  checkNotificationFlag = () => {
    if (this.state.notificationMessage.length == 0) {
      this.setState({
        notificationStartTime: '',
        notificationEndTime: '',
        notificationEndTimeError: false,
      });
    } else {
      this.setState({
        notificationEndTimeError: true,
      });
    }
  };

  onNotificationChange = (e, key) => {
    const {
      target: { value },
    } = e;

    this.setState(
      {
        [key]: value,
      },
      this.checkNotificationFlag
    );
  };
  onNameChange = (e, key) => {
    const {
      target: { value },
    } = e;

    this.setState({
      [key]: value,
    });
  };

  onImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  checkDiffTimeCorrect = (startTime, endTime) => {
    let ms = moment(endTime, 'YYYYMMDDHHmmss').diff(
      moment(startTime, 'YYYYMMDDHHmmss'),
      'seconds'
    );

    if (ms <= 0) {
      return true;
    } else {
      return false;
    }
  };

  checkNotificationError = () => {
    const { notificationStartTime, notificationEndTime } = this.state;
    let timeDiff = false;
    if (notificationEndTime != '' && notificationStartTime != '') {
      timeDiff = this.checkDiffTimeCorrect(
        notificationStartTime,
        notificationEndTime
      );
    }

    if (timeDiff || notificationEndTime == '' || notificationStartTime == '') {
      this.setState({ notificationEndTimeError: true });
    } else {
      this.setState({ notificationEndTimeError: false });
    }
  };

  onNotificationStartTimeChange = (d) => {
    this.setState(
      {
        notificationStartTime: moment(new Date(d), 'YYYYMMDDHHmmss').format(),
      },
      this.checkNotificationError
    );
  };

  onNotificationEndTimeChange = (d) => {
    this.setState(
      {
        notificationEndTime: moment(new Date(d), 'YYYYMMDDHHmmss').format(),
      },
      this.checkNotificationError
    );
  };

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.setState({
      photo: btoa(binaryString),
    });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;

    let errorMsg = '';
    switch (key) {
      case 'appNameError':
        if (value.length <= 0) {
          errorMsg = 'App name is mandatory';
        }
        break;
      case 'appLinkError':
        if (value.length <= 0) {
          errorMsg = 'App link is mandatory';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  render() {
    const { onClose, t } = this.props;
    const {
      appName,
      appLink,
      appDescription,
      notificationMessage,
      appNameError,
      appLinkError,
      notificationStartTime,
      notificationEndTime,
      photo,
      notificationEndTimeError,
      errorText,
      errorArray,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div
          className="edit-name-pop change-password-popup edit-admin-popup"
          role="main"
        >
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  <div className="change-password-popup__popup-body-header">
                    <h1 id="password-modal">{t('pages.Admin.editTitle')}</h1>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                  <p>
                    <span className="pr-2 red bold">*</span>
                    {t('pages.registration.requiredFields')}
                  </p>
                  <Form.Group controlId="appName">
                    <Form.Label>
                      {t('pages.Admin.appName')}
                      <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNameChange(e, 'appName');
                      }}
                      value={appName}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'appNameError');
                      }}
                      className={appNameError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!appNameError}
                      aria-describedby="appname-error"
                      disabled={true}
                    />
                    <p className="error-text" id="appname-error">
                      {appNameError && t(appNameError)}
                    </p>
                  </Form.Group>

                  <Form.Group controlId="appLink">
                    <Form.Label>
                      {t('pages.Admin.appLink')}
                      <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNameChange(e, 'appLink');
                      }}
                      value={appLink}
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'appLinkError');
                      }}
                      className={appLinkError ? 'error-input' : undefined}
                      aria-required
                      aria-invalid={!!appLinkError}
                      aria-describedby="lastname-error"
                    />
                    <p className="error-text" id="lastname-error">
                      {appLinkError && t(appLinkError)}
                    </p>
                  </Form.Group>
                  <Form.Group controlId="appDescription">
                    <Form.Label>{t('pages.Admin.appDescription')}</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNameChange(e, 'appDescription');
                      }}
                      value={appDescription}
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>
                  <Form.Group controlId="imageUpload">
                    <p className="bold">
                      {t('pages.Admin.appEditExistingLogo')}
                    </p>
                    {photo ? (
                      <div>
                        <img
                          className="admin-apps-img"
                          src={`data:image/png;base64,${photo}`}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="app-div">
                        <FontAwesomeIcon
                          icon={faTabletAlt}
                          id="app-icon"
                          role="img"
                        />
                      </div>
                    )}
                    <Form.Label>{t('pages.Admin.uploadImage')}</Form.Label>
                    <Form.Control
                      onChange={(e) => this.onImageChange(e)}
                      type="file"
                      name="image"
                      accept=".jpeg , .png, .jpg"
                    />
                  </Form.Group>
                  <Form.Group controlId="notificationMessage">
                    <Form.Label>
                      {t('pages.Admin.notificationMessage')}
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        this.onNotificationChange(e, 'notificationMessage');
                      }}
                      value={notificationMessage}
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>

                  <Form.Group controlId="notiDateTime" aria-hidden="true">
                    {notificationMessage ? (
                      <div className="mt-3">
                        <span>
                          <div className="notification-label">
                            <Form.Label>
                              {t('pages.Admin.notificationStartTime')}
                            </Form.Label>
                            <DateTimePicker
                              onChange={(date) => {
                                this.onNotificationStartTimeChange(date);
                              }}
                              value={
                                notificationStartTime
                                  ? new Date(notificationStartTime)
                                  : ''
                              }
                              minDate={moment().toDate()}
                              aria-label="notificationStartTime"
                            />
                          </div>
                        </span>

                        <span>
                          <div className="notification-label">
                            <Form.Label>
                              {t('pages.Admin.appNotiDateTimeEnd')}
                            </Form.Label>
                            <DateTimePicker
                              aria-label="notificationEndTime"
                              onChange={(date) => {
                                this.onNotificationEndTimeChange(date);
                              }}
                              value={
                                notificationEndTime
                                  ? new Date(notificationEndTime)
                                  : ''
                              }
                              minDate={
                                notificationStartTime || notificationEndTime
                                  ? new Date(notificationStartTime)
                                  : ''
                              }
                            />
                            <p className="error-text" id="notification-error">
                              {notificationEndTimeError ? (
                                <ul>
                                  <li>
                                    {t('errors.notificationStartTimeError')}
                                  </li>
                                  <li>
                                    {t('errors.notificationEndTimeError')}
                                  </li>
                                </ul>
                              ) : (
                                ''
                              )}
                            </p>
                            <p className="mt-3">
                              <span className="note">
                                {t('pages.forgotPassword.pleaseNote')}
                              </span>
                              <span className="est-time ml-2">
                                {t('pages.Admin.estTime')}
                              </span>
                            </p>
                          </div>
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>
                <div className="change-password-popup__popup-footer">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="primary"
                    block
                    type="submit"
                    disabled={notificationEndTimeError}
                    onClick={(e) => this.onNext(e)}
                  >
                    {t('pages.editName.save')}
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

AdminEditPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateApp: PropTypes.func.isRequired,
  appDetails: PropTypes.object,
};

export default withTranslation()(AdminEditPopup);
