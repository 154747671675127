import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import http from 'api/http-request';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import CloseIcon from 'assets/img/close-icon.svg';
import 'app/modules/Dashboard/EditApprove.scss';
import Cookies from 'js-cookie';
import WithError from 'app/modules/Shared/WithError/WithError';

class ApproveDetailsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: props.givenName ? props.givenName : '',
      errorText: '',
      errorArray: [],
      taskID: '',
      assigneeId: '',
      mail: '',
      myTask: {
        tasks: [
          {
            variables: {
              givenName: '',
              sn: '',
              userName: '',
              department: '',
              jobTitle: '',
            },
          },
        ],
      },
      selectUser: '',
      approverList: [],
      showAssigneMeButton: 'false',
      assignValue: '',
      username: Cookies.get('username'),
      initialAssignValue: null,
      dropDownError: ' ',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { listIndex, myTask, approverList } = this.props;

    let { username } = this.state;
    this.setState({
      myTask: myTask[listIndex],
      taskID: myTask[listIndex].tasks[0]._id,
      assigneeId: myTask[listIndex].tasks[0].processInstanceId,
      mail: myTask[listIndex].tasks[0].variables.mail,
      approverList: approverList,
      initialAssignValue: myTask[listIndex].tasks[0].assignee
        ? myTask[listIndex].tasks[0].assignee
        : null,

      assignValue: myTask[listIndex].tasks[0].assignee
        ? myTask[listIndex].tasks[0].assignee
        : null,
    });
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  assignTask = (assignToMe = false, e) => {
    const { taskID, assignValue } = this.state;
    const { onEditSuccessHandler } = this.props;
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    if (assignValue === null || assignValue === '') {
      this.setState({
        dropDownError: 'pages.myTasks.approvernotselectederror',
      });
    } else {
      this.setState({
        dropDownError: ' ',
      });
      http({
        url: `claim`,
        method: 'POST',
        data: {
          userId: assignToMe ? this.state.username : assignValue,
          TaskInstanceId: taskID,
          iPlanetDirectoryPro: iPlanetDirectoryPro,
        },
      }).then((res) => {
        if (res.data.iamcode == 'IAM000') {
          onEditSuccessHandler('assigned');
        }
      });
    }
  };

  assignToMe = (assignToMe = true, e) => {
    const { taskID, assignValue } = this.state;
    const { onEditSuccessHandler } = this.props;
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    http({
      url: `claim`,
      method: 'POST',
      data: {
        userId: assignToMe ? this.state.username : assignValue,
        TaskInstanceId: taskID,
        iPlanetDirectoryPro: iPlanetDirectoryPro,
      },
    }).then((res) => {
      if (res.data.iamcode == 'IAM000') {
        onEditSuccessHandler('assigned');
      }
    });
  };

  onChange = (event) => {
    this.setState({ assignValue: event.target.value });
  };

  setApprovals = (decision) => {
    const { taskID, mail } = this.state;
    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    const { onEditSuccessHandler } = this.props;
    http({
      url: `accept`,
      method: 'POST',
      data: {
        email: mail,
        decision: decision,
        TaskInstanceId: taskID,
        iPlanetDirectoryPro: iPlanetDirectoryPro,
      },
    }).then((res) => {
      if(res.status == 400 && res.data.iamcode == 'IAM0055'){
        this.showError(res.data.data.Message.errorMessage);
      } else if(res.status == 200 && res.data.iamcode == 'IAM000'){
        if (decision == 'accept') {
          onEditSuccessHandler('success');
        } else if (decision == 'reject') {
          onEditSuccessHandler('reject');
        }
      } else if(res.status == 400 && res.data.iamcode =='IAM0039'){
        this.showError(res.data.data.response);
      }else{
        this.showError('Some error occured');
      }
    });
  };

  render() {
    const { onClose, t, onEditSuccessHandler, listIndex } = this.props;
    const {
      myTask,
      approverList,
      assignValue,
      username,
      errorText,
      errorArray,
      dropDownError,
      initialAssignValue,
    } = this.state;

    return (
      <Modal
        show
        animation={false}
        onEscapeKeyDown={() => onClose()}
        aria-labelledby="password-modal"
      >
        <div className="edit-approve-pop change-password-popup">
          <Container fluid>
            <div className="change-password-popup__popup">
              <Form>
                <div className="change-password-popup__popup-body">
                  <div className="change-password-popup__popup-body-header">
                    <h6 id="password-modal">
                      {t('pages.editName.viewDetails')}
                    </h6>
                    <Button
                      className="change-password-popup__popup-body-header-close"
                      onClick={() => {
                        onClose();
                      }}
                      variant="light"
                      aria-label="Close"
                    >
                      <img src={CloseIcon} alt="" />
                    </Button>
                  </div>
                   <p>
                  {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
                  </p>
                  <Form.Group controlId="firstName">
                    <Form.Label>{t('pages.editName.firstName')}</Form.Label>
                    <Form.Control
                      value={myTask.tasks[0].variables.givenName}
                      maxLength="40"
                      aria-required
                      disabled="true"
                    />
                  </Form.Group>
                  <Form.Group controlId="lastName">
                    <Form.Label>{t('pages.editName.lastName')}</Form.Label>
                    <Form.Control
                      value={myTask.tasks[0].variables.sn}
                      maxLength="40"
                      aria-required
                      disabled="true"
                    />
                  </Form.Group>
                  <Form.Group controlId="userName">
                    <Form.Label>{t('pages.registration.userName')}</Form.Label>
                    <Form.Control
                      value={myTask.tasks[0].variables.userName}
                      maxLength="40"
                      aria-required
                      disabled="true"
                    />
                  </Form.Group>
                  <Form.Group controlId="userName">
                    <Form.Label>
                      {t('pages.registration.businessName')}
                    </Form.Label>
                    <Form.Control
                      value={myTask.tasks[0].variables.department}
                      maxLength="40"
                      aria-required
                      disabled="true"
                    />
                  </Form.Group>
                  <Form.Group controlId="userName">
                    <Form.Label>
                      {t('pages.registration.businessRole')}
                    </Form.Label>
                    <Form.Control
                      value={myTask.tasks[0].variables.jobTitle}
                      maxLength="40"
                      aria-required
                      disabled="true"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {myTask.tasks[0].assignee
                        ? t('pages.registration.assignedTo')
                        : t('pages.registration.assignTo')}
                    </Form.Label>
                    <div className="form-group">
                      <select
                        className="form-control"
                        onChange={this.onChange}
                        value={assignValue}
                        disabled={
                          initialAssignValue != null &&
                          initialAssignValue != username
                        }
                      >
                        <option value="">
                          {t('pages.myTasks.approverSelector')}
                        </option>
                        {approverList.map((item, index) => {
                          if (
                            username === assignValue &&
                            initialAssignValue == username
                          ) {
                            return (
                              <option key={index} value={item.userName}>
                                {item.userName}
                              </option>
                            );
                          } else {
                            return item.userName != username ? (
                              <option key={index} value={item.userName}>
                                {item.userName}
                              </option>
                            ) : (
                              ''
                            );
                          }
                        })}
                      </select>
                    </div>
                  </Form.Group>
                  <p
                    className="error-text"
                    id="username-error"
                    style={{ fontSize: '14px', padding: '0' }}
                  >
                    {dropDownError && t(dropDownError)}
                  </p>
                </div>
                <div className="row pt-3" style={{ 'margin-left': '1rem' }}>
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <>
                      {username === assignValue &&
                        initialAssignValue == username && (
                          <>
                            <Button
                              className="col btn btn-secondary  mr-sm-4 py-2 my-3"
                              size="lg"
                              variant="primary"
                              block
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setApprovals('accept');
                              }}
                            >
                              {t('pages.myTasks.approve')}
                            </Button>
                            <Button
                              className="col btn btn-secondary hollow py-2 my-3"
                              size="lg"
                              variant="primary"
                              block
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setApprovals('reject');
                              }}
                            >
                              {t('pages.myTasks.deny')}
                            </Button>
                          </>
                        )}
                      {(initialAssignValue == null ||
                        assignValue != username) && (
                        <>
                          <Button
                            className="col btn btn-secondary  mr-sm-4 py-2 my-3"
                            size="lg"
                            variant="primary"
                            block
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();

                              this.assignTask(false, e);
                            }}
                            disabled={
                              initialAssignValue != username
                            }
                          >
                            {t('pages.myTasks.assign')}
                          </Button>

                          <Button
                            className="col btn btn-secondary hollow py-2 my-3"
                            size="lg"
                            variant="primary"
                            block
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();

                              this.assignToMe(true, e);
                            }}
                            style={{ 'white-space': 'nowrap' }}
                            disabled={
                              initialAssignValue != null &&
                              initialAssignValue != username
                            }
                          >
                            {t('pages.myTasks.assignToMe')}
                          </Button>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Modal>
    );
  }
}

ApproveDetailsPopup.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditSuccessHandler: PropTypes.func.isRequired,
  givenName: PropTypes.string,
  sn: PropTypes.string,
  listIndex: PropTypes.number.isRequired,
  myTask: PropTypes.object.isRequired,
  addAssign: PropTypes.string,
  approverList: PropTypes.array.isRequired,
};
ApproveDetailsPopup.defaultProps = {
  givenName: '',
  sn: '',
  addAssign: '',
};
export default withTranslation()(ApproveDetailsPopup);
