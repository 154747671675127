import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import 'app/modules/Login Cognito/Login.scss';
import { withTranslation } from 'react-i18next';
import googleLogo from 'assets/img/google-plus-logo.png';
//import salesforceLogo from 'assets/img/salesforce-logo.png';
import http from 'api/http-request';
import Form from 'react-bootstrap/Form';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { getNestedObject, randomCount } from 'utils/common';
import WithError from 'app/modules/Shared/WithError Cognito/WithError';
import 'app/modules/Shared/WithError Cognito/WithError.scss';
import Radio from 'app/modules/Shared/Radio/Radio';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { DeviceUUID } from 'device-uuid';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import Carousel1 from 'assets/img/florida-homes-hero-image.jpg';
import Carousel2 from 'assets/img/mountains-hero-image.jpg';
import Carousel3 from 'assets/img/nyc.jpg';
import Carousel4 from 'assets/img/tampa-bay-hero-image.jpg';
import Carousel5 from 'assets/img/nyc1.jpg';
import Carousel6 from 'assets/img/san-usa.jpg';
import Carousel7 from 'assets/img/atlanta-usa.jpg';
import AlertGlobal from 'app/modules/Shared/AlertGlobal Cognito/AlertGlobal';
import ReactGA from 'react-ga';
import { UserPool } from 'cognito-srp';
import {
  SRPClient,
  calculateSignature,
  getNowString,
} from 'amazon-user-pool-srp-client';
import { aws_request } from 'api cognito/aws-request';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      showPassword: false,
      errorText: '',
      isAuthenticated: false,
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      challengeName: '',
      deviceName: '',
      deviceKey: '',
      verifier: '',
      salt: '',
      userIdSRP: '',
      SRP_B: '',
      SRP_A: '',
      secretBlock: '',
      screen: 1,
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCode: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
      devicePayLoad: { metadata: { hardware: {}, platform: {}, browser: {} } },
      selectedRegisterOption: '',
      countInterval: 0,
      carouselImages: [
        { image: Carousel1 },
        { image: Carousel2 },
        { image: Carousel3 },
        { image: Carousel4 },
        { image: Carousel5 },
        { image: Carousel6 },
        { image: Carousel7 },
      ],
      imageIndex: null,
      deliveryDestination: '',
    };
  }

  displayRandomImage = () => {
    this.setState({
      imageIndex: randomCount(),
    });
  };

  changeLanguage = (lang) => {
    setTimeout(() => {
      i18n.changeLanguage(lang);
    }, 0);
  };

  passwordlessAuth = () => {
    let customSet = (params) => {
      this.setState(params);
    };
    var params = {
      AuthFlow: 'CUSTOM_AUTH',
      ClientId: `${process.env.REACT_APP_COGNITO_ClientID}` /* required */,
      AuthParameters: {
        USERNAME: 'test12345',
      },
    };

    let cognitoidentityserviceprovider = aws_request();

    cognitoidentityserviceprovider.initiateAuth(
      params,
      function (err, data) {}
    );
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  componentDidMount() {
    // this.initAuthentication();
    this.scrollToTop();
    this.setDefaultLanguage();
    this.displayRandomImage();
    let token = Cookies.get('dataStoreToken');

    if (token) {
      window.location.href = '/profile/Dashboard';
    }
  }

  setDefaultLanguage = () => {
    localStorage.setItem('ctsessionlanguage', 'en_US');
    Cookies.set('ctsessionlanguage', 'en_US');

    this.changeLanguage('en_US');
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      screen: 1,
      userName: '',
      password: '',
      showPassword: '',
      errorText: '',
      isAuthenticated: '',
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      deviceName: '',
      deviceKey: '',
      verifier: '',
      salt: '',
      userIdSRP: '',
      SRP_B: '',
      SRP_A: '',
      secretBlock: '',
      challengeName: '',
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
    });
    this.authenticate();
  };

  genericResponse = (res) => {
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = sanitizeUrl(`/profile/Dashboard`);
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.authId || res.data.tokenId)
      ) {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = sanitizeUrl(`/profile/Dashboard`);
          localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res, ['data'])) {
          this.setState({
            loginPayLoad: res.data,
            isAuthenticated: true,
            loginToken: res.data.authId,
          });
        }
      }
      //this.isAuthenticate();
    } else if (
      res.data.code === 401 &&
      res.data.message === 'Authentication Failed'
    ) {
      this.showError('pages.login.incorrectLogin');
    } else if (
      res.data.code === 401 &&
      res.data.message === 'Session has timed out'
    ) {
      this.showError('pages.login.sessionTimedOut');
    } else if (
      res.data.code === 401 &&
      res.data.message ===
        'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
    ) {
      this.showError('pages.login.willGetLocked');
    } else if (
      res.data.code === 401 &&
      res.data.message ===
        'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
    ) {
      this.showError('pages.login.willGetLocked2');
    }
  };

  authenticate = () => {
    http(
      {
        url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.genericResponse(res);
    });
  };

  loginResponse = (res) => {
    this.scrollToTop();
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
      const environment = process.env.REACT_APP_ENV;
      if (environment !== 'dev') {
        window.location.href =
          'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
      } else {
        window.location.href =
          'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
      }
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.authId || res.data.tokenId)
      ) {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = sanitizeUrl(`/profile/Dashboard`);
          // localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res, ['data', 'callbacks'])) {
          const {
            data: { callbacks },
          } = res;
          if (callbacks) {
            if (
              callbacks[0].output[0].value === 'User Name' &&
              callbacks[1].output[0].value === 'Password'
            ) {
              this.showError('pages.login.incorrectLogin');
            } else if (callbacks[0].type === 'DeviceProfileCallback') {
              this.setState({
                loginPayLoad: res.data,
              });
              this.deviceCheck();
            } else if (callbacks[0].output[0].value === 'One Time Password') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 3,
                loginPayLoad: res.data,
              });
            } else {
              this.setState({
                userOption: res.data.callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 2,
                loginPayLoad: res.data,
              });
            }
          }
        }
      }
    }
  };

  deviceCheck = () => {
    const { devicePayLoad, loginPayLoad } = this.state;
    const deviceId = new DeviceUUID().get();
    const du = new DeviceUUID().parse();
    if (du) {
      devicePayLoad.metadata.platform.deviceName = du.platform;
    }
    if (deviceId) {
      devicePayLoad.identifier = deviceId;
    }
    if (navigator.userAgent) {
      devicePayLoad.metadata.browser.userAgent = navigator.userAgent;
    }
    if (navigator.appName) {
      devicePayLoad.metadata.browser.appName = navigator.appName;
    }
    if (navigator.appCodeName) {
      devicePayLoad.metadata.browser.appCodeName = navigator.appCodeName;
    }
    if (navigator.appVersion) {
      devicePayLoad.metadata.browser.appVersion = navigator.appVersion;
    }
    if (navigator.appMinorVersion) {
      devicePayLoad.metadata.browser.appMinorVersion =
        navigator.appMinorVersion;
    }
    if (navigator.buildID) {
      devicePayLoad.metadata.browser.buildID = navigator.buildID;
    }
    if (navigator.platform) {
      devicePayLoad.metadata.platform.platform = navigator.platform;
    }
    if (navigator.cpuClass) {
      devicePayLoad.metadata.hardware.cpuClass = navigator.cpuClass;
    }
    if (navigator.oscpu) {
      devicePayLoad.metadata.hardware.oscpu = navigator.oscpu;
    }
    if (navigator.product) {
      devicePayLoad.metadata.browser.product = navigator.product;
    }
    if (navigator.productSub) {
      devicePayLoad.metadata.browser.productSub = navigator.productSub;
    }
    if (navigator.vendor) {
      devicePayLoad.metadata.browser.vendor = navigator.vendor;
    }
    if (navigator.vendorSub) {
      devicePrint.metadata.browser.vendorSub = navigator.vendorSub;
    }
    if (navigator.language) {
      devicePayLoad.metadata.platform.language = navigator.language;
    }
    if (navigator.userLanguage) {
      devicePayLoad.metadata.platform.userLanguage = navigator.userLanguage;
    }
    if (navigator.browserLanguage) {
      devicePayLoad.metadata.browser.browserLanguage =
        navigator.browserLanguage;
    }
    if (navigator.systemLanguage) {
      devicePayLoad.metadata.platform.systemLanguage = navigator.systemLanguage;
    }
    loginPayLoad.callbacks[0].input[0].value = JSON.stringify(devicePayLoad);
    // loginPayLoad.callbacks[0].input[0].value =
    //   '{"identifier":"1267894517-1638565932-442693090","metadata":{"hardware":{"cpuClass":null,"deviceMemory":null,"hardwareConcurrency":8,"maxTouchPoints":10,"oscpu":null,"display":{"width":1280,"height":720,"pixelDepth":24,"angle":0}},"browser":{"userAgent":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36","appName":"Netscape","appCodeName":"Mozilla","appVersion":"5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36","appMinorVersion":null,"buildID":null,"product":"Gecko","productSub":"20030107","vendor":"Google Inc."},"platform":{"language":"en-US","platform":"Win32","userLanguage":null,"systemLanguage":null,"deviceName":"Windows (Browser)","timezone":-330}}}';
    http(
      {
        url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.scrollToTop();
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
        const environment = process.env.REACT_APP_ENV;
        if (environment !== 'dev') {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        } else {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        }
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/Dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (
                callbacks[0].output[0].value === 'User Name' &&
                callbacks[1].output[0].value === 'Password'
              ) {
                this.showError('pages.login.incorrectLogin');
              } else if (callbacks[0].type === 'DeviceProfileCallback') {
                this.setState({
                  loginPayLoad: res.data,
                });
                this.deviceCheck();
              } else if (callbacks[0].type === 'RedirectCallback') {
                this.setState({
                  loginPayLoad: res.data,
                });
                window.location.href = callbacks[0].output[0].value;
                // localStorage.setItem('socialAuthId', res.data.authId);
                Cookies.set('socialAuthId', res.data.authId);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      }
    });
  };

  onPasswordLessLogin = (e) => {
    e.preventDefault();
    const { userName } = this.state;
    if (userName === '') {
      this.showError('errors.userName');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: '/amazonaws',
          data: {
            ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
            AuthFlow: 'CUSTOM_AUTH',
            AuthParameters: {
              USERNAME: userName,
            },
          },
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        }
      ).then((res) => {
        if (res.data.__type == 'NotAuthorizedException') {
          ReactGA.event({
            category: 'Login failure',
            action: 'NotAuthorizedException',
          });
          this.setState({
            errorArray: [{ property: 'incorrectUserCredentials' }],
          });
        } else if (
          res.status === 200 &&
          res.data &&
          res.data.Session &&
          res.data.ChallengeName == 'CUSTOM_CHALLENGE'
        ) {
          ReactGA.event({
            category: 'Login credentials success',
            action: 'Verification code sent',
          });
          this.setState({
            loginToken: res.data.Session,
            screen: 4,
            deliveryDestination: res.data.ChallengeParameters.email,
          });
          Cookies.set('dataStoreToken', res.data.Session);
          //window.location.href = '/profile/Dashboard';
        }
      });
    }
  };

  onLogin = (e) => {
    e.preventDefault();
    this.authenticate();
    const { userName, password, deliveryDestination } = this.state;
    // http(
    //   {
    //     url: 'authenticate',
    //     method: 'POST',
    //   },
    //   {
    //     'X-OpenAM-Username': userName,
    //     'X-OpenAM-Password': password,
    //   }
    // ).then((res) => {
    //   Cookies.set('iPlanetDirectoryPro', res.data.tokenId);
    // });

    if (userName === '' || password === '') {
      this.showError('errors.userNamePassword');
    } else {
      this.setState({
        errorText: '',
      });
      http(
        {
          url: '/amazonaws',
          data: {
            ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
            AuthFlow: 'USER_PASSWORD_AUTH',
            AuthParameters: {
              USERNAME: userName,
              PASSWORD: password,
            },
          },
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        }
      ).then((res) => {
        if (res.data.__type == 'NotAuthorizedException') {
          ReactGA.event({
            category: 'Login failure',
            action: 'NotAuthorizedException',
          });
          this.setState({
            errorArray: [{ property: 'incorrectUserCredentials' }],
          });
        } else if (
          res.status === 200 &&
          res.data &&
          res.data.Session &&
          res.data.ChallengeName == 'SMS_MFA'
        ) {
          ReactGA.event({
            category: 'Login credentials success',
            action: 'Verification code sent',
          });
          this.setState({
            loginToken: res.data.Session,
            challengeName: res.data.ChallengeName,
            errorArray: [],
            screen: 3,
            deliveryDestination:
              res.data.ChallengeParameters.CODE_DELIVERY_DESTINATION,
          });
          Cookies.set('dataStoreToken', res.data.Session);
          //window.location.href = '/profile/Dashboard';
        }
      });
    }
  };

  otpVerification = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { userName, verificationCode, loginToken, challengeName } =
      this.state;
    var challengeResponse = {
      SMS_MFA_CODE: verificationCode,

      USERNAME: userName,
    };
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
          ChallengeName: challengeName,
          ChallengeResponses: challengeResponse,
          Session: loginToken,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
      }
    ).then((res) => {
      if (res.status === 400 && res.data.__type == 'CodeMismatchException') {
        ReactGA.event({
          category: 'Login Verification failure',
          action: 'CodeMismatchException',
        });
        this.setState({
          verificationCodeError: 'pages.forgotUserId.invalidVerificationCode',
        });
      } else if (
        res.status === 200 &&
        res.data.AuthenticationResult &&
        res.data.AuthenticationResult.AccessToken
      ) {
        ReactGA.event({
          category: 'Login Verification Success',
          action: 'Verification done',
        });
        this.setState({
          loginToken: res.data.AuthenticationResult.AccessToken,
          deviceKey: res.data.AuthenticationResult.NewDeviceMetadata.DeviceKey,
          deviceName:
            res.data.AuthenticationResult.NewDeviceMetadata.DeviceGroupKey,
          verificationCodeError: '',
        });
        Cookies.set(
          'dataStoreToken',
          res.data.AuthenticationResult.AccessToken
        );
        //window.location.href = '/profile/Dashboard?app=cognito';
        this.initiateAuthentication();
        // window.location.href = '/profile/Dashboard';
      } else if (
        res.status === 400 &&
        res.data.__type === 'InvalidParameterException'
      ) {
        ReactGA.event({
          category: 'Login Verification failure',
          action: 'InvalidParameterException',
        });
        this.setState({
          verificationCodeError: 'pages.login.enterVerificationCode',
        });
      }
    });
  };

  initiateAuthentication = async () => {
    const userPool = new UserPool('0hX5MkIfy');
    const userPoolId = 'us-east-1_0hX5MkIfy';
    // const username = 'anantmishra';
    // const password = 'Username@1';

    // const user = await userPool.createUser({
    //   username: 'anantmishra',
    //   password: 'Username@1',
    // });
    //console.log(user);

    const srp = new SRPClient(userPoolId);
    const srp_a = srp.calculateA();
    const { userName } = this.state;

    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
          AuthFlow: 'USER_SRP_AUTH',
          AuthParameters: {
            USERNAME: userName,
            SRP_A: srp_a,
          },
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      }
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          challengeName: res.data.ChallengeName,
          errorArray: [],
          salt: res.data.ChallengeParameters.SALT,
          secretBlock: res.data.ChallengeParameters.SECRET_BLOCK,
          userIdSRP: res.data.ChallengeParameters.USER_ID_FOR_SRP,
          SRP_B: res.data.ChallengeParameters.SRP_B,
          SRP_A: srp_a,
        });
        this.respondtoAuth();
      }
    });
  };

  respondtoAuth = async () => {
    var challengeName = 'PASSWORD_VERIFIER';
    const {
      userName,
      loginToken,
      salt,
      secretBlock,
      userIdSRP,
      SRP_B,
      SRP_A,
      password,
    } = this.state;
    const timeArr = new Date().toString().split('+')[0].split(' ');
    const timestamp = timeArr
      .slice(0, 3)
      .concat(timeArr.slice(4), timeArr[3])
      .join(' ');
    console.log(timestamp);

    const userPool = new UserPool('0hX5MkIfy');
    const userPoolId = 'us-east-1_0hX5MkIfy';
    const user = await userPool.createUser({
      username: userName,
      password: password,
    });
    const challenge = await userPool.getServerChallenge(user);
    // const session = challenge.getSession(SRP_A);
    const session = challenge.getSession(SRP_B, salt);
    // this getPasswordAuthenticationKey() function throws error for undefined string so commented.
    // let hkdf = srp.getPasswordAuthenticationKey(userIdSRP, password, SRP_B, salt);
    let hkdf = session.getHkdf();

    let signatureString = session.calculateSignature(secretBlock, timestamp);

    //using hkdf generated below still getting the same exception

    // let signatureString = calculateSignature(hkdf, userPoolId, userIdSRP,secretBlock, timestamp);

    var challengeResponse = {
      PASSWORD_CLAIM_SIGNATURE: signatureString,
      PASSWORD_CLAIM_SECRET_BLOCK: secretBlock,
      USERNAME: userName,
      TIMESTAMP: timestamp,
    };
    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
          ChallengeName: challengeName,
          ChallengeResponses: challengeResponse,
          Session: loginToken,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
      }
    ).then((res) => {
      console.log(res);
    });
  };

  confirmDevice = async () => {
    const { userName, loginToken } = this.state;

    const userPool = new UserPool('0hX5MkIfy');
    const userPoolId = 'us-east-1_0hX5MkIfy';
    const username = 'anantmishra';
    const password = 'Username@1';

    const user = await userPool.createUser({
      username: 'anantmishra',
      password: 'Username@1',
    });
    this.setState({
      errorArray: [],
      salt: user.salt,
      verifier: user.verifier,
    });

    http(
      {
        url: '/amazonaws',
        data: {
          AccessToken: loginToken,
          DeviceKey: this.state.deviceKey,
          DeviceName: this.state.deviceName,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmDevice',
      }
    ).then((res) => {
      console.log(res);
    });
  };

  otpVerificationPasswordless = (e) => {
    e.preventDefault();
    this.scrollToTop();
    var challengeName = 'CUSTOM_CHALLENGE';
    const { userName, verificationCode, loginToken } = this.state;
    var challengeResponse = {
      ANSWER: verificationCode,

      USERNAME: userName,
    };
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,
          ChallengeName: challengeName,
          ChallengeResponses: challengeResponse,
          Session: loginToken,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
      }
    ).then((res) => {
      if (res.status === 400 && res.data.__type == 'CodeMismatchException') {
        ReactGA.event({
          category: 'Login Verification failure',
          action: 'CodeMismatchException',
        });
        this.setState({
          verificationCodeError: 'pages.forgotUserId.invalidVerificationCode',
        });
      } else if (
        res.status === 200 &&
        res.data.AuthenticationResult &&
        res.data.AuthenticationResult.AccessToken
      ) {
        ReactGA.event({
          category: 'Login Verification Success',
          action: 'Verification done',
        });
        this.setState({
          loginToken: res.data.AuthenticationResult.AccessToken,
          verificationCodeError: '',
        });
        Cookies.set(
          'dataStoreToken',
          res.data.AuthenticationResult.AccessToken
        );
        //window.location.href = '/profile/Dashboard?app=cognito';
        window.location.href = '/profile/Dashboard';
      } else if (
        res.status === 400 &&
        res.data.__type === 'InvalidParameterException'
      ) {
        ReactGA.event({
          category: 'Login Verification failure',
          action: 'InvalidParameterException',
        });
        this.setState({
          verificationCodeError: 'pages.login.enterVerificationCode',
        });
      }
    });
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  showPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  PasswordValidation = (e) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/)) {
      this.setState({
        password: value,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onOptionsChange = (value) => {
    this.setState({
      selectedOption: value,
    });
  };

  onDeviceOptionsChange = (value) => {
    if (value.includes('Yes')) {
      value = 'Yes';
    } else {
      value = 'No';
    }
    this.setState({
      selectedRegisterOption: value,
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginPayLoad, verificationCode } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      loginPayLoad.callbacks[0].input[0].value = verificationCode;
      let url = '';
      http(
        {
          url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
          method: 'POST',
          data: loginPayLoad,
        },
        {
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
          const environment = process.env.REACT_APP_ENV;
          if (environment !== 'dev') {
            window.location.href =
              'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
          } else {
            window.location.href =
              'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
          }
        } else if (getNestedObject(res, ['data', 'callbacks'])) {
          if (
            res.status === 200 &&
            res.data &&
            (res.data.authId || res.data.tokenId)
          ) {
            if (getNestedObject(res, ['data', 'successUrl'])) {
              window.location.href = sanitizeUrl(`/profile/Dashboard`);
              // localStorage.setItem('userLoggedIn', true);
              Cookies.set('userLoggedIn', true);
            } else if (getNestedObject(res, ['data', 'callbacks'])) {
              const {
                data: { callbacks },
              } = res;
              if (callbacks) {
                if (callbacks[0].output[0].value === 'One Time Password') {
                  this.setState({
                    isAuthenticated: true,
                    loginToken: res.data.authId,
                    screen: 3,
                    loginPayLoad: res.data,
                    verificationCodeError:
                      'pages.forgotUserId.invalidVerificationCode',
                  });
                } else if (callbacks[0].type === 'ChoiceCallback') {
                  this.setState({
                    isAuthenticated: true,
                    loginToken: res.data.authId,
                    screen: 6,
                    loginPayLoad: res.data,
                    userOption: res.data.callbacks[0].output,
                  });
                } else {
                  this.setState({
                    userOption: res.data.callbacks[0].output[1].value,
                    isAuthenticated: true,
                    loginToken: res.data.authId,
                    screen: 2,
                    loginPayLoad: res.data,
                  });
                }
              }
            }
          }
        } else if (
          res.data.code === 401 &&
          res.data.message ===
            'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
        ) {
          this.showError('pages.login.willGetLocked');
        } else if (
          res.data.code === 401 &&
          res.data.message ===
            'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
        ) {
          this.showError('pages.login.willGetLocked2');
        } else if (
          res.data.code === 401 &&
          res.data.message === 'Login failure'
        ) {
          window.location.href = '/';
          this.showError('pages.login.loginFailure');
        }
      });
    }
  };

  socialLogin = (e) => {
    e.preventDefault();

    window.location.href =
      'https://accounts.google.com/o/oauth2/v2/auth/identifier?client_id=311124778386-gg0lmbj2779hpvdb6meq84r5drikb68c.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Ftestdemoaegis.auth.us-east-1.amazoncognito.com%2Foauth2%2Fidpresponse&scope=profile%20email%20openid&response_type=code&state=ZXlKMWMyVnlVRzl2YkVsa0lqb2lkWE10WldGemRDMHhYekJvV0RWTmEwbG1lU0lzSW5CeWIzWnBaR1Z5VG1GdFpTSTZJa2R2YjJkc1pTSXNJbU5zYVdWdWRFbGtJam9pTW05dmRtSmpZVGc0T1RObVkyNWpkR1E0YXpkdWFXZG9NVEFpTENKeVpXUnBjbVZqZEZWU1NTSTZJbWgwZEhBNkx5OXNiMk5oYkdodmMzUTZNekF3TUM5d2NtOW1hV3hsTDBSaGMyaGliMkZ5WkNJc0luSmxjM0J2Ym5ObFZIbHdaU0k2SW1OdlpHVWlMQ0p3Y205MmFXUmxjbFI1Y0dVaU9pSkhiMjluYkdVaUxDSnpZMjl3WlhNaU9sc2laVzFoYVd3aUxDSnZjR1Z1YVdRaUxDSndjbTltYVd4bElsMHNJbk4wWVhSbElqcHVkV3hzTENKamIyUmxRMmhoYkd4bGJtZGxJanB1ZFd4c0xDSmpiMlJsUTJoaGJHeGxibWRsVFdWMGFHOWtJanB1ZFd4c0xDSnViMjVqWlNJNkluSnFaMFpXT1U5b1VXMVFWMDFVZGxZeWJHcEdabEJZTUZWYU9HWTROWFI0T0RNMVQwMHRkVXBDVmpZMVNHVXlabFJ1VWpSbFF6aHdkakI0VFRCV1YwaG1RVFYyVVZsZmNXNXlOVUZTUVZGdFpsazNabXRHTkRCc1Z6WXdNQzFuYUhrdFoyWkhhM0UzYTNaVWJXWnZXSG8xUjBKVFNHZ3lPRzluYjFkRFdGQkdOMHg0ZGtKME5HWTFTa3B6TUU5NVF6bEVUMDFtVmtaVVdXVkVkbmxRVTNSalRqWTVWbGxCUVZoTlVTSXNJbk5sY25abGNraHZjM1JRYjNKMElqb2lkR1Z6ZEdSbGJXOWhaV2RwY3k1aGRYUm9MblZ6TFdWaGMzUXRNUzVoYldGNmIyNWpiMmR1YVhSdkxtTnZiU0lzSW1OeVpXRjBhVzl1VkdsdFpWTmxZMjl1WkhNaU9qRTJOVEF5TmpJNU9Ua3NJbk5sYzNOcGIyNGlPbTUxYkd3c0luVnpaWEpCZEhSeWFXSjFkR1Z6SWpwdWRXeHNMQ0p6ZEdGMFpVWnZja3hwYm10cGJtZFRaWE56YVc5dUlqcG1ZV3h6WlgwPTo1ajJmaWYxc3BUaFljdGxuMzJZYjFNK0FVT1hKUGYvRlFxRDRQMzhCWW9BPTo0&flowName=GeneralOAuthFlow';
  };

  onNext = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginPayLoad, selectedOption } = this.state;
    loginPayLoad.callbacks[0].input[0].value = selectedOption;
    http(
      {
        url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
        const environment = process.env.REACT_APP_ENV;
        if (environment !== 'dev') {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        } else {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        }
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/Dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              }
            } else if (callbacks[0].type === 'ChoiceCallback') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 6,
                loginPayLoad: res.data,
                userOption: '',
              });
            } else {
              this.setState({
                userOption: res.data.callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 2,
                loginPayLoad: res.data,
              });
            }
          }
        }
      }
    });
    this.scrollToTop();
  };

  onNextRegister = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { loginPayLoad, selectedRegisterOption } = this.state;
    if (selectedRegisterOption === 'Yes') {
      loginPayLoad.callbacks[0].input[0].value = 0;
    } else if (selectedRegisterOption === 'No') {
      loginPayLoad.callbacks[0].input[0].value = 1;
    }
    http(
      {
        url: 'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree',
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
        const environment = process.env.REACT_APP_ENV;
        if (environment !== 'dev') {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        } else {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        }
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/Dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'User Locked Out.'
      ) {
        this.showError('pages.login.userLockedOut');
        window.location.href = '/';
      }
    });
  };

  render() {
    const {
      userName,
      password,
      showPassword,
      errorText,
      errorArray,
      screen,
      userOption,
      selectedOption,
      verificationCode,
      verificationCodeError,
      verificationOption,
      scanTitle,
      qrCodeUrl,
      selectedRegisterOption,
      carouselImages,
      imageIndex,
      deliveryDestination,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <div
          className={`login-container
            ${screen > 1 ? 'has-background' : undefined}`}
        >
          {(errorText || errorArray.length > 0) && (
            <div className="error-div">
              <WithError errors={errorText} errorArray={errorArray} />
            </div>
          )}
          {screen === 1 && (
            <div className="log-container">
              <div className="row justify-content-center">
                <div className="col-md-6 p-0">
                  <div className="mt-3">
                    <AlertGlobal useTicker={false}></AlertGlobal>
                    <div className="row justify-content-center pt-5">
                      <Form className="login-form p-3">
                        <h1>{t('pages.login.login')}</h1>
                        <Form.Group controlId="loginEmail">
                          <Form.Label>
                            {t('pages.login.userId')} /{' '}
                            {t('pages.login.verifiedEmailAddress')}
                          </Form.Label>
                          <Form.Control
                            type="email"
                            onChange={(e) => {
                              this.setState({
                                userName: e.target.value.trim(),
                              });
                            }}
                            value={userName}
                            aria-required
                          />
                        </Form.Group>
                        <Form.Group
                          className="password-field position-relative"
                          controlId="loginPassword"
                        >
                          <Form.Label>{t('pages.login.password')}</Form.Label>
                          <Form.Control
                            className="login-password-input"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                              this.PasswordValidation(e);
                            }}
                            value={password}
                            aria-required
                            autoComplete="off"
                          />
                          <span
                            className={
                              showPassword
                                ? 'eye-icon login-password-eye show'
                                : 'eye-icon login-password-eye'
                            }
                            onClick={() => this.showPassword()}
                          />
                        </Form.Group>
                        <div className="row justify-content-center mt-3">
                          <div className="col">
                            <Button
                              className="btn w-100 btn btn-primary"
                              type="submit"
                              onClick={(e) => this.onLogin(e)}
                            >
                              {t('pages.login.logInBtnText')}
                            </Button>
                          </div>
                        </div>
                        <hr className="separator-login" />
                        <div className="row">
                          <div className="col text-center">
                            <p className="mb-2">{t('pages.login.orText')}</p>
                            <Link
                              className="text-blue"
                              onClick={(e) => this.onPasswordLessLogin(e)}
                            >
                              <FontAwesomeIcon icon={faLink} />
                              <span className="passwordless-link">
                                {t('pages.login.passwordLessAuthentication')}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </Form>
                      <hr className="separator-login" />
                    </div>
                    <div className="row">
                      <div className="col text-center">
                        <p className="mb-2">{t('pages.login.withText')}</p>
                        <a>
                          <img
                            className="social-login-img"
                            src={googleLogo}
                            alt="google"
                            onClick={(e) => this.socialLogin(e)}
                          />
                        </a>
                      </div>
                    </div>
                    <hr className="separator-login" />
                    <div className="row justify-content-center">
                      <Form className="login-form p-3">
                        <Form.Group>
                          <p className="mb-2 text-center">
                            {t('pages.login.noAccountQuestion')}
                          </p>
                          <Button
                            className="w-100 btn btn-secondary"
                            onClick={() => {
                              /*
                              window.location.href =
                                '/registration/?app=cognito';*/
                              window.location.href = '/registration/';
                            }}
                          >
                            {t('pages.login.signUpBtnText')}
                          </Button>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <div className="row justify-content-center mb-5">
                    <div className="forget-password-mobile col-sm-4 text-center text-sm-center">
                      <Link
                        //to="forgot-password?app=cognito"
                        to="forgot-password"
                        className="text-blue"
                      >
                        {t('pages.login.forgotPasswordLink')}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-0">
                  <div
                    className="hero-img-bg"
                    style={{
                      backgroundImage: `url(${
                        carouselImages[imageIndex || 0].image
                      })`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
          {screen === 2 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                {/* {localStorage.getItem('email') && ( */}
                {Cookies.get('email') && (
                  <div>
                    {t('pages.login.socialLoggedIn')}{' '}
                    <span className="bold">
                      {/* {localStorage.getItem('email')} */}
                      {Cookies.get('email')}
                    </span>
                  </div>
                )}
                <div className="mt-3 mb-3 choose-title">
                  {t('pages.login.choseMFA')}
                </div>
                <div>
                  <Form className="login-radio-buttons">
                    {userOption.length > 0 &&
                      userOption.map((payLoad, index) => (
                        <Radio
                          key={index}
                          name={payLoad}
                          label={payLoad}
                          value={index}
                          isChecked={selectedOption === index ? true : false}
                          handleChange={this.onOptionsChange}
                        />
                      ))}
                    <div className="row mt-3 mt-sm-5">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotPassword.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3 "
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                          disabled={selectedOption === ''}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          )}
          {screen === 8 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <h3>
                  <span>{t('pages.registration.verificationCodeSent')}</span>
                </h3>
                <Form>
                  <Form.Group
                    className="verification-form mt-5"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4">
                      {t('pages.registration.verificationCode')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      pattern="[0-9]*"
                      onInput={this.handleChange.bind(this)}
                      className="verification-code-input"
                      value={verificationCode}
                    />
                    {verificationCodeError && (
                      <p className="error-text" id="verification-error">
                        {verificationCodeError && t(verificationCodeError)}
                      </p>
                    )}
                  </Form.Group>
                  <div className="registration__container__pleasenote">
                    <p className="registration__container__pleasenote-fp">
                      {t('pages.registration.note')}
                    </p>
                    <p className="registration__container__pleasenote-sp">
                      {t('pages.registration.verifyNote')}
                    </p>
                  </div>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>

                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.onVerificationCodeSubmit(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
          {screen === 5 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <h3>
                  <span>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {t('pages.registration.mobileNumber')}
                  </span>
                </h3>
                <Form>
                  <Form.Group
                    className="verification-form"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4 mt-3">
                      {t('pages.registration.verificationCode')}
                    </Form.Label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      onInput={this.handleChange.bind(this)}
                      className="verification-code-input"
                      value={verificationCode}
                    />
                    {verificationCodeError && (
                      <p className="error-text" id="verification-error">
                        {verificationCodeError && t(verificationCodeError)}
                      </p>
                    )}
                  </Form.Group>
                  <div className="registration__container__pleasenote">
                    <p className="registration__container__pleasenote-fp">
                      {t('pages.registration.note')}
                    </p>
                    <p className="registration__container__pleasenote-sp">
                      {t('pages.registration.verifyNote')}
                    </p>
                  </div>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.onVerificationCodeSubmit(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}

          {screen === 3 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <h3>
                  <span>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {deliveryDestination}
                  </span>
                </h3>
                <Form>
                  <Form.Group
                    className="verification-form"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4 mt-3">
                      {t('pages.registration.verificationCode')}
                    </Form.Label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      onInput={this.handleChange.bind(this)}
                      className="verification-code-input ml-sm-4"
                      value={verificationCode}
                      maxLength="6"
                    />
                    {verificationCodeError && (
                      <p className="error-text" id="verification-error">
                        {verificationCodeError && t(verificationCodeError)}
                      </p>
                    )}
                  </Form.Group>
                  <div className="registration__container__pleasenote">
                    <p className="registration__container__pleasenote-fp">
                      {t('pages.registration.note')}
                    </p>
                    <p className="registration__container__pleasenote-sp">
                      {t('pages.registration.verifyNote')}
                    </p>
                  </div>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.otpVerification(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
          {screen === 4 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <h3>
                  <span>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {deliveryDestination}
                  </span>
                </h3>
                <Form>
                  <Form.Group
                    className="verification-form"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4 mt-3">
                      {t('pages.registration.verificationCode')}
                    </Form.Label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      onInput={this.handleChange.bind(this)}
                      className="verification-code-input ml-sm-4"
                      value={verificationCode}
                      maxLength="6"
                    />
                    {verificationCodeError && (
                      <p className="error-text" id="verification-error">
                        {verificationCodeError && t(verificationCodeError)}
                      </p>
                    )}
                  </Form.Group>
                  <div className="registration__container__pleasenote">
                    <p className="registration__container__pleasenote-fp">
                      {t('pages.registration.note')}
                    </p>
                    <p className="registration__container__pleasenote-sp">
                      {t('pages.registration.verifyNote')}
                    </p>
                  </div>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.otpVerificationPasswordless(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
          {screen === 6 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <div className="mt-3 mb-3 choose-title">
                  {userOption[0].value}
                </div>
                <div>
                  <Form>
                    <Radio
                      key={'Yes'}
                      name={'Yes'}
                      label={'Yes'}
                      value={'Yes'}
                      isChecked={
                        selectedRegisterOption === 'Yes' ? true : false
                      }
                      handleChange={this.onDeviceOptionsChange}
                    />
                    <Radio
                      key={'No'}
                      name={'No'}
                      label={'No'}
                      value={'No'}
                      isChecked={selectedRegisterOption === 'No' ? true : false}
                      handleChange={this.onDeviceOptionsChange}
                    />
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          type="submit"
                          onClick={(e) => this.onNextRegister(e)}
                          disabled={selectedRegisterOption === ''}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          )}
          {screen === 7 && (
            <>
              <div className="radiobox-container container my-5 forgot-username waiting-response">
                <div className="mt-3 mb-3 choose-title">
                  {userOption[1].value}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
Login.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(Login);
