import PageNotFound from 'app/modules/Shared/PageNotFound/PageNotFound';
import SomethingWentWrong from 'app/modules/Shared/SomethingWentWrong/SomethingWentWrong';
import ReactDOM from 'react-dom';
import moment from 'moment';
import http from 'api/http-request';
import queryString from 'query-string';
import { sanitizeUrl } from '@braintree/sanitize-url';
import secureRandom from 'secure-random';
import Cookies from 'js-cookie';

export const randomCount = () => {
  let bytes = secureRandom(1);
  if (bytes[0] <= 50) {
    return 0;
  } else if (bytes[0] > 50 && bytes[0] <= 100) {
    return 1;
  } else if (bytes[0] > 100 && bytes[0] <= 150) {
    return 2;
  } else if (bytes[0] > 150 && bytes[0] <= 200) {
    return 3;
  } else if (bytes[0] > 200 && bytes[0] <= 250) {
    return 4;
  } else if (bytes[0] > 250 && bytes[0] <= 300) {
    return 5;
  } else {
    return 6;
  }
};

export const hasSpecialCharacter = (value) => {
  const specialCharacterRegEx = new RegExp(
    '[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]'
  );
  return specialCharacterRegEx.test(value);
};

export const isPasswordFormatValid = (value) => {
  /* 
  Validation is now coming from backend
  return (
     value.length < 8 || value.length > 64
     ||
     !hasSpecialCharacter(value) ||
     !value.match('[A-Z]') ||
     !value.match('[a-z]') ||
     !value.match('[0-9]')
   );
  */
  return false;
};

export const getNestedObject = (nestedObj, pathArr) =>
  pathArr.reduce((obj, key) => (obj && obj[key] ? obj[key] : null), nestedObj);

export const removeLocalStorageValue = (name) => {
  // localStorage.removeItem(name);
  Cookies.remove(name);
};

export const formatPhoneNumber = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) {
      return currentValue;
    }
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
  return currentValue;
};

export const getPhoneNumberFromFormattedString = (formattedPhone) =>
  `+1${formattedPhone.substring(1, 4)}${formattedPhone.substring(
    6,
    9
  )}${formattedPhone.substring(10)}`;

export const getFormattedPhoneNumberFromUSFormat = (number) => {
  if (number) {
    if (number.length !== 12) {
      return null;
    }
    return `(${number.substring(2, 5)}) ${number.substring(
      5,
      8
    )}-${number.substring(8)}`;
  }
  return number;
};

export const preventNonDigitCharacters = (e) => {
  let charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
  if (charCode !== 13) {
    if (charCode >= 96 && charCode <= 105) {
      charCode -= 48;
    }
    let keyboardAction = false;
    const allowedKeys = [65, 67, 86, 88];
    if (
      allowedKeys.indexOf(e.keyCode) > -1 &&
      (e.ctrlKey === true || e.metaKey === true)
    ) {
      keyboardAction = true;
    }
    const isShiftKey = e.shiftKey || false;
    const charStr = String.fromCharCode(charCode);
    let isAllowedSpecialChars = false;
    if (/\W/.test(charStr)) {
      isAllowedSpecialChars = charCode === 8 || charCode === 9;
    }
    if (
      (isShiftKey && !isAllowedSpecialChars) ||
      (/\D/.test(charStr) && !keyboardAction && !isAllowedSpecialChars)
    ) {
      e.preventDefault();
    }
  }
};

export const pageNotFound = (value) => {
  if (value) {
    let setData = {};
    setData = value;
    ReactDOM.render(
      <PageNotFound setData={setData} />,
      document.getElementById('root')
    );
  }
};

export const capitalizeString = (s) => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};

export const somethingWentWrong = () => {
  ReactDOM.render(<SomethingWentWrong />, document.getElementById('root'));
};

export const checkTimeDiffValid = () => {
  // let startTime = localStorage.getItem('sessionTimeStart');
  let startTime = Cookies.get('sessionTimeStart');
  let currentTime = moment(new Date());
  let duration = moment.duration(currentTime.diff(startTime));
  let minutes = duration.asMinutes();
  if (minutes < 14) {
    return true;
  } else {
    return false;
  }
};

const endSession = () => {
  // let idToken = localStorage.getItem('idToken');
  let idToken = Cookies.get('idToken');
  let bearer = 'Bearer ';
  // let accessToken = localStorage.getItem('dataStoreToken');
  let accessToken = Cookies.get('dataStoreToken');
  http(
    {
      url: `endSession?id_token_hint=${idToken}`,
      method: 'GET',
    },
    {
      Authorization: `${bearer}${accessToken}`,
    }
  ).then(() => {
    // localStorage.removeItem('user_id');
    Cookies.remove('user_id');
    // localStorage.removeItem('dataStoreToken');
    Cookies.remove('dataStoreToken');
    // localStorage.removeItem('profileCodeDetails');
    Cookies.remove('profileCodeDetails');
    // localStorage.removeItem('sessionTimeStart');
    Cookies.remove('sessionTimeStart');

    // localStorage.setItem('userLoggedIn', false);
    Cookies.set('userLoggedIn', false);
    // localStorage.removeItem('idToken');
    Cookies.remove('idToken');
    // localStorage.removeItem('clientId');
    Cookies.remove('clientId');
    // localStorage.removeItem('userDetails');
    Cookies.remove('userDetails');
    // localStorage.removeItem('firstName');
    Cookies.remove('firstName');
    // localStorage.removeItem('lastName');
    Cookies.remove('lastName');
    // localStorage.removeItem('isUserLogin');
    Cookies.remove('isUserLogin');
    // localStorage.removeItem('userName');
    Cookies.remove('userName');
    Cookies.remove('admin');
    Cookies.remove('firstLoginSession');
    // Cookies.remove('idProofingStatus');
    Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
    window.location.href = sanitizeUrl('/');
  });
};

const getAPIHeaders = () => {
  return {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
};
const deleteStorage = () => {
  // let accessToken = localStorage.getItem('dataStoreToken');
  let accessToken = Cookies.get('dataStoreToken');
  let payLoad = {};
  payLoad.token = accessToken;
  // payLoad.client_id = localStorage.getItem('clientId');
  payLoad.client_id = Cookies.get('clientId');
  http(
    {
      url: 'revoke?realm=/aegis',
      method: 'POST',
      data: queryString.stringify(payLoad),
    },
    { ...getAPIHeaders() }
  ).then(() => {
    endSession();
  });
};

const checkSession = () => {
  let sessionData = false;
  http(
    {
      url: 'sessions?_action=getSessionInfo',
      method: 'POST',
    },
    {
      'Accept-API-Version': 'protocol=1.0,resource=2.0',
    }
  ).then((res) => {
    if (res.data && res.status === 200) {
      const environment = `${process.env.REACT_APP_ENV}`;
      const redirectUri = `${process.env.REACT_APP_REDIRECT_URI}`;
      const redirectUriLocal = `${process.env.REACT_APP_REDIRECT_URI_LOCAL}`;
      if (environment === 'dev') {
        window.location.href = redirectUri;
      } else {
        window.location.href = redirectUriLocal;
      }
    } else {
      deleteStorage();
    }
  });
};

export const redirectLogic = () => {
  checkSession();
};
