import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard Cognito/ChangePassword.scss';
import { faClock, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import http from 'api cognito/http-request';
import moment from 'moment-timezone';
import 'app/modules/Dashboard Cognito/Devices.scss';
import Cookies from 'js-cookie';

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordPopup: false,
      userDetails: props.userDetails ? props.userDetails : '',
      oAuthDevice: [],
      trustDevices: [],
      oPushDevice: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // if (localStorage.getItem('userName')) {
    if (Cookies.get('userName')) {
      // this.getInfo(localStorage.getItem('userName'));
      // this.getDevices(localStorage.getItem('userName'));
      // this.getPushDevice(localStorage.getItem('userName'));
      this.getInfo(Cookies.get('userName'));
      this.getDevices(Cookies.get('userName'));
      this.getPushDevice(Cookies.get('userName'));
    } else {
      this.getInfo(this.state.userDetails.userName);
      // localStorage.setItem('userName', this.state.userDetails.userName);
      Cookies.set('userName', this.state.userDetails.userName);
      this.getDevices(this.state.userDetails.userName);
      // this.getPushDevice(localStorage.getItem('userName'));
      this.getPushDevice(Cookies.get('userName'));
    }
  }

  getInfo = (details) => {
    http({
      url: `users/${details}/devices/2fa/oath?_queryFilter=true`,
      method: 'GET',
    }).then((res) => {
      if (res.data && res.status === 200 && res.data.result) {
        this.setState({
          oAuthDevice: res.data.result,
        });
      }
    });
  };

  getPushDevice = (details) => {
    http({
      url: `users/${details}/devices/2fa/push?_queryFilter=true`,
      method: 'GET',
    }).then((res) => {
      if (res.data && res.status === 200 && res.data.result) {
        this.setState({
          oPushDevice: res.data.result,
        });
      }
    });
  };

  getDevices = (details) => {
    http({
      url: `users/${details}/devices/profile/?_queryId=*`,
      method: 'GET',
    }).then((res) => {
      if (res.data && res.status === 200 && res.data.result) {
        this.setState({
          trustDevices: res.data.result,
        });
      }
    });
  };

  delete = (uuid) => {
    let bearer = 'Bearer ';
    // let userName = localStorage.getItem('userName');
    // let token = localStorage.getItem('dataStoreToken');
    let userName = Cookies.get('userName');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `users/${userName}/devices/2fa/oath/${uuid}`,
        method: 'DELETE',
      },
      {
        'Accept-API-Version': 'protocol=2.0,resource=1.0',
      }
    ).then((res) => {
      if (res.status === 200) {
        // this.getInfo(localStorage.getItem('userName'));
        this.getInfo(Cookies.get('userName'));
      }
    });
  };

  deleteDevice = (deviceId) => {
    // let userName = localStorage.getItem('userName');
    let userName = Cookies.get('userName');
    http(
      {
        url: `users/${userName}/devices/profile/${deviceId}`,
        method: 'DELETE',
      },
      {
        'Accept-API-Version': 'protocol=2.0,resource=1.0',
      }
    ).then((res) => {
      if (res.status === 200) {
        // this.getDevices(localStorage.getItem('userName'));
        this.getDevices(Cookies.get('userName'));
      }
    });
  };

  deletePushDevice = (uuid) => {
    let bearer = 'Bearer ';
    let userName = Cookies.get('userName');
    // let userName = localStorage.getItem('userName');
    // let token = localStorage.getItem('dataStoreToken');
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `users/${userName}/devices/2fa/push/${uuid}`,
        method: 'DELETE',
      },
      {
        'Accept-API-Version': 'protocol=2.0,resource=1.0',
      }
    ).then((res) => {
      if (res.status === 200) {
        // this.getPushDevice(localStorage.getItem('userName'));
        this.getPushDevice(Cookies.get('userName'));
      }
    });
  };

  render() {
    const { t } = this.props;
    const { oAuthDevice, trustDevices, oPushDevice } = this.state;
    return (
      <div>
        <div className="change-password devices">
          <h1>{t('pages.devices.title')}</h1>
          <hr className="separator-solid" />
          <h2>{t('pages.devices.authenticateDevice')}</h2>
          <div className="mt-3">
            <div className="row ml-0">
              {oAuthDevice.length > 0 ? (
                oAuthDevice.map((device, index) => (
                  <div
                    key={index}
                    className="col-sm-2 app-box-item box-shadow text-center device-box"
                  >
                    <FontAwesomeIcon icon={faClock} role="img" />
                    <div>{device.deviceName}</div>
                    <div>
                      {t('pages.devices.active')}
                      <span>{t('pages.devices.now')}</span>
                    </div>
                    {device.deviceManagementStatus && (
                      <div className="mt-3">
                        <a
                          className="mt-1"
                          onClick={() => this.delete(device.uuid)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="inside-button"
                            role="img"
                          />
                          <p>{t('pages.devices.delete')}</p>
                        </a>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="no-devices mb-3">
                  {t('pages.devices.noAuthDevices')}
                </div>
              )}
            </div>
          </div>
          <hr className="separator-solid" />
          <h2>{t('pages.devices.pushDevice')}</h2>
          <div className="mt-3">
            <div className="row ml-0"></div>
            {oPushDevice.length > 0 ? (
              oPushDevice.map((device, index) => (
                <div
                  key={index}
                  className="col-sm-2 app-box-item box-shadow text-center device-box"
                >
                  <FontAwesomeIcon icon={faClock} role="img" />
                  <div>{device.deviceName}</div>
                  <div>
                    {t('pages.devices.active')}
                    <span>{t('pages.devices.now')}</span>
                  </div>
                  {device.deviceManagementStatus && (
                    <div className="mt-3">
                      <a
                        className="mt-1"
                        onClick={() => this.deletePushDevice(device.uuid)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="inside-button"
                          role="img"
                        />
                        <p>{t('pages.devices.delete')}</p>
                      </a>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="no-devices mb-3">
                {t('pages.devices.noPushDevices')}
              </div>
            )}
          </div>
          <hr className="separator-solid" />
          <h2>{t('pages.devices.trustedDevices')}</h2>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t('pages.devices.no')}</th>
                  <th scope="col">{t('pages.devices.name')}</th>
                  <th scope="col">{t('pages.devices.os')}</th>
                  <th scope="col">{t('pages.devices.browser')}</th>
                  <th scope="col">{t('pages.devices.cpu')}</th>
                  <th scope="col">{t('pages.devices.lastUsed')}</th>
                  <th scope="col">{t('pages.devices.delete')}</th>
                </tr>
              </thead>
              <tbody>
                {trustDevices.length > 0 ? (
                  trustDevices.map((device, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{device.metadata.platform.deviceName}</td>
                      <td>
                        {device.metadata.browser.userAgent
                          .substring(
                            device.metadata.browser.userAgent.indexOf('('),
                            device.metadata.browser.userAgent.indexOf(')')
                          )
                          .replace('(', '')}
                      </td>
                      <td>
                        {
                          device.metadata.browser.userAgent
                            .split(' ')
                            .splice(
                              device.metadata.browser.userAgent.split(' ')
                                .length - 2
                            )[0]
                        }
                      </td>
                      <td>
                        {device.metadata
                          ? device.metadata.platform.platform
                          : ''}
                      </td>
                      <td>
                        {device.lastSelectedDate
                          ? moment(device.lastSelectedDate).format(
                              'DD MMM YYYY hh:mm a'
                            )
                          : '--'}
                      </td>
                      <td>
                        <a onClick={() => this.deleteDevice(device.identifier)}>
                          {t('pages.devices.delete')}
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>{t('pages.devices.noDevices')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
Devices.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
  userData: PropTypes.object,
};
Devices.defaultProps = {
  userDetails: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
};
export default withTranslation()(Devices);
