import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard Cognito/ChangePassword.scss';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import ChangePasswordPopup from 'app/modules/Dashboard Cognito/ChangePasswordPopup';
import ChangeSecurityQuestionsPopup from 'app/modules/Dashboard Cognito/ChangeSecurityQuestionsPopup';
import Alert from 'app/modules/Shared/Alert Cognito/Alert';
import { aws_request } from 'api cognito/aws-request';
import moment from 'moment';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordPopup: false,
      showSetQuestionsPopup: false,
      questionVals: [null, null, null],
      filterOptions: [],
      userData: props.userDetails ? props.userDetails : '',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getLastLoginTime();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userDetails !== prevState.userData) {
      this.setState({
        userData: prevProps.userDetails,
      });
    }
  }

  getLastLoginTime = () => {
    const { userDetails } = this.props;

    let customSet = (params) => {
      this.setState(params);
    };
    var params = {
      UserPoolId: `${process.env.REACT_APP_COGNITO_UserPoolID}`,
      Username: userDetails.Username,
      MaxResults: 1,
    };

    let cognitoidentityserviceprovider = aws_request();

    cognitoidentityserviceprovider.adminListUserAuthEvents(
      params,
      function (err, data) {
        let dateTime = 'Data not Available';
        if ('AuthEvents' in data && data.AuthEvents.length > 0) {
          dateTime = data.AuthEvents[0].CreationDate;
          dateTime = moment(dateTime).format('dddd, MMMM Do YYYY, HH:MM:SS A');
        }

        customSet({
          lastLoginDate: dateTime,
        });
      }
    );
  };

  showChangePasswordPopup = () => {
    this.setState({
      showPasswordPopup: true,
    });
  };

  showSetQuestionsPopup = () => {
    this.setState({
      showSetQuestionsPopup: true,
    });
  };
  onChangePasswordHandler = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.editName.passwordChangeSuccessMsg'
        : 'pages.editName.passwordChangeErrorMsg';
    this.setState(
      {
        showPasswordPopup: false,
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };

  updateSecurity = (status) => {
    const alertText =
      status === 'success'
        ? 'pages.changePassword.resetSecurity'
        : 'pages.changePassword.resetSecurity';
    this.setState(
      {
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 5000);
      }
    );
  };

  render() {
    const { t } = this.props;
    const {
      showPasswordPopup,
      showSetQuestionsPopup,
      alertText,
      alertStatus,
      userData,
      lastLoginDate,
      lastLoginTime,
    } = this.state;
    return (
      <div>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        {showPasswordPopup && (
          <ChangePasswordPopup
            onClose={() => {
              this.setState({ showPasswordPopup: false });
            }}
            onChangePasswordHandler={this.onChangePasswordHandler}
          />
        )}

        {showSetQuestionsPopup && (
          <ChangeSecurityQuestionsPopup
            onClose={() => {
              this.setState({ showSetQuestionsPopup: false });
            }}
            updateSecurity={this.updateSecurity}
          />
        )}

        <div className="personal-information">
          <h1 className="pb-3">{t('pages.changePassword.title')}</h1>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.changePassword.username')}
            </div>
            <div className="col-sm-5">{userData.Username}</div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.changePassword.password')}
            </div>
            <div className="col-sm-5">
              {t('pages.changePassword.passwordValue')}
            </div>
            <div
              className="col-sm-2 text-right"
              onClick={() => {
                this.showChangePasswordPopup();
              }}
              role="button"
              tabIndex={0}
              onKeyPress={() => {
                this.showChangePasswordPopup();
              }}
              aria-label="Edit Password"
            >
              <FontAwesomeIcon icon={faPenAlt} role="img" />
            </div>
          </div>
          <div className="personal-information__container">
            <div className="col-sm-5 bold">
              {t('pages.changePassword.loginActivity')}
            </div>
            <div className="col-sm-5">
              {`${t('pages.changePassword.lastLoginOn')} - ${lastLoginDate}`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ChangePassword.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
};
ChangePassword.defaultProps = {
  userDetails: {
    userName: '',
    mail: '',
    telephoneNumber: '',
    givenName: '',
    sn: '',
  },
};
export default withTranslation()(ChangePassword);
