import React, { Component } from 'react';
import { Tab, Tabs, Breadcrumb } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { faSearch, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import 'app/modules/Dashboard/Dashboard.scss';
import 'app/modules/Dashboard/Apps.scss';
import PropTypes from 'prop-types';
import http from 'api/http-request';
import 'app/modules/Dashboard/Admin.scss';
import Cookies from 'js-cookie';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AlertGlobal from '../Shared/AlertGlobal/AlertGlobal';
import SalesforceLogo from 'assets/img/SF.png';
import ServicenowLogo from 'assets/img/SN.png';
import IdProofingPopup from '../IdProofing/IdProofingPopup';
import AppsAccessPopup from '../IdProofing/AppsAccessPopup';
import BusinessImg from 'assets/img/Business Licenses.png';
import DML from 'assets/img/Department of Labour.png';
import DNR from 'assets/img/Department of Natural Resources.png';
import HEDU from 'assets/img/Higher Education.png';

class Apps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminApps: [
        {
          appname: 'Salesforce',
          applink: 'https://d5e000002f5fqeas-dev-ed.my.salesforce.com',
          photo: SalesforceLogo,
        },
        {
          appname: 'Servicenow',
          applink:
            'https://aegis-am.digitalidentityservice.com/am/idpssoinit?metaAlias=/aegis/salesforce&spEntityID=https%3A%2F%2Fdeloitteadvisoryinnovation.service-now.com',
          photo: ServicenowLogo,
        },
      ],
      walletApps: [
        {
          appname: 'Recreational',
          appsincluded: [
            {
              includedappname: 'Department of Natural Resources',
              applink: 'https://uib.aegis-frk.com/dnr/dashboard',
              photo: DNR,
            },
          ],
        },
        {
          appname: 'Business Licenses',
          appsincluded: [
            {
              includedappname: 'State E-Licensing System',
              applink: 'https://uib.aegis-frk.com/business-licenses/dashboard',
              photo: BusinessImg,
            },
          ],
        },
        {
          appname: 'Employment',
          appsincluded: [
            {
              includedappname: 'Department of Labour',
              applink: 'https://uib.aegis-frk.com/dol/dashboard',
              photo: DML,
            },
          ],
        },
        {
          appname: 'Higher Education',
          appsincluded: [
            {
              includedappname: 'State University',
              applink: 'https://uib.aegis-frk.com/university/dashboard',
              photo: HEDU,
            },
          ],
        },
      ],
      appDetails: {},
      searchString: '',
      userDetails: props.userDetails ? props.userDetails : {},
      showIdPlusPopup: false,
      idProofingStatus: Cookies.get('idProofingStatus'),
      showCustomWallet: false,
    };
  }
  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };
  componentDidMount() {
    this.scrollToTop();
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');

    if (Cookies.get('dashboardRequired') == 'customWallet') {
      this.setState({ showCustomWallet: true });
    }

    // this.getAdminApps();
  }

  getAdminApps = () => {
    let bearer = 'Bearer ';
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `endpoint/getAllApp`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      this.setState({
        adminApps: res.data.result,
      });
    });
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  closePopup = (e) => {
    if (this.state.idProofing === 'false') {
      this.setState({ showIdPlusPopup: true });
    }
  };
  closeIdProofingPopup = (e) => {
    this.setState({ showIdPlusPopup: false });
  };
  onAppAccess = (device, index) => {
    if (this.state.idProofingStatus != '2') {
      this.setState({
        showIdPlusPopup: true,
      });
    } else {
      this.setState({
        showIdPlusPopup: false,
      });
      this.redirectToApp(device.applink);
    }
  };

  setFilerKeyword = (e) => {
    let searchString = e.target.value;
    this.setState({ searchString: searchString });
  };

  redirectToApp = (appLink) => {
    if (appLink.indexOf('https://') === 0 || appLink.indexOf('http://') === 0) {
      window.open(appLink, '_blank');
    } else {
      window.open('https://' + appLink, '_blank');
    }
  };
  render() {
    const { t } = this.props;
    const {
      adminApps,
      searchString,
      showIdPlusPopup,
      walletApps,
      showCustomWallet,
    } = this.state;
    
    return (
      <>
        {showIdPlusPopup && (
          <AppsAccessPopup closePopup={this.closeIdProofingPopup} />
        )}
        <div>
          <AlertGlobal useTicker={false}></AlertGlobal>
        </div>
        <div className="admin-apps apps-tab">
          <div className="tab-area pb-5">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                {t('pages.BreadCrumb.home')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('pages.BreadCrumb.applications')}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>...</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs
              defaultActiveKey="My-Apps"
              transition={false}
              className="ml-3"
            >
              <Tab
                eventKey="My-Apps"
                title={t('pages.Apps.tabMyApps')}
                tabClassName="px-3"
              >
                <div className="m-5">
                  <div className="row mb-5 align-items-center">
                    <div className="col-md-5 mb-3 mb-sm-0">
                      <h1>{t('pages.Admin.searchBarMyAppsPlaceholder')}</h1>
                      <div className="input-group rounded">
                        <input
                          type="search"
                          className="form-control rounded"
                          onChange={this.setFilerKeyword}
                          placeholder={t(
                            'pages.Admin.searchBarMyAppsPlaceholder'
                          )}
                          aria-label={t('pages.Apps.searchBarAriaLabel')}
                          aria-describedby="search-addon"
                        />
                        <span
                          className="input-group-text border-0"
                          id="search-addon"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0 mb-5" style={{ flexFlow: 'column'}}>
                    {!showCustomWallet && (
                      <div>
                    {adminApps?.length > 0 ? (
                      adminApps
                        .filter((item) =>
                          item.appname
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        )
                        .map((device, index) => (
                          <div
                            key={index}
                            className="col-sm-6 col-md-3 col-lg-2 mx-0 mx-sm-4 my-4 app-box-item box-shadow text-center mt-3 app-content"
                            onClick={(e) => this.onAppAccess(device, index)}
                            tabIndex="0"
                          >
                            {device.photo ? (
                              <img
                                className="apps-img"
                                src={`${device.photo}`}
                                alt=""
                                style={{ width: '80%', height: '80%' }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTabletAlt}
                                className="inside-button app-icon"
                                role="img"
                              />
                            )}
                            <div className="device-name">
                                  {device.appname}
                                </div>
                          </div>
                        ))
                    ) : (
                      <div className="no-devices mb-3">
                        {t('pages.Admin.noApps')}
                      </div>
                       )}</div>
                    )}

                    {showCustomWallet && walletApps?.length > 0 ? (
                      walletApps
                        .filter((item) =>
                          item.appname
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        )
                        .map((device, index) => (
                          <div
                            key={index}
                            style={{ alignContent: 'center' }}
                            tabIndex="0"
                          >
                            <hr style={{height:"2px", borderWidth:0, color:"gray", backgroundColor:"gray", width: "100%", alignSelf: "center"}} />
                            <div
                              className="device-name"
                              style={{ marginBottom: '20px' }}
                            >
                              <h2>{device.appname}</h2>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginBottom: '20px',
                                flexDirection: 'column',
                              }}
                            >
                              {device.appsincluded?.length > 0 &&
                               device.appsincluded.map((apps, index1) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginRight: '10px',
                                    alignItems:'center',
                                  }}
                                  key={index1}
                                >
                                  <div
                                      onClick={() =>
                                        this.redirectToApp(apps.applink)
                                      }
                                      style={{
                                        display: 'flex',
                                        alignItems:'center',
                                        marginBottom: '2rem',
                                        marginRight: '1rem',
                                        cursor: 'pointer',
                                        marginLeft:'10px',
                                      }}
                                    >
                                      {apps.photo ? (
                                        <img
                                          className="apps-img"
                                          src={`${apps.photo}`}
                                          alt=""
                                          style={{
                                            display: 'block',
                                            width: '40px',
                                            height: '40px',
                                          }}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faTabletAlt}
                                          className="inside-button app-icon"
                                          role="img"
                                        />
                                      )}
                                      <p style={{marginLeft:'10px', marginBottom:'auto' , marginTop : 'auto'}}> {apps.includedappname}</p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))  
                    ) : (
                      <div className="no-devices mb-3">
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

Apps.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,

  setUserDetails: PropTypes.func.isRequired,
};
export default withTranslation()(Apps);
