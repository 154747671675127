import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import 'App.scss';

import Login from 'app/modules/Login/Login';
import LoginMobile from 'app/modules/Login/LoginMobile';
import LoginCustomWallet from 'app/modules/Login/LoginCustomWallet';
import LoginCognito from 'app/modules/Login Cognito/Login';
import LoginAegisCloud from 'app/modules/LoginAegisCloud/Login';
import ForgotPassword from 'app/modules/ForgotPassword/ForgotPassword';
import ForgotPasswordAegisCloud from 'app/modules/ForgotPassword AegisCloud/ForgotPassword';
import ForgotPasswordCognito from 'app/modules/ForgotPassword/ForgotPasswordCognito';
import ForgotUserId from 'app/modules/ForgotUserId/ForgotUserId';
import ForgotUserIdAegisCloud from 'app/modules/ForgotUserId AegisCloud/ForgotUserId';
import Header from 'app/modules/Header/Header';
import HeaderAegisCloud from 'app/modules/Header AegisCloud/Header';
import HeaderCognito from 'app/modules/Header Cognito/Header';
import Footer from 'app/modules/Footer/Footer';
import FooterAegisCloud from 'app/modules/Footer AegisCloud/Footer';
import FooterCognito from 'app/modules/Footer Cognito/Footer';
import Dashboard from 'app/modules/Dashboard/Dashboard';
import DashboardMobile from 'app/modules/Dashboard/DashboardMobile';
import DashboardAegisCloud from 'app/modules/Dashboard AegisCloud/Dashboard';
import DashboardCognito from 'app/modules/Dashboard Cognito/Dashboard';

import Registration from 'app/modules/Registration/Registration';
import RegistrationAegisCloud from 'app/modules/Registeration AegisCloud/Registration';

import RegistrationCognito from 'app/modules/Registration Cognito/Registration';
import Terms from 'app/modules/Shared/Terms/Terms';
import Help from 'app/modules/Shared/Help/Help';
import ContactUs from 'app/modules/Shared/ContactUs/ContactUs';
import SomethingWentWrong from 'app/modules/Shared/SomethingWentWrong/SomethingWentWrong';

import AWSConnectChatbot from 'app/modules/Shared/ChatBot/AWSConnectChatbot';
import BusinessRegistration from 'app/modules/BusinessRegistration/BusinessRegistration';
import IdProofingLandingPage from 'app/modules/IdProofing/IdProofingLandingPage';
import IdProofingSocure from 'app/modules/IdProofing/IdProofingSocure';
import IdProofingCompleted from 'app/modules/IdProofing/IdProofingCompleted';

import ReactGA from 'react-ga';
import IdProofingFailed from 'app/modules/IdProofing/IdProofingFailed';

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

    ReactGA.pageview(location.pathname);
  }, [location]);
}

function App() {
  const [userDetails, setUserDetails] = useState({});
  const [hasRouteChanged, setHasRouteChanged] = useState(false);
  //const [searchParam, setSearchParam] = useSearchParams();
  //const appParam = searchParam.get('app');

  usePageViews();
  let location = useLocation();
  //const appParam = queryString.parse(location.search).app;
  const appParam = `${process.env.REACT_APP_Name}`;
  return (
    <div className="App App-View" role="main">
      <div className="loader hide" />

      {appParam == 'cognito' ? (
        <HeaderCognito userDetails={userDetails} />
      ) : appParam == 'aegisCloud' ? (
        <HeaderAegisCloud userDetails={userDetails} />
      ) : (
        <Header userDetails={userDetails} />
      )}
      <Switch>
        <Route
          exact
          path="/"
          component={
            appParam == 'cognito'
              ? LoginCognito
              : appParam == 'aegisCloud'
              ? LoginAegisCloud
              : Login
          }
        />

        <Route
          exact
          path="/mobile"
          component={
            appParam == 'cognito'
              ? LoginCognito
              : appParam == 'aegisCloud'
              ? LoginAegisCloud
              : LoginMobile
          }
        />

        <Route
          exact
          path="/customwallet"
          component={
            appParam == 'cognito'
              ? LoginCognito
              : appParam == 'aegisCloud'
              ? LoginAegisCloud
              : LoginCustomWallet
          }
        />

        <Route
          path="/forgot-password"
          component={
            appParam == 'cognito'
              ? ForgotPasswordCognito
              : appParam == 'aegisCloud'
              ? ForgotPasswordAegisCloud
              : ForgotPassword
          }
        />

        <Route
          path="/forgot-userId"
          component={
            appParam == 'aegisCloud' ? ForgotUserIdAegisCloud : ForgotUserId
          }
        />
        <Route
          path="/registration"
          component={
            appParam == 'cognito'
              ? RegistrationCognito
              : appParam == 'aegisCloud'
              ? RegistrationAegisCloud
              : Registration
          }
        />
        <Route path="/business-registration" component={BusinessRegistration} />
        <Route path="/idproofing-landing" component={IdProofingLandingPage} />
        <Route path="/idproofing-socureLogin" component={IdProofingSocure} />
        <Route path="/idproofing-completed" component={IdProofingCompleted} />
        <Route path="/idproofing-failed" component={IdProofingFailed} />
        <Route path="/terms" component={Terms} />
        <Route path="/help" component={Help} />
        <Route path="/contact-us" component={ContactUs} />
        <Route
          path="/profile/dashboardMobile"
          render={(props) => {
            return (
              <DashboardMobile
                {...props}
                setUserDetails={setUserDetails}
                setHasRouteChanged={setHasRouteChanged}
              />
            );
          }}
        />

        <Route
          path="/profile/:pageName?"
          render={(props) => {
            if (appParam == 'cognito') {
              return (
                <DashboardCognito
                  {...props}
                  setUserDetails={setUserDetails}
                  setHasRouteChanged={setHasRouteChanged}
                />
              );
            } else if (appParam == 'aegisCloud') {
              return (
                <DashboardAegisCloud
                  {...props}
                  setUserDetails={setUserDetails}
                  setHasRouteChanged={setHasRouteChanged}
                />
              );
            } else {
              return (
                <Dashboard
                  {...props}
                  setUserDetails={setUserDetails}
                  setHasRouteChanged={setHasRouteChanged}
                />
              );
            }
          }}
        />
        {/* <Route component={<SomethingWentWrong />} /> */}
      </Switch>
      {appParam == 'cognito' ? (
        <FooterCognito />
      ) : appParam == 'aegisCloud' ? (
        <FooterAegisCloud />
      ) : (
        <Footer />
      )}
      <AWSConnectChatbot hasRouteChanged={hasRouteChanged}></AWSConnectChatbot>
    </div>
  );
}

export default App;
