import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'app/modules/Registration/Registration.scss';
import Form from 'react-bootstrap/Form';
import { Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import {
  isPasswordFormatValid,
  preventNonDigitCharacters,
  formatPhoneNumber,
} from 'utils/common';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import Select from 'react-select';
import http from 'api aegisCloud/http-request';
import { getNestedObject } from 'utils/common';
import Alert from 'app/modules/Shared/Alert/Alert';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import validator from 'validator';
class Registration extends Component {
  constructor(props) {
    super(props);
    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.verificationInputRef = React.createRef();
    this.telephoneNumberInputRef = React.createRef();
    this.state = {
      password: '',
      confirmPassword: '',
      screen: 0,
      verificationPlaceholder: ['0', '0', '0', '0', '0', '0', '0', '0'],
      currentStep: 0,
      stepNames: [
        'pages.formNavigator.personalInfo',
        'pages.formNavigator.securityQuestion',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification',
      ],
      maxStep: 3,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      givenName: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      sn: '',
      userName: '',
      mail: '',
      confirmEmail: '',
      confirmPasswordError: '',
      passwordError: '',
      formSubmitted: false,
      errorText: '',
      errorArray: [],
      questionVals: [],
      token: '',
      telephoneNumber: '',
      telephoneNumberUSFormatted: '',
      numberFocus: false,
      filterOptions: [
        {
          value: '1',
          label: "What's your favorite color?",
        },
        {
          value: '2',
          label: 'Who was your first employer?',
        },
        {
          value: '3',
          label: 'In which city were you born in?',
        },
        {
          value: '4',
          label: 'What is your mother maiden name?',
        },
      ],
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      telephoneNumberFormatted: '',
      preferredLanguage: 'en',
      payLoad: [],
    };
  }

  componentDidMount() {
    this.scrollToTop();
    this.getToken();
  }

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  getToken = () => {
    http(
      {
        url: 'Registration',

        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.setState({ payLoad: res.data });
    });
  };

  genericResponse = (res) => {
    this.scrollToTop();
    if (getNestedObject(res, ['data', 'callbacks'])) {
      const {
        data: { callbacks },
      } = res;
      if (callbacks) {
        if (callbacks[0].type === 'ReCaptchaCallback') {
          this.increaseCurrentStepHandler();
          const recaptchaSiteKey = res.data.callbacks[0].output[0].value;
          if (recaptchaSiteKey) {
            this.setState({
              showGoogleCaptcha: true,
              googleCaptchaToken: recaptchaSiteKey,
              screen: 4,
              payLoad: res.data,
            });
          }
        } else if (callbacks[0].type === 'SuspendedTextOutputCallback') {
          this.setState({
            screen: 3,
            payLoad: res.data,
          });
        } else if (callbacks[0].type === 'KbaCreateCallback') {
          this.setState({
            payLoad: res.data,
            screen: 1,
          });
          this.increaseCurrentStepHandler();
        } else if (callbacks[0].type === 'PasswordCallback') {
          this.setState({
            payLoad: res.data,
            screen: 2,
            verificationOption: res.data.callbacks[0].output[0].value,
          });
          this.increaseCurrentStepHandler();
        } else if (callbacks[0].type === 'ValidatedCreatePasswordCallback') {
          this.setState({
            payLoad: res.data,
            screen: 4,
          });
        } else if (callbacks[0].type === 'ValidatedCreateUsernameCallback') {
          this.showError('errors.invalidUserNameDesc');
          this.scrollToTop();
        }
      }
    }
  };

  checkUsername = () => {
    const {
      givenName,
      sn,
      userName,
      mail,
      password,
      telephoneNumber,
      preferredLanguage,
      payLoad,
    } = this.state;

    this.setState({
      errorText: '',
    });

    payLoad.callbacks[0].input[0].value = userName;

    payLoad.callbacks[1].input[0].value = givenName;

    payLoad.callbacks[2].input[0].value = sn;

    payLoad.callbacks[3].input[0].value = mail;

    payLoad.callbacks[4].input[0].value = false;

    payLoad.callbacks[5].input[0].value = false;

    payLoad.callbacks[6].input[0].value = password;
    payLoad.callbacks[7].input[0].value = true;
    payLoad.callbacks[8].input[0].value = telephoneNumber
      ? `+1${telephoneNumber}`
      : '';

    if (password.length >= 8 && password.length <= 64) {
      http(
        {
          url: 'Registration',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        this.genericResponse(res);
      });
    } else {
      this.showError('errors.passwordLengthError');
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      formSubmitted: true,
    });
    const {
      givenName,
      sn,
      userName,
      mail,
      confirmEmail,
      password,
      confirmPassword,
      telephoneNumber,
    } = this.state;
    const hasGivenName = this.isFormFieldValid(givenName, 'givenNameError');
    const hasSn = this.isFormFieldValid(sn, 'snError');
    const hasUserName = this.isFormFieldValid(userName, 'userNameError');
    const hasMail = this.isFormFieldValid(mail, 'emailError');
    const hasConfirmMail = this.isFormFieldValid(
      confirmEmail,
      'confirmEmailError'
    );
    const hasPassword = this.isFormFieldValid(password, 'passwordError');
    const hasConfirmPassword = this.isFormFieldValid(
      confirmPassword,
      'confirmPasswordError'
    );
    const hasTelephoneNumber = this.isFormFieldValid(
      telephoneNumber,
      'telephoneNumberError'
    );
    if (
      hasGivenName ||
      hasSn ||
      hasUserName ||
      hasMail ||
      hasConfirmMail ||
      hasPassword ||
      hasConfirmPassword ||
      hasTelephoneNumber
    ) {
      this.showError('errors.required');
    } else {
      if (telephoneNumber !== '') {
        const currentValue = this.state.telephoneNumber;
        this.setState((prevState) => ({
          ...prevState,
          telephoneNumber: formatPhoneNumber(
            currentValue,
            prevState.telephoneNumber
          ).replace(/[^A-Z0-9]/gi, ''),
        }));
        this.checkUsername();
      } else if (telephoneNumber === '') {
        this.checkUsername();
      } else {
        this.setState({
          telephoneNumberError: 'pages.registration.mobileNumberInvalid',
        });
        this.showError('pages.registration.mobileNumberInvalid');
      }
    }
  };

  increaseCurrentStepHandler = () => {
    this.scrollToTop();
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  onVerify = () => {
    const {
      payLoad,
      answerOne,
      answerTwo,
      answerThree,
      questionVals,
    } = this.state;
    this.setState({
      errorText: '',
    });
    const hasAnswerOne = this.isFormFieldValid(answerOne, 'answerOneError');
    const hasAnswerTwo = this.isFormFieldValid(answerTwo, 'answerTwoError');
    const hasAnswerThree = this.isFormFieldValid(
      answerThree,
      'answerThreeError'
    );
    if (
      hasAnswerOne ||
      hasAnswerTwo ||
      hasAnswerThree ||
      !questionVals[0] ||
      !questionVals[1] ||
      !questionVals[2]
    ) {
      this.showError('errors.required');
    } else if (
      answerOne === answerTwo ||
      answerOne === answerThree ||
      answerTwo === answerThree
    ) {
      this.showError('errors.duplicateEntries');
    } else {
      payLoad.callbacks[0].input[0].value = questionVals[0].label;
      payLoad.callbacks[0].input[1].value = answerOne;
      payLoad.callbacks[1].input[0].value = questionVals[1].label;
      payLoad.callbacks[1].input[1].value = answerTwo;
      payLoad.callbacks[2].input[0].value = questionVals[2].label;
      payLoad.callbacks[2].input[1].value = answerThree;
      http(
        {
          url: 'Registration',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        this.genericResponse(res);
        // if (
        //   res.status === 200 &&
        //   res.data &&
        //   res.data.type === 'EmailValidation' &&
        //   res.data.tag === 'VALIDATE_CODE'
        // ) {
        //   const token = getNestedObject(res, ['data', 'token']);
        //   if (token) {
        //     this.setState({
        //       token: token,
        //     });
        //   }
        //   this.setState({
        //     screen: 2,
        //     verificationOption:
        //       res.data.requirements.properties.verificationCode.description,
        //   });
        // } else if (
        //   res.status === 200 &&
        //   res.data &&
        //   res.data.type === 'allInOneRegistration' &&
        //   res.data.tag === 'initial'
        // ) {
        //   const token = getNestedObject(res, ['data', 'token']);
        //   if (token) {
        //     this.setState({
        //       token: token,
        //     });
        //   }
        //   const recaptchaSiteKey = getNestedObject(res, [
        //     'data',
        //     'requirements',
        //     'properties',
        //     'response',
        //     'recaptchaSiteKey',
        //   ]);
        //   if (recaptchaSiteKey) {
        //     this.setState({
        //       showGoogleCaptcha: true,
        //       googleCaptchaToken: recaptchaSiteKey,
        //       screen: 4,
        //     });
        //     this.increaseCurrentStepHandler();
        //   }
        // }
        //this.increaseCurrentStepHandler();
      });
    }
  };

  captchaSubmit = () => {
    const { googleCaptchaResponse, payLoad, googleCaptchaToken } = this.state;
    payLoad.callbacks[0].input[0].value = googleCaptchaResponse;
    payLoad.callbacks[0].output[0].value = googleCaptchaToken;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'Registration',
        data: payLoad,
        method: 'POST',
      },
      {
        'Content-Type': 'application/json',
        'accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      ReactGA.event({
        category: 'Signup - AegisCloud',
        action: 'Signup Triggered: Captcha Submitted',
      });
      this.genericResponse(res);
    });
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'MAX_RESEND_EXCEEDED'
    ) {
      alertText = 'errors.maxVerifyAttempt';
    }
    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
      this.scrollToTop();
    }
  };

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    const { payLoad, verificationCode } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      payLoad.callbacks[0].input[0].value = verificationCode;
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: 'Registration',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        ReactGA.event({
          category: 'Signup - AegisCloud',
          action: 'Signup Triggered: Signed up Successfully',
        });
        this.genericResponse(res);
      });
    }
  };

  disableCopyPaste = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    const { token } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'selfservice/registration?_action=submitRequirements',
        data: {
          input: {
            resend: 'true',
          },
          token: token,
        },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (res.status === 200 && res.data) {
        ReactGA.event({
          category: 'Signup - AegisCloud',
          action: 'Signup Triggered: OTP Resent',
        });
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            token: token,
          });
        }
      } else if (
        res.status === 400 &&
        res.data &&
        res.data.code === 400 &&
        res.data.message === 'MAX_RESEND_EXCEEDED'
      ) {
        this.setState({
          screen: 0,
          currentStep: 0,
          password: '',
          confirmPassword: '',
          googleCaptchaResponse: '',
          captchaReset: false,
          givenName: '',
          answerOne: '',
          answerTwo: '',
          answerThree: '',
          sn: '',
          userName: '',
          mail: '',
          confirmEmail: '',
          confirmPasswordError: '',
          passwordError: '',
          formSubmitted: false,
          errorText: '',
          errorArray: [],
          token: '',
          telephoneNumber: '',
          telephoneNumberUSFormatted: '',
          verificationCode: '',
          verificationOption: '',
          verificationCodeError: '',
          questionVals: [null, null, null],
        });
        this.onErrorHandler('error', res.data.message);
        this.getToken();
      }
    });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { mail, confirmEmail, password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'telephoneNumberError':
        if (!validator.isMobilePhone(value)) {
          errorMsg = 'errors.telephoneNumberError';
        }
        break;
      case 'givenNameError':
        if (value.length <= 0) {
          errorMsg = 'errors.firstNameError';
        }
        break;
      case 'answerOneError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerOneError';
        }
        break;
      case 'answerTwoError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerTwoError';
        }
        break;
      case 'answerThreeError':
        if (value.length <= 0) {
          errorMsg = 'errors.answerThreeError';
        }
        break;
      case 'snError':
        if (value.length <= 0) {
          errorMsg = 'errors.lastNameError';
        }
        break;
      case 'userNameError':
        if (value.length < 5 || value.length > 30) {
          errorMsg = 'errors.userNameLength';
        } else if (
          !value.match(/([a-zA-Z])*/) &&
          value.length >= 5 &&
          value.length <= 30
        ) {
          errorMsg = 'errors.userNameAtleastAlphabet';
        }
        break;
      case 'emailError':
        if (
          value.length <= 0 ||
          !value.match(
            /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
          )
        ) {
          errorMsg = 'errors.emailError';
        }
        break;
      case 'confirmEmailError':
        if (value.length <= 0) {
          errorMsg = 'errors.confirmEmailError';
        } else if (mail !== confirmEmail) {
          errorMsg = 'errors.mailsNotMatchError';
        }
        break;
      case 'passwordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (password !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  decreaseCurrentStepHandler = () => {
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };

  sendToLogin = (e) => {
    e.preventDefault();
    return this.props.history.push('/');
  };

  onCaptchaChange = (value) => {
    if (value) {
      this.setState(
        {
          googleCaptchaResponse: value,
        },
        () => {
          this.captchaSubmit();
        }
      );
    } else {
      this.setState({
        googleCaptchaResponse: '',
      });
    }
  };

  onFirstAndLastNameChange = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z '`,.-]*$/)) {
      this.setState({
        [key]: value,
      });
    }
  };

  onUserNameChange = (e, key) => {
    const value = e.target.value.trim();
    if (!value.match(/[\\+/""&@'*%;, ]/g)) {
      this.setState({
        [key]: value,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    this.scrollToTop();
  };

  handleQuestionValChange = (option, index) => {
    const newQuestionVals = this.state.questionVals;
    newQuestionVals[index] = option;
    this.setState(() => {
      return {
        questionVals: newQuestionVals,
      };
    });
  };

  getAvailableOptions = () => {
    const availableOptionsLeft = this.state.filterOptions;
    return availableOptionsLeft.filter((questionOption) => {
      return this.state.questionVals.indexOf(questionOption) === -1;
    });
  };

  onMobileNumberChange = (e) => {
    const currentValue = e.target.value.trim();
    this.setState((prevState) => ({
      ...prevState,
      telephoneNumber: formatPhoneNumber(currentValue, prevState.phoneNumber),
    }));
  };

  handleKeyPress = (e) => {
    preventNonDigitCharacters(e);
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      screen: 0,
      currentStep: 0,
      password: '',
      confirmPassword: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      givenName: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
      sn: '',
      userName: '',
      mail: '',
      confirmEmail: '',
      confirmPasswordError: '',
      passwordError: '',
      formSubmitted: false,
      errorText: '',
      errorArray: [],
      token: '',
      telephoneNumber: '',
      telephoneNumberUSFormatted: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      questionVals: [null, null, null],
    });
    this.getToken();
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  render() {
    const {
      currentStep,
      maxStep,
      stepNames,
      screen,
      givenName,
      givenNameError,
      sn,
      snError,
      userName,
      userNameError,
      mail,
      emailError,
      confirmEmail,
      confirmEmailError,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
      formSubmitted,
      errorText,
      answerOne,
      answerTwo,
      answerThree,
      answerOneError,
      answerTwoError,
      answerThreeError,
      telephoneNumber,
      telephoneNumberError,
      numberFocus,
      verificationOption,
      verificationCodeError,
      errorArray,
      verificationCode,
      alertText,
      alertStatus,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="registration registration-form">
          {(errorText || errorArray.length > 0) && (
            <WithError errors={errorText} errorArray={errorArray} />
          )}
          <div className="col">
            <h1>{t('pages.registration.title')}</h1>
          </div>
          <FormNavigationBar
            currentStep={currentStep}
            numFormSteps={maxStep + 1}
            stepNames={stepNames}
            t={t}
          />
          {currentStep === 0 && screen === 0 && (
            <div className="col-md-12 registration__container">
              <p>
                <span className="pr-2 red bold">*</span>
                {t('pages.registration.requiredFields')}
              </p>
              <Form>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="firstName">
                      <Form.Label>
                        {t('pages.registration.firstName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.onFirstAndLastNameChange(e, 'givenName');
                        }}
                        value={givenName}
                        maxLength="40"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'givenNameError');
                        }}
                        className={givenNameError ? 'error-input' : undefined}
                        aria-required="true"
                        aria-invalid={!!givenNameError}
                        aria-describedby="firstname-error"
                      />
                      <p className="error-text" id="firstname-error">
                        {givenNameError && t(givenNameError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pl-2" lg={{ span: 6 }}>
                    <Form.Group controlId="lastName">
                      <Form.Label>
                        {t('pages.registration.lastName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.onFirstAndLastNameChange(e, 'sn');
                        }}
                        value={sn}
                        maxLength="80"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'snError');
                        }}
                        className={snError ? 'error-input' : undefined}
                        aria-required
                        aria-invalid={!!snError}
                        aria-describedby="lastname-error"
                      />
                      <p className="error-text" id="lastname-error">
                        {snError && t(snError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="userName">
                      <Form.Label>
                        {t('pages.registration.userName')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          this.onUserNameChange(e, 'userName');
                        }}
                        value={userName}
                        maxLength="30"
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'userNameError');
                        }}
                        className={userNameError ? 'error-input' : undefined}
                        aria-required="true"
                        autoComplete="off"
                        aria-invalid={!!userNameError}
                        aria-describedby="username-error"
                      />
                      <p className="error-text" id="username-error">
                        {userNameError && t(userNameError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="telephoneNumber">
                      <Form.Label>
                        {t('pages.registration.telephoneNumber')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        ref={this.telephoneNumberInputRef}
                        onKeyDown={this.handleKeyPress}
                        placeholder={!numberFocus ? '(###) ###-####' : ''}
                        onFocus={() => {
                          this.setState({
                            numberFocus: true,
                          });
                        }}
                        onBlur={(e) => {
                          this.setState({
                            numberFocus: false,
                          });

                          this.isFormFieldValid(e, 'telephoneNumberError');
                        }}
                        onChange={this.onMobileNumberChange}
                        value={telephoneNumber}
                        aria-required="true"
                        className={
                          telephoneNumberError ? 'error-input' : undefined
                        }
                        aria-invalid={!!telephoneNumberError}
                        aria-describedby="mobile-number-error"
                      />
                      <p className="error-text" id="mobile-number-error">
                        {telephoneNumberError && t(telephoneNumberError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        {t('pages.registration.email')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          this.setState({
                            mail: e.target.value.trim().toLowerCase(),
                          });
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'emailError');
                        }}
                        value={mail}
                        aria-required="true"
                        className={emailError ? 'error-input' : undefined}
                        aria-invalid={!!emailError}
                        aria-describedby="email-error"
                      />
                      <p>{t('pages.registration.emailContext')}</p>
                      <p className="error-text" id="email-error">
                        {emailError && t(emailError)}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="confirmEmail">
                      <Form.Label>
                        {t('pages.registration.confirmEmail')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          const value = e.target.value.trim().toLowerCase();
                          this.setState(
                            {
                              confirmEmail: value,
                            },
                            () => {
                              this.isFormFieldValid(value, 'confirmEmailError');
                            }
                          );
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'confirmEmailError');
                        }}
                        value={confirmEmail}
                        className={
                          confirmEmailError ? 'error-input' : undefined
                        }
                        aria-required="true"
                        aria-invalid={!!confirmEmailError}
                        aria-describedby="confirm-email-error"
                      />
                      <p className="error-text" id="confirm-email-error">
                        {confirmEmailError && t(confirmEmailError)}
                      </p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="passWord">
                      <Form.Label>
                        {t('pages.registration.password')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <PasswordField
                        onChange={(value) => {
                          this.setState({
                            password: value,
                          });
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'passwordError');
                        }}
                        password={password}
                        className={passwordError ? 'error-input' : undefined}
                        aria-required="true"
                        ariaInvalid={!!passwordError}
                        ariaDescribedby="password-error"
                        myRef={this.passwordInputRef}
                        onCopy={(e) => {
                          this.disableCopyPaste(e);
                        }}
                        onPaste={(e) => {
                          this.disableCopyPaste(e);
                        }}
                      />
                    </Form.Group>
                    <p className="error-text" id="password-error">
                      {passwordError && t(passwordError)}
                    </p>
                  </Col>
                  <Col className="pr-2" lg={{ span: 6 }}>
                    <Form.Group controlId="confirmPassWord">
                      <Form.Label>
                        {t('pages.registration.confirmPassword')}
                        <span className="red"> *</span>
                      </Form.Label>
                      <PasswordField
                        onChange={(value) => {
                          this.setState(
                            {
                              confirmPassword: value,
                            },
                            () => {
                              this.isFormFieldValid(
                                value,
                                'confirmPasswordError'
                              );
                            }
                          );
                        }}
                        onBlur={(e) => {
                          this.isFormFieldValid(e, 'confirmPasswordError');
                        }}
                        password={confirmPassword}
                        className={
                          confirmPasswordError ? 'error-input' : undefined
                        }
                        aria-required="true"
                        ariaInvalid={!!confirmPasswordError}
                        ariaDescribedby="confirm-password-error"
                        myRef={this.confirmPasswordInputRef}
                        onCopy={(e) => {
                          this.disableCopyPaste(e);
                        }}
                        onPaste={(e) => {
                          this.disableCopyPaste(e);
                        }}
                      />
                    </Form.Group>
                    <p className="error-text" id="confirm-password-error">
                      {confirmPasswordError && t(confirmPasswordError)}
                    </p>
                  </Col>
                </Form.Row>
                <div className="mt-3">
                  <PasswordRequirements
                    password={password}
                    formSubmitted={formSubmitted}
                  />
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                      onClick={(e) => this.sendToLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      type="submit"
                      onClick={(e) => this.onSubmit(e)}
                    >
                      {t('pages.registration.registration')}
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="separator-light mt-4 mb-4"></div>
            </div>
          )}
          {currentStep === 1 && screen === 1 && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.registration.signUpSecurityQuestion')}</span>
              </h3>
              <p>
                <span className="pr-2 red bold">*</span>
                {t('pages.registration.requiredFields')}
              </p>
              <div>
                <Form>
                  <label>
                    {t('pages.registration.question1')}
                    <span className="red"> *</span>
                  </label>
                  <Select
                    name="filters"
                    placeholder="Question 1"
                    value={this.state.questionVals[0]}
                    options={this.getAvailableOptions()}
                    onChange={(e) => {
                      this.handleQuestionValChange(e, 0);
                    }}
                    className={`select-box`}
                  />
                  <Form.Group controlId="answerOne">
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'answerOne');
                      }}
                      value={answerOne}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'answerOneError');
                      }}
                      className={`answer-input ${
                        answerOneError ? 'error-input' : undefined
                      }`}
                      aria-required="true"
                      aria-invalid={!!answerOneError}
                      aria-describedby="firstname-error"
                    />
                  </Form.Group>
                  <label>
                    {t('pages.registration.question2')}
                    <span className="red"> *</span>
                  </label>
                  <Select
                    name="filters"
                    placeholder="Question 2"
                    value={this.state.questionVals[1]}
                    options={this.getAvailableOptions()}
                    onChange={(e) => {
                      this.handleQuestionValChange(e, 1);
                    }}
                    className={`select-box`}
                  />
                  <Form.Group controlId="answerTwo">
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'answerTwo');
                      }}
                      value={answerTwo}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'answerTwoError');
                      }}
                      className={`answer-input ${
                        answerTwoError ? 'error-input' : undefined
                      }`}
                      aria-required="true"
                      aria-invalid={!!answerTwoError}
                      aria-describedby="firstname-error"
                    />
                  </Form.Group>
                  <label>
                    {t('pages.registration.question3')}
                    <span className="red"> *</span>
                  </label>
                  <Select
                    name="filters"
                    placeholder="Question 3"
                    value={this.state.questionVals[2]}
                    options={this.getAvailableOptions()}
                    onChange={(e) => {
                      this.handleQuestionValChange(e, 2);
                    }}
                    className={`select-box`}
                  />
                  <Form.Group controlId="answerThree">
                    <Form.Control
                      onChange={(e) => {
                        this.onFirstAndLastNameChange(e, 'answerThree');
                      }}
                      value={answerThree}
                      maxLength="40"
                      onBlur={(e) => {
                        this.isFormFieldValid(e, 'answerThreeError');
                      }}
                      className={`answer-input ${
                        answerThreeError ? 'error-input' : undefined
                      }`}
                      aria-required="true"
                      aria-invalid={!!answerThreeError}
                      aria-describedby="firstname-error"
                    />
                  </Form.Group>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                        variant="primary"
                        block
                      >
                        {t('pages.registration.back')}
                      </Button>
                      <Button
                        className="col btn btn-secondary  py-2 my-3"
                        onClick={(e) => this.onVerify(e)}
                      >
                        {t('pages.registration.verify')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
          {currentStep === 2 && showGoogleCaptcha && screen === 4 && (
            <>
              <div className="captcha">
                <div className="col">
                  <h2 className="mb-4">{t('pages.forgotPassword.header3')}</h2>
                </div>
                <div className="col-md-6 mt-3 mb-9">
                  <div className="google-captcha">
                    <div className="google-captcha__box">
                      {googleCaptchaToken && (
                        <GoogleCaptcha
                          sitekey={googleCaptchaToken}
                          onCaptchaChange={this.onCaptchaChange}
                          captchaReset={captchaReset}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex col-md-3">
                  <Button
                    className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                    type="submit"
                    onClick={(e) => this.decreaseCurrentStepHandler(e)}
                  >
                    {t('pages.forgotUserId.backBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {currentStep === 3 && screen === 2 && verificationOption && (
            <div className="col-md-12 registration__container">
              <div className="registration__container__resend-verification">
                <div className="registration__container__resend-verification__resend-text">
                  <h3>
                    {t('pages.registration.verificationCodeSentToYourEmail')}
                  </h3>
                </div>
              </div>

              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className="ml-3"
                    value={verificationCode}
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__resend-verification__resend-text">
                  <p
                    className="resend-link"
                    onClick={(e) => this.resendVerify(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
                <div className="registration__container__pleasenote">
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>

                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4  py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={(e) => this.onVerificationCodeSubmit(e)}
                      type="submit"
                    >
                      {t('pages.registration.verify')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {currentStep === 3 && screen === 3 && (
            <div className="col-md-12 registration__container mb-5">
              <>
                <SuccessConfirmation
                  successMessage={t('pages.registration.registrationSuccess')}
                  emailVerify={t('pages.registration.welcomeRegistration')}
                />
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-3 col-md-12">
                    <Button
                      className="col btn btn-secondary py-2 my-3"
                      onClick={this.sendToLogin}
                      type="submit"
                    >
                      {t('pages.forgotPassword.loginBtn')}
                    </Button>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    );
  }
}
Registration.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(Registration);
