import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';
import 'app/modules/Dashboard/IDs.scss';
import { withTranslation } from 'react-i18next';
import DriverLicense from 'assets/img/driver-license1.jpg';
import Badge from 'assets/img/officebadge.jpg';
import DeleteIcon from 'assets/img/delete.png';
import DeleteDocument from 'app/modules/Dashboard/DeleteDocument';
import Cookies from 'js-cookie';

class IDs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordPopup: false,
      driverLicense: false,
      officeBadge: false,
      showEditPopup: false,
    };
  }
  componentDidMount() {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });

    // let token = localStorage.getItem('dataStoreToken');
    // let id = localStorage.getItem('user_id');
    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');
  }

  onDeleteID = () => {
    this.setState({
      driverLicense: !this.state.driverLicense,
    });
  };
  onDeleteBagde = () => {
    this.setState({
      officeBadge: !this.state.officeBadge,
    });
  };

  showEditPopup = () => {
    this.setState({
      showEditPopup: true,
    });
  };

  render() {
    const { t } = this.props;
    const { driverLicense, officeBadge, showEditPopup } = this.state;
    return (
      <div>
        {showEditPopup && (
          <DeleteDocument
            onClose={() => {
              this.setState({ showEditPopup: false });
            }}
          />
        )}
        <Breadcrumb>
          <Breadcrumb.Item href="#">
            {t('pages.BreadCrumb.home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('pages.BreadCrumb.identities')}</Breadcrumb.Item>
          <Breadcrumb.Item active>...</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row mx-5 mb-5">
          <h1>{t('pages.BreadCrumb.identities')}</h1>
        </div>
        <div className="row text-center id-container mx-5">
          <div className="col-sm-12 col-md-6 col-xs-12 id-container__content">
            <img
              src={DriverLicense}
              onClick={() => {
                this.onDeleteID();
              }}
              alt=""
            />
            <div
              className="mb-3"
              onClick={() => {
                this.onDeleteID();
              }}
            >
              {t('pages.IDs.driverLicense')}
            </div>
            {driverLicense && (
              <img
                className="delete-icon"
                src={DeleteIcon}
                alt=""
                onClick={() => {
                  this.showEditPopup();
                }}
              />
            )}
          </div>
          <div className="col-sm-12 col-md-6 col-xs-12">
            <img
              src={Badge}
              onClick={() => {
                this.onDeleteBagde();
              }}
              alt=""
            />
            <div
              className="mb-3"
              onClick={() => {
                this.onDeleteBagde();
              }}
            >
              {t('pages.IDs.officeBadge')}
            </div>
            {officeBadge && (
              <img
                className="delete-icon"
                src={DeleteIcon}
                onClick={() => {
                  this.showEditPopup();
                }}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="my-5">
          <hr />
          <div className="row mx-5">
            <p>{t('pages.idDocuments.title')}</p>
          </div>
          <div className="row mx-5">
            <div className="col-sm-3 p-0">
              <button className="btn btn-secondary btn-small py-2 my-3">
                {t('pages.idDocuments.add')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
IDs.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(IDs);
