import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loginAnimation from 'assets/json/login_idProofing.json';
import licenseAnimation from 'assets/json/licensePlate.json';
import securityAnimation from 'assets/json/security.json';
import verifiedAnimation from 'assets/json/verified.json';
import dotAnimation from 'assets/json/threeDot.json';
import { Button } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import PropTypes from 'prop-types';
import Alert from 'app/modules/Shared/Alert/Alert';
import http from 'api/http-request';
import Cookies from 'js-cookie';

class IdProofingCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertStatus: '',
      alertText: '',
      errorText: '',
      errorArray: [],
      finish: {
        loop: true,
        autoplay: true,
        animationData: verifiedAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  getAPIHeaders = () => {
    //const dataStoreToken = Cookies.get('dataStoreToken');
    const iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');
    //let bearer = 'Bearer ';
    return {
      //'X-OpenIDM-NoSession': false,
      //'X-OpenIDM-OAuth-Login': true,
      //'X-Requested-With': 'XMLHttpRequest',
      //Authorization: `${bearer}${dataStoreToken}`,
      iPlanetDirectoryPro: `${iPlanetDirectoryPro}`,
      'Content-Type': 'application/json',
    };
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    const user_id = Cookies.get('user_id');
    let userId = user_id;

    const payload = [
      {
        operation: 'add',
        field: '/idProofing',
        value: '2',
      },
    ];
    http(
      {
        url: `updateSelfProfile`,
        method: 'PATCH',
        data: payload,
      },
      { ...this.getAPIHeaders() }
    ).then((res) => {
      if (res && res.status === 200 && res.data) {
        this.setState(
          {
            alertStatus: 'succcess',
            alertText: 'pages.editName.successMsg',
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  alertText: '',
                },
                () => {
                  window.location.href = `/profile/Dashboard`;
                }
              );
            }, 1000);
          }
        );
      } else if (res.status === 401) {
        this.showError('pages.idproofing.sessionExpiry');

        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      } else {
        this.setState(
          {
            alertStatus: 'error',
            alertText: 'pages.Admin.errorMsg',
          },
          () => {
            setTimeout(() => {
              this.setState({
                alertText: '',
              });
            }, 3000);
          }
        );
      }
    });
  };

  render() {
    const { t } = this.props;
    const { finish, errorText, errorArray } = this.state;

    return (
      <>
        {(errorText || errorArray.length > 0) && (
          <div className="error-div">
            <WithError errors={errorText} errorArray={errorArray} />
          </div>
        )}
        <div className="id-proofing-login container forgot-username forgot-password my-5">
          <div className="col">
            <h2>{t('pages.idproofing.title')}</h2>
          </div>
          <div>
            <div className="container">
              {/* <h2>{t('pages.idproofing.finish')}</h2> */}
              <h4 className="pt-5" style={{ textAlign: 'center' }}>
                {t('pages.idproofing.finishDesc')}
              </h4>
              <div className="image">
                <Lottie options={finish} height={300} width={300} />
              </div>
              <div className="row pt-5 d-flex justify-content-center">
                <div className="d-sm-flex col-lg-6 col-md-12">
                  <div class="buttonWrapper-idproofing">
                    <Button
                      className="startButton-idproofing"
                      type="submit"
                      onClick={(e) => this.onFormSubmit(e)}
                    >
                      {t('pages.idproofing.submitBtn')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6
            className="pt-4"
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: '700',
            }}
          >
            Note : Do not close this page yet. Click on the submit button to
            submit and complete the IdProofing process.
          </h6>
        </div>
      </>
    );
  }
}

IdProofingCompleted.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(IdProofingCompleted);
