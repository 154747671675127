import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard Cognito/ChangePassword.scss';
import { withTranslation } from 'react-i18next';
import Radio from 'app/modules/Shared/Radio/Radio';
import http from 'api cognito/http-request';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import Switch from 'react-switch';
import 'app/modules/Dashboard Cognito/CommunicationPreferences.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class CommunicationPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordPopup: false,
      industryEmail: false,
      industrySMS: false,
      weeklyEmail: false,
      weeklySMS: false,
      announcementEmail: false,
      announcementSMS: false,
      profileUpdateEmail: false,
      profileUpdateSMS: false,
      securityNotificationEmail: false,
      securityNotificationSMS: false,
      walletUpdateEmail: false,
      walletUpdateSMS: false,
      preferredLanguage: 'en',
      preferredContactMethod: 'email',
      privacyToggle: false,
      consentToggle: false,
    };
  }

  getUserDetails = (id, token) => {
    this.setState({
      userId: id,
      token: token,
    });
    let bearer = 'Bearer ';
    http(
      {
        url: `managed/user/${id}?&_fields=userName,givenName,sn,telephoneNumber,mail,preferredLanguage`,
        method: 'GET',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.data) {
        this.setState({
          preferredLanguage: res.data.preferredLanguage,
        });
        if (this.state.preferredLanguage === 'en') {
          i18n.changeLanguage('en_US');
        } else {
          i18n.changeLanguage(this.state.preferredLanguage);
        }
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let id = Cookies.get('user_id');
    let token = Cookies.get('dataStoreToken');
    if (token || id) {
      this.getUserDetails(id, token);
      this.getCommPreferenceDetails();
    }
  }

  onCheckboxChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  onOptionsChange = (value) => {
    this.setState({
      preferredLanguage: value,
    });
  };

  getAPIHeaders = () => {
    const dataStoreToken = Cookies.get('dataStoreToken');
    let bearer = 'Bearer ';
    return {
      'X-OpenIDM-NoSession': false,
      'X-OpenIDM-OAuth-Login': true,
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `${bearer}${dataStoreToken}`,
    };
  };

  onSave = (e) => {
    e.preventDefault();
    //this.savePreferredLanguage();
    //this.saveContactPreference();
    //this.savePrivacyConsent();
  };

  savePreferredLanguage = () => {
    const userId = Cookies.get('user_id');
    const { preferredLanguage } = this.state;
    const payload = [
      {
        operation: 'add',
        field: 'preferredLanguage',
        value: preferredLanguage,
      },
    ];
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: payload,
      },
      { ...this.getAPIHeaders() }
    ).then(() => {
      let token = Cookies.get('dataStoreToken');
      let id = Cookies.get('user_id');
      if (token || id) {
        this.getUserDetails(id, token);
      }
    });
  };

  handleCommPreferenceChange = (preferenceType) => {
    switch (preferenceType) {
      case 'email':
        this.setState({
          preferredContactMethod: 'email',
        });
        break;
      case 'sms':
        this.setState({
          preferredContactMethod: 'sms',
        });
        break;
      case 'privacyToggle':
        this.setState((prev) => ({
          privacyToggle: !prev.privacyToggle,
        }));
        break;
      case 'consentToggle':
        this.setState((prev) => ({
          consentToggle: !prev.consentToggle,
        }));
        break;
      default:
        this.setState({
          preferredContactMethod: 'email',
          privacyToggle: false,
          consentToggle: false,
        });
        break;
    }
  };

  saveContactPreference = () => {
    const { preferredContactMethod } = this.state;
    const userId = Cookies.get('user_id');
    const payloadEmail = [
      {
        operation: 'replace',
        field: '/preferences/Email',
        value:
          preferredContactMethod.toLocaleLowerCase() === 'email' ? true : false,
      },
    ];
    const payloadSMS = [
      {
        operation: 'replace',
        field: '/preferences/SMS',
        value:
          preferredContactMethod.toLocaleLowerCase() === 'sms' ? true : false,
      },
    ];
    // http call for preferred Email save
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: payloadEmail,
      },
      { ...this.getAPIHeaders() }
    );

    // http call for preferred SMS save
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: payloadSMS,
      },
      { ...this.getAPIHeaders() }
    ).then((res) => {
      this.setState({
        preferredContactMethod: res.data.preferences.SMS ? 'sms' : 'email',
      });
    });
  };

  savePrivacyConsent = () => {
    const { privacyToggle, consentToggle } = this.state;
    const userId = Cookies.get('user_id');
    const payloadPrivacy = [
      {
        operation: 'add',
        field: '/privacy',
        value: privacyToggle ? true : false,
      },
    ];
    const payloadConsent = [
      {
        operation: 'add',
        field: '/consent',
        value: consentToggle ? true : false,
      },
    ];

    // http call for preferred Email save
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: payloadPrivacy,
      },
      { ...this.getAPIHeaders() }
    );

    // http call for preferred SMS save
    http(
      {
        url: `managed/user/${userId}`,
        method: 'PATCH',
        data: payloadConsent,
      },
      { ...this.getAPIHeaders() }
    ).then((res) => {
      if (res.data) {
        this.setState({
          privacyToggle: res.data.privacy,
          consentToggle: res.data.consent,
        });
      }
    });
  };

  getCommPreferenceDetails = () => {
    const userId = Cookies.get('user_id');
    http(
      {
        url: `managed/user/${userId}`,
        method: 'GET',
      },
      { ...this.getAPIHeaders() }
    ).then((res) => {
      if (res.data) {
        const preferences = res.data.preferences;
        this.setState({
          preferredContactMethod: preferences.SMS ? 'sms' : 'email',
          privacyToggle: res.data.privacy,
          consentToggle: res.data.consent,
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    const {
      preferredLanguage,
      preferredContactMethod,
      consentToggle,
      privacyToggle,
    } = this.state;
    return (
      <div>
        <div className="change-password commmunication-preferences">
          <h1>{t('pages.communicationPreferences.title')}</h1>
          <hr className="separator-solid" />
          <div>
            <h2>{t('pages.communicationPreferences.preferredLanguage')}</h2>

            <Radio
              key={'en'}
              name={'en'}
              label={t('pages.communicationPreferences.english')}
              value={'en'}
              isChecked={preferredLanguage === 'en' ? true : false}
              handleChange={this.onOptionsChange}
            />

            <Radio
              key={'es'}
              name={'es'}
              label={t('pages.communicationPreferences.spanish')}
              value={'es'}
              isChecked={preferredLanguage === 'es' ? true : false}
              handleChange={this.onOptionsChange}
            />
          </div>
          <div className="mt-4">
            <h3>{t('pages.communicationPreferences.preferredContactMode')}</h3>
            <div>
              <div className="row align-items-center m-0">
                <Switch
                  checked={
                    preferredContactMethod.toLocaleLowerCase() === 'email'
                      ? true
                      : false
                  }
                  uncheckedIcon={false}
                  onChange={() => {
                    this.handleCommPreferenceChange('email');
                  }}
                  id="contactPreferenceSwitch1"
                  aria-label="Contact Preference Email"
                />
                <span className="pl-2">Email</span>
              </div>
              <div className="row align-items-center m-0 mt-3">
                <Switch
                  checked={
                    preferredContactMethod.toLocaleLowerCase() === 'sms'
                      ? true
                      : false
                  }
                  uncheckedIcon={false}
                  onChange={() => {
                    this.handleCommPreferenceChange('sms');
                  }}
                  id="contactPreferenceSwitch2"
                  aria-label="Contact Preference SMS"
                />
                <span className="pl-2">SMS</span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h3>{t('pages.communicationPreferences.privacyAndConsent')}</h3>
            <div>
              <div className="row align-items-center m-0">
                <Switch
                  checked={privacyToggle ? true : false}
                  uncheckedIcon={false}
                  onChange={() => {
                    this.handleCommPreferenceChange('privacyToggle');
                  }}
                  id="privacyToggle"
                  aria-label="Privacy Toggle"
                />
                <span className="pl-2">
                  {t('pages.communicationPreferences.privacyToggle')}
                </span>
              </div>
              <div className="row align-items-center m-0 mt-3">
                <Switch
                  checked={consentToggle ? true : false}
                  uncheckedIcon={false}
                  onChange={() => {
                    this.handleCommPreferenceChange('consentToggle');
                  }}
                  id="consentToggle"
                  aria-label="Consent Toggle"
                />
                <span className="pl-2">
                  {t('pages.communicationPreferences.consentToggle')}
                </span>
              </div>
            </div>
          </div>

          <div className="row pt-5">
            <div className="d-sm-flex col-lg-4 col-md-12">
              <button
                className="col btn btn-secondary mr-sm-4 py-2 my-3"
                onClick={(e) => this.onSave(e)}
              >
                {t('pages.communicationPreferences.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CommunicationPreferences.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(CommunicationPreferences);
