import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/Dashboard/AuthorizedApps.scss';
import 'app/modules/Dashboard/PeopleSharing.scss';
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../Shared/Checkbox/Checkbox';
import { Accordion, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PeopleSharing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sharedUserConnections: [
        {
          userName: 'User 1',
          sharingPermissions: {
            profileInfo: true,
            emailAddress: true,
            phoneNumber: true,
            contactList: true,
          },
        },
        {
          userName: 'User 2',
          sharingPermissions: {
            profileInfo: true,
            emailAddress: false,
            phoneNumber: true,
            contactList: true,
          },
        },
        {
          userName: 'User 3',
          sharingPermissions: {
            profileInfo: true,
            emailAddress: true,
            phoneNumber: true,
            contactList: true,
          },
        },
        {
          userName: 'User 4',
          sharingPermissions: {
            profileInfo: false,
            emailAddress: true,
            phoneNumber: true,
            contactList: false,
          },
        },
        {
          userName: 'User 5',
          sharingPermissions: {
            profileInfo: true,
            emailAddress: true,
            phoneNumber: true,
            contactList: true,
          },
        },
      ],
      connectionChevrons: [false],
    };
  }

  onCheckboxChange = (key, checked, checkboxType, index) => {
    let newState;
    if (checkboxType === 'all') {
      newState = this.state.sharedUserConnections;
    }
    if (checkboxType === 'custom') {
      newState = this.state.sharedUserConnections;
    }
    if (checkboxType === 'profile') {
      newState = this.state.sharedUserConnections.map((obj, i) => {
        if (index === i) {
          if (obj.sharingPermissions.profileInfo !== checked) {
            obj.sharingPermissions.profileInfo = checked;
          }
        }
        return obj;
      });
    }
    if (checkboxType === 'phone') {
      newState = this.state.sharedUserConnections.map((obj, i) => {
        if (index === i) {
          if (obj.sharingPermissions.phoneNumber !== checked) {
            obj.sharingPermissions.phoneNumber = checked;
          }
        }
        return obj;
      });
    }
    if (checkboxType === 'email') {
      newState = this.state.sharedUserConnections.map((obj, i) => {
        if (index === i) {
          if (obj.sharingPermissions.emailAddress !== checked) {
            obj.sharingPermissions.emailAddress = checked;
          }
        }
        return obj;
      });
    }
    if (checkboxType === 'contact') {
      newState = this.state.sharedUserConnections.map((obj, i) => {
        if (index === i) {
          if (obj.sharingPermissions.contactList !== checked) {
            obj.sharingPermissions.contactList = checked;
          }
        }
        return obj;
      });
    }
    this.setState(() => ({
      sharedUserConnections: newState,
    }));
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setConnectionChevrons();
  };

  setConnectionChevrons = () => {
    this.setState(() => ({
      connectionChevrons: this.state.sharedUserConnections.map(() => false),
    }));
  };

  onChevronClick = (clickedChevIndex) => {
    const { connectionChevrons } = this.state;
    let retVal = [];
    if (
      connectionChevrons.find(
        (chevron, x) => x != clickedChevIndex && chevron === true
      )
    ) {
      retVal = this.resetChevrons();
    } else {
      retVal = connectionChevrons.map((chevronBool, i) => {
        if (clickedChevIndex === i) {
          return (chevronBool = !chevronBool);
        } else {
          return chevronBool;
        }
      });
    }
    this.setState(() => ({
      connectionChevrons: retVal,
    }));
  };

  resetChevrons = () => {
    return this.state.connectionChevrons.map(() => false);
  };

  render = () => {
    const { t } = this.props;
    const { sharedUserConnections } = this.state;

    return (
      <div className="authorized-apps">
        <div className="mb-4">
          <h1>{t('pages.peopleSharing.title')}</h1>
        </div>
        <div className="row">
          <h2 className="col-6">
            <u>{t('pages.peopleSharing.connections')}</u>
          </h2>
          <h2 className="col-6">
            <u>{t('pages.peopleSharing.sharing')}</u>
          </h2>
        </div>
        <Accordion>
          <div className="row mt-3">
            <div className="col">
              {sharedUserConnections.map((userConnection, index) => (
                <div
                  key={index}
                  className="userConnections-style align-items-center p-2"
                >
                  <div className="row m-0">
                    <div className="col-6 d-flex align-items-center">
                      <p className="d-inline pr-3 m-0">
                        {userConnection?.userName}
                      </p>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={index + 1}
                        aria-label="show user sharing options toggler"
                        tabIndex={0}
                        onClick={() => this.onChevronClick(index)}
                      >
                        <FontAwesomeIcon
                          icon={
                            this.state.connectionChevrons[index]
                              ? faChevronDown
                              : faChevronUp
                          }
                          role="img"
                          className="chevron-style"
                        />
                      </Accordion.Toggle>
                    </div>
                    <div className="col-6 d-flex align-items-center">
                      <div className="pr-0 pr-md-5">
                        <Accordion.Toggle
                          as={Link}
                          variant="link"
                          eventKey={index + 1}
                          aria-label="show user sharing options toggler"
                          onClick={() => this.onChevronClick(index)}
                        >
                          <Checkbox
                            name={'All' + (index + 1)}
                            ariaLabel="All"
                            labelAlignment="left"
                            label="All"
                            handleChange={(checked) =>
                              this.onCheckboxChange(
                                'sharedUserConnections',
                                checked,
                                'all',
                                index
                              )
                            }
                            isChecked={
                              userConnection.sharingPermissions.contactList &&
                              userConnection.sharingPermissions.emailAddress &&
                              userConnection.sharingPermissions.phoneNumber &&
                              userConnection.sharingPermissions.profileInfo
                            }
                          />
                        </Accordion.Toggle>
                      </div>
                      <div>
                        <Accordion.Toggle
                          as={Link}
                          variant="link"
                          eventKey={index + 1}
                          aria-label="show user sharing options toggler"
                          onClick={() => this.onChevronClick(index)}
                        >
                          <Checkbox
                            name={'Custom' + (index + 1)}
                            ariaLabel="Custom"
                            labelAlignment="left"
                            label="Custom"
                            handleChange={(checked) =>
                              this.onCheckboxChange(
                                'sharedUserConnections',
                                checked,
                                'custom',
                                index
                              )
                            }
                            isChecked={
                              !(
                                userConnection.sharingPermissions.contactList &&
                                userConnection.sharingPermissions
                                  .emailAddress &&
                                userConnection.sharingPermissions.phoneNumber &&
                                userConnection.sharingPermissions.profileInfo
                              )
                            }
                          />
                        </Accordion.Toggle>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0">
                    <Accordion.Collapse eventKey={index + 1}>
                      <div className="ml-4  py-2">
                        <div>
                          <Checkbox
                            name={'ProfileInfo' + (index + 1)}
                            labelAlignment="right"
                            label="Profile Info"
                            handleChange={(checked) =>
                              this.onCheckboxChange(
                                'sharedUserConnections',
                                checked,
                                'profile',
                                index
                              )
                            }
                            isChecked={
                              sharedUserConnections[index].sharingPermissions
                                .profileInfo
                            }
                          />
                        </div>
                        <div>
                          <Checkbox
                            name={'EmailAddress' + (index + 1)}
                            labelAlignment="right"
                            label="Email Address"
                            handleChange={(checked) =>
                              this.onCheckboxChange(
                                'sharedUserConnections',
                                checked,
                                'email',
                                index
                              )
                            }
                            isChecked={
                              sharedUserConnections[index].sharingPermissions
                                .emailAddress
                            }
                          />
                        </div>
                        <div>
                          <Checkbox
                            name={'PhoneNumber' + (index + 1)}
                            labelAlignment="right"
                            label="Phone Number"
                            handleChange={(checked) =>
                              this.onCheckboxChange(
                                'sharedUserConnections',
                                checked,
                                'phone',
                                index
                              )
                            }
                            isChecked={
                              sharedUserConnections[index].sharingPermissions
                                .phoneNumber
                            }
                          />
                        </div>
                        <div>
                          <Checkbox
                            name={'ContactList' + (index + 1)}
                            labelAlignment="right"
                            label="Contact List"
                            handleChange={(checked) =>
                              this.onCheckboxChange(
                                'sharedUserConnections',
                                checked,
                                'contact',
                                index
                              )
                            }
                            isChecked={
                              sharedUserConnections[index].sharingPermissions
                                .contactList
                            }
                          />
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Accordion>
      </div>
    );
  };
}

PeopleSharing.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(PeopleSharing);
