import React, { Component } from 'react';
import { Tab, Tabs, Breadcrumb } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {
  faSearch,
  faTrashAlt,
  faClock,
  faPencilAlt,
  faPlus,
  faTabletAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import http from 'api/http-request';
import 'app/modules/Dashboard/Admin.scss';
import AdminPopup from 'app/modules/Dashboard/AdminPopup';
import AdminEditPopup from 'app/modules/Dashboard/AdminEditPopup';
import Alert from 'app/modules/Shared/Alert/Alert';
import Cookies from 'js-cookie';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: props.userDetails ? props.userDetails : '',

      showAdminPopup: false,
      showEditAdminPopup: false,
      adminApps: [],
      appDetails: {},
      searchString: '',
    };
  }
  componentDidMount() {
    this.scrollToTop();
    this.getAdminApps();
  }

  getAdminApps = () => {
    let bearer = 'Bearer ';
    let token = Cookies.get('dataStoreToken');
    // http(
    //   {
    //     url: `managed/Application?_queryFilter=true&_pageSize=50&_sortKeys=appname&_totalPagedResultsPolicy=EXACT&_fields=`,
    //     method: 'GET',
    //   },
    //   {
    //     Authorization: `${bearer}${token}`,
    //   }
    // ).then((res) => {
    //   this.setState({
    //     adminApps: res.data.result,
    //   });
    // });
  };

  updateApp = (status, type = 'add') => {
    var alertText =
      status === 'success' ? 'pages.Admin.successMsg' : 'pages.Admin.errorMsg';

    if (type == 'edit') {
      alertText =
        status === 'success'
          ? 'pages.Admin.editSuccessMsg'
          : 'pages.Admin.errorMsg';
    }
    if (status === 'success') {
      this.getAdminApps();
    }
    this.setState(
      {
        alertStatus: status,
        alertText,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertText: '',
          });
        }, 3000);
      }
    );
  };
  /*
  checkUIAdmin = () => {
    this.state.userDetails.effectiveRoles.map((item) => {
      if (
        'name' in item &&
        item.name === process.env.REACT_APP_UI_NAME &&
        item._id == process.env.REACT_APP_UI_ID
      ) {
        this.setState({
          uiAdmin: true,
        });
      }
    });
  };*/
  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  deleteApp = (appId) => {
    let bearer = 'Bearer ';
    let token = Cookies.get('dataStoreToken');
    http(
      {
        url: `endpoint/createappandrole/${appId}`,
        method: 'DELETE',
      },
      {
        Authorization: `${bearer}${token}`,
      }
    ).then((res) => {
      if (res.status === 200) {
        this.getAdminApps();
      }
    });
  };

  showEditAdminPopup = (appDetails) => {
    this.setState({
      showEditAdminPopup: true,
      appDetails: appDetails,
    });
  };

  setFilerKeyword = (e) => {
    let searchString = e.target.value;
    this.setState({ searchString: searchString });
  };
  render() {
    const { t, userDetails } = this.props;
    const {
      showAdminPopup,
      showEditAdminPopup,
      alertText,
      alertStatus,
      adminApps,
      appDetails,
      searchString,
    } = this.state;
    return (
      <>
        <div className="admin-apps">
          {alertText && (
            <Alert
              alertText={alertText}
              className={alertStatus}
              onAlertClose={() => {
                this.setState({
                  alertText: '',
                });
              }}
            />
          )}
          <div className="tab-area pb-5">
            <Breadcrumb>
              <Breadcrumb.Item href="#">
                {t('pages.BreadCrumb.home')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('pages.BreadCrumb.admin')}</Breadcrumb.Item>
              <Breadcrumb.Item active>...</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs
              defaultActiveKey="My-Apps"
              transition={false}
              className="ml-3"
            >
              <Tab
                eventKey="My-Apps"
                title={t('pages.Admin.tabApps')}
                tabClassName="px-3"
              >
                <div className="m-5">
                  <div className="row mb-5 align-items-center">
                    <div className="col-md-5 mb-3 mb-sm-0">
                      <h1>{t('pages.Admin.searchBarMyAppsPlaceholder')}</h1>
                      <div className="input-group rounded">
                        <input
                          type="search"
                          className="form-control rounded"
                          onChange={this.setFilerKeyword}
                          placeholder={t(
                            'pages.Admin.searchBarMyAppsPlaceholder'
                          )}
                          aria-label={t('pages.Apps.searchBarAriaLabel')}
                          aria-describedby="search-addon"
                        />
                        <span
                          className="input-group-text border-0"
                          id="search-addon"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </div>
                    {userDetails
                      ? userDetails.effectiveRoles?.map((role, index) =>
                          'name' in role &&
                          role.name === process.env.REACT_APP_UI_NAME &&
                          role._id == process.env.REACT_APP_UI_ID ? (
                            <div key={index} className="col-md-7 text-left text-md-right">
                              <a
                                className="btn-add-apps btn-small py-2 my-3 mt-0"
                                onClick={() => {
                                  this.setState({ showAdminPopup: true });
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                <span className="pl-2">
                                  {t('pages.Admin.add')}
                                </span>
                              </a>
                            </div>
                          ) : (
                            ''
                          )
                        )
                      : ''}
                  </div>
                  {showAdminPopup && (
                    <AdminPopup
                      onClose={() => {
                        this.setState({ showAdminPopup: false });
                      }}
                      updateApp={this.updateApp}
                    />
                  )}
                  {showEditAdminPopup && (
                    <AdminEditPopup
                      onClose={() => {
                        this.setState({ showEditAdminPopup: false });
                      }}
                      updateApp={this.updateApp}
                      appDetails={appDetails}
                    />
                  )}
                  <div className="row mx-0 mb-5">
                    {adminApps?.length > 0 ? (
                      adminApps
                        .filter((item) =>
                          item.appname
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        )
                        .map((device, index) => (
                          <div
                            key={index}
                            className="col-sm-6 col-md-3 col-lg-2 mx-0 mx-sm-4 my-4 app-box-item box-shadow text-center mt-3 app-content"
                            tabIndex="0"
                          >
                            {device.photo ? (
                              <img
                                className="admin-apps-img"
                                alt=""
                                src={`data:image/png;base64,${device.photo}`}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTabletAlt}
                                className="inside-button app-icon"
                              />
                            )}
                            <div className="device-name">{device.appname}</div>
                            <div className="mt-3 admin-block justify-content-evenly">
                              <a
                                className="mt-3"
                                onClick={() => this.showEditAdminPopup(device)}
                              >
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  className="inside-button"
                                />
                              </a>
                              <a
                                className="mt-3"
                                onClick={() => this.deleteApp(device._id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="inside-button"
                                />
                              </a>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="no-devices mb-3">
                        {t('pages.Admin.noApps')}
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

Admin.propTypes = {
  t: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
  // getUserDetails: PropTypes.func.isRequired,
};
export default withTranslation()(Admin);
