import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import 'app/modules/Login/Login.scss';
import { withTranslation } from 'react-i18next';
import googleLogo from 'assets/img/google-plus-logo.png';
import hyprLogo from 'assets/img/hypr.png';
import kosmosLogo from 'assets/img/1kosmos.png';
import http from 'api aegisCloud/http-request';
import Form from 'react-bootstrap/Form';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { getNestedObject, randomCount } from 'utils/common';
import WithError from 'app/modules/Shared/WithError/WithError';
import 'app/modules/Shared/WithError/WithError.scss';
import Radio from 'app/modules/Shared/Radio/Radio';
import QRCode from 'qrcode.react';
import { DeviceUUID } from 'device-uuid';
import { faCircleNotch, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import Carousel1 from 'assets/img/florida-homes-hero-image.jpg';
import Carousel2 from 'assets/img/mountains-hero-image.jpg';
import Carousel3 from 'assets/img/nyc.jpg';
import Carousel4 from 'assets/img/tampa-bay-hero-image.jpg';
import Carousel5 from 'assets/img/nyc1.jpg';
import Carousel6 from 'assets/img/san-usa.jpg';
import Carousel7 from 'assets/img/atlanta-usa.jpg';
import qrcode from 'assets/img/qrcode.jpg';
import AlertGlobal from 'app/modules/Shared/AlertGlobal/AlertGlobal';
import ReactGA from 'react-ga';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      showPassword: false,
      errorText: '',
      isAuthenticated: false,
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      screen: 1,
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCode: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
      qrURL: '',
      loginQRCode: '',
      devicePayLoad: { metadata: { hardware: {}, platform: {}, browser: {} } },
      selectedRegisterOption: '',
      countInterval: 0,
      socialLogin: false,
      securityQuestionError: '',
      carouselImages: [
        { image: Carousel1 },
        { image: Carousel2 },
        { image: Carousel3 },
        { image: Carousel4 },
        { image: Carousel5 },
        { image: Carousel6 },
        { image: Carousel7 },
      ],
      imageIndex: null,
    };
  }

  displayRandomImage = () => {
    this.setState({
      imageIndex: randomCount(),
    });
  };

  changeLanguage = (lang) => {
    setTimeout(() => {
      i18n.changeLanguage(lang);
    }, 0);
  };

  scrollToTop = () => {
    let scrollElement = document.getElementsByClassName('App-View')[0];
    scrollElement
      ? scrollElement.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : '';
  };

  componentDidMount() {
    this.scrollToTop();
    this.authenticate();
    // if (localStorage.getItem('socialLogin')) {
    if (Cookies.get('socialLogin')) {
      this.socialLoginMFA();
    }
    this.setDefaultLanguage();
    this.displayRandomImage();
  }

  setDefaultLanguage = () => {
    localStorage.setItem('ctsessionlanguage', 'en_US');
    Cookies.set('ctsessionlanguage', 'en_US');

    this.changeLanguage('en_US');
  };

  onBackLogin = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      screen: 1,
      userName: '',
      password: '',
      showPassword: '',
      errorText: '',
      isAuthenticated: '',
      loginPayLoad: {},
      authId: '',
      errorArray: [],
      loginToken: '',
      userEmail: '',
      userSMS: '',
      userOption: [],
      selectedOption: '',
      verificationCodeError: '',
      verificationOption: '',
      userPhone: '',
      authenticatorCode: '',
      scanTitle: '',
      qrCodeUrl: '',
      payLoad: [],
    });
    this.authenticate();
  };

  genericResponse = (res) => {
    this.scrollToTop();
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
      const environment = process.env.REACT_APP_ENV;
      if (environment !== 'dev') {
        window.location.href =
          'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
      } else {
        window.location.href =
          'https://openam-deloitte-dev2.forgeblocks.com/am/oauth2/realms/root/realms/bravo/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=cloudui&response_type=code&scope=openid%20openid%20fr:idm:*';
      }
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      const {
        data: { callbacks },
      } = res;
      if (callbacks) {
        if (callbacks[0].type === 'ReCaptchaCallback') {
          const recaptchaSiteKey = res.data.callbacks[0].output[0].value;
          if (recaptchaSiteKey) {
            this.setState({
              showGoogleCaptcha: true,
              googleCaptchaToken: recaptchaSiteKey,
              screen: 1,
              payLoad: res.data,
            });
            this.increaseCurrentStepHandler();
          }
        } else if (callbacks[0].type === 'SuspendedTextOutputCallback') {
          this.setState({
            currentStep: 2,
            screen: 5,
            payLoad: res.data,
          });
        } else if (callbacks[0].type === 'ChoiceCallback') {
          this.setState({
            payLoad: res.data,
            screen: 2,
            userOption: res.data.callbacks[0].output[1].value,
          });
        } else if (callbacks[0].type === 'TextOutputCallback') {
          if (callbacks[0].output[0].value.includes('Scan the barcode')) {
            this.setState({
              loginToken: res.data.authId,
              screen: 4,
              payLoad: res.data,
              scanTitle: res.data.callbacks[0].output[0].value,
              qrCodeUrl: res.data.callbacks[2].output[0].value,
            });
          }
        } else if (callbacks[0].output[0].value === 'Enter verification code') {
          this.setState({
            isAuthenticated: true,
            loginToken: res.data.authId,
            screen: 5,
            payLoad: res.data,
          });
        } else if (callbacks[0].type === 'DeviceProfileCallback') {
          this.setState({
            payLoad: res.data,
          });
          this.deviceCheck();
        } else if (callbacks[0].type === 'PasswordCallback') {
          if (res.data.callbacks[0].output[0].value == 'One Time Password') {
            this.setState({
              payLoad: res.data,
              screen: 3,
            });
          } else {
            this.setState({
              payLoad: res.data,
              screen: 11,
              verificationOption: res.data.callbacks[0].output[0].value,
            });
          }
        } else if (callbacks[0].type === 'RedirectCallback') {
          this.setState({
            payLoad: res.data,
          });
          window.location.href = callbacks[0].output[0].value;
          // localStorage.setItem('socialAuthId', res.data.authId);
          Cookies.set('socialAuthId', res.data.authId);
        }
      }
    }
  };
  authenticate = () => {
    http(
      {
        url: 'Login',
        method: 'POST',
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = sanitizeUrl(`/profile/Dashboard`);
        // localStorage.setItem('userLoggedIn', true);
        Cookies.set('userLoggedIn', true);
      } else {
        this.setState({ payLoad: res.data });
      }
    });
  };

  socialLoginMFA = () => {
    // const authId = localStorage.getItem('socialAuthId');
    const authId = Cookies.get('socialAuthId');
    // const socialLogin = JSON.parse(localStorage.getItem('socialLogin'));
    const socialLogin = JSON.parse(Cookies.get('socialLogin'));
    let state = socialLogin.state;
    let authuser = socialLogin.authuser;
    let prompt = socialLogin.prompt;
    let scope = socialLogin.scope;
    let code = socialLogin.code;
    socialLogin.authId = authId;
    http(
      {
        url: `authenticate?state=${state}&code=${code}&scope=${scope}&authuser=${authuser}&prompt=${prompt}`,
        method: 'POST',
        data: socialLogin,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.loginResponse(res);
    });
  };

  socialAuthenticate = () => {
    http(
      {
        url: 'SocialAuthentication',
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.genericResponse(res);
    });
  };

  loginResponse = (res) => {
    this.scrollToTop();
    if (getNestedObject(res, ['data', 'successUrl'])) {
      window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
      const environment = process.env.REACT_APP_ENV;
      if (environment !== 'dev') {
        window.location.href =
          'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
      } else {
        window.location.href =
          'https://openam-deloitte-dev2.forgeblocks.com/am/oauth2/realms/root/realms/bravo/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=cloudui&response_type=code&scope=openid%20openid%20fr:idm:*';
      }
    } else if (getNestedObject(res, ['data', 'callbacks'])) {
      if (
        res.status === 200 &&
        res.data &&
        (res.data.authId || res.data.tokenId)
      ) {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = sanitizeUrl(`/profile/Dashboard`);
          // localStorage.setItem('userLoggedIn', true);
          Cookies.set('userLoggedIn', true);
        } else if (getNestedObject(res, ['data', 'callbacks'])) {
          const {
            data: { callbacks },
          } = res;
          if (callbacks) {
            if (
              callbacks[0].output[0].value === 'User Name' &&
              callbacks[1].output[0].value === 'Password'
            ) {
              this.showError('pages.login.incorrectLogin');
            } else if (callbacks[0].type === 'DeviceProfileCallback') {
              this.setState({
                loginPayLoad: res.data,
              });
              this.deviceCheck();
            } else if (callbacks[0].output[0].value === 'One Time Password') {
              this.setState({
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 3,
                loginPayLoad: res.data,
              });
            } else {
              this.setState({
                userOption: res.data.callbacks[0].output[1].value,
                isAuthenticated: true,
                loginToken: res.data.authId,
                screen: 2,
                loginPayLoad: res.data,
              });
            }
          }
        }
      }
    }
  };

  deviceCheck = () => {
    const { devicePayLoad, payLoad } = this.state;
    const deviceId = new DeviceUUID().get();
    const du = new DeviceUUID().parse();
    if (du) {
      devicePayLoad.metadata.platform.deviceName = du.platform;
    }
    if (deviceId) {
      devicePayLoad.identifier = deviceId;
    }
    if (navigator.userAgent) {
      devicePayLoad.metadata.browser.userAgent = navigator.userAgent;
    }
    if (navigator.appName) {
      devicePayLoad.metadata.browser.appName = navigator.appName;
    }
    if (navigator.appCodeName) {
      devicePayLoad.metadata.browser.appCodeName = navigator.appCodeName;
    }
    if (navigator.appVersion) {
      devicePayLoad.metadata.browser.appVersion = navigator.appVersion;
    }
    if (navigator.appMinorVersion) {
      devicePayLoad.metadata.browser.appMinorVersion =
        navigator.appMinorVersion;
    }
    if (navigator.buildID) {
      devicePayLoad.metadata.browser.buildID = navigator.buildID;
    }
    if (navigator.platform) {
      devicePayLoad.metadata.platform.platform = navigator.platform;
    }
    if (navigator.cpuClass) {
      devicePayLoad.metadata.hardware.cpuClass = navigator.cpuClass;
    }
    if (navigator.oscpu) {
      devicePayLoad.metadata.hardware.oscpu = navigator.oscpu;
    }
    if (navigator.product) {
      devicePayLoad.metadata.browser.product = navigator.product;
    }
    if (navigator.productSub) {
      devicePayLoad.metadata.browser.productSub = navigator.productSub;
    }
    if (navigator.vendor) {
      devicePayLoad.metadata.browser.vendor = navigator.vendor;
    }
    if (navigator.vendorSub) {
      devicePrint.metadata.browser.vendorSub = navigator.vendorSub;
    }
    if (navigator.language) {
      devicePayLoad.metadata.platform.language = navigator.language;
    }
    if (navigator.userLanguage) {
      devicePayLoad.metadata.platform.userLanguage = navigator.userLanguage;
    }
    if (navigator.browserLanguage) {
      devicePayLoad.metadata.browser.browserLanguage =
        navigator.browserLanguage;
    }
    if (navigator.systemLanguage) {
      devicePayLoad.metadata.platform.systemLanguage = navigator.systemLanguage;
    }
    payLoad.callbacks[0].input[0].value = JSON.stringify(devicePayLoad);
    // loginPayLoad.callbacks[0].input[0].value =
    //   '{"identifier":"1267894517-1638565932-442693090","metadata":{"hardware":{"cpuClass":null,"deviceMemory":null,"hardwareConcurrency":8,"maxTouchPoints":10,"oscpu":null,"display":{"width":1280,"height":720,"pixelDepth":24,"angle":0}},"browser":{"userAgent":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36","appName":"Netscape","appCodeName":"Mozilla","appVersion":"5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36","appMinorVersion":null,"buildID":null,"product":"Gecko","productSub":"20030107","vendor":"Google Inc."},"platform":{"language":"en-US","platform":"Win32","userLanguage":null,"systemLanguage":null,"deviceName":"Windows (Browser)","timezone":-330}}}';
    http(
      {
        url: 'Login',
        method: 'POST',
        data: payLoad,
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      this.scrollToTop();
      this.genericResponse(res);
    });
  };

  onSocialLogin = (e) => {
    e.preventDefault();
    this.setState({
      socialLogin: true,
    });
    //window.location.href = `${process.env.REACT_APP_GOOGLE_LOGIN_ENDPOINT}`;
    this.socialAuthenticate();
  };

  /*
  on1kosmosLogin = async (e) => {
    e.preventDefault();

    const BIDSDK = require('blockid-nodejs-helpers/BIDSDK.js');
    const BIDSessions = require('blockid-nodejs-helpers/BIDSessions.js');
    let mySession = null;

    BIDSDK.setupTenant(
      { dns: 'blockid-trial.1kosmos.net', communityName: 'devx' },
      '0fc061c6-bfcb-4037-acf2-35d3fb488c76'
    ).then((loaded) => {});

    mySession = await BIDSessions.createNewSession('Fingerprint', 'firstname');
    const sessionid = mySession.sessionId;
    const url = mySession.url;

    this.setState({
      qrURL: url + '/session/' + sessionid,
      screen: 10,
    });

    this.kosmosPollingSession(e, sessionid);
  };

  kosmosPollingSession = (e, sessionid) => {
    const BIDSessions = require('blockid-nodejs-helpers/BIDSessions.js');
    let sessionData = null;
    let count = 0;
    try {
      const pollingSessionTimer = setInterval(async () => {
        count++;
        if (count === 120) {
          clearInterval(pollingSessionTimer);
          this.on1kosmosLogin(e);
        }
        sessionData = await BIDSessions.pollSession(sessionid, true, true);
        if (sessionData.status === 200) {
          clearInterval(pollingSessionTimer);
          this.showError('Authentication Successful');
        } else if (
          sessionData.message === "Session with this sessionId doesn't exist"
        ) {
          clearInterval(pollingSessionTimer);
          this.showError('The Session has Expired, Try Refreshing the QR code');
        }
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  // createUser= () => {

  //   const BIDECDSA = require("blockid-nodejs-helpers/BIDECDSA.js");
  //   var BIDSDK = require("blockid-nodejs-helpers/BIDSDK.js");
  //   BIDSDK.setupTenant({dns: "eims-pilot.1kosmos.net>", communityName: "default"}, "9111a44f-886c-4d59-b6bc-b1d60ce77bd3").then(loaded => {
  //     console.log('is tenant loaded:', loaded);
  //   });

  //   const publicKey = "os9skQhJpyJIz7g5xgopwlN3N7eqMR9XMupvIIZf+CkB0V5ADDDdQ0wfA8KqH98NuFch4cIm6qwfuLGwoPCk+A==";
  //   const licenseKey = "9111a44f-886c-4d59-b6bc-b1d60ce77bd3";
  //   const privateKey = "cGB/g7jd/jw1v4z8FDfoeppYQ6y1IJAYzPPkzKkCc4w=";
  //   const bidpublickKey = "tl5v3ISk+1/tssWIOHHoZeg9QF3SztMyvOstLnNy6v0NdESeaVurn9CuwPE+qtMlyO9x1pbPbGZZeR7aF75aIQ==";

  //   const sharedKey = BIDECDSA.createSharedKey(privateKey,bidpublickKey);
  //   const encryptedLicenseKey = BIDECDSA.encrypt(licenseKey,sharedKey);

  //   var dataStr = JSON.stringify({ts: Math.round(new Date().getTime() / 1000),appid: 'com.bid.jsp.sdk',uuid: '77992454-beb3-49ce-ba8a-b230bfc90d67'});

  //   const encryptedRequestID = BIDECDSA.encrypt(dataStr,sharedKey);

  //   http(
  //     {
  //       url: 'tenant/61b0c6a594e40a0a3ed7bc42/community/61b0c6a694e40a0a3ed7bc43/users/create',
  //       data:{
  //         "authModule": "61b9fff0e785e8001ace7229",
  //         "users": [
  //           {
  //             "username": "anant123",
  //             "password": "@Lpha123",
  //             "status": "active",
  //             "firstname": "Anant",
  //             "middlename": "asd",
  //             "lastname": "Mishra",
  //             "email1": "anantmishra20@email.com",
  //             "email1_verified": true,
  //             "email2": "anantmishra22@email.com",
  //             "email2_verified": true,
  //             "phone1": "9876543210",
  //             "phone1_verified": true,
  //             "address": {"house" : "Church Road"},
  //             "address_verified": true,
  //             "disabled": false
  //           }
  //         ]
  //       },
  //       method: 'PUT',
  //     },
  //     {
  //       "Content-Type": "application/json",
  //       "Accept": "",
  //       "licensekey": encryptedLicenseKey,
  //       "requestid" : encryptedRequestID,
  //       "publickey": publicKey
  //     }
  //   ).then((res) => {
  //     console.log(res);
  //   });
  // }

  */

  onhyprLogin = (e) => {
    e.preventDefault();

    http(
      {
        url: `hyprAuth`,
        method: 'POST',
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.setState({ screen: 8 });

      this.genericResponse(res);
    });
  };

  onHyprAuthentication = (e) => {
    e.preventDefault();
    const { userName } = this.state;
    if (userName === '') {
      this.showError('errors.userName');
    } else {
      this.setState({
        errorText: '',
      });

      this.getHyprAuth();
    }
  };

  getHyprAuth = () => {
    const { userName, loginPayLoad } = this.state;
    this.scrollToTop();
    loginPayLoad.callbacks[0].input[0].value = userName;

    http(
      {
        url: `hyprAuth`,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (
        res.data &&
        res.status === 200 &&
        res.data.callbacks[0].type === 'PollingWaitCallback'
      ) {
        this.setState({
          loginPayLoad: res.data,
          screen: 9,
          userOption: res.data.callbacks[0].output,
        });
        setInterval(this.getPushHYPR(), 5000);
      } else if (
        res.status === 401 &&
        res.data.code === 401 &&
        res.data.reason === 'Unauthorized'
      ) {
        this.showError('errors.invalidUsername');
        http(
          {
            url: `hyprAuth`,
            method: 'POST',
          },
          {
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
          }
        ).then((res) => {
          if (getNestedObject(res, ['data'])) {
            this.setState({
              loginPayLoad: res.data,
              loginToken: res.data.authId,
            });
          }
        });
      }
    });
  };
  authService = () => {
    const { payLoad } = this.state;
    http(
      {
        url: 'Login',
        data: payLoad,
        method: 'POST',
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      if (res.status == 401 && res.data.reason == 'Unauthorized') {
        this.showError('pages.login.incorrectLogin');
      } else {
        this.genericResponse(res);
      }
    });
  };

  onLogin = (e) => {
    ReactGA.event({
      category: 'Login-AegisCloud',
      action: 'Login: Login Button Pressed',
    });
    e.preventDefault();
    this.setState({
      socialLogin: false,
    });

    const { userName, password, socialLogin, payLoad } = this.state;

    payLoad.callbacks[0].input[0].value = userName;
    payLoad.callbacks[1].input[0].value = password;

    http(
      {
        url: 'Login',
        data: payLoad,
        method: 'POST',
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      if (res.status == 401 && res.data.reason == 'Unauthorized') {
        this.showError('pages.login.incorrectLogin');
      } else {
        this.setState({
          errorText: '',
        });
        this.genericResponse(res);
      }
    });
    if (!socialLogin) {
      // localStorage.removeItem('socialLogin');
      Cookies.remove('socialLogin');
      // localStorage.removeItem('email');
      Cookies.remove('email');
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  showPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  PasswordValidation = (e) => {
    const {
      target: { value },
    } = e;
    if (value.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/)) {
      this.setState({
        password: value,
      });
    }
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  onOptionsChange = (value) => {
    this.setState({
      selectedOption: value,
    });
  };

  onDeviceOptionsChange = (value) => {
    if (value.includes('Yes')) {
      value = 'Yes';
    } else {
      value = 'No';
    }
    this.setState({
      selectedRegisterOption: value,
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  onVerificationCodeSubmit = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { payLoad, verificationCode, socialLogin } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      payLoad.callbacks[0].input[0].value = verificationCode;
      let url = '';
      if (socialLogin) {
        url =
          'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
      } else {
        url = 'Login';
      }
      http(
        {
          url: url,
          method: 'POST',
          data: payLoad,
        },
        {
          'accept-api-version': 'protocol=1.0,resource=2.1',
          'Content-Type': 'application/json',
        }
      ).then((res) => {
        if (getNestedObject(res, ['data', 'successUrl'])) {
          window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
          const environment = process.env.REACT_APP_ENV;
          if (environment !== 'dev') {
            window.location.href =
              'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
          } else {
            window.location.href =
              'https://openam-deloitte-dev2.forgeblocks.com/am/oauth2/realms/root/realms/bravo/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=cloudui&response_type=code&scope=openid%20openid%20fr:idm:*';
          }
        } else if (getNestedObject(res, ['data', 'callbacks'])) {
          if (
            res.status === 200 &&
            res.data &&
            (res.data.authId || res.data.tokenId)
          ) {
            if (getNestedObject(res, ['data', 'successUrl'])) {
              window.location.href = sanitizeUrl(`/profile/Dashboard`);
              // localStorage.setItem('userLoggedIn', true);
              Cookies.set('userLoggedIn', true);
            } else if (getNestedObject(res, ['data', 'callbacks'])) {
              const {
                data: { callbacks },
              } = res;
              if (callbacks) {
                if (callbacks[0].output[0].value === 'One Time Password') {
                  this.setState({
                    isAuthenticated: true,
                    loginToken: res.data.authId,
                    screen: 3,
                    payLoad: res.data,
                    verificationCodeError:
                      'pages.forgotUserId.invalidVerificationCode',
                  });
                } else if (callbacks[0].type === 'ChoiceCallback') {
                  this.setState({
                    isAuthenticated: true,
                    loginToken: res.data.authId,
                    screen: 6,
                    payLoad: res.data,
                    userOption: res.data.callbacks[0].output,
                  });
                } else {
                  this.setState({
                    userOption: res.data.callbacks[0].output[1].value,
                    isAuthenticated: true,
                    loginToken: res.data.authId,
                    screen: 2,
                    payLoad: res.data,
                  });
                }
              }
            }
          }
        } else if (
          res.data.code === 401 &&
          res.data.message ===
            'Authentication Failed Warning: You will be locked out after 1 more failure(s).'
        ) {
          this.showError('pages.login.willGetLocked');
        } else if (
          res.data.code === 401 &&
          res.data.message ===
            'Authentication Failed Warning: You will be locked out after 2 more failure(s).'
        ) {
          this.showError('pages.login.willGetLocked2');
        } else if (
          res.data.code === 401 &&
          res.data.message === 'Login failure'
        ) {
          window.location.href = '/';
          this.showError('pages.login.loginFailure');
        }
      });
    }
  };

  getPushHYPR = () => {
    const { loginPayLoad } = this.state;
    this.scrollToTop();
    http(
      {
        url: 'hyprAuth',
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.scrollToTop();
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
        const environment = process.env.REACT_APP_ENV;
        if (environment !== 'dev') {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=https://aegisui.deloitte.com/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        } else {
          window.location.href =
            'https://aegisam.deloitte.com/am/oauth2/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=aegisui&response_type=code&scope=openid%20openid%20fr:idm:*&realm=/aegis';
        }
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/Dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].type === 'PollingWaitCallback') {
                this.setState({
                  loginPayLoad: res.data,
                  screen: 9,
                  userOption: res.data.callbacks[0].output,
                });
                setInterval(this.getPushHYPR(), 5000);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'Login failure'
      ) {
        window.location.href = '/';
        //this.showError('errors.loginApiError');
      }
    });
  };

  getPushNotification = () => {
    const { loginPayLoad, socialLogin } = this.state;
    this.scrollToTop();
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url =
        'authenticate?authIndexType=service&authIndexValue=testTree&service=testTree';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: loginPayLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/Dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  loginPayLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  loginPayLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
                if (this.state.qrCodeUrl.includes('pushauth')) {
                  setTimeout(() => {
                    setInterval(this.getPushNotification(), 10000);
                  }, 2000);
                  this.setState({
                    countInterval: this.state.countInterval + 1,
                  });
                }
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 6,
                  loginPayLoad: res.data,
                  userOption: res.data.callbacks[0].output,
                });
              } else if (callbacks[0].type === 'PollingWaitCallback') {
                this.setState({
                  loginPayLoad: res.data,
                  screen: 7,
                  userOption: res.data.callbacks[0].output,
                });
                setInterval(this.getPushNotification(), 5000);
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  loginPayLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'Login failure'
      ) {
        window.location.href = '/';
        //this.showError('errors.loginApiError');
      }
    });
  };

  onVerifyQuestion = (e) => {
    e.preventDefault();
    const { payLoad, securityQuestionValue } = this.state;
    if (securityQuestionValue === '') {
      this.setState({
        securityQuestionError: 'pages.forgotUserId.securityQuestionRequired',
      });
    } else {
      payLoad.callbacks[0].input[0].value = securityQuestionValue;
      http(
        {
          url: 'Login',
          data: payLoad,
          method: 'POST',
        },
        {
          'Content-Type': 'application/json',
          'Accept-API-Version': 'protocol=1.0,resource=2.1',
        }
      ).then((res) => {
        if (res.status === 200 && res.data) {
          this.genericResponse(res);
        } else if (
          res.status === 401 &&
          res.data &&
          res.data.reason === 'Unauthorized'
        ) {
          this.setState({
            securityQuestionError: 'pages.forgotUserId.invalidSecurityAnswer',
          });
        }
      });
    }
  };
  onNext = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { payLoad, selectedOption } = this.state;
    payLoad.callbacks[0].input[0].value = selectedOption;

    http(
      {
        url: 'Login',
        method: 'POST',
        data: payLoad,
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      this.genericResponse(res);
    });
    this.scrollToTop();
  };

  handleQuestionChange(evt) {
    const securityQuestionValue = evt.target.validity.valid
      ? evt.target.value
      : this.state.securityQuestionValue;

    this.setState({ securityQuestionValue });
  }

  onNextRegister = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { payLoad, selectedRegisterOption, socialLogin } = this.state;
    if (selectedRegisterOption === 'Yes') {
      payLoad.callbacks[0].input[0].value = 0;
    } else if (selectedRegisterOption === 'No') {
      payLoad.callbacks[0].input[0].value = 1;
    }

    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'Login';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: payLoad,
      },
      {
        'accept-api-version': 'protocol=1.0,resource=2.1',
        'Content-Type': 'application/json',
      }
    ).then((res) => {
      if (getNestedObject(res, ['data', 'successUrl'])) {
        window.location.href = 'sanitizeUrl(`/profile/Dashboard`);';
        const environment = process.env.REACT_APP_ENV;
        if (environment !== 'dev') {
          window.location.href =
            'https://openam-deloitte-dev2.forgeblocks.com/am/oauth2/realms/root/realms/bravo/authorize?redirect_uri=https://openam-deloitte-dev2.forgeblocks.com/enduser/appAuthHelperRedirect.html&client_id=endUserUIClient&response_type=code&state=L9GqA4Uszx&scope=openid%20fr:idm:*&code_challenge=lfxE3BKJE-6QQi8gM_uAsD4aNJgqY719U5NSGwncskI&code_challenge_method=S256';
        } else {
          window.location.href =
            'https://openam-deloitte-dev2.forgeblocks.com/am/oauth2/realms/root/realms/bravo/authorize?redirect_uri=http://localhost:3000/profile/Dashboard&client_id=cloudui&response_type=code&state=hHpPhCD9RS&scope=openid fr:idm:*&code_challenge=om1S31QhWc3Cg3SIYmXtsrehcnZ--hlLjq1_hl5sAfc&code_challenge_method=S256';
        }
      } else if (getNestedObject(res, ['data', 'callbacks'])) {
        if (
          res.status === 200 &&
          res.data &&
          (res.data.authId || res.data.tokenId)
        ) {
          if (getNestedObject(res, ['data', 'successUrl'])) {
            window.location.href = sanitizeUrl(`/profile/Dashboard`);
            // localStorage.setItem('userLoggedIn', true);
            Cookies.set('userLoggedIn', true);
          } else if (getNestedObject(res, ['data', 'callbacks'])) {
            const {
              data: { callbacks },
            } = res;
            if (callbacks) {
              if (callbacks[0].output[0].value === 'One Time Password') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 3,
                  payLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value === 'Enter verification code'
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  payLoad: res.data,
                });
              } else if (
                callbacks[0].output[0].value.includes('Scan the barcode')
              ) {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 4,
                  payLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else if (callbacks[0].type === 'ChoiceCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  payLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else if (callbacks[0].type === 'NameCallback') {
                this.setState({
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 5,
                  payLoad: res.data,
                  scanTitle: res.data.callbacks[0].output[0].value,
                  qrCodeUrl: res.data.callbacks[2].output[0].value,
                });
              } else {
                this.setState({
                  userOption: res.data.callbacks[0].output[1].value,
                  isAuthenticated: true,
                  loginToken: res.data.authId,
                  screen: 2,
                  payLoad: res.data,
                });
              }
            }
          }
        }
      } else if (
        res.data.code === 401 &&
        res.data.message === 'User Locked Out.'
      ) {
        this.showError('pages.login.userLockedOut');
        window.location.href = '/';
      }
    });
  };
  createSession = () => {
    createNewSession(
      'Fingerprint',
      'firstname,did,userIdList',
      'qrcode',
      null,
      function (result, error) {
        client_dataRecieved(result);
      }
    );
  };
  client_dataRecieved = (result) => {
    let str = JSON.stringify(result, null, 4);
    var aurl =
      'http://forgerock.blockid.co/XUI/?realm=/&service=BlockIDTree&BlockIDAuthn=' +
      window.btoa(str);
    window.location.href = aurl;
  };
  generateQRCode = () => {
    let loginQRCode =
      'http://chart.apis.google.com/chart?cht=qr&chl=google.com&chs=248';
    this.setState({
      loginQRCode: loginQRCode,
    });
    this.createSession();
  };
  onNextQR = (e) => {
    e.preventDefault();
    this.scrollToTop();
    const { payLoad, socialLogin } = this.state;
    let url = '';
    if (socialLogin) {
      url =
        'authenticate?service=SocialLogin&authIndexType=service&authIndexValue=SocialLogin';
    } else {
      url = 'Login';
    }
    http(
      {
        url: url,
        method: 'POST',
        data: payLoad,
      },
      {
        'Accept-API-Version': 'protocol=1.0,resource=2.1',
      }
    ).then((res) => {
      this.genericResponse(res);
    });
  };

  render() {
    const {
      userName,
      password,
      showPassword,
      errorText,
      errorArray,
      screen,
      userOption,
      selectedOption,
      verificationCode,
      verificationCodeError,
      verificationOption,
      scanTitle,
      qrCodeUrl,
      selectedRegisterOption,
      carouselImages,
      imageIndex,
      loginQRCode,
      securityQuestionError,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <div
          className={`login-container
            ${screen > 1 ? 'has-background' : undefined}`}
        >
          {(errorText || errorArray.length > 0) && (
            <div className="error-div">
              <WithError errors={errorText} errorArray={errorArray} />
            </div>
          )}
          {screen === 1 && (
            <div className="log-container">
              <div className="row justify-content-center">
                <div className="col-md-6 p-0">
                  <div className="mt-3">
                    <AlertGlobal useTicker={false}></AlertGlobal>
                  </div>

                  <div className="row justify-content-center pt-5">
                    <Form className="login-form p-3">
                      <h1>{t('pages.login.login')}</h1>
                      <Form.Group controlId="loginEmail">
                        <Form.Label>{t('pages.login.userId')}</Form.Label>
                        <Form.Control
                          type="email"
                          onChange={(e) => {
                            this.setState({
                              userName: e.target.value.trim(),
                            });
                          }}
                          value={userName}
                          aria-required
                        />
                      </Form.Group>
                      <Form.Group
                        className="password-field position-relative"
                        controlId="loginPassword"
                      >
                        <Form.Label>{t('pages.login.password')}</Form.Label>
                        <Form.Control
                          className="login-password-input"
                          type={showPassword ? 'text' : 'password'}
                          onChange={(e) => {
                            this.PasswordValidation(e);
                          }}
                          value={password}
                          aria-required
                          autoComplete="off"
                        />
                        <span
                          className={
                            showPassword
                              ? 'eye-icon login-password-eye show'
                              : 'eye-icon login-password-eye'
                          }
                          onClick={() => this.showPassword()}
                        />
                      </Form.Group>
                      <div className="row justify-content-center mt-3">
                        <div className="col">
                          <Button
                            className="btn w-100 btn btn-primary"
                            type="submit"
                            onClick={(e) => this.onLogin(e)}
                          >
                            {t('pages.login.logInBtnText')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                  {/*
                  <div className="row">
                    <div className="col text-center">
                      {loginQRCode == '' && (
                        <button
                          className="unstyled-button mb-2"
                          onClick={this.generateQRCode}
                        >
                          {t('pages.login.scanLogin')}{' '}
                          <img src={qrcode} alt="qrcode" className="qrcode" />
                        </button>
                      )}

                      {loginQRCode != '' && (
                        <button
                          className="unstyled-button mb-2"
                          onClick={this.generateQRCode}
                        >
                          {t('pages.login.scanLoginNew')}{' '}
                          <img src={qrcode} alt="qrcode" className="qrcode" />
                        </button>
                      )}

                      {loginQRCode != '' && (
                        <div className="col text-center">
                          {/*<div
                            id="qrcode"
                            style="z-index: 1;position:relative;margin:10px"
                            align="center"
                          ></div>
                          */}
                  {/*
                          <QRCode value={loginQRCode} />
                        </div>
                      )}
                    </div>
                  </div>
                          */}

                  <div className="row">
                    <div className="col text-center">
                      <p className="mb-2">{t('pages.login.withText')}</p>
                      <span className="mr-sm-4 py-2 my-3">
                        <a>
                          <img
                            src={googleLogo}
                            alt="google"
                            onClick={(e) => this.onSocialLogin(e)}
                          />
                        </a>
                      </span>
                      {/* <span className="mr-sm-4 py-2 my-3">
                        <a>
                          <img
                            style={{ 'max-height': '3.5rem' }}
                            src={hyprLogo}
                            alt="hypr"
                            onClick={(e) => this.onhyprLogin(e)}
                          />
                        </a>
                      </span>
                      <span className="mr-sm-4 py-2 my-3">
                        <a>
                          <img
                            style={{ 'max-height': '3.5rem' }}
                            src={kosmosLogo}
                            alt="1kosmos"
                            onClick={(e) => this.on1kosmosLogin(e)}
                          />
                        </a>
                      </span> */}
                    </div>
                  </div>
                  <hr className="separator-login" />
                  <div className="row justify-content-center">
                    <Form className="login-form p-3">
                      <Form.Group>
                        <p className="mb-2 text-center">
                          {t('pages.login.noAccountQuestion')}
                        </p>
                        <Button
                          className="w-100 btn btn-secondary"
                          onClick={() => {
                            ReactGA.event({
                              category: 'Signup - AegisCloud',
                              action: 'Signup Triggered: Signup button Pressed',
                            });
                            window.location.href = '/registration';
                          }}
                        >
                          {t('pages.login.signUpBtnText')}
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="row justify-content-center mb-5">
                    <div className="forget-username-mobile col-sm-4 pb-3 pb-sm-0 text-center text-sm-left">
                      <Link to="/forgot-userId" className="text-blue">
                        {t('pages.login.forgotUserIdLink')}
                      </Link>
                    </div>
                    <div className="forget-password-mobile col-sm-4 text-center text-sm-right">
                      <Link to="forgot-password" className="text-blue">
                        {t('pages.login.forgotPasswordLink')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div
                    className="hero-img-bg"
                    style={{
                      backgroundImage: `url(${
                        carouselImages[imageIndex || 0].image
                      })`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
          {screen === 2 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                {/* {localStorage.getItem('email') && ( */}
                {Cookies.get('email') && (
                  <div>
                    {t('pages.login.socialLoggedIn')}{' '}
                    <span className="bold">
                      {/* {localStorage.getItem('email')} */}
                      {Cookies.get('email')}
                    </span>
                  </div>
                )}
                <div className="mt-3 mb-3 choose-title">
                  {t('pages.login.choseMFA')}
                </div>
                <div>
                  <Form className="login-radio-buttons">
                    {userOption.length > 0 &&
                      userOption.map((payLoad, index) => (
                        <Radio
                          key={index}
                          name={payLoad}
                          label={payLoad}
                          value={index}
                          isChecked={selectedOption === index ? true : false}
                          handleChange={this.onOptionsChange}
                        />
                      ))}
                    <div className="row mt-3 mt-sm-5">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotPassword.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3 "
                          type="submit"
                          onClick={(e) => this.onNext(e)}
                          disabled={selectedOption === ''}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          )}
          {screen === 3 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <h3>
                  <span>{t('pages.registration.verificationCodeSent')}</span>
                </h3>
                <Form>
                  <Form.Group
                    className="verification-form mt-5"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4">
                      {t('pages.registration.verificationCode')}
                    </Form.Label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      onInput={this.handleChange.bind(this)}
                      className="ml-3"
                      value={verificationCode}
                    />
                    {verificationCodeError && (
                      <p className="error-text" id="verification-error">
                        {verificationCodeError && t(verificationCodeError)}
                      </p>
                    )}
                  </Form.Group>
                  <div className="registration__container__pleasenote">
                    <p className="registration__container__pleasenote-fp">
                      {t('pages.registration.note')}
                    </p>
                    <p className="registration__container__pleasenote-sp">
                      {t('pages.registration.verifyNote')}
                    </p>
                  </div>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>

                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.onVerificationCodeSubmit(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
          {screen === 11 && (
            <div className="radiobox-container container my-5 forgot-username">
              <h3>
                <span>{t('pages.forgotUserId.securityQuestion')}</span>
              </h3>
              <Form className="col-md-6 mt-3 pt-4 pl-0">
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">{verificationOption}</Form.Label>
                  <Form.Control
                    type="text"
                    onInput={this.handleQuestionChange.bind(this)}
                  />
                  {securityQuestionError && (
                    <p className="error-text" id="verification-error">
                      {securityQuestionError && t(securityQuestionError)}
                    </p>
                  )}
                </Form.Group>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-12 col-md-12">
                    <button
                      className="col btn btn-secondary hollow  mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      // onClick={this.decreaseCurrentStepHandler}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary  py-2 my-3"
                      onClick={(e) => this.onVerifyQuestion(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {screen === 4 && (
            <>
              <div className="radiobox-container container my-5 forgot-username barcode-center">
                <h4 className="mb-5">
                  <span>{scanTitle}</span>
                </h4>
                <QRCode value={qrCodeUrl} />
                {qrCodeUrl.includes('pushauth') && (
                  <div className="mb-3 mt-5">
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="fa-spin"
                      role="img"
                    />
                    <span className="ml-3">
                      {t('pages.login.waitingResponse')}
                    </span>
                  </div>
                )}
                {!qrCodeUrl.includes('pushauth') && (
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3 "
                        type="submit"
                        onClick={(e) => this.onNextQR(e)}
                      >
                        {t('pages.editNumberPopup.next')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {screen === 5 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <h3>
                  <span>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {t('pages.registration.authenticatorApp')}
                  </span>
                </h3>
                <Form>
                  <Form.Group
                    className="verification-form"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4 mt-3">
                      {t('pages.registration.verificationCode')}
                    </Form.Label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      onInput={this.handleChange.bind(this)}
                      className="ml-3"
                      value={verificationCode}
                    />
                    {verificationCodeError && (
                      <p className="error-text" id="verification-error">
                        {verificationCodeError && t(verificationCodeError)}
                      </p>
                    )}
                  </Form.Group>
                  <div className="registration__container__pleasenote">
                    <p className="registration__container__pleasenote-fp">
                      {t('pages.registration.note')}
                    </p>
                    <p className="registration__container__pleasenote-sp">
                      {t('pages.registration.verifyNote')}
                    </p>
                  </div>
                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.onVerificationCodeSubmit(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
          {screen === 6 && (
            <>
              <div className="radiobox-container container my-5 forgot-username">
                <div className="mt-3 mb-3 choose-title">
                  {userOption[0].value}
                </div>
                <div>
                  <Form>
                    <Radio
                      key={'Yes'}
                      name={'Yes'}
                      label={'Yes'}
                      value={'Yes'}
                      isChecked={
                        selectedRegisterOption === 'Yes' ? true : false
                      }
                      handleChange={this.onDeviceOptionsChange}
                    />
                    <Radio
                      key={'No'}
                      name={'No'}
                      label={'No'}
                      value={'No'}
                      isChecked={selectedRegisterOption === 'No' ? true : false}
                      handleChange={this.onDeviceOptionsChange}
                    />
                    <div className="row pt-3">
                      <div className="d-sm-flex col-lg-6 col-md-12">
                        <Button
                          className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                          onClick={(e) => this.onBackLogin(e)}
                        >
                          {t('pages.forgotUserId.backBtn')}
                        </Button>
                        <Button
                          className="col btn btn-secondary py-2 my-3"
                          type="submit"
                          onClick={(e) => this.onNextRegister(e)}
                          disabled={selectedRegisterOption === ''}
                        >
                          {t('pages.editNumberPopup.next')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          )}
          {screen === 7 && (
            <>
              <div className="radiobox-container container my-5 forgot-username waiting-response">
                <div className="mt-3 mb-3 choose-title">
                  {userOption[1].value}
                </div>
              </div>
            </>
          )}
          {screen === 9 && (
            <>
              <div className="radiobox-container container my-5 forgot-username waiting-response">
                <div className="mt-3 mb-3 choose-title">
                  {userOption[1].value}
                </div>
              </div>
            </>
          )}

          {screen === 8 && (
            <>
              <div className="radiobox-container container my-5 forgot-username hypr">
                <div className="mt-3 mb-3 choose-title">
                  {t('pages.login.hyprLogin')}
                </div>
                <Form>
                  <Form.Group
                    className="verification-form"
                    controlId="verificationCode"
                  >
                    <Form.Label className="mb-4 mt-3">
                      {t('pages.login.userId')} /{' '}
                      {t('pages.registration.userName')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        this.setState({
                          userName: e.target.value.trim(),
                        });
                      }}
                      value={userName}
                    />
                  </Form.Group>

                  <div className="row pt-3">
                    <div className="d-sm-flex col-lg-6 col-md-12">
                      <Button
                        className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                        onClick={(e) => this.onBackLogin(e)}
                      >
                        {t('pages.forgotUserId.backBtn')}
                      </Button>
                      <Button
                        className="col btn btn-secondary py-2 my-3"
                        onClick={(e) => this.onHyprAuthentication(e)}
                        type="submit"
                      >
                        {t('pages.login.login')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
          {screen === 10 && (
            <>
              <div className="radiobox-container container my-5 1kosmos barcode-center">
                <h4 className="mb-5">
                  <span>{t('pages.login.1kosmosTitle')}</span>
                </h4>
                <QRCode value={this.state.qrURL} />
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <Button
                      className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                    >
                      {t('pages.forgotUserId.backBtn')}
                    </Button>
                    <Button
                      className="col btn btn-secondary py-2 my-3 "
                      type="submit"
                      onClick={(e) => this.on1kosmosLogin(e)}
                    >
                      {t('pages.forgotUserId.generateQRBtn')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
Login.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(Login);
