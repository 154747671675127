import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'app/modules/ForgotPassword/ForgotPassword.scss';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import SuccessConfirmation from 'app/modules/Shared/SuccessConfirmation/SuccessConfirmation';
import FormNavigationBar from 'app/modules/Shared/FormNavigationBar/FormNavigationBar';
import GoogleCaptcha from '../Shared/GoogleCaptcha/GoogleCaptcha';
import { Col } from 'react-bootstrap';
import http from 'api/http-request';
import {
  getNestedObject,
  isPasswordFormatValid,
  pageNotFound,
} from 'utils/common';
import Radio from 'app/modules/Shared/Radio/Radio';
import PasswordField from 'app/modules/Shared/PasswordField/PasswordField';
import PasswordRequirements from 'app/modules/Shared/PasswordRequirements/PasswordRequirements';
import Alert from 'app/modules/Shared/Alert/Alert';
import WithError from '../Shared/WithError/WithError';

class ForgotPasswordCognito extends Component {
  constructor(props) {
    super(props);
    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.state = {
      mail: '',
      emailError: '',
      stepNames: [ 
        'pages.formNavigator.lookup',
        'pages.formNavigator.captcha',
        'pages.formNavigator.accountVerification'
      ],
      maxStep: 1,
      screen: 0,
      currentStep: 0,
      showGoogleCaptcha: false,
      googleCaptchaToken: '',
      token: '',
      googleCaptchaResponse: '',
      captchaReset: false,
      responseToken: '',
      userName: '',
      verificationPlaceholder: ['0', '0', '0', '0', '0', '0'],
      securityQuestion1: '',
      securityQuestion2: '',
      securityQuestion3: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      verificationCode: '',
      verificationOption: '',
      verificationCodeError: '',
      errorText: '',
      passwordErrorText: '',
      errorArray: [],
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      changeMethod: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      securityQuestionError: '',
      userExist: '',
      radioOptionError: '',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  disableCopyPaste = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  showError = (errorText) => {
    this.setState({
      errorText,
    });
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  increaseCurrentStepHandler = () => {
    if (this.state.currentStep < this.state.maxStep) {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  decreaseCurrentStepHandler = (e) => {
    e.preventDefault();
    this.setState({
      userExist: false,
    });
    if (this.state.currentStep > 0) {
      this.setState((prev) => ({
        currentStep: prev.currentStep - 1,
        screen: prev.currentStep - 1,
      }));
    } else {
      this.sendToLogin();
    }
  };

  sendToLogin = () => {
    return this.props.history.push('/');
  };

  forgotUserNameSubmit = () => {
    const { googleCaptchaResponse, responseToken } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'selfservice/reset?_action=submitRequirements',
        data: {
          input: {
            response: googleCaptchaResponse,
            'g-recaptcha-response': googleCaptchaResponse,
          },
          token: responseToken,
        },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (
        res.status === 200 &&
        res.data.type === 'kbaSecurityAnswerVerificationStage' &&
        res.data.tag === 'initial'
      ) {
        const securityQuestionSet = getNestedObject(res, [
          'data',
          'requirements',
          'properties',
        ]);
        if (securityQuestionSet) {
          this.setState({
            securityQuestion1: securityQuestionSet.answer1.systemQuestion.en,
            securityQuestion2: securityQuestionSet.answer2.systemQuestion.en,
            securityQuestion3: securityQuestionSet.answer3.systemQuestion.en,
            responseToken: res.data.token,
          });
        }
        this.increaseCurrentStepHandler();
        this.setState({
          screen: 2,
        });
      } else if (
        res.status === 200 &&
        res.data.type === 'codeValidation' &&
        res.data.tag === 'VALIDATE_CODE'
      ) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
          });
        }
        this.setState({
          screen: 3,
          verificationOption:
            res.data.requirements.properties.verificationCode.description,
        });
        this.increaseCurrentStepHandler();
      } else if (
        res.status === 200 &&
        res.data.type === 'userOTPChoice' &&
        res.data.tag === 'initial'
      ) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
          });
        }
        this.setState({
          screen: 2,
          userEmail: res.data.requirements.userEmail,
          userPhone: res.data.requirements.userPhone,
          kba: res.data.requirements.KBA,
        });
        this.increaseCurrentStepHandler();
      }
    });
  };

  isFormFieldValid = (e, key) => {
    const value = e.target ? e.target.value.trim() : e;
    const { password, confirmPassword } = this.state;

    let errorMsg = '';
    switch (key) {
      case 'passwordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noPasswordError';
        } else if (isPasswordFormatValid(value)) {
          errorMsg = 'errors.invalidFormat';
        }
        break;
      case 'confirmPasswordError':
        if (value.length <= 0) {
          errorMsg = 'errors.noConfirmPasswordError';
        } else if (password !== confirmPassword) {
          errorMsg = 'errors.passwordsError';
        }
        break;
      default:
        errorMsg = '';
        break;
    }
    this.setState({
      [key]: errorMsg,
    });
    return errorMsg;
  };

  handleVerifyChange = (vals) => {
    this.setState({
      verificationCode: vals,
    });
  };

  onVerify = (e) => {
    e.preventDefault();
    const { responseToken, verificationCode } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      http(
        {
          url: 'selfservice/reset?_action=submitRequirements',
          data: {
            input: {
              verificationCode: verificationCode,
            },
            token: responseToken,
          },
          method: 'POST',
        },
        {
          'X-OpenIDM-NoSession': true,
          'X-OpenIDM-Username': 'anonymous',
          'X-OpenIDM-Password': 'anonymous',
        }
      ).then((res) => {
        if (
          res.status === 200 &&
          res.data &&
          res.data.type === 'resetStage' &&
          res.data.tag === 'initial'
        ) {
          this.setState({
            responseToken: res.data.token,
            screen: 4,
          });
          //this.increaseCurrentStepHandler();
        } else if (
          res.status === 200 &&
          res.data &&
          res.data.type === 'codeValidation' &&
          res.data.tag === 'VALIDATE_CODE'
        ) {
          this.setState({
            verificationCodeError: 'pages.forgotUserId.invalidVerificationCode',
          });
          const token = getNestedObject(res, ['data', 'token']);
          if (token) {
            this.setState({
              responseToken: token,
            });
          }
        } else if (
          res.status === 400 &&
          res.data &&
          res.data.code === 400 &&
          res.data.message === 'MAX_RETRY_EXCEEDED'
        ) {
          this.setState({
            screen: 0,
            currentStep: 0,
            verificationOption: '',
            userEmail: '',
            userPhone: '',
            selectedOption: '',
            verificationCodeError: '',
            verificationCode: '',
            kba: '',
            securityOption: '',
            securityQuestionValue: '',
            securityQuestionError: '',
            userExist: false,
            radioOptionError: '',
          });
          this.onErrorHandler('error', res.data.message);
        }
      });
    }
  };

  onVerifyQuestion = (e) => {
    e.preventDefault();
    const { responseToken, securityQuestionValue } = this.state;
    if (securityQuestionValue === '') {
      this.setState({
        securityQuestionError: 'pages.forgotUserId.securityQuestionRequired',
      });
    } else {
      http(
        {
          url: 'selfservice/reset?_action=submitRequirements',
          data: {
            input: {
              answer1: securityQuestionValue,
            },
            token: responseToken,
          },
          method: 'POST',
        },
        {
          'X-OpenIDM-NoSession': true,
          'X-OpenIDM-Username': 'anonymous',
          'X-OpenIDM-Password': 'anonymous',
        }
      ).then((res) => {
        if (
          res.status === 200 &&
          res.data &&
          res.data.type === 'resetStage' &&
          res.data.tag === 'initial'
        ) {
          this.setState({
            responseToken: res.data.token,
            screen: 4,
            securityQuestionError: '',
          });
          //this.increaseCurrentStepHandler();
        } else if (
          res.status === 200 &&
          res.data &&
          res.data.type === 'codeValidation' &&
          res.data.tag === 'VALIDATE_CODE'
        ) {
          this.setState({
            securityQuestionError: 'pages.forgotUserId.invalidSecurityAnswer',
          });
          const token = getNestedObject(res, ['data', 'token']);
          if (token) {
            this.setState({
              responseToken: token,
            });
          }
        }
      });
    }
  };

  onErrorHandler = (status, message) => {
    let alertText = '';
    if (message === 'TWILIO_ERROR') {
      alertText = 'errors.twilioError';
    } else if (
      message === 'MAX_RETRY_EXCEEDED' ||
      message === 'LimitExceededException'
    ) {
      alertText = 'Attempt limit exceeded, please try after some time.';
    } else if (
      message === 'ExpiredCodeException' ||
      message === 'CodeMismatchException'
    ) {
      alertText = 'Invalid code provided, please try again.';
    }

    if (status === 'error') {
      this.setState(
        {
          alertStatus: status,
          alertText,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alertText: '',
            });
          }, 5000);
        }
      );
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  resendVerify = (e) => {
    e.preventDefault();
    this.setState({
      verificationCode: '',
      verificationCodeError: '',
    });
    const { responseToken } = this.state;
    this.setState({
      errorText: '',
    });
    http(
      {
        url: 'selfservice/reset?_action=submitRequirements',
        data: {
          input: {
            resend: 'true',
          },
          token: responseToken,
        },
        method: 'POST',
      },
      {
        'X-OpenIDM-NoSession': true,
        'X-OpenIDM-Username': 'anonymous',
        'X-OpenIDM-Password': 'anonymous',
      }
    ).then((res) => {
      if (res.status === 200 && res.data) {
        const token = getNestedObject(res, ['data', 'token']);
        if (token) {
          this.setState({
            responseToken: token,
            verificationCode: '',
            verificationCodeError: '',
          });
        }
      } else if (
        res.status === 400 &&
        res.data &&
        res.data.code === 400 &&
        res.data.message === 'MAX_RESEND_EXCEEDED'
      ) {
        this.setState({
          screen: 0,
          currentStep: 0,
          verificationOption: '',
          userEmail: '',
          userPhone: '',
          selectedOption: '',
          verificationCodeError: '',
          verificationCode: '',
          kba: '',
          securityOption: '',
          securityQuestionValue: '',
          securityQuestionError: '',
          userExist: false,
          radioOptionError: '',
        });
        this.onErrorHandler('error', res.data.message);
      }
    });
  };

  onBackLogin = (e) => {
    e.preventDefault();

    this.setState({
      screen: 0,
      currentStep: 0,
      verificationOption: '',
      userEmail: '',
      userPhone: '',
      selectedOption: '',
      verificationCodeError: '',
      verificationCode: '',
      kba: '',
      securityOption: '',
      securityQuestionValue: '',
      securityQuestionError: '',
      userExist: false,
      radioOptionError: '',
    });
  };

  handleChange(evt) {
    const verificationCode = evt.target.validity.valid
      ? evt.target.value
      : this.state.verificationCode;
    this.setState({ verificationCode });
  }

  onRecoverPassword = (e) => {
    e.preventDefault();
    const { userName, emailError } = this.state;
    if (userName === '' || emailError) {
      this.setState({
        emailError: true,
      });
      this.showError('A valid username  is required');
    } else if (userName !== '' && !emailError) {
      this.sendVerificationCode();
      this.setState({
        errorText: '',
      });
    }
  };

  sendVerificationCode = () => {
    const { userName } = this.state;
    http(
      {
        url: '/amazonaws',
        data: {
          ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,

          Username: userName,
        },
        method: 'POST',
      },
      {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.ForgotPassword',
      }
    ).then((res) => {
      if (
        '__type' in res.data &&
        res.data['__type'] == 'LimitExceededException'
      ) {
        this.onErrorHandler('error', res.data['__type']);
      } else {
        let encryptedMail = res.data.CodeDeliveryDetails.Destination;

        this.setState({
          screen: 1,

          verificationOption: encryptedMail,
        });
        this.increaseCurrentStepHandler();
      }
    });
  };

  getPassword = () => {
    const { verificationCode } = this.state;
    if (verificationCode === '') {
      this.setState({
        verificationCodeError: 'pages.forgotUserId.verificationCodeRequired',
      });
    } else {
      this.setState({
        verificationCodeError: '',
      });
      this.setState({
        screen: 2,
      });
    }
  };

  submitNewPassword = (e) => {
    e.preventDefault();
    const {
      password,
      confirmPassword,
      verificationCode,
      userName,
    } = this.state;
    const hasPassword = this.isFormFieldValid(password, 'passwordError');
    const hasConfirmPassword = this.isFormFieldValid(
      confirmPassword,
      'confirmPasswordError'
    );
    if (hasPassword || hasConfirmPassword) {
      this.showError('errors.required');
    } else {
      http(
        {
          url: '/amazonaws',
          data: {
            ClientId: `${process.env.REACT_APP_COGNITO_ClientID}`,

            Username: userName,

            ConfirmationCode: verificationCode,
            Password: password,
          },
          method: 'POST',
        },
        {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target':
            'AWSCognitoIdentityProviderService.ConfirmForgotPassword',
        }
      ).then((res) => {
        if (res.status === 200) {
          this.setState({
            screen: 3,
          });
        } else {
          if (
            ('__type' in res.data &&
              res.data['__type'] == 'ExpiredCodeException') ||
            res.data['__type'] == 'CodeMismatchException'
          ) {
            this.onErrorHandler('error', res.data['__type']);
            this.setState({
              screen: 1,
            });
          }
        }
        /*
      const failedPolicyRequirements = getNestedObject(res, [
        'data',
        'requirements',
        'error',
        'detail',
        'failedPolicyRequirements',
      ]);
      if (failedPolicyRequirements) {
        if (failedPolicyRequirements.length > 0) {
          this.setState({
            errorArray: failedPolicyRequirements,
          });
          window.scrollTo({
            behavior: 'smooth',
            top: 0,
          });
        }
      } else if (res.status === 200 && res.data && res.data.status.success) {
        this.increaseCurrentStepHandler();
        this.setState({
          screen: 5,
          passwordErrorText: '',
          errorArray: '',
        });
      }

      */
      });
    }
  };

  render() {
    const {
      mail,
      emailError,
      currentStep,
      maxStep,
      stepNames,
      screen,
      showGoogleCaptcha,
      googleCaptchaToken,
      captchaReset,
      userName,
      selectedOption,
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      verificationOption,
      verificationCodeError,
      userEmail,
      userPhone,
      kba,
      securityOption,
      securityQuestionError,
      userExist,
      verificationCode,
      alertText,
      alertStatus,
      radioOptionError,
      passwordErrorText,
      errorArray,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        {alertText}
        {alertText && (
          <Alert
            alertText={alertText}
            className={alertStatus}
            onAlertClose={() => {
              this.setState({
                alertText: '',
              });
            }}
          />
        )}
        <div className="container forgot-username forgot-password my-5">
          {(passwordErrorText || errorArray.length > 0) && (
            <WithError errors={passwordErrorText} errorArray={errorArray} />
          )}
          <div className="col">
            <h1>{t('pages.forgotPassword.header1ForgotPassword')}</h1>
          </div>
          <div>
            <FormNavigationBar
              currentStep={currentStep}
              numFormSteps={maxStep + 1}
              stepNames={stepNames}
              t={t}
            />
          </div>
          {currentStep === 0 && screen === 0 && (
            <>
              <div className="col">
                <h3 className="m-0">
                  {t('pages.forgotPassword.instructionsCognito')}
                </h3>
              </div>
              <Form className="col-md-6 mt-3 pt-4">
                <Form.Group controlId="loginEmail">
                  <Form.Label>{t('pages.registration.userName')}</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      this.setState({
                        userName: e.target.value.trim().toLowerCase(),
                      });
                    }}
                    value={userName}
                    className={
                      emailError || userExist ? 'error-input' : undefined
                    }
                    aria-required
                    aria-invalid={!!emailError}
                    aria-describedby="email-error"
                  />
                  <p
                    className="error-text forgot-error-username"
                    id="email-error"
                  >
                    {emailError && t('errors.emailError')}
                    {userExist && t('errors.userExist')}
                  </p>
                </Form.Group>
                <div className="row mt-5">
                  <div className="d-flex col-md-6">
                    <Button
                      className="wrapper__container-card-body-submit"
                      size="lg"
                      variant="secondary"
                      block
                      type="submit"
                      onClick={(e) => this.onRecoverPassword(e)}
                    >
                      {t('pages.forgotPassword.nextBtn')}
                    </Button>
                  </div>
                </div>
                <div>
                  <p className="return-text mt-4">
                    {t('pages.forgotUserId.returnTo')}
                    <span className="bgColor">
                      <a
                        href="#"
                        id="registerPolicy"
                        className="pl-2"
                        onClick={(e) => this.decreaseCurrentStepHandler(e)}
                      >
                        {t('pages.forgotUserId.loginBtn')}
                      </a>
                    </span>
                  </p>
                </div>
              </Form>
            </>
          )}

          {currentStep === 1 && screen === 1 && verificationOption && (
            <div className="col-md-12 registration__container">
              <h3>
                <span>{t('pages.forgotPassword.header2')}</span>
              </h3>
              <div className="registration__container__resend-verification">
                <div className="registration__container__resend-verification__resend-text">
                  <p>
                    {t('pages.registration.verificationCodeSentTo')}{' '}
                    {verificationOption}
                  </p>
                  <p
                    className="resend-link"
                    onClick={(e) => this.onRecoverPassword(e)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('pages.registration.resendVerificationCode')}
                  </p>
                </div>
              </div>
              <Form>
                <Form.Group
                  className="verification-form"
                  controlId="verificationCode"
                >
                  <Form.Label className="mb-4">
                    {t('pages.registration.verificationCode')}
                  </Form.Label>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onInput={this.handleChange.bind(this)}
                    className={`ml-3 verification-input ${
                      verificationCodeError ? 'error-verification' : undefined
                    }`}
                    value={verificationCode}
                  />
                  {verificationCodeError && (
                    <p className="error-text" id="verification-error">
                      {verificationCodeError && t(verificationCodeError)}
                    </p>
                  )}
                </Form.Group>
                <div className="registration__container__pleasenote">
                  <p className="registration__container__pleasenote-fp">
                    {t('pages.registration.note')}
                  </p>
                  <p className="registration__container__pleasenote-sp">
                    {t('pages.registration.verifyNote')}
                  </p>
                </div>
                <div className="row pt-3">
                  <div className="d-sm-flex col-lg-6 col-md-12">
                    <button
                      className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                      onClick={(e) => this.onBackLogin(e)}
                      variant="primary"
                      block
                    >
                      {t('pages.forgotPassword.backBtn')}
                    </button>
                    <button
                      className="col btn btn-secondary mr-sm-4 py-2 my-3"
                      onClick={(e) => this.getPassword(e)}
                    >
                      {t('pages.registration.verify')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}

          {currentStep === 1 && screen === 2 && (
            <>
              <Col className="pr-2" lg={{ span: 6 }}>
                <Form.Group controlId="passWord">
                  <Form.Label>{t('pages.registration.password')}</Form.Label>
                  <PasswordField
                    onChange={(value) => {
                      this.setState({
                        password: value,
                      });
                    }}
                    onBlur={(e) => {
                      this.isFormFieldValid(e, 'passwordError');
                    }}
                    password={password}
                    className={passwordError ? 'error-input' : undefined}
                    ariaInvalid={!!passwordError}
                    ariaDescribedby="new-password-error"
                    myRef={this.passwordInputRef}
                    onCopy={(e) => {
                      this.disableCopyPaste(e);
                    }}
                    onPaste={(e) => {
                      this.disableCopyPaste(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="pr-2" lg={{ span: 6 }}>
                <Form.Group controlId="confirmPassWord">
                  <Form.Label>
                    {t('pages.registration.confirmPassword')}
                  </Form.Label>
                  <PasswordField
                    onChange={(value) => {
                      this.setState(
                        {
                          confirmPassword: value,
                        },
                        () => {
                          this.isFormFieldValid(value, 'confirmPasswordError');
                        }
                      );
                    }}
                    onBlur={(e) => {
                      this.isFormFieldValid(e, 'confirmPasswordError');
                    }}
                    password={confirmPassword}
                    className={confirmPasswordError ? 'error-input' : undefined}
                    ariaInvalid={!!confirmPasswordError}
                    ariaDescribedby="confirm-password-error"
                    myRef={this.confirmPasswordInputRef}
                    onCopy={(e) => {
                      this.disableCopyPaste(e);
                    }}
                    onPaste={(e) => {
                      this.disableCopyPaste(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <div className="pl-1 pt-2">
                <PasswordRequirements
                  password={password}
                  formSubmitted={false}
                />
              </div>
              <div className="row pt-3">
                <div className="d-sm-flex col-lg-6 col-md-12">
                  <button
                    className="col btn btn-secondary hollow mr-sm-4 py-2 my-3"
                    onClick={(e) => this.onBackLogin(e)}
                  >
                    {t('pages.forgotPassword.backBtn')}
                  </button>

                  <button
                    className="col btn btn-secondary  py-2 my-3"
                    onClick={(e) => this.submitNewPassword(e)}
                  >
                    {t('pages.forgotPassword.nextBtn')}
                  </button>
                </div>
              </div>
            </>
          )}
          {currentStep === 1 && screen === 3 && (
            <>
              <SuccessConfirmation
                header={t('pages.forgotUserId.successBannerHeader')}
                successMessage={t('pages.forgotPassword.password')}
                username={userName}
              />
              <div className="row mt-5">
                <div className="d-flex col-md-4">
                  <Button
                    className="wrapper__container-card-body-submit"
                    size="lg"
                    variant="secondary"
                    block
                    type="submit"
                    onClick={(e) => this.sendToLogin(e)}
                  >
                    {t('pages.forgotUserId.loginBtn')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

ForgotPasswordCognito.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
};
export default withTranslation()(ForgotPasswordCognito);
