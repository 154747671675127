import React from 'react';
import { useTranslation } from 'react-i18next';
import 'app/modules/Shared/PasswordRequirements/PasswordRequirements.scss';

const PasswordRequirements = () => {
  const { t } = useTranslation();
  return (
    <ul className="password-terms">
      <li>{t('pages.changePassword.eightCharacters')}</li>
      <li>{t('pages.changePassword.invalidPasswordPersonalInfo')}</li>
      <li>{t('pages.changePassword.noDictionaryWords')}</li>
      <li>{t('pages.changePassword.noConsecutiveWords')}</li>
    </ul>
  );
};

export default PasswordRequirements;
