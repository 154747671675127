import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Tab,
  Tabs,
  Breadcrumb,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faChevronDown, faEye } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'react-lottie';
import idProofingAnimation from 'assets/json/idproofing_banner.json';
import bulletAnimation from 'assets/json/bullet.json';
import 'app/modules/Dashboard/ProfileLeftMenu.scss';
import 'app/modules/Dashboard/Dashboard.scss';
import 'app/modules/Dashboard/Apps.scss';
import 'app/modules/Dashboard/Wallet.scss';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import Cookies from 'js-cookie';
import 'app/modules/IdProofing/IdProofingBanner.scss';
import http from 'api/http-request';
import aegisIMG from 'assets/img/apple-touch-icon.png';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';
import uniLogin from 'assets/img/uniLogin.png';
import WithError from 'app/modules/Shared/WithError/WithError';


const Wallet = () => {
  const paymentTypes = [
    {
      cardHolder: 'John Smith',
      cardNumber: '4012888888881881',
      ccv: '123',
      expDate: '08/23',
      last4Digits: '1234',
      paymentType: 'Visa',
    },
    {
      cardHolder: 'John Smith',
      cardNumber: '5105105105105100',
      ccv: '321',
      expDate: '04/22',
      last4Digits: '5678',
      paymentType: 'Mastercard',
    },
  ];

  const { t } = useTranslation();
  const [newPaymentMethod, setNewPaymentMethod] = useState(false);
  const [hiddenCardNumList, setHiddenCardNumList] = useState(
    paymentTypes.map(() => true)
  );
  const [hiddenCVVList, setHiddenCVVList] = useState(
    paymentTypes.map(() => true)
  );

  const [showBannerLocalStorage, setShowBannerLocalStorage] = useState(false);
  const [idProofing, setIdProofing] = useState(false);
  const [pingOne, setPingOne] = useState(Cookies.get('walletRegistered'));
  const [documentIssued, setDocumentIssued] = useState();
  const [documentIssuedwoConsent, setDocumentIssuedwoConsent] = useState();

  const createNewPaymentMethod = () => {
    setNewPaymentMethod(true);
  };

  const cancelNewPaymentMethod = () => {
    setNewPaymentMethod(false);
  };
  const [firstTimeFetch, setFirstTimeFetch] = useState(true);

  const [showButtonForPass, setShowButtonForPass] = useState(true);
  const [idProofingPingPopup, setidProofingPingPopup] = useState(false);
  const [credentialIssued, setCredentialIssued] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [showBarCode, setShowBarCode] = useState(false);
  const [count, setCount] = useState(60);
  const [url, setUrl] = useState('');
  const [authID, setauthID] = useState('');
  const [credTransfer, setCredTransfer] = useState(false);
  const [walletChoiceSelector, setWalletChoiceSelector] = useState(false);
  const [walletChoice, setWalletChoice] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [qrURL, setqrURL] = useState('');
  const [consentPopup, setConsentPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState('');
  const [mandatoryAttributesforConsent, setMandatoryAttributesforConsent] =
    useState([]);
  const [attributesforConsent, setAttributesforConsent] = useState([]);
  const [isMailSelected, setIsMailSelected] = useState(false);
  const [mailNotSelectedMessage, setMailNotSelectedMessage] = useState(false);
  const [walletNotSelectedMessage, setWalletNotSelectedMessage] =
    useState(false);
  const [errorText, setErrorText] = useState();
  const [errorArray, setErrorArray] = useState([]);
  const [qRError, setQRError] = useState(false);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let newSelectedValues = '';

    if (value == 'Email') {
      setIsMailSelected(true);
      setMailNotSelectedMessage(false);
    }
    if (checked) {
      newSelectedValues = selectedValues
        ? `${selectedValues}, ${value}`
        : value;
    } else {
      const selectedArray = selectedValues
        .split(', ')
        .filter((selectedValue) => selectedValue !== value);
      newSelectedValues = selectedArray.join(', ');
    }

    setSelectedValues(newSelectedValues);
  };

  const showError = (errorText) => {
    setErrorText(errorText)
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const cardNumberFormatter = (cardNumber) => {
    return cardNumber.match(/.{1,4}/g).join(' ');
  };

  const codeText = (text, method) => {
    if (method === 'card') {
      return text.replace(/.(?=.{4})/g, '*');
    } else if (method === 'cvv') {
      return text.replace(/.(?=.{0})/g, '*');
    }
  };

  const hiddenCardNumHandler = (index) => {
    setHiddenCardNumList(
      hiddenCardNumList.map((bool, i) => (index === i ? !bool : bool))
    );
  };

  const hiddenCVVHandler = (index) => {
    setHiddenCVVList(
      hiddenCVVList.map((bool, i) => (index === i ? !bool : bool))
    );
  };

  const hideAll = () => {
    setHiddenCardNumList(hiddenCVVList.map(() => true));
    setHiddenCVVList(hiddenCVVList.map(() => true));
  };

  useEffect(() => {
    searchAPI();
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });

    if (firstTimeFetch == true) {
      fetchCred();
      setFirstTimeFetch(false);
    }

    let token = Cookies.get('dataStoreToken');
    let id = Cookies.get('user_id');

    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setShowBarCode(false);
          return 0;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const searchAPI = () => {
    let username = Cookies.get('username');

    let iPlanetDirectoryPro = Cookies.get('iPlanetDirectoryPro');

    let data = {
      pattern: username,

      attribute: 'userName',

      operation: 'eq',
      iPlanetDirectoryPro: iPlanetDirectoryPro,
    };

    http({
      url: '/aegis-managed-service/aegis/api/searchUser',
      method: 'POST',
      data: data,
    }).then((res) => {
      if (
        res.status == 401 &&
        ((res.data.iamcode == 'IAM0028' &&
          res.data.response == 'Access Denied') ||
          (res.data.iamcode == 'IAM0039' &&
            res.data.response == 'Unauthorized'))
      ) {
        Cookies.remove('username');
        Cookies.remove('admin');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        window.location.href = sanitizeUrl('/');
      } else if (res.status == 200) {
        setUserDetails(res.data.data.result[0]);
        if (res.data.data.result[0].idProofData) {
          Cookies.set('walletRegistered', true);
          setPingOne(true);

          let data = res.data.data.result[0].idProofData
            .split('|')
            .map((item) => JSON.parse(item));

          if (data[0].dataConsent == 'allowed') {
            setDocumentIssued(
              res.data.data.result[0].idProofData
                .split('|')
                .map((item) => JSON.parse(item))
            );
          } else {
            setDocumentIssuedwoConsent(
              res.data.data.result[0].idProofData
                .split('|')
                .map((item) => JSON.parse(item))
            );
          }
        }
      }
    });
  };

  const getIdentityPass = (e) => {
    e.preventDefault();

    // const des = userDetails.idProofData
    //   .split('|')
    //   .map((item) => JSON.parse(item));

    const payLoad = {
      lastName: userDetails.sn,
      firstName: userDetails.givenName,

      // lastName: des[0].lastName,
      // firstName: des[0].firstName,
      username: userDetails.userName,
      email: userDetails.mail,
      uid: userDetails._id,
      jobTitle:
        userDetails.jobTitle != null ? userDetails.jobTitle : 'specialist',
      telephone:
        userDetails.telephone != null
          ? userDetails.telephoneNumber
          : '1425367890',
    };
    http({
      url: `credential`,
      data: payLoad,
      method: 'POST',
    }).then((res) => {
      if (res.status === 200) {
        setShowBarCode(true);
        setCount(60);
        setUrl(res.data);
      }
    });
  };

  const revokeCredentials = (e, username, credId) => {
    e.preventDefault();

    http({
      url: `https://digitalwallet-dev.deloitte.com/credential-offer?username=${username}&credentialId=${credId}`,
      method: 'PUT',
    }).then((res) => {
      if (res.status === 200) {
        setShowButtonForPass(true);
        setCredentialIssued(null);
      }
    });
  };

  const fetchCred = () => {
    setShowBarCode(false);

    let user_id = Cookies.get('user_id');
    let fetchurl = `https://digitalwallet-dev.deloitte.com/agency-credential-offers?uid=${user_id}`;

    http({
      url: fetchurl,
      method: 'POST',
    }).then((res) => {
      let arr = [];
      if (res.status === 200) {
        arr = res.data;
        if (arr.length != 0) {
          setShowButtonForPass(false);
          setCredentialIssued(res.data);
        }
      } else {
        setShowButtonForPass(false);
      }
    });
  };

  const onuniIdQR = (e) => {
    e.preventDefault();

    if (Cookies.get('walletRegistered') == 'true') {
      getIdentityPass(e);
    } else {
      setidProofingPingPopup(true);
    }
  };

  const handleClose = (e) => {
    setCredTransfer(false);
    setConsentPopup(false);
    setWalletChoiceSelector(false);
    setidProofingPingPopup(false);
  };

  const credentialPortability = (e) => {
    e.preventDefault();
    setCredTransfer(false);
    getWalletChoices(e);
  };

  const getWalletChoices = (e) => {
    e.preventDefault();

    http({
      url: 'credentialPortability',
      method: 'POST',
      data: {
        iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
        Email: userDetails.mail,
      },
    }).then((res) => {
      if (res.status === 200 && res.data.iamcode === 'IAM000') {
        setWalletChoice(res.data.data.callbacks[0].output[1].value);
        setauthID(res.data.data.authId);
        setWalletChoiceSelector(true);
      } else{
        showError('No active session found');
        Cookies.remove('iPlanetDirectoryPro', { domain: '.aegis-frk.com' });
        setTimeout(() => {
          window.location.href = sanitizeUrl(`/`);
        }, 3000);
      }
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
  };

  const getCredentialTransferQR = (e) => {
    e.preventDefault();

    if (selectedValue == '') {
      setWalletNotSelectedMessage(true);
    } else {
      setWalletChoiceSelector(false);

      http({
        url: 'credentialPortability',
        method: 'POST',
        data: {
          iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
          wallet: selectedValue,
          authId: authID,
        },
      }).then((res) => {
        if (res.status === 200 && res.data.iamcode == 'IAM000') {
          setauthID(res.data.data.authId);
          setqrURL(res.data.data.callbacks[0].output[0].value);
          setCredTransfer(true);
        } else {
          showError('This wallet is not available right now. Please try again.');
          setTimeout(() => {
            setErrorText();
          }, 3000);
        }
      });
    }
  };

  const getCredentialTransferQRforIssuance = (e) => {
    e.preventDefault();
    setCredTransfer(false);
    setQRError(false);
    setMailNotSelectedMessage(false);
    http({
      url: 'credentialPortability',
      method: 'POST',
      data: {
        iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
        qrURL: qrURL,
        authId: authID,
      },
    }).then((res) => {
      if (res.status === 200 && res.data.iamcode == 'IAM000') {
        if (
          res.data.data.callbacks[0].output[0].value ==
          'Please scan the QR code'
        ) {
          setMailNotSelectedMessage(false);
          setQRError(true);
          setauthID(res.data.data.authId);
          setConsentPopup(false);
          http({
            url: 'credentialPortability',
            method: 'POST',
            data: {
              iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
              reIssue: 'pingQR',
              authId: res.data.data.authId,
            },
          }).then((res) => {
            if (res.status === 200 && res.data.iamcode == 'IAM000') {
              setauthID(res.data.data.authId);
              setqrURL(res.data.data.callbacks[0].output[0].value);
              setCredTransfer(true);
            } else {
              console.log('Please reshare Credential');
            }
          });
        } else {
          let attributes = JSON.parse(
            res.data.data.callbacks[0].output[0].value
          );
          setMandatoryAttributesforConsent(
            attributes.wallet_mandatory_attribute
          );
          setAttributesforConsent(attributes.personalInformation);
          setauthID(res.data.data.authId);
          setConsentPopup(true);
        }
      } else {
        console.log('Please reshare Credential');
      }
    });
  };

  const reIssueQR = (e) => {
    e.preventDefault();
    setCredTransfer(false);
    setMailNotSelectedMessage(false);
    setQRError(false);
    http({
      url: 'credentialPortability',
      method: 'POST',
      data: {
        iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
        qrURL: qrURL,
        authId: authID,
      },
    }).then((res) => {
      if (res.status === 200 && res.data.iamcode == 'IAM000') {
        if (
          res.data.data.callbacks[0].output[0].value ==
          'Please scan the QR code'
        ) {
          setMailNotSelectedMessage(false);
          setauthID(res.data.data.authId);
          setConsentPopup(false);
          http({
            url: 'credentialPortability',
            method: 'POST',
            data: {
              iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
              reIssue: 'pingQR',
              authId: res.data.data.authId,
            },
          }).then((res) => {
            if (res.status === 200 && res.data.iamcode == 'IAM000') {
              setauthID(res.data.data.authId);
              setqrURL(res.data.data.callbacks[0].output[0].value);
              setCredTransfer(true);
            } else {
              console.log('Please reshare Credential');
            }
          });
        } else {
          let attributes = JSON.parse(
            res.data.data.callbacks[0].output[0].value
          );
          setMandatoryAttributesforConsent(
            attributes.wallet_mandatory_attribute
          );
          setAttributesforConsent(attributes.personalInformation);
          setauthID(res.data.data.authId);
          setConsentPopup(true);
        }
      } else {
        console.log('Please reshare Credential');
      }
    });
  };

  const setSelectedAttributes = (e) => {
    e.preventDefault();
    if (isMailSelected) {
      setConsentPopup(false);
      http({
        url: 'credentialPortability',
        method: 'POST',
        data: {
          iPlanetDirectoryPro: Cookies.get('iPlanetDirectoryPro'),
          consent: selectedValues,
          authId: authID,
        },
      }).then((res) => {
        if (res.status === 200 && res.data.iamcode == 'IAM000') {
          setSelectedValues('');
          setIsMailSelected(false);
          console.log(res.data.data.url);
          setConsentPopup(false);
          setCount(60);
          setUrl(res.data.data.url);
          setShowBarCode(true);
        }
      });
    } else {
      setMailNotSelectedMessage(true);
    }
  };
  
  const banner = {
    loop: true,
    autoplay: true,
    animationData: idProofingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const bullet = {
    loop: true,
    autoplay: true,
    animationData: bulletAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const paymentsHTML = () => {
    if (!newPaymentMethod) {
      return (
        <div className="container px-3 px-sm-0 mx-sm-0">
          <div className="my-5">
            <div className="row m-0 mx-sm-5">
              <Accordion className="w-100">
                {paymentTypes.map((paymentTypeObj, index) => (
                  <Card
                    key={index}
                    className="mb-4 box-shadow-default wallet-card-container"
                  >
                    <Card.Header className="bg-white">
                      <div className="row align-items-center">
                        <div className="payment-logo col-2">
                          {paymentTypeObj.paymentType.toLowerCase() ===
                            'visa' && <FontAwesomeIcon icon={faCcVisa} />}
                          {paymentTypeObj.paymentType.toLowerCase() ===
                            'mastercard' && (
                            <FontAwesomeIcon icon={faCcMastercard} role="img" />
                          )}
                        </div>
                        <div className="col-10">
                          <div className="row align-items-center">
                            <div className="col-7">
                              <p className="m-0">
                                {`${paymentTypeObj.paymentType} ${t(
                                  'pages.wallet.endingIn'
                                )} ${paymentTypeObj.last4Digits}`}
                              </p>
                            </div>
                            <div className="col-3 text-right p-0">
                              Exp {paymentTypeObj.expDate}
                            </div>
                            <div className="col-2 text-right pl-0 pr-0 pl-md-3 pr-md-0">
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={index + 1}
                                onClick={hideAll}
                              >
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="black"
                                  role="button"
                                />
                              </Accordion.Toggle>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index + 1}>
                      <Card.Body>
                        <div className="row mb-3 mb-sm-0 align-items-center">
                          <p className="col-4 m-0 pl-1 pr-1 px-sm-3">
                            {t('pages.wallet.cardholder')}:
                          </p>
                          <p className="col-6 m-0 text-right m-0 p-0 px-sm-2">
                            {paymentTypeObj.cardHolder}
                          </p>
                        </div>
                        <div className="row mb-3 mb-sm-0 align-items-center">
                          <p className="col-4 m-0 pl-1 pr-1 px-sm-3">
                            {t('pages.wallet.cardNumber')}:
                          </p>
                          <p className="col-6 text-right m-0 p-0 px-sm-2">
                            {cardNumberFormatter(
                              hiddenCardNumList[index]
                                ? codeText(paymentTypeObj.cardNumber, 'card')
                                : paymentTypeObj.cardNumber
                            )}
                          </p>
                          <div className="col-2 m-0">
                            <Button
                              className="bg-transparent border-0"
                              onClick={() => hiddenCardNumHandler(index)}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                role="button"
                              ></FontAwesomeIcon>
                            </Button>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <p className="col-4 m-0 pl-1 pr-1 px-sm-3">CVV:</p>
                          <p className="col-6 text-right m-0 p-0 px-sm-2">
                            {hiddenCVVList[index]
                              ? codeText(paymentTypeObj.ccv, 'cvv')
                              : paymentTypeObj.ccv}
                          </p>
                          <p className="col-1 m-0">
                            <Button
                              className="bg-transparent border-0"
                              onClick={() => hiddenCVVHandler(index)}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                role="button"
                              ></FontAwesomeIcon>
                            </Button>
                          </p>
                        </div>
                        <div className="row mt-4">
                          <div className="px-1 px-sm-3">
                            <Button className="btn btn-danger py-2 my-3">
                              {t('pages.wallet.deleteCard')}
                            </Button>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
            <div className="row mx-0 mx-sm-5">
              <Button
                className="btn btn-secondary py-2 my-3"
                onClick={createNewPaymentMethod}
              >
                {t('pages.wallet.addNewPaymentMethodBtn')}
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="m-5">
          <div className="row m-0">
            <h2 className="mb-4">{t('pages.wallet.addNewPaymentMethodBtn')}</h2>
          </div>
          <div className="row">
            <form className="col-lg-6 col-md-10">
              <div className="row form-group">
                <div className="col-md-6">
                  <label htmlFor="card-holder-name" className="col-form-label">
                    {t('pages.wallet.cardholderName')}:
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    id="card-holder-name"
                    className="form-control"
                  ></input>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label htmlFor="card-number" className="col-form-label">
                    {t('pages.wallet.cardNumber')}:
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    id="card-number"
                    className="form-control"
                  ></input>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label htmlFor="expiration-date" className="col-form-label">
                    {t('pages.wallet.expirationDate')}:
                  </label>
                </div>
                <div className="col-md-6 d-flex justify-content-md-end align-items-center">
                  <input
                    type="text"
                    id="expiration-date"
                    placeholder="MM/YY"
                    className="expiration-date form-control mr-2"
                  ></input>
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarAlt}
                    role="img"
                  ></FontAwesomeIcon>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label htmlFor="cvv" className="col-form-label">
                    CVV:
                  </label>
                </div>
                <div className="col-md-6">
                  <input type="text" id="cvv" className="form-control"></input>
                </div>
              </div>
            </form>
          </div>
          <div className="row m-0">
            <Button
              className="btn btn-secondary btn-small my-3 mr-3"
              onClick={createNewPaymentMethod}
            >
              {t('pages.wallet.add')}
            </Button>
            <Button
              className="btn btn-secondary hollow btn-small my-3"
              onClick={cancelNewPaymentMethod}
            >
              {t('pages.wallet.cancel')}
            </Button>
          </div>
        </div>
      );
    }
  };

  const myDocumentsHTML = () => {
    if (pingOne === true) {
      return (
        <>
          <div className="my-5" style={{ width: '100%' }}>
            {showBarCode && (
              <Modal
                show={showBarCode}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <p>
                      Open your <strong>Digital Wallet</strong> scanner and scan
                      the QR Code shown beside within {count} seconds and store
                      your Identity Pass in your Digital Wallet.
                    </p>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ textAlign: 'center' }}>
                    <QRCode value={url} />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={(e) => fetchCred()}>Close</Button>
                </Modal.Footer>
              </Modal>
            )}
            <div className="grid-container">
              <div className="grid-item item1">
                <div className="row mx-5 mb-5 d-flex justify-content-center align-items-center">
                  <p>
                    <b>List of ID Proofed Documents - Aegis</b>
                  </p>
                </div>
                {documentIssued
                  ?.reduce((rows, documentsIssued, index) => {
                    if (index % 3 === 0) {
                      rows.push([]);
                    }

                    rows[rows.length - 1].push(
                      <div className="col-lg-3 col-md-3 mx-5" key={index}>
                        <div className="col-lg-1 ">
                          <div className="crad-v">
                            <div className="row">
                              <div className="col-lg-3 col-3 text-center">
                                <div className="imglogo-v">
                                  <img
                                    src={aegisIMG}
                                    className="com-logo"
                                    alt="Company Logo"
                                  />
                                </div>
                                <div className="imglogo-v">
                                  <b>{documentsIssued?.docType}</b>
                                </div>
                              </div>
                              <div className="col-lg-9 col-9">
                                <div className="alldetail">
                                  <div className="op">
                                    <strong className="allDetail-font">
                                      First Name:
                                    </strong>{' '}
                                    {documentsIssued?.firstName}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Last Name:
                                    </strong>{' '}
                                    {documentsIssued?.lastName}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Date of Birth:
                                    </strong>{' '}
                                    {documentsIssued?.dOb}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Gender:
                                    </strong>{' '}
                                    {documentsIssued?.gender}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Document ID:
                                    </strong>{' '}
                                    {documentsIssued?.docID}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Expiration Date:
                                    </strong>{' '}
                                    {documentsIssued?.docExp}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Country:
                                    </strong>{' '}
                                    {documentsIssued?.country}
                                  </div>
                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Aegis ID:
                                    </strong>{' '}
                                    {documentsIssued?.frID}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Issuer ID:
                                    </strong>{' '}
                                    {documentsIssued?.issuerId}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Issuer Name:
                                    </strong>{' '}
                                    {documentsIssued?.issuerName}
                                  </div>

                                  <div className="op">
                                    <strong className=" allDetail-font">
                                      Issued On:
                                    </strong>{' '}
                                    {documentsIssued?.eventTimestamp}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );

                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                      {row}
                    </div>
                  ))}

                {documentIssuedwoConsent
                  ?.reduce((rows, documentIssuedwoConsent, index) => {
                    if (index % 3 === 0) {
                      rows.push([]);
                    }

                    rows[rows.length - 1].push(
                      <div className="col-lg-3 col-md-3 mx-5" key={index}>
                        <div className="row">
                          <div className="col-lg-1 ">
                            <div className="crad-v">
                              <div className="row">
                                <div className="col-lg-3 col-3 text-center">
                                  <div className="imglogo-v">
                                    <img
                                      src={aegisIMG}
                                      className="com-logo"
                                      alt="Company Logo"
                                    />
                                  </div>
                                  <div className="imglogo-v">
                                    <b>{documentIssuedwoConsent?.docType}</b>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <div className="text-vcard">
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Aegis ID:
                                        </strong>{' '}
                                        {documentIssuedwoConsent?.frID}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Issuer ID:
                                        </strong>{' '}
                                        {documentIssuedwoConsent?.issuerId}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Issuer Name:
                                        </strong>{' '}
                                        {documentIssuedwoConsent?.issuerName}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Issued On:
                                        </strong>{' '}
                                        {
                                          documentIssuedwoConsent?.eventTimestamp
                                        }
                                      </div>
                                    </div>
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Expiration Date:
                                        </strong>{' '}
                                        {documentIssuedwoConsent?.docExp}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );

                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                      {row}
                    </div>
                  ))}

                <div className="row mx-3 mb-5">
                  <div className="col-4 p-0 d-flex justify-content-center align-items-center">
                    <a href="https://auth.pingone.com/916fd1e1-7806-45f1-9663-bbe43dd7e5aa/as/authorize?response_type=code&client_id=7f12e526-c87c-40f3-a81d-a20bf50c3a53&scope=openid&redirect_uri=https://demoui.aegis-frk.com/profile/Wallet">
                      <Button className="btn btn-secondary py-2 my-1">
                        {t('pages.walletDocuments.addMore')}
                      </Button>
                    </a>
                  </div>

                  <div className="col-4 p-0 d-flex justify-content-center align-items-center"></div>

                  <div className="col-4 p-0 d-flex justify-content-center align-items-center">
                    <Button className="btn btn-secondary py-2 my-3">
                      {t('pages.walletDocuments.manageDocumentsBtn')}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="grid-item item2">
                <div className="row mx-5 mb-5 d-flex justify-content-center align-items-center">
                  <p>
                    <b>List of issued credentials - Unilogin</b>
                  </p>
                </div>

                {showButtonForPass && (
                  <div className="row mx-3 mb-5 d-flex justify-content-center">
                    <Button
                      className="btn btn-secondary btn btn-primary"
                      onClick={(e) => onuniIdQR(e)}
                    >
                      Get your Identity Pass
                    </Button>
                  </div>
                )}

                {credentialIssued
                  ?.reduce((rows, credentialIssued, index) => {
                    if (index % 3 === 0) {
                      rows.push([]);
                    }

                    rows[rows.length - 1].push(
                      <div className="col-lg-3 col-md-3 mx-5" key={index}>
                        <div className="row">
                          <div className="col-lg-1 ">
                            <div className="crad-v">
                              <div className="row">
                                <div className="col-lg-6 col-6 text-center">
                                  <div className="imglogo-v">
                                    <img
                                      src={uniLogin}
                                      className="com-logo"
                                      alt="Company Logo"
                                    />
                                  </div>
                                  <div className="imglogo-v">
                                    <b> {credentialIssued?.username}</b>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-4">
                                  <div className="text-vcard">
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          User Name:
                                        </strong>{' '}
                                        {credentialIssued?.username}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Issuer:
                                        </strong>{' '}
                                        {credentialIssued?.issuer}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="mb-2">
                                      <div className="op">
                                        <strong className="font-bolder-new">
                                          Issue Date:
                                        </strong>{' '}
                                        {credentialIssued?.issuanceDate}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <Button
                                      className="mt-4"
                                      onClick={(e) =>
                                        revokeCredentials(
                                          e,
                                          credentialIssued?.username,
                                          credentialIssued?.credentialid
                                        )
                                      }
                                    >
                                      Revoke Credentials
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );

                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                      {row}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <div
            className="home-page__container-content-wrapper mt-5"
            style={{
              marginBottom: '20rem',
            }}
          >
            <AnimatePresence>
              {showBannerLocalStorage != 'false' && idProofing != 'true' && (
                <motion.div
                  exit={{ x: -2000 }}
                  transition={{ duration: 0.5 }}
                  className="w-100 idproofing-banner-container"
                  style={{
                    position: 'relative',
                  }}
                >
                  {showBannerLocalStorage != 'false' &&
                    idProofing != 'true' && (
                      <motion.div
                        exit={{ x: -2000 }}
                        transition={{ duration: 0.5 }}
                        className="container-idproofing-banner"
                      >
                        <motion.div
                          transition={{ duration: 0.5 }}
                          className="idproofing-banner-wrapper"
                        >
                          <div className="idproofing-banner-animation">
                            <Lottie options={banner} height={150} width={150} />
                          </div>
                          <div className="idproofing-info">
                            <h4>{t('pages.walletDocuments.title')}</h4>
                            <h5>
                              {t('pages.walletDocuments.walletBannerMessage')}
                            </h5>
                            <div className="idproofing-steps">
                              <div className="idproofing-bullet-wrapper">
                                <Lottie
                                  className="img-bullet"
                                  options={bullet}
                                  height={50}
                                  width={50}
                                />
                                <h6>
                                  {t('pages.idproofing.bannerRequirement1')}
                                </h6>
                              </div>
                              <div className="idproofing-bullet-wrapper">
                                <Lottie
                                  className="img-bullet"
                                  options={bullet}
                                  height={50}
                                  width={50}
                                />
                                <h6>
                                  {t('pages.idproofing.bannerRequirement2')}
                                </h6>
                              </div>
                              <div className="buttonWrapper-idproofing">
                                <a href="https://auth.pingone.com/916fd1e1-7806-45f1-9663-bbe43dd7e5aa/as/authorize?response_type=code&client_id=7f12e526-c87c-40f3-a81d-a20bf50c3a53&scope=openid&redirect_uri=https://demoui.aegis-frk.com/profile/Wallet">
                                  <motion.Button className="startButton-idproofing mx-5">
                                    {t('pages.idproofing.start')}
                                  </motion.Button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      </motion.div>
                    )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* <div className="credCards">
            <div style={{ textAlignLast: 'center' }}>
              <Button
                className="mt-4"
                onClick={(e) => onuniIdQR(e)}
                style={{ width: '250px' }}
              >
                Get your Identity Pass
              </Button>
            </div>
          </div> */}
        </div>
      );
    }
  };

  const credentialsHTML = () => {
    return (
      <div>
         {(errorText || errorArray.length > 0) && (
                    <WithError
                      errors={errorText}
                      errorArray={errorArray}
                      isPopup={true}
                    />
                  )}
        {showBarCode && (
          <Modal
            show={showBarCode}
            backdrop="static"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <p>
                  Open your <strong>Digital Wallet</strong> scanner and scan the
                  QR Code shown beside within {count} seconds and store your
                  Identity Pass in your Digital Wallet.
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: 'center' }}>
                <QRCode value={url} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={(e) => fetchCred()}>Close</Button>
            </Modal.Footer>
          </Modal>
        )}
        <div className="m-5">
          <Button onClick={(e) => credentialPortability(e)}>
            Credential Portability
          </Button>
        </div>
        <div>
          {credentialIssued
            ?.reduce((rows, credentialIssued, index) => {
              if (index % 3 === 0) {
                rows.push([]);
              }

              rows[rows.length - 1].push(
                <div className="col-lg-3 col-md-3 mx-5" key={index}>
                  <div className="row">
                    <div className="col-lg-1 ">
                      <div className="crad-v">
                        <div className="row">
                          <div className="col-lg-6 col-6 text-center">
                            <div className="imglogo-v">
                              <img
                                src={uniLogin}
                                className="com-logo"
                                alt="Company Logo"
                              />
                            </div>
                            <div className="imglogo-v">
                              <b> {credentialIssued?.username}</b>
                            </div>
                          </div>
                          <div className="col-lg-6 col-4">
                            <div className="text-vcard">
                              <div className="mb-2">
                                <div className="op">
                                  <strong className="font-bolder-new">
                                    User Name:
                                  </strong>{' '}
                                  {credentialIssued?.username}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="mb-2">
                                <div className="op">
                                  <strong className="font-bolder-new">
                                    Issuer:
                                  </strong>{' '}
                                  {credentialIssued?.issuer}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="mb-2">
                                <div className="op">
                                  <strong className="font-bolder-new">
                                    Issue Date:
                                  </strong>{' '}
                                  {credentialIssued?.issuanceDate}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <Button
                                className="mt-4"
                                onClick={(e) =>
                                  revokeCredentials(
                                    e,
                                    credentialIssued?.username,
                                    credentialIssued?.credentialid
                                  )
                                }
                              >
                                Revoke Credentials
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div className="row" key={rowIndex}>
                {row}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {credTransfer && (
        <Modal
          show={credTransfer}
          onHide={(e) => handleClose(e)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Scan via Ping Wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>After scanning and sharing credential click next</p>
            <div style={{ textAlign: 'center' }}>
              <QRCode value={qrURL} />
            </div>
            {qRError && (
              <p style={{ color: 'red' }}>Please Scan again and share credential</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => reIssueQR(e)}>Retry</Button>
            <Button onClick={(e) => getCredentialTransferQRforIssuance(e)}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {walletChoiceSelector && (
        <Modal
          show={walletChoiceSelector}
          onHide={(e) => handleClose(e)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Choose from Wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="dropdown">Select an option : </label>
            <select id="dropdown" value={selectedValue} onChange={handleChange}>
              <option value="" disabled>
                Select an option
              </option>
              {walletChoice.map((choice) => (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ))}
            </select>
            {walletNotSelectedMessage && (
              <p style={{ color: 'red' }}>Please Select Wallet</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button>Back</Button>
            <Button onClick={(e) => getCredentialTransferQR(e)}>Next</Button>
          </Modal.Footer>
        </Modal>
      )}

      {consentPopup && (
        <Modal
          show={consentPopup}
          onHide={(e) => handleClose(e)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Attributes you wants to share
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Please select personal information you want to share</h5>
            <div>
              {attributesforConsent.map((option, index) => (
                <div key={index}>
                  <input
                    style={{ marginRight: '.5rem' }}
                    type="checkbox"
                    value={option}
                    onChange={handleCheckboxChange}
                  />
                  {option}
                </div>
              ))}
            </div>
            <p></p>
            <h>Required Attributes</h>
            <div>
              {mandatoryAttributesforConsent.map((option, index) => (
                <div key={index}>
                  <input
                    style={{ marginRight: '.5rem' }}
                    type="checkbox"
                    value={option}
                    onChange={handleCheckboxChange}
                    required
                  />
                  {option}
                </div>
              ))}
            </div>
            {mailNotSelectedMessage && (
              <p style={{ color: 'red' }}>Please Select mail to continue</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => handleClose(e)}>Cancel</Button>
            <Button onClick={(e) => setSelectedAttributes(e)}>Share</Button>
          </Modal.Footer>
        </Modal>
      )}
      {idProofingPingPopup && (
        <Modal
          show={idProofingPingPopup}
          onHide={(e) => handleClose(e)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              ID Proofing Required
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Kindly complete the ID proofing in order to issue the identity pass
            for your wallet.
          </Modal.Body>
          <Modal.Footer>
            <a href="https://auth.pingone.com/916fd1e1-7806-45f1-9663-bbe43dd7e5aa/as/authorize?response_type=code&client_id=7f12e526-c87c-40f3-a81d-a20bf50c3a53&scope=openid&redirect_uri=https://demoui.aegis-frk.com/profile/Wallet">
              <Button>Start ID Proofing</Button>
            </a>
          </Modal.Footer>
        </Modal>
      )}

      <div>
        <div className="tab-area pb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#">
              {t('pages.BreadCrumb.home')}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#">
              {t('pages.BreadCrumb.wallet')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>...</Breadcrumb.Item>
          </Breadcrumb>
          <div className="row mx-3 mb-3">
            <h1>{t('pages.BreadCrumb.wallet')}</h1>
          </div>
          <Tabs
            defaultActiveKey="Documents"
            transition={false}
            className="ml-3"
          >
            <Tab eventKey="Documents" title="Documents" tabClassName="px-3">
              {myDocumentsHTML()}
            </Tab>
            <Tab eventKey="Payments" title="Payments" tabClassName="px-3">
              {paymentsHTML()}
            </Tab>
            <Tab eventKey="credentialsHTML" title="Credentials" tabClassName="px-3">
              {credentialsHTML()}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

Wallet.propTypes = {};

export default Wallet;
