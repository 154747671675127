import { React } from 'react';
import 'app/modules/Footer/Footer.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="col-md-12 pt-4 text-center">
        <div>
          <a className="footer-links pr-4" href="/terms">
            <span className="underline">{t('pages.footer.termsOfUse')}</span>
            <span className="pl-4">|</span>
          </a>
          <a className="footer-links pr-4" href="/contact-us">
            <span className="underline">{t('pages.header.contactUs')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
